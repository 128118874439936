import { Button, Modal, Row, Spin, Typography } from 'antd';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { withApollo } from 'react-apollo';
import '../../assets/css/orders.css';
import { OrderContext } from '../../utils/context';
import OrdersForm from './components/orders-form';
import SearchModal from './components/search';
import { modeTypes } from './constants';
import { acquireFormData } from './functions';
import { GET_OPTIONS, GET_ORDERS_BY_ID } from './queries';
import _ from 'lodash';
import { UPDATE_ORDER } from '../Orders/queries';

const OrdersAction = (props) => {
  const { date, client, onCancel, onOk: closeModal, id, vehicle, mode: orderMode, withdrawDate = null, isDriver, handleRefetch, record = {} } = props;
  const [state, setState] = useState({
    closeModal,
    orderMode,
    formData: {
      transport: [{}, {}, {}]
    },
    error: false,
    resetForm: false,
    action: orderMode === modeTypes.EDIT ? 'edit' : 'add',
    submit: false,
    transportDate: null,
    transportDistance: null,
    dataList: {
      vehicleList: []
    },
    modal: {
      visible: false,
      mode: null,
      defaults: {}
    },
    imported: null,
    isDriver
  });

  const stateSetter = (value) => setState(state => ({ ...state, ...value }));
  const modalSetter = (value) => setState(state => ({ ...state, modal: { ...state.modal, ...value } }));

  const onImportClick = () => modalSetter({ visible: true, mode: modeTypes.ALL });

  const fetchFormData = async (id, vehicle, mode) => {
    /**
     * param vehicle is Only used when GET_ORDERS_BY_ID results to none 
     * prevent query if there is no id
    */
    let formatted = {}
    const defaultNodes = [{ ordersTransportsByOrderId: { nodes: [] }, vehicleId: vehicle }]; //this is a fallback value
    if (!id) {
      const [order] = defaultNodes;
      formatted = acquireFormData(order, state.formData, mode, withdrawDate, record, state.action, state.transportDate);
    } else {
      const { data: { allOrders: { nodes } } } = await client.query({
        query: GET_ORDERS_BY_ID,
        fetchPolicy: 'network-only',
        variables: {
          filter: { 
            isDeleted: { equalTo: false },
            id: { equalTo: id } 
          }
        }
      });
      const [order] = nodes.length > 0 ? nodes : defaultNodes;
      if ((mode === 'all' || mode === 'reverse') && state.action) {
        order.id = state.formData.id;
        if (!state.formData.removeTransports) {
          state.formData.removeTransports = _.compact(state.formData.transport.map(data => data.id));
          state.formData.isEditImport = true;
        }
      }
      formatted = acquireFormData(_.cloneDeep(order), state.formData, mode, withdrawDate, record, state.action, state.transportDate);
    }
    stateSetter({ formData: { ...formatted }, resetForm: true, transportDate: formatted.transportDate });
    modalSetter({ transportIndex: null, defaults: {} });
  };

  const onImportData = (data, mode, importMode) => {
    if (mode === modeTypes.ORIGIN || mode === modeTypes.DESTINATION) {
      const { modal: { transportIndex } } = state;
      stateSetter({ imported: { importMode, mode, transportIndex, data } });
      modalSetter({ transportIndex: null, defaults: {} });
      return;
    };
    fetchFormData(data, vehicle, mode);
  };

  const onSubmit = () => {
    stateSetter({ submit: true });
  };

  const onUnmounted = async () => {
    if(!id) return;
    await client.mutate({
      mutation: UPDATE_ORDER,
      variables: {
        input: {
          orderPatch: {
            isEditing: false
          }, 
          id
        }
      }
    });
  };

  useEffect(() => {
    window.addEventListener('beforeunload', onUnmounted);
    return () => {
      window.removeEventListener('beforeunload', onUnmounted);
      onUnmounted();
    }
  }, [])

  useEffect(() => {
    (async () => {
      const { data } = await client.query({
        query: GET_OPTIONS,
        fetchPolicy: 'network-only',
        variables: {
          filter: { status: { equalTo: 'ACTIVE' } }
        }
      });
      const dataList = { vehicleList: data.allVehicles.nodes, driverList: data.allUsers.nodes };
      stateSetter({ dataList });
    })();

    if (orderMode === modeTypes.EDIT) stateSetter({ action: modeTypes.EDIT })
    fetchFormData(id, vehicle, orderMode);
  }, []);

  useLayoutEffect(() => {
    setTimeout(() => {
      if (document.getElementsByClassName('order-modal')[0]) {
        document.getElementsByClassName('order-modal')[0].scrollIntoView();
      }
    })
  }, []);

  return (
    <Modal
      visible={true}
      width={'80%'}
      style={{ top: 20 }}
      className='order-modal'
      onCancel={onCancel}
      onOk={onSubmit}
      footer={[
        !isDriver && <Button key='submit' type='primary' onClick={onSubmit} autoFocus disabled={state.submit}>{orderMode === 'edit' ? '更新' : '登録'}</Button>,
      ]}
    >
      <OrderContext.Provider value={{ ...state, orderSetter: stateSetter, modalSetter }}>
        <Spin spinning={state.submit}>
          <Row>
            <Typography style={{ textAlign: 'center' }}>受注・配車割当</Typography>
            {!isDriver && <Row style={{ textAlign: 'end', marginBottom: 10 }}>
              <Button onClick={onImportClick} type='primary'>過去検索</Button>
            </Row>}
            <Row style={{ overflowY: 'auto' }}>
              <OrdersForm date={date} handleRefetch={handleRefetch} />
            </Row>
          </Row>
          {state.modal.visible && (
            <SearchModal
              onOk={onImportData}
              onCancel={() => modalSetter({ visible: false, defaults: {} })}
            />
          )}
        </Spin>
      </OrderContext.Provider>
    </Modal>
  )
};

export default withApollo(OrdersAction);