import { Col, Modal, Row, Typography } from 'antd';
import 'moment/locale/ja';
import React, { useEffect } from 'react';
import adhocTranslator from '../../../../../utils/locales/translate-adhoc';

const lt = adhocTranslator('K020-010');

const indexTypes = {
  1: '１',
  2: '２',
  3: '３',
  4: '４',
  5: '５'
}
const titleTypes = {
  assignee: '担当者',
  contact: '連絡先',
  borrower: '借主',
};
const keyTypes = {
  assignee: {
    pickup: 'pickupAssignee',
    dropoff: 'dropoffAssignee'
  },
  contact: {
    pickup: 'pickupContact',
    dropoff: 'dropoffContact'
  }
};
const AssigneeModal = (props) => {
  const { onCancel, onOk, visible, transports, type } = props;

  const keys = keyTypes[type];

  return (
    <Modal
      visible={visible}
      onOK={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onOk();
      }}
      onCancel={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onCancel();
      }}
      className='modal-vehicle'
      width={800}
      footer={[
        <></>,
      ]}
    >
      <Row onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}>
        <Row type='flex' justify='center' style={{ marginBottom: 30 }}>
          <Typography style={{ fontWeight: 'bold' }}>担当者/連絡先</Typography>
        </Row>
        <Row style={{ paddingLeft: 20, paddingRight: 20 }}>
          {transports.map((transport, transportIndex) => {
            let pickups = transport.pickupAssignee ? transport.pickupAssignee.split('|') : [];
            let pickupContacts = transport.pickupContact ? transport.pickupContact.split('|') : [];
            let dropoffs = transport.dropoffAssignee ? transport.dropoffAssignee.split('|') : [];
            let dropoffContacts = transport.dropoffContact ? transport.dropoffContact.split('|') : [];

            pickups = pickups.map((pickup, index) => (
              <Typography>担当者/連絡先{indexTypes[index + 1]}：{pickup && pickup !== 'empty' ? pickup : ''}{pickupContacts[index] && pickupContacts[index] !== 'empty' ? `（${pickupContacts[index]}）` : ''}</Typography>
            ))
            dropoffs = dropoffs.map((dropoff, index) => (
              <Typography>担当者/連絡先{indexTypes[index + 1]}：{dropoff && dropoff !== 'empty' ? dropoff : ''}{dropoffContacts[index] && dropoffContacts[index] !== 'empty' ? `（${dropoffContacts[index]}）` : ''}</Typography>
            ))
            return (
              <Row style={{ marginBottom: 20 }}>
                <Col span={12}>
                  <Typography>発{indexTypes[transportIndex + 1]}</Typography>
                  {pickups}
                </Col>
                <Col span={12}>
                  <Typography>着{indexTypes[transportIndex + 1]}</Typography>
                  {dropoffs}
                </Col>
              </Row>
            );
          })}
        </Row>
      </Row>
    </Modal>
  )
};

const styles = {
  label: { textAlign: 'end', paddingTop: 5, flex: 1, marginRight: 30 },
}

export default AssigneeModal;