import React, { Component, Fragment } from 'react';
import TableToExcel from '@linways/table-to-excel';
import { withApollo } from 'react-apollo';
import moment from 'moment';

import 'moment/locale/ja';
import momentDurationFormatSetup from 'moment-duration-format';
import uuid from 'uuid/v4';
import _ from 'lodash';

const crewPerformanceType = `
    excelCrewPerformanceOutputData {
        id
        driverName
        departmentManager
        manager
        month
        workingDays
        days {
            column1
            column2
            column3
            column4
            column5
            column6
            column7
            column8
            column9
            column10
            column11
            column12
            column13
            column14
            column15
            column16
            column17
            column18
        }
    }
`;

class CrewPerformanceExcel extends Component {

    constructor(props) {
        super(props);
        this.excel = {}
    };

    render() {
        const noOfDaysCounter = (noOfDays, row) => {
            // eslint-disable-next-line array-callback-return
            row.days.map((detail, i) => {
                if (!noOfDays.includes(detail.column1)) {
                    noOfDays.push(detail.column1)
                }
            })

        }

        const getActualVehicleRate = (actualCarDistance, mileage) => {
            if (!mileage) {
                return '';
            }
            var vechicleRate = actualCarDistance / mileage;
            return Math.round(vechicleRate * 100) + '%';
        }
        const getDurationTime = (time) => {
            var duration = time.format("hh:mm", {
                trim: false
            });
            return duration
        }

        const createExcel = (noOfDays, row) => {
            momentDurationFormatSetup(moment);

            let i = row.id;
            let totalJcolumn = moment.duration("00:00", "hh:mm");
            let totalLcolumn = 0;

            noOfDaysCounter(noOfDays, row)
            let idvalue = "Output" + i;
            let details = row.days.map((detail, i) => {
                var rowDuration;
                if (detail.column9) {
                    rowDuration = detail.column9
                    totalJcolumn = totalJcolumn.add(rowDuration, "hh:mm")
                }
                if (detail.column11) {
                    totalLcolumn += detail.column11;
                }

                return (
                    <tr data-height="33" key={uuid()}>
                        <td></td>
                        <td data-a-v="middle" data-b-l-s="thin" data-b-b-s="thin" data-a-h="center">{detail.column1}</td>{/*DAY*/}
                        <td data-a-v="middle" data-b-l-s="thin" data-b-b-s="thin" data-a-h="center">{detail.column2}</td>{/*Landing place*/}
                        <td data-a-v="middle" data-b-l-s="thin" data-b-b-s="thin" data-a-h="center">{detail.column3}</td>{/*Vehicle Number*/}
                        <td data-a-v="middle" data-b-l-s="thin" data-b-b-s="thin" data-a-h="center">{detail.column4}</td>{/*Working time*/}
                        <td data-a-v="middle" data-b-l-s="thin" data-b-b-s="thin" data-a-h="center">{detail.column5}</td>{/*Out-of*/}
                        <td data-a-v="middle" data-b-l-s="thin" data-b-b-s="thin" data-a-h="center">{detail.column6}</td>
                        <td data-a-v="middle" data-b-l-s="thin" data-b-b-s="thin" data-a-h="center">{detail.column7}</td>
                        <td data-a-v="middle" data-b-l-s="thin" data-b-b-s="thin" data-a-h="center">{detail.column8}</td>
                        <td data-a-v="middle" data-b-l-s="thin" data-b-b-s="thin" data-a-h="center">{rowDuration}</td>
                        <td data-a-v="middle" data-b-l-s="thin" data-b-b-s="thin" data-a-h="center">{detail.column10}</td>
                        <td data-a-v="middle" data-b-l-s="thin" data-b-b-s="thin" data-a-h="right">{detail.column11}</td>
                        <td data-a-v="middle" data-b-l-s="thin" data-b-b-s="thin" data-a-h="right">{detail.column12}</td>
                        <td data-a-v="middle" data-b-l-s="thin" data-b-b-s="thin" data-a-h="right" data-t="n">{detail.column13}</td>
                        <td data-a-v="middle" data-b-l-s="thin" data-b-b-s="thin" data-a-h="right">{getActualVehicleRate(detail.column13, detail.column11)}</td>
                        <td data-a-v="middle" data-b-l-s="thin" data-b-b-s="thin" data-a-h="center">{detail.column15}</td>
                        <td data-a-v="middle" colSpan="2" data-b-l-s="thin" data-b-b-s="thin" data-a-h="center" data-b-r-s="thin" data-a-wrap="true">{detail.column16}</td>
                    </tr>
                );
            })

            let totalNcolumn = _.sumBy(row.days, (day) => Number(day.column13));
            let totalOColumn = "";
            if (totalLcolumn > 0) {
                totalOColumn = Math.round(totalNcolumn / totalLcolumn * 100) + '%'
            } else if (totalLcolumn === 0) {
                totalOColumn = 0 + '%'
            }

            // "2,5,12,15,12,12,14,12,14,12,12,14,14,12,12,12,15"
            return (
                <Fragment key={uuid()}>
                    <table id={idvalue} style={{ display: "none" }} data-cols-width="2,5,8.5,8.5,8.5,8.5,8.5,8.5,8.5,8.5,8.5,8.5,8.5,8.5,8.5,7,7,7">
                        <thead>
                            <tr data-height="11">
                                <td ></td>
                                <th colSpan="17"></th>
                            </tr>
                            <tr data-height="19">
                                <td ></td>
                                <td colSpan="6"></td>
                                <th rowSpan="2" colSpan="4" data-a-h="right" data-a-v="top" data-f-sz="20" data-f-bold="true" style={{ padding: '10px' }}>乗　務　実　績　一　覧　表</th>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <th colSpan="1" data-a-h="center" data-b-a-s="thin" data-f-sz="26" style={{ padding: '10px' }}></th>
                                <td colSpan="1" data-a-h="center" data-b-a-s="thin" data-a-v="middle">部長</td>
                                <td colSpan="1" data-a-h="center" data-b-a-s="thin" data-a-v="middle">管理者</td>
                            </tr>
                            <tr data-height="38">
                                <td  ></td>
                                <td colSpan="6"></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td rowSpan="1" colSpan="1" data-b-a-s="thin">&nbsp;</td>
                                <td rowSpan="1" colSpan="1" data-b-a-s="thin">&nbsp;</td>
                                <td rowSpan="1" colSpan="1" data-b-a-s="thin">&nbsp;</td>
                            </tr>
                            <tr data-height="9">
                                <td ></td>
                                <td ></td>
                                <td ></td>
                                <td ></td>
                                <td ></td>
                                <td ></td>
                                <td ></td>
                                <td ></td>
                                <td ></td>
                                <td ></td>
                                <td ></td>
                                <td ></td>
                                <td ></td>
                                <td ></td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr data-height="20">
                                <td ></td>
                                <td colSpan="3" data-a-v="middle" data-f-sz="14" data-a-h="center" data-b-a-s="thin">運転者氏名</td>
                                <td colSpan="2" data-a-v="middle" data-f-sz="14" data-a-h="center" data-b-a-s="thin">{row.driverName}</td>
                                <td colSpan="2"></td>
                                <th colSpan="3" data-a-h="center" data-a-v="middle" data-f-sz="14">営業所</th>
                                <td colSpan="4"></td>
                                <td colSpan="3" data-f-sz="14">{moment(row.month).format("YYYY年MM月分")}</td>
                            </tr>
                            <tr data-height="9">
                                <td ></td>
                                <td ></td>
                                <td ></td>
                                <td ></td>
                                <td ></td>
                                <td ></td>
                                <td ></td>
                                <td ></td>
                                <td ></td>
                                <td ></td>
                                <td ></td>
                                <td ></td>
                                <td ></td>
                                <td ></td>
                                <td ></td>
                                <td ></td>
                                <td colSpan="2"></td>
                            </tr>
                            <tr data-height="44">
                                <td ></td>
                                <td data-fill-color="D8D8D8" data-a-wrap="true" data-a-v="middle" data-a-h="center" data-b-a-s="thin" style={{ padding: '10px' }}>日</td>
                                <td data-fill-color="D8D8D8" data-a-wrap="true" data-a-v="middle" data-a-h="center" data-b-a-s="thin" style={{ padding: '10px' }}>出庫地</td>
                                <td data-fill-color="D8D8D8" data-a-wrap="true" data-a-v="middle" data-a-h="center" data-b-a-s="thin" style={{ padding: '10px' }}>車両番号</td>
                                <td data-fill-color="D8D8D8" data-a-wrap="true" data-a-v="middle" data-a-h="center" data-b-a-s="thin" style={{ padding: '10px' }}>出勤時間</td>
                                <td data-fill-color="D8D8D8" data-a-wrap="true" data-a-v="middle" data-a-h="center" data-b-a-s="thin" style={{ padding: '10px' }}>退勤時間</td>
                                <td data-fill-color="D8D8D8" data-a-wrap="true" data-a-v="middle" data-a-h="center" data-b-a-s="thin" style={{ padding: '10px' }}>ハンドル時間</td>
                                <td data-fill-color="D8D8D8" data-a-wrap="true" data-a-v="middle" data-a-h="center" data-b-a-s="thin" style={{ padding: '10px' }}>運転外実績</td>
                                <td data-fill-color="D8D8D8" data-a-wrap="true" data-a-v="middle" data-a-h="center" data-b-a-s="thin" style={{ padding: '10px' }}>仮暇/休憩時間</td>
                                <td data-fill-color="D8D8D8" data-a-wrap="true" data-a-v="middle" data-a-h="center" data-b-a-s="thin" style={{ padding: '10px' }}>拘束時間</td>
                                <td data-fill-color="D8D8D8" data-a-wrap="true" data-a-v="middle" data-a-h="center" data-b-a-s="thin" style={{ padding: '10px' }}>残業時間</td>
                                <td data-fill-color="D8D8D8" data-a-wrap="true" data-a-v="middle" data-a-h="center" data-b-a-s="thin" style={{ padding: '10px' }}>走行距離一般</td>
                                <td data-fill-color="D8D8D8" data-a-wrap="true" data-a-v="middle" data-a-h="center" data-b-a-s="thin" style={{ padding: '10px' }}>走行距離高速</td>
                                <td data-fill-color="D8D8D8" data-a-wrap="true" data-a-v="middle" data-a-h="center" data-b-a-s="thin" style={{ padding: '10px' }}>実車距離</td>
                                <td data-fill-color="D8D8D8" data-a-wrap="true" data-a-v="middle" data-a-h="center" data-b-a-s="thin" style={{ padding: '10px' }}>実車率</td>
                                <td data-fill-color="D8D8D8" data-a-wrap="true" data-a-v="middle" data-a-h="center" data-b-a-s="thin" style={{ padding: '10px' }}>宿泊有無</td>
                                <td data-fill-color="D8D8D8" colSpan="2" data-a-wrap="true" data-a-v="middle" data-a-h="center" data-b-a-s="thin" style={{ padding: '10px' }}>備考</td>
                            </tr>
                            {details}
                            <tr data-height="38">
                                <td ></td>
                                <td data-a-v="middle" colSpan="2" data-a-h="center" data-f-color="000" data-f-sz="15" data-b-a-s="thin" style={{ padding: '20px' }}>合　計</td>{/*B-C*/}
                                <td data-a-v="middle" colSpan="3" data-a-h="center" data-f-color="000" data-f-sz="15" data-b-a-s="thin" style={{ padding: '20px' }}>出勤日数　{row.workingDays}日</td>{/*D-F*/}
                                <td data-a-v="middle" data-a-h="center" data-f-color="000" data-b-a-s="thin" style={{ padding: '20px' }}></td>{/*G*/}
                                <td data-a-v="middle" data-a-h="center" data-f-color="000" data-b-a-s="thin" data-f-sz="13" style={{ padding: '20px' }}></td>{/*H*/}
                                <td data-a-v="middle" data-a-h="center" data-f-color="000" data-b-a-s="thin" data-f-sz="13" style={{ padding: '20px' }}></td>{/*I*/}
                                <td data-a-v="middle" data-a-h="center" data-f-color="000" data-b-a-s="thin" data-f-sz="13" style={{ padding: '20px' }}>{getDurationTime(totalJcolumn)}</td>{/*J*/}
                                <td data-a-v="middle" data-a-h="center" data-f-color="000" data-b-a-s="thin" style={{ padding: '20px' }}></td>{/*K*/}
                                <td data-a-v="middle" data-a-h="right" data-f-color="000" data-b-a-s="thin" data-f-sz="13" style={{ padding: '20px' }}>{totalLcolumn}</td>{/*L*/}
                                <td data-a-v="middle" data-a-h="right" data-f-color="000" data-b-a-s="thin" style={{ padding: '20px' }}></td>{/*M*/}
                                <td data-a-v="middle" data-a-h="right" data-f-color="000" data-b-a-s="thin" data-f-sz="13" style={{ padding: '20px' }} data-t="n">{totalNcolumn}</td>{/*N*/}
                                <td data-a-v="middle" data-a-h="right" data-f-color="000" data-b-a-s="thin" data-f-sz="13" style={{ padding: '20px' }}>{totalOColumn}</td>{/*O*/}
                                <td data-a-v="middle" colSpan="3" data-a-h="center" data-f-color="000" data-b-a-s="thin" style={{ padding: '20px' }}></td>
                            </tr>
                        </tbody>
                    </table>
                </Fragment>
            )
        };

        let { data } = this.props;
        let noOfDays = [];


        const excels = data.map((row) => {
            return createExcel(noOfDays, row);
        });
        if (excels) {
            setTimeout(() => {
                for (var i = data.length - 1; i >= 0; i--) {
                    let row = data[i];
                    let idname = "Output" + row.id;
                    TableToExcel.convert(document.getElementById(idname), {
                        name: row.driverName + "_" + moment(row.month).format("YYYYMM") + ".xlsx",
                        sheet: {
                            name: "Sheet 10"
                        },
                        pageSetup: {
                            orientation: 'portrait',
                            paperSize: 13,
                            // fitToWidth: 1,
                            // fitToHeight: 1,
                            margins: {
                                left: 0.1, right: 0.1,
                                top: 0.47, bottom: 0.47,
                                header: 0.31, footer: 0.31
                            },
                        }
                    });
                }
                this.props.client.writeData({
                    data: {
                        excelOutput: {
                            __typename: 'excelOutput',
                            excelCrewPerformanceOutputData: []
                        }
                    }
                });
            }, 0);
        }

        moment.locale('ja');
        return (<Fragment>{excels}</Fragment>);
    }
}

const CrewPerformanceExcelWrap = withApollo(CrewPerformanceExcel);

export { CrewPerformanceExcelWrap, crewPerformanceType };