import React, { useContext, useEffect, Fragment } from 'react';
import moment from 'moment';
import { Table, Spin } from 'antd';
import { Context } from '../../../commons/Context';
import _ from 'lodash';

const Graph = ({ lt }) => {
  const { state, dispatch } = useContext(Context);
  const {
    vehicleId,
    vehicleNo,
    transportDate_From_Year,
    transportDate_From_Month,
    transportDate_To_Year,
    transportDate_To_Month
  } = state.filters;

  const columns = [{
    title: lt('lbl_year/month'),
    dataIndex: 'dateFormat',
    key: 'dateFormat',
    align: 'center'
  }, {
    title: lt('lbl_total_mileage'),
    dataIndex: 'totalMilleage',
    key: 'totalMilleage',
    align: 'center',
    render: (text) => { return <p style={{ textAlign: 'right' }}>{!!text ? `${text.toLocaleString()}km` : `${0}km`}</p> }
  }, {
    title: lt('lbl_amount_refueled'),
    dataIndex: 'totalFuelOilSupply',
    key: 'totalFuelOilSupply',
    align: 'center',
    render: (text) => { return <p style={{ textAlign: 'right' }}>{!!text ? `${text.toLocaleString()}L` : `${0}L`}</p> }
  }, {
    title: lt('lbl_fuel_consumed'),
    dataIndex: 'totalFuelConsumed',
    key: 'totalFuelConsumed',
    align: 'center',
    render: (text) => { return <p style={{ textAlign: 'right' }}>{isNaN(text.toLocaleString()) || !isFinite(text) ? '0ｋｍ/L' : `${_.round(+text)}ｋｍ/L`}</p> }
  }, {
    title: lt('lbl_actual_vehicle_distance'),
    dataIndex: 'totalActualDistance',
    key: 'totalActualDistance',
    align: 'center',
    render: (text) => { return <p style={{ textAlign: 'right' }}>{!!text ? `${text.toLocaleString()}km` : `${0}km`}</p> }
  }, {
    title: lt('lbl_vehicle_operation_rate'),
    dataIndex: 'totalActualOperation',
    key: 'totalActualOperation',
    align: 'center',
    render: (text) => { return <p style={{ textAlign: 'right' }}>{!!text ? `${text.toLocaleString()}％` : `${0}％`}</p> }
  }];
  const sorted = state.graph.sort((a, b) => (a.date > b.date) ? 1 : ((b.date > a.date) ? -1 : 0));
  let dataSource = sorted.map(sort => {
    const ym = sort.date.split('-');
    const format = ym.length > 1 ? ym[0] + '年' + ym[1] + '月' : sort.date + '年';
    return {
      dateFormat: format,
      ...sort
    }
  });

  useEffect(() => {
    let err = true;
    Object.keys(dataSource).forEach((key) => {
      if (!dataSource[key] && !dataSource[key].nodes.length) {
        err = false;
      }
    })
    if (err && state.spinner) {
      dispatch({
        type: 'spinner',
        spinner: false
      })
    }
  }, [state.graph]);

  return (
    <Fragment>
      <div className='head' style={{ textAlign: 'center', fontWeight: 'bold' }}>{lt('lbl_vehicle_analysis')}</div>
      <div className='chartDate'>{vehicleId !== undefined && `${lt('lbl_analysis_year/month')}: ${moment().format('YYYY年MM月')}`}</div>
      <div className='chartDate'>{vehicleId !== undefined && `${lt('対象号車')}: ${vehicleNo}`}</div>
      <div className='chartDate' style={{ marginBottom: 15 }}>
        {vehicleId !== undefined && `${lt('出力年')}:  
          ${moment(`${transportDate_From_Year}-${transportDate_From_Month}`).format('YYYY年MM月')} ～ 
          ${moment(`${transportDate_To_Year}-${transportDate_To_Month}`).format('YYYY年MM月')}`}
      </div>

      <Spin spinning={state.spinner} size='large'>
        <Table dataSource={dataSource} columns={columns} bordered />
      </Spin>
    </Fragment>
  );
}
export default Graph;