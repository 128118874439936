import { getAll, getTransportationPrice, getVehiclePrice } from '../../../queries/MasterMaintenance/unitprice';
import adhocTranslator from "../../../utils/locales/translate-adhoc";

export default (props = null) => {
    let lt = adhocTranslator('K030-021');
    return {
        fetchQuery: getAll,
        fetchQueryTransportation: getTransportationPrice,
        fetchQueryVehicle: getVehiclePrice,
        allKey: 'allUnitPrices',
        perPage: 20,
        displayField: 'unitPriceName',
        columns: [
            {
                title: lt('単価表'),
                dataIndex: 'unitPriceName',
                key: 'unitPriceName',
            }
        ],
        type: 'radio',
        unitCategory: props.categoryId ? props.categoryId : null,
        th: 430,
        dataMapping: (item) => {
            return {
                id: item.id,
                unitPriceName: item.unitPriceName,
                unitPriceCategory: item.unitPriceCategory
            }
        }
    };
}