import React from 'react';
import {Radio, Form} from 'antd';

const CustomRadioGroup = ({
                          label,
                          className,
                          width =20,
                          mode,
                          offset = 0,
                          half = false,
                          oneFourth = false,
                          oneThird = false,
                          twoThird = false,
                          small = false,
                          labelWidth,
                          contain = false,
                          decorator,
                          form: {getFieldDecorator},
                          fieldName,
                          options,
                          optionId = 'id',
                          optionValue = 'value',
                          onChange,
                          customerConfig
                      }) => {
    const formItemLayout = {
        labelCol: {  //for label width
            xs: {span: 24},
            sm: {span: 8},
            md: {span: 4},
            lg: {span: labelWidth},
        },
        wrapperCol: { //for input width
            xs: {span: 24},
            sm: {span: 24},
            md: {span: width, offset},
        },
    };

    const onchangeChecker = onChange ? {onChange} : {};
    const autofocus = 'autoFocus'

    let mainStyle = { display:'inline-block' };
    if(half){
        mainStyle.width = '50%';
    }else if(oneFourth){
        mainStyle.width = '25%';
    }else if(oneThird){
        mainStyle.width = '33.3%';
    }else if(twoThird){
        mainStyle.width = '66.6%';
    }else if(small){
        mainStyle.width = '15%';
    }else mainStyle = null;

    const renderOptions = options.map((item, i) => (<Radio key={i} value={item[optionId]} autoFocus={item[autofocus]} >{item[optionValue]}</Radio>));
    
    if(!decorator.initialValue || (customerConfig && mode === 'add')) {
        decorator.initialValue = options[0].id
    }

    return (
        <Form.Item
            {...formItemLayout}
            label={label}
            style={mainStyle}
            className={className}
        >
            {getFieldDecorator(fieldName, decorator)(
                <Radio.Group disabled={mode === 'delete' ? true : false} {...onchangeChecker}>
                    {renderOptions}
                </Radio.Group>
            )}
        </Form.Item>
    );
};

export default CustomRadioGroup
