import {gql} from 'apollo-boost';

const createDetails = gql`
mutation createUnitPricesDetail(
  $unitPriceId:Int,
  $weight:Int,
  $transportDistance:Int,
  $price:Float,
  $updatedUserId : Int,
  $createdUserId : Int,
) {
  createUnitPricesDetail(input:{
    unitPricesDetail: {
      unitPriceId:$unitPriceId,
      weight:$weight,
      transportDistance:$transportDistance,
      price:$price,
      createdUserId : $createdUserId,
      updatedUserId : $updatedUserId
    }
  }) {
    unitPricesDetail {
      id
    }
  }
}`;


const deleteDetails = gql`
mutation deleteUnitPricesDetailById ($id:Int!) {
  deleteUnitPricesDetailById(input:{
    id:$id
  }) {
    deletedUnitPricesDetailId
  }
}`;

const createUnitpriceDetails = gql`
  mutation unitPricesDetailsInsertMultiple($payload: String!) {
    unitPricesDetailsInsertMultiple(input: { payload: $payload }) {
      result
    }
  }
`

const updateUnitpriceDetails = gql`
  mutation unitPricesDetailsUpdateMultiple($payload: String!) {
    unitPricesDetailsUpdateMultiple(input: { payload: $payload }) {
      result
    }
  }
`

export {
  createDetails,
  deleteDetails,
  createUnitpriceDetails,
  updateUnitpriceDetails
};