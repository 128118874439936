import {Link} from "react-router-dom";
import React, {Fragment} from "react";
import {Button} from "antd";
import adhocTranslator from "../../../utils/locales/translate-adhoc";
import ActionHeader from '../../../commons/TableActions/ActionHeader';
import {getAll} from '../../../queries/MasterMaintenance/users';
import moment from "moment";
import { statusTypes } from "../../../utils/types";

export default () => {
    let lt = adhocTranslator('K900-010');

    const bloodTypeList = {
        'A': 'A',
        'B': 'B',
        'O': 'O',
        'AB': 'AB',
        'N_A': '不明',
    };

    // const authorityList = {
    //     1:ltA('rdo_user_authority_system_manager'),
    //     2:ltA('rdo_user_authority_representative_accountant'),
    //     3:ltA('rdo_user_authority_office_worker'),
    //     4:ltA('rdo_user_authority_driver'),
    //     5:ltA('rdo_user_authority_driver_charttered_vehicle'),
    //     6:ltA('rdo_user_authority_driver_charttered_vehicle_induction'),
    //     // 1: ltA('super_admin'),
    //     // 2: ltA('rdo_rep_accounts_executive'),
    //     // 3: ltA('rdo_clerical_accounting'),
    //     // 4: ltA('rdo_driver'),
    // };

    const dateFormat = 'YYYY/MM/DD';

    let formatPostCode = postCode => {
        if (postCode) {
            postCode = postCode.replace(/-/g,"");
            if (postCode.length >= 4) {
                postCode = postCode.substr(0,3) + "-" + postCode.substr(3,4);
            }
        }
        return postCode;
    }

    return {
        fetchQuery: getAll,
        allKey: 'allUsers',
        perPage: 20,
        removeType: true,
        authority: null,
        minWidth: 'max-content',
        th: '630px',
        columns : [
            {
                title: lt('lbl_user_id_header'),
                dataIndex: 'loginId',
                key: 'loginId',
                width : 100,
            }, {
                title: lt('lbl_name_kanji_header'),
                dataIndex: 'fullNameJa',
                key: 'fullNameJa',
                width : 150,
            }, {
                title: lt('lbl_name_kana_header'),
                dataIndex: 'fullNameJaKatakana',
                key: 'fullNameJaKatakana',
                width : 150,
            }, {
                title: lt('lbl_name_abbv_header'),
                dataIndex: 'shortNameJa',
                key: 'shortNameJa',
                width : 150,
            }, {
                title: lt('lbl_department_affiliation_start_header'),
                dataIndex: 'affiliationStartDate',
                key: 'affiliationStartDate',
                align: 'center',
                width : 90,
            },{
                title: lt('lbl_postal_code_header'),
                dataIndex: 'postCode',
                key: 'postCode',
                align: 'center',
                width : 80,
            }, {
                title: lt('lbl_address_header'),
                dataIndex: 'address',
                key: 'address',
                width : 340,
            }, {
                title: lt('lbl_mobile_phone_number_header'),
                dataIndex: 'mobileNo',
                key: 'mobileNo',
                width : 120,
            }, {
                title: lt('lbl_date_of_birth_header'),
                dataIndex: 'dateOfBirth',
                key: 'dateOfBirth',
                align: 'center',
                width : 90,
            }, {
                title: lt('lbl_blood_type_header'),
                dataIndex: 'bloodType',
                key: 'bloodType',
                align: 'center',
                width : 60,
            },  {
                title: lt('lbl_status_header'),
                dataIndex: 'status',
                key: 'status',
                align: 'center',
                width : 60,
            }, 
            // {
            //     title: lt('lbl_authority_header'),
            //     dataIndex: 'authority',
            //     key: 'authority',
            //     align: 'center',
            //     width: 120
            // }, 
            {
                title: lt('lbl_process'),
                key: 'process',
                render: (text, record) => (
                    <span>
                        <Link to={{
                            pathname: `/user/update/${record.id}`,
                            state: {id: text.id}
                        }}>[ {lt('btn_edit')} ]</Link>
                        <Link to={{
                            pathname: `/user/delete/${record.id}`,
                            state: {id: text.id}
                        }}>[ {lt('btn_delete')} ]</Link>
                    </span>
                ),
                align: 'center',
                width: 110
            }
        ],
        dataMapping: (item) => {
            return {
                loginId: item.loginId,
                fullNameJa: item.fullNameJa,
                fullNameJaKatakana: item.fullNameJaKatakana,
                shortNameJa: item.shortNameJa,
                affiliationStartDate: item.affiliationStartDate && moment(item.affiliationStartDate).format(dateFormat),
                // affiliationEndDate: item.affiliationEndDate && moment(item.affiliationEndDate).format(dateFormat),
                postCode: formatPostCode(item.postCode),
                address: item.address,
                mobileNo: item.mobileNo,
                dateOfBirth: item.dateOfBirth && moment(item.dateOfBirth).format(dateFormat),
                bloodType: bloodTypeList[item.bloodType],
                // authority: authorityList[item.authority],
                status: statusTypes[item.status],
                id: item.id
            }
        },

        actionHeader: () => {
            return (
                <Fragment>
                    <ActionHeader
                        rightActions={
                            <Fragment>
                                <Button
                                    type="primary"
                                    htmlType="button">
                                    <Link to="/user/register">{lt('btn_register_new')}</Link>
                                </Button>
                            </Fragment>
                        }/>
                </Fragment>
            )
        },
    };
}