import React, {Fragment} from 'react';
import {DatePicker, Form, Button} from 'antd';
import jaJP from 'antd/lib/date-picker/locale/ja_JP';
import moment from 'moment';
import 'moment/locale/ja';

const dateFormat = 'YYYY/MM/DD';

const CustomDatepicker = ({
            label,
            placeholder,
            className,
            fieldName,
            mode,
            rowField = true,
            style,
            labelWidth = 8,
            width = 24,
            offset = 0,
            locale,
            postLabel,
            half = false,
            mid = false,
            small = false,
            oneThird = false,
            oneFourth = false,
            form,
            onChange,
            form: { getFieldDecorator },
            decorator,
            hasClearBtn = false,
            btnReset,
            clearBtnMargin = 15,
            rangeSymbol,
        }) => {

        const formItemLayout = {
            labelCol: {  //for label width
                xs: { span: 24 },
                sm: { span: 8 },
                md: { span: 8},
                lg: { span: labelWidth },
            },
            wrapperCol: { //for input width
                xs: { span: 24 },
                sm: { span: 24 },
                md: { span: width, offset },
            },
        };
        let mainStyle = { display:'inline-block' };
        if(half){
            mainStyle.width = '50%';
        }else if(mid){
            mainStyle.width = '35%';
        }else if(oneThird){
            mainStyle.width = '33.3%';
        }else if(oneFourth){
            mainStyle.width = '25%';
        }else if(small){
            mainStyle.width = '15%';
        }else mainStyle = null;

        // const handleOnClear = (form, name) => {
        //     form.setFieldsValue({
        //         [name]: ''
        //     });
        // }

        const handleReset = (form, name) => {
            form.setFieldsValue({
                [name]: moment().add(3,'months')
            });
        }

        return (
            <Fragment>
                {rowField ? (
                    <Fragment>{rangeSymbol && <div className='range-symbol'>{rangeSymbol}</div>}
                        <Form.Item
                            {...formItemLayout}
                            label={label}
                            className={className}
                            style={mainStyle}>
                            {getFieldDecorator(fieldName, decorator)(
                                <DatePicker
                                    format={dateFormat}
                                    disabled={mode === 'delete' ? true : false}
                                    locale={jaJP}
                                    onChange={onChange}
                                    placeholder={placeholder}
                                    showToday={false}
                                    size="default"/>
                            )}
                            {postLabel && <label htmlFor={fieldName} style={{marginRight:10, marginLeft: 10}}>{postLabel}</label>}
                            {hasClearBtn && <Button disabled={mode === 'delete' ? true : false} style={{marginLeft:clearBtnMargin}} type="primary" onClick={() => handleReset(form, `${fieldName}`)}>{btnReset}</Button>}
                        </Form.Item>
                    </Fragment>) :(getFieldDecorator(fieldName, decorator)(
                    <span onChange={onChange}>
                        {label && <label htmlFor={fieldName} style={{width:labelWidth, display: 'inline-block'}}>{label}</label>}
                            {getFieldDecorator(fieldName, decorator)(
                                <DatePicker
                                    disabled={mode === 'delete' ? true : false}
                                    className={className}
                                    format={dateFormat}
                                    locale={jaJP}
                                    placeholder={placeholder}
                                    style={style}
                                    showToday={false}
                                    size="default"/>
                            )}
                        {postLabel && <label htmlFor={fieldName} style={{marginRight:10}}>{postLabel}</label>}
                        {hasClearBtn && <Button disabled={mode === 'delete' ? true : false} style={{marginLeft:clearBtnMargin}} type="primary" onClick={() => handleReset(form, `${fieldName}`)}>{btnReset}</Button>}
                    </span>
                ))
            }
        </Fragment>
    );
}

export default CustomDatepicker;