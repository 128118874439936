import { gql } from 'apollo-boost';

const getAll = gql`
    query OrdersTransports($filter: OrdersTransportFilter) {
        allOrdersTransports( filter: $filter,
        orderBy:[ORDER_BY_ORDER_ID__DISPATCH_STATUS_DESC,
                ORDER_BY_ORDER_ID__VEHICLE_ID_ASC,
                ORDER_BY_ORDER_ID__TRANSPORT_DATE_ASC,
                PICKUP_DATE_ASC,
                PICKUP_OPTION_ASC,
                PICKUP_HOUR_ASC,
                PICKUP_MINUTE_ASC,
                DROPOFF_HOUR_FROM_ASC,
                DROPOFF_MINUTE_FROM_ASC]
            ){
            nodes {
                id,
                orderId,
                transportNo,
                origin,
                pickupDate,
                pickupOption,
                pickupHour,
                pickupMinute,
                pickupActualDatetime,
                pickupMileage,
                destination,
                dropoffDate,
                dropoffOption,
                dropoffHourFrom,
                dropoffMinuteFrom,
                dropoffHourTo,
                dropoffMinuteTo,
                dropoffActualDatetime,
                dropoffMileage,
                orderByOrderId {
                    dispatchStatus
                }
            },
            totalCount
        }
    }
`;

export { getAll };