import React, { Fragment, Component } from 'react';
import { Input, Form } from 'antd';
class CustomConfirmPass extends Component {
    

    compareToFirstPassword = (rule, value, callback) => {
        const {form} = this.props;
        if (value && value !== (form.getFieldValue('newPassword') || form.getFieldValue('password'))) {
            callback('パスワードが一致しません。');
        } else {
            callback();
        }
    }

    validateToNextPassword = (rule, value, callback) => {
        const form = this.props.form;
        if (value) {
            process.nextTick(() => {
                form.validateFields(['confirm'], { force: true });
            })         
        }
        callback();
    }

    render() {
        
        const {
            form, 
            fieldName, 
            placeholderConfirmPassword, 
            placeholderPassword, 
            labelPassword, 
            labelConfirmPassword, 
            labelWidth = 4,
            half,
            oneThird = false,
            rule1,
            mode,
            width,
            offset,
            rule2 } = this.props
        const { getFieldDecorator } = form;


        let mainStyle = { display:'inline-block' };
        if(half){
            mainStyle.width = '50%';
        }else if(oneThird){
            mainStyle.width = '33.3%';
        }else mainStyle = null;

        const formItemLayout = {
            labelCol: {  //for label width
                xs: { span: 24 },
                sm: { span: 8 },
                md: { span: 5 },
                lg: { span: labelWidth },
            },
            wrapperCol: { //for input width
                xs: { span: 24 },
                sm: { span: 24 },
                md: { span: width, offset },
            },
        };

        return (
            <Fragment>
                <Form.Item
                    {...formItemLayout}
                    label={labelPassword}
                    style={mainStyle}
                    >
                    {getFieldDecorator(fieldName, {
                        rules: [{ ...rule1}, {
                            validator: this.validateToNextPassword,
                        }]
                    })(
                        <Input maxLength={16} disabled={mode === 'delete' ? true : false} type="password" placeholder={placeholderPassword} />
                    )}
                </Form.Item>
                <Form.Item
                    {...formItemLayout}
                    label={labelConfirmPassword}
                    style={mainStyle}
                    >
                    {getFieldDecorator('confirm', {
                        rules: [{ ...rule2 }, {
                            validator: this.compareToFirstPassword,
                        }]
                    })(
                        <Input maxLength={16} disabled={mode === 'delete' ? true : false} type="password"  placeholder={placeholderConfirmPassword} />
                    )}
                </Form.Item>
            </Fragment>
        )
    }
}

export default CustomConfirmPass
