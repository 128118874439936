import { Button, Col, Form, Row } from 'antd';
import _ from 'lodash';
import moment from 'moment';
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import ModalBase from '../../../commons/ModalBase';
import Month from "../../../commons/Picklist/Month";
import Year from "../../../commons/Picklist/Year";
import ActionHeader from '../../../commons/TableActions/ActionHeader';
import WithEventButton from '../../../commons/WithEventButton';
import { getAll } from '../../../queries/MasterMaintenance/driverleave';
import adhocTranslator from "../../../utils/locales/translate-adhoc";
import { leaveTypes } from '../../../utils/types';
import UserModalConfig from '../User/ListModal';

export default () => {
    let lt = adhocTranslator('K905-010');
    const dateFormat = 'YYYY/MM/DD';
    return {
        driverLeave: true,
        fetchQuery: getAll,
        allKey: 'allLeaves',
        perPage: 20,
        removeType: true,
        columns: [
            {
                title: lt('lbl_leave_date_header'),
                dataIndex: 'leaveDate',
                key: 'leaveDate',
                width: 120,
                align: 'center',
            }, {
                title: lt('lbl_driver_header'),
                dataIndex: 'fullNameJa',
                key: 'fullNameJa',
                width: 110,
                align: 'center',
            }, {
                title: lt('lbl_leave_category_header'),
                dataIndex: 'leaveCategory',
                key: 'leaveCategory',
                width: 110,
                align: 'center',
            }, {
                title: lt('lbl_reason_header'),
                dataIndex: 'reason',
                key: 'reason',
                width: 120,
                align: 'center',
            }, {
                title: lt('lbl_process_header'),
                key: 'lbl_process',
                width: 110,
                align: 'center',
                render: (text, record) => (
                    <span>
                        <Link to={{
                            pathname: `/driver-leave/update/${record.id}`,
                            state: { id: text.id }
                        }}>[ {lt('btn_edit')} ]</Link>
                        <Link to={{
                            pathname: `/driver-leave/delete/${record.id}`,
                            state: { id: text.id }
                        }}>[ {lt('btn_delete')} ]</Link>
                    </span>
                )

            }

        ],



        dataMapping: (item) => {
            const gsDayNames = [
                '(日)',
                '(月)',
                '(火)',
                '(水)',
                '(木)',
                '(金)',
                '(土)',
            ];
            var d = new Date(item.leaveDate);
            var dayName = gsDayNames[d.getDay()];

            return {
                id: item.id,
                userId: item.userId,
                fullNameJa: _.get(item.userByUserId, 'fullNameJa', ''),
                leaveDate: item.leaveDate && moment(item.leaveDate).format(dateFormat) + " " + dayName,
                leaveCategory: leaveTypes[item.leaveCategory],
                reason: item.reason
            }
        },

        searchHeader: ({ form, handleSearch, loading, clearRows }) => {
            const resetFields = () => {
                form.resetFields();
                handleSearch();
                clearRows(true)
            }

            return (
                <Fragment>
                    <span className="search-filter">{lt('hdr_search_conditions')}</span>
                    <Form onSubmit={handleSearch}>
                        <Row>
                            <Col span={12}>
                                <div className='ant-row ant-form-item'>
                                    {/*Oil distance*/}
                                    <Year
                                        form={form}
                                        fieldName='leaveDate_From_Year'
                                        label={lt('lbl_conditions_date_range')}
                                        postLabel={lt('lbl_conditions_from_year')}
                                        rowField={false}
                                        style={{ width: 100, display: 'inline-block', marginRight: 10 }}
                                        decorator={{
                                        }}
                                        labelWidth={130}
                                    />
                                    <Month
                                        form={form}
                                        fieldName='leaveDate_From_Month'
                                        postLabel={lt('lbl_conditions_from_month')}
                                        style={{ width: 80, display: 'inline-block', marginRight: 10 }}
                                        rowField={false}
                                        decorator={{
                                        }}
                                        labelWidth={80}
                                    />

                                    <Year
                                        form={form}
                                        fieldName='leaveDate_To_Year'
                                        postLabel={lt('lbl_conditions_to_year')}
                                        rowField={false}
                                        style={{ width: 100, display: 'inline-block', marginRight: 10 }}
                                        decorator={{
                                        }}
                                        labelWidth={120}
                                    />

                                    <Month
                                        form={form}
                                        fieldName='leaveDate_To_Month'
                                        postLabel={lt('lbl_conditions_to_month')}
                                        rowField={false}
                                        style={{ width: 80, display: 'inline-block', marginRight: 10 }}
                                        decorator={{
                                        }}
                                        labelWidth={120}
                                    />
                                </div>

                                <div className="ant-row ant-form-item">
                                    <ModalBase
                                        title='運転手情報検索'
                                        form={form}
                                        mode={'add'}
                                        label={lt('lbl_conditions_driver')}
                                        btnLabel={lt('選択')}
                                        fieldName='userId'
                                        rowField={false}
                                        labelWidth={130}
                                        config={UserModalConfig({ authority: 4 })}
                                        decorator={{
                                        }}
                                        isFilter={true}
                                        filterType='driver'
                                    />
                                </div>

                            </Col>
                            <Col span={12}> </Col>
                        </Row>
                        <Row type='flex' justify='space-around'>
                            <Col span={24} align='center'>
                                {/*Submit*/}
                                <WithEventButton
                                    event={handleSearch}
                                    label={lt('btn_conditions_search')}
                                    loading={loading}
                                    style={{ height: '38px', width: '104px', margin: '14px 14px 14px 0px' }}
                                />
                                <Button
                                    onClick={resetFields}
                                    type="primary"
                                    htmlType='button'
                                    loading={loading}
                                    style={{ height: '38px', width: '104px', margin: '14px 14px 14px 0px' }}
                                >
                                    {lt('btn_conditions_clear')}
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Fragment>
            );
        },
        actionHeader: () => {
            return (
                <Fragment >
                    <ActionHeader
                        rightActions={
                            <Fragment>
                                <Button
                                    type="primary"
                                    htmlType="button">
                                    <Link to="/driver-leave/register">{lt('btn_register_new_info')}</Link>
                                </Button>
                            </Fragment>
                        }
                    />
                </Fragment>
            )
        },
        minWidth: 'max-content'
    }
}