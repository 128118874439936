import { Button, Icon, Modal, notification, Popover, Row, Table, Typography } from 'antd';
import _ from 'lodash';
import moment from 'moment';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { withApollo } from 'react-apollo';
import { createDndContext, DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { withRouter } from 'react-router-dom';
import { OrderContext, TableContext } from '../../../../utils/context';
import adhocTranslator from '../../../../utils/locales/translate-adhoc';
import { dayTypes, timeTypes } from '../../../../utils/types';
import { columnTypes, dispatchTypes, tabTypes } from '../../constants';
import { useKeyPress } from '../../hooks';
import { GET_ORDER_INFO, UPDATE_ORDER, RESTORE_DELETED_RECORD, SOFT_DELETE_RECORD, INSERT_ORDER, UPDATE_DISPLAY_ORDER, UPDATE_ORDERS_MULTIPLE } from '../../queries';
import CellWrap from './cell-wrap';
import { constructOrdersPayload, constructTransportPayload } from './functions';
import DateModal from './modal/date-modal';
import RowWrap from './row-wrap';
import { fetchTypes } from '../../../OrdersAction/constants';
import { sendNotifications } from '../../../../queries/Orders';
import user_id from '../../../../utils/user_id';

const { confirm } = Modal;
let at = adhocTranslator('K030-020');
const messages = {
  add: "受注・配車割当情報を登録しますか？",
  edit: "受注・配車割当情報を更新しますか？",
  delete: "受注・配車割当情報を削除しますか？",
  restore: "受注・配車割当情報を復元しますか？",
  restore_success: "注文/発送が復元されました",
  restore_failure: "注文/発送の復元に失敗しました",
  reg_success: at("reg_success_message"),
  reg_error: at("reg_error_message"),
  edit_success: at("edit_success_message"),
  edit_failure: at("edit_failure_message"),
  delete_success: at("delete_success_message"),
  delete_failure: at("delete_failure_message")

};

const isOptionValue = (value) => ['AM', 'PM', 'DAY_TIME', 'EVENING'].indexOf(value) >= 0 ? timeTypes[value] : null;
const escortVehicles = ['ESCORT_VEHICLE_COMPANY_', 'ESCORT_VEHICLE_OUTSOURCE_', 'OUTSOURCE'];

const initialTransport = { origin: null, destination: null, destinationTime: null, originTime: null, };

const initialOrder = {
  class: 'lblue-row', remarks: null,
  packaging2: null, transports: [{ ...initialTransport }],
  added: false, minimized: false
}

const notify = (type, message) => {
  notification[type]({
    message: message,
  });
};

const floatingTypes = {
  [tabTypes.ORDERS]: ['edit', 'copy', 'inverse-copy', 'delete', 'row-above', 'row-below'],
  [tabTypes.DISPATCH]: ['edit', 'copy', 'inverse-copy', 'delete'],
  [tabTypes.PLANNED]: ['edit', 'copy', 'inverse-copy', 'delete', 'row-above', 'row-below']
}

const colorValues = {
  RED: 'text-red',
  GREEN: 'text-green',
  BLACK: 'text-black',
}

let activeRecord = null;
let activeIndex = null;
const TableView = (props) => {
  const { client } = props;
  const RNDContext = createDndContext(HTML5Backend);
  const manager = useRef(RNDContext);
  const { transportColorList, modal: { visible }, overlay, view, pageLoading, selected, editSave, editMode, orderList, vehicleList, orderSetter, modalSetter: orderModalSetter, isDriver, fetch, date, refetch } = useContext(OrderContext);
  const [state, setState] = useState({
    minimized: [],
    modal: {
      visible: false,
      key: null,
      transportIndex: null
    }
  });
  const [reverse, setReverse] = useState({
    visible: false,
    id: null
  });
  const [tableState, setTableState] = useState({
    columns: [],
    scroll: { y: 620, x: 1000, scrollToFirstRowOnChange: true },
    dataSource: null,
    current: 0,
  });

  const components = {
    body: {
      row: RowWrap,
      cell: CellWrap
    },
  };

  // Craete key press hooks
  const [highlighted, setHighlighted] = useState(0);
  const [floated, setFloated] = useState(false);
  const [action, setAction] = useState(0);

  const actionKey = useKeyPress();

  useEffect(() => {
    if (editMode) {
      tableSetter({ dataSource: _.cloneDeep(tableState.dataSource) })
    }
  }, [editMode]);

  useEffect(() => {
    if (editMode || visible || overlay) return;

    if (actionKey) {
      switch (actionKey) {
        case 'Tab':
          if (!visible && !overlay) {
            if (floated && highlighted) {
              const currentHighlighted = document.getElementById(`floating-${highlighted - 1}`);
              if (currentHighlighted) currentHighlighted.click();
            };
            tabHandler();
          }
          break;
        case 'Space': spaceHandler(); break;
        case 'Enter': enterHandler(); break;
        case 'ArrowLeft': arrowLeftHandler(); break;
        case 'ArrowRight': arrowRightHandler(); break;
        default:
      }
    }
  }, [actionKey]);

  const hideFloat = () => {
    // revert
    const currentHighlighted = document.getElementById(`floating-${highlighted - 1}`);
    if (currentHighlighted) currentHighlighted.click();
    activeRecord = null;
    activeIndex = null;
    setAction('edit');
    setFloated(false);
  };

  const clickHandler = (nextHighlight) => {
    // Set states
    const sourceList = _.cloneDeep(tableState.dataSource);
    if (!sourceList.length) return;
    // Set datasource highlights
    if (highlighted && sourceList[highlighted - 1]) sourceList[highlighted - 1].highlighted = false;
    sourceList[nextHighlight].highlighted = true;
    setHighlighted(nextHighlight + 1);

    setFloated(true);
    setAction('edit');
    tableSetter({ dataSource: sourceList });
  };

  /**
   * Handle tab key action
   */
  const tabHandler = () => {
    const sourceList = tableState.dataSource;
    if (!sourceList.length) return;
    const listCount = sourceList.length;
    const nextHighlight = highlighted === listCount ? 1 : highlighted + 1;

    // Set datasource highlights
    if (highlighted && sourceList[highlighted - 1]) sourceList[highlighted - 1].highlighted = false;
    if (sourceList[nextHighlight - 1]) sourceList[nextHighlight - 1].highlighted = true;

    setHighlighted(nextHighlight);
    setAction('edit');
    tableSetter({ dataSource: sourceList });
  }

  /**
   * Handle space key action
   */
  const spaceHandler = () => {
    if (!highlighted) return;

    // Scroll table body
    const tableBody = document.getElementsByClassName('ant-table-body')[0];
    if (tableBody.scrollLeft !== 1000) tableBody.scrollLeft = 1000;

    // Set clicked element
    const currentHighlighted = document.getElementById(`floating-${highlighted - 1}`);
    if (currentHighlighted) currentHighlighted.click();
    setFloated(!floated);
  }

  /**
   * Handle Enter key action
   */
  const enterHandler = () => {
    // invoke action selected
    if (!(highlighted && floated)) return;
    if (activeRecord && activeIndex) {
      action === 'edit' && onEditRow(activeRecord)();
      action === 'copy' && onCopyBelow(activeRecord)();
      action === 'inverse-copy' && onReverseCopy(activeRecord)();
      action === 'delete' && onDeleteRow(activeIndex, activeRecord)();
      action === 'row-above' && onInsertAbove(activeIndex, activeRecord)();
      action === 'row-below' && onInsertBelow(activeIndex, activeRecord)();
      // revert
      hideFloat();
    }
  }

  /**
   * Handle left arrow key action
   */
  const arrowLeftHandler = () => {
    // Move between icons
    if (!(highlighted && floated)) return;
    const floatingList = floatingTypes[view];
    const floatLength = floatingList.length - 1;

    // Get new index value
    const currentIndex = floatingList.findIndex((data) => data === action);
    const newFloatIndex = currentIndex === 0 ? floatLength : currentIndex - 1;
    setAction(floatingList[newFloatIndex]);
  }

  /**
   * Handle right arrow key action
   */
  const arrowRightHandler = () => {
    // Move between icons
    if (!(highlighted && floated)) return;
    const floatingList = floatingTypes[view];
    const floatLength = floatingList.length - 1;

    // Get new index value
    const currentIndex = floatingList.findIndex((data) => data === action);
    const newFloatIndex = (currentIndex === floatLength) ? 0 : currentIndex + 1;
    setAction(floatingList[newFloatIndex]);
  }

  const stateSetter = (value) => setState(state => ({ ...state, ...value }));
  const modalSetter = (value) => setState(state => ({ ...state, modal: { ...state.modal, ...value } }));
  const tableSetter = (value) => setTableState(state => ({ ...state, ...value }));

  const isMinimized = (key) => !!state.minimized.find((data) => data === key);

  const onCell = (field) => (record) => ({ field, record, editable: true, minimized: isMinimized(field) });

  const onRestoreRow = (index, record) => (e) => {
    e.preventDefault();
    e.stopPropagation();
    confirm({
      title: '確認',
      content: messages.restore,
      okText: 'はい',
      icon: null,
      cancelText: 'いいえ',
      onOk: async () => {
        try {     
          await client.mutate({
            mutation: RESTORE_DELETED_RECORD,
            variables: { 
              id: record.id,
            }
          });
          const { dataSource } = tableState;

          // Remove from list
          dataSource.splice(index, 1);
          tableSetter({ dataSource: _.cloneDeep(dataSource) });

          notify('success', messages.restore_success);
        } catch (err) {
          notify('error', messages.restore_failure);
        }
      },
      onCancel: () => { },
    });
  };

  const onEditRow = (record) => async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { id, vehicleUnused, vehicleId } = record;

    const { data: { allOrders: { nodes: orders }} } = await client.query({
      query: GET_ORDER_INFO,
      fetchPolicy: 'network-only',
      variables: {
        orderBy: ['CREATED_AT_ASC'],
        filter: {
          id: { equalTo: id }
        }
      }
    });

    if(orders.length) {
      const order = orders[0];
      if(order.isEditing && moment().diff(moment(order.lastEdited), 'seconds') < 300) {
        return notification.error({ message: '他のユーザーが、編集中です。' })
      }

      await client.mutate({
        mutation: UPDATE_ORDER,
        variables: {
          input: {
            orderPatch: {
              lastEdited: moment(),
              isEditing: true,
            }, 
            id
          }
        }
      });
    }

    hideFloat();
    if (vehicleUnused) {
      orderModalSetter({ visible: true, id: null, vehicle: vehicleId, mode: 'add' });
      return
    };
    orderModalSetter({ visible: true, id, vehicle: null, mode: 'edit', record });
  };

  const onCopyBelow = ({ id }) => (e) => {
    e.stopPropagation();
    e.preventDefault();
    hideFloat();
    orderModalSetter({ visible: true, id, mode: 'copy' })
  };

  const onReverseCopy = ({ id }) => (e) => {
    e.stopPropagation();
    e.preventDefault();
    hideFloat();
    setReverse({ visible: true, id });
  }

  const onModalReverseOk = (date) => {
    hideFloat();
    const { id } = reverse;
    orderModalSetter({ visible: true, id, mode: 'copy inverse', withdrawDate: date });
    setReverse({ visible: false, id: null });
  };

  const onDeleteRow = (index, record) => (e) => {
    e.stopPropagation();
    e.preventDefault();
    hideFloat();
    confirm({
      title: '確認',
      content: messages.delete,
      okText: 'はい',
      icon: null,
      cancelText: 'いいえ',
      onOk: async () => {
        try {
          const user = user_id()          
          await client.mutate({
            mutation: SOFT_DELETE_RECORD,
            variables: { 
              id: record.id,
              deleted_at: moment(),
              deleted_by: user.login_id
            }
          });
          const { dataSource } = tableState;

          const source = dataSource[index];
          if (source && source.driverId1 && source.dispatch === "配車" && source.transportDate === moment().format('YYYY-MM-DD')) {
            // Call api to process message sending
            await client.mutate({
              mutation: sendNotifications,
              variables: {
                orders: JSON.stringify([source]),
                is_type_1: false
              },
            });
          }

          // Remove from list
          dataSource.splice(index, 1);
          tableSetter({ dataSource: _.cloneDeep(dataSource) });

          notify('success', messages.delete_success);
        } catch (err) {
          notify('error', messages.delete_failure);
        }
      },
      onCancel: () => { },
    });
  };

  const insertOrder = async (record, sort) => {
    const userId = getUserId();
    const { vehicleId, orderDate, dateOption, transportDate, driverId1 } = record;
    const { data: { createOrder: { order } } } = await client.mutate({
      mutation: INSERT_ORDER,
      variables: {
        input: {
          order: {
            orderDate,
            dispatchStatus: 'PENDING', vehicleId,
            displayOrder: sort, transportDate,
            transportDateOption: dateOption,
            createdUserId: userId,
            updatedUserId: userId,
            driverId1
          }
        }
      }
    });

    orderSetter({ refetch: true });
    return order;
  };

  const onInsertAbove = (index, record) => async (e) => {
    e.preventDefault();
    e.stopPropagation();
    hideFloat();
    const { dataSource } = tableState;
    let { vehicleId, vehicle, dateOption, orderDate, transportDate, transportDisplay } = record;
    if (record.vehicleUnused) {
      orderDate = date ? date.format('YYYY-MM-DD') : null;
      dateOption = "SPECIFIED_DAY";
      orderDate = date ? date.format('YYYY-MM-DD') : null;
      transportDate = date ? date.format('YYYY-MM-DD') : null;
      transportDisplay = date ? date.format('MM/DD') : null;
      record.transportDate = date ? date.format('YYYY-MM-DD') : null;
      record.dateOption = "SPECIFIED_DAY";
      record.orderDate = date ? date.format('YYYY-MM-DD') : null;
    }
    const sourceBelow = record.displayOrder;
    const sourceAbove = dataSource[index - 1];
    const range = sourceAbove && sourceAbove.transportDate === transportDate ? sourceAbove.displayOrder : 0;
    const displayOrder = _.mean([range, sourceBelow]);
    const response = await insertOrder(record, _.floor(displayOrder));
    if (displayOrder === sourceBelow) {
      await updateAllDisplayOrder(record.transportDate, dataSource);
    }
    const driverId1 = record.driverId1;
    const driver1 = record.driver1;

    dataSource.splice(index, 0, {
      id: response.id, ...initialOrder, class: record.vehicleUnused ? "pink-row" : record.class, transportDate, edited: false,
      dispatch: dispatchTypes['PENDING'], vehicleId, vehicle, displayOrder: _.floor(displayOrder), dateOption, orderDate, transportDisplay, driverId1, driver1
    });
    tableSetter({ dataSource });
  };

  const onInsertBelow = (index, record) => async (e) => {
    e.preventDefault();
    e.stopPropagation();
    hideFloat();
    const { dataSource } = tableState;
    let { vehicleId, vehicle, dateOption, orderDate, transportDate, transportDisplay } = record;
    if (record.vehicleUnused) {
      orderDate = date ? date.format('YYYY-MM-DD') : null;
      dateOption = "SPECIFIED_DAY";
      orderDate = date ? date.format('YYYY-MM-DD') : null;
      transportDate = date ? date.format('YYYY-MM-DD') : null;
      record.transportDate = date ? date.format('YYYY-MM-DD') : null;
      transportDisplay = date ? date.format('MM/DD') : null;
      record.dateOption = "SPECIFIED_DAY";
      record.orderDate = date ? date.format('YYYY-MM-DD') : null;

    }

    const sourceAbove = record.displayOrder || 0;
    const sourceBelow = dataSource[index + 1] || {};
    const range = sourceBelow && sourceBelow.transportDate === transportDate ? sourceBelow.displayOrder : 0;
    const displayOrder = range ? _.mean([sourceAbove, range]) : sourceAbove + 1000;

    const response = await insertOrder(record, _.ceil(displayOrder));
    if (displayOrder === sourceAbove) {
      await updateAllDisplayOrder(record.transportDate, dataSource);
    }
    const driverId1 = record.driverId1;
    const driver1 = record.driver1;

    dataSource.splice(index + 1, 0, {
      id: response.id, ...initialOrder, class: record.vehicleUnused ? "pink-row" : record.class, transportDate, edited: false,
      dispatch: dispatchTypes['PENDING'], vehicleId, vehicle, displayOrder: _.ceil(displayOrder), dateOption, orderDate, transportDisplay, driverId1, driver1
    });
    tableSetter({ dataSource });
  };

  const FloatingActions = useCallback(({ index, record }) => {
    let activeAction = null;
    if (index === (highlighted - 1) && floated) {
      // Set actives
      activeRecord = { ...record }
      activeIndex = index;
      activeAction = action;
    }
    if (record.vehicleUnused) {
      return (
        <Row type='flex' style={{ justifyContent: 'space-between', display: 'inline-block' }}>
          <Button onClick={onEditRow(record)} className='floating-button active-btn'>
            <Icon type='edit' />
            <Typography>編集</Typography>
          </Button>
          {view !== tabTypes.DISPATCH && record.orderDate && (
            <>
              <Button onClick={onInsertAbove(index, record)} className={`floating-button ${activeAction === 'row-above' && 'active-btn'}`}>
                <Icon type='up' />
                <Typography>上に行追加</Typography>
              </Button>
              <Button onClick={onInsertBelow(index, record)} className={`floating-button ${activeAction === 'row-below' && 'active-btn'}`}>
                <Icon type='down' />
                <Typography>下に行追加</Typography>
              </Button>
            </>
          )}
        </Row>)
    }

    return (
      <Row type='flex' style={{ justifyContent: 'space-between', width: view !== tabTypes.DISPATCH && !editMode ? 460 : 310 }}>
        <Button onClick={onEditRow(record)} className={`floating-button ${activeAction === 'edit' && 'active-btn'}`}>
          <Icon type='edit' />
          <Typography>編集</Typography>
        </Button>
        <Button onClick={onCopyBelow(record)} className={`floating-button ${activeAction === 'copy' && 'active-btn'}`}>
          <Icon type='copy' />
          <Typography>コピー</Typography>
        </Button>
        <Button onClick={onReverseCopy(record)} className={`floating-button ${activeAction === 'inverse-copy' && 'active-btn'}`}>
          <Icon type='snippets' />
          <Typography>引上げコピー</Typography>
        </Button>
        <Button onClick={onDeleteRow(index, record)} className={`floating-button ${activeAction === 'delete' && 'active-btn'}`}>
          <Icon type='delete' />
          <Typography>削除</Typography>
        </Button>
        {view !== tabTypes.DISPATCH && !editMode && (
          <>
            <Button onClick={onInsertAbove(index, record)} className={`floating-button ${activeAction === 'row-above' && 'active-btn'}`}>
              <Icon type='up' />
              <Typography>上に行追加</Typography>
            </Button>
            <Button onClick={onInsertBelow(index, record)} className={`floating-button ${activeAction === 'row-below' && 'active-btn'}`}>
              <Icon type='down' />
              <Typography>下に行追加</Typography>
            </Button>
          </>
        )}
      </Row>
    )
  }, [highlighted, floated, view, action]);

  const onHeaderCell = (column) => {
    return {
      onDoubleClick: () => {
        const { key } = column;
        let { minimized } = state;

        minimized = isMinimized(key) ?
          minimized.filter((data) => data !== key) :
          [...minimized, key];

        stateSetter({ minimized });
      },
    };
  };

  const checkMinimized = (key) => isMinimized(key) ? {
    title: <Icon style={{ fontSize: 18 }} type='column-width' />,
    width: 50,
    dataIndex: 'minimized',
  } : {};

  const columns = useMemo(() => {
    return [
      ...columnTypes[isDriver ? `driver_${view}` : view](editMode, onCell, onHeaderCell, checkMinimized),
      {
        title: '処理', dataIndex: 'actions', key: 'actions', width: 80, align: 'center', className: 'vertical-align',
        render: (data, record, index) => {
          return (
            <Row className='floating-actions'>
              {view === tabTypes.DELETED ? 
                <Typography
                id={`floating-${index}`}
                style={{ color: '#0d47a0' }}
                onClick={onRestoreRow(index, record)}
              >
                [復元する]
              </Typography> : 
                isDriver ?
                <Typography
                  id={`floating-${index}`}
                  style={{ color: '#0d47a0' }}
                  onClick={onEditRow(record)}
                >
                  [処理]
                </Typography> :
                <Popover
                  placement='topRight'
                  trigger='click'
                  overlayClassName='floating-popover'
                  onClick={(e) => {
                    // e.preventDefault();
                    // e.stopPropagation();
                    clickHandler(index)
                  }}
                  onVisibleChange={(value) => setFloated(value)}
                  content={<FloatingActions record={record} index={index} />}
                >
                  <Typography id={`floating-${index}`} style={{ color: '#0d47a0' }}>[処理]</Typography>
                </Popover>
              }
            </Row>
          )
        }
      },
    ]
  }, [tableState.dataSource, state.minimized, editMode, view, floated, action, highlighted]);

  const columnsMap = () => columns.map(col => {
    let colData = col;
    if (col.editable) {
      colData = { ...col, onCell: record => ({ record, editable: col.editable }) }
    }
    return colData;
  });

  const getTextColor = (data, type) => {
    let textColor = 'text-green'
    let findData;

    const { origin, destination } = transportColorList

    if (type === 'origin' && origin.length) {
      findData = origin.find(pickup => {
        return pickup.nameJa === data.customer &&
          pickup.origin === data.origin &&
          pickup.pickupBorrower === data.pickupBorrower &&
          pickup.pickupAssignee === data.pickupAssignee
      })
    }

    if (type === 'destination' && destination.length) {
      findData = destination.find(dropoff => {
        return dropoff.nameJa === data.customer &&
          dropoff.destination === data.destination &&
          dropoff.dropoffBorrower === data.dropoffBorrower &&
          dropoff.dropoffAssignee === data.dropoffAssignee
      })
    }

    if (findData && findData.count > 1) {
      textColor = 'text-red'
    }

    return textColor
  }

  const checkTransportDisplay = orders => {
    const { transportDateOption, transportDate } = orders
    let formatted = ''

    if (!transportDate && transportDateOption === 'SPECIFIED_DAY') return formatted

    if (transportDateOption === 'SPECIFIED_DAY') {
      formatted = moment(transportDate).format('MM/DD')
    } else {
      formatted = dayTypes[transportDateOption]
    }

    return formatted
  }

  const getCompletedBackground = order => {
    const { transportDate } = order
    let colorClassname = 'lblue-row'

    if (order.overnight === 'YES' && order.transportDate !== (date || {}).format('YYYY-MM-DD')) {
      colorClassname = 'yellow-row';
    }
    if (transportDate && fetch === fetchTypes.FUTURE && moment(transportDate).isAfter(moment(date))) {
      colorClassname = 'blue-row'
    }

    return colorClassname
  }

  const checkMinute = minute => minute === 0 ? '00' : minute

  const generateTextColor = color => {
    return colorValues[color] || null;
  };

  useEffect(() => {
    if (!orderList || !vehicleList || refetch) return
    (async () => {
      let dataSource = _.map(orderList || [], (orders, index) => {

        const {
          customerByCustomerId = {},
          packagingByPacking1 = {},
          userByDriverId1 = {},
          userByDriverId2 = {},
          vehicleByVehicleId = {},
          ordersTransportsByOrderId: transportations,
        } = orders;
        let escortList = [], escortFare = 0;
        const transports = transportations.nodes.length ? _.map(_.orderBy(transportations.nodes, 'transportNo'), (transport) => {
          const { origin, destination, dropoffHourFrom, pickupHour, pickupHourTo, dropoffMinuteFrom, pickupMinute, pickupMinuteTo, ordersEscortVehiclesByTransportId,
            pickupOption, dropoffOption, dropoffHourTo, dropoffMinuteTo, pickupMemo, dropoffMemo, originColor, destinationColor } = transport;
          const textOriginColor = _.includes(origin, 'Ｐ') ? 'text-black' : generateTextColor(originColor) || getTextColor({ ...transport, customer: (customerByCustomerId || {}).nameJa }, 'origin')
          const textDestinationColor = _.includes(destination, 'Ｐ') ? 'text-black' : generateTextColor(destinationColor) || getTextColor({ ...transport, customer: (customerByCustomerId || {}).nameJa }, 'destination')
          const originStyles = `${pickupMemo ? '' : 'text-bold'} ${textOriginColor}`;
          const destinationStyles = `${dropoffMemo ? '' : 'text-bold'} ${textDestinationColor}`;

          if (ordersEscortVehiclesByTransportId.nodes.length) {
            const dataList = ordersEscortVehiclesByTransportId.nodes;
            let transportEscorts = dataList.map(data => {
              const vehicle = vehicleList.find(vehicle => vehicle.id === data.vehicleId);
              if (!vehicle) return null;
              escortFare = escortFare + data.transportBillingAmount
              return vehicle.vehicleNo;
            });
            escortList.push(..._.compact(transportEscorts));
          };
          const dest = [], orig = [];
          dest.push(dropoffHourFrom !== null ? `${dropoffHourFrom}:${checkMinute(dropoffMinuteFrom)}` : '')
          dest.push(dropoffHourTo !== null ? `${dropoffHourTo}:${checkMinute(dropoffMinuteTo)}` : '');

          orig.push(pickupHour !== null ? `${pickupHour}:${checkMinute(pickupMinute)}` : '')
          orig.push(pickupHourTo !== null  ? `${pickupHourTo}:${checkMinute(pickupMinuteTo)}` : '');
          return {
            ...transport,
            originStyles, destinationStyles,
            originColor: textOriginColor,
            destinationColor: textDestinationColor,
            // originTime: pickupHour ? `${pickupHour}:${checkMinute(pickupMinute)}` : isOptionValue(pickupOption),
            originTime: pickupHour === null && pickupHourTo === null ? isOptionValue(pickupOption) : orig.join('〜'),
            destinationTime: dropoffHourFrom === null && dropoffHourTo === null ? isOptionValue(dropoffOption) : dest.join('〜'),
          }
        }) : [{ ...initialTransport }];
        const className = orders.isColored ? 'dark-blue' : orders.dispatchStatus === 'PENDING' ? 'pink-row' : getCompletedBackground(orders);
        const { ordersConnectedsByOrderId1, ordersConnectedsByOrderId2 } = orders;
        let connectionData = {};
        let hasConnection = false;
        if (ordersConnectedsByOrderId1.nodes && ordersConnectedsByOrderId1.nodes.length) {
          let escortTotal = null;
          const { id, escortConnected, connectionId, totalBilling, totalDistance, orderByOrderId1, orderByOrderId2 } = ordersConnectedsByOrderId1.nodes[0];
          const { ordersTransportsByOrderId: { nodes: transport1 }, ordersEscortVehiclesByOrderId: { nodes: escort1 } } = orderByOrderId1;
          const { ordersTransportsByOrderId: { nodes: transport2 }, ordersEscortVehiclesByOrderId: { nodes: escort2 } } = orderByOrderId2;
          if (escortConnected && (escort1.length || escort2.length)) {
            escortTotal = _.sumBy(escort1, 'transportBillingAmount') + _.sumBy(escort2, 'transportBillingAmount');
          }
          let isCompleted = false;
          if (transport1.length && transport2.length) {
            const [data1] = transport1;
            const [data2] = transport2;
            if (data1 && data2 && data1.pickupActualDatetime && data1.dropoffActualDatetime &&
              data2.pickupActualDatetime && data2.dropoffActualDatetime) {
              isCompleted = true
            }
          }
          connectionData = {
            orderConnectionId: id,
            connectionId,
            totalBilling,
            totalDistance,
            escortConnected,
            isFirst: true,
            isCompleted,
            escortTotal
          };
          hasConnection = true;
        }
        
        if ((ordersConnectedsByOrderId2 || {}).nodes && ((ordersConnectedsByOrderId2 || {}).nodes || []).length) {
          let escortTotal = null;
          const { id, escortConnected, connectionId, totalBilling, totalDistance, orderByOrderId1, orderByOrderId2 } = ordersConnectedsByOrderId2.nodes[0];
          const { ordersTransportsByOrderId: { nodes: transport1 }, ordersEscortVehiclesByOrderId: { nodes: escort1 } } = orderByOrderId1;
          const { ordersTransportsByOrderId: { nodes: transport2 }, ordersEscortVehiclesByOrderId: { nodes: escort2 } } = orderByOrderId2;
          if (escortConnected && (escort1.length || escort2.length)) {
            escortTotal = _.sumBy(escort1, 'transportBillingAmount') + _.sumBy(escort2, 'transportBillingAmount');
          }
          let isCompleted = false;
          if (transport1.length && transport2.length) {
            const [data1] = transport1;
            const [data2] = transport2;
            if (data1 && data2 && data1.pickupActualDatetime && data1.dropoffActualDatetime &&
              data2.pickupActualDatetime && data2.dropoffActualDatetime) {
              isCompleted = true
            }
          }
          connectionData = {
            orderConnectionId: id,
            connectionId,
            totalBilling,
            totalDistance,
            escortConnected,
            isFirst: false,
            isCompleted,
            escortTotal
          };
          hasConnection = true
        };

        return {
          highlighted: (index + 1) === highlighted,
          edited: false, id: orders.id, class: className,
          displayOrder: orders.displayOrder, dateOption: orders.transportDateOption,
          orderDate: orders.orderDate,
          escortFare,
          ...connectionData,
          hasConnection: hasConnection,
          transportDate: orders.transportDate,
          transportDisplay: checkTransportDisplay(orders),
          dispatch: dispatchTypes[orders.dispatchStatus],
          customerId: orders.customerId,
          customerUnitPriceId: (customerByCustomerId || {}).transportationUnitPriceId,
          escortUnitPriceId: (customerByCustomerId || {}).escortVehicleUnitPriceId,
          customer: (customerByCustomerId || {}).nameJa,
          packagingId1: orders.packing1,
          packaging1: (packagingByPacking1 || {}).packagingName,
          packagingWeight: (packagingByPacking1 || {}).weight,
          packaging2: orders.packing2,

          vehicleId: orders.vehicleId,
          vehicle: (vehicleByVehicleId || {}).vehicleNo,

          driverId1: orders.driverId1,
          driverId2: orders.driverId2,
          driver1: (userByDriverId1 || {}).shortNameJa,
          driver2: (userByDriverId2 || {}).shortNameJa,

          distance: orders.transportDistance ? `${orders.transportDistance.toLocaleString()}km` : null,
          billingAmount: orders.transportBillingAmount ? `${orders.transportBillingAmount.toLocaleString()}円` : null,
          paymentAmount: orders.transportPaymentAmount ? `${orders.transportPaymentAmount.toLocaleString()}円` : null,
          remarks: orders.notes,
          transports, escortList,
          minimized: true,
          userId: orders.updatedUserId,
          isColored: orders.isColored,
          isDeleted: orders.isDeleted,
          deletedBy: orders.deletedBy,
          deletedAt: orders.deletedAt ? moment(orders.deletedAt).format('YYYY/MM/DD HH:mm'): null
        }
      });

      setTimeout(() => {
        tableSetter({ dataSource: view === tabTypes.PLANNED ? dataSource.filter(order => !!order.customerId) : dataSource });
        orderSetter({ pageLoading: false })
      }, 0)
    })();
  }, [refetch, orderList, vehicleList, transportColorList]);

  const updateDisplayOrder = async (id, displayOrder) => {
    await client.mutate({
      mutation: UPDATE_DISPLAY_ORDER,
      variables: {
        id, displayOrder
      }
    });
  };

  const updateAllDisplayOrder = async (transportDate, dataSource) => {
    const dataList = dataSource.filter(data => data.transportDate === transportDate);
    const updates = dataList.map(async (data, index) => {
      await updateDisplayOrder(data.id, (index + 1) * 1000);
    });

    await Promise.all(updates);

    orderSetter({ refetch: true });
  };

  const onMoveRow = useCallback((dragIndex, hoverIndex, mode) => {
    if (dragIndex === hoverIndex) return;
    const { dataSource } = tableState;
    const [data] = dataSource.splice(dragIndex, 1);
    const aboveData = (dataSource[hoverIndex - 1] || {});
    const sourceBelow = dataSource[hoverIndex];
    const sourceAbove = aboveData.displayOrder && aboveData.transportDate === data.transportDate ? (aboveData.displayOrder || 0) : 0;

    const range = sourceBelow && sourceBelow.transportDate === data.transportDate ? sourceBelow.displayOrder : 0;
    const displayOrder = Math.ceil(range ? _.mean([sourceAbove, range]) : sourceAbove + 1000);
    data.displayOrder = displayOrder;
    dataSource.splice(hoverIndex, 0, data);

    if (displayOrder === 1 || displayOrder === range) {
      data.displayOrder = _.mean([sourceAbove, range]);
      // Update all and refetch
      updateAllDisplayOrder(data.transportDate, dataSource);
      return;
    } else {
      // Check if display Order is 1
      updateDisplayOrder(data.id, displayOrder);
    }

    tableSetter({ dataSource });
  }, [tableState.dataSource]);

  const getUserId = () => {
    const token = localStorage.getItem('token');
    const { user_id } = JSON.parse(atob(token.split('.')[1]));
    return user_id;
  };

  useEffect(() => {
    setHighlighted(0);
    setFloated(false);
    setAction('edit');
    activeRecord = null;
    activeIndex = null;
  }, [view]);

  useEffect(() => {
    (async () => {
      if (editSave) {
        const sources = tableState.dataSource.filter(source => source.edited);
        const sourcesEdited = sources.filter(source => {
          const getEditedTransport = source.transports.map(keySource => {
            return !Object.keys(keySource).some(k => keySource[k]);
          });
          return source.edited && source.vehicleUnused && !source.packaging1 && !source.packagingId1 && !source.remarks && !source.customer && !source.customerId && getEditedTransport[0]
        });
        orderSetter({ editSave: false });
        const finalSource = _.difference(sources, sourcesEdited);
        if (!finalSource.length) return;
        const orderPayload = [];
        const transportPayload = { addList: [], updateList: [], deleteList: [] };
        _.each(sources, source => {
          constructOrdersPayload(source, orderPayload);
          constructTransportPayload(source, transportPayload);
        });
        try {
          await client.mutate({
            mutation: UPDATE_ORDERS_MULTIPLE,
            variables: {
              payload: JSON.stringify({ orderPayload, transportPayload, userId: getUserId() })
            }
          });
          notify('success', messages.edit_success);
        } catch (err) {
          notify('error', messages.edit_failure);
        }
        orderSetter({ refetch: true });
      }
    })()
  }, [editSave]);

  const onRowHover = (index) => {
    if (floated || editMode) return;

    if (highlighted) {
      if (floated && highlighted) {
        const currentHighlighted = document.getElementById(`floating-${highlighted - 1}`);
        if (currentHighlighted) currentHighlighted.click();
      };

      const sourceList = _.cloneDeep(tableState.dataSource);
      if (!sourceList.length) return;
      if (sourceList[highlighted - 1]) sourceList[highlighted - 1].highlighted = false;

      setAction('edit');
      setFloated(false);
      setHighlighted(index);
      tableSetter({ dataSource: sourceList });
    }
  };

  const rowClick = (record) => {
    if(view === tabTypes.DELETED) return;

    if (!(actionKey === 'Space' || actionKey === 'Tab' || actionKey === 'Enter' || editMode)) {
      const selectedRecords = [...selected];
      const recordIndex = selectedRecords.findIndex(data => data.id === record.id);

      // Set selected
      recordIndex >= 0 ? selectedRecords.splice(recordIndex, 1) : selectedRecords.push(record);
      orderSetter({ selected: selectedRecords });
    }
  }

  const rowSelection = view === tabTypes.DELETED ? null : {
    selectedRowKeys: selected.map(data => data.id),
    onChange: (selectedRowKeys, selectedRows) => orderSetter({ selected: selectedRows })
  };

  const onTableChange = (page) => tableSetter({ current: page.current });

  const pagination = useMemo(() => {
    return {
      total: (tableState.dataSource || []).length,
      pageSize: 100,
      current: tableState.current,
      position: 'bottom',
      showTotal: (total, range) => `検索結果　${total} 件中：${range[0]} - ${range[1]} 件を表示しています。`
    }
  }, [tableState.current, tableState.dataSource]);

  useEffect(() => {
    const elements = document.getElementsByClassName('ant-checkbox-input');
    _.each(elements, element => {
      element.setAttribute("tabIndex", "-1");
    });
  }, [tableState.dataSource]);

  const getTableProps = useCallback(() => {
    const defaultProps = {
      id: 'table',
      rowKey: 'id',
      components,
      className: `order-table ${highlighted ? 'no-highlight' : ''}`,
      columns: columnsMap(),
      pagination,
      rowClassName: (record, index) => record.highlighted ? 'highlighted' : record.class
    }
    if (isDriver) {
      return { ...tableState, ...defaultProps }
    } else {
      return {
        ...tableState,
        ...defaultProps,
        rowSelection,
        onChange: onTableChange,
        onRow: (record, rowIndex) => ({ record, rowIndex, onMoveRow, onClick: (e) => rowClick(record), onHover: () => onRowHover(rowIndex + 1) })
      }
    }
  }, [tableState.dataSource, tableState, isDriver, highlighted, components, pagination])

  useEffect(() => { 
    if(pageLoading) {
      tableSetter({
        dataSource: []
      })
    }
  }, [pageLoading]);

  return (
    <TableContext.Provider value={{ ...state, modalSetter }}>
      <Row id='unselect-area' style={{ marginTop: 10 }}>
        <DndProvider manager={manager.current.dragDropManager}>
          <Table {...getTableProps()} />
        </DndProvider>
      </Row>
      {reverse.visible && (
        <DateModal
          visible={reverse.visible}
          onOk={onModalReverseOk}
          onCancel={() => setReverse({ visible: false, id: null })}
        />
      )}
    </TableContext.Provider>
  )
};

export default withApollo(withRouter(TableView));