import React, { Component, Fragment } from 'react';
import { Form, Row, Col, Modal, notification } from 'antd';
import {  CustomInput, CustomButton, CustomConfirmPass } from '../../commons/CustomDataEntry';
import { withRouter } from 'react-router';
import { graphql, compose } from 'react-apollo';

import withTranslation from '../../utils/locales/translator';
import { changePasswordConfirmation } from '../../queries/Users';
import { changeCustomerPasswordConfirmation } from '../../queries/Customer';


class ChangePassword extends Component {
    
	state = {
		loading: false
	}

	handleLoading = (loading) => {
		this.setState({ loading });
	}

	openNotificationWithIcon = (type, title) => {
	  notification[type]({
	    message: title,
	    description: '',
	  });
	};
	
	handleSubmit = (e) => {
		e.preventDefault();
		this.handleLoading(true)
		this.props.form.validateFields((err, values) => {
			if (!err) {
				Modal.confirm({
					title: 'パスワードを変更しますか？',
					icon: 'false',
					content: '',
					okText: 'はい',
					cancelText: 'いいえ',
					onCancel: this.handleLoading(false),
					onOk: async () => {
						try {
							const variables = { variables: {
								newPassword: values.newPassword,
								currentPassword: values.currentPassword,
								userId: this.props.location.state.id
							}};
							const token = localStorage.getItem('token');
					        if (token) {
					            const decodeToken = JSON.parse(atob(token.split('.')[1]));
					            if(decodeToken.role === 'app_customer') {
					                await this.props.customerPassword(variables);
					            } else {
					            	await this.props.userPassword(variables);
					            }
					        }
							this.handleLoading(false);
							this.openNotificationWithIcon('success','パスワード変更が完了しました。');
							this.props.history.push('/');
						} catch (err) {
							this.openNotificationWithIcon('error','現在のパスワードが違います。');
						}
					}  
				});
			}else{
				this.handleLoading(false);
			}
		});
	}

	mutate = () => {

	}

	render() {
		const {lt, form} = this.props;
		return (
			<Fragment>
				<Row type='flex' justify='space-around' align='middle'>
					<Col span={24} >
						<Form onSubmit={this.handleSubmit} layout="vertical">
							<CustomInput
								form={form} fieldName='currentPassword'
								label={lt('lbl_current_password')}
								type='password'
								width={8}
								maxLength={16}
								decorator={{
									rules: [{
										required: true,
										message: lt('現在のパスワードを入力してください。'),
									}]
								}}
							/>
							<CustomConfirmPass
								form={form}
								maxLength={16}
								fieldName='newPassword'
								labelPassword={lt('lbl_new_password')}
								labelConfirmPassword={lt('lbl_confirm_new_password')}
								rule1={{required: true, message: lt('psw_password') }}
								rule2={{required: true, message: lt('psw_confirm_password') }}
								width={8}
							/>
			
							<CustomButton 
								form={form} 
								label={lt('btn_update')}
								loading={this.state.loading} 
								offset={7} 
								htmlType='submit'
							/>
						</Form>
					</Col>
				</Row>
			</Fragment>
		);
 	}
}

const withChangePasswordMutate = compose(
		graphql(changePasswordConfirmation,{name:"userPassword"}), 
		graphql(changeCustomerPasswordConfirmation,{name:"customerPassword"})
	);

export default withChangePasswordMutate(withRouter(Form.create()(withTranslation(ChangePassword, 'K010-020'))));
