import React, { Component, Fragment } from 'react';
import { withApollo } from 'react-apollo';
import moment from 'moment';
import TableToExcel from '@linways/table-to-excel';
import uuid from 'uuid/v4';

const estimateType = `
    excelEstimateOutputData {
        id
        key
        createdAt
        customerNameJa
        packagingName
        transportationFare
        escortVehicle
        totalAmount
        estimateNumber
        dateOfIssue
        customerName
        companyName
        quotationAmount
        validity
        rows {
            por
            packaging
            distance
            amount
            notes
            inhouseEscortCount
            outsourceEscortCount
            escortFee
            transportFee
        }
        subtotal
        tax
        totalamount
    }
`;

class EstimateExcel extends Component {

    constructor(props) {
        super(props);
        this.excel = {}
    };

    formatNumber = (number) => {
        var nf = new Intl.NumberFormat();
        return nf.format(number);
    }


    render() {

        const createExcel = (row) => {
            const i = row.id;
            let idvalue = "Output" + i;
            let details = row.rows.map((detail, i) => {

                let rows = []
                let transportRow = (
                    <tr data-height="24" key={uuid()}>
                        <td></td>
                        <td colSpan="2" data-b-l-s="thin" data-a-v="middle" data-b-b-s="dotted" >{detail.por}</td>
                        <td data-b-l-s="thin" data-a-v="middle" data-b-b-s="dotted" >{detail.packaging}</td>
                        <td data-b-l-s="thin" data-a-v="middle" data-b-b-s="dotted" data-a-h="right">{this.formatNumber(detail.distance)}km</td>
                        <td data-a-h="right" data-a-v="middle" data-b-l-s="thin" data-b-b-s="dotted" >{(detail.transportFee).toLocaleString()}</td>
                        <td data-b-l-s="thin" data-a-v="middle" data-b-b-s="dotted" data-b-r-s="thin">{detail.notes}</td>
                    </tr>
                );

                if (detail.inhouseEscortCount || detail.outsourceEscortCount) {
                    let escortRow = (
                        <tr data-height="24" key={uuid()}>
                            <td></td>
                            <td colSpan="2" data-b-l-s="thin" data-a-v="middle" data-b-b-s="dotted" >誘導{detail.inhouseEscortCount + detail.outsourceEscortCount}台</td>
                            <td data-b-l-s="thin" data-a-v="middle" data-b-b-s="dotted" ></td>
                            <td data-b-l-s="thin" data-a-v="middle" data-b-b-s="dotted" data-a-h="right">{this.formatNumber(detail.distance)}km</td>
                            <td data-a-h="right" data-a-v="middle" data-b-l-s="thin" data-b-b-s="dotted" >{detail.escortFee.toLocaleString()}</td>
                            <td data-b-l-s="thin" data-a-v="middle" data-b-b-s="dotted" data-b-r-s="thin"></td>
                        </tr>
                    );
                    rows.push(escortRow);
                }

                rows.push(transportRow);

                return rows
            });

            for (var j = 16 - details.length * 2; j >= 0; j--) {
                let row = (<tr data-height="24" key={j}>
                    <td></td>
                    <td colSpan="2" data-b-l-s="thin" data-b-b-s="dotted"></td>
                    <td data-b-l-s="thin" data-b-b-s="dotted"></td>
                    <td data-b-l-s="thin" data-b-b-s="dotted"></td>
                    <td data-b-l-s="thin" data-b-b-s="dotted"></td>
                    <td data-b-l-s="thin" data-b-b-s="dotted" data-b-r-s="thin"></td>
                </tr>);
                details.push(row)
            }
            return <Fragment key={uuid()}>
                <table id={idvalue} data-cols-width="1.90,10,30,21,7,10,22">
                    <thead>
                        <tr data-height="15">
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td colSpan="2"></td>
                        </tr>
                        <tr data-height="15">
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td data-a-h="right" data-a-v="middle" colSpan="2">
                                見積番号：{row.estimateNumber}
                            </td>
                        </tr>
                        <tr data-height="15">
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td data-a-h="right" data-a-v="middle" colSpan="2">
                                発行日：{moment().format("YYYY年MM月DD日")}
                            </td>
                        </tr>
                        <tr data-height="15">
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td colSpan="2"></td>
                        </tr>
                        <tr data-height="27">
                            <td></td>
                            <td colSpan="6" data-f-bold="true" data-a-v="middle" data-a-h="center" data-f-sz="26">御　見　積　書</td>
                        </tr>
                        <tr data-height="25">
                            <td></td>
                            <td colSpan="5"></td>
                        </tr>
                        <tr data-height="25">
                            <td></td>
                            <td data-f-sz="16" colSpan="2" data-a-h="center" data-b-b-s="thin" data-a-v="middle" style={{ padding: '10px' }}>
                                {row.customerName} &nbsp;&nbsp;御中
                        </td>
                            <td></td>
                            <td></td>
                            <td data-a-h="left" data-a-v="middle" colSpan="2">一般区域貨物運送事業</td>
                        </tr>
                        <tr data-height="15">
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td data-a-h="left" data-a-v="middle" colSpan="2" data-f-sz="14">有限会社黒潮重機興業</td>
                        </tr>
                        <tr data-height="15">
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr data-height="15">
                            <td></td>
                            <td data-a-v="middle" colSpan="2">下記の通り、お見積り申し上げます。</td>
                            <td></td>
                            <td></td>
                            <td data-a-h="left" data-a-v="middle" colSpan="2" data-f-sz="10">〒985-0844</td>
                        </tr>
                        <tr data-height="15">
                            <td></td>
                            <td data-a-v="middle" colSpan="2">ご検討のほど、よろしくお願いいたします。</td>
                            <td></td>
                            <td></td>
                            <td data-a-v="middle" data-a-h="left" colSpan="2" data-f-sz="10">宮城県多賀城市宮内一丁目13番13号</td>
                        </tr>
                        <tr data-height="15">
                            <td></td>
                            <td rowSpan="2" colSpan="2" data-a-v="middle" data-a-h="center" data-b-a-s="thick" data-f-sz="16">合計金額￥　{row.totalamount} 円</td>

                            <td></td>
                            <td></td>
                            <td data-a-v="middle" data-a-h="left" colSpan="2">TEL:022(367)5288 FAX:022(367)5289&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
                        </tr>
                        <tr data-height="15">
                            <td></td>
                            <td></td>
                            <td></td>
                            <td colSpan="2"></td>
                        </tr>
                        <tr data-height="15">
                            <td></td>
                            <td colSpan="5"></td>
                        </tr>
                        <tr data-height="11.5">
                            <td></td>
                            <td data-a-v="middle" colSpan="3" rowSpan="2" data-a-h="center" data-b-b-s="double" data-f-sz="12">本見積書有効期限&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;発行日より１ヶ月</td>
                            <td colSpan="2"></td>
                        </tr>
                        <tr data-height="11.5">
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr data-height="15">
                            <td></td>
                            <td colSpan="5"></td>
                        </tr>
                        <tr data-height="24">
                            <td></td>
                            <td colSpan="2" data-a-h="center" data-a-v="middle" data-fill-color="D8D8D8" data-f-bold="true" data-b-a-s="thin" style={{ padding: '10px' }}>発地～着地</td>
                            <td data-a-h="center" data-a-v="middle" data-fill-color="D8D8D8" data-f-bold="true" data-b-a-s="thin" style={{ padding: '10px' }}>荷姿</td>
                            <td data-a-h="center" data-a-v="middle" data-fill-color="D8D8D8" data-f-bold="true" data-b-a-s="thin" style={{ padding: '10px' }}>距離</td>
                            <td data-a-h="center" data-a-v="middle" data-fill-color="D8D8D8" data-f-bold="true" data-b-a-s="thin" style={{ padding: '10px' }}>金額</td>
                            <td data-a-h="center" data-a-v="middle" data-fill-color="D8D8D8" data-f-bold="true" data-b-a-s="thin" style={{ padding: '10px' }}>備考</td>
                        </tr>
                        {details}
                        <tr data-height="24">
                            <td></td>
                            <td colSpan="2" data-b-b-s="thin" data-b-l-s="thin" data-b-r-s="thin"></td>
                            <td data-b-b-s="thin" data-b-l-s="thin" data-b-r-s="thin"></td>
                            <td data-b-b-s="thin" data-b-l-s="thin" data-b-r-s="thin"></td>
                            <td data-b-b-s="thin" data-b-l-s="thin" data-b-r-s="thin"></td>
                            <td data-b-b-s="thin" data-b-l-s="thin" data-b-r-s="thin"></td>
                        </tr>
                        <tr>
                            <td colSpan="6"></td>
                        </tr>
                        <tr data-height="25.5" rowSpan="3">
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td data-fill-color="D8D8D8" data-a-h="left" data-a-v="middle" data-b-l-s="thin" data-b-t-s="thin" data-b-b-s="dotted" style={{ padding: '10px' }}>小計</td>
                            <td data-a-h="right" data-b-l-s="thin" data-a-v="middle" data-b-b-s="dotted" data-b-r-s="thin" data-b-t-s="thin">{row.subtotal.toLocaleString()}</td>
                            <td></td>
                        </tr>
                        <tr data-height="25.5">
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td data-fill-color="D8D8D8" data-a-h="left" data-a-v="middle" data-b-l-s="thin" data-b-t-s="dotted" data-b-b-s="dotted" style={{ padding: '10px' }}>消費税</td>
                            <td data-a-h="right" data-b-l-s="thin" data-a-v="middle" data-b-b-s="dotted" data-b-r-s="thin">{row.tax.toLocaleString()}</td>
                            <td></td>
                        </tr>
                        <tr data-height="25.5">
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td data-fill-color="D8D8D8" data-a-h="left" data-a-v="middle" data-b-l-s="thin" data-b-t-s="dotted" data-b-b-s="thin" style={{ padding: '10px' }}>合計</td>
                            <td data-a-h="right" data-b-l-s="thin" data-a-v="middle" data-b-b-s="thin" data-b-r-s="thin">{row.totalamount.toLocaleString()}</td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </Fragment>
        };

        let { data } = this.props;

        const excels = data.map((row) => {
            return createExcel(row);
        });

        setTimeout(() => {
            for (var i = data.length - 1; i >= 0; i--) {

                let row = data[i];
                let idname = "Output" + row.id;
                TableToExcel.convert(document.getElementById(idname), {
                    name: "見積書_" + row.customerName + "_" + moment(row.createdAt).format("YYYYMMDD") + ".xlsx",
                    sheet: {
                        name: "Sheet 1"
                    },
                    pageSetup: {
                        orientation: 'portrait',
                        paperSize: 9,
                        margins: {
                            left: 0.1, right: 0.1,
                            top: 0.39, bottom: 0.39,
                            header: 0.20, footer: 0.20
                        },
                    }
                });
            }
            this.props.client.writeData({
                data: {
                    excelOutput: {
                        __typename: 'excelOutput',
                        excelEstimateOutputData: []
                    }
                }
            });
        }, 0);

        return (<Fragment>{excels}</Fragment>);
    }
}

const EstimateExcelWrap = withApollo(EstimateExcel);

export { EstimateExcelWrap, estimateType };