import { gql } from 'apollo-boost';

const getAll = gql`
query AllEstimates ($first: Int, $offset:Int, $filter: EstimateFilter) {
  allEstimates(first:$first,offset:$offset,filter:$filter) {
    nodes {
      id,
      estimateDate,
      estimateNo,
      customerNameJa,
      transportationUnitPriceId,
      escortVehicleUnitPriceId,
      subcontractedEscortVehicleUnitPriceId,
      subtotal,
      consumptionTax,
      totalAmount,
      status,
      createdUserId,
      createdAt,
      estimatesDetailsByEstimateId {
        nodes {
          id,
          estimateId,
          sequenceNo,
          escortVehicleCount,
          outsourceEscortVehicleCount,
          originDestinationLocation,
          packing1,
          transportDistance,
          transportFee,
          notes,
          escortVehicleFee,
          packagingByPacking1 {
            packagingName,
            weight
          }
        }
      }
    },
    totalCount
  }
}`;

const create = gql`
mutation createEstimate(
  $estimateDate: Date,
  $estimateNo: Int!,
  $customerNameJa: String,
  $transportationUnitPriceId: Int,
  $escortVehicleUnitPriceId: Int,
  $subcontractedEscortVehicleUnitPriceId: Int,
  $subtotal: Float,
  $consumptionTax: Float,
  $totalAmount: Float,
  $status: StatusOption,
  $updatedUserId : Int,
  $createdUserId : Int
) {
  createEstimate(input: {
    estimate: {
      estimateDate: $estimateDate,
      estimateNo: $estimateNo,
      customerNameJa: $customerNameJa,
      subcontractedEscortVehicleUnitPriceId: $subcontractedEscortVehicleUnitPriceId,
      transportationUnitPriceId: $transportationUnitPriceId,
      escortVehicleUnitPriceId: $escortVehicleUnitPriceId,
      subtotal: $subtotal,
      consumptionTax: $consumptionTax,
      totalAmount: $totalAmount,
      status: $status,
      createdUserId : $createdUserId,
      updatedUserId : $updatedUserId
    }
  }) {
    estimate {
      id
    }
  }
}`;

const estimatesDetailsBatch = gql`
mutation createEstimatesDetailsBatch(
  $estimateDate: Date,
  $estimateNo: String!,
  $customerNameJa: String!,
  $transportationUnitPriceId: Int!,
  $escortVehicleUnitPriceId: Int,
  $subcontractedEscortVehicleUnitPriceId: Int,
  $subtotal: Float!,
  $consumptionTax: Float!,
  $totalAmount: Float!,
  $status: StatusOption,
  $updatedUserId : Int!,
  $createdUserId : Int!,
  $estimatesDetails: [EstimatesDetailInput!]
) {
  estimatesDetailsBatch(input:{
    estimate:{
      estimateDate: $estimateDate,
      estimateNo: $estimateNo,
      customerNameJa: $customerNameJa,
      transportationUnitPriceId: $transportationUnitPriceId,
      subcontractedEscortVehicleUnitPriceId: $subcontractedEscortVehicleUnitPriceId,
      escortVehicleUnitPriceId: $escortVehicleUnitPriceId,
      subtotal: $subtotal,
      consumptionTax: $consumptionTax,
      totalAmount: $totalAmount,
      status: $status,
      createdUserId : $createdUserId,
      updatedUserId : $updatedUserId
    },
    estimateDetails: $estimatesDetails
  }) {
    status
  }
}`;

const estimatesDetailsBatchUpdate = gql`
mutation estimatesDetailsBatchUpdate(
  $id:Int!,
  $estimateDate: Date,
  $estimateNo: String!,
  $customerNameJa: String!,
  $transportationUnitPriceId: Int!,
  $escortVehicleUnitPriceId: Int!,
  $subcontractedEscortVehicleUnitPriceId: Int!,
  $subtotal: Float!,
  $consumptionTax: Float!,
  $totalAmount: Float!,
  $status: StatusOption,
  $updatedUserId : Int!,
  $createdUserId : Int!,
  $estimatesDetails:[EstimatesDetailInput!]
) {
  estimatesDetailsBatchUpdate(input:{
    estimate:{
      id: $id,
      estimateDate:$estimateDate,
      estimateNo:$estimateNo,
      customerNameJa:$customerNameJa,
      transportationUnitPriceId: $transportationUnitPriceId,
      escortVehicleUnitPriceId: $escortVehicleUnitPriceId,
      subcontractedEscortVehicleUnitPriceId: $subcontractedEscortVehicleUnitPriceId,
      subtotal:$subtotal,
      consumptionTax:$consumptionTax,
      totalAmount:$totalAmount,
      status:$status,,
      createdUserId : $createdUserId,
      updatedUserId : $updatedUserId
    },
    estimateDetails:$estimatesDetails
  }){
    status
  }
}`;

const estimatesDetailsBatchDelete = gql`
mutation estimatesDetailsBatchDelete($id:Int!,$estimateNo:String!) {
  estimatesDetailsBatchDelete(input:{
    estimate:{
      id: $id,
      estimateNo:$estimateNo
    }
  }) {
    status
  }
}`;

const getById = gql`
query GetEstimate($id: Int!) {
  estimateById(id:$id) {
    id,
    estimateDate,
    estimateNo,
    customerNameJa,
    transportationUnitPriceId,
    escortVehicleUnitPriceId,
    subcontractedEscortVehicleUnitPriceId,
    subtotal,
    consumptionTax,
    totalAmount,
    status,
    createdUserId,
    createdAt,
    updatedUserId,
    updatedAt,
    estimatesDetailsByEstimateId {
      nodes {
        id,
        estimateId,
        sequenceNo,
        packing1,
        originDestinationLocation,
        transportDistance,
        transportFee,
        notes,
        escortVehicleOption,
        escortVehicleCount,
        escortVehicleDistance,
        escortVehicleFee,
        outsourceEscortVehicleOption,
        outsourceEscortVehicleCount,
        outsourceEscortVehicleDistance,
        outsourceEscortVehicleFee,
        packagingByPacking1 {
          packagingName,
          weight
        }
      }
    }
  }
}`;

const updateById = gql`
mutation updateEstimateById(
  $id: Int!,
  $estimateDate: Date,
  $estimateNo: Int!,
  $customerNameJa: String!,
  $transportationUnitPriceId: Int!,
  $escortVehicleUnitPriceId: Int!,
  $subcontractedEscortVehicleUnitPriceId: Int!,
  $subtotal: Float!,
  $consumptionTax: Float!,
  $totalAmount: Float!,
  $status: StatusOption,
  $updatedUserId : Int!,
  $createdUserId : Int!
) {
  updateEstimateById(input:{
    id:$id,
    estimatePatch:{
      estimateDate: $estimateDate,
      estimateNo: $estimateNo,
      customerNameJa: $customerNameJa,
      subcontractedEscortVehicleUnitPriceId: $subcontractedEscortVehicleUnitPriceId,
      transportationUnitPriceId: $transportationUnitPriceId,
      escortVehicleUnitPriceId: $escortVehicleUnitPriceId,
      subtotal: $subtotal,
      consumptionTax: $consumptionTax,
      totalAmount: $totalAmount,
      status: $status,
      createdUserId : $createdUserId,
      updatedUserId : $updatedUserId
    }
  }) {
    estimate{
      id
    }
  }
}`;

const deleteById = gql`
mutation deleteEstimateById($id: Int!) {
  deleteEstimateById(input:{
    id:$id
  }) {
    deletedEstimateId,
    estimate{
      id
    }
  }
}`;

export { getAll, create, getById, updateById, deleteById, estimatesDetailsBatch, estimatesDetailsBatchUpdate, estimatesDetailsBatchDelete };