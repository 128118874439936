import { gql } from 'apollo-boost';

const getUnitPrice = gql`
mutation getUnitPrice($id: Int, $weight: Float, $distance: Float) {
    getUnitPrice(input: { id: $id, unitweight: $weight, distance: $distance }) {
      float
    }
  }
`;

export { getUnitPrice }