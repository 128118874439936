import { Button, Col, Row } from 'antd';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { OrderContext } from '../../../../utils/context';
import { formatTypes } from '../../../OrdersAction/constants';

const dayListJap = {
  0: '(日)',
  1: '(月)',
  2: '(火)',
  3: '(水)',
  4: '(木)',
  5: '(金)',
  6: '(土)',
}

const DateView = () => {

  const { date, orderSetter } = useContext(OrderContext);

  const [active, setActive] = useState();
  const [dates, setDates] = useState([]);

  const onDateClick = (value) => () => {
    if (!moment(value).isSame(date)) {
      orderSetter({ pageLoading: true })
    }
    setActive(value);
    orderSetter({ date: moment(value) });
  };

  useEffect(() => {
    if (date) {
      setActive(date.format(formatTypes.DATE));
      let dateValue = moment(date).subtract(6, 'days');
      const dateList = [...Array(11)].map(() => {
        dateValue = dateValue.add(1, 'days');
        const dayOfWeek = dateValue.day();
        const day = dateValue.format('DD');
        return { day: `${day} ${dayListJap[dayOfWeek]}`, value: dateValue.format(formatTypes.DATE) };
      });
      setDates(dateList);
    } else {
      setDates([])
    }
  }, [date]);

  return (
    <Col style={{ flex: 1, flexGrow: 2 }}>
      <Row type='flex' style={{ alignItems: 'center', justifyContent: 'flex-end' }}>
        {dates.map(({ day, value }, index) => (
          <Button
            key={index}
            tabIndex='-1'
            className='day-button-layout'
            style={active === value ? styles.active : styles.default}
            onClick={onDateClick(value)}
          >
            {day}
          </Button>
        ))}
      </Row>
    </Col>
  )
};

const styles = {
  default: {
    width: 70,
    borderRadius: 0,
  },
  active: {
    width: 70,
    color: '#1890FF',
    borderRadius: 0
  },
};

export default DateView;