import {gql} from 'apollo-boost';

const getAll = gql`
query allPackagings($first: Int, $offset: Int, $filter: PackagingFilter) {
  allPackagings(first:$first,offset:$offset,filter:$filter,orderBy:[PACKAGING_CATEGORY_BY_PACKING_CATEGORY_ID__NAME_JA_ASC,CONVERTED_PACKAGING_NAME_ASC,WEIGHT_ASC]){
  # allPackagings(first:$first,offset:$offset,filter:$filter,orderBy:[PACKAGING_CATEGORY_BY_PACKING_CATEGORY_ID__CONVERTED_NAME_JA_ASC,CONVERTED_PACKAGING_NAME_ASC,WEIGHT_ASC]){

    nodes {
        id,
        packagingName,
        packingCategoryId,
        packagingCategoryByPackingCategoryId {
            nameJa
        },
        weight,
        status
    },
    totalCount
  }
}`;

const getAllPackagingCategories = gql`
query allPackagingCategories{
  allPackagingCategories {
    nodes {
      id,
      nameJa
    }
  }
}
`;

const create = gql`
  mutation createPackaging (
    $packagingName: String!,
    $packingCategoryId: Int!,
    $weight: Int!,
    $status: StatusOption,
    $updatedUserId : Int!,
    $createdUserId : Int!,
  ){
  createPackaging (input:{
    packaging : {
        packagingName: $packagingName,
        packingCategoryId: $packingCategoryId,
        weight: $weight,
        status: $status,
        createdUserId : $createdUserId,
        updatedUserId : $updatedUserId
    }
	}){
        packaging {
            packagingName
        }
    }
  }
`;

const getById = gql`
query getPackaging ($id:Int!){
    packagingById (id : $id) {
        id,
        packagingName,
        packingCategoryId,
        weight,
        status
    }
}`;


const updateById = gql`
mutation updatePackaging(
    $id: Int!,
    $packagingName: String!,
    $packingCategoryId: Int!,
    $weight: Int!,
    $status: StatusOption,
    $updatedUserId : Int,
    $createdUserId : Int,
  ){
    updatePackagingById(input:{
        id : $id,
        packagingPatch : {
            packagingName: $packagingName,
            packingCategoryId: $packingCategoryId,
            weight: $weight,
            status: $status,
            createdUserId : $createdUserId,
            updatedUserId : $updatedUserId
        }
	}){
        packaging {
            packagingName
        }
    }
  }
`;

// const deleteById = gql`
// mutation PackageBatchDelete($id:Int!){
//   PackageBatchDelete (input:{id:$id}){
//     status
//   }
// }


// `;



const deleteById = gql`
mutation deletePackagingById($id:Int!){
  deletePackagingById (input:{id:$id}){
    deletedPackagingId
  }
}

`;


export {getAll, create, getById, updateById, deleteById, getAllPackagingCategories};