import { Col, Form, Row } from 'antd';
import React from 'react';
/** Get All Common  **/
import {
    CustomButton,
    CustomHiddenInput, CustomInput,
    CustomRadioGroup,
    InputNumbers
} from '../../../commons/CustomDataEntry';

import PackagingCategories from "../../../commons/Picklist/PackagingCategories";
/** Get All Package Queries **/
import {
    create,
    deleteById, getAll, getById,
    updateById
} from '../../../queries/MasterMaintenance/package';
/** Get ADhoc translate **/
import adhocTranslator from '../../../utils/locales/translate-adhoc';
/** Get user_id stored in LocalStorage **/
import decodeToken from '../../../utils/user_id';

export default ({ mode }) => {

    /** Initialize translators **/
    let lt = adhocTranslator('K903-020');

    return {
        /** Assign mode **/
        mode,

        /** Redirect config **/
        redirect: '/packaging/search-list',

        /** query mapping **/
        data: {
            idRef: 'packagingById'
        },
        /** Map queries **/
        queries: {
            GET_BY_ID: getById,
            GET_ALL: getAll,
            CREATE: create,
            UPDATE_BY_ID: updateById,
            DELETE_BY_ID: deleteById
        },

        /** messages */
        messages: {
            add: "荷姿情報を登録しますか？",
            edit: "荷姿情報を更新しますか？",
            delete: "荷姿情報を削除しますか？",
            reg_success_message: lt("reg_success_message"),
            reg_error_message: lt("reg_error_message"),
            edit_success_message: lt("edit_success_message"),
            edit_failure_message: lt("edit_failure_message"),
            delete_success_message: lt("delete_success_message"),
            delete_failure_message: lt("delete_failure_message")
        },

        /** Create common component **/
        component: ({ form, data, handleSubmit, loading }) => {
            const radioStatus = [

                { id: 'ACTIVE', value: lt('rdo_active') },
                { id: 'INACTIVE', value: lt('rdo_inactive') }
            ];

            let btnLabel;
            if (mode === 'edit') {
                btnLabel = lt('btn_edit')
            } else if (mode === 'delete') {
                btnLabel = lt('btn_delete')
            } else {
                btnLabel = lt('btn_register')
            }

            return (
                <Row type='flex' className='h100' justify='space-around' align='top'>
                    <Col span={24}>
                        <Form onSubmit={handleSubmit} layout='vertical'>
                            <CustomHiddenInput
                                fieldName={mode === 'add' ? 'createdUserId' : 'updatedUserId'}
                                form={form}
                                decorator={{
                                    initialValue: decodeToken().user_id
                                }}
                            />
                            <CustomInput
                                autoFocus={true}
                                form={form}
                                fieldName='packagingName'
                                label={lt('lbl_packaging')}
                                type='text'
                                mode={mode}
                                offset={1}
                                width={14}
                                half={true}
                                decorator={{
                                    rules: [{
                                        required: true,
                                        message: lt('荷姿を入力してください。'),
                                    }],
                                    initialValue: data.packagingName
                                }} />

                            <div className="ant-row" >
                                <PackagingCategories
                                    form={form}
                                    fieldName='packingCategoryId'
                                    label={lt('lbl_class')}
                                    mode={mode}
                                    half={true}
                                    width={14}
                                    offset={1}
                                    labelWidth={4}
                                    style={{ width: 320, display: 'inline-block', marginLeft: 33, marginRight: 10 }}
                                    decorator={{
                                        rules: [{
                                            required: true,
                                            message: lt('error_classification'),
                                        }],
                                        initialValue: data.packingCategoryId
                                    }}
                                />
                            </div>
                            <InputNumbers
                                form={form}
                                fieldName='weight'
                                label={lt('lbl_weight_ton')}
                                maxLength={2}
                                type='text'
                                mode={mode}
                                offset={1}
                                width={14}
                                half={true}
                                decorator={{
                                    rules: [{
                                        required: true,
                                        message: lt('重さ（トン）を入力してください。'),
                                    }],
                                    initialValue: data.weight
                                }} />
                            <CustomHiddenInput
                                form={form}
                                type='hidden'
                                fieldName='packageConfig'
                                mode={mode}
                                decorator={{
                                    initialValue: true
                                }}
                            />
                            <div className="ant-row ant-form-item" >
                                <CustomRadioGroup
                                    options={radioStatus}
                                    form={form}
                                    mode={mode}
                                    label={lt('lbl_status')}
                                    fieldName='status'
                                    decorator={{
                                        rules: [{
                                            required: true,
                                            message: lt('lbl_status'),
                                        }],
                                        initialValue: data.status
                                    }}
                                    offset={0}
                                    width={14}
                                    half={true}
                                    labelWidth={5}
                                />
                            </div>
                            <CustomButton label={btnLabel}
                                event={handleSubmit}
                                loading={loading}
                                style={{ marginTop: '15px', height: '40px', width: '104px' }}
                                htmlType="submit"
                                offset={12} />
                        </Form>
                    </Col>
                </Row>
            );
        }

    }
}