import { Form } from "antd";
import React, { Fragment } from "react";
import {
    CustomButton,
    CustomHiddenInput, CustomInput
} from "../../../commons/CustomDataEntry";
import PackagingCategories from '../../../commons/Picklist/PackagingCategories';
import { getAll } from '../../../queries/MasterMaintenance/package';
import adhocTranslator from "../../../utils/locales/translate-adhoc";

export default () => {
    let lt = adhocTranslator('K030-021');

    return {
        fetchQuery: getAll,
        allKey: 'allPackagings',
        displayField: 'packagingName',
        perPage: 20,
        columns: [
            {
                title: lt('lbl_name_header'),
                dataIndex: 'packagingName',
                key: 'packagingName',
            }
        ],
        type: 'radio',
        th: 430,
        searchHeader: ({ form, handleSearch, loading }) => {
            return (
                <Fragment>
                    <Form onKeyPress={e => e.which === 13 && e.preventDefault()}>
                        <div className="ant-row ant-form-item" >
                            <PackagingCategories
                                form={form}
                                fieldName='packingCategoryId'
                                placeholder={lt('分類を選択してください。')}
                                rowField={false}
                                style={{ width: '100%', display: 'inline-block', marginRight: 10 }}
                            />
                        </div>
                        <CustomHiddenInput
                            form={form}
                            type='hidden'
                            fieldName='packagingName_LikeStart'
                        />
                        <div className="ant-row ant-form-item" >
                            <CustomInput form={form}
                                fieldName='packagingName_Like'
                                type='text'
                                placeholder={lt('荷姿を入力してください。')}
                                rowField={false}
                                style={{ width: '87%', marginRight: 10 }} />
                            <CustomButton label={lt('btn_search')}
                                loading={loading}
                                onClick={handleSearch}
                                width={2}
                                style={{ display: 'inline-block' }} />
                        </div>
                    </Form>
                </Fragment>)
        },

        dataMapping: (item) => {
            return {
                id: item.id,
                packingCategoryId: item.packingCategoryId,
                packagingName: item.packagingName,
                weight: item.weight,
            }
        }
    };
}