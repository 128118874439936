import React, {Fragment} from 'react';
import {Button, Form} from 'antd';
import KeyboardEventHandler from 'react-keyboard-event-handler';

const CustomButton = ({
                          label,
                          offset = 8,
                          width = 24,
                          loading,
                          inline = false,
                          className,
                          style,
                          mode,
                          htmlType = 'button',
                          onClick,
                          event,
                          autoFocus
                      }) => {
    const tailFormItemLayout = {
        wrapperCol: {
            xs: {
                span: 24,
                offset
            },
            sm: {
                span: width,
                offset
            },
        },
    };
    let mainStyle = inline ? {display: 'inline-block'} : null
    return (
        <Fragment>
            {event && <KeyboardEventHandler
                handleKeys={['enter']}
                onKeyEvent={(key, e) => event(e)}
            />}
            {htmlType === 'submit' ? (
                <Form.Item {...tailFormItemLayout} style={mainStyle} className={className}>
                    <Button
                        autoFocus={autoFocus}
                        type='primary'
                        loading={loading}
                        htmlType='submit'
                        style={style}
                        onClick={onClick}
                        disabled={mode === 'delete' ? true : false}
                    >{label}</Button>
                </Form.Item>
            ) : (<Button autoFocus={autoFocus} type='primary' style={style} disabled={mode === 'delete' || mode === 'addUser'? true : false} onClick={onClick} htmlType='button'>{label}</Button>)}
        </Fragment>
    )
}
export default CustomButton;
