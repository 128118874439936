import React, {Fragment} from 'react';
import CustomSelect from '../CustomDataEntry/CustomSelect';
import {getAllPackagingCategories} from '../../queries/MasterMaintenance/package';
import {Query} from 'react-apollo';

export default (props)=> {
    return (<Query
        query={getAllPackagingCategories}
        fetchPolicy='network-only'>
        {
            ({data, loading}) => {
                if( loading ) return <Fragment></Fragment>;
                let allPackagingCategories = data.allPackagingCategories.nodes.slice();
                allPackagingCategories.unshift({
                    id: null,
                    nameJa: 'すべて'
                });
                return (
                    <Fragment>
                        <CustomSelect {...props}
                                      options={props.hasDefault ? allPackagingCategories : data.allPackagingCategories.nodes}
                                      optionId={'id'}
                                      optionValue={'nameJa'}/>
                    </Fragment>
                );
            }
        }
    </Query>)
};