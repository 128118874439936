import { gql } from 'apollo-boost';
import moment from 'moment';

export const GET_ORDERS_DISPATCH = gql`
  query getOrdersDispatch(
    $orderBy: [OrdersOrderBy!],
    $filter: OrderFilter,
    $arrivalsByVehicleFilter: ArrivalFilter,
    $departureByVehicleFilter: DepartureFilter
    ) {
      allOrders(filter: $filter, orderBy: $orderBy) {
        nodes {
          createdAt
          createdUserId
          customerId
          dispatchStatus
          displayOrder
          driverId1
          driverId2
          vehicleId
          id
          notes
          orderDate
          overnight
          packing1
          packing2
          transportBillingAmount
          transportDate
          transportDateOption
          transportDistance
          transportPaymentAmount
          updatedAt
          updatedUserId
          userByDriverId1 {
            id
            shortNameJa
            fullNameJaKatakana
            fullNameJa
          }
          userByDriverId2 {
            id
            shortNameJa
            fullNameJa
            fullNameJaKatakana
          }
          packagingByPacking1 {
            id
            packagingName
          }
          customerByCustomerId {
            id
            nameJa
            formOutputName
          }
          vehicleByVehicleId {
            id
            vehicleNo
            arrivalsByVehicleId(filter:$arrivalsByVehicleFilter){
              nodes {
                id
                vehicleId
                workingDay
                orderId
                arrivalMileage
                arrivalDatetime
                weather
                fuelOilSupply
              }
            }
            departuresByVehicleId(filter:$departureByVehicleFilter){
              nodes {
                id
                vehicleId
                workingDay
                orderId
                departuresMileage
                departuresDatetime
              }
            }
          }
          ordersTransportsByOrderId(orderBy: TRANSPORT_NO_ASC) {
            nodes {
              destination
              origin
              dropoffActualDatetime
              dropoffAssignee
              dropoffBorrower
              dropoffContact
              dropoffDate
              dropoffHourFrom
              dropoffHourTo
              dropoffMileage
              dropoffMinuteFrom
              dropoffMinuteTo
              dropoffOption
              dropoffMemo
              dropoffSiteName
              id
              nodeId
              numberEscortVehicle
              orderId
              pickupActualDatetime
              pickupAssignee
              pickupBorrower
              pickupContact
              pickupDate
              pickupHour
              pickupMemo
              originColor
              destinationColor
              pickupMileage
              pickupMinute
              pickupOption
              pickupSiteName
              transportNo
              ordersPickupImagesByTransportId(orderBy: IMAGE_NO_ASC) {
                nodes {
                  imageNo
                  imageFile
                  id
                  transportId
                }
              }
              ordersEscortVehiclesByTransportId(orderBy: ESCORT_NO_ASC) {
                nodes {
                  id
                  escortNo
                  dropoffDistance
                  pickupDistance
                  origin
                  driverId
                  destination
                  transportBillingAmount
                  transportId
                  transportPaymentAmount
                  vehicleId
                  userByDriverId {
                    fullNameJa
                    id
                  }
                }
              }
              ordersDropoffImagesByTransportId(orderBy: IMAGE_NO_ASC) {
                nodes {
                  id
                  imageFile
                  imageNo
                  transportId
                }
              }
            }
          }
        }
    }
  }
`;

export const GET_ORDERS_REPORT = gql`
  query getOrdersDispatch(
    $orderBy: [OrdersOrderBy!],
    $filter: OrderFilter,
    $arrivalsByVehicleFilter: ArrivalFilter,
    $departureByVehicleFilter: DepartureFilter
    ) {
      allOrders(filter: $filter, orderBy: $orderBy) {
        nodes {
          createdAt
          createdUserId
          customerId
          dispatchStatus
          displayOrder
          driverId1
          driverId2
          vehicleId
          id
          notes
          orderDate
          overnight
          packing1
          packing2
          transportBillingAmount
          transportDate
          transportDateOption
          transportDistance
          transportPaymentAmount
          updatedAt
          updatedUserId
          arrivalsByOrderId(orderBy: CREATED_AT_ASC, filter:$arrivalsByVehicleFilter) {
            nodes {
              arrivalDatetime
              arrivalMileage
              createdUserId
              weather
              workingDay
              vehicleId
              orderId
              id
              fuelOilSupply
            }
          }
          departuresByOrderId(orderBy: CREATED_AT_ASC, filter:$departureByVehicleFilter) {
            nodes {
              departuresDatetime
              departuresMileage
              id
              orderId
              vehicleId
              workingDay
            }
          }
          userByDriverId1 {
            id
            shortNameJa
            fullNameJaKatakana
            fullNameJa
          }
          userByDriverId2 {
            id
            shortNameJa
            fullNameJa
            fullNameJaKatakana
          }
          packagingByPacking1 {
            id
            packagingName
          }
          customerByCustomerId {
            id
            nameJa
            formOutputName
          }
          vehicleByVehicleId {
            id
            vehicleNo
          }
          ordersTransportsByOrderId(orderBy: TRANSPORT_NO_ASC) {
            nodes {
              destination
              origin
              dropoffActualDatetime
              dropoffAssignee
              dropoffBorrower
              dropoffContact
              dropoffDate
              dropoffHourFrom
              dropoffHourTo
              dropoffMileage
              dropoffMinuteFrom
              dropoffMinuteTo
              dropoffOption
              dropoffMemo
              dropoffSiteName
              id
              nodeId
              numberEscortVehicle
              orderId
              pickupActualDatetime
              pickupAssignee
              pickupBorrower
              pickupContact
              pickupDate
              pickupHour
              pickupMemo
              originColor
              destinationColor
              pickupMileage
              pickupMinute
              pickupOption
              pickupSiteName
              transportNo
              ordersEscortVehiclesByTransportId(orderBy: ESCORT_NO_ASC) {
                nodes {
                  id
                  escortNo
                  dropoffDistance
                  pickupDistance
                  origin
                  driverId
                  destination
                  transportBillingAmount
                  transportId
                  transportPaymentAmount
                  vehicleId
                  userByDriverId {
                    fullNameJa
                    id
                  }
                }
              }
            }
          }
        }
    }
  }
`;

export const GET_ORDERS_DISPATCH_REPORTING = gql`
  query getOrdersDispatchReporting( $filter: OrderFilter ) {
    allOrders(filter: $filter) {
      nodes {
        id
        transportBillingAmount
        transportDate
        customerByCustomerId {
          id
          nameJa
        }
        ordersEscortVehiclesByOrderId {
          nodes {
            id
            transportBillingAmount
          }
        }
      }
    }
  }
`;

export const GET_VEHICLES = gql`
  query getAllVehicles($filter: VehicleFilter) {
    allVehicles(filter: $filter, orderBy: DISPLAY_ORDER_ASC) {
      nodes {
        vehicleType
        vehicleNo
        id
        usersByVehicleNumber {
          nodes {
            id
            fullNameJa
            shortNameJa
          }
        }
      }
    }
  }
`;

export const GET_LEAVES = gql`
query getOptions($filter: LeafFilter) {
  allLeaves(filter: $filter, orderBy:[LEAVE_DATE_ASC,USER_BY_USER_ID__FULL_NAME_JA_ASC,LEAVE_CATEGORY_ASC]) {
    nodes {
      id
      leaveDate
      reason
      userByUserId {
        fullNameJa
        id
      }
    }
  }
}
`;

export const GET_OPTIONS = gql`
  query getOptions($filter: LeafFilter) {
    allCustomers(filter: {status: {equalTo: ACTIVE}}) {
      nodes {
        id
        nameJa
        nameJaKatakana
      }
    }
    allPackagings(orderBy: DISPLAY_ORDER_ASC) {
      nodes {
        id
        packagingName
      }
    }
    allLeaves(filter: $filter, orderBy:[LEAVE_DATE_ASC,USER_BY_USER_ID__FULL_NAME_JA_ASC,LEAVE_CATEGORY_ASC]) {
      nodes {
        id
        leaveDate
        reason
        userByUserId {
          fullNameJa
          id
        }
      }
    }
    allVehicles(orderBy: DISPLAY_ORDER_ASC) {
      nodes {
        id
        vehicleNo
        vehicleType
        status
        usersByVehicleNumber {
          nodes {
            id
            fullNameJa
            shortNameJa
          }
        }
      }
    }
    allUsers(filter: {rolesByUserId: {every: {role: {in: [DRIVER_COMPANY_, DRIVER_OUTSOURCING_, ESCORT_VEHICLE_COMPANY_DRIVER, ESCORT_VEHICLE_OUTSOURCING_DRIVER]}}}}) {
      nodes {
        id
        fullNameJa
        fullNameJaKatakana
      }
    }
  }
`;

export const DELETE_RECORD = gql`
mutation deleteById($input: OrdersDeleteInput!) {
  ordersDispatchBatchDelete(input: $input) {
    status
  }
}
`;

export const UPDATE_ORDER = gql`
mutation updateOrderById($input: UpdateOrderByIdInput!) {
  updateOrderById(input: $input) {
    clientMutationId
  }
}
`;

export const SOFT_DELETE_RECORD = gql`
mutation updateOrderById($id: Int!, $deleted_at: Datetime, $deleted_by: String) {
  updateOrderById(input: {orderPatch: {isDeleted: true, deletedAt: $deleted_at, deletedBy: $deleted_by}, id: $id}) {
    clientMutationId
  }
}
`;

export const RESTORE_DELETED_RECORD = gql`
mutation updateOrderById($id: Int!) {
  updateOrderById(input: {orderPatch: {isDeleted: false, deletedAt: null, deletedBy: null}, id: $id}) {
    clientMutationId
  }
}
`;


export const UPDATE_ORDERS_MULTIPLE = gql`
  mutation updateOrders($payload: String!) {
    ordersUpdateMultiple(input: {payload: $payload}) {
      result {
        response
      }
    }
  }
`;

export const INSERT_ORDER = gql`
  mutation insertOrder($input: CreateOrderInput!) {
    createOrder(input: $input) {
      order {
        id
      }
    }
  }
`;

export const UPDATE_DISPLAY_ORDER = gql`
mutation updateDisplayOrder($displayOrder: Int, $id: Int!) {
  updateOrderById(input: {id: $id, orderPatch: {displayOrder: $displayOrder}}) {
    clientMutationId
  }
}
`;

export const UPDATE_ORDER_COLORED = gql`
mutation updateDisplayOrder($colored: Boolean, $id: Int!) {
  updateOrderById(input: {id: $id, orderPatch: {isColored: $colored}}) {
    clientMutationId
  }
}
`;

export const GET_ORDERS = gql`
  query getOrdersDispatch(
    $orderBy: [OrdersOrderBy!],
    $filter: OrderFilter,
    ) {
      allOrders(filter: $filter, orderBy: $orderBy) {
        nodes {
          createdAt
          lastEdited
          createdUserId
          customerId
          dispatchStatus
          displayOrder
          driverId1
          driverId2
          vehicleId
          id
          isColored
          notes
          orderDate
          overnight
          packing1
          packing2
          transportBillingAmount
          transportDate
          transportDateOption
          transportDistance
          transportPaymentAmount
          updatedAt
          updatedUserId
          deletedAt
          deletedBy
          isDeleted
          isEditing
          ordersConnectedsByOrderId1 {
            nodes {
              id
              connectionId
              orderId1
              orderId2
              totalBilling
              totalDistance
              escortConnected
              orderByOrderId1 {
                id
                transportDate
                dispatchStatus
                ordersEscortVehiclesByOrderId {
                  nodes {
                    transportBillingAmount
                    transportPaymentAmount
                    id
                  }
                }
                ordersTransportsByOrderId {
                  nodes {
                    id
                    pickupActualDatetime
                    dropoffActualDatetime
                  }
                }
              }
              orderByOrderId2 {
                id
                transportDate
                dispatchStatus
                ordersEscortVehiclesByOrderId {
                  nodes {
                    transportBillingAmount
                    transportPaymentAmount
                    id
                  }
                }
                ordersTransportsByOrderId {
                  nodes {
                    id
                    pickupActualDatetime
                    dropoffActualDatetime
                  }
                }
              }
            }
          }
          ordersConnectedsByOrderId2 {
            nodes {
              id
              connectionId
              orderId1
              orderId2
              totalBilling
              totalDistance
              escortConnected
              orderByOrderId1 {
                id
                transportDate
                dispatchStatus
                ordersEscortVehiclesByOrderId {
                  nodes {
                    transportBillingAmount
                    transportPaymentAmount
                    id
                  }
                }
                ordersTransportsByOrderId {
                  nodes {
                    id
                    pickupActualDatetime
                    dropoffActualDatetime
                  }
                }
              }
              orderByOrderId2 {
                id
                transportDate
                dispatchStatus
                ordersEscortVehiclesByOrderId {
                  nodes {
                    transportBillingAmount
                    transportPaymentAmount
                    id
                  }
                }
                ordersTransportsByOrderId {
                  nodes {
                    id
                    pickupActualDatetime
                    dropoffActualDatetime
                  }
                }
              }
            }
          }
          userByDriverId1 {
            id
            shortNameJa
            fullNameJaKatakana
            fullNameJa
          }
          userByDriverId2 {
            id
            shortNameJa
            fullNameJa
            fullNameJaKatakana
          }
          packagingByPacking1 {
            id
            packagingName
            weight
          }
          customerByCustomerId {
            id
            transportationUnitPriceId
            escortVehicleUnitPriceId
            nameJa
            formOutputName
            telNo1
            telNo2
          }
          vehicleByVehicleId {
            id
            vehicleNo
          }
          ordersTransportsByOrderId(orderBy: TRANSPORT_NO_ASC) {
            nodes {
              destination
              origin
              dropoffActualDatetime
              dropoffDate
              dropoffHourFrom
              dropoffHourTo
              dropoffMileage
              dropoffMinuteFrom
              dropoffMinuteTo
              dropoffOption
              dropoffAssignee
              dropoffBorrower
              dropoffContact
              dropoffMemo
              dropoffSiteName
              id
              nodeId
              numberEscortVehicle
              orderId
              pickupActualDatetime
              pickupAssignee
              pickupBorrower
              pickupContact
              pickupMemo
              pickupSiteName
              pickupDate
              pickupHour
              pickupHourTo
              originColor
              destinationColor
              pickupMileage
              pickupMinute
              pickupMinuteTo
              pickupOption
              transportNo
              ordersPickupImagesByTransportId(orderBy: IMAGE_NO_ASC) {
                nodes {
                  imageNo
                  imageFile
                  id
                  transportId
                }
              }
              ordersEscortVehiclesByTransportId(orderBy: ESCORT_NO_ASC) {
                nodes {
                  id
                  escortNo
                  dropoffDistance
                  pickupDistance
                  origin
                  driverId
                  destination
                  transportBillingAmount
                  transportId
                  transportPaymentAmount
                  vehicleId
                  userByDriverId {
                    fullNameJa
                    id
                  }
                }
              }
              ordersDropoffImagesByTransportId(orderBy: IMAGE_NO_ASC) {
                nodes {
                  id
                  imageFile
                  imageNo
                  transportId
                }
              }
            }
          }
        }
    }
  }
`;

export const GET_ORDER_INFO = gql`
  query getOrdersDispatch(
    $orderBy: [OrdersOrderBy!],
    $filter: OrderFilter,
    ) {
      allOrders(filter: $filter, orderBy: $orderBy) {
        nodes {
          lastEdited
          id
          isColored
          isDeleted
          isEditing
        }
    }
  }
`;

export const GET_ORDERS_EXCEL = gql`
  query getOrdersDispatch(
    $orderBy: [OrdersOrderBy!],
    $filter: OrderFilter,
    ) {
      allOrders(filter: $filter, orderBy: $orderBy) {
        nodes {
          createdAt
          createdUserId
          customerId
          dispatchStatus
          displayOrder
          driverId1
          driverId2
          vehicleId
          id
          notes
          orderDate
          overnight
          packing1
          packing2
          transportBillingAmount
          transportDate
          transportDateOption
          transportDistance
          transportPaymentAmount
          updatedAt
          updatedUserId
          ordersConnectedsByOrderId1 {
            nodes {
              id
              connectionId
              orderId1
              orderId2
              totalBilling
              totalDistance
              orderByOrderId1 {
                id
                transportDate
                dispatchStatus
                notes
                ordersEscortVehiclesByOrderId {
                  nodes {
                    transportBillingAmount
                    transportPaymentAmount
                    id
                  }
                }
                ordersTransportsByOrderId {
                  nodes {
                    id
                    destination
                    origin
                    pickupBorrower
                    dropoffBorrower
                  }
                }
              }
              orderByOrderId2 {
                id
                transportDate
                dispatchStatus
                notes
                ordersEscortVehiclesByOrderId {
                  nodes {
                    transportBillingAmount
                    transportPaymentAmount
                    id
                  }
                }
                ordersTransportsByOrderId {
                  nodes {
                    id
                    destination
                    origin
                    pickupBorrower
                    dropoffBorrower
                  }
                }
              }
            }
          }
          ordersConnectedsByOrderId2 {
            nodes {
              id
              connectionId
              totalBilling
              totalDistance
              orderId1
              orderId2
              orderByOrderId1 {
                id
                transportDate
                dispatchStatus
                ordersEscortVehiclesByOrderId {
                  nodes {
                    transportBillingAmount
                    transportPaymentAmount
                    id
                  }
                }
                ordersTransportsByOrderId {
                  nodes {
                    id
                    destination
                    origin
                    pickupBorrower
                    dropoffBorrower
                  }
                }
              }
              orderByOrderId2 {
                id
                transportDate
                dispatchStatus
                ordersEscortVehiclesByOrderId {
                  nodes {
                    transportBillingAmount
                    transportPaymentAmount
                    id
                  }
                }
                ordersTransportsByOrderId {
                  nodes {
                    id
                    destination
                    origin
                    pickupBorrower
                    dropoffBorrower
                  }
                }
              }
            }
          }
          userByDriverId1 {
            id
            shortNameJa
            fullNameJaKatakana
            fullNameJa
          }
          userByDriverId2 {
            id
            shortNameJa
            fullNameJa
            fullNameJaKatakana
          }
          packagingByPacking1 {
            id
            packagingName
            weight
          }
          customerByCustomerId {
            id
            transportationUnitPriceId
            escortVehicleUnitPriceId
            nameJa
            formOutputName
            telNo1
            telNo2
          }
          vehicleByVehicleId {
            id
            vehicleNo
          }
          ordersTransportsByOrderId(orderBy: TRANSPORT_NO_ASC) {
            nodes {
              destination
              origin
              dropoffActualDatetime
              dropoffDate
              dropoffHourFrom
              dropoffHourTo
              dropoffMileage
              dropoffMinuteFrom
              dropoffMinuteTo
              dropoffOption
              dropoffAssignee
              dropoffBorrower
              dropoffContact
              dropoffMemo
              dropoffSiteName
              id
              nodeId
              numberEscortVehicle
              orderId
              pickupActualDatetime
              pickupAssignee
              pickupBorrower
              pickupContact
              pickupMemo
              pickupSiteName
              pickupDate
              pickupHour
              originColor
              destinationColor
              pickupMileage
              pickupMinute
              pickupOption
              transportNo
              ordersPickupImagesByTransportId(orderBy: IMAGE_NO_ASC) {
                nodes {
                  imageNo
                  imageFile
                  id
                  transportId
                }
              }
              ordersEscortVehiclesByTransportId(orderBy: ESCORT_NO_ASC) {
                nodes {
                  id
                  escortNo
                  dropoffDistance
                  pickupDistance
                  origin
                  driverId
                  destination
                  transportBillingAmount
                  transportId
                  transportPaymentAmount
                  vehicleId
                  ordersEscortConnectedsByEscortId1 {
                    nodes {
                      id
                      ordersConnectedId
                      totalBilling
                      totalDistance
                      escortId2
                      escortId1
                      ordersEscortVehicleByEscortId1 {
                        id
                        origin
                        destination
                      }
                      ordersEscortVehicleByEscortId2 {
                        id
                        origin
                        destination
                      }
                    }
                  }
                  ordersEscortConnectedsByEscortId2 {
                    nodes {
                      id
                      ordersConnectedId
                      totalBilling
                      totalDistance
                      escortId2
                      escortId1
                      ordersEscortVehicleByEscortId1 {
                        id
                        origin
                        destination
                      }
                      ordersEscortVehicleByEscortId2 {
                        id
                        origin
                        destination
                      }
                    }
                  }
                  userByDriverId {
                    fullNameJa
                    id
                  }
                }
              }
              ordersDropoffImagesByTransportId(orderBy: IMAGE_NO_ASC) {
                nodes {
                  id
                  imageFile
                  imageNo
                  transportId
                }
              }
            }
          }
        }
    }
  }
`;

export const GET_ORDERS_CUSTOMERS_EXCEL = gql`
  query getOrdersDispatch(
    $orderBy: [OrdersOrderBy!],
    $filter: OrderFilter,
    ) {
      allOrders(filter: $filter, orderBy: $orderBy) {
        nodes {
          customerId
          id
          customerByCustomerId {
            id
            transportationUnitPriceId
            escortVehicleUnitPriceId
            nameJa
            formOutputName
            telNo1
            telNo2
          }
        }
    }
  }
`;

export const GET_ORDERS_EXCEL_OVERNIGHT = gql`
  query getOrdersDispatch(
    $orderBy: [OrdersOrderBy!],
    $filter: OrderFilter,
    ) {
      allOrders(filter: $filter, orderBy: $orderBy) {
        nodes {
          createdAt
          createdUserId
          customerId
          dispatchStatus
          displayOrder
          driverId1
          driverId2
          vehicleId
          id
          notes
          orderDate
          overnight
          packing1
          packing2
          transportBillingAmount
          transportDate
          transportDateOption
          transportDistance
          transportPaymentAmount
          updatedAt
          updatedUserId
          ordersConnectedsByOrderId1 {
            nodes {
              id
              connectionId
              orderId1
              orderId2
              totalBilling
              totalDistance
              orderByOrderId1 {
                id
                transportDate
                dispatchStatus
                ordersEscortVehiclesByOrderId {
                  nodes {
                    transportBillingAmount
                    transportPaymentAmount
                    id
                  }
                }
                ordersTransportsByOrderId {
                  nodes {
                    id
                    destination
                    origin
                    pickupBorrower
                    dropoffBorrower
                  }
                }
              }
              orderByOrderId2 {
                id
                transportDate
                dispatchStatus
                ordersEscortVehiclesByOrderId {
                  nodes {
                    transportBillingAmount
                    transportPaymentAmount
                    id
                  }
                }
                ordersTransportsByOrderId {
                  nodes {
                    id
                    destination
                    origin
                    pickupBorrower
                    dropoffBorrower
                  }
                }
              }
            }
          }
          ordersConnectedsByOrderId2 {
            nodes {
              id
              connectionId
              totalBilling
              totalDistance
              orderId1
              orderId2
              orderByOrderId1 {
                id
                transportDate
                dispatchStatus
                ordersEscortVehiclesByOrderId {
                  nodes {
                    transportBillingAmount
                    transportPaymentAmount
                    id
                  }
                }
                ordersTransportsByOrderId {
                  nodes {
                    id
                    destination
                    origin
                    pickupBorrower
                    dropoffBorrower
                  }
                }
              }
              orderByOrderId2 {
                id
                transportDate
                dispatchStatus
                ordersEscortVehiclesByOrderId {
                  nodes {
                    transportBillingAmount
                    transportPaymentAmount
                    id
                  }
                }
                ordersTransportsByOrderId {
                  nodes {
                    id
                    destination
                    origin
                    pickupBorrower
                    dropoffBorrower
                  }
                }
              }
            }
          }
          userByDriverId1 {
            id
            shortNameJa
            fullNameJaKatakana
            fullNameJa
          }
          userByDriverId2 {
            id
            shortNameJa
            fullNameJa
            fullNameJaKatakana
          }
          packagingByPacking1 {
            id
            packagingName
            weight
          }
          customerByCustomerId {
            id
            transportationUnitPriceId
            escortVehicleUnitPriceId
            nameJa
            formOutputName
            telNo1
            telNo2
          }
          vehicleByVehicleId {
            id
            vehicleNo
          }
          ordersTransportsByOrderId(orderBy: TRANSPORT_NO_ASC) {
            nodes {
              destination
              origin
              dropoffActualDatetime
              dropoffDate
              dropoffHourFrom
              dropoffHourTo
              dropoffMileage
              dropoffMinuteFrom
              dropoffMinuteTo
              dropoffOption
              dropoffAssignee
              dropoffBorrower
              dropoffContact
              dropoffMemo
              dropoffSiteName
              id
              nodeId
              numberEscortVehicle
              orderId
              pickupActualDatetime
              pickupAssignee
              pickupBorrower
              pickupContact
              pickupMemo
              pickupSiteName
              pickupDate
              pickupHour
              originColor
              destinationColor
              pickupMileage
              pickupMinute
              pickupOption
              transportNo
              ordersPickupImagesByTransportId(orderBy: IMAGE_NO_ASC) {
                nodes {
                  imageNo
                  imageFile
                  id
                  transportId
                }
              }
              ordersEscortVehiclesByTransportId(orderBy: ESCORT_NO_ASC) {
                nodes {
                  id
                  escortNo
                  dropoffDistance
                  pickupDistance
                  origin
                  driverId
                  destination
                  transportBillingAmount
                  transportId
                  transportPaymentAmount
                  vehicleId
                  ordersEscortConnectedsByEscortId1 {
                    nodes {
                      id
                      ordersConnectedId
                      totalBilling
                      totalDistance
                      escortId2
                      escortId1
                      ordersEscortVehicleByEscortId1 {
                        id
                        origin
                        destination
                      }
                      ordersEscortVehicleByEscortId2 {
                        id
                        origin
                        destination
                      }
                    }
                  }
                  ordersEscortConnectedsByEscortId2 {
                    nodes {
                      id
                      ordersConnectedId
                      totalBilling
                      totalDistance
                      escortId2
                      escortId1
                      ordersEscortVehicleByEscortId1 {
                        id
                        origin
                        destination
                      }
                      ordersEscortVehicleByEscortId2 {
                        id
                        origin
                        destination
                      }
                    }
                  }
                  userByDriverId {
                    fullNameJa
                    id
                  }
                }
              }
              ordersDropoffImagesByTransportId(orderBy: IMAGE_NO_ASC) {
                nodes {
                  id
                  imageFile
                  imageNo
                  transportId
                }
              }
            }
          }
        }
    }
  }
`;

export const GET_ORDERS_TRIMMED = gql`
query AllOrders(
  $orderBy: [OrdersOrderBy!],
  $filter: OrderFilter, 
  $arrivalsByVehicleFilter: ArrivalFilter, 
  $departureByVehicleFilter: DepartureFilter) {
  allOrders(filter: $filter, orderBy: $orderBy) {
    nodes {
      createdAt
      createdUserId
      customerId
      dispatchStatus
      displayOrder
      driverId1
      driverId2
      vehicleId
      id
      notes
      orderDate
      overnight
      packing1
      packing2
      transportBillingAmount
      transportDate
      transportDateOption
      transportDistance
      transportPaymentAmount
      updatedAt
      updatedUserId
      userByDriverId1 {
        id
        fullNameJa
        fullNameJaKatakana
      }
      userByDriverId2 {
        id
        fullNameJa
        fullNameJaKatakana
      }
      packagingByPacking1 {
        id
        packagingName
      }
      customerByCustomerId {
        id
        nameJa
        formOutputName
      }
      vehicleByVehicleId {
        id
        vehicleNo
      }
      ordersTransportsByOrderId(orderBy: TRANSPORT_NO_ASC) {
        nodes {
          destination
          origin
          dropoffActualDatetime
          dropoffAssignee
          dropoffBorrower
          dropoffContact
          dropoffDate
          dropoffHourFrom
          dropoffHourTo
          dropoffMileage
          dropoffMinuteFrom
          dropoffMinuteTo
          dropoffOption
          dropoffMemo
          dropoffSiteName
          id
          nodeId
          numberEscortVehicle
          orderId
          pickupActualDatetime
          pickupAssignee
          pickupBorrower
          pickupContact
          pickupDate
          pickupHour
          pickupMemo
          originColor
          destinationColor
          pickupMileage
          pickupMinute
          pickupOption
          pickupSiteName
          transportNo
          ordersEscortVehiclesByTransportId(orderBy: ESCORT_NO_ASC) {
            nodes {
              id
              escortNo
              dropoffDistance
              pickupDistance
              origin
              driverId
              destination
              transportBillingAmount
              transportId
              transportPaymentAmount
              vehicleId
              userByDriverId {
                fullNameJa
                id
              }
            }
          }
        }
      }
    },
    totalCount
  }
  allArrivals(filter:$arrivalsByVehicleFilter){
    nodes {
      id
      vehicleId
      workingDay
      arrivalMileage
      arrivalDatetime
      weather
      fuelOilSupply
    },
    totalCount
  }
  allDepartures(filter:$departureByVehicleFilter){
    nodes {
      id
      vehicleId
      workingDay
      departuresMileage
      departuresDatetime
    },
    totalCount
  }
}
`;

export const GET_VEHICLE_ANALYSIS_REPORTING = gql`
  query vehicleAnalyisReporting(
    $orderBy: [OrdersOrderBy!],
    $filter: OrderFilter, 
    $arrivalsByVehicleFilter: ArrivalFilter, 
    $departureByVehicleFilter: DepartureFilter) {
    allOrders(filter: $filter, orderBy: $orderBy) {
      nodes {
        id
        transportDate
        ordersTransportsByOrderId(orderBy: TRANSPORT_NO_ASC) {
          nodes {
            id
            dropoffMileage
            pickupMileage
          }
        }
      }
    }
    allArrivals(filter:$arrivalsByVehicleFilter){
      nodes {
        id
        vehicleId
        workingDay
        arrivalMileage
        arrivalDatetime
        weather
        orderId
        fuelOilSupply
      }
    }
    allDepartures(filter:$departureByVehicleFilter){
      nodes {
        id
        vehicleId
        orderId
        workingDay
        departuresMileage
        departuresDatetime
      }
    }
  }
`;

export const GET_ORDER_TRANSPORTS_COLORS = gql`
  query GET_ORDER_TRANSPORTS_COLORS {
    allOriginOrdersTransportsColors {
      nodes {
        count
        nameJa
        origin
        pickupAssignee
        pickupBorrower
      }
      totalCount
    }

    allDestinationOrdersTransportsColors {
      nodes {
        count
        destination
        dropoffAssignee
        dropoffBorrower
        nameJa
      }
      totalCount
    }
  }
`

const constructOvernightDisplay = (date) => {
  const overnightTransportFilter = {
    transportDateOption: { equalTo: 'SPECIFIED_DAY' },
    overnight: { equalTo: 'YES' },
    ordersTransportsByOrderId: {
      overnight: { equalTo: 'YES' },
      ordersTransportsByOrderId: {
        some: {
          and: [
            { pickupDate: { lessThanOrEqualTo: date } },
            { dropoffDate: { greaterThanOrEqualTo: date } },
          ]
        }
      }
    }
  };
  return overnightTransportFilter;
};

export const CONSTRUCT_ORDERS_QUERY = (dateList) => {
  const query = dateList.map((date, index) => {
    const overnightDate = date ? moment(date).subtract(1, 'days').format('YYYY-MM-DD') : null;
    const name = `a_${index}`;
    return `
      ${name}: allOrders(filter: { 
        dispatchStatus: { equalTo: COMPLETED }, 
        or: [
          { transportDate: { equalTo: "${date}" } },
          { overnight: { equalTo: YES }, transportDate: { equalTo: "${overnightDate}" }, transportDateOption: { equalTo: SPECIFIED_DAY } },
          {
            overnight: { equalTo: YES },
            ordersTransportsByOrderId: {
              some: {
                and: [
                  { pickupDate: { lessThanOrEqualTo: "${date}" } },
                  { dropoffDate: { greaterThanOrEqualTo: "${date}" } },
                ]
              }
            }
          }
        ]
      }, orderBy: [TRANSPORT_DATE_ASC, DISPLAY_ORDER_ASC, CREATED_AT_ASC]) {
        nodes {
          createdAt
          createdUserId
          customerId
          dispatchStatus
          displayOrder
          driverId1
          driverId2
          vehicleId
          id
          notes
          orderDate
          overnight
          packing1
          packing2
          transportBillingAmount
          transportDate
          transportDateOption
          transportDistance
          transportPaymentAmount
          updatedAt
          updatedUserId
          ordersConnectedsByOrderId1 {
            nodes {
              id
              connectionId
              orderId1
              orderId2
              totalBilling
              totalDistance
              orderByOrderId1 {
                id
                ordersTransportsByOrderId {
                  nodes {
                    pickupActualDatetime
                    dropoffActualDatetime
                  }
                }
              }
              orderByOrderId2 {
                id
                ordersTransportsByOrderId {
                  nodes {
                    pickupActualDatetime
                    dropoffActualDatetime
                  }
                }
              }
            }
          }
          ordersConnectedsByOrderId2 {
            nodes {
              id
              connectionId
              totalBilling
              totalDistance
              orderId1
              orderId2
              orderByOrderId1 {
                id
                ordersTransportsByOrderId {
                  nodes {
                    pickupActualDatetime
                    dropoffActualDatetime
                  }
                }
              }
              orderByOrderId2 {
                id
                ordersTransportsByOrderId {
                  nodes {
                    pickupActualDatetime
                    dropoffActualDatetime
                  }
                }
              }
            }
          }
          userByDriverId1 {
            id
            shortNameJa
            fullNameJaKatakana
            fullNameJa
          }
          userByDriverId2 {
            id
            shortNameJa
            fullNameJa
            fullNameJaKatakana
          }
          packagingByPacking1 {
            id
            packagingName
          }
          customerByCustomerId {
            id
            nameJa
            formOutputName
          }
          vehicleByVehicleId {
            id
            vehicleNo
            arrivalsByVehicleId(orderBy: CREATED_AT_ASC) {
              nodes {
                id
                vehicleId
                workingDay
                orderId
                arrivalMileage
                arrivalDatetime
                weather
                fuelOilSupply
              }
            }
            departuresByVehicleId(orderBy: CREATED_AT_ASC) {
              nodes {
                id
                vehicleId
                workingDay
                orderId
                departuresMileage
                departuresDatetime
              }
            }
          }
          ordersTransportsByOrderId(orderBy: TRANSPORT_NO_ASC) {
            nodes {
              destination
              origin
              dropoffActualDatetime
              dropoffAssignee
              dropoffBorrower
              dropoffContact
              dropoffDate
              dropoffHourFrom
              dropoffHourTo
              dropoffMileage
              dropoffMinuteFrom
              dropoffMinuteTo
              dropoffOption
              dropoffMemo
              dropoffSiteName
              id
              nodeId
              numberEscortVehicle
              orderId
              pickupActualDatetime
              pickupAssignee
              pickupBorrower
              pickupContact
              pickupDate
              pickupHour
              pickupMemo
              originColor
              destinationColor
              pickupMileage
              pickupMinute
              pickupOption
              pickupSiteName
              transportNo
              ordersPickupImagesByTransportId(orderBy: IMAGE_NO_ASC) {
                nodes {
                  imageNo
                  imageFile
                  id
                  transportId
                }
              }
              ordersEscortVehiclesByTransportId(orderBy: ESCORT_NO_ASC) {
                nodes {
                  id
                  escortNo
                  dropoffDistance
                  pickupDistance
                  origin
                  driverId
                  destination
                  transportBillingAmount
                  transportId
                  transportPaymentAmount
                  vehicleId
                  userByDriverId {
                    fullNameJa
                    id
                  }
                }
              }
              ordersDropoffImagesByTransportId(orderBy: IMAGE_NO_ASC) {
                nodes {
                  id
                  imageFile
                  imageNo
                  transportId
                }
              }
            }
          }
        }
      }`;
  }).join('');

  return gql`
    query getOrdersDispatch {
      ${query}
    }
  `;
}

export const GET_DEPARTURES = gql`
  query GET_DEPARTURES($orderId: Int!) {
    allDepartures(filter: {orderId: {equalTo: $orderId} }) {
      nodes {
        id
        vehicleId
        workingDay
        orderId
      }
    }
  }
`;

export const DELETE_DEPARTURE = gql`
  mutation DELETE_DEPARTURE($id: Int!) {
    deleteDepartureById(input: {id: $id}) {
      deletedDepartureId
    }
  }
`;

export const GET_ORDER_THROUGH_TRANSPORT = gql`
query getOrders ($filter: OrdersTransportFilter) {
    allOrdersTransports (filter: $filter, orderBy: ORDER_BY_ORDER_ID__TRANSPORT_DATE_DESC) {
        nodes {
            orderByOrderId {
                orderDate
                transportDate
                dispatchStatus
                displayOrder
                vehicleId
                driverId1
                driverId2
                userByDriverId1 {
                  fullNameJa
                  fullNameJaKatakana
                  shortNameJa
                }
                userByDriverId2 {
                  fullNameJa
                  fullNameJaKatakana
                  shortNameJa
                }
                customerByCustomerId {
                  nameJa
                }
                customerId
                packagingByPacking1 {
                  packagingName
                }
                packing1
                packing2
                notes
                transportDistance
                transportBillingAmount
                transportPaymentAmount
                vehicleByVehicleId {
                    vehicleNo
                }
            }
            ordersEscortVehiclesByTransportId {
                nodes {
                    orderId
                    transportBillingAmount
                    transportPaymentAmount
                }
            }
            orderId
            origin
            pickupDate
            pickupActualDatetime
            pickupBorrower
            pickupAssignee
            pickupContact
            pickupSiteName
            pickupMemo
            originColor
            destinationColor
            destination
            dropoffDate
            dropoffActualDatetime
            dropoffBorrower
            dropoffAssignee
            dropoffContact
            dropoffSiteName
            dropoffMemo
            ordersPickupImagesByTransportId(orderBy: IMAGE_NO_ASC) {
                nodes {
                  imageNo
                  imageFile
                  id
                  transportId
                }
              }
              ordersDropoffImagesByTransportId(orderBy: IMAGE_NO_ASC) {
                nodes {
                  id
                  imageFile
                  imageNo
                  transportId
                }
              }
        }
    }
}
`;

export const CREATE_ORDER_CONNECTED = gql`
  mutation createOrdersConnected($input: CreateOrdersConnectedInput!, $billing1: Float, $id1: Int!, $billing2: Float, $id2: Int!) {
    a: createOrdersConnected(input: $input) {
      ordersConnected {
        escortConnected
        id
        orderId1
        orderId2
        totalDistance
        totalBilling
      }
    }
    b: updateOrderById(input: {orderPatch: {transportBillingAmount: $billing1}, id: $id1}) {
      order {
        id
        transportBillingAmount
      }
    }
    c: updateOrderById(input: {orderPatch: {transportBillingAmount: $billing2}, id: $id2}) {
      order {
        id
        transportBillingAmount
      }
    }
  }
`;

export const CREATE_ESCORT_CONNECTED = gql`
  mutation createOrdersEscortConnected($input: CreateOrdersEscortConnectedInput!, $billing1: Float, $id1: Int!, $billing2: Float, $id2: Int!) {
    a: createOrdersEscortConnected(input: $input) {
      ordersEscortConnected {
        id
        escortId1
        escortId2
        totalBilling
        totalDistance
      }
    }
    b: updateOrdersEscortVehicleById(input: {ordersEscortVehiclePatch: {transportBillingAmount: $billing1}, id: $id1}) {
      ordersEscortVehicle {
        id
        transportBillingAmount
      }
    }
    c: updateOrdersEscortVehicleById(input: {ordersEscortVehiclePatch: {transportBillingAmount: $billing2}, id: $id2}) {
      ordersEscortVehicle {
        id
        transportBillingAmount
      }
    }
  }
`;

export const DELETE_ORDER_CONNECTED = gql`
  mutation deleteOrdersConnectedById($id: Int!) {
    deleteOrdersConnectedById(input: {id: $id}) {
      deletedOrdersConnectedId
    }
  }
`;

export const DISCONNECT_ESCORT = gql`
  mutation disconenct($connectId: Int!) {
    updateOrdersConnectedById(input: {ordersConnectedPatch: {escortConnected: false}, id: $connectId}) {
      clientMutationId
    }
  }
`;

export const CONNECT_ESCORT = gql`
  mutation connect($connectId: Int!) {
    updateOrdersConnectedById(input: {ordersConnectedPatch: {escortConnected: true}, id: $connectId}) {
      clientMutationId
    }
  }
`;

export const ESCORT_CONNECTED_BY_CONNECTION = gql`
  query allOrdersEscortConnecteds($connectId: Int!) {
    allOrdersEscortConnecteds(filter: {ordersConnectedId: {equalTo: $connectId}}) {
      nodes {
        id
      }
    }
  }
`;

export const DELETE_ESCORT_CONNECTED = gql`
  mutation deleteEscortConnected($id: Int!) {
    deleteOrdersEscortConnectedById(input: {id: $id}) {
      clientMutationId
    }
  }
`;

export const GET_LAST_CONNECTION = gql`
  query allOrdersConnecteds($connectionId: String) {
    allOrdersConnecteds(filter: {connectionId: {like: $connectionId } }, orderBy: CREATED_AT_DESC, first: 1) {
      nodes {
        connectionId
        id
      }
    }
  }
`;

export const GET_CONNECTION_DETAILS = gql`
  query allOrdersConnecteds($connectionId: String) {
    allOrdersConnecteds(filter: {connectionId: {equalTo: $connectionId}}) {
      nodes {
        orderByOrderId1 {
          transportDistance
          customerByCustomerId {
            escortVehicleUnitPriceId
            transportationUnitPriceId
          }
          packagingByPacking1 {
            weight
          }
          ordersTransportsByOrderId {
            nodes {
              ordersEscortVehiclesByTransportId {
                nodes {
                  transportBillingAmount
                  transportDistance
                  pickupDistance
                  transportPaymentAmount
                  driverId
                  id
                }
              }
            }
          }
        }
        orderByOrderId2 {
          transportDistance
          customerByCustomerId {
            escortVehicleUnitPriceId
            transportationUnitPriceId
          }
          packagingByPacking1 {
            weight
          }
          ordersTransportsByOrderId {
            nodes {
              ordersEscortVehiclesByTransportId {
                nodes {
                  transportBillingAmount
                  transportDistance
                  pickupDistance
                  transportPaymentAmount
                  driverId
                  id
                }
              }
            }
          }
        }
      }
    }
  }
`