import React, { Fragment } from 'react';
import CustomSelect from '../CustomDataEntry/CustomSelect';

const vehicleTypes = [
    { id: 'BLANK', value: '指定なし' },
    { id: 'TRAILER', value: 'トレーラー' },
    { id: 'TRUCK_UNIC_', value: 'トラック(ユニック)' },
    { id: 'TRUCK_SELF_LOADER_', value: 'トラック(セルフ)' },
    { id: 'OUTSOURCE', value: '傭車' },
    { id: 'ESCORT_VEHICLE_COMPANY_', value: '誘導車（自社）' },
    { id: 'ESCORT_VEHICLE_OUTSOURCE_', value: '誘導車（傭車）' },
];

export default (props) => {
    return (
        <Fragment>
            <CustomSelect {...props}
                options={vehicleTypes}
            />
        </Fragment>
    )
};