import React, {Fragment, forwardRef, useImperativeHandle} from 'react';
import CustomSelect from '../CustomDataEntry/CustomSelect';
import {getAll} from '../../queries/MasterMaintenance/unitprice';
import {Query} from 'react-apollo';

const UnitPrices = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
        defValue:  props.decorator.initialValue
      }));

    return (<Query
        query={getAll}
        fetchPolicy='network-only'>
        {
            ({data, loading}) => {

                if( loading ) return <Fragment></Fragment>;

                let unitPrices = data.allUnitPrices.nodes;
                if(props.filter) {
                    unitPrices = data.allUnitPrices.nodes.filter((price)=>{
                        return price.unitPriceCategory === props.filter;
                    });
                }
                return (
                    <Fragment>
                        <CustomSelect {...props}
                                      options={unitPrices}
                                      optionId={'id'}
                                      optionValue={'unitPriceName'}/>
                    </Fragment>
                );
            }
        }
    </Query>)
});

export default UnitPrices;
