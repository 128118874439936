import { Button, Col, Collapse, DatePicker, Form, Icon, Input, Modal, Row, Select, TimePicker, Typography, Upload } from "antd";
import jaJP from 'antd/lib/date-picker/locale/ja_JP';
import TextArea from 'antd/lib/input/TextArea';
import _ from 'lodash';
import moment from 'moment';
import 'moment/locale/ja';
import React, { useContext, useEffect, useState } from 'react';
import { FormContext, OrderContext } from '../../../../utils/context';
import adhocTranslator from '../../../../utils/locales/translate-adhoc';
import { escortTypes, formatTypes, modeTypes, schedTypes, colorTypes } from '../../constants';
import { convertHalfWidthToFullWidth, hasHalfWidthCharacter } from '../../functions';
import EscortForm from './escort-form';
import LocationInput from '../location';

const lt = adhocTranslator('K020-010');

const { Option } = Select;
const { Panel } = Collapse;

const indexTypes = {
  1: '１',
  2: '２',
  3: '３',
  4: '４',
  5: '５'
}
const initialAssignee = {
  assigned: null,
  contact: null
}
const TransportForm = (props) => {
  const { transportIndex, transportValue, notify, client } = props;
  const { form, form: { getFieldDecorator, getFieldValue, setFieldsValue } } = useContext(FormContext);
  const { imported, formData, modalSetter, orderSetter, isDriver } = useContext(OrderContext);

  const [state, setState] = useState({
    pickupTime: false,
    dropoffTime: false,
    images: ['', '', '', ''],
    escort: null,
    pickupAssignees: [],
    dropoffAssignees: [],
    transportData: {}
  });

  const [imageState, setImageState] = useState({
    previewVisible: false,
    previewImage: '',
    previewTitle: '',
  });

  const [showMemoOrigin, setShowMemoOrigin] = useState(false);
  const [showMemoDestination, setShowMemoDestination] = useState(false);

  const { transportData } = state;

  const stateSetter = (value) => setState(state => ({ ...state, ...value }));
  const imageSetter = (value) => setImageState(state => ({ ...state, ...value }));

  const onTimeChange = (key) => (value) => {
    stateSetter({ [key]: value === 'SPECIFIED_TIME' });
  };

  const onRenderEscort = () => {
    const escort = getFieldValue(`numberEscortVehicle_${transportIndex}`);
    stateSetter({ escort })
  }

  const onLocationClick = (mode) => () => {
    // Set data
    const values = form.getFieldsValue();
    const defaults = {
      customerName: values['customerId-display'], //customerName
      origin_destination_name: mode === 'origin' ? values[`origin_${transportIndex}`] : values[`destination_${transportIndex}`], //origin_destination_name
      borrower: mode === 'origin' ? values[`pickupBorrower_${transportIndex}`] : values[`dropoffBorrower_${transportIndex}`], // borrower
      person_in_charge: mode === 'origin' ? values[`pickupAssignee_${transportIndex}_0`] : values[`dropoffAssignee_${transportIndex}_0`], //person_in_charge
    }
    modalSetter({ visible: true, mode, defaults, transportIndex: transportIndex - 1 });
  }

  const constructAssignee = (payload, transportValue, mode = null, dataAffix = {}) => {
    if (!mode || mode === 'pickup') {
      if ((transportValue || {}).pickupAssignee) {
        const assignees = transportValue.pickupAssignee.split('|');
        const contacts = transportValue.pickupContact.split('|');
        const maxPickup = _.max([assignees.length, contacts.length]);
        payload.pickupAssignees = [...new Array(maxPickup)].map((data, index) => ({
          assigned: assignees[index] && assignees[index] !== 'empty' ? assignees[index] : null,
          contact: contacts[index] && contacts[index] !== 'empty' ? contacts[index] : null,
        }))
      } else {
        payload.pickupAssignees = [{}]
      }
    }

    if (!mode || mode === 'dropoff') {
      if ((transportValue || {}).dropoffAssignee) {
        const assignees = transportValue.dropoffAssignee.split('|');
        const contacts = transportValue.dropoffContact.split('|');
        const maxDropoff = _.max([assignees.length, contacts.length]);
        payload.dropoffAssignees = [...new Array(maxDropoff)].map((data, index) => ({
          assigned: assignees[index] && assignees[index] !== 'empty' ? assignees[index] : null,
          contact: contacts[index] && contacts[index] !== 'empty' ? contacts[index] : null,
        }))
      } else {
        payload.dropoffAssignees = [{}]
      }
    }
    return payload;
  };

  useEffect(() => {
    if (transportValue) {
      let payload = {
        pickupTime: transportValue.pickupOption === 'SPECIFIED_TIME',
        dropoffTime: transportValue.dropoffOption === 'SPECIFIED_TIME',
        transportData: { ...transportValue },
        escort: (transportValue.escort || []).length || null,
      }

      payload = constructAssignee(payload, transportValue)
      stateSetter(payload);
    }
  }, [formData, transportValue]);

  useEffect(() => {
    if (imported && (+transportIndex === (imported.transportIndex + 1))) {
      const pickupOption = form.getFieldValue(`pickupOption_${transportIndex}`);
      const dropoffOption = form.getFieldValue(`dropoffOption_${transportIndex}`);
      const pickupTime = form.getFieldValue(`pickupTime_${transportIndex}`);
      const pickupTimeTo = form.getFieldValue(`pickupTimeTo_${transportIndex}`);
      const dropoffTimeFrom = form.getFieldValue(`dropoffTimeFrom_${transportIndex}`);
      const dropoffTimeTo = form.getFieldValue(`dropoffTimeTo_${transportIndex}`);

      const { mode, data, importMode } = imported;
      const affix = mode === 'origin' ? 'pickup' : 'dropoff';
      const dataAffix = importMode === 'origin' ? { assignee: 'pickupAssignee', contact: 'pickupContact' } : { assignee: 'dropoffAssignee', contact: 'dropoffContact' };

      const fileKey = importMode === 'origin' ? 'pickupImages' : 'dropoffImages';
      const affixFileKey = mode === 'origin' ? 'pickupImages' : 'dropoffImages';
      const clonedForm = _.cloneDeep(formData);
      const transport = clonedForm.transport[transportIndex - 1];
      transport[affixFileKey] = [...data[fileKey]];
      transport[`${affix}Assignee`] = data[dataAffix.assignee];
      transport[`${affix}Contact`] = data[dataAffix.contact];
      orderSetter({ formData: { ...clonedForm } });

      setTimeout(() => {
        if (pickupOption === 'SPECIFIED_TIME') {
          stateSetter({ pickupTime: true });
          setFieldsValue({
            [`pickupTime_${transportIndex}`]: pickupTime,
            [`pickupTimeTo_${transportIndex}`]: pickupTimeTo
          })
        }

        if (dropoffOption === 'SPECIFIED_TIME') {
          stateSetter({ dropoffTime: true });
          setFieldsValue({
            [`dropoffTimeFrom_${transportIndex}`]: dropoffTimeFrom,
            [`dropoffTimeTo_${transportIndex}`]: dropoffTimeTo
          })
        }
      }, 200)
    }
  }, [imported]);

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  const onPreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    imageSetter({
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
    })
  };

  const onLocationChange = (field, value) => {
    if (value === '') {
      setFieldsValue({ [`${field}Color_${transportIndex}`]: null });
    }
    const fieldValue = convertHalfWidthToFullWidth(value)
    setFieldsValue({ [`${transportIndex}_${field}_${1}`]: fieldValue });
    if (_.includes(fieldValue, 'Ｐ')) {
      setFieldsValue({ [`${field}Color_${transportIndex}`]: 'BLACK' });
    }
  };

  // check if size is smaller than 3mb
  const checkFileSize = file => {
    if (!file.size) return true
    return file.size / 1024 / 1024 < 3;
  }

  const onFileChange = (key) => ({ fileList }) => {
    if (fileList.length > 5) {
      notify('error', "アップロードファイルは、５ファイルまで登録可能です。")
      return
    }

    // check if size is smaller than 3mb
    if (fileList.length && !checkFileSize(fileList[fileList.length - 1])) {
      notify('error', "画像ファイルは、3MB以下にしてください。")
      return
    }

    const clonedForm = _.cloneDeep(formData);
    const transport = clonedForm.transport[transportIndex - 1];
    transport[key] = [...fileList];

    orderSetter({ formData: { ...clonedForm } });
  };

  const uploaderProps = {
    beforeUpload: () => false,
    accept: 'image/*',
    listType: 'picture-card',
    className: 'avatar-uploader',
    showUploadList: {
      showDownloadIcon: false,
      showPreviewIcon: true,
    },
  }

  const constructFileList = key => {
    if (_.isEmpty(transportData[key])) return []

    return transportData[key].map(image => {
      if (!image.imageNo) return image
      return {
        uid: image.imageNo,
        url: image.imageFile
      }
    })
  }

  const getCollapseProps = () => {
    let defaultProps = {
      className: 'escort-collapse',
      style: styles.collapseStyle,
      expandIcon: ({ isActive }) => <Icon type='caret-right' rotate={isActive ? 90 : 0} />
    }

    if (isDriver) {
      defaultProps.activeKey = [...new Array(state.escort)].map((escort, index) => index)
      defaultProps.disabled = true
    }

    return defaultProps
  }

  const convertFullWidthOnBlur = (field, value) => {
    if (value) {
      setFieldsValue({ [field]: convertHalfWidthToFullWidth(value) })
    }
  }

  const onActionClick = (type, field, index) => () => {
    if (type === 'plus-circle') {
      // Add
      state[field].push({ ...initialAssignee });
    } else {
      // Remove
      state[field].splice(index, 1);
    }
    stateSetter({ [field]: [...state[field]] });
    // Set
  }

  const showLinks = (key, isDest = false) => () => {
    const value = form.getFieldValue(key);
    if (!!value) {
      isDest ? setShowMemoDestination(!showMemoDestination) : setShowMemoOrigin(!showMemoOrigin)
    }
  }

  const renderMemo = (key) => {
    const values = form.getFieldValue(key);

    const links = values.split(/\s+/).filter(link => link.includes('https') || link.includes('www'));
    return (
      <Row type='flex' style={{ alignItems: 'center' }}>
        <Typography style={{ marginLeft: 72, fontWeight: '500', marginBottom: 10 }}>メモ</Typography>
        <Row style={{ marginTop: 7, marginBottom: 20, marginRight: 8, maxWidth: 438, minWidth: 438, minHeight: 205 }}>
          {links.map(link => <a href={link} target='_blank' style={{ paddingLeft: 28, display: 'block', color: 'blue', textDecorationLine: 'underline' }}>{link}</a>)}
        </Row>
      </Row>
    )
  }

  const extract = (values) => {
    const regexp = /(https?:\/\/(?:www\.|(?!www))[^\s\.]+\.[^\s]{2,}|www\.[^\s]+\.[^\s]{2,})/gi;
    const urls = regexp.exec(values);
    console.log('urls', urls)
    if (urls) {
      return urls;
    }
  }

  return (
    <Row style={{ padding: 10 }}>
      <Row className='transport-form'>
        <Col span={12}>
          <Typography style={{ fontWeight: 'bold', marginBottom: 20 }}> 【発地】</Typography>

          <Row type='flex'>
            <LocationInput
              form={form}
              fieldName={`origin_${transportIndex}`}
              type='origin'
              initialValue={transportData.origin}
              disabled={isDriver}
              onBlur={(value) => {
                onLocationChange('origin', value);
                convertFullWidthOnBlur(`origin_${transportIndex}`, value);

              }}
            />
            <Button onClick={onLocationClick(modeTypes.ORIGIN)} className='edit-btn-trigger' style={{ marginRight: 20 }} disabled={isDriver}>選択</Button>
            <Form.Item style={styles.formItem}>
              {getFieldDecorator(`originColor_${transportIndex}`, {
                initialValue: transportData.originColor || null
              })(
                <Select
                  style={{ width: 140, marginRight: 15 }}
                  allowClear
                >
                  {colorTypes.map(color => (
                    <Option key={color.key} value={color.value}>{color.label}</Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Row>

          <Row type='flex'>
            <Form.Item style={styles.formItem} label='日時'>
              {getFieldDecorator(`pickupDate_${transportIndex}`, {
                initialValue: transportData.pickupDate || null,
              })(
                <DatePicker
                  format={formatTypes.DATE}
                  locale={jaJP}
                  placeholder={lt('txt_ｆrom_date')}
                  size='default'
                  style={{ width: 150, display: 'inline-block', marginRight: 15 }}
                  disabled={isDriver}
                />
              )}
            </Form.Item>
            <Form.Item style={styles.formItem}>
              {getFieldDecorator(`pickupOption_${transportIndex}`, {
                initialValue: transportData.pickupOption || null
              })(
                <Select
                  style={{ width: 120, marginRight: 15 }}
                  onChange={onTimeChange('pickupTime')}
                  disabled={isDriver}
                  allowClear
                >
                  {schedTypes.map(sched => (
                    <Option key={sched.key} value={sched.value}>{sched.label}</Option>
                  ))}
                </Select>
              )}
            </Form.Item>

            {state.pickupTime && (
              <>
                <Form.Item style={styles.formItem}>
                  {getFieldDecorator(`pickupTime_${transportIndex}`, {
                    initialValue: transportData.pickupTime ? moment(transportData.pickupTime, 'HH:mm') : null
                  })(
                    <TimePicker
                      className={`pickupTime_${transportIndex}`}
                      allowClear
                      minuteStep={10}
                      style={{ width: 110, marginRight: 5 }}
                      placeholder='時間を選択'
                      format={formatTypes.TIME}
                      defaultOpenValue={moment('00:00')}
                      disabled={isDriver}
                    />
                  )}
                </Form.Item>
                <Form.Item style={styles.formItem}>
                  {getFieldDecorator(`pickupTimeTo_${transportIndex}`, {
                    initialValue: transportData.pickupTimeTo ? moment(transportData.pickupTimeTo, 'HH:mm') : null
                  })(
                    <TimePicker
                      className={`pickupTimeTo_${transportIndex}`}
                      allowClear
                      minuteStep={10}
                      style={{ width: 110 }}
                      defaultOpenValue={moment('00:00')}
                      placeholder='時間を選択'
                      format={formatTypes.TIME}
                      disabled={isDriver}
                    />
                  )}
                </Form.Item>
              </>
            )}
          </Row>

          <Form.Item style={styles.formItem} label='借主'>
            {getFieldDecorator(`pickupBorrower_${transportIndex}`, {
              initialValue: transportData.pickupBorrower ? convertHalfWidthToFullWidth(transportData.pickupBorrower) : null,
              rules: [{
                validator: async (rule, value) => {
                  if (value && hasHalfWidthCharacter(value)) {
                    throw new Error('半角文字が検出されました。');
                  }
                }
              }],
            })(
              <Input
                style={{ width: 420 }}
                disabled={isDriver}
                onBlur={(e) => convertFullWidthOnBlur(`pickupBorrower_${transportIndex}`, e.target.value)}
              />
            )}
          </Form.Item>

          {state.pickupAssignees.map((pickupAssignee, index) => {
            const lastIndex = !!index || state.pickupAssignees.length === 5 ? 'close-circle' : 'plus-circle'
            return (
              <>
                <Row type='flex' align='middle' className='assignee'>
                  <Form.Item style={{ ...styles.formItem }} label={`担当者/連絡先${indexTypes[index + 1]}`}>
                    {getFieldDecorator(`pickupAssignee_${transportIndex}_${index}`, {
                      initialValue: pickupAssignee.assigned ? convertHalfWidthToFullWidth(pickupAssignee.assigned) : null,
                      preserve: false,
                      rules: [{
                        validator: async (rule, value) => {
                          if (value && hasHalfWidthCharacter(value)) {
                            throw new Error('半角文字が検出されました。');
                          }
                        }
                      }],
                    })(
                      <Input
                        placeholder='担当者'
                        style={{ width: 200 }}
                        disabled={isDriver}
                        onBlur={(e) => convertFullWidthOnBlur(`pickupAssignee_${transportIndex}_${index}`, e.target.value)}
                      />
                    )}
                  </Form.Item>
                  <Form.Item style={{ ...styles.formItem, marginLeft: 20 }}>
                    {getFieldDecorator(`pickupContact_${transportIndex}_${index}`, {
                      initialValue: pickupAssignee.contact,
                      preserve: false,
                    })(
                      <Input
                        placeholder='連絡先'
                        style={{ width: 200 }}
                        disabled={isDriver}
                      />
                    )}
                  </Form.Item>
                  {lastIndex === 'plus-circle' ?
                    <Icon onClick={onActionClick(lastIndex, 'pickupAssignees', index)} type='plus-circle' theme='twoTone' style={{ fontSize: 18, marginLeft: 10, maxWidth: 30, marginBottom: 5 }} /> :
                    <Icon onClick={onActionClick(lastIndex, 'pickupAssignees', index)} type='close-circle' theme='twoTone' style={{ fontSize: 18, marginLeft: 10, maxWidth: 30, marginBottom: 5 }} twoToneColor='#E84855' />
                  }
                </Row>
              </>)
          })}

          <Form.Item style={styles.formItem} label='現場名'>
            {getFieldDecorator(`pickupSiteName_${transportIndex}`, {
              initialValue: transportData.pickupSiteName,
            })(
              <Input
                style={{ width: 420 }}
                disabled={isDriver}
              />
            )}
          </Form.Item>

          <Row type='flex' flexDirection='row' style={{ alignItems: 'center' }}>
            {showMemoOrigin ? renderMemo(`pickupMemo_${transportIndex}`) :
              <Form.Item style={styles.formItem} label='メモ'>
                {getFieldDecorator(`pickupMemo_${transportIndex}`, {
                  initialValue: transportData.pickupMemo,
                  preserve: true
                })(
                  <TextArea
                    style={{ width: 420, marginRight: 10 }}
                    disabled={isDriver}
                    rows={10}
                  />
                )}
              </Form.Item>
            }
            <Button style={{ marginBottom: 8, marginLeft: 2 }} type='primary' onClick={showLinks(`pickupMemo_${transportIndex}`)}>リンクに変換</Button>
          </Row>

          <Row type='flex' style={{ flexDirection: 'row', marginLeft: 50 }}>
            <Upload
              {...uploaderProps}
              fileList={constructFileList('pickupImages')}
              onChange={onFileChange('pickupImages')}
              disabled={isDriver}
              onPreview={onPreview}
            >
              <Row>
                <Icon type='plus' />
                <Typography style={{ fontSize: 12 }}>アップロード</Typography>
              </Row>
            </Upload>
          </Row>

          <Row type='flex' style={{ marginTop: 20 }}>
            <Form.Item style={styles.formItem} label='誘導車'>
              {getFieldDecorator(`numberEscortVehicle_${transportIndex}`, {
                initialValue: state.escort
              })(
                <Select
                  style={{ width: 165 }}
                  disabled={isDriver}
                >
                  <Option key={0} value={0}> ０台 </Option>
                  <Option key={1} value={1}> １台 </Option>
                  <Option key={2} value={2}> ２台 </Option>
                  <Option key={3} value={3}> ３台 </Option>
                  <Option key={4} value={4}> ４台 </Option>
                </Select>
              )}
            </Form.Item>
            <Button
              onClick={onRenderEscort}
              className='escort-btn-trigger'
              status='primary'
              style={{ marginLeft: 10 }}
              disabled={isDriver}
            >
              選択
            </Button>
          </Row>
        </Col>
        <Col span={12}>
          <Typography style={{ fontWeight: 'bold', marginBottom: 20 }}> 【着地】</Typography>
          <Row type='flex'>
            <LocationInput
              form={form}
              type='destination'
              fieldName={`destination_${transportIndex}`}
              initialValue={transportData.destination}
              disabled={isDriver}
              onBlur={(value) => {
                onLocationChange('destination', value);
                convertFullWidthOnBlur(`destination_${transportIndex}`, value);
              }}
            />
            <Button onClick={onLocationClick(modeTypes.DESTINATION)} className='edit-btn-trigger' style={{ marginRight: 20 }} disabled={isDriver}>選択</Button>

            <Form.Item style={styles.formItem}>
              {getFieldDecorator(`destinationColor_${transportIndex}`, {
                initialValue: transportData.destinationColor || null
              })(
                <Select
                  allowClear
                  style={{ width: 140, marginRight: 15 }}
                >
                  {colorTypes.map(color => (
                    <Option key={color.key} value={color.value}>{color.label}</Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Row>

          <Row type='flex'>
            <Form.Item style={styles.formItem} label='日時'>
              {getFieldDecorator(`dropoffDate_${transportIndex}`, {
                initialValue: transportData.dropoffDate || null
              })(
                <DatePicker
                  format={formatTypes.DATE}
                  locale={jaJP}
                  placeholder={lt('txt_ｆrom_date')}
                  size='default'
                  style={{ width: 150, display: 'inline-block', marginRight: 15 }}
                  disabled={isDriver}
                />
              )}
            </Form.Item>
            <Form.Item style={styles.formItem}>
              {getFieldDecorator(`dropoffOption_${transportIndex}`, {
                initialValue: transportData.dropoffOption || null
              })(
                <Select
                  style={{ width: 120, marginRight: 15 }}
                  onChange={onTimeChange('dropoffTime')}
                  allowClear
                  disabled={isDriver}
                >
                  {schedTypes.map(sched => (
                    <Option key={sched.key} value={sched.value}>{sched.label}</Option>
                  ))}
                </Select>
              )}
            </Form.Item>

            {state.dropoffTime && (
              <>
                <Form.Item style={styles.formItem}>
                  {getFieldDecorator(`dropoffTimeFrom_${transportIndex}`, {
                    initialValue: transportData.dropoffTimeFrom ? moment(transportData.dropoffTimeFrom, 'HH:mm') : null
                  })(
                    <TimePicker
                      className={`dropoffTimeFrom_${transportIndex}`}
                      allowClear
                      minuteStep={10}
                      style={{ width: 110, marginRight: 5 }}
                      placeholder='時間を選択'
                      defaultOpenValue={moment('00:00')}
                      format={formatTypes.TIME}
                      disabled={isDriver}
                    />
                  )}
                </Form.Item>
                <Form.Item style={styles.formItem}>
                  {getFieldDecorator(`dropoffTimeTo_${transportIndex}`, {
                    initialValue: transportData.dropoffTimeTo ? moment(transportData.dropoffTimeTo, 'HH:mm') : null
                  })(
                    <TimePicker
                      className={`dropoffTimeTo_${transportIndex}`}
                      allowClear
                      minuteStep={10}
                      style={{ width: 110 }}
                      defaultOpenValue={moment('00:00')}
                      placeholder='時間を選択'
                      format={formatTypes.TIME}
                      disabled={isDriver}
                    />
                  )}
                </Form.Item>
              </>
            )}
          </Row>

          <Form.Item style={styles.formItem} label='借主'>
            {getFieldDecorator(`dropoffBorrower_${transportIndex}`, {
              initialValue: transportData.dropoffBorrower ? convertHalfWidthToFullWidth(transportData.dropoffBorrower) : null,
              rules: [{
                validator: async (rule, value) => {
                  if (value && hasHalfWidthCharacter(value)) {
                    throw new Error('半角文字が検出されました。');
                  }
                }
              }],
            })(
              <Input
                style={{ width: 420 }}
                disabled={isDriver}
                onBlur={(e) => convertFullWidthOnBlur(`dropoffBorrower_${transportIndex}`, e.target.value)}
              />
            )}
          </Form.Item>

          {state.dropoffAssignees.map((dropoffAssignee, index) => {

            const lastIndex = !!index || state.dropoffAssignees.length === 5 ? 'minus-circle' : 'plus-circle'
            return (
              <>
                <Row type='flex' align='middle' className='assignee'>
                  <Form.Item style={{ ...styles.formItem }} label={`担当者/連絡先${indexTypes[index + 1]}`}>
                    {getFieldDecorator(`dropoffAssignee_${transportIndex}_${index}`, {
                      initialValue: dropoffAssignee.assigned ? convertHalfWidthToFullWidth(dropoffAssignee.assigned) : null,
                      preserve: false,
                      rules: [{
                        validator: async (rule, value) => {
                          if (value && hasHalfWidthCharacter(value)) {
                            throw new Error('半角文字が検出されました。');
                          }
                        }
                      }],
                    })(
                      <Input
                        placeholder='担当者'
                        style={{ width: 200 }}
                        disabled={isDriver}
                        onBlur={(e) => convertFullWidthOnBlur(`dropoffAssignee_${transportIndex}_${index}`, e.target.value)}
                      />
                    )}
                  </Form.Item>
                  <Form.Item style={{ ...styles.formItem, marginLeft: 20 }}>
                    {getFieldDecorator(`dropoffContact_${transportIndex}_${index}`, {
                      initialValue: dropoffAssignee.contact,
                      preserve: false,
                    })(
                      <Input
                        placeholder='連絡先'
                        style={{ width: 200 }}
                        disabled={isDriver}
                      />
                    )}
                  </Form.Item>
                  {lastIndex === 'plus-circle' ?
                    <Icon onClick={onActionClick(lastIndex, 'dropoffAssignees', index)} type='plus-circle' theme='twoTone' style={{ fontSize: 18, marginLeft: 10, maxWidth: 30, marginBottom: 5 }} /> :
                    <Icon onClick={onActionClick(lastIndex, 'dropoffAssignees', index)} type='close-circle' theme='twoTone' style={{ fontSize: 18, marginLeft: 10, maxWidth: 30, marginBottom: 5 }} twoToneColor='#E84855' />
                  }
                  {/* <Button
                    icon={lastIndex}
                    onClick={onActionClick(lastIndex, 'dropoffAssignees', index)}
                    style={{ borderWidth: 0, color: lastIndex === 'plus-circle' ? '#' : '#E84855', marginBottom: 5 }}
                  /> */}
                </Row>
              </>)
          })}

          <Form.Item style={styles.formItem} label='現場名'>
            {getFieldDecorator(`dropoffSiteName_${transportIndex}`, {
              initialValue: transportData.dropoffSiteName,
            })(
              <Input
                style={{ width: 420 }}
                disabled={isDriver}
              />
            )}
          </Form.Item>

          <Row type='flex' flexDirection='row' style={{ alignItems: 'center' }}>
            {showMemoDestination ? renderMemo(`dropoffMemo_${transportIndex}`) :
              <Form.Item style={styles.formItem} label='メモ'>
                {getFieldDecorator(`dropoffMemo_${transportIndex}`, {
                  initialValue: transportData.dropoffMemo,
                  preserve: true
                })(
                  <TextArea
                    style={{ width: 420, marginRight: 10 }}
                    disabled={isDriver}
                    rows={10}
                  />
                )}
              </Form.Item>
            }
            <Button style={{ marginBottom: 8, marginLeft: 2 }} type='primary' onClick={showLinks(`dropoffMemo_${transportIndex}`, true)}>リンクに変換</Button>
          </Row>

          <Row type='flex' style={{ flexDirection: 'row', marginLeft: 50 }}>
            <Upload
              {...uploaderProps}
              fileList={constructFileList('dropoffImages')}
              onChange={onFileChange('dropoffImages')}
              disabled={isDriver}
              onPreview={onPreview}
            >
              <Row>
                <Icon type='plus' />
                <Typography style={{ fontSize: 12 }}>アップロード</Typography>
              </Row>
            </Upload>
          </Row>
        </Col>
      </Row>
      <Modal
        visible={imageState.previewVisible}
        title={imageState.previewTitle}
        footer={null}
        width={'80%'}
        bodyStyle={{ textAlign: 'center' }}
        onCancel={() => {
          imageSetter({ previewVisible: false })
        }}
      >
        <img alt="example" style={{ width: '100%' }} src={imageState.previewImage} />
      </Modal>
      <Row>
        {state.escort ? (
          <Collapse {...getCollapseProps()}>
            {[...new Array(state.escort)].map((escort, index) => (
              <Panel forceRender header={escortTypes[index]} key={index} style={styles.panelStyle}>
                <EscortForm escortIndex={index + 1} transportIndex={transportIndex} transportData={transportData} client={client} />
              </Panel>
            ))}
          </Collapse>
        ) : null}
      </Row>
    </Row>
  )
};

const styles = {
  headerStyle: {
    padding: 5,
    marginTop: 30,
  },
  formStyle: {
    padding: 20,
    borderTopWidth: 0
  },
  formItem: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 8
  },
  panelStyle: {
    backgroundColor: '#FFFFFF',
    borderRadius: 4,
    border: 0,
    overflow: 'hidden',
  },
  collapseStyle: {
    borderBottom: '1px solid #d9d9d9'
  }
}
export default TransportForm;