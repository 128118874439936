import React from 'react';
import { Form, Row, Col, Modal, notification } from 'antd';

/** Get All Common  **/
import {
	CustomButton,
	CustomDatepicker
} from '../../commons/CustomDataEntry';

import {
	getAll,
	getById,
	create,
	updateById,
	deleteById
} from '../../queries/Orders';
import { CONSTRUCT_ORDERS_QUERY, GET_ORDERS_DISPATCH } from '../Orders/queries';

import adhocTranslator from '../../utils/locales/translate-adhoc';
import moment from 'moment';
import _ from 'lodash';
import styles from '../../assets/scss/dashboard.module.scss';

notification.config({
	duration: 8
});

const timeDataTypes = {
	BLANK: '',
	AM: 'AM',
	PM: 'PM',
	DAY_TIME: '日中',
	EVENING: '夕方'
}

export default ({ mode }) => {
	let lt = adhocTranslator('K080-010');
	let outputRange = "";

	return {
		mode,
		/** query mapping **/
		data: {
			idRef: 'customerById'
		},
		queries: {
			GET_ALL: getAll,
			GET_BY_ID: getById,
			UPDATE_BY_ID: updateById,
			CREATE: create,
			DELETE_BY_ID: deleteById
		},
		component: function ({ form, data, handleSubmit, loading }) {
			setTimeout(function () {
				let errors = document.getElementsByClassName('ant-form-explain');
				for (let x = 0; x < errors.length; x++) {
					errors[x].style.width = '185px';
				}
			}, 1);
			let submitForm = () => {
				form.validateFields((err, values) => {
					if (!err) {
						const date1 = form.getFieldValue('txt_from_date').format("YYYY年MM月DD日");
						const date2 = form.getFieldValue('txt_to_date').format("YYYY年MM月DD日");

						if (date1 === date2) {
							outputRange = `${date1}`;
						} else {
							outputRange = `${date1} ～ ${date2}`;
						}

						Modal.confirm({
							title: '確認',
							okText: 'はい',
							cancelText: 'いいえ',
							icon: 'false',
							content: outputRange + 'の配車表を作成しますか？',
							onOk: async () => { printExcel() }
						})
					}
				})
			}

			const openNotificationWithIcon = (type, message) => {
				notification[type]({
					message: message
				});
			};

			const getBetweenDates = function (startDate, endDate) {
				const now = startDate.clone(), dates = [];
				while (now.isSameOrBefore(endDate)) {
					dates.push(now.format('YYYY-MM-DD'));
					now.add(1, 'days');
				}
				return dates;
			};

			let printExcel = async () => {
				// const id = form.getFieldValue('customerId');
				const dayList = getBetweenDates(form.getFieldValue('txt_from_date'), form.getFieldValue('txt_to_date'));
				let { data: orders } = await this.props.client.query({
					query: CONSTRUCT_ORDERS_QUERY(dayList),
					fetchPolicy: 'network-only',
				});
				let reports = [];

				const newOrders = [];
				_.forOwn(orders, (order, key) => {
					const [, index] = key.split('_');
					if (order.nodes.length) {
						newOrders.push({ order: order.nodes, dispatchDate: dayList[index] });
					};
				})
				
				let counterID = 1; // unique id for excel sheet data
				_.each(newOrders, ({ order, dispatchDate }) => {
					let rows = [];
					_.each(order, (o) => {
						const escortList = [];
						const { ordersConnectedsByOrderId1: { nodes: connection1 }, ordersConnectedsByOrderId2: { nodes: connection2 } } = o;

						let amount = null;
						let isCompleted = false, hasConnection = false;
						if (connection1 && connection1.length) {
							hasConnection = true;
							const { orderByOrderId1, orderByOrderId2 } = connection1[0];
							const { ordersTransportsByOrderId: { nodes: transport1 } } = orderByOrderId1;
							const { ordersTransportsByOrderId: { nodes: transport2 } } = orderByOrderId2;
							if (transport1.length && transport2.length) {
								const [data1] = transport1;
								const [data2] = transport2;
								if (data1 && data2 && data1.pickupActualDatetime && data1.dropoffActualDatetime &&
									data2.pickupActualDatetime && data2.dropoffActualDatetime) {
									isCompleted = true;
								}
							}
						}
						if (connection2 && connection2.length) {
							hasConnection = true;
							const { totalBilling, orderByOrderId1, orderByOrderId2 } = connection2[0];
							const { ordersTransportsByOrderId: { nodes: transport1 } } = orderByOrderId1;
							const { ordersTransportsByOrderId: { nodes: transport2 } } = orderByOrderId2;
							if (transport1.length && transport2.length) {
								const [data1] = transport1;
								const [data2] = transport2;
								if (data1 && data2 && data1.pickupActualDatetime && data1.dropoffActualDatetime &&
									data2.pickupActualDatetime && data2.dropoffActualDatetime) {
									isCompleted = true;
									amount = totalBilling;
								}
							}
						}
						o.ordersTransportsByOrderId.nodes.forEach(transports => escortList.push(...transports.ordersEscortVehiclesByTransportId.nodes));
						const escortFare = _.sumBy(escortList, 'transportBillingAmount') || 0;
						const escortCount = escortList.length;
						const format = 'HH:mm';

						let fare = _.compact([o.transportBillingAmount ? `${o.transportBillingAmount.toLocaleString()}円` : null]).join("<br />");
						if (hasConnection && !isCompleted) {
							fare = null;
						}

						const packages = _.compact([(o.packagingByPacking1 || {}).packagingName, o.packing2]);
						let data = {
							date: moment(o.transportDate).format("MM/DD"),
							vehicleNo: !o.vehicleByVehicleId ? '' : !o.vehicleByVehicleId.vehicleNo ? '' : o.vehicleByVehicleId.vehicleNo,
							driver: !(o.userByDriverId1 || {}).fullNameJa ? '' : (o.userByDriverId1 || {}).fullNameJa,
							orderPlace: !o.customerByCustomerId ? '' : !o.customerByCustomerId.nameJa ? '' : o.customerByCustomerId.nameJa,
							packagingName: !packages.length ? '' : packages.join('、'),

							por: _.map(o.ordersTransportsByOrderId.nodes, (p) => {
								return p.origin ? p.origin : ''
							}).join("<br/>"),


							schedulePickup: _.map(o.ordersTransportsByOrderId.nodes, (p) => {
								// PICK UP
								var pickUp;
								if (p.pickupHour !== null && p.pickupMinute !== null && p.pickupOption === 'SPECIFIED_TIME') {
									pickUp = "" + moment(`${p.pickupHour}:${p.pickupMinute}`, format).format(format) + "";
								} else if (p.pickupOption !== 'SPECIFIED_TIME') {
									pickUp = timeDataTypes[p.pickupOption];
								}
								return pickUp
							}).join("<br/>"),
							actualPickup: _.map(o.ordersTransportsByOrderId.nodes, (p) => {
								return p.pickupActualDatetime && moment(p.pickupActualDatetime).format("YYYY/MM/DD HH:mm")
							}).join("<br/>"),
							destination: _.map(o.ordersTransportsByOrderId.nodes, (p) => {
								return p.destination ? p.destination : ''
							}).join("<br/>"),
							scheduleDropoff: _.map(o.ordersTransportsByOrderId.nodes, (p) => {
								// DROP OFF
								var dropOff;
								if (p.dropoffHourFrom !== null && p.dropoffMinuteFrom !== null && p.dropoffOption === 'SPECIFIED_TIME') {
									if (p.dropoffHourTo !== null && p.dropoffMinuteTo !== null) {
										dropOff = "" + moment(`${p.dropoffHourFrom}:${p.dropoffMinuteFrom}`, format).format(format) + "～" + moment(`${p.dropoffHourTo}:${p.dropoffMinuteTo}`, format).format(format) + "";
									} else {
										if (p.dropoffHourFrom !== null && p.dropoffMinuteFrom !== null) {
											dropOff = "" + moment(`${p.dropoffHourFrom}:${p.dropoffMinuteFrom}`, format).format(format) + "";
										} else {
											dropOff = "" + moment(`${p.dropoffHourTo}:${p.dropoffMinuteTo}`, format).format(format) + "";
										}
									}
								} else {
									if (p.dropoffHourFrom === null && p.dropoffMinuteFrom === null && p.dropoffHourTo === null && p.dropoffMinuteTo === null && p.dropoffOption !== 'SPECIFIED_TIME') {
										dropOff = timeDataTypes[p.dropoffOption];
									}
								}
								return dropOff
							}).join("<br/>"),
							actualDropoff: _.map(o.ordersTransportsByOrderId.nodes, (p) => {
								return p.dropoffActualDatetime && moment(p.dropoffActualDatetime).format("YYYY/MM/DD HH:mm")
							}).join("<br/>"),
							actualVehicleDistance: o.transportDistance,
							escortCount: escortCount,
							payment: o.transportPaymentAmount ? `${o.transportPaymentAmount.toLocaleString()}円` : null,
							escortFare: escortFare ? `${escortFare.toLocaleString()}円` : null,
							amount: amount ? `${amount.toLocaleString()}円` : null,
							fare: fare,
							remarks: o.notes,
							borrower: _.map(o.ordersTransportsByOrderId.nodes, (p) => {
								const borrowList = [];
								if (p.pickupBorrower) borrowList.push(p.pickupBorrower);
								if (p.dropoffBorrower) borrowList.push(p.dropoffBorrower);
								return borrowList.join(', ');
							}).join("<br/>"),
							__typename: 'excelData'
						};
						rows.push(data);
					})
					let data = {
						id: counterID,
						//dispatchDate: moment(_.head(order).transportDate).format("YYYY年MM月DD日"),
						dispatchDate: dispatchDate,
						date: moment().format("YYYY年MM月DD日"),
						rows: rows,
						__typename: 'excelData'
					};

					reports.push(data);
					counterID++;
				});

				if (reports.length > 0) {
					this.props.client.writeData({
						data: {
							excelOutput: {
								__typename: 'excelOutput',
								type: 'Dispatch',
								excelEstimateOutputData: [],
								excelOrdersOutputData: [],
								excelInvoiceOutputData: [],
								excelCrewPerformanceOutputData: [],
								excelDispatchOutputData: reports,
								excelCustomersOutputData: []
							}
						}
					});

					openNotificationWithIcon('success', outputRange + lt('success_message'));
				} else {
					openNotificationWithIcon('error', outputRange + lt('error_message'));
				}

			};

			return (
				<Row justify='space-around'>
					<Col span={24}>
						<Form layout='horizontal'>
							<div className="ant-row ant-form-item dispatchconfig">
								<CustomDatepicker
									form={form}
									label={lt('lbl_date_range')}
									placeholder='YYYY/MM/DD'
									fieldName='txt_from_date'
									className={styles.datepicker}
									decorator={{
										rules: [{
											required: true,
											message: '年月日を入力してください。'
										}]
									}}
									width={5}
									labelWidth={12} />
								<CustomDatepicker
									form={form}
									placeholder='YYYY/MM/DD'
									fieldName='txt_to_date'
									className={styles.datepicker}
									style={{ width: 100, display: 'inline-block', marginRight: 15 }}
									decorator={{
										rules: [{
											required: true,
											message: '年月日を入力してください。'
										}]
									}}
									width={4}
									labelWidth={2}
									rangeSymbol='~' />
							</div>
							<div style={{ textAlign: 'center' }}>
								<CustomButton label={lt('btn_create')}
									autoFocus={true}
									event={submitForm}
									loading={loading}
									style={{ marginTop: '15px', height: '40px', width: '104px' }}
									onClick={() => { submitForm() }}
									offset={12} />
							</div>
						</Form>
					</Col>
				</Row>
			);
		}
	}
}