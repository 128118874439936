import _ from 'lodash';
import fullwidth from 'fullwidth';

const orderEdit = ['packaging', 'customer', 'remarks', 'transports'];
const types = { 'EMPTY': 'empty' };

const timeTypes = {
  BLANK: 'Blank',
  AM: 'AM',
  PM: 'PM',
  DAY_TIME: 'Day time',
  EVENING: 'Evening',
  SPECIFIED_TIME: 'Specified time'
}

export const constructOrdersPayload = (source, payload) => {
  const { editList } = source;
  const updatedList = _.intersection(orderEdit, editList);
  if (updatedList.length) {
    const { packagingId1, customerId, packaging2, remarks, id, vehicleUnused, packagingId2 } = source;
    const checkLength = source.transports.length;
    const checkLength1 = source.transports.length > 1;
    const checkLength2 = source.transports.length > 2;
    //@TODO add this in loop

    const tranportDestination1 = checkLength ? getDestinationData(source.transports[0]) : {};
    const tranportDestination2 = checkLength1 ? getDestinationData(source.transports[1]) : {};
    const tranportDestination3 = checkLength2 ? getDestinationData(source.transports[2]) : {};

    const tranportOriginData1 = checkLength ? getOriginData(source.transports[0]) : {};
    const tranportOriginData2 = checkLength1 ? getOriginData(source.transports[1]) : {};
    const tranportOriginData3 = checkLength2 ? getOriginData(source.transports[2]) : {};


    let specificTimePickup = tranportOriginData1.pickupOption === 'Specified time' ? {
      // pickupTime_1: checkLength ? source.transports[0].originTime : null,
      pickupTime_1: checkLength ? [tranportDestination1.pickupHour, tranportDestination1.pickupMinute].join(':') : null,
      pickupTimeTo_1: checkLength ? [tranportDestination1.pickupHourTo, tranportDestination1.pickupMinuteTo].join(':') : null,
    } : {}

    let specificTimePickup2 = tranportOriginData2.pickupOption === 'Specified time' ? {
      // pickupTime_2: checkLength1 ? source.transports[1].originTime : null,
      pickupTime_2: checkLength ? [tranportDestination2.pickupHour, tranportDestination2.pickupMinute].join(':') : null,
      pickupTimeTo_2: checkLength ? [tranportDestination2.pickupHourTo, tranportDestination2.pickupMinuteTo].join(':') : null,
    } : {}

    let specificTimePickup3 = tranportOriginData3.pickupOption === 'Specified time' ? {
      // pickupTime_3: checkLength2 ? source.transports[2].originTime : null,
      pickupTime_3: checkLength ? [tranportDestination3.pickupHour, tranportDestination3.pickupMinute].join(':') : null,
      pickupTimeTo_3: checkLength ? [tranportDestination3.pickupHourTo, tranportDestination3.pickupMinuteTo].join(':') : null,
    } : {}

    let specificTimeDropOff = tranportDestination1.dropoffOption === 'Specified time' ? {
      dropoffTimeFrom_1: checkLength ? [tranportDestination1.dropoffHourFrom, tranportDestination1.dropoffMinuteFrom].join(':') : null,
      dropoffTimeTo_1: checkLength ? [tranportDestination1.dropoffHourTo, tranportDestination1.dropoffMinuteTo].join(':') : null,
    } : {}

    let specificTimeDropOff2 = tranportDestination2.dropoffOption === 'Specified time' ? {
      dropoffTimeFrom_2: checkLength1 ? [tranportDestination2.dropoffHourFrom, tranportDestination2.dropoffMinuteFrom].join(':') : null,
      dropoffTimeTo_2: checkLength1 ? [tranportDestination2.dropoffHourTo, tranportDestination2.dropoffMinuteTo].join(':') : null,
    } : {}

    let specificTimeDropOff3 = tranportDestination3.dropoffOption === 'Specified time' ? {
      dropoffTimeFrom_3: checkLength1 ? [tranportDestination3.dropoffHourFrom, tranportDestination3.dropoffMinuteFrom].join(':') : null,
      dropoffTimeTo_3: checkLength1 ? [tranportDestination3.dropoffHourTo, tranportDestination3.dropoffMinuteTo].join(':') : null,
    } : {}

    payload.push({
      id,
      fields: {
        customer_id: customerId,
        packing1: packagingId1,
        packing2: packaging2 || null,
        notes: remarks,
        vehicleUnused,
        initial: {
          id: source.id,
          transportDate: source.transportDate,
          orderDate: source.orderDate,
          transportDateOption: source.dateOption,
          dispatchStatus: "PENDING",
          vehicleId: source.vehicleId,
          driverId1: source.driverId1,
          driver1Name: source.driver1,
          driverId2: null,
          customerId: source.customerId,
          customerName: source.customer,
          weight: null,
          escortVehicleOutsourcingUnitPriceId: null,
          escortVehicleUnitPriceId: null,
          transportationUnitPriceId: null,
          packing1: source.packagingId1,
          packagingName: source.packaging1,
          packing2: source.packaging2,
          overnight: 'NO',
          notes: remarks,
          transport: source.transports.map((o, i) => {
            const oTransport = { ...o };
            // delete oTransport.originTime;
            return {
              ...oTransport,
              transportNo: i + 1,
              numberEscortVehicle: 0,
              ...getOriginData(o),
              ...getDestinationData(o),
            }
          }),
          transportDistance: null,
          transportBillingAmount: null,
          transportPaymentAmount: null,
          'driverId1-display': null,
          'customerId-display': null,
          'customerId-escortVehicleUnitPriceId': null,
          'customerId-transportationUnitPriceId': null,
          'packing1-display': null,
          'packing1-weight': null,
          pickupDate_1: checkLength ? source.transports[0].pickupDate : null,
          pickupOption_1: checkLength ? source.transports[0].pickupOption : null,
          dropoffDate_1: checkLength ? source.transports[0].dropoffDate : null,
          origin_1: checkLength ? source.transports[0].origin : null,
          destination_1: checkLength ? source.transports[0].destination : null,
          dropoffOption_1: checkLength ? source.transports[0].dropoffOption : null,
          dropoffDate_1: checkLength ? source.transports[0].dropoffDate : null,
          destinationTime_1: checkLength ? source.transports[0].destinationTime : null,
          originTime_1: checkLength ? source.transports[0].originTime : null,

          pickupDate_2: checkLength1 ? source.transports[1].pickupDate : null,
          pickupOption_2: checkLength1 ? source.transports[1].pickupOption : null,
          dropoffDate_2: checkLength1 ? source.transports[1].dropoffDate : null,
          origin_2: checkLength1 ? source.transports[1].origin : null,
          destination_2: checkLength1 ? source.transports[1].destination : null,
          dropoffOption_2: checkLength1 ? source.transports[1].dropoffOption : null,
          dropoffDate_2: checkLength1 ? source.transports[1].dropoffDate : null,
          dropoffDate_2: checkLength1 ? source.transports[1].dropoffDate : null,
          destinationTime_2: checkLength1 ? source.transports[1].destinationTime : null,

          pickupDate_3: checkLength2 ? source.transports[2].pickupDate : null,
          pickupOption_3: checkLength2 ? source.transports[2].pickupOption : null,
          dropoffDate_3: checkLength2 ? source.transports[2].dropoffDate : null,
          origin_3: checkLength2 ? source.transports[2].origin : null,
          destination_3: checkLength2 ? source.transports[2].destination : null,
          dropoffOption_3: checkLength2 ? source.transports[2].dropoffOption : null,
          dropoffDate_3: checkLength2 ? source.transports[2].dropoffDate : null,
          dropoffDate_3: checkLength2 ? source.transports[2].dropoffDate : null,
          destinationTime_3: checkLength2 ? source.transports[2].destinationTime : null,

          ...specificTimePickup,
          ...specificTimePickup2,
          ...specificTimePickup3,
          ...specificTimeDropOff,
          ...specificTimeDropOff2,
          ...specificTimeDropOff3
        }
      }
    });
  };

};

const checkInvalid = (transport) => {
  return ((transport.origin === types.EMPTY || transport.origin === '') && (transport.destination === types.EMPTY || transport.destination === '')
    && (transport.originTime === types.EMPTY || transport.originTime === '') && (transport.destinationTime === types.EMPTY || transport.destinationTime === ''))
};

const getOriginData = (transport) => {
  const { originTime, pickupOption, pickupDate = null, pickupHour = null, pickupMinute = null, pickupHourTo = null, pickupMinuteTo = null } = transport;

  const timeData = { pickupHour, pickupMinute, pickupHourTo, pickupMinuteTo };
  if (pickupOption === 'SPECIFIED_TIME' && originTime && originTime !== types.EMPTY) {
    // const time = originTime.split(':');
    // timeData.pickupHour = time[0];
    // timeData.pickupMinute = time[1]
    const [timeFrom, timeTo] = originTime.split('〜');
    const [pickupHour, pickupMinute] = timeFrom.split(':')
    const [pickupHourTo, pickupMinuteTo] = timeTo.split(':')
    timeData.pickupHour = pickupHour || null;
    timeData.pickupMinute = pickupMinute || null;
    timeData.pickupHourTo = pickupHourTo || null;
    timeData.pickupMinuteTo = pickupMinuteTo || null;
  } else {
    timeData.pickupHour = null;
    timeData.pickupMinute = null;
    timeData.pickupHourTo = null;
    timeData.pickupMinuteTo = null;
  }

  return {
    pickupOption: (originTime === types.EMPTY || !originTime) ? 'Blank' : timeTypes[pickupOption],
    pickupDate: (originTime === types.EMPTY || !originTime) ? null : pickupDate,
    ...timeData
  }
}

const getDestinationData = (transport) => {
  const { destinationTime, dropoffOption = null, dropoffDate = null, dropoffHourFrom = null,
    dropoffMinuteFrom = null, dropoffHourTo = null, dropoffMinuteTo = null } = transport;

  const timeData = { dropoffHourFrom, dropoffMinuteFrom, dropoffHourTo, dropoffMinuteTo };
  if (dropoffOption === 'SPECIFIED_TIME' && destinationTime && destinationTime !== types.EMPTY) {
    const [timeFrom, timeTo] = destinationTime.split('〜');
    const [dropoffHourFrom, dropoffMinuteFrom] = timeFrom.split(':')
    const [dropoffHourTo, dropoffMinuteTo] = timeTo.split(':')
    timeData.dropoffHourFrom = dropoffHourFrom || null;
    timeData.dropoffMinuteFrom = dropoffMinuteFrom || null;
    timeData.dropoffHourTo = dropoffHourTo || null;
    timeData.dropoffMinuteTo = dropoffMinuteTo || null;
  } else {
    timeData.dropoffHourFrom = null;
    timeData.dropoffMinuteFrom = null;
    timeData.dropoffHourTo = null;
    timeData.dropoffMinuteTo = null;
  }

  return {
    dropoffOption: (destinationTime === types.EMPTY || !destinationTime) ? 'Blank' : timeTypes[dropoffOption],
    dropoffDate: (destinationTime === types.EMPTY || !destinationTime) ? null : dropoffDate,
    ...timeData
  }
}

export const constructTransportPayload = (source, payload) => {
  const { editList, transports, id: orderId } = source;
  const { updateList, addList, deleteList } = payload;
  if (editList && editList.indexOf('transports') >= 0) {
    _.each(transports, (transport, index) => {
      const { id, origin, destination } = transport;
      if (transport.id) {

        checkInvalid(transport) ?
          deleteList.push(id) :
          updateList.push({
            id, transportNo: index + 1,
            origin: origin === types.EMPTY ? null : origin,
            destination: destination === types.EMPTY ? null : destination,
            ...getOriginData(transport),
            ...getDestinationData(transport),
          });
      } else {
        // if has data
        if (!checkInvalid(transport)) addList.push({
          orderId: orderId, transportNo: index + 1,
          origin: origin === types.EMPTY ? null : origin,
          destination: destination === types.EMPTY ? null : destination,
          ...getOriginData(transport),
          ...getDestinationData(transport),
        });
      }
    })
  };

};

export const toFullWidth = (rawValue) => {
  if (rawValue) {
    return fullwidth(rawValue);
  }
}