import React, { Component, Fragment, useContext } from 'react';
import { graphql, compose, withApollo } from 'react-apollo';
import { Checkbox, Form, Alert } from 'antd';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { withRouter } from "react-router";
import { GlobalContext } from '../utils/context';
import { customerLoginMutation } from '../queries/Customer';
import { loginMutation, getDriverRole } from '../queries/Login';
import { CustomInput, CustomButton } from './CustomDataEntry';
import decode from 'jwt-decode';
import { isMobile } from 'react-device-detect';

class LoginForm extends Component {
    state = {
        isEnabled: false,
        errors: null,
        loading: false
    };

    handleEnabledChange = () => {

        this.setState({
            isEnabled: !this.state.isEnabled
        })
        localStorage.setItem('remember', !this.state.isEnabled)
    };

    handleLoading = (loading) => {
        this.setState({ loading });
    };

    handleSubmit = (e) => {
        e.preventDefault();
        this.handleLoading(true);
        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                try {
                    // cookies.set("username", values.username, 3000)
                    // cookies.set("password", values.password, 3000)
                    let token = null;
                    let res = null;
                    const variables = {
                        variables: {
                            loginId: values.username,
                            password: values.password,
                        }
                    };

                    if (!!this.props.isCustomer) {
                        res = await this.props.customerLogin(variables);
                        token = await res.data.authenticateCustomer;
                    } else {
                        res = await this.props.login(variables);
                        token = await res.data.authenticate;
                    }

                    const { role, user_id } = decode(token.jwtToken);
                    localStorage.setItem('token', token.jwtToken);
                    const { data: { allRoles: { nodes: driverRole } } } = await this.props.client.query({
                        query: getDriverRole,
                        fetchPolicy: 'network-only',
                        variables: {
                            user_id
                        }
                    });
                    if ((isMobile && !driverRole.length) || role === '') {
                        localStorage.removeItem('token');
                        this.setState({ errors: 'ログインは許可されていません' });
                        this.handleLoading(false);
                        return;
                    }
                    this.props.globalSetter({ savedDate: null, savedSpan: null, savedTab: null });
                    this.props.history.push("/");
                } catch (error) {
                    // const errors = await error.graphQLErrors.map((error) => error.message);
                    this.setState({ errors: 'ログインIDまたはパスワードに誤りがあります。ログインID、パスワードを入力して、ログインボタンを再度押してください。' });
                    this.handleLoading(false);
                }
            } else {
                this.handleLoading(false);
            }
        });
    };

    renderError = () => {
        if (this.state.errors) {
            let err = this.state.errors;
            return (
                <ReactCSSTransitionGroup
                    transitionName="login"
                    transitionEnterTimeout={500}
                    transitionLeaveTimeout={300}>
                    <Alert message={err} type="error" />
                </ReactCSSTransitionGroup>
            );
        }
    };

    onFocusEvent = (e) => {
        document.getElementById(e.target.id).removeAttribute('readonly');
    }


    render() {
        const { txtSystemName, txtCompanyName, form, txtLoginId, errLoginId, errPassword, txtPassword, lblSaveLogin, txtBtnLogin } = this.props


        let checkbox = localStorage.getItem('remember')
        if (checkbox !== "true") {
            // cookies.set('password','')
            // cookies.set('username','')
        }
        return (
            <Fragment>
                <Form onSubmit={this.handleSubmit} layout="vertical">
                    <header>
                        {this.renderError()}
                        <p style={{ textAlign: 'center' }}>{txtCompanyName}</p>
                        <h3>{txtSystemName}</h3>
                    </header>
                    <CustomInput
                        onFocus={this.onFocusEvent}
                        form={form}
                        readOnly={checkbox !== "true" ? true : false}
                        fieldName='username'
                        width={24}
                        type='text'
                        placeholder={txtLoginId}
                        maxLength={10}
                        decorator={{
                            // initialValue:cookies.get('username') ,
                            rules: [{
                                required: true,
                                message: errLoginId,
                            }]
                        }}
                    />
                    <CustomInput
                        form={form}
                        fieldName='password'
                        width={24}
                        type='password'
                        placeholder={txtPassword}
                        maxLength={16}
                        decorator={{
                            // initialValue:cookies.get('password'),
                            rules: [{
                                required: true,
                                message: errPassword,
                            }]
                        }}
                    />
                    <Form.Item>
                        <Checkbox
                            checked={checkbox === "true" ? true : false}
                            className="checkbox"
                            onChange={this.handleEnabledChange}
                        > {lblSaveLogin} </Checkbox>
                    </Form.Item>
                    <CustomButton
                        offset={0}
                        label={txtBtnLogin}
                        htmlType='submit'
                        loading={this.state.loading}
                    />
                </Form>
            </Fragment>
        )
    }
}

const LoginMain = (props) => {
    const { globalSetter } = useContext(GlobalContext);
    return (
        <LoginForm globalSetter={globalSetter} {...props} />
    )
}

const withLoginAppMutate = compose(graphql(customerLoginMutation, { name: "customerLogin" }), graphql(loginMutation, { name: "login" }))
export default withApollo(withLoginAppMutate(Form.create()(withRouter(LoginMain))));