import React, {useReducer} from 'react';
import { Col, Row} from "antd";
import {Context} from './Context';
import {initialState, reducer} from './Reducer';
import {withApollo} from 'react-apollo';
import styles from '../assets/scss/scroller.module.scss';

const ReportBase = ({config, client}) => {

    let {
        filter,
        graph
    } = config;

    const [state, dispatch] = useReducer(reducer, initialState);

    return (
        <Context.Provider value={{dispatch,state,client}}>
           <Row>
                <Col style={{height:'80vh'}} span={9} className={styles.scroll + " border-graph"}>
                    {filter}
                </Col>
                <Col style={{height:'80vh'}} span={14} className={styles.scroll + " mxl-10 border-graph"}>
                    {graph}
                </Col>
            </Row>
        </Context.Provider>
    );
}

export default withApollo(ReportBase);
