import {Link} from "react-router-dom";
import React, {Fragment} from "react";
import {Row, Col, Button, Modal, Form } from "antd";
import _ from 'lodash';

import adhocTranslator from "../../utils/locales/translate-adhoc";
import ActionHeader from '../../commons/TableActions/ActionHeader';
import {getAll} from '../../queries/Estimate';
import {
    CustomDatepicker,
    CustomInput
} from '../../commons/CustomDataEntry';
import WithEventButton from "../../commons/WithEventButton";

import moment from 'moment';

export default () => {
    let lt = adhocTranslator('K050-010');

    return {
        fetchQuery: getAll,
        allKey: 'allEstimates',
        perPage: 20,
        bordered: true,
        columns : [
            {
                align: 'center',
                title: lt('lbl_quotation_number'),  //
                dataIndex: 'estimateNumber',
                key: 'estimateNumber',
                width: 140
            }, {
                align: 'center',
                title: lt('lbl_date_estimate_header'),  //lbl_date_created_header
                dataIndex: 'estimateDate', //createdAt
                key: 'estimateDate', //createdAt,
                width: 90,
            }, {
                align: 'center',
                title: lt('lbl_customer_name_list_header'),
                dataIndex: 'customerNameJa',
                key: 'customerNameJa',
                width: 150,
                render: (text) => {return <p style={{textAlign:'left', marginBottom: '1px'}}>{text}</p>}
            }, {
                align: 'center',
                title: lt('lbl_packaging_header'),
                dataIndex: 'packagingName',
                key: 'packagingName',
                render: (text,record) => {
                    return record.packagingName.map((item, i)=>{
                        return (<p key={i} style={{textAlign:'left', marginBottom:1}}>{item}</p>);
                    })
                },
                width: 150
            }, {
                align: 'center',
                title: lt('lbl_point_of_origin_header'),
                dataIndex: 'por',
                key: 'por',
                render: (text,record) => {
                    return record.por.map((item, i)=>{
                        return (<p key={i} style={{textAlign:'left', marginBottom:1}}>{item}</p>);
                    })
                },
                width: 250,
            }, {
                align: 'center',
                title: lt('lbl_distance_header'),
                dataIndex: 'distance',
                key: 'distance',
                className: 'ant-table-thead-align-center',
                render: (text,record) => {
                    return record.distance.map((item, i)=>{
                        return (<p key={i} style={{textAlign:'right', marginBottom:1}}>{item} km</p>);
                    })
                },
                width: 100
            }, {
                align: 'center',
                title: lt('lbl_transportation_fare_header'),
                dataIndex: 'transportationFare',
                key: 'transportationFare',
                className: 'ant-table-thead-align-center',
                render: (text,record) => {
                    return record.transportationFare.map((item, i)=>{
                        return (<p key={i} style={{textAlign:'right', marginBottom:1}}>{item} 円</p>);
                    })
                },
                width: 100
            }, {
                align: 'center',
                title: lt('lbl_escort_vehicle_header'),
                dataIndex: 'escortVehicle',
                key: 'escortVehicle',
                className: 'ant-table-thead-align-center',
                render: (text,record) => {
                    return record.escortVehicle.map((item, i)=>{
                        return (<p key={i} style={{textAlign:'right', marginBottom:1}}>{item} 円</p>);
                    })
                },
                width: 100
            }, {
                align: 'center',
                title: lt('lbl_total_amount_header'),
                dataIndex: 'totalAmount',
                key: 'totalAmount',
                className: 'ant-table-thead-align-center',
                render: (text,record) => {
                    return record.totalAmount.map((item, i)=>{
                        return (<p key={i} style={{textAlign:'right', marginBottom:1}}>{item} 円</p>);
                    })
                },
                width: 100
            }, {
                align: 'center',
                title: lt('lbl_process_header'),
                key: 'lbl_process_header',
                render: (text, record) => (
                    <span>
                        <Link to={{
                            pathname: `/create-estimate/update/${record.id}`,
                            state: { id: text.id }
                        }}>[ {lt('lbl_edit')} ]</Link>
                        <Link to={{
                            pathname: `/create-estimate/delete/${record.id}`,
                            state: { id: text.id }
                        }}>[ {lt('lbl_delete')} ]</Link>
                    </span>
                ),
                width: 150
            }
        ],
        methods : {

            modalOutputReport: function (selectedRows) {
                let download = () => {
                    this.props.client.writeData({
                        data: {
                            excelOutput : {
                                __typename : 'excelOutput',
                                type : 'Estimates',
                                excelEstimateOutputData : selectedRows,
                                excelOrdersOutputData: [],
                                excelInvoiceOutputData: [],
                                excelCrewPerformanceOutputData: [],
                                excelDispatchOutputData: []
                            }
                        }
                    });
                }           
                
                Modal.confirm({
                    title: '確認',
                    content: '選択した見積書を出力しますか？',
                    centered: true,
                    okText:'はい',
                    icon: 'false',
                    cancelText:'いいえ',
                    
                    onOk(){
                        download();
                    },
                    onCancel() {},
                });
            }
        },
        dataMapping: (item) => {
            let estimateDetails = [];
            _.each(item.estimatesDetailsByEstimateId.nodes, (detail)=>{
                const packigingName = detail.packagingByPacking1 ? detail.packagingByPacking1.packagingName : ''
                const transportFee = detail.transportFee ? detail.transportFee : 0
                const escortVehicleFee = detail.escortVehicleFee ? detail.escortVehicleFee : 0
                const inhouseEscortCount = detail.escortVehicleCount ? detail.escortVehicleCount : 0
                const outsourceEscortVehicleCount = detail.outsourceEscortVehicleCount ? detail.outsourceEscortVehicleCount : 0
                const notes = detail.notes ? detail.notes : ''

                estimateDetails.push({
                    por: detail.originDestinationLocation,
                    packaging: packigingName,
                    distance: detail.transportDistance,
                    amount: transportFee,
                    notes: notes,
                    escortAmount: escortVehicleFee,
                    totalAmount: (transportFee + escortVehicleFee),
                    inhouseEscortCount,
                    outsourceEscortCount: outsourceEscortVehicleCount,
                    escortFee: escortVehicleFee,
                    transportFee,

                    __typename : 'excelData'
                });
            });

            let packagingName = _.map(estimateDetails, (detail)=>{
                return detail['packaging'];
            });

            let por = _.map(estimateDetails, (detail)=>{
                return detail['por'];
            });

            let distance = _.map(estimateDetails, (detail)=>{
                return Math.round(detail['distance']).toLocaleString();
            });

            let amount = _.map(estimateDetails, (detail)=>{
                return Math.round(detail['amount']).toLocaleString();
            });

            let escortAmount = _.map(estimateDetails, (detail)=>{
                return Math.round(detail['escortAmount']).toLocaleString();
            });

            let totalAmount = _.map(estimateDetails, (detail)=>{
                return Math.round(detail['totalAmount']).toLocaleString();
            });

            return {
                id: item.id,
                estimateDate: moment(item.estimateDate).format("YYYY/MM/DD"),
                createdAt: moment(item.createdAt).format("YYYY/MM/DD"),
                customerNameJa: item.customerNameJa,
                packagingName:packagingName,
                por: por,
                destination: por,
                distance: distance,
                transportationFare: amount,
                escortVehicle: escortAmount,
                totalAmount: totalAmount,
                estimateNumber: item.estimateNo,
                dateOfIssue: '',
                customerName: item.customerNameJa,
                companyName: '',
                quotationAmount: '',
                validity: '',
                rows: estimateDetails,
                subtotal: Math.round(item.subtotal).toLocaleString(),
                tax: item.consumptionTax,
                totalamount: Math.round(item.totalAmount).toLocaleString(),
                __typename : 'excelData'
            }
        },

        searchHeader: ({form, handleSearch, loading, clearRows}) => {

            const resetFields = () => {
                form.resetFields();
                handleSearch();
                clearRows(true)
            }

            return (
                <Fragment>
                    <Form onSubmit={handleSearch}>
                        <CustomDatepicker 
                            form={form} 
                            className={'customDatepickStyle2'}
                            label={lt('lbl_output_date_range')}
                            placeholder='YYYY/MM/DD'
                            fieldName='createdAt_From'
                            style={{ width: 100, display: 'inline-block', marginRight: 15 }}
                            decorator={{}}
                            labelWidth={3}/>
                        <div className="date-separator">〜</div>
                        <CustomDatepicker 
                            form={form} 
                            placeholder='YYYY/MM/DD' 
                            className={'customDatepickStyle3'}
                            fieldName='createdAt_To'
                            style={{ width: 100, display: 'inline-block', marginRight: 15 }}
                            decorator={{}}
                            labelWidth={194}/>
                        <div className='clearme' />
                        <CustomInput
                            form={form}
                            fieldName='estimateNo_Like'
                            labelWidth={3}
                            className="estimate-cname"
                            label={lt('lbl_estimate_no')}
                            decorator={{
                                
                            }}
                            width={2}
                        />
                        <CustomInput
                            form={form}
                            fieldName='customerNameJa_Like'
                            label={lt('lbl_customer_name')}
                            className="estimate-cname"
                            decorator={{
                            }}
                            width={2}
                            labelWidth={3}
                        />

                        {/* <ModalBase
                            title='顧客情報検索'
                            form={form}
                            mode={'add'}
                            btnLabel={lt('選択')}
                            label={lt('lbl_customer_name')}
                            fieldName='customerNameJa'
                            labelWidth={2}
                            config={CustomerModalConfig()}
                            className="estimate-cname"
                            filterStatus
                        /> */}

                        <Row type='flex' justify='space-around'>
                            <Col span={12}>
                                {/*Submit*/}
                                <WithEventButton
                                    event={handleSearch}
                                    loading={loading}
                                    label={lt('btn_search')}
                                    style={{margin: '0px 20px 15px 220px', height:'40px', width:'104px'}}
                                />
                                <Button type="primary" 
                                        loading={loading} 
                                        style={{marginTop: '15px', height:'40px', width:'104px'}} 
                                        onClick={resetFields}
                                >
                                    {lt('クリア')}
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Fragment>
            );
        },
        actionHeader: function({methods,selectedRows,setState,state}) {
            return (
                <Fragment>
                    <ActionHeader
                        rightActions={
                            <Fragment>
                                <Button type="primary" htmlType="button" style={{marginLeft:'10px'}}>
                                    <Link to="/create-estimate/register">{lt('lbl_total_amount_header_1')}</Link>
                                </Button>
                                <Button type="primary" htmlType="button" style={{marginLeft:'10px'}} disabled={selectedRows.length > 0 ? false:true} onClick={()=>{methods.modalOutputReport.bind(this)(selectedRows)}}>
                                    {lt('btn_click_on_create_estimate')}
                                </Button>
                            </Fragment>
                        }
                    />
                </Fragment>
            )
        },
        minWidth: 'max-content'
    };
}