import { Layout } from 'antd';
import React, { Component } from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import Footer from '../commons/Footer';
// Import common components
import Header from '../commons/Header';
import Home from '../commons/Home';
import Sidebar from '../commons/Sidebar';
// Change Password Route
import ChangePassword from '../components/ChangePassword/ChangePassword';
import Excel from '../components/Excel';
// Get the main routes
import mainRoutes from '../routes/main.js';

// Get antd layouts
const { Content } = Layout;

class Dashboard extends Component {

    state = {
        collapsed: false,
    };
    toggleCollapsed = (collapsed) => {
        this.setState({ collapsed });
    };

    render() {

        let routes = [];

        // Create flatten function
        let flattenRoutes = (propRoutes) => {
            propRoutes.forEach((subProp, subKey) => {
                const rendering = subProp.path === '/order/search-list' ?
                    { render: subProp.component } :
                    { component: subProp.component }

                if (subProp.path) {
                    routes.push(<Route path={subProp.path} {...rendering} key={`sub-menu-${subKey}`} />);
                } else {
                    flattenRoutes(subProp.components);
                }
            });
        };


        let pages = [];
        const token = localStorage.getItem('token');
        if (token) {
            const decodeToken = JSON.parse(atob(token.split('.')[1]));
            switch (decodeToken.role) {
                case "app_customer":
                    pages = mainRoutes().customer;
                    break;
                case "app_driver":
                    pages = mainRoutes().driver;
                    break;
                case "app_user":
                    pages = mainRoutes().user;
                    break;
                default:
                    pages = mainRoutes().admin;
                    break;
            }
        }

        // Loop through all the routes
        pages.forEach((prop) => {
            flattenRoutes(prop.components);
        });

        routes.push(<Route path='/changepassword'  component={ChangePassword} key='99' />);
        routes.push(<Route path='/' exact component={Home} key='100' />);
        routes.push(<Route path='/login' exact component={Home} key='101' />);

        if(this.props.location.pathname === '/404') {
            return (
                <Switch>
                    {routes}
                    <Redirect to={"/404"} />
                </Switch>
            )
        }

        return (
            <Layout>
                <Sidebar
                    {...this.props}
                    collapsed={this.state.collapsed}
                    routes={pages} />
                <Layout>
                    <Header routes={pages} toggle={this.toggleCollapsed} />
                    <Excel />
                    <Content style={{ margin: '14px 16px', background: '#fff', minHeight: 280, padding: '20px' }}>
                        <Switch>
                            {routes}
                            <Redirect to={"/404"} />
                        </Switch>
                    </Content>
                    <Footer />
                </Layout>
            </Layout>
        )
    }
}

export default withRouter(Dashboard)

