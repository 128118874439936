import { Form } from "antd";
import React, { Fragment } from "react";
import {
    CustomButton,
    CustomHiddenInput,
    CustomInput
} from "../../../commons/CustomDataEntry";
import { getAll } from '../../../queries/MasterMaintenance/vehicle';
import adhocTranslator from "../../../utils/locales/translate-adhoc";

export default () => {
    let lt = adhocTranslator('K030-031');

    return {
        fetchQuery: getAll,
        allKey: 'allVehicles',
        perPage: 20,
        displayField: 'vehicleNo',
        columns: [
            {
                title: lt('号車'),
                dataIndex: 'vehicleNo',
                key: 'vehicleNo',
            },
        ],
        type: 'radio',
        th: 430,
        searchHeader: ({ form, handleSearch, loading }) => {
            return (
                <Fragment>
                    <Form onKeyPress={e => e.which === 13 && e.preventDefault()}>
                        <div className='ant-row ant-form-item'>
                            <CustomInput form={form}
                                width={24}
                                fieldName='vehicleNo_Like'
                                placeholder={lt('txt_enter_location_name')}
                                type='text' />
                            <CustomHiddenInput
                                form={form}
                                type='hidden'
                                fieldName='vehicleNo_LikeStart'
                            />
                        </div>
                        <CustomButton label={lt('btn_click_on_search')}
                            loading={loading}
                            onClick={handleSearch}
                            width={2}
                            style={{ margin: '0 auto 10px', display: 'block' }} />
                    </Form>
                </Fragment>)
        },

        dataMapping: (item) => {
            return {
                id: item.id,
                vehicleNo: item.vehicleNo,
                vehicleType: item.vehicleType
            }
        }
    };
}