import { Button, DatePicker, Form, Modal, Row, Typography } from 'antd';
import jaJP from 'antd/lib/date-picker/locale/ja_JP';
import 'moment/locale/ja';
import React from 'react';
import adhocTranslator from '../../../../../utils/locales/translate-adhoc';
import { formatTypes } from '../../../constants';

const lt = adhocTranslator('K020-010');

const DateModal = (props) => {

  const { onCancel, onOk, visible, form: { getFieldDecorator, getFieldsValue } } = props;

  const onSubmit = () => {
    const { date } = getFieldsValue();

    onOk(date);
  };

  return (
    <Modal
      visible={visible}
      onOK={onOk}
      onCancel={onCancel}
      className='modal-vehicle'
      width={416}
      footer={[
        <Button key='submit' type='primary' onClick={onSubmit}>設定</Button>,
      ]}
    >
      <Row>
        <Row type='flex' justify='center' style={{ marginBottom: 30 }}>
          <Typography style={{ fontWeight: 'bold' }}>引き上げ日</Typography>
        </Row>
        <Row style={{ paddingLeft: 20, paddingRight: 20 }}>
          <Typography>選択した受注・配車割当情報の引き上げ日を入力してください。</Typography>
        </Row>
        <Form>
          <Row type='flex' style={{ flexDirection: 'row' }} className='vertical-align'>
            <Typography.Text style={styles.label}>引き上げ日</Typography.Text>
            <Form.Item style={{ flex: 1 }}>
              {getFieldDecorator('date')(
                <DatePicker
                  format={formatTypes.DATE}
                  locale={jaJP}
                  placeholder={lt('txt_ｆrom_date')}
                  showToday={false}
                  size='default'
                  style={{ width: 170, marginRight: 30 }}
                />
              )}
            </Form.Item>
          </Row>
        </Form>
      </Row>
    </Modal>
  )
};

const styles = {
  label: { textAlign: 'end', paddingTop: 5, flex: 1, marginRight: 30 },
}

export default Form.create()(DateModal);