import { Link } from "react-router-dom";
import React from "react";
import { Button } from "antd";
import _ from 'lodash';

import adhocTranslator from "../../utils/locales/translate-adhoc";
import { getAll } from '../../queries/Estimate/details';

export default ({ modal, mode }) => {
    let lt = adhocTranslator('K050-020');
    const onAction = (action, id) => {
        if (action === 'edit') {
            modal.onEdit(id)
        } else if(action === 'copy') {
            modal.onCopy(id)
        } else {
            modal.onDelete(id)
        }
    }

    return {
        fetchQuery: getAll,
        allKey: 'allEstimatesDetails',
        perPage: 20,
        columns: [
            {
                title: lt('lbl_point_of_origin-destination_location'),
                dataIndex: 'originDestinationLocation',
                key: 'por',
                className: 'ant-table-thead-align-center',
                width: 100,
                align: 'center',
            }, {
                align: 'center',
                title: lt('lbl_packaging'),
                dataIndex: 'packagingName',
                key: 'packagingName',
                className: 'ant-table-thead-align-center',
                width: 100
            }, {
                title: lt('lbl_distance'),
                dataIndex: 'transportDistance',
                key: 'transportDistance',
                align: 'center',
                className: 'ant-table-thead-align-center',
                render: (text, record) => <span>{`${record.transportDistance}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}km</span>,
                width: 100
            }, {
                title: lt('lbl_amount'),
                dataIndex: 'transportFee',
                key: 'transportFee',
                align: 'center',
                className: 'ant-table-thead-align-center',
                render: (text, record) => <span>{`${record.transportFee + record.escortVehicleFee}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}円</span>,
                width: 100
            }, {
                title: lt('lbl_notes'),
                dataIndex: 'notes',
                key: 'notes',
                className: 'ant-table-thead-align-center',
                align: 'center',
                width: 100
            }, {
                title: lt('lbl_process'),
                key: 'process',
                align: 'center',
                className: 'ant-table-thead-align-center',
                render: (text, record) => {
                    if (modal) {
                        return (
                            <span>
                                {mode === 'delete' ?
                                    (
                                        <>
                                            <Button className='planeBtn' disabled type="default" htmlType="button" onClick={() => onAction('edit', record.id)}>[ {lt('btn_edit')} ]</Button>
                                            <Button className='planeBtn' disabled type="default" htmlType="button" onClick={() => onAction('copy', record.id)}>[ コピー作成 ]</Button>
                                            <Button className='planeBtn' disabled type="default" htmlType="button" onClick={() => onAction('delete', record.id)}>[ {lt('btn_delete')} ]</Button>
                                        </>
                                    ) :
                                    (
                                        <>
                                            <Button className='planeBtn' type="default" htmlType="button" onClick={() => onAction('edit', record.id)}>[ {lt('btn_edit')} ]</Button>
                                            <Button className='planeBtn' type="default" htmlType="button" onClick={() => onAction('copy', record.id)}>[ コピー作成 ]</Button>
                                            <Button className='planeBtn' type="default" htmlType="button" onClick={() => onAction('delete', record.id)}>[ {lt('btn_delete')} ]</Button>
                                        </>
                                    )}
                            </span>
                        )
                    } else {
                        return (
                            <span>
                                <Link to={{
                                    pathname: `/transportation-details/update/${record.id}`,
                                    state: { id: text.id }

                                }}>[ {lt('btn_edit')} ]</Link>
                                <Link to={{
                                    pathname: `/transportation-details/delete/${record.id}`,
                                    state: { id: text.id }
                                }}>[ {lt('btn_delete')} ]</Link>
                            </span>
                        )
                    }
                },
                width: 80
            }
        ],
        methods: {

        },
        dataMapping: (item) => {
            return {
                id: item.id,
                originDestinationLocation: item.originDestinationLocation,
                packagingName: _.get(item.packagingByPacking1, 'packagingName', ''),
                transportDistance: item.transportDistance,
                transportFee: item.transportFee,
                notes: item.notes,
                inhouseEscortCount: item.escortVehicleCount,
                ousourceEscortCount: item.outsourceEscortVehicleCount,
                escortFee: item.escortVehicleFee,
            }
        }
    };
}