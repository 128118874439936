import { Form } from 'antd';
import React, { useContext, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { FormContext, OrderContext } from '../../../../utils/context';
import { tabTypes } from '../../constants';


/**
 * TABLE ROW WRAPPER
 * 
 */
// const type = 'RowType';
const RowWrap = ({ form, rowIndex, onMoveRow, onHover, className, style, record = {}, ...props }) => {
  const type = record.transportDate ? record.transportDate : (record.id || 1).toString();
  const { view, editMode } = useContext(OrderContext);
  const ref = useRef();
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: monitor => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === rowIndex) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName: dragIndex < rowIndex ? ' drop-over-downward' : ' drop-over-upward',
      };
    },
    drop: item => {
      onMoveRow(item.index, rowIndex, editMode);
    },
  });

  const [, drag] = useDrag({
    item: { type, index: rowIndex },
    canDrag: !editMode,
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drop(drag(ref));
  
  const getReference = () => {
    let reference = null;
    if(view !== tabTypes.DELETED && view !== tabTypes.PLANNED && record.dateOption === 'SPECIFIED_DAY') {
      reference = ref;
    }
    return reference;
  };

  return (
    <FormContext.Provider value={{ form }}>
      <tr
        onMouseOver={onHover}
        ref={getReference()}
        className={`${className}${isOver ? dropClassName : ''}`}
        style={{ cursor: 'move', ...style }}
        {...props}
      />
    </FormContext.Provider>
  )
};

export default Form.create()(RowWrap);