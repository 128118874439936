import { Col, Form, Row, Tabs } from 'antd';
import _ from 'lodash';
import React from 'react';
/** Get All Common  **/
import {
    CustomButton,
    CustomHiddenInput,
    CustomInput,
    CustomRadioGroup
} from '../../../commons/CustomDataEntry';
import ModalBase from '../../../commons/ModalBase';
import Day from "../../../commons/Picklist/Day";
import Month from "../../../commons/Picklist/Month";
import { changeCustomerPassword } from '../../../queries/Customer';
/** Get All Customer Queries **/
import {
    create,
    deleteById, getAll, getById,
    updateById
} from '../../../queries/MasterMaintenance/customer';
/** Get ADhoc translate **/
import adhocTranslator from '../../../utils/locales/translate-adhoc';
import { unitPriceCategory } from '../../../utils/types';
/** Get user_id stored in LocalStorage **/
import decodeToken from '../../../utils/user_id';
import UnitPriceModalConfig from '../UnitPrice/ListModal';

const TabPane = Tabs.TabPane;

export default ({ mode }) => {

    /** Initialize translators **/
    let lt = adhocTranslator('K901-021');
    let ltV = adhocTranslator('K901-022');

    return {
        /** Assign mode **/
        mode,

        /** Redirect config **/
        redirect: '/customer/search-list',

        /** query mapping **/
        data: {
            idRef: 'customerById'
        },
        /** Map queries **/
        queries: {
            GET_BY_ID: getById,
            GET_ALL: getAll,
            UPDATE_BY_ID: updateById,
            CREATE: create,
            DELETE_BY_ID: deleteById
        },

        /** messages */
        messages: {
            add: "顧客情報を登録しますか？",
            edit: "顧客情報を更新しますか？",
            delete: "顧客情報を削除しますか？",
            reg_success_message: lt("reg_success_message"),
            reg_error_message: lt("reg_error_message"),
            edit_success_message: lt("edit_success_message"),
            edit_failure_message: lt("edit_failure_message"),
            delete_success_message: lt("delete_success_message"),
            delete_failure_message: lt("delete_failure_message")
        },

        postEdit: async function (variables) {
            if (variables.password) {
                await variables;
                this.props.client.mutate({
                    mutation: changeCustomerPassword,
                    variables: {
                        id: variables.id,
                        password: variables.password
                    }
                });
            }
        },
        /** Create common component **/
        component: ({ form, data, handleSubmit, loading, state, handleChangeTab }) => {
            const radioStatus = [
                { id: 'ACTIVE', value: lt('rdo_status_active') },
                { id: 'INACTIVE', value: lt('rdo_status_inactive') }
            ];
            const selectBillingMonth = [
                { value: '前々月', id: -2 },
                { value: '前月', id: -1 },
                { value: '当月', id: 0 },
                { value: '翌月', id: 1 },
                { value: '翌々月', id: 2 },
            ];
            const selectBillingDay = [
                { value: '01日', id: 1 },
                { value: '02日', id: 2 },
                { value: '03日', id: 3 },
                { value: '04日', id: 4 },
                { value: '05日', id: 5 },
                { value: '06日', id: 6 },
                { value: '07日', id: 7 },
                { value: '08日', id: 8 },
                { value: '09日', id: 9 },
                { value: '10日', id: 10 },
                { value: '11日', id: 11 },
                { value: '12日', id: 12 },
                { value: '13日', id: 13 },
                { value: '14日', id: 14 },
                { value: '15日', id: 15 },
                { value: '16日', id: 16 },
                { value: '17日', id: 17 },
                { value: '18日', id: 18 },
                { value: '19日', id: 19 },
                { value: '20日', id: 20 },
                { value: '21日', id: 21 },
                { value: '22日', id: 22 },
                { value: '23日', id: 23 },
                { value: '24日', id: 24 },
                { value: '25日', id: 25 },
                { value: '26日', id: 26 },
                { value: '27日', id: 27 },
                { value: '28日', id: 28 },
                { value: '29日', id: 29 },
                { value: '30日', id: 30 },
                { value: '31日', id: 31 },
                { value: '末日', id: 99 },
            ];
            const selectDueMonth = [
                { value: '当月', id: 0 },
                { value: '翌月', id: 1 },
                { value: '翌々月', id: 2 },
            ]

            let formatPostCode = postCode => {
                if (postCode) {
                    postCode = postCode.replace(/-/g, "");
                    if (postCode.length >= 4) {
                        postCode = postCode.substr(0, 3) + "-" + postCode.substr(3, 4);
                    }
                }
                return postCode;
            }

            let btnLabel;
            if (mode === 'edit') {
                btnLabel = ltV('btn_edit')
            } else if (mode === 'delete') {
                btnLabel = ltV('btn_delete')
            } else {
                btnLabel = ltV('btn_register')
            }
            const formItemLayout = {
                labelCol: {
                    xs: { span: 50 },
                    sm: { span: 20 },
                },
                wrapperCol: {
                    xs: { span: 100 },
                    sm: { span: 20 },
                },
            };

            let onFocusEvent = (e) => {
                document.getElementById(e.target.id).removeAttribute('readonly');
            }
            return (
                <Row type='flex' className='h100' justify='space-around' align='top'>
                    <Col span={24}>
                        <Tabs activeKey={state.activeKey} onChange={(activeKey) => handleChangeTab(activeKey)} defaultActiveKey='1'>
                            <TabPane tab={lt('tｂ_basic_info')} key='1'>
                                <Form onSubmit={handleSubmit} layout='vertical'>
                                    <CustomInput
                                        autoFocus={true}
                                        form={form}
                                        fieldName='nameJa'
                                        label={lt('lbl_customer_name_kanji')}
                                        type='text'
                                        placeholder={lt('txt_customer_name_kanji')}
                                        maxLength={128}
                                        mode={mode}
                                        offset={4}
                                        width={14}
                                        half={true}
                                        decorator={{
                                            rules: [{
                                                required: true,
                                                message: lt('顧客名（漢字）を入力してください。'),
                                            }],
                                            initialValue: data.nameJa
                                        }} />
                                    <CustomInput
                                        form={form} fieldName='nameJaKatakana'
                                        label={lt('lbl_customer_name_furigana')}
                                        type='text'
                                        placeholder={lt('txt_customer_name_furigana')}
                                        maxLength={128}
                                        mode={mode}
                                        offset={1}
                                        labelWidth={5}
                                        half={true}
                                        width={12}
                                        decorator={{
                                            rules: [{
                                                required: true,
                                                message: lt('顧客名（フリガナ）を入力してください。'),
                                            }],
                                            initialValue: data.nameJaKatakana
                                        }} />
                                    <CustomHiddenInput
                                        fieldName={mode === 'add' ? 'createdUserId' : 'updatedUserId'}
                                        form={form}
                                        decorator={{
                                            initialValue: decodeToken().user_id
                                        }} />
                                    <CustomInput
                                        form={form} fieldName='formOutputName'
                                        label={lt('lbl_customer_name_on_forms')}
                                        type='text'
                                        placeholder={lt('txt_customer_name_on_forms')}
                                        maxLength={128}
                                        mode={mode}
                                        offset={0}
                                        width={7}
                                        decorator={{
                                            rules: [{
                                                required: true,
                                                message: lt('帳票顧客名（漢字）を入力してください。'),
                                            }],
                                            initialValue: data.formOutputName
                                        }} />
                                    <CustomInput
                                        form={form} fieldName='loginId'
                                        label={lt('lbl_login_id')}
                                        type='text'
                                        placeholder={lt('txt_login_id')}
                                        maxLength={10}
                                        mode={mode}
                                        readOnly={true}
                                        onFocus={onFocusEvent}
                                        offset={0}
                                        width={5}
                                        decorator={{
                                            rules: [{
                                                required: true,
                                                message: lt('txt_login_id'),
                                            }],
                                            initialValue: data.loginId
                                        }} />
                                    <CustomInput
                                        form={form} fieldName='password'
                                        label={lt('lbl_password')}
                                        type='password'
                                        readOnly={true}
                                        onFocus={onFocusEvent}
                                        placeholder={mode === 'add' ? lt('psw_password') : '********'}
                                        maxLength={16}
                                        mode={mode}
                                        offset={0}
                                        width={5}
                                        decorator={{
                                            rules: [{
                                                required: mode === 'add' ? true : false,
                                                message: lt('psw_password'),
                                            }]
                                        }} />
                                    <CustomInput
                                        form={form} fieldName='postCodeFormatted'
                                        label={lt('lbl_postal_code')}
                                        type='text'
                                        placeholder={lt('txt_postal_code')}
                                        mode={mode}
                                        width={3}
                                        maxLength={7}
                                        onFocus={(event) => {
                                            let { value } = event.target;
                                            form.setFieldsValue({ postCodeFormatted: value.replace(/-/g, '') });
                                        }}
                                        onBlur={(event) => {
                                            let { value } = event.target;
                                            let dataValue = null;
                                            if (!value || value !== '') {
                                                value = value.replace(/-/g, '');
                                                if (value.length >= 4) {
                                                    dataValue = value.substr(0, 3) + "-" + value.substr(3, 4);
                                                }
                                            };
                                            form.setFieldsValue({ postCodeFormatted: dataValue });
                                        }}
                                        onChange={
                                            e => {
                                                form.setFieldsValue({
                                                    postCode: `${e.target.value}`.replace(/-/g, "")
                                                });
                                            }
                                        }
                                        decorator={{
                                            rules: [{
                                                required: true,
                                                min: 8,
                                                message: lt('郵便番号を入力してください。'),
                                            }],
                                            // normalize: value => formatPostCode(value),
                                            initialValue: formatPostCode(data.postCode)
                                        }} />
                                    <CustomHiddenInput fieldName='postCode' form={form} />
                                    <CustomHiddenInput form={form} fieldName='customerConfig' decorator={{ initialValue: true }} />
                                    <CustomInput
                                        form={form} fieldName='address'
                                        label={lt('lbl_address')}
                                        type='text'
                                        placeholder={lt('txt_address')}
                                        mode={mode}
                                        width={12}
                                        maxLength={255}
                                        decorator={{
                                            rules: [{
                                                required: true,
                                                message: lt('住所を入力してください。'),
                                            }],
                                            initialValue: data.address
                                        }} />
                                    <CustomInput
                                        form={form} fieldName='telNo1'
                                        label={lt('lbl_tel')}
                                        type='text'
                                        placeholder={lt('txt_tel1')}
                                        mode={mode}
                                        offset={4}
                                        width={8}
                                        maxLength={15}
                                        half={true}
                                        decorator={{
                                            rules: [{
                                                required: true,
                                                message: lt('電話番号を入力してください。'),
                                            }],
                                            initialValue: data.telNo1
                                        }} />
                                    <CustomInput
                                        form={form} fieldName='telNo2'
                                        type='text'
                                        placeholder={lt('txt_tel2')}
                                        mode={mode}
                                        width={8}
                                        maxLength={15}
                                        half={true}
                                        decorator={{
                                            initialValue: data.telNo2
                                        }} />
                                    <CustomInput
                                        form={form} fieldName='faxNo1'
                                        label={lt('lbl_fax')}
                                        type='text'
                                        placeholder={lt('txt_fax1')}
                                        mode={mode}
                                        offset={4}
                                        width={8}
                                        maxLength={15}
                                        half={true}
                                        decorator={{
                                            initialValue: data.faxNo1
                                        }} />
                                    <CustomInput
                                        form={form} fieldName='faxNo2'
                                        type='text'
                                        placeholder={lt('txt_fax2')}
                                        mode={mode}
                                        width={8}
                                        maxLength={15}
                                        half={true}
                                        decorator={{
                                            initialValue: data.faxNo2
                                        }} />
                                    <CustomInput
                                        form={form} fieldName='emailAddress'
                                        label={lt('lbl_email_address')}
                                        type='text'
                                        placeholder={lt('txt_email_address')}
                                        mode={mode}
                                        width={7}
                                        maxLength={255}
                                        decorator={{
                                            initialValue: data.emailAddress
                                        }} />
                                    <CustomInput
                                        form={form} fieldName='url'
                                        label={lt('lbl_url')}
                                        type='text'
                                        placeholder={lt('txt_url')}
                                        mode={mode}
                                        width={7}
                                        maxLength={255}
                                        decorator={{
                                            initialValue: data.url
                                        }} />
                                    <CustomRadioGroup
                                        options={radioStatus}
                                        form={form}
                                        mode={mode}
                                        label={lt('lbl_status')}
                                        fieldName='status'
                                        decorator={{
                                            rules: [{
                                                required: true,
                                                message: lt('lbl_status'),
                                            }],
                                            initialValue: `${data.status}`
                                        }}
                                        customerConfig={true}
                                    />
                                    <CustomButton label={btnLabel}
                                        event={handleSubmit}
                                        loading={loading}
                                        style={{ marginTop: '15px', height: '40px', width: '104px' }}
                                        htmlType="submit"
                                        offset={12} />
                                </Form>
                            </TabPane>
                            <TabPane tab={ltV('tb_unit_rate_and_invoice_info')} key='2' forceRender={true}>
                                <Form onSubmit={handleSubmit} {...formItemLayout} layout='vertical'>
                                    <div className="ant-row ant-form-item" >
                                        <ModalBase
                                            title='単価情報検索'
                                            form={form}
                                            mode={mode}
                                            label={ltV('lbl_transportation_unit_price')}
                                            btnLabel={ltV('btn_transportation_unit_price')}
                                            placeholder={ltV('txt_transportation_unit_price')}
                                            fieldName='transportationUnitPriceId'
                                            rowField={false}
                                            modalClass={'cm'}
                                            labelWidth={2}
                                            config={UnitPriceModalConfig({ categoryId: unitPriceCategory.FARE })}
                                            decorator={{
                                                rules: [{
                                                    required: true,
                                                    message: lt('error_transpo_price'),
                                                }],
                                                initialValue: _.get(data.unitPriceByTransportationUnitPriceId, 'unitPriceName', '')
                                            }}
                                            width={16}
                                            inputWidth={250}
                                            hiddenDecorator={{
                                                initialValue: data.transportationUnitPriceId

                                            }}
                                        />
                                    </div>

                                    <div className="ant-row ant-form-item" >
                                        <ModalBase
                                            title='単価情報検索'
                                            form={form}
                                            mode={mode}
                                            label={ltV('lbl_escort_vehicle_unit_price')}
                                            placeholder={ltV('txt_escort_vehicle_unit_price')}
                                            btnLabel={ltV('btn_escort_vehicle_unit_price')}
                                            fieldName='escortVehicleUnitPriceId'
                                            rowField={false}
                                            modalClass={'cm'}
                                            labelWidth={2}
                                            inputWidth={250}
                                            config={UnitPriceModalConfig({ categoryId: unitPriceCategory.ESCORT_VEHICLE_FARE })}
                                            decorator={{
                                                rules: [{
                                                    required: true,
                                                    message: lt('error_subcontracted_escort_price'),
                                                }],
                                                initialValue: _.get(data.unitPriceByEscortVehicleUnitPriceId, 'unitPriceName', '')
                                            }}
                                            hiddenDecorator={{
                                                initialValue: data.escortVehicleUnitPriceId
                                            }}
                                        />
                                    </div>

                                    <div className="ant-row ant-form-item" >
                                        <ModalBase
                                            title='単価情報検索'
                                            form={form}
                                            mode={mode}
                                            label={ltV('lbl_subcontracted_escort_vehicle_unit_price')}
                                            placeholder={ltV('txt_escort_vehicle_unit_price')}
                                            btnLabel={ltV('btn_escort_vehicle_unit_price')}
                                            fieldName='escortVehicleOutsourcingUnitPriceId'
                                            rowField={false}
                                            modalClass={'cm'}
                                            labelWidth={2}
                                            inputWidth={250}
                                            config={UnitPriceModalConfig({ categoryId: unitPriceCategory.ESCORT_VEHICLE_FARE })}
                                            decorator={{
                                                rules: [{
                                                    required: true,
                                                    message: lt('error_subcontracted_escort_price'),
                                                }],
                                                initialValue: _.get(data.unitPriceByEscortVehicleOutsourcingUnitPriceId, 'unitPriceName', '')
                                            }}
                                            hiddenDecorator={{
                                                initialValue: data.escortVehicleOutsourcingUnitPriceId
                                            }}
                                        />
                                    </div>


                                    <div className="ant-row ant-form-item" style={{ width: '60%', display: 'inline-block' }}>
                                        <span style={{ color: '#ea4d4d', 'fontMozTabSize': '100px' }}>* &nbsp;</span><p style={{ width: 115, display: 'inline-block' }}> {ltV('lbl_billing_date_range1')}
                                        </p>
                                        <Month
                                            options={selectBillingMonth}
                                            form={form}
                                            // label={ltV('lbl_billing_date_range1')}
                                            fieldName='billingDateRangeMonthFrom'
                                            mode={mode}
                                            rowField={false}
                                            style={{ display: 'inline-block', width: 'calc(20% - 12px)' }}
                                            labelWidth={100}
                                            inlineForm={true}
                                            decorator={{
                                                rules: [{
                                                    required: true,
                                                    message: '請求範囲を入力してください'

                                                }
                                                ],
                                                initialValue: data.billingDateRangeMonthFrom
                                            }} />
                                        <Day
                                            options={selectBillingDay}
                                            form={form}
                                            fieldName='billingDateRangeDayFrom'
                                            mode={mode}
                                            rowField={false}
                                            style={{ display: 'inline-block', width: 'calc(20% - 12px)' }}
                                            labelWidth={100}
                                            postLabel={'～'}
                                            inlineForm={true}
                                            decorator={{
                                                rules: [{
                                                    required: true,
                                                    message: '請求範囲を入力してください'
                                                }
                                                ],
                                                initialValue: data.billingDateRangeDayFrom
                                            }} />
                                        <Month
                                            options={selectBillingMonth}
                                            form={form}
                                            fieldName='billingDateRangeMonthTo'
                                            mode={mode}
                                            rowField={false}
                                            style={{ display: 'inline-block', width: 'calc(20% - 12px)' }}
                                            labelWidth={100}
                                            inlineForm={true}
                                            decorator={{
                                                rules: [{
                                                    required: true,
                                                    message: '請求範囲を入力してください'
                                                }
                                                ],
                                                initialValue: data.billingDateRangeMonthTo
                                            }} />
                                        <Day
                                            options={selectBillingDay}
                                            form={form}
                                            fieldName='billingDateRangeDayTo'
                                            mode={mode}
                                            rowField={false}
                                            style={{ display: 'inline-block', width: 'calc(20% - 12px)' }}
                                            labelWidth={100}
                                            inlineForm={true}
                                            decorator={{
                                                rules: [{
                                                    required: true,
                                                    message: '請求範囲を入力してください'
                                                }
                                                ],
                                                initialValue: data.billingDateRangeDayTo
                                            }} />
                                    </div>

                                    <div className="ant-row ant-form-item" style={{ width: '60%', display: 'inline-block' }}>
                                        <span style={{ color: '#ea4d4d', 'fontMozTabSize': '100px' }}>* &nbsp;</span><p style={{ width: 115, display: 'inline-block' }}> {ltV('lbl_due_date')}
                                        </p>
                                        <Month
                                            options={selectDueMonth}
                                            form={form}
                                            fieldName='dueDateMonth'
                                            // label={ltV('lbl_due_date')}
                                            mode={mode}
                                            rowField={false}
                                            style={{ display: 'inline-block', width: 'calc(20% - 12px)' }}
                                            labelWidth={100}
                                            inlineForm={true}
                                            decorator={{
                                                rules: [{
                                                    required: true,
                                                    message: '振込期限を入力してください'
                                                }
                                                ],
                                                initialValue: data.dueDateMonth
                                            }} />

                                        <Day
                                            options={selectBillingDay}
                                            form={form}
                                            fieldName='dueDateDay'
                                            mode={mode}
                                            rowField={false}
                                            style={{ display: 'inline-block', width: 'calc(20% - 12px)' }}
                                            labelWidth={100}
                                            inlineForm={true}
                                            decorator={{
                                                rules: [{
                                                    required: true,
                                                    message: '振込期限を入力してください'
                                                }
                                                ],
                                                initialValue: data.dueDateDay
                                            }} />
                                    </div>

                                    <CustomButton label={btnLabel}
                                        event={handleSubmit}
                                        loading={loading}
                                        style={{ marginTop: '15px', height: '40px', width: '104px' }}
                                        htmlType="submit"
                                        offset={12} />
                                </Form>
                            </TabPane>
                        </Tabs>
                    </Col>
                </Row>
            );
        }
    }
}