import { Button, Form, notification, Row, Tabs, Modal } from "antd";
import _ from "lodash";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { withApollo } from "react-apollo";
import { uploadOrderTransportFiles } from "../../../../utils/api-handler";
import { FormContext, OrderContext } from "../../../../utils/context";
import adhocTranslator from "../../../../utils/locales/translate-adhoc";
import { DELETE_DEPARTURE, GET_DEPARTURES } from "../../../Orders/queries";
import { modeTypes } from "../../constants";
import { getUserId, updateMomentValues, checkLocations } from "../../functions";
import {
  GET_ORDER_UPDATED_AT,
  INSERT_NESTED_ORDERS,
  UPDATE_ESCORT,
  UPDATE_NESTED_ORDERS,
  UPDATE_ORDER,
} from "../../queries";
import BasicInfoForm from "./basic-form";
import TransportForm from "./transport-form";

const { TabPane } = Tabs;
const modeDate = { origin: "pickupDate", destination: "dropoffDate" };
let at = adhocTranslator("K030-020");
const messages = {
  add: "受注・配車割当情報を登録しますか？",
  edit: "受注・配車割当情報を更新しますか？",
  delete: "受注・配車割当情報を削除しますか？",
  reg_success: at("reg_success_message"),
  reg_failure: at("reg_error_message"),
  edit_success: at("edit_success_message"),
  edit_failure: at("edit_failure_message"),
  delete_success: at("delete_success_message"),
  delete_failure: at("delete_failure_message"),
};
const reverseDataTypes = [
  "origin|destination",
  "pickupBorrower|dropoffBorrower",
  "pickupAssignee|dropoffAssignee",
  "pickupContact|dropoffContact",
  "pickupSiteName|dropoffSiteName",
  "pickupMemo|dropoffMemo",
  "originColor|destinationColor",
];

const OrdersForm = (props) => {
  const {
    form,
    form: { getFieldValue, setFieldsValue },
    client,
    handleRefetch,
    date = null,
  } = props;
  const {
    resetForm,
    action,
    submit,
    closeModal,
    formData,
    orderMode,
    imported,
    orderSetter,
    isDriver,
  } = useContext(OrderContext);
  const [state, setState] = useState({
    form,
    transport: "transport1",
    escortConnected: [],
  });

  const notify = (type, message) => {
    notification[type]({
      message: message,
    });
  };

  const stateSetter = (value) => setState((state) => ({ ...state, ...value }));

  const onTransportClick = (value) => {
    stateSetter({ transport: value });
  };

  const showConifrmError = () => {
    Modal.confirm({
      title: "確認",
      content: "配車済みのため、発地または着地を入力してください。",
      okText: "はい",
      icon: null,
      cancelText: "いいえ",
      onOk: async () => {},
      onCancel: () => {},
    });
  };

  useEffect(() => {
    return () => {
      form.resetFields();
    };
  }, []);

  const updateOtherOrder = async (form) => {
    try {
      if (formData.connectedOrderId && form.updateOther !== null) {
        await client.mutate({
          mutation: UPDATE_ORDER,
          variables: {
            id: formData.connectedOrderId,
            patch: {
              transportBillingAmount: form.updateOther,
            },
          },
        });
      }

      if (formData.updateEscorts && formData.updateEscorts.length) {
        await Promise.all(
          _.map(formData.updateEscorts, async (escort) => {
            await client.mutate({
              mutation: UPDATE_ESCORT,
              variables: {
                id: escort.id,
                patch: {
                  transportBillingAmount: escort.billing,
                },
              },
            });
          })
        );
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (submit) {
      (async () => {
        console.log("formData", formData);
        // scroll to top transition
        document
          .getElementsByClassName("order-modal")[0]
          .scrollIntoView({ behavior: "smooth" });
        const isEditMode = action === modeTypes.EDIT;
        if (isEditMode) {
          // Check if updated at is changed
          const { data } = await client.query({
            query: GET_ORDER_UPDATED_AT,
            fetchPolicy: "network-only",
            variables: {
              id: formData.id,
            },
          });
          const { updatedAt } = data.orderById;
          if (
            isEditMode &&
            !formData.isEditImport &&
            !moment(updatedAt).isSame(formData.updatedAt)
          ) {
            orderSetter({ submit: false });
            return notify("error", "現在のフォームは最新ではありません");
          }
        }

        const { mutation, success, error } = isEditMode
          ? {
              mutation: UPDATE_NESTED_ORDERS,
              success: messages.edit_success,
              error: messages.edit_failure,
            }
          : {
              mutation: INSERT_NESTED_ORDERS,
              success: messages.reg_success,
              error: messages.reg_failure,
            };

        // return orderSetter({ submit: false });
        form.validateFields(async (err, values) => {
          if (
            values.driverId1 &&
            values.driverId2 &&
            values.driverId1 === values.driverId2
          ) {
            orderSetter({ submit: false });
            return notify(
              "error",
              "同じ運転手を設定することができません。別な運転手を選択してください。"
            );
          }

          if (err) {
            orderSetter({ submit: false });
            return;
          }
          const isAllowed = checkLocations(values);
          if (!isAllowed) {
            // show notification¥
            orderSetter({ submit: false });
            showConifrmError();
            return;
          }
          updateMomentValues(values);
          try {
            if (isEditMode && !formData.isEditImport) {
              await checkDataChange(formData, values);
              await updateOtherOrder(formData);
            }

            // if (orderMode === "copy" || orderMode === "copy inverse") {
            //   formData.transport = [{}, {}, {}];
            // }
            const { data } = await client.mutate({
              mutation,
              variables: {
                payload: JSON.stringify({
                  initial: formData,
                  values,
                  userId: getUserId(),
                }),
              },
            });

            let ordersResult =
              data[isEditMode ? "ordersNestedUpdate" : "ordersNestedInsert"];
            let orderId = null,
              transportIds = null;
            if (isEditMode) {
              const data = ordersResult.find(
                (data) => data.orderId === formData.id
              );
              orderId = data.orderId;
              transportIds = JSON.parse(data.transportIds);
            } else {
              orderId = ordersResult.orderId;
              transportIds = JSON.parse(ordersResult.transportIds);
            }

            if (Object.keys(transportIds).length) {
              const form = new FormData();

              // append common keys in form
              form.append("orderId", orderId);
              form.append("userId", getUserId());

              formData.transport.forEach((transport, index) => {
                if (_.isEmpty(transport) || !transportIds[index]) return;

                const { pickupImages, dropoffImages } = transport;

                /**
                 * Append FormData for pickupImages
                 */
                _.forEach(pickupImages, (orgImg, imageIndex) => {
                  if (_.isEmpty(orgImg)) return;
                  /**
                   * If there are image urls from AWS S3 attached and is not a file
                   * This will be treated as req.body
                   */
                  if (!orgImg.originFileObj) {
                    form.append(
                      `pickup|${transportIds[index]}|${imageIndex + 1}`,
                      orgImg.imageFile ? orgImg.imageFile : orgImg.url
                    );
                  } else {
                    // This will be treated as req.file in the backend
                    form.append(
                      `pickup|${transportIds[index]}|${imageIndex + 1}`,
                      orgImg.originFileObj
                    );
                  }
                });

                /**
                 * Append FormData for dropoffImages
                 */
                _.forEach(dropoffImages, (destImg, imageIndex) => {
                  if (_.isEmpty(destImg)) return;
                  /**
                   * If there are image urls from AWS S3 attached and is not a file
                   * This will be treated as req.body
                   */
                  if (!destImg.originFileObj) {
                    form.append(
                      `dropoff|${transportIds[index]}|${imageIndex + 1}`,
                      destImg.imageFile ? destImg.imageFile : destImg.url
                    );
                  } else {
                    // This will be treated as req.file in the backend
                    form.append(
                      `dropoff|${transportIds[index]}|${imageIndex + 1}`,
                      destImg.originFileObj
                    );
                  }
                });
              });
              await uploadOrderTransportFiles(form);
            }

            notify("success", success);
            orderSetter({ submit: false });
            closeModal(true);
          } catch (e) {
            notify("error", error);
            orderSetter({ submit: false });
            // closeModal(false);
          }
        });
      })();
    }
  }, [submit]);

  // Deletes departure on data change
  const checkDataChange = async (form, values) => {
    const transportDate = form.transportDate
      ? form.transportDate.format("YYYY-MM-DD")
      : null;
    if (
      form.vehicleId &&
      (transportDate !== values.transportDate ||
        form.vehicleId !== values.vehicleId)
    ) {
      const { data } = await client.query({
        query: GET_DEPARTURES,
        fetchPolicy: "network-only",
        variables: {
          orderId: form.id,
        },
      });
      const departure = data.allDepartures.nodes;
      if (departure.length) {
        const departureMap = departure.map(async (data) => {
          await client.mutate({
            mutation: DELETE_DEPARTURE,
            variables: {
              id: data.id,
            },
          });
        });
        await Promise.all(departureMap);
      }
    }
  };
  const onReverseClick = () => {
    // Swap origin and destination
    const clonedForm = _.cloneDeep(formData);
    _.each([1, 2, 3], (count) => {
      let swapData = {};
      _.each(reverseDataTypes, (dataTypes) => {
        const [key1, key2] = dataTypes.split("|");
        // if (key1 === 'pickupAssignee' || key1 === 'pickupContact') {
        //   const origin0 = `${key1}_${count}_0`, destination0 = `${key2}_${count}_0`;
        //   const origin1 = `${key1}_${count}_1`, destination1 = `${key2}_${count}_1`;
        //   const origin2 = `${key1}_${count}_2`, destination2 = `${key2}_${count}_2`;
        //   const origin3 = `${key1}_${count}_3`, destination3 = `${key2}_${count}_3`;
        //   const origin4 = `${key1}_${count}_4`, destination4 = `${key2}_${count}_4`;
        //   const data = form.getFieldsValue([origin0, origin1, origin2, origin3, origin4, destination0, destination1, destination2, destination3, destination4]);
        //   if (data[origin0] || data[destination0]) {
        //     swapData[origin0] = data[destination0];
        //     swapData[destination0] = data[origin0];
        //   };
        //   if (data[origin1] || data[destination1]) {
        //     swapData[origin1] = data[destination1];
        //     swapData[destination1] = data[origin1];
        //   };
        //   if (data[origin2] || data[destination2]) {
        //     swapData[origin2] = data[destination2];
        //     swapData[destination2] = data[origin2];
        //   };
        //   if (data[origin3] || data[destination3]) {
        //     swapData[origin3] = data[destination3];
        //     swapData[destination3] = data[origin3];
        //   };
        //   if (data[origin4] || data[destination4]) {
        //     swapData[origin4] = data[destination4];
        //     swapData[destination4] = data[origin4];
        //   };
        // };

        const origin = `${key1}_${count}`,
          destination = `${key2}_${count}`;
        const data = form.getFieldsValue([origin, destination]);
        if (!data[origin] && !data[destination]) return;
        swapData[origin] = data[destination];
        swapData[destination] = data[origin];
      });

      // Clear fields
      swapData = {
        [`pickupDate_${count}`]: null,
        [`pickupOption_${count}`]: null,
        [`originColor_${count}`]: null,
        [`destinationColor_${count}`]: null,
        [`pickupTime_${count}`]: null,
        [`pickupTime_${count}`]: null,
        [`dropoffDate_${count}`]: null,
        [`dropoffOption_${count}`]: null,
        [`dropoffTimeFrom_${count}`]: null,
        [`dropoffTimeTo_${count}`]: null,
        ...swapData,
      };

      const transport = clonedForm.transport[count - 1] || {};
      if (!_.isEmpty(transport)) {
        const pickupAssignee = [],
          dropoffAssignee = [],
          pickupContact = [],
          dropoffContact = [];
        _.each([0, 1, 2, 3, 4], (index) => {
          const assignee1 = `pickupAssignee_${count}_${index}`,
            assignee2 = `dropoffAssignee_${count}_${index}`;
          const contact1 = `pickupContact_${count}_${index}`,
            contact2 = `dropoffContact_${count}_${index}`;
          const values = form.getFieldsValue([
            assignee1,
            assignee2,
            contact1,
            contact2,
          ]);
          if (values[assignee1] !== undefined)
            pickupAssignee.push(values[assignee1]);
          if (values[assignee2] !== undefined)
            dropoffAssignee.push(values[assignee2]);
          if (values[contact1] !== undefined)
            pickupContact.push(values[contact1]);
          if (values[contact2] !== undefined)
            dropoffContact.push(values[contact2]);
        });
        transport.pickupAssignee = dropoffAssignee.join("|");
        transport.dropoffAssignee = pickupAssignee.join("|");
        transport.pickupContact = dropoffContact.join("|");
        transport.dropoffContact = pickupContact.join("|");
        const dropImg = transport.dropoffImages;
        transport.dropoffImages = transport.pickupImages;
        transport.pickupImages = dropImg;
        orderSetter({ formData: clonedForm });
      }
      form.setFieldsValue(swapData);

      setTimeout(() => {
        orderSetter({ reversed: true });
      }, 0);
    });
  };

  useEffect(() => {
    if (resetForm) {
      form.resetFields();
      orderSetter({ resetForm: false });
    }
  }, [resetForm]);

  useEffect(() => {
    if (imported) {
      const { importMode, mode, transportIndex, data } = imported;
      const transportDate = getFieldValue("transportDate");
      const dateKey = `${modeDate[mode]}_${transportIndex + 1}`;

      const index = transportIndex + 1;
      const affix = mode === "origin" ? "pickup" : "dropoff";
      const dataAffix = importMode === "origin" ? "pickup" : "dropoff";
      const key = `${mode}_${index}`;

      const details = {
        [key]: data[importMode],
        [dateKey]: transportDate,
        [`${affix}Borrower_${index}`]: data[`${dataAffix}Borrower`],
        [`${affix}Memo_${index}`]: data[`${dataAffix}Memo`],
        [`${affix}SiteName_${index}`]: data[`${dataAffix}SiteName`],
        [`${mode}Color_${index}`]: "RED",
      };
      setFieldsValue(details);
      orderSetter({ imported: null });
    }
  }, [imported]);

  useEffect(() => {
    if (date) {
      form.setFieldsValue({
        transportDate: date,
      });
    }
  }, [date]);

  return (
    <FormContext.Provider value={{ ...state }}>
      <Form>
        {!isDriver && (
          <BasicInfoForm client={client} handleRefetch={handleRefetch} />
        )}
        <Row className="transport-tab">
          {!isDriver && (
            <Row style={{ marginTop: 20, textAlign: "right" }}>
              <Button type="primary" onClick={onReverseClick}>
                発地・着地反転
              </Button>
            </Row>
          )}
          <Tabs
            style={styles.headerStyle}
            defaultActiveKey="transport1"
            onChange={onTransportClick}
            centered
          >
            <TabPane forceRender tab="運搬１" key="transport1">
              <TransportForm
                transportIndex="1"
                notify={notify}
                transportValue={formData.transport[0]}
                client={client}
              />
            </TabPane>
            <TabPane forceRender tab="運搬２" key="transport2">
              <TransportForm
                transportIndex="2"
                notify={notify}
                transportValue={formData.transport[1]}
                client={client}
              />
            </TabPane>
            <TabPane forceRender tab="運搬３" key="transport3">
              <TransportForm
                transportIndex="3"
                notify={notify}
                transportValue={formData.transport[2]}
                client={client}
              />
            </TabPane>
          </Tabs>
        </Row>
      </Form>
    </FormContext.Provider>
  );
};

const styles = {
  headerStyle: {
    marginTop: 20,
  },
  formStyle: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 20,
    border: "1px solid rgba(0, 0, 0, 0.2)",
    borderTopWidth: 0,
  },
};

export default withApollo(Form.create()(OrdersForm));
