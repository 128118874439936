import {gql} from 'apollo-boost';

const getAll = gql`
query allVehicles($first: Int, $offset: Int, $filter: VehicleFilter) {
  allVehicles (first:$first,offset:$offset,filter:$filter, orderBy: DISPLAY_ORDER_ASC){
    nodes {
      id,
      vehicleNo,
      vehicleType,
      nextInspectionDate,
      nextPeriodicInspectionDate,
      oilChangeDistance,
      greaseDistance,
      status,
    },
    totalCount
  }
}`;

const create = gql`
  mutation createVehicle (
    $vehicleNo :String!,
    $vehicleType : VehicleTypeOption!,
    $nextInspectionDate : Date,
    $nextPeriodicInspectionDate : Date,
    $oilChangeDistance :Int,
    $greaseDistance : Int,
    $status :StatusOption,
    $displayOrder: Int,
    $updatedUserId : Int!,
    $createdUserId : Int!,
  ){
  createVehicle (input:{
    vehicle : {
      vehicleNo :$vehicleNo,
      vehicleType :$vehicleType,
      nextInspectionDate : $nextInspectionDate,
      nextPeriodicInspectionDate : $nextPeriodicInspectionDate,
      oilChangeDistance :$oilChangeDistance,
      greaseDistance : $greaseDistance,
      status :$status,
      displayOrder: $displayOrder,
      createdUserId : $createdUserId,
      updatedUserId : $updatedUserId
    }
	}){
    vehicle {
      vehicleNo
    }
    }
  }
`;

const getById = gql`
query getVehicle ($id:Int!){
  vehicleById (id : $id) {
      id,
      vehicleNo,
      vehicleType,
      nextInspectionDate,
      nextPeriodicInspectionDate,
      oilChangeDistance,
      greaseDistance,
      displayOrder
      status
  }
}`;


const updateById = gql`
mutation updateVehicle (
    $id : Int!,
    $vehicleNo :String!,
    $vehicleType :VehicleTypeOption!,
    $nextInspectionDate : Date,
    $nextPeriodicInspectionDate : Date,
    $oilChangeDistance :Int,
    $greaseDistance : Int,
    $status :StatusOption,
    $displayOrder: Int,
    $updatedUserId : Int,
    $createdUserId : Int,
  ){
  updateVehicleById (input:{
    id : $id,
    vehiclePatch : {
      vehicleNo :$vehicleNo,
      vehicleType :$vehicleType,
      nextInspectionDate : $nextInspectionDate,
      nextPeriodicInspectionDate : $nextPeriodicInspectionDate,
      oilChangeDistance :$oilChangeDistance,
      greaseDistance : $greaseDistance,
      status :$status,
      displayOrder: $displayOrder
      createdUserId : $createdUserId,
      updatedUserId : $updatedUserId
    }
	}){
    vehicle {
      vehicleNo
    }
    }
  }
`;

const deleteById = gql`
mutation deleteVehicleById($id:Int!){
  deleteVehicleById (input:{id:$id}){
    deletedVehicleId
  }
}
`;

const getAllTypes = gql`
query allVehicleTypes($first: Int, $offset: Int, $filter: VehicleFilter) {
  allVehicleTypes (first:$first,offset:$offset,filter:$filter, orderBy: VEHICLE_TYPE_ASC){
    nodes {
      id,
      referenceId,
      escortVehicleType,
      vehicleType,
    },
    totalCount
  }
}`;

export {getAll, create, getById, updateById, deleteById, getAllTypes};