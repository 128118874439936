import React, { Component } from 'react';
import { Row, Col, Form, Button } from 'antd';

import DispatchList from '../../components/DispatchStatus/List';
import ListBase from '../../commons/ListBase';
import DispatchMap from "./DispatchMap";

class Base extends Component {

  state = {
    selectedRows: [],
    triggerUpdate: false
  };

  handleOnSelection = (selectedRowKeys, selectedRows) => {
    // this.state.selectedRows = selectedRows;
    this.setState({ ...this.state, selectedRows });
  };

  resetTrigger = () => {
    this.setState({ ...this.state, triggerUpdate: false });
  }

  triggerUpdate = () => {
    this.setState({ ...this.state, triggerUpdate: true });
  }

  render() {
    return (
      <Row className='h100' justify='space-around' align='middle' gutter={16}>
        <Col span={16}>
          <DispatchMap placemarkers={this.state.selectedTrucks} selectedRows={this.state.selectedRows} triggerUpdate={this.state.triggerUpdate} resetTrigger={this.resetTrigger} />
        </Col>
        <Col span={8}>
          <Button size='large' type='primary' style={{ width: '100%', marginBottom: 10 }} onClick={this.triggerUpdate}>更新</Button>
          <ListBase
            config={DispatchList()}
            pagination={true}
            handleOnSelection={this.handleOnSelection} />
        </Col>
      </Row>
    )
  }
}

export default Form.create()(Base);