import { AutoComplete, Form } from "antd";
import { gql } from 'apollo-boost';
import _ from 'lodash';
import 'moment/locale/ja';
import React, { useMemo, useState } from 'react';
import { withApollo } from "react-apollo";
import { toFullWidth } from './functions';

const constructQuery = (query, key) => gql`
  query MyQuery($value: String) {
    ${query}(filter: {${key}: {likeInsensitive: $value}}) {
      nodes {
        ${key}
      }
    }
  }
`;

const queryTypes = {
  origin: { query: 'allOriginOrdersTransportsColors', dataKey: 'origin' },
  destination: { query: 'allDestinationOrdersTransportsColors', dataKey: 'destination' }
};

const LocationInput = (props) => {
  const { form: { getFieldDecorator }, type, fieldName, initialValue, onBlur, client } = props;
  const [values, setValues] = useState([]);

  const onSearchLocation = _.debounce(async (value) => {
    // Query
    if (!values) {
      setValues([]);
      return;
    };

    const { query, dataKey } = queryTypes[type];
    const { data } = await client.query({
      query: constructQuery(query, dataKey),
      fetchPolicy: 'network-only',
      variables: {
        value: `%${toFullWidth(value)}%`
      }
    });

    // Set to state
    const uniqueLocations = _.uniqBy(data[query].nodes, (data) => data[dataKey]);
    setValues(_.map(uniqueLocations, data => data[dataKey]));
  }, 500);

  const renderOptions = useMemo(() => values.map((data, index) => (
    <AutoComplete.Option key={index} value={data}>
      {data}
    </AutoComplete.Option>
  )), [values])

  return (
    <>
      <Form.Item style={{ display: 'inline-table' }}>
        {getFieldDecorator(fieldName, {
          initialValue: initialValue ? toFullWidth(initialValue) : null,
        })(
          <AutoComplete
            onChange={onSearchLocation}
            dataSource={renderOptions}
            onBlur={onBlur}
          />
        )}
      </Form.Item>
    </>
  )
};

export default withApollo(LocationInput);