import React, {useContext, useState} from 'react';
import {Form, Row, Col, Button, Table, Modal, notification} from 'antd';
import Year from '../../../commons/Picklist/Year';
import Month from '../../../commons/Picklist/Month';
import {CustomRadioGroup, CustomHiddenInput} from "../../../commons/CustomDataEntry";
import CustomerModalConfig from '../../../components/MasterMaintenance/Customer/ListModal'
import ModalBase from "../../../commons/ModalBase";
import {Context} from '../../../commons/Context';
import _ from 'lodash';
import moment from 'moment';
import uuid from 'uuid/v4';
import { GET_ORDERS_DISPATCH_REPORTING } from "../../Orders/queries";
import WithEventButton from '../../../commons/WithEventButton';

notification.config({
    duration: 8
})

const Filter = ({lt, form}) => {
    let graphs = [];
    const [, handleSelectedRowKeys] = useState([])
    const { state, dispatch, client} = useContext(Context);

    const openNotificationWithIcon = (type, message) => {
        notification[type]({message});
    };

    const search = (e) => {
        state.data = _.slice(state.data, 0, 5, true);
        e.preventDefault();
        form.validateFields((err, values) => {
            if (!err) {
                if(state.data.length){
                    values.data = state.data
                    Modal.confirm({
                        title: '確認',
                        icon: 'false',
                        content: '複数顧客別売上の分析を開始しますか？',
                        okText: 'はい',
                        cancelText: 'いいえ',
                        onOk: () => {
                            dispatch({
                                type : 'filters',
                                filters : { values }
                            });
                            dispatch({
                                type: 'spinner',
                                spinner: true
                            });
                            let date1 = moment(`${values.transportDate_From_Year}-${values.transportDate_From_Month}-01`).format("YYYY-MM-01");
                            let date2 = moment(`${values.transportDate_To_Year}-${values.transportDate_To_Month}-01`).add(1,"months").format("YYYY-MM-01");
                            customerSale(values, date1, date2);
                            openNotificationWithIcon('success', '複数顧客別売上の分析を開始しました。');
                        }
                    });
                }else{
                    openNotificationWithIcon('error', '顧客名を選択してください。');
                }
            }
        });
    };

    let customerSale = async (values, date1, date2) => {
        let mapIds = values.data.map(customer => { 
					return { customerId: { equalTo: customer.id } } }
        )
        // let mapIds = values.data.map(customer => customer.id)
        const {data:orders} = await client.query({
          query: GET_ORDERS_DISPATCH_REPORTING,
          fetchPolicy: 'network-only',
          variables: {
            filter: {
              isDeleted: { equalTo: false },
              or: mapIds,
              dispatchStatus: {
                equalTo: "COMPLETED"
              },
              and: [
                {	transportDate: { greaterThanOrEqualTo: date1 } },
								{ transportDate: { lessThan: date2 } }
              ]
            }
          }
        });
        let startDate = moment(date1);
        let endDate = moment(date2);
        let yearOrMonth = values.radio_customer === 1 ? 'year' : 'month';

        let data = _.chain(orders.allOrders.nodes).map(order=>{
					const escortFare = _.sumBy(order.ordersEscortVehiclesByOrderId.nodes, 'transportBillingAmount') || 0;
						
          return {
            date: moment(order.transportDate).startOf(yearOrMonth).format("YYYY-MM"),
            fare: order.transportBillingAmount + escortFare,
            customer: order.customerByCustomerId.nameJa
          }
        }).groupBy('date').map((cs,i) => {
            let datePick = yearOrMonth === 'year' ? moment(_.head(cs).date).format('YYYY年') : moment(_.head(cs).date).startOf(yearOrMonth).format('YYYY年MM月')
            return {
                date: datePick,
                values: _.chain(cs).map(({customer, fare}) => {
                    return {
                        name: customer,
                        fare
                    }
                }).groupBy('name').map((ym) => {
                    return {
                        [_.head(ym).name]: _.sumBy(ym,'fare'),
                    }
                }).value()
            }
        }).value();

        let selectedRows = []
        values.data.forEach(item => {
            selectedRows.push({[item.nameJa] : 0 })
        });
     

        while(startDate.isBefore(endDate)) { 
            if(yearOrMonth === 'year') {
                graphs.push(_.merge({date: startDate.format("YYYY年")}, ...selectedRows));
            } else {
                graphs.push(_.merge({date: startDate.format("YYYY年MM月")}, ...selectedRows));
            } 
            startDate = startDate.add(1,yearOrMonth);
        }

        let output = data.map(flat => {
            return _.merge({date: flat.date}, ...selectedRows, ...flat.values)
        });

        output.push(...graphs);

        const flatten = output.reduce((o, cur, i) => {
            let found = o.find(elem =>  elem.date === cur.date);
            if (!found) {
                o.push(cur)
            }
            return o;
        }, []);

        dispatch({
            type: 'flatten',
            flatten
        });
    }

    const handleReset = () => {
        dispatch({
            type: 'reset'
        });
        form.resetFields();
        handleSelectedRowKeys([])
        openNotificationWithIcon('warning', '分析条件をクリアしました。');
    }


    const selectedCustomer = (rows) => {
        let selectedRows = rows.map(({id,nameJa})=>{
            let { data } = state;
            let filterData = data.filter(item => item.nameJa === nameJa);
            if(filterData.length <= 0){
                return {
                    id,
                    nameJa,
                    key: uuid()
                };
            }
            // eslint-disable-next-line array-callback-return
            return
        });
        
        let rowFilter = selectedRows.filter(item => item !== undefined);
        
        if(rowFilter.length){
            dispatch({
                type:'add',
                selectedData: rowFilter
            });
        }
        handleSelectedRowKeys([])
    };

    const radioFilter = [
        {id: 2, value: lt('rdo_by_month'), autoFocus: true },
        {id: 1, value: lt('rdo_by_year'), autoFocus: false }
    ];

    let columns = [{
        title:'顧客名',
        dataIndex: 'nameJa',
        key: 'nameJa',
    }];

    return (
        <Form onSubmit={search}>
            <Row>
                <Col>
                    {lt('lbl_output_category')}
                </Col>
            </Row>
            <Row style={{marginTop: '10px'}}>
                <Col>
                    <CustomRadioGroup
                        options={radioFilter}
                        form={form}
                        fieldName='radio_customer'
                        decorator={{
                            initialValue: 0
                        }}
                    />
                </Col>
            </Row>
            <Row style={{marginTop: '4px'}}>
                <Col span={24}>
                    {lt('分析年月')}
                </Col>
                <Col span={24}>
                    <div className='ant-row ant-form-item four_column' style={{marginTop: '10px'}}>
                        <Year
                            form={form}
                            fieldName='transportDate_From_Year'
                            postLabel={lt('lbl_conditions_from_year')}
                            rowField={true}
                            style={{width: 100, display: 'inline-block', marginRight: 10}}
                            decorator={{
                                rules: [{
                                    required: true,
                                    message: ' '
                                }]
                            }}
                            labelWidth={16}
                            width={18}
                        />
                        <Month
                            form={form}
                            fieldName='transportDate_From_Month'
                            postLabel={lt('lbl_conditions_from_month')}
                            style={{width: 80, display: 'inline-block', marginRight: 10}}
                            rowField={true}
                            decorator={{
                                rules: [{
                                    required: true,
                                    message: ' '
                                }]
                            }}
                            labelWidth={12}
                            width={14}
                        />

                        <Year
                            form={form}
                            fieldName='transportDate_To_Year'
                            postLabel={lt('lbl_conditions_to_year')}
                            rowField={true}
                            style={{width: 100, display: 'inline-block', marginRight: 10}}
                            decorator={{
                                rules: [{
                                    required: true,
                                    message: ' '
                                }]
                            }}
                            labelWidth={16}
                            width={18}
                        />
                        <CustomHiddenInput
                            form={form}
                            fieldName='customerId'
                            type='hidden'
                            decorator={{}}
                        />
                        <CustomHiddenInput
                            form={form}
                            fieldName='customerName'
                            type='hidden'
                            decorator={{}}
                        />
                        <Month
                            form={form}
                            fieldName='transportDate_To_Month'
                            postLabel={lt('lbl_conditions_to_month')}
                            rowField={true}
                            style={{width: 80, display: 'inline-block', marginRight: 10}}
                            decorator={{
                                rules: [{
                                    required: true,
                                    message: ' '
                                }]
                            }}
                            labelWidth={12}
                            width={14}
                        />
                    </div>
                </Col>
            </Row>
            <Row style={{marginBottom: '10px'}}>
                <Col span={12} style={{padding: '5px'}}>
                    {lt('lbl_target_customer')}
                </Col>
                <Col span={12} style={{textAlign: 'right'}}>
                    <ModalBase
                        title={lt('lbl_customer_name')}
                        hideInputText={true}
                        form={form}
                        btnLabel={lt('btn_click_on_select_customer')}
                        fieldName='customers'
                        afterSelect={selectedCustomer}
                        config={CustomerModalConfig('checkbox')}
                        decorator={{}}
                        labelWidth={32}
                        width={32}
                        buttonMarginRight={0}
                        buttonMarginLeft={0}
                        filterStatus
                    />
                </Col>
            </Row>
            <Row >
                <Col span={24}>
                    <Table
                        className='searchsales'
                        position="top"
                        bordered={true}
                        columns={columns}
                        dataSource={ state.data = _.slice(state.data, 0, 5, true)}
                        pagination={{position: 'top'}}
                    />
                </Col>
            </Row>
            <Row style={{marginTop: '10px'}}>
                <Col span={12}>
                    <Button type='default' style={{width: '106px'}} onClick={handleReset} htmlType='button'>{lt('btn_click_on_clear_conditions')}</Button>
                </Col>
                <Col span={12} style={{textAlign: 'right' }}>
                    <WithEventButton
                        event={search}
                        label={lt('btn_click_on_analyze')}
                        style={{width: '106px'}}
                        loading={false}
                    />
                </Col>
            </Row>
        </Form>
    );
}

export default Form.create()(Filter);