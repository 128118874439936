import { Col, Form, Row } from 'antd';
import moment from 'moment';
import React from 'react';
/** Get All Common **/
import {
    CustomButton,
    CustomDatepicker,
    CustomHiddenInput, CustomInput,
    CustomInputNumber,
    CustomRadioGroup
} from '../../../commons/CustomDataEntry';
import VehicleType from '../../../commons/Picklist/VehicleType';
/** Get All User Queries **/
import {
    create,
    deleteById,
    getAll,
    getById,
    updateById
} from '../../../queries/MasterMaintenance/vehicle';
/** Get ADhoc translate **/
import adhocTranslator from '../../../utils/locales/translate-adhoc';
/** Get user_id stored in LocalStorage **/
import decodeToken from '../../../utils/user_id';

export default ({ mode }) => {

    /** Initialize translators **/
    let lt = adhocTranslator('K902-020');

    return {
        /** Assign mode **/
        mode,

        /** Redirect config **/
        redirect: '/vehicle/search-list',

        /** query mapping **/
        data: {
            idRef: 'vehicleById'
        },

        /** Map queries **/
        queries: {
            GET_BY_ID: getById,
            GET_ALL: getAll,
            UPDATE_BY_ID: updateById,
            CREATE: create,
            DELETE_BY_ID: deleteById
        },

        decorate: (variables) => {
            variables.nextInspectionDate = variables.nextInspectionDate ? variables.nextInspectionDate.format("YYYY-MM-DD") : null;
            variables.nextPeriodicInspectionDate = variables.nextPeriodicInspectionDate ? variables.nextPeriodicInspectionDate.format("YYYY-MM-DD") : null;
            variables.displayOrder = variables.displayOrder ? +variables.displayOrder : null;
            return variables;
        },

        /** messages */
        messages: {
            add: "車両情報を登録しますか？",
            edit: "車両情報を更新しますか？",
            delete: "車両情報を削除しますか？",
            reg_success_message: lt("reg_success_message"),
            reg_error_message: lt("reg_error_message"),
            edit_success_message: lt("edit_success_message"),
            edit_failure_message: lt("edit_failure_message"),
            delete_success_message: lt("delete_success_message"),
            delete_failure_message: lt("delete_failure_message")
        },

        /** Modal **/
        modalLabels: {
            add: {
                title: '',
                cancelText: '',
                okText: '',
                content: ''
            },
            edit: {
                title: '',
                cancelText: '',
                okText: '',
                content: ''
            },
            delete: {
                title: '',
                cancelText: '',
                okText: '',
                content: ''
            }
        },

        /** Create common component **/
        component: ({ form, data, handleSubmit, loading }) => {
            const radioStatus = [
                { id: 'ACTIVE', value: lt('rdo_active') },
                { id: 'INACTIVE', value: lt('rdo_inactive') }
            ];

            let btnLabel;
            if (mode === 'edit') {
                btnLabel = lt('btn_edit')
            } else if (mode === 'delete') {
                btnLabel = lt('btn_delete')
            } else {
                btnLabel = lt('btn_register')
            }

            let formSubmit = () => {
                let oilChange = form.getFieldValue('oilChangeDistance')
                let greaseDist = form.getFieldValue('greaseDistance')
                form.setFieldsValue({
                    oilChangeDistance: !!oilChange ? parseFloat(oilChange.toString().replace(/,/g, '')) : "",
                    greaseDistance: !!greaseDist ? parseFloat(greaseDist.toString().replace(/,/g, '')) : ""
                })
            }

            let onBlur = (fieldName) => {
                let x = document.getElementById(fieldName).value;
                x = x.replace(/\D+/g, '');
                form.setFieldsValue({
                    [fieldName]: `${x}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                })
            }

            const handleFormSubmit = e => {
                formSubmit()
                handleSubmit(e)
            }

            return (
                <Row type='flex' className='h100' justify='space-around' align='top'>
                    <Col span={16}>
                        <Form onSubmit={handleFormSubmit} layout='vertical'>

                            <CustomInput
                                autoFocus={true}
                                form={form}
                                fieldName='vehicleNo'
                                label={lt('lbl_vehicle_no')}
                                mode={mode}
                                offset={1}
                                width={5}
                                style={{ width: 380, marginRight: 10 }}
                                decorator={{
                                    rules: [{
                                        required: true,
                                        message: lt('号車を入力してください。'),
                                    }],
                                    initialValue: data.vehicleNo
                                }}
                                labelRowWidth={5} />
                            <VehicleType
                                form={form}
                                label={lt('lbl_vehicle_class')}
                                fieldName='vehicleType'
                                rowField={true}
                                decorator={{
                                    rules: [{
                                        required: true,
                                        message: lt('車両区分を入力してください。'),
                                    }],
                                    initialValue: data.vehicleType
                                }}
                                mode={mode}
                                width={5}
                                labelWidth={5} />
                            <CustomDatepicker
                                form={form}
                                label={lt('lbl_date_of_next_vehicle_inspection')}
                                fieldName='nextInspectionDate'
                                mode={mode}
                                placeholder={lt('dpc_date_of_next_vehicle_inspection')}
                                decorator={{
                                    initialValue: data.nextInspectionDate && moment(data.nextInspectionDate)
                                }}
                                offset={0}
                                labelWidth={5}
                                width={7}
                            />
                            <CustomHiddenInput
                                fieldName={mode === 'add' ? 'createdUserId' : 'updatedUserId'}
                                form={form}
                                decorator={{
                                    initialValue: decodeToken().user_id
                                }}
                            />
                            <CustomDatepicker
                                form={form}
                                label={lt('lbl_date_of_next_periodic_inspectionl')}
                                fieldName='nextPeriodicInspectionDate'
                                mode={mode}
                                btnReset='点検日リセット'
                                placeholder={lt('dpc_date_of_next_periodic_inspectionl')}
                                rowField={true}
                                decorator={{
                                    initialValue: data.nextPeriodicInspectionDate && moment(data.nextPeriodicInspectionDate)
                                }}
                                labelWidth={5}
                                width={14}
                                hasClearBtn={true}
                                clearBtnMargin={99}
                            />
                            <CustomInputNumber
                                form={form}
                                fieldName='oilChangeDistance'
                                label={lt('lbl_oil_replacement_distance')}
                                placeholder={lt('lbl_oil_replacement_distance')}
                                mode={mode}
                                maxLength={8}
                                rowField={true}
                                postLabel=" / 30,000km"
                                onBlur={() => onBlur('oilChangeDistance')}
                                style={{ width: 195, marginRight: 10, direction: 'LTR', textAlign: 'right' }}
                                decorator={{
                                    initialValue: data.oilChangeDistance
                                }}
                                btnReset='距離リセット'
                                hasClearBtn={true}
                                labelRowWidth={5} />
                            <CustomInputNumber
                                form={form}
                                fieldName='greaseDistance'
                                label={lt('lbl_grease_up_distance')}
                                postLabel=" / 5,000km"
                                placeholder={lt('lbl_grease_up_distance')}
                                rowField={true}
                                mode={mode}
                                maxLength={8}
                                style={{ width: 195, marginRight: 10, direction: 'LTR', textAlign: 'right' }}
                                decorator={{
                                    initialValue: data.greaseDistance
                                }}
                                onBlur={() => onBlur('greaseDistance')}
                                btnReset='距離リセット'
                                hasClearBtn={true}
                                clearBtnMargin={23}
                                labelRowWidth={5} />
                            <CustomHiddenInput
                                form={form}
                                type='hidden'
                                fieldName='vehicleConfig'
                                mode={mode}
                                decorator={{
                                    initialValue: true
                                }}
                            />
                            <CustomInput
                                form={form} fieldName='displayOrder'
                                label={lt('lbl_display_order')}
                                type='number'
                                mode={mode}
                                half={true}
                                labelWidth={6}
                                offset={4}
                                width={8}
                                maxLength={100}
                                decorator={{
                                    initialValue: data.displayOrder
                                }} />
                            <Col span={24}>

                                <span style={{ color: 'black' }}> {lt('lbl_status')} </span>
                                <CustomRadioGroup
                                    options={radioStatus}
                                    form={form}
                                    mode={mode}
                                    rowField={false}
                                    fieldName='status'
                                    labelWidth={5}
                                    className='radio-button-layout'
                                    decorator={{
                                        rules: [{
                                            required: true,
                                            message: lt('lbl_status'),
                                        }],
                                        initialValue: data.status
                                    }}
                                />
                            </Col>
                            <CustomButton label={btnLabel}
                                event={handleFormSubmit}
                                htmlType='submit'
                                loading={loading}
                                style={{ marginTop: '15px', height: '40px', width: '104px' }}
                                offset={12} />
                        </Form>

                    </Col>
                    <Col span={8} />
                </Row>
            );
        }
    }
}