
import React, {Fragment} from 'react';
import { Input } from 'antd';

const CustomHiddenInput = ({
        fieldName,
        decorator,
        form : { getFieldDecorator }
    }) => {
    
    return (
        <Fragment>
            {getFieldDecorator(fieldName, decorator)(
                <Input type="hidden" />
            )}
        </Fragment>
    );
}

export default CustomHiddenInput;
