import { Form } from "antd";
import React, { Fragment } from "react";
import CustomButton from "../../../commons/CustomDataEntry/CustomButton";
import CustomFilter from "../../../commons/CustomDataEntry/CustomFilter";
import CustomHiddenInput from "../../../commons/CustomDataEntry/CustomHiddenInput";
import CustomInput from "../../../commons/CustomDataEntry/CustomInput";
import { getAll } from '../../../queries/MasterMaintenance/customer';
import adhocTranslator from "../../../utils/locales/translate-adhoc";

export default (type = 'radio') => {
    let lt = adhocTranslator('K030-021');

    return {
        modalPop: true,
        fetchQuery: getAll,
        allKey: 'allCustomers',
        perPage: 20,
        displayField: 'nameJa',
        columns: [
            {
                title: lt('lbl_name_header'),
                className: "nameHeader",
                dataIndex: 'nameJa',
                key: 'nameJa',
            }

        ],
        type,
        th: 430,

        searchHeader: ({ form, handleSearch, loading, state, setState }) => {
            let setFilter = (e, filter) => {
                setState({
                    type1: false,
                    type2: false,
                    type3: false,
                    type4: false,
                    type5: false,
                    type6: false,
                    type7: false,
                    type8: false,
                    type9: false,
                    type10: false,
                    type11: false,
                });
                setState({
                    ['type' + e.target.id]: true
                });
                let nameJaKatakana_LikeStart;
                switch (filter) {
                    case 'ア':
                        nameJaKatakana_LikeStart = ['ア', 'イ', 'ウ', 'エ', 'オ'];
                        break;
                    case 'カ':
                        nameJaKatakana_LikeStart = ['カ', 'キ', 'ク', 'ケ', 'コ', 'ガ', 'ギ', 'グ', 'ゲ', 'ゴ'];
                        break;
                    case 'サ':
                        nameJaKatakana_LikeStart = ['サ', 'シ', 'ス', 'セ', 'ソ', 'ザ', 'ジ', 'ズ', 'ゼ', 'ゾ'];
                        break;
                    case 'タ':
                        nameJaKatakana_LikeStart = ['タ', 'チ', 'ツ', 'テ', 'ト', 'ダ', 'ヂ', 'ヅ', 'デ', 'ド'];
                        break;
                    case 'ナ':
                        nameJaKatakana_LikeStart = ['ナ', 'ニ', 'ヌ', 'ネ', 'ノ'];
                        break;
                    case 'ハ':
                        nameJaKatakana_LikeStart = ['ハ', 'ヒ', 'フ', 'ヘ', 'ホ', 'バ', 'ビ', 'ブ', 'ベ', 'ボ', 'パ', 'ピ', 'プ', 'ペ', 'ポ'];
                        break;
                    case 'マ':
                        nameJaKatakana_LikeStart = ['マ', 'ミ', 'ム', 'メ', 'モ'];
                        break;
                    case 'ヤ':
                        nameJaKatakana_LikeStart = ['ヤ', 'ユ', 'ヨ'];
                        break;
                    case 'ラ':
                        nameJaKatakana_LikeStart = ['ラ', 'リ', 'ル', 'レ', 'ロ'];
                        break;
                    case 'ワ':
                        nameJaKatakana_LikeStart = ['ワ', 'ン'];
                        break;
                    default:
                        break;
                }
                form.setFieldsValue({ nameJaKatakana_LikeStart });
                if (!filter) {
                    form.setFieldsValue({
                        nameJaKatakana: ''
                    });
                }
                handleSearch();
            };
            return (
                <Fragment>
                    <Form onKeyPress={e => e.which === 13 && e.preventDefault()}>
                        <CustomFilter buttonType={state} setFilter={setFilter} />
                        <CustomHiddenInput
                            form={form}
                            type='hidden'
                            fieldName='nameJaKatakana_LikeStart'
                        />
                        <div className='ant-row ant-form-item'>
                            <CustomInput form={form}
                                fieldName='nameJaKatakana_Like_nameJa_Like'
                                type='text'
                                rowField={false}
                                style={{ width: 480, display: 'inline-block', marginRight: 10 }} />
                            <CustomButton label={lt('btn_search')}
                                loading={loading}
                                onClick={handleSearch}
                                width={2} />
                        </div>
                    </Form>
                </Fragment>
            )
        },

        dataMapping: (item) => {
            return {
                id: item.id,
                loginId: item.loginId,
                nameJa: item.nameJa,
                nameJaKatakana: item.nameJaKatakana,
                formOutputName: item.formOutputName,
                postCode: item.postCode,
                address: item.address,
                telNo1: item.telNo1,
                telNo2: item.telNo2,
                faxNo1: item.faxNo1,
                faxNo2: item.faxNo2,
                emailAddress: item.emailAddress,
                url: item.url,
                transportationUnitPriceId: item.transportationUnitPriceId,
                escortVehicleUnitPriceId: item.escortVehicleUnitPriceId,
                escortVehicleOutsourcingUnitPriceId: item.escortVehicleOutsourcingUnitPriceId,
                billingDateRangeMonthFrom: item.billingDateRangeMonthFrom,
                billingDateRangeDayFrom: item.billingDateRangeDayFrom,
                billingDateRangeMonthTo: item.billingDateRangeMonthTo,
                billingDateRangeDayTo: item.billingDateRangeDayTo,
                dueDateMonth: item.dueDateMonth,
                dueDateDay: item.dueDateDay,
                status: item.status,
            }
        }
    };
}

