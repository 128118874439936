import { Button, Col, Form, Row } from 'antd';
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import CustomInput from "../../../commons/CustomDataEntry/CustomInput";
import CustomSelect from "../../../commons/CustomDataEntry/CustomSelect";
import { ActionHeader } from '../../../commons/TableActions';
import WithEventButton from '../../../commons/WithEventButton';
import { getAll } from '../../../queries/MasterMaintenance/unitprice';
import adhocTranslator from "../../../utils/locales/translate-adhoc";

export default () => {
    let lt = adhocTranslator('K908-010');

    const unitPriceCategoryList = {
        FARE: lt('lbl_transportation_fare'),
        ESCORT_VEHICLE_FARE: lt('lbl_escort_vehicle_fare')
    };

    const fractionCategoryList = {
        AS_IT_IS: '変更なし',
        ROUND_UP: '切り上げ',
        ROUND_DOWN: '切り拾て',
        ROUND_OFF: '四拾五入'
    };

    return {
        fetchQuery: getAll,
        allKey: 'allUnitPrices',
        perPage: 20,
        removeType: true,
        th: '630px',
        columns: [
            {
                title: lt('lbl_unit_price_chart_name_header'),
                dataIndex: 'unitPriceName',
                key: 'unitPriceName',
                width: 200
            }, {
                title: lt('lbl_fare_category_header'),
                dataIndex: 'unitPriceCategory',
                key: 'unitPriceCategory',
                align: 'center',
                width: 200
            }, {
                title: lt('lbl_fraction_category_header'),
                dataIndex: 'fractionCategory',
                key: 'fractionCategory',
                align: 'center',
                width: 200
            }, {
                title: lt('lbl_process'),
                key: 'lbl_process',
                render: (text, record) => (
                    <span>
                        <Link to={{
                            pathname: `/unit-price/update/${record.id}`,
                            state: { id: text.id }
                        }}>[ {lt('btn_edit')} ]</Link>
                        <Link to={{
                            pathname: `/unit-price/delete/${record.id}`,
                            state: { id: text.id }
                        }}>[ {lt('btn_delete')} ]</Link>
                    </span>
                ),
                align: 'center',
                width: 130
            }
        ],

        dataMapping: (item) => {
            return {
                id: item.id,
                unitPriceName: item.unitPriceName,
                unitPriceCategory: unitPriceCategoryList[item.unitPriceCategory],
                fractionCategory: fractionCategoryList[item.fractionCategory]
            }
        },

        searchHeader: ({ form, handleSearch, loading, clearRows }) => {
            const radioPC = [
                { id: '', value: lt('全て') },
                { id: 'FARE', value: lt('lbl_transportation_fare') },
                { id: 'ESCORT_VEHICLE_FARE', value: lt('lbl_escort_vehicle_fare') }
            ];

            const resetFields = () => {
                form.resetFields();
                handleSearch();
                clearRows(true)
            }

            return (
                <Fragment>
                    <span className="search-filter">{lt('hdr_Search_Conditionｓ')}</span>
                    <Form onSubmit={handleSearch}>
                        <Row type='flex'>
                            <Col span={24}>
                                <CustomInput
                                    form={form}
                                    fieldName='unitPriceName_Like'
                                    type='text'
                                    label={lt('lbl_conditionｓ_unit_price_chart_name')}
                                    decorator={{}}
                                    width={5}
                                    labelWidth={2}
                                />

                                <CustomSelect
                                    form={form}
                                    options={radioPC}
                                    label={lt('lbl_conditionｓ_unit_price_category')}
                                    fieldName='unitPriceCategory'
                                    decorator={{
                                    }}
                                    width={5}
                                    labelWidth={2}
                                />

                            </Col>

                        </Row>
                        <Row type='flex' justify='space-around'>
                            <Col span={24} align='center'>
                                {/*Submit*/}
                                <WithEventButton
                                    event={handleSearch}
                                    loading={loading}
                                    label={lt('btn_conditionｓ_search')}
                                    style={{ height: '38px', width: '104px', margin: '14px 14px 14px 0px' }}
                                />
                                <Button
                                    onClick={resetFields}
                                    type="primary"
                                    htmlType='button'
                                    loading={loading}
                                    style={{ height: '38px', width: '104px', margin: '14px 14px 14px 0px' }}
                                >
                                    {lt('btn_conditionｓ_clear')}
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Fragment>
            );
        },
        actionHeader: () => {
            return (
                <Fragment>
                    <ActionHeader
                        rightActions={
                            <Fragment>
                                <Button
                                    type="primary"
                                    htmlType="button">
                                    <Link to="/unit-price/register">{lt('btn_register_new_info')}</Link>
                                </Button>
                            </Fragment>
                        }
                    />
                </Fragment>
            )
        },
        minWidth: 'max-contents'
    }
}