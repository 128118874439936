import { useEffect, useState } from 'react';

const keys = ['Tab', 'Space', 'Enter', 'ArrowLeft', 'ArrowRight']
export const useKeyPress = () => {
  const [keyPressed, setKeyPressed] = useState(null);

  // If pressed key is our target key then set to true
  const onKeyDown = ({ code }) => {
    if (keys.indexOf(code) >= 0) {
      setKeyPressed(code);
    }
  }

  // If released key is our target key then set to false
  const onKeyUp = ({ code }) => {
    if (keys.indexOf(code) >= 0) {
      setKeyPressed(null);
    }
  };

  // Add event listeners
  useEffect(() => {
    window.addEventListener('keydown', onKeyDown, true);
    window.addEventListener('keyup', onKeyUp, true);
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener('keydown', onKeyDown, true);
      window.removeEventListener('keyup', onKeyUp, true);
    };
  }, []);

  return keyPressed;
}