import { Button, Col, DatePicker, Form, Row, Typography } from 'antd';
import jaJP from 'antd/lib/date-picker/locale/ja_JP';
import decode from 'jwt-decode';
import moment from 'moment';
import 'moment/locale/ja';
import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { withApollo } from 'react-apollo';
import { GlobalContext, OrderContext } from '../../../../utils/context';
import adhocTranslator from '../../../../utils/locales/translate-adhoc';
import { fetchTypes, formatTypes, tabTypes } from '../../constants';
import { GET_ORDERS } from '../../queries';
import SearchModal from '../actions/modals/seach-modal';

const lt = adhocTranslator('K020-010');
const dateFormat = 'YYYY/MM/DD';

const dayListJap = {
  0: '（日）',
  1: '（月）',
  2: '（火）',
  3: '（水）',
  4: '（木）',
  5: '（金）',
  6: '（土）',
}

const SearchView = (props) => {
  const { client, form: { setFieldsValue, getFieldValue, getFieldDecorator, getFieldsValue }, getTransportColorList } = props;
  const { date, view, refetch, orderSetter, dataSetter, isDriver, selected } = useContext(OrderContext);
  const { savedDate, savedSpan, globalSetter } = useContext(GlobalContext);
  const [state, setState] = useState({
    // loading: true,
    show: false,
    fetch: fetchTypes.CURRENT
  });
  const dateRef = useRef(), spanRef = useRef();

  const stateSetter = (value) => setState(state => ({ ...state, ...value }));

  const isActive = (type) => state.fetch === type ? 'primary' : 'default';

  const onCurrentClick = (currentDate) => async () => {
    spanRef.current = fetchTypes.CURRENT;
    stateSetter({ fetch: fetchTypes.CURRENT });
    orderSetter({ fetch: fetchTypes.CURRENT });
    if (state.fetch !== fetchTypes.CURRENT) {
      orderSetter({ pageLoading: true });
    }
    const { date } = getFieldsValue();
    await getOrders(currentDate || date, fetchTypes.CURRENT);
  };

  const onFutureClick = (currentDate) => async () => {
    spanRef.current = fetchTypes.FUTURE;
    stateSetter({ fetch: fetchTypes.FUTURE });
    orderSetter({ fetch: fetchTypes.FUTURE });
    if (state.fetch !== fetchTypes.FUTURE) {
      orderSetter({ pageLoading: true });
    }
    await getOrders(currentDate, fetchTypes.FUTURE);
  };

  const onChange = async (value) => {
    const dateValue = value ? value : null;
    if (!moment(dateValue).isSame(date)) {
      orderSetter({ pageLoading: true });
    }
    orderSetter({ date: dateValue });
  };

  const constructOrderDateFilter = (type, fetch, minDate, maxDate = null, planned = false) => {
    const frequency = (type === 'THIS_WEEK' || type === 'NEXT_WEEK') ? 'week' : 'month'

    const getPlannedDate = date => {
      return planned ? moment(date).subtract(1, 'day').format(formatTypes.DATE_QUERY) : moment(date).format(formatTypes.DATE_QUERY)
    }

    if (type === 'THIS_WEEK' || type === 'THIS_MONTH') {
      if (fetch === fetchTypes.CURRENT) {
        return {
          transportDate: { isNull: true },
          orderDate: {
            lessThanOrEqualTo: getPlannedDate(minDate),
            greaterThanOrEqualTo: moment(minDate).startOf(frequency).format(formatTypes.DATE_QUERY)
          },
          transportDateOption: { equalTo: type }
        }
      } else {
        return {
          transportDate: { isNull: true },
          or: [
            {
              orderDate: {
                lessThanOrEqualTo: getPlannedDate(minDate),
                greaterThanOrEqualTo: moment(minDate).startOf(frequency).format(formatTypes.DATE_QUERY)
              }
            },
            {
              orderDate: {
                lessThanOrEqualTo: getPlannedDate(maxDate),
                greaterThanOrEqualTo: moment(maxDate).startOf(frequency).format(formatTypes.DATE_QUERY)
              }
            }
          ],
          transportDateOption: { equalTo: type }
        }
      }
    } else {
      if (fetch === fetchTypes.CURRENT) {
        return {
          transportDate: { isNull: true },
          orderDate: {
            lessThanOrEqualTo: getPlannedDate(minDate),
            greaterThanOrEqualTo: moment(minDate).subtract(1, frequency).startOf(frequency).format(formatTypes.DATE_QUERY)
          },
          transportDateOption: { equalTo: type }
        }
      } else {
        return {
          transportDate: { isNull: true },
          or: [
            {
              orderDate: {
                lessThanOrEqualTo: getPlannedDate(minDate),
                greaterThanOrEqualTo: moment(minDate).subtract(1, frequency).startOf(frequency).format(formatTypes.DATE_QUERY)
              }
            },
            {
              orderDate: {
                lessThanOrEqualTo: getPlannedDate(maxDate),
                greaterThanOrEqualTo: moment(maxDate).subtract(1, frequency).startOf(frequency).format(formatTypes.DATE_QUERY)
              }
            }
          ],
          transportDateOption: { equalTo: type }
        }
      }
    }
  }

  const constructOvernightDisplay = (fetch, minDate, maxDate, specific = false) => {
    const overnightTransportFilter = (fetch || state.fetch) === fetchTypes.CURRENT ?
      {
        overnight: { equalTo: 'YES' },
        ordersTransportsByOrderId: {
          some: {
            and: [
              { pickupDate: { lessThanOrEqualTo: minDate } },
              { dropoffDate: { greaterThanOrEqualTo: minDate } },
            ]
          }
        }
      } :
      {
        overnight: { equalTo: 'YES' },
        ordersTransportsByOrderId: {
          some: {
            and: [
              { pickupDate: { lessThanOrEqualTo: minDate } },
              { dropoffDate: { greaterThanOrEqualTo: minDate } },
              { pickupDate: { lessThanOrEqualTo: maxDate } },
              { dropoffDate: { greaterThanOrEqualTo: maxDate } }
            ]
          }
        }
      };

    if (specific) {
      overnightTransportFilter.transportDateOption = { equalTo: 'SPECIFIED_DAY' }
    }
    return overnightTransportFilter;
  };

  const getOrders = async (date, fetch = null) => {
    const minDate = date && moment(date).isValid() ? date.format(formatTypes.DATE_QUERY) : null;
    const maxDate = minDate ? moment(minDate).add(1, 'd').format(formatTypes.DATE_QUERY) : null;

    let filter = {};

    if (minDate && maxDate) {
      const overnightDate = minDate ? moment(date.format(formatTypes.DATE_QUERY)).subtract(1, 'days').format(formatTypes.DATE_QUERY) : null;
      switch (view) {
        case tabTypes.DELETED:
        case tabTypes.ORDERS:
          const specificFilter = (fetch || state.fetch) === fetchTypes.CURRENT ?
            { transportDate: { equalTo: minDate }, transportDateOption: { equalTo: 'SPECIFIED_DAY' } } :
            { transportDate: { in: [minDate, maxDate] }, transportDateOption: { equalTo: 'SPECIFIED_DAY' } };

          const overnightOrderFilter = (fetch || state.fetch) === fetchTypes.CURRENT ?
            { overnight: { equalTo: 'YES' }, transportDate: { equalTo: overnightDate }, transportDateOption: { equalTo: 'SPECIFIED_DAY' } } :
            { overnight: { equalTo: 'YES' }, transportDate: { in: [overnightDate, maxDate] }, transportDateOption: { equalTo: 'SPECIFIED_DAY' } };

          filter = {
            or: [
              specificFilter,
              overnightOrderFilter,
              constructOvernightDisplay(fetch, minDate, maxDate, true),
              constructOrderDateFilter('THIS_WEEK', (fetch || state.fetch), minDate, maxDate),
              constructOrderDateFilter('NEXT_WEEK', (fetch || state.fetch), minDate, maxDate),
              constructOrderDateFilter('THIS_MONTH', (fetch || state.fetch), minDate, maxDate),
              constructOrderDateFilter('NEXT_MONTH', (fetch || state.fetch), minDate, maxDate)
            ]
          }
          break;
        case tabTypes.DISPATCH:

          const overnightDispatchFilter = (fetch || state.fetch) === fetchTypes.CURRENT ?
            { overnight: { equalTo: 'YES' }, transportDate: { equalTo: overnightDate } } :
            { overnight: { equalTo: 'YES' }, transportDate: { in: [overnightDate, maxDate] } };

          const transportFilter = (fetch || state.fetch) === fetchTypes.CURRENT ?
            { transportDate: { equalTo: minDate } } :
            { transportDate: { in: [minDate, maxDate] } };

          filter = {
            or: [
              transportFilter,
              overnightDispatchFilter,
              constructOvernightDisplay(fetch, minDate, maxDate, false),
              constructOrderDateFilter('THIS_WEEK', (fetch || state.fetch), minDate, maxDate),
              constructOrderDateFilter('NEXT_WEEK', (fetch || state.fetch), minDate, maxDate),
              constructOrderDateFilter('THIS_MONTH', (fetch || state.fetch), minDate, maxDate),
              constructOrderDateFilter('NEXT_MONTH', (fetch || state.fetch), minDate, maxDate)
            ]
          }
          break;
        case tabTypes.CONNECTED:
          filter = {
            and: [
              {
                or: [
                  { ordersConnectedsByOrderId1Exist: true },
                  { ordersConnectedsByOrderId2Exist: true }
                ]
              }
            ],
            or: [
              { transportDate: { greaterThanOrEqualTo: minDate }, transportDateOption: { equalTo: 'SPECIFIED_DAY' } },
              // { transportDate: { equalTo: minDate } },
              { overnight: { equalTo: 'YES' }, transportDate: { equalTo: overnightDate } },
              constructOvernightDisplay(fetch, minDate, maxDate, false),
              constructOrderDateFilter('THIS_WEEK', (fetch || state.fetch), minDate, maxDate),
              constructOrderDateFilter('NEXT_WEEK', (fetch || state.fetch), minDate, maxDate),
              constructOrderDateFilter('THIS_MONTH', (fetch || state.fetch), minDate, maxDate),
              constructOrderDateFilter('NEXT_MONTH', (fetch || state.fetch), minDate, maxDate)
            ]
          }
          break;
        case tabTypes.PLANNED:
          filter = {
            or: [
              { transportDate: { greaterThan: moment().format(formatTypes.DATE_QUERY) }, transportDateOption: { equalTo: 'SPECIFIED_DAY' } },
              constructOrderDateFilter('THIS_WEEK', (fetch || state.fetch), minDate, maxDate),
              constructOrderDateFilter('NEXT_WEEK', (fetch || state.fetch), minDate, maxDate),
              constructOrderDateFilter('THIS_MONTH', (fetch || state.fetch), minDate, maxDate),
              constructOrderDateFilter('NEXT_MONTH', (fetch || state.fetch), minDate, maxDate)
            ]
          }
          break;
        default:
      }
    } else {
      switch (view) {
        case tabTypes.ORDERS:
        case tabTypes.DISPATCH:
        case tabTypes.CONNECTED:
          filter = { transportDate: { isNull: true } }
          break;
        case tabTypes.PLANNED:
          filter = { transportDate: { greaterThan: moment().format(formatTypes.DATE_QUERY) }, transportDateOption: { equalTo: 'SPECIFIED_DAY' } }
          break;
        default:
      }
    }

    if (isDriver) {
      const token = localStorage.getItem('token')
      const { vehicle_number } = decode(token)
      if (vehicle_number) {
        filter = { ...filter, vehicleId: { equalTo: vehicle_number } }
      }
    }

    if(view === tabTypes.DELETED) {
      filter.isDeleted = { equalTo: true }
    } else {
      filter.isDeleted = { equalTo: false }
    }

    const { data } = await client.query({
      query: GET_ORDERS,
      fetchPolicy: 'network-only',
      variables: {
        filter,
        orderBy: ['TRANSPORT_DATE_ASC', 'DISPLAY_ORDER_ASC', 'CREATED_AT_ASC']
      }
    });
    dataSetter({ orderList: [...data.allOrders.nodes] });
    orderSetter({ pageLoading: false });
  };

  useEffect(() => {
    (async () => {
      if (date) {
        dateRef.current = date;
      }

      setFieldsValue({ date });
      await getOrders(date, state.fetch);
    })();
  }, [date]);

  useEffect(() => {
    if (!savedDate) {
      setFieldsValue({ date: moment() });
      orderSetter({ date: moment() });
    }
  }, []);

  useEffect(() => {
    (async () => {
      if (refetch) {
        if (selected.length) orderSetter({ selected: [] })

        await getTransportColorList()
        const { date: pickerDate } = getFieldsValue();
        await getOrders(pickerDate || date, state.fetch);
        orderSetter({ refetch: false });
      }
    })();
  }, [refetch]);

  useEffect(() => {
    (async () => {
      if (savedDate || savedSpan) {
        dateRef.current = savedDate;
        spanRef.current = savedSpan;

        setFieldsValue({ date: savedDate });
        stateSetter({ fetch: savedSpan || fetchTypes.CURRENT });
        orderSetter({ date: savedDate });
      }
    })();

    return () => {
      globalSetter({ savedDate: dateRef.current, savedSpan: spanRef.current });
    }
  }, [savedDate, savedSpan]);

  useEffect(() => {
    (async () => {
      const { date: pickerDate } = getFieldsValue();
      const dateData = pickerDate || date;
      if (view) {
        await getOrders(dateData, state.fetch);
      }
    })();
  }, [view]);

  useEffect(() => {
    orderSetter({ overlay: state.show })
  }, [state.show]);

  useLayoutEffect(() => {
    document.getElementById('date').getElementsByTagName('input')[0].setAttribute("tabIndex", "-1");
  }, []);

  const renderSuffix = () => {
    const value = getFieldValue('date');
    if (!value) return <></>
    return <Typography style={{ width: 50, marginRight: 53, paddingTop: 1 }}>{dayListJap[value.day()]}</Typography>
  }

  return (
    <Col style={{ flex: 1, maxWidth: 550 }}>
      <Row type='flex' style={{ alignItems: 'center' }}>
        <Typography>運搬日</Typography>
        <Form>
          <Form.Item style={{ marginBottom: 0 }}>
            {getFieldDecorator('date')(
              <DatePicker
                format={dateFormat}
                locale={jaJP}
                id='order-search'
                autoFocus={false}
                placeholder={lt('txt_ｆrom_date')}
                suffixIcon={renderSuffix()}
                showToday={false}
                size='default'
                onChange={onChange}
                style={{ width: 200, display: 'inline-block', marginLeft: 15, marginRight: 15 }}
              />
            )}
          </Form.Item>
        </Form>
        <Button
          loading={state.loading}
          tabIndex='-1'
          onClick={onCurrentClick(moment(date))}
          type={isActive(fetchTypes.CURRENT)}
          style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
        >
          当日
        </Button>

        <Button
          loading={state.loading}
          tabIndex='-1'
          onClick={onFutureClick(moment(date))}
          type={isActive(fetchTypes.FUTURE)}
          style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
        >
          当日/翌日
        </Button>

        {view === tabTypes.ORDERS || view === tabTypes.DISPATCH ? (
          <Button
            tabIndex='-1'
            type='primary'
            style={{ marginLeft: 10 }}
            onClick={() => stateSetter({ show: true })}
          >
            過去検索
          </Button>
        ) : null}
      </Row>
      {state.show && (
        <SearchModal
          onOk={(value) => { }}
          noAction={true}
          onCancel={() => stateSetter({ show: false })}
        />
      )}
    </Col>
  )
};

export default withApollo(Form.create()(SearchView))