import React, { Component, Fragment } from 'react';
import { Spin, Form, Modal, notification } from 'antd';
import { Query, Mutation } from 'react-apollo';
import { withRouter } from 'react-router';
import _ from 'lodash';
import { graphql, compose, withApollo } from 'react-apollo';
import { getCustomerById } from '../queries/MasterMaintenance/customer';
import { getAll } from '../queries/Estimate';
import { createUnitpriceDetails, updateUnitpriceDetails } from '../queries/MasterMaintenance/unitpricedetails';
import { create as createHolidays } from '../queries/MasterMaintenance/holidays';
import { getAll as ordersTransports } from '../queries/Orders/ordersTransportDestination'
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import { CREATE_ROLES, DELETE_ROLES } from '../queries/Roles';
import { getLastCustomer } from '../queries/Customer';
const moment = extendMoment(Moment);

notification.config({
    duration: 8
})

class AEDBase extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            disable: true,
            overlapping: false,
            activeKey: this.isAdmin() ? '1' : '3'
        }
        // this.refKM = React.createRef();
    }

    openNotificationWithIcon = (type, message) => {
        let title;
        switch (type) {
            case 'success':
                title = "成功";
                break;
            case 'warning':
                title = "警告";
                break;
            case 'error':
                title = "エラー";
                break;
            default:
                break;
        }
        notification[type]({
            message: title,
            //message: message,
            description: message,
        });
    };

    handleLoading = (loading) => {
        this.setState({ loading });
    };

    handleChangeTab = (activeKey) => {
        this.setState({ activeKey })
    }

    isAdmin = () => {
        const token = localStorage.getItem('token');
        if (token) {
            const decodeToken = JSON.parse(atob(token.split('.')[1]));
            if (decodeToken.role === 'app_driver' || decodeToken.role === 'app_customer') {
                return false;
            }
        }
        return true;
    }

    isAllowed = () => {
        const token = localStorage.getItem('token');
        if (token) {
            const decodeToken = JSON.parse(atob(token.split('.')[1]));
            if (decodeToken.role === 'app_driver' || decodeToken.role === 'app_user') {
                return false;
            }
        }
        return true;
    }

    hasAdminRoles = (roles) => {
        const dataFilter = roles.filter(auth => ['SYSTEM_ADMINISTRATOR', 'REP_OR_ACCOUNTS_EXECUTIVE'].indexOf(auth) >= 0);
        return !!dataFilter.length
    }

    handleSave = async (action, variables, data = [], refetch) => {
        let {
            config: {
                redirect,
                decorate,
                modal,
                queries: {
                    // eslint-disable-next-line
                    GET_ALL
                }
            }
        } = this.props;
        try {
            // Return if modal;

            if (modal) {
                modal.onData(variables);
            } else {
                if (!!variables.holidaysList) {
                    await this.props.client.mutate({
                        mutation: createHolidays,
                        variables: {
                            holidaysList: variables.holidaysList
                        }
                    });
                }

                if (decorate) {
                    variables = decorate(variables, this.state, data);
                    if (variables.estimatesDetails) {
                        variables.estimatesDetails = _.map(variables.estimatesDetails, (detail) => {
                            return _.omit(detail, ['packagingName']);
                        });
                    }
                }

                if (this.props.config.isUserConfig && !this.isAllowed()) {
                    if (this.hasAdminRoles((variables.authority || []))) {
                        this.openNotificationWithIcon('error', "このリクエストの処理は許可されていません")
                        // Do something
                        return;
                    }
                }

                let createdData = await action({
                    variables,
                    // refetchQueries: [{query: GET_ALL}]
                    // refetchQueries: [{ query: ordersTransports }]
                });

                if (variables.authority && variables.authority.length) {
                    const { updatedUserId, createdUserId } = variables;
                    const { data: { createUser: { user: { id } } } } = createdData
                    const roles = variables.authority.map(async (authority) => {
                        await this.props.client.mutate({
                            mutation: CREATE_ROLES,
                            variables: {
                                input: { role: { userId: id, role: authority, createdUserId, updatedUserId } }
                            }
                        });
                    });
                    await Promise.all(roles);
                };

                if (variables.csvFile) {
                    let id = createdData.data.createUnitPrice.unitPrice.id;
                    const formattedCSV = variables.csvFile.map(file => {
                        return { ...file, unitPriceId: id }
                    })

                    formattedCSV.pop() // remove last element as it is the csv header

                    await this.props.client.mutate({
                        mutation: createUnitpriceDetails,
                        variables: { payload: JSON.stringify(formattedCSV) }
                    });
                }

                // fetchMore({
                //     variables: {
                //         first: perPage,
                //         offset: page * perPage,
                //         filter
                //     },
                //     updateQuery: (prev, {fetchMoreResult}) => {
                //         return Object.assign({}, fetchMoreResult);
                //     }
                // });


                if(refetch) refetch();
                this.setState({ loading: false });
                if (this.props.config.messages && this.props.config.messages.reg_success_message) {
                    this.openNotificationWithIcon('success', this.props.config.messages.reg_success_message);
                }
                this.props.history.push(redirect);
            }
        } catch (error) {
            const errors = await error.graphQLErrors.map((error) => error.message);
            if (errors[0] === 'duplicate key value violates unique constraint "users_login_id_key"' && variables.loginId) {
                this.openNotificationWithIcon('error', variables.loginId + " : ユーザーIDが重複しています。")
            } else if (errors[0] === 'duplicate key value violates unique constraint "vehicles_vehicle_no_key"' && variables.vehicleNo) {
                this.openNotificationWithIcon('error', variables.vehicleNo + "はすでに登録されています。")
            } else if (errors[0] === 'duplicate key value violates unique constraint "packagings_packaging_name_key"') {
                this.openNotificationWithIcon('error', "重複する荷姿情報が存在しています。登録データを確認してください。")
            } else if (this.props.config.messages && this.props.config.messages.reg_error_message) {
                this.openNotificationWithIcon('error', this.props.config.messages.reg_error_message);
            }
            this.setState({ loading: false });
        }

    };

    handleEdit = async (action, variables, refetch, data) => {
        if (data != null) {
            if (_.isString(variables.transportFare) && variables.transportFare) {
                variables.transportFare = parseFloat(variables.transportFare.toString().replace(/,/g, ''))
            }
            if (variables.escortVehicleDistance !== data.escortVehicleDistance && variables.escortVehicleDistance) {
                variables.escortVehicleDistance = parseFloat(variables.escortVehicleDistance.toString().replace(/,/g, ''))
            }
            if (variables.escortVehicleFare !== data.escortVehicleFare && variables.escortVehicleFare) {
                variables.escortVehicleFare = parseFloat(variables.escortVehicleFare.toString().replace(/,/g, ''))
            }
            if (variables.transportDistance !== data.transportDistance && variables.transportDistance) {
                variables.transportDistance = parseFloat(variables.transportDistance.toString().replace(/,/g, ''))
            }
            if (variables.subcontractedEscortVehicleFare !== data.subcontractedEscortVehicleFare && variables.subcontractedEscortVehicleFare) {
                variables.subcontractedEscortVehicleFare = parseFloat(variables.subcontractedEscortVehicleFare.toString().replace(/,/g, ''))
            }
            if (variables.subcontractedEscortVehicleDistance !== data.subcontractedEscortVehicleDistance && variables.subcontractedEscortVehicleDistance) {
                variables.subcontractedEscortVehicleDistance = parseFloat(variables.subcontractedEscortVehicleDistance.toString().replace(/,/g, ''))
            }
            if (_.isString(variables.subcontractedEscortVehicleCount && variables.subcontractedEscortVehicleCount)) {
                variables.subcontractedEscortVehicleCount = parseFloat(variables.subcontractedEscortVehicleCount.toString().replace(/,/g, ''))
            }
            if (_.isString(variables.escortVehicleCount && variables.escortVehicleCount)) {
                variables.escortVehicleCount = parseFloat(variables.escortVehicleCount.toString().replace(/,/g, ''))
            }
        } else {
            if (!!variables.escortVehicleFee && variables.escortVehicleFee) {
                variables.escortVehicleFee = parseFloat(variables.escortVehicleFee.toString().replace(/,/g, ''))
            }
            if (!!variables.escortVehicleCount && variables.escortVehicleCount) {
                variables.escortVehicleCount = parseFloat(variables.escortVehicleCount.toString().replace(/,/g, ''))
            }
            if (!!variables.subcontractedEscortVehicleCount && variables.subcontractedEscortVehicleCount) {
                variables.subcontractedEscortVehicleCount = parseFloat(variables.subcontractedEscortVehicleCount.toString().replace(/,/g, ''))
            }
            if (variables.hasOwnProperty('escortVehicleDistance')) {
                if (variables.escortVehicleDistance && variables.escortVehicleDistance)
                    variables.escortVehicleDistance = parseFloat(variables.escortVehicleDistance.toString().replace(/,/g, ''))
            }
            if (variables.hasOwnProperty('transportFee') && variables.transportFee) {
                variables.transportFee = parseFloat(variables.transportFee.toString().replace(/,/g, ''))
            }

            if (variables.hasOwnProperty('consumptionTax') && variables.consumptionTax) {
                variables.consumptionTax = parseFloat(variables.consumptionTax.toString().replace(/,/g, ''))
            }
            if (variables.hasOwnProperty('totalAmount') && variables.totalAmount) {

                variables.totalAmount = parseFloat(variables.totalAmount.toString().replace(/,/g, ''))
            }

            if (!!variables.transportDistance && variables.transportDistance) {
                variables.transportDistance = parseFloat(variables.transportDistance.toString().replace(/,/g, ''))
            }
            if (variables.hasOwnProperty('subtotal') && variables.subtotal != null) {
                variables.subtotal = parseFloat(variables.subtotal.toString().replace(/,/g, ''))
            }
        }


        // variables.transportDistance=parseFloat(variables.transportDistance.replace(/,/g, ''))
        /** Get Properties from the config **/
        if (!this.props.location.state && this.props.config.modal) {
            this.props.location.state = { id: 1 };
        }
        let {
            config: {
                decorate,
                postEdit,
                redirect,
                modal,
                queries: {
                    // eslint-disable-next-line
                    GET_ALL
                }
            },
            location: {
                state: {
                    id
                }
            }
        } = this.props;

        try {

            if (modal) {
                modal.onData(variables);
            } else {
                if (decorate) {
                    variables = decorate(variables, this.state, data);
                }
                if (variables.password) {
                    postEdit.bind(this)(variables);
                }
                if (variables.estimatesDetails) {
                    variables.estimatesDetails = _.map(variables.estimatesDetails, (detail) => {
                        return _.omit(detail, ['packagingName']);
                    });
                }
                variables.id = id;
                _.forEach(variables, (value, key) => {
                    if (
                        value === undefined &&
                        key !== "password" &&
                        key !== "driversLicensePdf" &&
                        key !== "otherCertifications" &&
                        key !== "notes" &&
                        key !== "areaName" &&
                        key !== "assignee" &&
                        key !== "borrower" &&
                        key !== "contact" &&
                        key !== "destinationChecker" &&
                        key !== "siteName" &&
                        key !== "customerByCustomerId" &&
                        key !== "latitude-longitude" &&
                        key !== "csvFile" &&
                        key !== "postCode" &&
                        key !== "driverId") {
                        variables[key] = 0;
                    }
                });

                if (this.props.config.isUserConfig && !this.isAllowed()) {
                    if (this.hasAdminRoles((variables.authority || [])) || this.hasAdminRoles(data.roles)) {
                        this.openNotificationWithIcon('error', "このリクエストの処理は許可されていません")
                        // Do something
                        return;
                    }
                }

                const editData = await action({
                    variables,
                    // refetchQueries: [{query: GET_ALL}]
                    // refetchQueries: [{query: ordersTransportDestination}],
                });

                if (variables.authority && variables.authority.length) {
                    const { data: { updateUserById: { user: { id, rolesByUserId: { nodes } } } } } = editData;
                    const roleList = nodes.map(roles => roles.role);
                    const diff = _.xor(variables.authority, roleList);
                    if (diff.length) {
                        const deleteRoles = nodes.map(async (roles) => {
                            await this.props.client.mutate({
                                mutation: DELETE_ROLES,
                                variables: {
                                    id: roles.id
                                }
                            })
                        });

                        const { updatedUserId } = variables;
                        const createRoles = variables.authority.map(async (authority) => {
                            await this.props.client.mutate({
                                mutation: CREATE_ROLES,
                                variables: {
                                    input: { role: { userId: id, role: authority, updatedUserId, createdUserId: updatedUserId } }
                                }
                            });
                        });
                        await Promise.all(deleteRoles, createRoles);
                    }
                };

                if (variables.csvFile) {
                    const tobeDeletedIds = variables.details.map(detail => detail.id)
                    const formattedCSV = variables.csvFile.map(file => {
                        return { ...file, unitPriceId: id }
                    })

                    formattedCSV.pop() // remove last element as it is the csv header

                    await this.props.client.mutate({
                        mutation: updateUnitpriceDetails,
                        variables: {
                            payload: JSON.stringify({
                                tobeDeletedIds,
                                formattedCSV
                            })
                        }
                    });
                }

                refetch();
                this.setState({ loading: false });
                if (this.props.config.messages && this.props.config.messages.edit_success_message) {
                    this.openNotificationWithIcon('success', this.props.config.messages.edit_success_message);
                }
                this.props.history.push(redirect);
            }
        } catch (error) {
            if (this.props.config.messages && this.props.config.messages.edit_failure_message) {
                this.openNotificationWithIcon('error', this.props.config.messages.edit_failure_message);
            }
            // const errors = await error.graphQLErrors.map((error) => error.message);
            this.setState({ loading: false });
        }

    };

    zeroPad = (num, places) => {
        let zero = places - num.toString().length + 1;
        return Array(+(zero > 0 && zero)).join("0") + num;
    }

    downloadExcel = async (data) => {
        try {

            let details = [{
                por: '',
                packaging: '',
                distance: '',
                amount: '',
                notes: '',
                inhouseEscortCount: '',
                outsourceEscortCount: '',
                escortFee: '',
                __typename: 'excelData'
            }];

            if (this.state.list) {
                details = [];
                details = this.state.list.map(detail => {
                    const transportFee = detail.transportFee ? detail.transportFee : 0
                    const escortVehicleFee = detail.escortVehicleFee ? detail.escortVehicleFee : 0
                    let data = {
                        por: detail.originDestinationLocation,
                        packaging: detail.packagingName,
                        distance: detail.transportDistance.toLocaleString(),
                        amount: Math.round(Number(transportFee) + Number(escortVehicleFee)).toLocaleString(),
                        notes: detail.notes ? detail.notes : '',
                        inhouseEscortCount: detail.escortVehicleCount ? detail.escortVehicleCount : 0,
                        outsourceEscortCount: detail.outsourceEscortVehicleCount ? detail.outsourceEscortVehicleCount : 0,
                        escortFee: escortVehicleFee,
                        transportFee,
                        __typename: 'excelData'
                    };
                    return data;
                });
            }

            const { data: { allEstimates } } = await this.props.client.query({
                query: getAll,
                fetchPolicy: "network-only",
                variables: {
                    filter: {
                        and: [
                            {
                                createdAt: {
                                    greaterThanOrEqualTo: moment().format("YYYY-01-01")
                                }
                            },
                            {
                                createdAt: {
                                    lessThan: moment().add(1, 'year').format("YYYY-01-01")
                                }
                            }
                        ]
                    }
                }
            });

            const estimateNodes = allEstimates.nodes
            const lastEstimate = estimateNodes.length ? estimateNodes[estimateNodes.length - 1] : {}
            const lastEstimateNo = _.isEmpty(lastEstimate) ? 0 : lastEstimate.id
            let excelData = {
                id: 1,
                key: '',
                createdAt: moment().format("YYYYMMDD"),
                customerNameJa: '',
                packagingName: '',
                transportationFare: '',
                escortVehicle: '',
                totalAmount: '',
                estimateNumber: moment().format("YYYYMMDD") + '-' + this.zeroPad(lastEstimateNo + 1, 5),
                dateOfIssue: moment().format("平成YYYY年MM月分"),
                customerName: data.customerNameJa,
                companyName: '',
                quotationAmount: '',
                validity: '',
                rows: details,
                subtotal: Math.round(data.subtotal).toLocaleString(),
                tax: Math.round(data.consumptionTax).toLocaleString(),
                totalamount: Math.round(data.totalAmount).toLocaleString(),
                __typename: 'excelData'
            };

            this.props.client.writeData({
                data: {
                    excelOutput: {
                        __typename: 'excelOutput',
                        type: 'Estimates',
                        excelEstimateOutputData: [excelData],
                        excelOrdersOutputData: [],
                        excelInvoiceOutputData: [],
                        excelCrewPerformanceOutputData: [],
                        excelDispatchOutputData: [],
                        excelCustomersOutputData: []
                    }
                }
            });
        } catch (error) {
            // const errors = await error.graphQLErrors.map((error) => error.message);
            this.setState({ loading: false });
        }
    }

    handleDelete = async (action, variables, data) => {
        /** Get Properties from the config **/
        let {
            config: {
                redirect,
                decorate,
                modal,
                queries: {
                    // eslint-disable-next-line
                    GET_ALL
                }
            },
            location: {
                state: {
                    id
                }
            }
        } = this.props;

        try {
            if (modal) {
                modal.onData(variables);
            } else {
                variables.id = id;
                if (decorate) {
                    variables = decorate(variables, this.state, data);
                }

                if (this.props.config.isUserConfig && !this.isAllowed()) {
                    if (this.hasAdminRoles((variables.authority || []))) {
                        this.openNotificationWithIcon('error', "このリクエストの処理は許可されていません")
                        // Do something
                        return;
                    }
                }

                await action({
                    variables,
                    // refetchQueries: [{query: GET_ALL}]
                });

                this.setState({ loading: false });
                if (this.props.config.messages && this.props.config.messages.delete_success_message) {
                    this.openNotificationWithIcon('success', this.props.config.messages.delete_success_message);
                }
                this.props.history.push(redirect);
            }
        } catch (error) {
            const errors = await error.graphQLErrors.map((error) => error.message);
            if (errors[0] === 'update or delete on table "vehicles" violates foreign key constraint "vehicle_id" on table "orders_dispatch"' && variables.vehicleNo) {
                this.openNotificationWithIcon('error', variables.vehicleNo + ' すでに使用されているデータがあるため削除できません');
            } else if (errors[0] === 'update or delete on table "users" violates foreign key constraint "driver_id_1" on table "orders_dispatch"' && variables.loginId) {
                this.openNotificationWithIcon('error', variables.loginId + ' すでに使用されているデータがあるため削除できません。');
            } else if (errors[0] === 'update or delete on table "packagings" violates foreign key constraint "packing1" on table "orders_dispatch"' && variables.packagingName) {
                this.openNotificationWithIcon('error', variables.packagingName + ' すでに使用されているデータがあるため削除できません。');
            } else if (errors[0] === 'update or delete on table "unit_prices" violates foreign key constraint "transportation_unit_price_id" on table "customers"') {
                this.openNotificationWithIcon('error', 'すでに使用しているデータがあるため、削除できません。');
            } else if (errors[0] === 'update or delete on table "unit_prices" violates foreign key constraint "unit_price_id" on table "unit_prices_details"') {
                this.openNotificationWithIcon('error', 'すでに使用しているデータがあるため、削除できません。');
            } else if (this.props.config.messages && this.props.config.messages.delete_failure_message) {
                this.openNotificationWithIcon('error', this.props.config.messages.delete_failure_message);
            }

            this.setState({ loading: false });
        }
    };

    handleSubmit = (action, refetch, data) => {
        return (e) => {
            e.preventDefault();
            this.handleLoading(true);
            /** Get Properties from the config **/
            let {
                form,
                config: {
                    mode,
                    messages
                }
            } = this.props;

            form.validateFields(async (err, values) => {
                if (values.driverId1 === values.driverId2 && !!values.driverId1) {

                    this.handleLoading(false);
                    this.handleChangeTab('1')
                    return this.openNotificationWithIcon('error', '同じ運転手を設定することができません。別な運転手を選択してください。');
                }
                if (!err) {
                    let handler, title, content, okText, cancelText;
                    let message = messages ? messages[mode] : "";
                    if (values.customerConfig) {
                        let err = false;
                        let err2 = false;
                        Object.keys(values).forEach((key) => {
                            if ((key === 'billingDateRangeMonthFrom' ||
                                key === 'billingDateRangeDayFrom' ||
                                key === 'billingDateRangeDayTo' ||
                                key === 'billingDateRangeMonthTo') &&
                                values[key] === undefined) {
                                err = true;
                            } else if ((key === 'dueDateDay' || key === 'dueDateMonth') &&
                                values[key] === undefined) {
                                err2 = true;
                            }
                        })
                        if (err && err2) {
                            this.handleLoading(false)
                            return this.openNotificationWithIcon('error', '請求日と転送期日の両方のフィールドが必須です。空白のままにしないでください。');
                        } else if (err) {
                            this.handleLoading(false)
                            return this.openNotificationWithIcon('error', 'すべての請求日フィールドは必須です。空白のままにしないでください。');
                        } else if (err2) {
                            this.handleLoading(false)
                            return this.openNotificationWithIcon('error', 'すべての振込期限フィールドは必須です。空白のままにしないでください。');
                        }

                        // values.status = +values.status
                    } else if (values.ordersConfig) {
                        let checker = false;
                        // let ndx = 1;
                        let orderTransportDestinations = {};
                        Object.keys(values).forEach((key) => {
                            if ((key.split('-').length > 1) || !values[key]) {
                                return;
                            }

                            let keyArray = key.split('_');
                            if (keyArray.length > 1) {
                                let orderTransportDestinationKey = keyArray.shift();
                                let orderTransportDestinationId = keyArray.shift();
                                if (!orderTransportDestinations[orderTransportDestinationId]) {
                                    orderTransportDestinations[orderTransportDestinationId] = {};
                                    orderTransportDestinations[orderTransportDestinationId].sequenceNo = +orderTransportDestinationId;
                                }
                                orderTransportDestinations[orderTransportDestinationId][orderTransportDestinationKey] = values[key];
                            }
                        });

                        Object.keys(orderTransportDestinations).forEach((key1) => {
                            let val = Object.keys(orderTransportDestinations[key1]);
                            val.forEach((key) => {
                                if (key.includes("Date")) {
                                    checker = true;
                                }
                            })
                        })

                        if (checker && (values.driverId1 != null || values.driverId2 != null) && mode !== 'delete') {
                            let smallest, biggest;
                            smallest = moment(new Date("12-12-9999 00:00:00"));
                            biggest = moment(new Date("01-01-1000 00:00:00"));
                            for (let x = 0; x < Object.keys(orderTransportDestinations).length; x++) {
                                if (orderTransportDestinations[`${x + 1}`][`pickupOption`] !== 'A' && orderTransportDestinations[`${x + 1}`][`pickupOption`] !== 'E') {
                                    let pickUp;
                                    // let date = orderTransportDestinations[`${x + 1}`][`pickupDate`] ? orderTransportDestinations[`${x + 1}`][`pickupDate`] : data.transportDate
                                    switch (orderTransportDestinations[`${x + 1}`][`pickupOption`]) {
                                        case 'B':
                                            pickUp = moment(orderTransportDestinations[`${x + 1}`][`pickupDate`].format("YYYY-MM-DD") + " " + orderTransportDestinations[`${x + 1}`][`pickupHour`].format("HH:mm"));
                                            break;
                                        case 'C':
                                            pickUp = moment(orderTransportDestinations[`${x + 1}`][`pickupDate`].format("YYYY-MM-DD") + " 11:59:00");
                                            break;
                                        case 'D':
                                            pickUp = moment(orderTransportDestinations[`${x + 1}`][`pickupDate`].format("YYYY-MM-DD") + " 12:01:00");
                                            break;
                                        case 'E':
                                            pickUp = moment(orderTransportDestinations[`${x + 1}`][`pickupDate`].format("YYYY-MM-DD") + " 00:00:00");
                                            break;
                                        case 'F':
                                            pickUp = moment(orderTransportDestinations[`${x + 1}`][`pickupDate`].format("YYYY-MM-DD") + " 20:00:00");
                                            break;
                                        default:
                                            break;
                                    }
                                    if (pickUp < smallest) {
                                        smallest = pickUp;
                                    }
                                }
                                if (orderTransportDestinations[`${x + 1}`][`dropoffOption`] !== 'A' && orderTransportDestinations[`${x + 1}`][`dropoffOption`] !== 'E') {
                                    let dropOff;
                                    let date = orderTransportDestinations[`${x + 1}`][`pickupDate`] ? orderTransportDestinations[`${x + 1}`][`pickupDate`] : data.transportDate
                                    switch (orderTransportDestinations[`${x + 1}`][`dropoffOption`]) {
                                        case 'B':
                                            let dropOffHours = orderTransportDestinations[`${x + 1}`][`dropoffHourTo`] ? orderTransportDestinations[`${x + 1}`][`dropoffHourTo`] : orderTransportDestinations[`${x + 1}`][`dropoffHourFrom`];
                                            dropOff = moment(date.format("YYYY-MM-DD") + " " + dropOffHours.format("HH:mm"));
                                            break;
                                        case 'C':
                                            dropOff = moment(date.format("YYYY-MM-DD") + " 11:59:00");
                                            break;
                                        case 'D':
                                            dropOff = moment(date.format("YYYY-MM-DD") + " 12:01:00");
                                            break;
                                        case 'E':
                                            dropOff = moment(date.format("YYYY-MM-DD") + " 00:00:00");
                                            break;
                                        case 'F':
                                            dropOff = moment(date.format("YYYY-MM-DD") + " 20:00:00");
                                            break;
                                        default:
                                            break;
                                    }
                                    if (dropOff > biggest) {
                                        biggest = dropOff;
                                    }
                                }
                            }
                            let dbdata;
                            if (mode === 'edit' && data) {
                                dbdata = await this.props.client.query({
                                    query: ordersTransports,
                                    fetchPolicy: 'network-only',
                                    variables: {
                                        filter: {
                                            orderByOrderId: {
                                                isDeleted: { equalTo: false },
                                                driverId1: { equalTo: values.driverId1 },
                                                and: { id: { notEqualTo: data.id } }
                                            },
                                            or: {
                                                pickupDate: { equalTo: smallest.format("YYYY-MM-DD") },
                                                or: { dropoffDate: { equalTo: biggest.format("YYYY-MM-DD") } }
                                            }
                                        }
                                    }
                                })
                            } else {
                                dbdata = await this.props.client.query({
                                    query: ordersTransports,
                                    fetchPolicy: 'network-only',
                                    variables: {
                                        filter: {
                                            orderByOrderId: { isDeleted: { equalTo: false }, driverId1: { equalTo: values.driverId1 } },
                                            or: {
                                                pickupDate: { equalTo: smallest.format("YYYY-MM-DD") },
                                                or: { dropoffDate: { equalTo: biggest.format("YYYY-MM-DD") } }
                                            }
                                        }
                                    }
                                })
                            }
                            let dbSmallest, dbBiggest;
                            let dbdata2 = dbdata.data.allOrdersTransports.nodes;
                            dbSmallest = moment(new Date("12-12-12000 00:00:00"));
                            dbBiggest = moment(new Date("01-01-1000 00:00:00"));

                            if (dbdata2.length) {
                                for (let x = 0; x < dbdata2.length; x++) {
                                    if (dbdata2[x].pickupOption !== 'A' && dbdata2[x].pickupOption !== 'E') {
                                        let pickUp;
                                        switch (dbdata2[x].pickupOption) {
                                            case 'B':
                                                let hour = dbdata2[x].pickupHour < 11 ? "0" + dbdata2[x].pickupHour : dbdata2[x].pickupHour;
                                                let minute = dbdata2[x].pickupMinute < 11 ? "0" + dbdata2[x].pickupMinute : dbdata2[x].pickupMinute;
                                                pickUp = moment(moment(dbdata2[x].pickupDate).format("YYYY-MM-DD") + " " + hour + ":" + minute);
                                                break;
                                            case 'C':
                                                pickUp = moment(moment(dbdata2[x].pickupDate).format("YYYY-MM-DD") + " 00:00:00");
                                                break;
                                            case 'D':
                                                pickUp = moment(moment(dbdata2[x].pickupDate).format("YYYY-MM-DD") + " 12:00:00");
                                                break;
                                            // case 'E':
                                            //     pickUp =  moment(moment(dbdata2[x].pickupDate).format("YYYY-MM-DD")+" 10:00:00");
                                            //     break;
                                            case 'F':
                                                pickUp = moment(moment(dbdata2[x].pickupDate).format("YYYY-MM-DD") + " 16:00:00");
                                                break;
                                            default:
                                                break;
                                        }
                                        if (pickUp < dbSmallest) {
                                            dbSmallest = pickUp;
                                        }
                                    }
                                    if (dbdata2[x].dropoffOption !== 'A' && dbdata2[x].dropoffOption !== 'E') {
                                        let dropOff;
                                        switch (dbdata2[x].dropoffOption) {
                                            case 'B':
                                                let hour = dbdata2[x].dropoffHourTo < 11 ? "0" + dbdata2[x].dropoffHourTo : dbdata2[x].dropoffHourTo;
                                                let minute = dbdata2[x].dropoffMinuteTo < 11 ? "0" + dbdata2[x].dropoffMinuteTo : dbdata2[x].dropoffMinuteTo;
                                                dropOff = moment(moment(dbdata2[x].dropoffDate).format("YYYY-MM-DD") + " " + hour + ":" + minute);
                                                break;
                                            case 'C':
                                                dropOff = moment(moment(dbdata2[x].dropoffDate).format("YYYY-MM-DD") + " 11:59:00");
                                                break;
                                            case 'D':
                                                dropOff = moment(moment(dbdata2[x].dropoffDate).format("YYYY-MM-DD") + " 23:59:00");
                                                break;
                                            // case 'E':
                                            //     dropOff =  moment(moment(dbdata2[x].dropoffDate).format("YYYY-MM-DD")+" 12:59:00"));
                                            //     break;
                                            case 'F':
                                                dropOff = moment(moment(dbdata2[x].dropoffDate).format("YYYY-MM-DD") + " 17:59:00");
                                                break;
                                            default:
                                                break;
                                        }
                                        if (dropOff > dbBiggest) {
                                            dbBiggest = dropOff
                                        }
                                    }
                                }
                                let range1 = moment.range(smallest, biggest);
                                let range2 = moment.range(dbSmallest, dbBiggest);
                                if (range1.overlaps(range2)) {
                                    this.setState({ overlapping: true });
                                } else {
                                    this.setState({ overlapping: false });
                                }
                            }
                        }

                        // //front end checking
                        // let notOverlapping = true;
                        // for (let x =0; x < dates.length-1 && notOverlapping === true; x++ ){
                        //     // first row

                        //     if (dates[x].dropoffOption === 'B' && dates[x].dropoffOption === 'B'){
                        //         let oldStartTime = moment(new Date(""+dates[x].pickupDate+" "+dates[x].pickupTime+""));
                        //         let oldEndTimeTo = moment(new Date(""+dates[x].dropoffDate+" "+dates[x].dropoffTo+""));

                        //         for (let y = x+1; y < dates.length && notOverlapping === true; y++){
                        //             if (dates[y].pickupOption === 'B' && dates[y].dropoffOption === 'B'){
                        //                 //second row

                        //                 let startTime =moment(new Date(""+dates[y].pickupDate+" "+dates[y].pickupTime+""));
                        //                 let oldTime = moment(new Date(""+dates[y].dropoffDate+" "+dates[y].dropoffTo+""));
                        //                 // let oldEndTimeFrom = moment(new Date(""+dates[x].dropoffDate+" "+dates[x].dropoffFrom+""));

                        //                 let range1,range2;

                        //                 range1 = moment.range(startTime,oldTime);
                        //                 range2 = moment.range(oldStartTime,oldEndTimeTo);
                        //                 // range1 = moment.range(oldStartTime,oldEndTimeFrom);
                        //                 if (range1.overlaps(range2)){
                        //                     notOverlapping = false;
                        //                 }
                        //             }
                        //         }
                        //     }
                        // }

                        // if (!notOverlapping){
                        //     this.handleLoading(false)
                        //     return this.openNotificationWithIcon('error','Values Overlapping');
                        // }
                        let ordersDispatchDestination = Object.keys(orderTransportDestinations).map((key) => {
                            if (!orderTransportDestinations[key].originId && !orderTransportDestinations[key].destinationId) {
                                return null
                            }
                            return {
                                ordersTransportDestination: orderTransportDestinations[key]
                            };
                        }).filter((o) => !!o);
                        values.ordersDispatchDestination = ordersDispatchDestination;
                        if (values.dispatchStatus === 'COMPLETED' && !values.ordersDispatchDestination.length && mode !== 'delete') {
                            this.handleLoading(false)
                            return this.openNotificationWithIcon('warning', '発地・着地情報を入力してください。');
                        }
                    } else if (values.unitPriceConfig) {
                        // eslint-disable-next-line array-callback-return
                        let err = Object.keys(values).some((key) => {
                            if (!values[key]) {
                                if (key === 'createdUserId' || key === 'updatedUserId') {
                                    return false;
                                } else if (key === 'details' && mode === 'add') {
                                    return false;
                                } else if (key === "csvFile" && (mode === 'edit' || mode === 'delete')) {
                                    return false;
                                } else {
                                    return true;
                                }
                            }
                        })
                        if (err) {
                            this.handleLoading(false)
                            return this.openNotificationWithIcon('error', 'CSVファイルを取込みしてください。');
                        }
                    } else if (values.vehicleConfig) {
                        let oilChangeDistance = !values.oilChangeDistance ? 0 : +values.oilChangeDistance;
                        values.oilChangeDistance = oilChangeDistance;

                        let greaseDistance = !values.greaseDistance ? 0 : +values.greaseDistance;
                        values.greaseDistance = parseInt(greaseDistance);
                    } else if (values.packageConfig) {
                        let weight = !values.weight ? 0 : +values.weight;
                        values.weight = weight;

                    } else if (values.transportationConfig) {
                        let latitude = !values.latitude ? 0 : +values.latitude;
                        let longitude = !values.longitude ? 0 : +values.longitude;
                        values.latitude = latitude;
                        values.longitude = longitude;

                    } else if (values.holidaysConfig) {
                        if (!values.holidaysList) {
                            this.handleLoading(false)
                            return this.openNotificationWithIcon('error', 'CSVファイルを取込みしてください。');
                        }
                    }
                    switch (mode) {
                        case 'add':
                            values.updatedUserId = values.createdUserId;
                            handler = () => this.handleSave(action, values, data);
                            title = '確認';
                            okText = 'はい';
                            cancelText = 'いいえ';
                            content = message;
                            break;
                        case 'copy':
                            values.updatedUserId = values.createdUserId;
                            handler = () => this.handleSave(action, values, data, refetch);
                            title = '確認';
                            okText = 'はい';
                            cancelText = 'いいえ';
                            content = message;
                            break;
                        case 'edit':
                            handler = () => this.handleEdit(action, values, refetch, data);
                            title = '確認';
                            okText = 'はい';
                            cancelText = 'いいえ';
                            content = message;
                            break;
                        case 'delete':
                            handler = () => this.handleDelete(action, values, refetch, data);
                            title = '確認';
                            okText = 'はい';
                            cancelText = 'いいえ';
                            content = message;
                            break;
                        default:
                            break;
                    }

                    if (mode === 'delete') {
                        let modal = Modal.confirm({
                            title,
                            icon: 'false',
                            content,
                            okText,
                            cancelText,
                            onCancel: this.handleLoading(false),
                            onOk: () => Modal.confirm({
                                title,
                                icon: 'false',
                                content: "本当に削除しますか？",
                                okText,
                                cancelText,
                                onCancel: this.handleLoading(false),
                                onOk: handler
                            }) && modal.destroy()
                        });
                    } else {

                        if (values.triggerFlag && values.triggerFlag === 'add') {
                            if (!!values.print) {
                                this.downloadExcel(values);
                            }
                            return handler()
                        }
                        if (this.state.overlapping) {
                            let modal = Modal.confirm({
                                title,
                                icon: 'false',
                                content: "運搬時間が重複していますが、問題ありませんか？",
                                okText,
                                cancelText,
                                onCancel: this.handleLoading(false),
                                onOk: () => Modal.confirm({
                                    title,
                                    icon: 'false',
                                    content,
                                    closable: true,
                                    okText,
                                    cancelText,
                                    onCancel: this.handleLoading(false),
                                    onOk: async () => { return await handler() && modal.destroy() && console.log('closing') }
                                }) && modal.destroy()
                            });
                        } else {
                            let modal = Modal.confirm({
                                title,
                                icon: 'false',
                                content,
                                closable: true,
                                okText,
                                cancelText,
                                onCancel: this.handleLoading(false),
                                onOk: async () => { return await handler() && modal.destroy() && console.log('closing') }
                            })
                        }
                    }

                } else {
                    if (values.customerConfig) {
                        const firstTabErrors = Object.keys(err).some((element) => {
                            return element.includes("nameJa") ||
                                element.includes("nameJaKatakana") ||
                                element.includes("formOutputName") ||
                                element.includes("loginId") ||
                                element.includes("password") ||
                                element.includes("postCodeFormatted") ||
                                element.includes("address") ||
                                element.includes("telNo1") ||
                                element.includes("status")
                        })

                        const secondTabErrors = Object.keys(err).some((element) => {
                            return element.includes("transportationUnitPriceId") ||
                                element.includes("escortVehicleUnitPriceId") ||
                                element.includes("escortVehicleOutsourcingUnitPriceId") ||
                                element.includes("billingDateRangeMonthFrom") ||
                                element.includes("billingDateRangeDayFrom") ||
                                element.includes("billingDateRangeMonthTo") ||
                                element.includes("billingDateRangeDayTo") ||
                                element.includes("dueDateMonth") ||
                                element.includes("dueDateDay")
                        })

                        if (firstTabErrors) {
                            this.handleLoading(false);
                            this.handleChangeTab('1')
                        } else if (secondTabErrors) {
                            this.handleLoading(false);
                            this.handleChangeTab('2')
                        } else {
                            this.handleLoading(false);
                            this.handleChangeTab('1')
                        }
                    } else {
                        let errs = Object.keys(err).some((element) => {
                            return element.includes("pickupDate_") || element.includes("dropoffDate_")
                        });

                        if (!errs) {
                            this.handleLoading(false);
                            this.handleChangeTab('1')
                        } else {
                            this.handleLoading(false);
                            this.handleChangeTab('2')
                        }

                    }
                }
            });
        }
    };

    async componentDidMount() {
        if (this.props.config.mode === 'add' && (this.props.config || {}).redirect === '/customer/search-list') {
            const { data: { allCustomers: { nodes: customers } } } = await this.props.client.query({
                query: getLastCustomer,
                fetchPolicy: 'network-only',
            });
            if (customers.length) {
                const [customer] = customers;
                const code = ((+customer.loginId.replace('C', '')) + 1);
                const newCode = `C${code.toString().padStart('4', 0)}`;
                this.props.form.setFieldsValue({
                    loginId: newCode,
                    password: newCode
                })
            }
        }
    }

    render() {
        let {
            config: {
                mode,
                modal,
                decorateResult,
                queries: {
                    GET_BY_ID,
                    UPDATE_BY_ID,
                    DELETE_BY_ID,
                    CREATE,
                },
                component,
                data: {
                    idRef
                },
                methods,
            },
            location: { state },
            form,
            match: { params }
        } = this.props;
        const stateId = state ? state.id : +params.id;
        const customerById = this.props.getCustomerById && !this.isAdmin() ? this.props.getCustomerById.customerById : null;
        if ((mode === 'edit' || mode === 'delete') && stateId) {
            // if(!stateId) {
            //     return <Fragment></Fragment>;
            // }
            return <Query
                query={GET_BY_ID}
                fetchPolicy='network-only'
                variables={{
                    id: stateId
                }}>
                {
                    ({ data, loading, refetch, error }) => {
                        if (loading) return (
                            <div style={{
                                position: 'absolute',
                                top: 0,
                                bottom: 0,
                                left: 0,
                                right: 0,
                                width: '0%',
                                height: '5%',
                                margin: 'auto'
                            }}>
                                <Spin spinning={loading} size="large" delay={500}></Spin>
                            </div>
                        );
                        if (modal && !data) {
                            data = [];
                        } else {
                            data = data[idRef];
                        }

                        if (decorateResult) {
                            data = decorateResult(data, this.state, this.setState.bind(this));
                        }

                        if (mode === 'edit') {
                            return (
                                <Mutation mutation={UPDATE_BY_ID}>
                                    {(updateById) => {
                                        return (
                                            <Fragment>
                                                {component.bind(this)({
                                                    methods,
                                                    form,
                                                    data,
                                                    handleSubmit: this.handleSubmit(updateById, refetch, data),
                                                    loading: this.state.loading,
                                                    setState: this.setState.bind(this),
                                                    state: this.state,
                                                    id: stateId,
                                                    handleChangeTab: this.handleChangeTab
                                                })}
                                            </Fragment>
                                        )
                                    }}
                                </Mutation>
                            );
                        }
                        return (
                            <Mutation mutation={DELETE_BY_ID}>
                                {(deleteById) => {
                                    return (
                                        <Fragment>
                                            {component({
                                                methods,
                                                form,
                                                data,
                                                handleSubmit: this.handleSubmit(deleteById, refetch, data),
                                                loading: this.state.loading,
                                                setState: this.setState.bind(this),
                                                state: this.state,
                                                id: stateId,
                                                handleChangeTab: this.handleChangeTab
                                            })}
                                        </Fragment>

                                    )
                                }}
                            </Mutation>
                        );
                    }
                }
            </Query>;
        }

        return (
            <Mutation
                mutation={CREATE}
            >
                {(create) => {
                    return (
                        <Fragment>
                            {component.bind(this)({
                                methods,
                                form,
                                data: {},
                                handleSubmit: this.handleSubmit(create),
                                loading: this.state.loading,
                                setState: this.setState.bind(this),
                                state: this.state,
                                customerById,
                                handleChangeTab: this.handleChangeTab,
                                client: this.props.client
                            })}
                        </Fragment>
                    )
                }}
            </Mutation>
        );
    }
}

const options = () => {
    const token = localStorage.getItem('token');
    const decodeToken = JSON.parse(atob(token.split('.')[1]));
    return {
        variables: {
            id: decodeToken.user_id
        }
    }
}

const withCustomerInfo = compose(
    graphql(getCustomerById, {
        name: 'getCustomerById',
        options
    })
);

export default withCustomerInfo(withRouter(withApollo(Form.create()(AEDBase))));