import React, { Component } from 'react';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from 'react-google-maps';
import { compose, withProps } from 'recompose';

class Map extends Component {
  state = {
    lat : 38.2811543,
    lng : 141.0078766
  }
  getCenter = (evt) => {
    this.setState({
      lat: evt.latLng.lat(),
      lng: evt.latLng.lng(),
    })
  };

  componentDidUpdate(prevProps, prevState) {

    if (this.props.handleLatLong) {
      if(prevState !== this.state) {
        let {lat, lng} = this.state
        this.props.handleLatLong(lat, lng);
      }
    }
    
    if(this.props.isCenter) {
      this._map.context.__SECRET_MAP_DO_NOT_USE_OR_YOU_WILL_BE_FIRED.setCenter(this.props.position.lat ? this.props.position : {lat:this.state.lat, lng:this.state.lng})
    }

  }

  render() {
    let {lat,lng} = this.state
    return (
      <GoogleMap
        ref={(map) => this._map = map}
        defaultZoom={8}
        defaultCenter={!!this.props.position.lat ? this.props.position : {lat, lng}}
        // center={!!this.props.center ? (!!this.props.center.lat ? this.props.center : {lat, lng}) : {lat, lng}}
      > 
        {this.props.isMarkerShown && <Marker 
        draggable={true} 
        onDrag={this.getCenter}
        position={!!this.props.position.lat ? this.props.position : {lat, lng}} />}
        {
          this.props.placemarkers &&
          this.props.placemarkers.map((marker, key) => (
            <Marker
              key={key}
              icon={this.props.icon}
              label={marker.label}
              position={{lat: marker.position.lat, lng: marker.position.lng}} />
          ))
        }
      </GoogleMap>
    )
  }
}

export default compose(
  withProps({
    googleMapURL:"https://maps.googleapis.com/maps/api/js?key=AIzaSyD_hGMgMfcBCwDxF4EIL1Yw14K0rzXIufs&v=3.35?&libraries=geometry,drawing,places",
    loadingElement:<div style={{ height: `100%` }} />,
    containerElement:<div className='mapHeight' style={{ height: `762px` }} />,
    mapElement:<div style={{ height: `100%` }} />,
    // center:
  }),
  withScriptjs,
  withGoogleMap
)(Map)

