import {gql} from 'apollo-boost';

const getAll = gql`
    query allCustomers($first: Int, $offset: Int, $filter: CustomerFilter) {
        allCustomers (first:$first,offset:$offset,filter:$filter, orderBy: CONVERTED_NAME_JA_KATAKANA_ASC){
            nodes {
              id,
              loginId,
              password,
              nameJa,
              nameJaKatakana,
              formOutputName,
              postCode,
              address,
              telNo1,
              telNo2,
              faxNo1,
              faxNo2,
              emailAddress,
              url,
              transportationUnitPriceId,
              escortVehicleUnitPriceId,
              escortVehicleOutsourcingUnitPriceId,
              billingDateRangeMonthFrom,
              billingDateRangeDayFrom,
              billingDateRangeMonthTo,
              billingDateRangeDayTo,
              dueDateMonth,
              dueDateDay,
              status,
              # ordersDispatchesByCustomerId {
              #     id,
              #     nameJa,
              #     nameJaKatakana
              # },
              # transportDestinationsByCustomerId {
              #   id,
              #   customerId,
              #   areaName,
              #   siteName,
              #   borrower,
              #   assignee
              # }
            },
            totalCount
        }
	}`;

const create = gql`
  mutation createCustomer (
    $id: Int,
    $loginId: String!,
    $password: String!,
    $nameJa: String!,
    $nameJaKatakana: String!,
    $formOutputName: String!,
    $postCode: String,
    $address: String,
    $telNo1: String,
    $telNo2: String,
    $faxNo1: String,
    $faxNo2: String,
    $emailAddress: String,
    $url: String,
    $transportationUnitPriceId: Int!,
    $escortVehicleUnitPriceId: Int!,
    $escortVehicleOutsourcingUnitPriceId: Int!,
    $billingDateRangeMonthFrom: Int,
    $billingDateRangeDayFrom: Int,
    $billingDateRangeMonthTo: Int,
    $billingDateRangeDayTo: Int,
    $dueDateMonth: Int,
    $dueDateDay: Int,
    $status: StatusOption,
    $updatedUserId : Int!,
    $createdUserId : Int!,
  ){
    createCustomer (input:{
		customer : {
			id: $id,
			loginId: $loginId,
			password: $password,
			nameJa: $nameJa,
			nameJaKatakana: $nameJaKatakana,
			formOutputName: $formOutputName,
			postCode: $postCode,
			address: $address,
			telNo1: $telNo1,
			telNo2: $telNo2,
			faxNo1: $faxNo1,
			faxNo2: $faxNo2,
			emailAddress: $emailAddress,
			url: $url,
			transportationUnitPriceId: $transportationUnitPriceId,
      escortVehicleUnitPriceId: $escortVehicleUnitPriceId,
      escortVehicleOutsourcingUnitPriceId: $escortVehicleOutsourcingUnitPriceId,
			billingDateRangeMonthFrom: $billingDateRangeMonthFrom,
			billingDateRangeDayFrom: $billingDateRangeDayFrom,
			billingDateRangeMonthTo: $billingDateRangeMonthTo,
			billingDateRangeDayTo: $billingDateRangeDayTo,
			dueDateMonth: $dueDateMonth,
			dueDateDay: $dueDateDay,
			status: $status,
      createdUserId : $createdUserId,
      updatedUserId : $updatedUserId
		}
		}){ customer { nameJa } }
  	}
`;

const getById = gql`
query getCustomer ($id:Int!){
  	customerById (id : $id) {
		id,
		loginId,
		nameJa,
		nameJaKatakana,
		formOutputName,
		postCode,
		address,
		telNo1,
		telNo2,
		faxNo1,
		faxNo2,
		emailAddress,
		url,
		transportationUnitPriceId,
    escortVehicleUnitPriceId,
    escortVehicleOutsourcingUnitPriceId,
		billingDateRangeMonthFrom,
		billingDateRangeDayFrom,
		billingDateRangeMonthTo,
		billingDateRangeDayTo,
		dueDateMonth,
		dueDateDay,
		status,
		    unitPriceByEscortVehicleUnitPriceId {
          unitPriceName
        },
        unitPriceByEscortVehicleOutsourcingUnitPriceId {
          unitPriceName
        },
        unitPriceByTransportationUnitPriceId {
          unitPriceName
        }
  
  }
}`;

const getCustomerById = gql`
query getCustomer ($id:Int!){
  	customerById (id : $id) {
		id,
		nameJa,
		nameJaKatakana,
  }
}`;

const updateById = gql`
mutation updateCustomer (
    $id: Int!,
    $loginId: String!,
    $nameJa: String!,
    $nameJaKatakana: String!,
    $formOutputName: String!,
    $password: String,
    $postCode: String,
    $address: String,
    $telNo1: String,
    $telNo2: String,
    $faxNo1: String,
    $faxNo2: String,
    $emailAddress: String,
    $url: String,
    $transportationUnitPriceId: Int!,
    $escortVehicleUnitPriceId: Int!,
    $escortVehicleOutsourcingUnitPriceId: Int!,
    $billingDateRangeMonthFrom: Int,
    $billingDateRangeDayFrom: Int,
    $billingDateRangeMonthTo: Int,
    $billingDateRangeDayTo: Int,
    $dueDateMonth: Int,
    $dueDateDay: Int,
    $status: StatusOption,
    $updatedUserId : Int,
    $createdUserId : Int,
  ){
	updateCustomerById (input:{
		id : $id,
			customerPatch : {
				loginId: $loginId,
				password: $password,
				nameJa: $nameJa,
				nameJaKatakana: $nameJaKatakana,
				formOutputName: $formOutputName,
				postCode: $postCode,
				address: $address,
				telNo1: $telNo1,
				telNo2: $telNo2,
				faxNo1: $faxNo1,
				faxNo2: $faxNo2,
				emailAddress: $emailAddress,
				url: $url,
				transportationUnitPriceId: $transportationUnitPriceId,
        escortVehicleUnitPriceId: $escortVehicleUnitPriceId,
        escortVehicleOutsourcingUnitPriceId:$escortVehicleOutsourcingUnitPriceId,,
				billingDateRangeMonthFrom: $billingDateRangeMonthFrom,
				billingDateRangeDayFrom: $billingDateRangeDayFrom,
				billingDateRangeMonthTo: $billingDateRangeMonthTo,
				billingDateRangeDayTo: $billingDateRangeDayTo,
				dueDateMonth: $dueDateMonth,
				dueDateDay: $dueDateDay,
				status: $status,
        createdUserId : $createdUserId,
        updatedUserId : $updatedUserId
			}
		}
	){
		customer {
			loginId
		}
    }
  }
`;

const deleteById = gql`
mutation deleteCustomerById($id:Int!){
	deleteCustomerById (input:{id:$id}){
		deletedCustomerId
	}
}
`;

export {getAll, create, getById, getCustomerById, updateById, deleteById};