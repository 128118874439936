import { Col, Form, Row, Tabs } from 'antd';
import moment from 'moment';
import React from 'react';
import KeyboardEventHandler from 'react-keyboard-event-handler';
/** Get All Common  **/
import {
    CustomButton,
    CustomCheckboxGroup, CustomConfirmPass, CustomDatepicker, CustomHiddenInput, CustomInput,
    CustomRadioGroup, CustomSelect,
    CustomTextarea
} from '../../../commons/CustomDataEntry';
import CustomFile from "../../../commons/CustomDataEntry/CustomFile";
import VehicleList from '../../../commons/Picklist/VehicleList';
/** Get All User Queries **/
import {
    create,
    deleteById, getAll, getById,
    updateById
} from '../../../queries/MasterMaintenance/users';
import { changePassword } from '../../../queries/Users';
/** Get ADhoc translate **/
import adhocTranslator from '../../../utils/locales/translate-adhoc';
import { userRoles } from '../../../utils/types';
/** Get user_id stored in LocalStorage **/
import decodeToken from '../../../utils/user_id';

const TabPane = Tabs.TabPane;

export default ({ mode }) => {

    /** Initialize translators **/
    let lt = adhocTranslator('K900-021');
    let ltV = adhocTranslator('K900-022');

    return {
        /** Assign mode **/
        mode,

        /** Redirect config **/
        redirect: '/user/search-list',

        /** query mapping **/
        data: {
            idRef: 'userById'
        },
        /** Map queries **/
        queries: {
            GET_BY_ID: getById,
            GET_ALL: getAll,
            UPDATE_BY_ID: updateById,
            CREATE: create,
            DELETE_BY_ID: deleteById
        },
        isUserConfig: true,
        postEdit: async function (variables) {
            if (variables.password && variables.confirm) {
                await variables;
                setTimeout(() => {
                    this.props.client.mutate({
                        mutation: changePassword,
                        variables: {
                            id: variables.id,
                            password: variables.password
                        }
                    });
                }, 500)
            }
        },
        decorate: (variables) => {
            variables.affiliationStartDate = variables.affiliationStartDate ? variables.affiliationStartDate.format("YYYY-MM-DD") : null;
            variables.dateOfBirth = variables.dateOfBirth ? variables.dateOfBirth.format("YYYY-MM-DD") : null;
            variables.driversLicenseValiduntil = variables.driversLicenseValiduntil ? variables.driversLicenseValiduntil.format("YYYY-MM-DD") : null;
            variables.password = variables.password === "" ? undefined : variables.password
            variables.displayOrder = variables.displayOrder ? +variables.displayOrder : null
            return variables;
        },

        /** messages */
        messages: {
            add: "社員情報を登録しますか？",
            edit: "社員情報を更新しますか？",
            delete: "社員情報を削除しますか？",
            reg_success_message: lt("reg_success_message"),
            reg_error_message: lt("reg_error_message"),
            edit_success_message: lt("edit_success_message"),
            edit_failure_message: lt("edit_failure_message"),
            delete_success_message: lt("delete_success_message"),
            delete_failure_message: lt("delete_failure_message")
        },

        /** Create common component **/
        component: ({ form, data, handleSubmit, loading, state, setState, handleChangeTab }) => {
            if (data.rolesByUserId) data.roles = data.rolesByUserId.nodes.map(roles => roles.role);

            const selectBT = [
                { id: 'A', value: 'A' },
                { id: 'B', value: 'B' },
                { id: 'O', value: 'O' },
                { id: 'AB', value: 'AB' },
                { id: 'N_A', value: '不明' },
            ];

            const radioAuth = [
                { id: 1, checked: 'false', value: userRoles.SYSTEM_ADMINISTRATOR, label: lt('rdo_user_authority_system_manager') },
                { id: 2, checked: 'false', value: userRoles.REP_OR_ACCOUNTS_EXECUTIVE, label: lt('rdo_user_authority_representative_accountant') },
                { id: 3, checked: 'false', value: userRoles.CLERICAL_OR_ACCOUNTING, label: lt('rdo_user_authority_office_worker') },
                { id: 4, checked: 'false', value: userRoles.COMPANY_DRIVER, label: lt('rdo_user_authority_driver_owned') },
                { id: 5, checked: 'false', value: userRoles.OUTSOURCING_DRIVER, label: lt('rdo_user_authority_driver_chartered') },
                { id: 6, checked: 'false', value: userRoles.ESCORT_COMPANY_DRIVER, label: lt('rdo_user_authority_driver_charttered_vehicle') },
                { id: 7, checked: 'false', value: userRoles.ESCORT_OUTSOURCING_DRIVER, label: lt('rdo_user_authority_driver_charttered_vehicle_induction') },
            ];

            const radioStatus = [
                { id: 'ACTIVE', value: lt('rdo_user_status_effective') },
                { id: 'INACTIVE', value: lt('rdo_user_status_invalid') },
            ];

            const tailFormItemLayout = {
                labelCol: {
                    xs: { span: 4 }
                },
                wrapperCol: { //for input width
                    md: { offset: 4 },
                },
            };

            let formatPostCode = postCode => {
                if (postCode) {
                    postCode = postCode.replace(/-/g, "");
                    if (postCode.length >= 4) {
                        postCode = postCode.substr(0, 3) + "-" + postCode.substr(3, 4);
                    }
                }
                return postCode;
            };

            let btnLabel;
            if (mode === 'edit') {
                btnLabel = lt('btn_edit')
            } else if (mode === 'delete') {
                btnLabel = lt('btn_delete')
            } else {
                btnLabel = lt('btn_register')
            }

            // if(mode === 'add') {
            //     form.resetFields()
            // }

            let onFocusEvent = (e) => {
                document.getElementById(e.target.id).removeAttribute('readonly');
            }

            return (
                <Row type='flex' className='h100' justify='space-around' align='top'>
                    <KeyboardEventHandler
                        handleKeys={['enter']}
                        onKeyEvent={(key, e) => handleSubmit(e)}
                    />
                    <Col span={24}>
                        <Tabs activeKey={state.activeKey} onChange={(activeKey) => handleChangeTab(activeKey)} defaultActiveKey='1'>
                            <TabPane tab={lt('tｂ_basic_info')} key='1'>
                                <Form onSubmit={handleSubmit} layout='vertical'  >
                                    <CustomInput
                                        autoFocus={state.activeKey === '1'}
                                        form={form}
                                        fieldName='loginId'
                                        label={lt('lbl_user_id')}
                                        type='text'
                                        placeholder={lt('txt_user_id')}
                                        oneThird={true}
                                        mode={mode}
                                        labelWidth={7}
                                        width={12}
                                        offset={1}
                                        onFocus={onFocusEvent}
                                        readOnly={true}
                                        className={'custom-empty'}
                                        maxLength={10}
                                        decorator={{
                                            rules: [{
                                                required: true,
                                                message: lt('ユーザーIDを入力してください'),
                                            }],
                                            initialValue: mode === 'add' ? null : data.loginId
                                        }} />
                                    <br />
                                    <CustomConfirmPass
                                        form={form}
                                        mode={mode}
                                        fieldName='password'
                                        placeholderPassword={mode === 'add' ? lt('psw_password') : '********'}
                                        placeholderConfirmPassword={mode === 'add' ? lt('psw_password') : '********'}
                                        labelPassword={lt('lbl_password')}
                                        labelConfirmPassword={lt('lbl_confirm_password')}
                                        oneThird={true}
                                        labelWidth={7}
                                        width={12}
                                        maxLength={16}
                                        offset={1}
                                        rule1={{ required: mode === 'add' ? true : false, message: 'パスワードを入力してください。' }}
                                        rule2={{ required: mode === 'add' || (mode === 'edit' && (form.getFieldValue('password') !== "" && form.getFieldValue('password') !== undefined)) ? true : false, message: 'パスワード(確認)を入力してください' }}
                                    />
                                    <br />
                                    <CustomInput
                                        form={form}
                                        fieldName='fullNameJa'
                                        label={lt('lbl_name_kanji')}
                                        type='text'
                                        placeholder={lt('txt_name_kanji')}
                                        mode={mode}
                                        oneThird={true}
                                        labelWidth={7}
                                        offset={1}
                                        width={12}
                                        maxLength={100}
                                        decorator={{
                                            rules: [{
                                                required: true,
                                                message: lt('氏名（漢字）を入力してください。'),
                                            }],
                                            initialValue: data.fullNameJa
                                        }} />
                                    <CustomInput
                                        form={form} fieldName='fullNameJaKatakana'
                                        label={lt('lbl_name_kana')}
                                        type='text'
                                        placeholder={lt('txt_name_kana')}
                                        mode={mode}
                                        oneThird={true}
                                        labelWidth={7}
                                        offset={1}
                                        width={12}
                                        maxLength={100}
                                        decorator={{
                                            rules: [{
                                                required: true,
                                                message: lt('氏名（カナ）を入力してください。'),
                                            }],
                                            initialValue: data.fullNameJaKatakana
                                        }} />
                                    <CustomInput
                                        form={form} fieldName='shortNameJa'
                                        label={lt('lbl_name_abbreviation')}
                                        type='text'
                                        placeholder={lt('txt_name_abbreviation')}
                                        mode={mode}
                                        oneThird={true}
                                        labelWidth={7}
                                        offset={1}
                                        width={12}
                                        maxLength={100}
                                        decorator={{
                                            rules: [{
                                                required: true,
                                                message: lt('名前（略称）を入力してください。'),
                                            }],
                                            initialValue: data.shortNameJa
                                        }} />
                                    <br />
                                    <CustomDatepicker
                                        form={form} label={lt('lbl_department_affiliation_start')}
                                        fieldName='affiliationStartDate'
                                        mode={mode}
                                        placeholder={lt('txt_department_affiliation_start')}
                                        oneThird={true}
                                        labelWidth={7}
                                        offset={1}
                                        width={12}
                                        decorator={{
                                            rules: [{
                                                required: true,
                                                message: lt('所属開始日を入力してください。'),
                                            }],
                                            initialValue: data.affiliationStartDate && moment(data.affiliationStartDate)
                                        }} />
                                    {/*
                                    <CustomDatepicker
                                        form={form} label={lt('lbl_department_affiliation_end')}
                                        fieldName='affiliationEndDate'
                                        mode={mode}
                                        placeholder={lt('txt_department_affiliation_end')}
                                        mid={true}
                                        offset={3}
                                        className={'dp-alignment'}
                                        width={12}
                                        decorator={{
                                            rules: [{
                                                required: true,
                                                message: lt('所属終了日を入力してください。'),
                                            }],
                                            initialValue: data.affiliationEndDate && moment(data.affiliationEndDate)
                                        }}/>
                                    <CustomButton
                                        mode={mode}
                                        label={lt('btn_maximum_day')}
                                        onClick={() => {
                                            form.setFieldsValue({
                                                affiliationEndDate: moment('2099/12/31')
                                            })
                                        }}
                                        style={{marginTop: '0px'}}/>
                                    */}
                                    <br />
                                    <CustomInput
                                        form={form} fieldName='postCodeFormatted'
                                        label={lt('lbl_postal_code')}
                                        type='text'
                                        placeholder={lt('txt_postal_code')}
                                        mode={mode}
                                        oneThird={true}
                                        maxLength={7}
                                        labelWidth={7}
                                        offset={1}
                                        width={12}
                                        onFocus={(event) => {
                                            let { value } = event.target;
                                            form.setFieldsValue({ postCodeFormatted: value.replace(/-/g, '') });
                                        }}
                                        onBlur={(event) => {
                                            let { value } = event.target;
                                            let dataValue = null;
                                            if (!value || value !== '') {
                                                value = value.replace(/-/g, '');
                                                if (value.length >= 4) {
                                                    dataValue = value.substr(0, 3) + "-" + value.substr(3, 4);
                                                }
                                            };
                                            form.setFieldsValue({ postCodeFormatted: dataValue });
                                        }}
                                        onChange={
                                            e => {
                                                form.setFieldsValue({
                                                    postCode: `${e.target.value}`.replace(/-/g, "")
                                                });
                                            }
                                        }
                                        decorator={{
                                            rules: [{
                                                required: true,
                                                min: 8,
                                                message: lt('郵便番号を入力してください。'),
                                            }],
                                            // normalize: value => formatPostCode(value),
                                            initialValue: formatPostCode(data.postCode)
                                        }} />
                                    <br />
                                    <CustomHiddenInput
                                        fieldName='postCode'
                                        form={form}
                                        decorator={{
                                            initialValue: data.postCode
                                        }}
                                    />
                                    <CustomHiddenInput
                                        fieldName={mode === 'add' ? 'createdUserId' : 'updatedUserId'}
                                        form={form}
                                        decorator={{
                                            initialValue: decodeToken().user_id
                                        }}
                                    />
                                    <CustomInput
                                        form={form} fieldName='address'
                                        label={lt('lbl_address')}
                                        type='text'
                                        placeholder={lt('txt_address')}
                                        mode={mode}
                                        twoThird={true}
                                        labelWidth={4}
                                        offset={0}
                                        width={17}
                                        maxLength={200}
                                        decorator={{
                                            rules: [{
                                                required: true,
                                                message: lt('住所を入力してください。'),
                                            }],
                                            initialValue: data.address
                                        }} />
                                    <br />
                                    <CustomInput
                                        form={form} fieldName='mobileNo'
                                        label={lt('lbl_mobile_phone_no')}
                                        type='text'
                                        placeholder={lt('txt_mobile_phone_no')}
                                        mode={mode}
                                        oneThird={true}
                                        labelWidth={7}
                                        offset={1}
                                        width={12}
                                        maxLength={15}
                                        decorator={{
                                            initialValue: data.mobileNo
                                        }} />
                                    <br />
                                    <CustomDatepicker
                                        form={form}
                                        label={lt('lbl_date_of_birth')}
                                        fieldName='dateOfBirth'
                                        mode={mode}
                                        placeholder={lt('txt_date_of_birth')}
                                        oneThird={true}
                                        labelWidth={7}
                                        offset={1}
                                        width={12}
                                        decorator={{
                                            rules: [{
                                                required: true,
                                                message: lt('年月日を入力してください。'),
                                            }],
                                            initialValue: data.dateOfBirth && moment(data.dateOfBirth)
                                        }} />
                                    <br />
                                    <CustomSelect
                                        options={selectBT}
                                        form={form}
                                        fieldName='bloodType'
                                        label={lt('btn_blood_type')}
                                        mode={mode}
                                        oneThird={true}
                                        labelWidth={7}
                                        offset={1}
                                        width={12}
                                        decorator={{
                                            initialValue: data.bloodType
                                        }} />
                                    <br />
                                    <div className="userConfigAuthority">
                                        <CustomCheckboxGroup
                                            options={radioAuth}
                                            form={form}
                                            defaultValue={3}
                                            mode={mode}
                                            label={lt('lbl_authority')}
                                            fieldName='authority'
                                            twoThird={true}
                                            labelWidth={0}
                                            offset={0}
                                            width={17}
                                            decorator={{
                                                rules: [{
                                                    required: true,
                                                    message: lt('権限を選択してください。'),
                                                }],
                                                // valuePropName: 'checked',
                                                initialValue: data.roles
                                            }}
                                        />
                                    </div>
                                    <br />
                                    <VehicleList
                                        form={form}
                                        label={lt('lbl_vehicle_no')}
                                        hasDefault={true}
                                        fieldName='vehicleNumber'
                                        mode={mode}
                                        oneThird={true}
                                        labelWidth={7}
                                        offset={1}
                                        width={12}
                                        decorator={{
                                            rules: [{
                                                required: form.getFieldValue('authority') === 4 && true,
                                                message: lt('号車を選択してください。'),
                                            }],
                                            initialValue: data.vehicleNumber
                                        }} />
                                    <br />
                                    <CustomInput
                                        form={form}
                                        fieldName='displayOrder'
                                        label={lt('lbl_display_order')}
                                        type='number'
                                        // placeholder={lt('txt_mobile_phone_no')}
                                        mode={mode}
                                        oneThird={true}
                                        labelWidth={7}
                                        offset={1}
                                        width={12}
                                        maxLength={15}
                                        decorator={{
                                            initialValue: data.displayOrder
                                        }} />
                                    <br />
                                    <CustomRadioGroup
                                        options={radioStatus}
                                        form={form}
                                        defaultValue={1}
                                        mode={mode}
                                        label={lt('lbl_status')}
                                        fieldName='status'
                                        oneThird={true}
                                        labelWidth={7}
                                        offset={1}
                                        width={12}
                                        decorator={{
                                            rules: [{
                                                required: true,
                                                message: lt('lbl_status'),
                                            }],
                                            initialValue: data.status
                                        }}
                                    />
                                    <br />
                                    <CustomButton label={btnLabel}
                                        loading={loading}
                                        style={{ marginTop: '15px', height: '40px', width: '104px' }}
                                        htmlType="submit"
                                        offset={12} />
                                </Form>
                            </TabPane>
                            <TabPane tab={ltV('tb_drivers_license_and_other_certifications_tab')} key='2' forceRender={true}>
                                <Form onSubmit={handleSubmit} layout='horizontal'>
                                    <CustomInput
                                        autoFocus={state.activeKey === '2'}
                                        form={form} fieldName='driversLicenseNo'
                                        label={ltV('lbl_drivers_license_no')}
                                        type='text'
                                        placeholder={ltV('txt_drivers_license_no')}
                                        mode={mode}
                                        labelWidth={'16.66666667%'}
                                        preLabel={'第'}
                                        postLabel={'号'}
                                        rowField={false}
                                        style={{ width: '19.16666667%', marginBottom: '15px', marginRight: 10 }}
                                        width={7}
                                        maxLength={12}
                                        decorator={{
                                            initialValue: data.driversLicenseNo
                                        }}
                                    />
                                    <div className='clearme test' />
                                    <CustomDatepicker
                                        form={form} fieldName='driversLicenseValiduntil'
                                        label={ltV('lbl_expiration_date')}
                                        type='text'
                                        placeholder={ltV('YYYY/MM/DD')}
                                        mode={mode}
                                        width={12}
                                        labelWidth={4}
                                        // rowField={false}
                                        style={{ width: '19.16666667%', marginBottom: '15px', marginRight: 10 }}
                                        decorator={{
                                            initialValue: data.driversLicenseValiduntil && moment(data.driversLicenseValiduntil)
                                        }}
                                        postLabel='まで有効'
                                    />
                                    <CustomFile
                                        form={form} fieldName='driversLicensePdf'
                                        label={ltV('lbl_copy_of_drivers_license')}
                                        type='text'
                                        placeholder={ltV('txt_copy_of_drivers_license')}
                                        label1={ltV('btn_select_file')}
                                        label2={ltV('btn_delete_file')}
                                        label3={ltV('btn_output_file')}
                                        mode={mode}
                                        width={7}
                                        offset={12}
                                        decorator={{
                                            initialValue: data.driversLicensePdf
                                        }}
                                        tailFormItemLayout={tailFormItemLayout}
                                    />
                                    {/* <CustomHiddenInput 
                                    fieldName='userConfig' 
                                    form={form}
                                    decorator={{
                                        initialValue: true
                                    }}/> */}
                                    <CustomTextarea
                                        form={form} fieldName='otherCertifications'
                                        label={ltV('lbl_other_certifications')}
                                        type='text'
                                        mode={mode}
                                        decorator={{
                                            rules: [{
                                                message: ltV('lbl_other_certifications'),
                                            }],
                                            initialValue: data.otherCertifications
                                        }}
                                        width={7} />
                                    <CustomButton label={btnLabel}
                                        loading={loading}
                                        style={{ marginTop: '15px', height: '40px', width: '104px' }}
                                        htmlType="submit"
                                        offset={12} />
                                </Form>
                            </TabPane>
                        </Tabs>
                    </Col>
                </Row>
            );
        }

    }
}