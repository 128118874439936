import { Col, Form, Icon, notification, Row, Upload } from 'antd';
import _ from 'lodash';
import Papa from 'papaparse';
import React from 'react';
/** Get All Common  **/
import {
    CustomButton,
    CustomHiddenInput
} from '../../../commons/CustomDataEntry';
/** Get All Package Queries **/
import {
    create,
    getAll
} from '../../../queries/MasterMaintenance/holidays';
import {
    deleteById, getById,
    updateById
} from '../../../queries/MasterMaintenance/unitprice';
/** Get ADhoc translate **/
import adhocTranslator from '../../../utils/locales/translate-adhoc';
/** Get user_id stored in LocalStorage **/
import decodeToken from '../../../utils/user_id';

const Dragger = Upload.Dragger;

export default ({ mode }) => {
    /** Initialize translators **/
    let lt = adhocTranslator('K908-020');
    notification.config({
        duration: 3
    })

    return {
        /** Assign mode **/
        mode,

        /** Redirect config **/
        redirect: '/holidays/register',

        /** query mapping **/
        data: {
            idRef: 'unitPriceById'
        },
        /** Map queries **/
        queries: {
            GET_BY_ID: getById,
            GET_ALL: getAll,
            CREATE: create,
            UPDATE_BY_ID: updateById,
            DELETE_BY_ID: deleteById
        },

        /** messages */
        messages: {
            add: "休祝日情報を登録しますか？",
            edit: "休祝日情報を更新しますか？",
            delete: "休祝日情報を削除しますか？",
            reg_success_message: "休祝日情報を登録しました。",
            reg_error_message: "休祝日情報の登録に失敗しました。",
            edit_success_message: "休祝日情報を更新しました。",
            edit_failure_message: "休祝日情報の更新に失敗しました。",
            delete_success_message: "休祝日情報を削除しました。",
            delete_failure_message: "休祝日情報の削除に失敗しました。"
        },

        methods: {
            downloadCSV: (data) => {
                var promise1 = Promise.resolve(data);

                promise1.then(function (value) {
                    if (!!value) {
                        let parseThisPapa = value.map(item => {
                            return {
                                holiday_date: item.holidayDate,
                                holiday_name: item.holidayName,
                            }
                        })
                        const csv = Papa.unparse(parseThisPapa);
                        const csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
                        if (window.navigator.msSaveOrOpenBlob) {
                            window.navigator.msSaveBlob(csvData, "Holidays.csv");
                        } else {
                            let a = window.document.createElement("a");
                            a.href = window.URL.createObjectURL(csvData, { type: "text/plain" });
                            a.download = "Holidays.csv";
                            a.click()
                        }
                    }
                });
            }
        },


        /** Create common component **/
        component: ({ form, data, handleSubmit, loading, methods, client }) => {
            const openNotificationWithIcon = (type, title) => {
                notification[type]({
                    message: title,
                    description: '',
                });
            };
            const propsCSV = {
                name: "file",
                openFileDialogOnClick: true,
                showUploadList: true,
                multiple: false,
                accept: '.csv',
                beforeUpload: (file) => {
                    if (!file.size) {
                        openNotificationWithIcon('error', '空のCSVファイルは取込むことはできません。');
                        return false;
                    }
                    return true;
                },
                onRemove: (file) => {
                    if (file.status === 'removed') {
                        file = null;
                        form.setFieldsValue({
                            holidaysList: null
                        });
                    }
                },
                onChange: (e) => {
                    // to delete undefined list doesnt work without setTimeOut
                    setTimeout(function () {
                        let element = document.getElementsByClassName('ant-upload-list-item ant-upload-list-item-undefined');
                        if (element.length > 0) {
                            // element[0].remove()
                        }
                    }, 1);
                    if (e.file.status === 'done' || e.file.status === 'error') {
                        Papa.parse(e.file.originFileObj, {
                            complete: function (results) {
                                let holidayDetails = [];
                                results.data.shift();
                                _.map(results.data, (data) => {
                                    holidayDetails.push({
                                        holiday: {
                                            createdUserId: decodeToken().user_id,
                                            updatedUserId: decodeToken().user_id,
                                            holidayDate: data[0],
                                            holidayName: data[1]
                                        }
                                    });
                                })

                                form.setFieldsValue({
                                    holidaysList: holidayDetails
                                });

                            }
                        });
                    }
                },
            };

            let btnLabel;
            if (mode === 'edit') {
                btnLabel = lt('btn_edit')
            } else if (mode === 'delete') {
                btnLabel = lt('btn_delete')
            } else {
                btnLabel = lt('btn_register')
            }

            const getHoliday = async () => {
                const { data } = await client.query({
                    fetchPolicy: 'network-only',
                    query: getAll
                });
                return data.allHolidays.nodes
            }

            return (
                <Row type='flex' className='h100' justify='space-around'>
                    <Col span={12}>
                        <Form onSubmit={handleSubmit} layout='vertical' >
                            <CustomHiddenInput
                                fieldName={mode === 'add' ? 'createdUserId' : 'updatedUserId'}
                                form={form}
                                decorator={{
                                    initialValue: decodeToken().user_id
                                }}
                            />
                            <CustomHiddenInput fieldName='holidaysList' form={form} />
                            <div className="ant-row ant-form-item-label custom-upload">
                                <label style={{ display: 'block', float: 'left' }}>
                                    <span style={{ color: '#ea4d4d', 'fontMozTabSize': '100px' }}>* </span>ファイル名
                                </label>
                                <Dragger
                                    disabled={mode === 'delete' ? true : false}
                                    action="//jsonplaceholder.typicode.com/posts/"
                                    fieldName='fileCSV' {...propsCSV}>
                                    <p className="ant-upload-drag-icon">
                                        <Icon type="inbox" />
                                    </p>
                                    <p>{lt('btn_import_csv')}</p>
                                </Dragger>

                                <CustomButton label={lt('btn_download_csv')}
                                    mode={mode}
                                    loading={loading}
                                    onClick={() => {
                                        methods.downloadCSV(getHoliday());
                                    }}
                                    style={{ float: 'right' }}
                                    offset={20} />
                            </div>
                            <CustomHiddenInput fieldName='holidaysConfig' form={form} decorator={{ initialValue: true }} />
                            <CustomButton label={btnLabel}
                                event={handleSubmit}
                                loading={loading}
                                style={{ marginTop: '15px', height: '40px', width: '104px' }}
                                htmlType="submit"
                                offset={5} />
                        </Form>

                    </Col>
                    <Col span={12} />
                </Row>
            );
        }

    }
}
