import React from 'react';
import { Col, Row } from 'antd';

import LoginForm from '../../commons/LoginForm';
import styles from '../../assets/scss/login.module.scss';
import withTranslation from '../../utils/locales/translator';

export default withTranslation(({lt}) => (
	<Row type='flex' className='h100' justify='space-around' align='middle'>
		<Col span={5} className={styles.login}>
			<LoginForm 
				txtCompanyName={lt('lbl_company_name')}
				txtSystemName={lt('lbl_system_name')}
				txtLoginId={lt('txt_login_id')}
				errLoginId={lt('ログインIDを入力してください。')}
				errPassword={lt('パスワードを入力してください。')}
				txtPassword={lt('psw_password')}
				lblSaveLogin={lt('lbl_save_login')}
				txtBtnLogin={lt('btn_login')}
				isCustomer={true}
			/>
		</Col>
	</Row>
), 'K000-020')