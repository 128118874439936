export const initialState = {filters: {}, data: [], graph: [], flatten: [], mode: '', spinner: false};
export const reducer = (state, action) => {
    switch(action.type) {
        case 'filters' :
            return {
            	...state,
            	filters: action.filters.values
            };
        case 'add' : 
        	return {
        		...state,
        		data: [...state.data, ...action.selectedData]
        	};
        case 'graph': 
        	return {
        		...state,
            	graph: action.graphs
            };
        case 'flatten': 
            return {
                ...state,
                flatten: action.flatten
            };
        case 'reset':
            return initialState;
        case 'mode':
            return {
                ...state,
                mode: action.mode
            }
        case 'spinner':
            return {
                ...state,
                spinner: action.spinner
            }
        default:
            return state;
    }
};