import { gql } from 'apollo-boost';

const getAllTaxes = gql`
query allTaxes($first:Int,$last:Int,$filter:TaxFilter) {
  allTaxes(first:$first, last:$last,filter:$filter) {
    nodes {
      id,
      effectiveDate,
      taxRate
    }
  }
}`;


export {getAllTaxes};