import React, { Component, Fragment } from 'react';
import { withApollo } from 'react-apollo';
import TableToExcel from '@linways/table-to-excel';

const invoiceCustomerType = `
	excelCustomersOutputData {
		date
		rows {
			id
			name
		}
	}
`;

class InvoiceCustomerExcel extends Component {
	constructor(props) {
		super(props);
		this.excel = {}
	};

	render() {
		const createExcel = (record) => {
			let idvalue = "Output1";
			return (<Fragment>
				<table id={idvalue} data-cols-width="5,40">
					<thead>
						<tr data-height="23">
							<td data-a-v="middle" data-a-h="left">No.</td>
							<td data-a-v="middle" data-a-h="left">顧客名</td>
						</tr>
					</thead>
					<tbody>
						{record.map(row => (
							<tr data-height="23" key={row.id}>
								<td data-a-v="middle" data-a-h="left">{row.id}</td>
								<td data-a-v="middle" data-a-h="left">{row.name}</td>
							</tr>
						))}
					</tbody>
				</table>
			</Fragment>);
		};

		let { data } = this.props;
		const excels = data.map((row, i) => {
			return createExcel(row.rows, row)
		});

		setTimeout(() => {
			for (var i = data.length - 1; i >= 0; i--) {
				TableToExcel.convert(document.getElementById("Output1"), {
					name: "請求顧客一覧_" + data[0].date + ".xlsx",
					sheet: {
						name: "Sheet 1"
					},
					pageSetup: {
						orientation: 'portrait',
						paperSize: 9,
						fitToWidth: 1,
						fitToHeight: 0,
						margins: {
							left: 0.28, right: 0.28,
							top: 0.47, bottom: 0.47,
							header: 0.31, footer: 0.31
						},
					},
					properties: {
						defaultRowHeight: 25
					},
					views: [{
					}]
				});
			}

			this.props.client.writeData({
				data: {
					excelOutput: {
						__typename: 'excelOutput',
						excelCustomersOutputData: []
					}
				}
			});
		}, 0);

		return (<Fragment>{excels}</Fragment>);
	}
}

const InvoiceCustomerExcelWrap = withApollo(InvoiceCustomerExcel);

export { InvoiceCustomerExcelWrap, invoiceCustomerType };
