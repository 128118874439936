import React, {Component, Fragment} from 'react';
import { Input, Form } from 'antd';

class CustomInput extends Component {
  render () {
      let  {
          placeholder,
          className,
          type,
          label,
          width = 24,
          maxLength,
          onChange,
          postLabel,
          preLabel,
          labelWidth = 4,
          offset = 0,
          half = false,
          oneThird = false,
          twoThird = false,
          oneFourth = false,
          rowField = true,
          style,
          small = false,
          fieldName,
          decorator,
          mode,
          readOnly,
          onFocus,
          form : { getFieldDecorator },
          autoComplete,
          onBlur,
          autoFocus = false
      } = this.props;
      const formItemLayout = {
          labelCol: {  //for label width
              xs: { span: 24 },
              sm: { span: 8 },
              md: { span: 5 },
              lg: { span: labelWidth },
          },
          wrapperCol: { //for input width
              xs: { span: 24 },
              sm: { span: 24 },
              md: { span: width, offset },
          },
      };
      let mainStyle = { display:'inline-block' };
      if(half){
          mainStyle.width = '50%';
      }else if(oneThird){
        mainStyle.width = '33.3%';
      }else if(twoThird){
        mainStyle.width = '66.6%';
      }else if(oneFourth){
          mainStyle.width = '25%';
      }else if(small){
          mainStyle.width = '15%';
      }else mainStyle = null;
    //   const trimmed = Object.assign(decorator, {initialValue : decorator.initialValue !== undefined ? decorator.initialValue.trim() : ''});
      return (
        <Fragment>
            {rowField ? (
                <Form.Item
                    {...formItemLayout}
                    label={label}
                    style={mainStyle}
                    className={className}>
                    {getFieldDecorator(fieldName, decorator)(
                        <Input autoFocus={autoFocus} onFocus={onFocus}  onBlur={onBlur} readOnly={readOnly} disabled={mode === 'delete' ? true : false} name={fieldName} type={type} placeholder={placeholder} maxLength={maxLength} onChange={onChange} style={style} autoComplete={autoComplete}/>
                    )}
                </Form.Item>
                ): (<Fragment>
                        {label && <label htmlFor={fieldName} style={{width:labelWidth, display: 'inline-block'}} className={className}>{label}</label>}
                        {preLabel && <label htmlFor={fieldName} style={{marginRight:10}}>{preLabel}</label>}
                        {getFieldDecorator(fieldName, decorator)(
                            <Input autoFocus={autoFocus} onBlur={onBlur} onFocus={onFocus} readOnly={readOnly} style={style} disabled={mode === 'delete' ? true : false} name={fieldName} placeholder={placeholder} maxLength={maxLength} onChange={onChange} autoComplete={autoComplete}/>
                        )}
                    {postLabel && <label htmlFor={fieldName} style={{marginRight:10}}>{postLabel}</label>}
            </Fragment>
            )}
        </Fragment>
      )
  }
}

export default CustomInput;
