import { gql } from 'apollo-boost';

const getAll = gql`
  query AllUsers($first: Int, $offset: Int, $filter: UserFilter) {
    allUsers (first:$first,offset:$offset, filter:$filter, orderBy: DISPLAY_ORDER_ASC){
      nodes {
        loginId
        fullNameJa
        fullNameJaKatakana
        shortNameJa
        affiliationStartDate
        affiliationEndDate
        postCode
        address
        mobileNo
        dateOfBirth
        bloodType
        vehicleNumber
        displayOrder
        status
        id
        rolesByUserId {
          nodes {
            id
            userId
            role
          }
        }
      }
      totalCount
    }
  }
`;

const create = gql`
  mutation createUser(
    $loginId: String!, 
    $password: String!,
    $fullNameJa: String!, 
    $fullNameJaKatakana: String!,
    $shortNameJa: String!,
    $affiliationStartDate: Date!,
    $postCode: String,
    $address: String,
    $mobileNo: String,
    $dateOfBirth: Date!,
    $bloodType: BloodTypeOption,
    $vehicleNumber: Int,
    $driversLicenseNo : String,
    $driversLicenseValiduntil : Date,
    $driversLicensePdf : String,
    $otherCertifications : String,
    $displayOrder: Int,
    $updatedUserId : Int!,
    $createdUserId : Int!,
  ){
    createUser(input: {user:{
      loginId: $loginId, 
      fullNameJa: $fullNameJa, 
      password: $password,
      fullNameJaKatakana: $fullNameJaKatakana,
      affiliationStartDate: $affiliationStartDate,
      shortNameJa: $shortNameJa,
      postCode: $postCode,
      address: $address,
      mobileNo: $mobileNo,
      dateOfBirth: $dateOfBirth,
      bloodType: $bloodType,
      vehicleNumber: $vehicleNumber,
      displayOrder: $displayOrder,
      driversLicenseNo : $driversLicenseNo,
      driversLicenseValiduntil : $driversLicenseValiduntil,
      driversLicensePdf : $driversLicensePdf,
      otherCertifications : $otherCertifications,
      createdUserId : $createdUserId,
      updatedUserId : $updatedUserId
    }}){
      user {
        id
        loginId
        fullNameJaKatakana 
      }
    }
  }
`;

const getById = gql`
    query GetUser($id:Int!){
      userById(id:$id) {
        id,
        loginId,
        fullNameJa, 
        fullNameJaKatakana,
        shortNameJa,
        affiliationStartDate,
        affiliationEndDate,
        postCode,
        status,
        address,
        mobileNo,
        dateOfBirth,
        bloodType,
        vehicleNumber,
        displayOrder
        driversLicenseNo,
        driversLicenseValiduntil,
        driversLicensePdf,
        otherCertifications,
        rolesByUserId {
          nodes {
            id
            userId
            role
          }
        }
      }
    }
`;

const updateById = gql`
  mutation updateUserById(
    $id: Int!,
    $loginId: String!, 
    $fullNameJa: String!, 
    $fullNameJaKatakana: String!,
    $shortNameJa: String!,
    $password: String,
    $affiliationStartDate: Date!,
    $postCode: String,
    $address: String,
    $mobileNo: String,
    $dateOfBirth: Date!,
    $bloodType: BloodTypeOption,
    $status: StatusOption,
    $vehicleNumber: Int,
    $displayOrder: Int,
    $driversLicenseNo : String,
    $driversLicenseValiduntil : Date,
    $driversLicensePdf : String,
    $otherCertifications : String,
    $updatedUserId : Int!,
  ){
    updateUserById(input: {
      id: $id,
      userPatch:{
        loginId: $loginId, 
        fullNameJa: $fullNameJa, 
        password: $password,
        fullNameJaKatakana: $fullNameJaKatakana,
        affiliationStartDate: $affiliationStartDate,
        shortNameJa: $shortNameJa,
        postCode: $postCode,
        address: $address,
        status: $status,
        mobileNo: $mobileNo,
        dateOfBirth: $dateOfBirth,
        bloodType: $bloodType,
        vehicleNumber: $vehicleNumber,
        displayOrder: $displayOrder,
        driversLicenseNo : $driversLicenseNo,
        driversLicenseValiduntil : $driversLicenseValiduntil,
        driversLicensePdf : $driversLicensePdf,
        otherCertifications : $otherCertifications,
        updatedUserId : $updatedUserId
      }
    }) {
      user{
        id
        loginId
        fullNameJa
        rolesByUserId {
          nodes {
            id
            userId
            role
          }
        }
      }
    }
  }
`;

const deleteById = gql`
	mutation DeleteUserById($id:Int!){
		deleteUserById(input:{
			id: $id
		}) { 
			deletedUserId,
			user{
        id
        loginId
				fullNameJa
			}
    }
	}
`;


export { getAll, create, getById, updateById, deleteById };