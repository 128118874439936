import { gql } from 'apollo-boost';

const getUserQuery = gql`
  query userById($id: Int!){
    userById(id: $id) {
      fullNameJa,
      fullNameJaKatakana,
      id
    }
  }
`;

const getCustomerQuery = gql`
  query customerById($id: Int!){
    customerById(id: $id) {
      id,
      nameJa,
      nameJaKatakana,
      formOutputName,
    }
  }
`;

const changePassword = gql`
  mutation changePassword(
    $id: Int!,
    $password: String!,
  ){
    changePassword(input:{
      userId: $id,
      newPassword: $password
    }) {
      clientMutationId
    }
  }
`;


const changePasswordConfirmation = gql`
mutation changePasswordConfirmation(
  $newPassword:String,
  $currentPassword:String, 
  $userId: Int) {
  changePasswordConfirmation(input:{
    newPassword:$newPassword,
    currentPassword:$currentPassword,
    userId:$userId
  }) {
    user {
      id
    }
  }
}
`;

export { getUserQuery, getCustomerQuery, changePassword, changePasswordConfirmation };