import React, {Fragment} from 'react';
import { Form, Button,Input } from 'antd';

const CustomInputNumber = ({
        placeholder,
        label,
        width = 14,
        maxLength,
        offset = 0,
        half = false,
        oneFourth = false,
        small = false,
        formatter,
        rowField = true,
        style,
        labelWidth = 24,
        labelRowWidth = 4,
        postLabel,
        fieldName,
        parser,
        decorator,
        mode,
        onChange,
        className,
        form,
        form : { getFieldDecorator },
        hasClearBtn = false,
        btnReset,
        inputClassname,
        clearBtnMargin = 15,
        onBlur,
        textInput,
        
    }) => {

    const formItemLayout = {
        labelCol: {  //for label width
            xs: { span: 24 },
            sm: { span: 8 },
            md: { span: 5 },
            lg: { span: labelRowWidth },
        },
        wrapperCol: { //for input width
            xs: { span: 24 },
            sm: { span: 24 },
            md: { span: width, offset },
        },
    };
    
    let mainStyle = { display:'inline-block' };
    if(half){
        mainStyle.width = '50%';
    }else if(oneFourth){
        mainStyle.width = '25%';
    }else if(small){
        mainStyle.width = '15%';
    }else mainStyle = null;
    //   const trimmed = Object.assign(decorator, {initialValue : decorator.initialValue !== undefined ? decorator.initialValue.trim() : ''});

    const handleOnClear = (form, name) => {
        form.setFieldsValue({
            [name]: 0
        });
    }
    const parseHandler = (e) => {
        let x = document.getElementById(fieldName).value;
        x=x.replace(/\D+/g, '');
        form.setFieldsValue({
            [fieldName]: `${x}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        })
    }
    const onFocusHandler = () => {
        var x=document.getElementById(fieldName)
        if(!!x.value){
            x.value=parseFloat(x.value.toString().replace(/,/g, ''))
        }
    }
    

    return (
        <Fragment>
            {rowField ? (
                <Form.Item
                    {...formItemLayout}
                    label={label}
                    className={inputClassname}
                    style={mainStyle}>
                    {getFieldDecorator(fieldName, decorator)(
                        <Input  onBlur={onBlur} min={0} disabled={mode === 'delete' ? true : false} placeholder={placeholder} maxLength={maxLength} onChange={onChange} style={style} className={inputClassname} onFocus={onFocusHandler}/>
                    )}
                    {postLabel && <label className={className} htmlFor={fieldName} style={{marginRight:10}}>{postLabel}</label>}
                        {hasClearBtn && <Button disabled={mode === 'delete' ? true : false} style={{marginLeft:clearBtnMargin,width: 130} } type="primary" onClick={() => handleOnClear(form, `${fieldName}`)} >{btnReset}</Button>}
                </Form.Item>
                ): ( <span className="ant-form-item">
                    {label && <label htmlFor={fieldName} style={{width:labelWidth, display: 'inline-block'}}>{label}</label>}
                        {getFieldDecorator(fieldName, decorator)(
                    <Input onBlur={parseHandler} min={0} disabled={mode === 'delete' ? true : false} placeholder={placeholder} maxLength={maxLength} onChange={onChange} className={inputClassname} style={style} onFocus={onFocusHandler}/>
                        )}
                        {postLabel && <label htmlFor={fieldName} style={{marginRight:10}}>{postLabel}</label>}
                        {hasClearBtn && <Button disabled={mode === 'delete' ? true : false} style={{marginLeft:clearBtnMargin}} className="btn-block" type="primary" onClick={() => handleOnClear(form, `${fieldName}`)} >{btnReset}</Button>}
                </span>
            )}
        </Fragment>
    );
}

export default CustomInputNumber;
