import React from 'react';
import Filter from './Filter';
import Graph from './Graph';

// Get translator
import adhocTranslator from '../../../utils/locales/translate-adhoc';

export default () => {
    let lt = adhocTranslator('K100-010');
    return {
        filter : <Filter lt={lt}/>,
        graph : <Graph lt={lt}/>
    }
}