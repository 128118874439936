import 'antd/dist/antd.css';
import { ApolloClient, InMemoryCache } from 'apollo-boost';
import { setContext } from 'apollo-link-context';
import { createHttpLink } from 'apollo-link-http';
import { createBrowserHistory } from 'history';
import moment from 'moment';
import 'moment/locale/ja';
import React, { useState } from 'react';
import { ApolloProvider } from 'react-apollo';
import ReactDOM from 'react-dom';
import { Router, Switch } from 'react-router-dom';
import TabGuard from 'react-tabguard';
import './assets/css/main.css'; //dev css
import indexRoutes from "./routes";
import FancyRoute from './routes/FancyRoute';
import * as serviceWorker from './serviceWorker';
import { GlobalContext } from './utils/context';

moment.locale('ja');

const hist = createBrowserHistory();
const httpLink = new createHttpLink({

    // uri: process.env.REACT_APP_GRAPHQL || 'http://52.77.254.202:4005/graphql'
    // uri: process.env.REACT_APP_GRAPHQL || 'http://52.77.254.202:4005/graphql'
    uri: process.env.REACT_APP_GRAPHQL || 'http://localhost:4005/graphql'
});

const authLink = setContext((_, { headers }) => {
    const token = localStorage.getItem('token');
    let baseHeaders = {
        ...headers
    };

    // Check if there is a token
    if (token) {
        baseHeaders.authorization = `Bearer ${token}`;
    }
    return { headers: baseHeaders };

});

const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),

});

const Main = () => {
    const [state, setState] = useState({
        savedDate: null,
        savedTab: null,
        savedSpan: null
    });

    const globalSetter = (value) => setState(state => ({ ...state, ...value }));

    return (
        <TabGuard>
            <Router history={hist}>
                <ApolloProvider client={client}>
                    <Switch>
                        <GlobalContext.Provider value={{ ...state, globalSetter }}>
                            {indexRoutes.map((prop, key) => {
                                return <FancyRoute path={prop.path} key={key} render={prop.render} />;
                            })}
                        </GlobalContext.Provider>
                    </Switch>
                    {/* <AlertsNotification /> */}
                </ApolloProvider>
            </Router>
        </TabGuard>
    )
};

ReactDOM.render(<Main />, document.getElementById('root'));

serviceWorker.unregister();

