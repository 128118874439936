import { Col, Form, Row } from 'antd';
import moment from 'moment';
import React from 'react';
/** Get All Common  **/
import {
    CustomButton,
    CustomDatepicker,
    CustomHiddenInput,
    CustomInput,
    CustomSelect
} from '../../../commons/CustomDataEntry';
import ModalBase from '../../../commons/ModalBase';
/** Get All Package Queries **/
import {
    create,
    deleteById,
    getAll,
    getById,
    updateById
} from '../../../queries/MasterMaintenance/driverleave';
/** Get ADhoc translate **/
import adhocTranslator from '../../../utils/locales/translate-adhoc';
/** Get user_id stored in LocalStorage **/
import decodeToken from '../../../utils/user_id';
import UserModalConfig from '../User/ListModal';

export default ({ mode }) => {

    /** Initialize translators **/
    let lt = adhocTranslator('K905-020');

    return {
        /** Assign mode **/
        mode,

        /** Redirect config **/
        redirect: '/driver-leave/search-list',

        /** query mapping **/
        data: {
            idRef: 'leafById'
        },
        /** Map queries **/
        queries: {
            GET_BY_ID: getById,
            GET_ALL: getAll,
            CREATE: create,
            UPDATE_BY_ID: updateById,
            DELETE_BY_ID: deleteById
        },

        decorate: (variables) => {
            variables.leaveDate = variables.leaveDate ? variables.leaveDate.format("YYYY-MM-DD") : null;
            return variables;
        },

        /** messages */
        messages: {
            add: "運転手休暇情報を登録しますか？",
            edit: "運転手休暇情報を更新しますか？",
            delete: "運転手休暇情報を削除しますか？",
            reg_success_message: lt("reg_success_message"),
            reg_error_message: lt("reg_error_message"),
            edit_success_message: lt("edit_success_message"),
            edit_failure_message: lt("edit_failure_message"),
            delete_success_message: lt("delete_success_message"),
            delete_failure_message: lt("delete_failure_message")
        },

        /** Create common component **/
        component: ({ form, data, handleSubmit, loading }) => {
            const selectBT = [
                { id: 'SPECIAL_LEAVE', value: '特別休暇' },
                { id: 'PAID_LEAVE', value: '有給休暇' },
                { id: 'ABSENCE', value: '欠勤' },
                { id: 'REPAIR', value: '修理' },
            ];

            let btnLabel;
            if (mode === 'edit') {
                btnLabel = lt('btn_edit')
            } else if (mode === 'delete') {
                btnLabel = lt('btn_delete')
            } else {
                btnLabel = lt('btn_register')
            }
            return (
                <Row type='flex' className='h100' justify='space-around' align='top'>
                    <Col span={24}>
                        <Form onSubmit={handleSubmit} layout='vertical'>
                            <CustomHiddenInput
                                fieldName={mode === 'add' ? 'createdUserId' : 'updatedUserId'}
                                form={form}
                                decorator={{
                                    initialValue: decodeToken().user_id
                                }}
                            />
                            <CustomDatepicker
                                form={form} label={lt('lbl_leave_date')}
                                fieldName='leaveDate'
                                mode={mode}
                                placeholder={lt('dpc_leave_date')}
                                offset={0}
                                width={14}
                                labelWidth={2}
                                decorator={{
                                    rules: [{
                                        required: true,
                                        message: lt('年月日を入力してください。'),
                                    }],
                                    initialValue: data.leaveDate && moment(data.leaveDate)
                                }}
                            />
                            <div className="ant-row ant-form-item">
                                <ModalBase
                                    title='社員情報検索'
                                    form={form}
                                    mode={mode}
                                    label={lt('lbl_driver')}
                                    placeholder={lt('txt_driver')}
                                    btnLabel={lt('btn_select_driver')}
                                    fieldName='userId'
                                    rowField={false}
                                    labelWidth={2}
                                    config={UserModalConfig({ authority: 4 })}
                                    decorator={{
                                        rules: [{
                                            required: true,
                                            message: lt('運転手を選択してください。'),
                                        }],
                                        initialValue: data.userByUserId && data.userByUserId.fullNameJa
                                    }}
                                    hiddenDecorator={{
                                        initialValue: data.userId
                                    }}
                                />
                            </div>
                            <CustomSelect
                                options={selectBT}
                                form={form}
                                fieldName='leaveCategory'
                                label={lt('lbl_leave_category')}
                                placeholder={lt('lst_leave_category')}
                                mode={mode}
                                width={7}
                                labelWidth={2}
                                decorator={{
                                    rules: [{
                                        required: true,
                                        message: lt('休暇区分を選択してください。'),
                                    }],
                                    initialValue: data.leaveCategory
                                }} />

                            <CustomInput
                                autoFocus={true}
                                form={form}
                                fieldName='reason'
                                label={lt('lbl_reason')}
                                placeholder={lt('txt_reason')}
                                type='text'
                                mode={mode}
                                offset={2}
                                width={14}
                                maxLength={255}
                                labelWidth={2}
                                half={true}
                                decorator={{
                                    initialValue: data.reason
                                }} />

                            <CustomButton label={btnLabel}
                                event={handleSubmit}
                                loading={loading}
                                style={{ marginTop: '15px', height: '40px', width: '104px' }}
                                htmlType="submit"
                                offset={12} />
                        </Form>
                    </Col>
                </Row>
            );
        }

    }
}