import React from 'react';
import { Col, Row } from 'antd';

import LoginForm from '../../commons/LoginForm';
import styles from '../../assets/scss/login.module.scss';
import withTranslation from '../../utils/locales/translator';

export default withTranslation((props) => (
	<Row type='flex' className='h100' justify='space-around' align='middle'>
		<Col span={5} className={styles.login}>
			<LoginForm 
				txtCompanyName={props.lt('lbl_company_name')}
				txtSystemName={props.lt('lbl_system_name')}
				txtLoginId={props.lt('txt_login_id')}
				errLoginId={props.lt('ログインIDを入力してください。')}
				errPassword={props.lt('パスワードを入力してください。')}
				txtPassword={props.lt('psw_password')}
				lblSaveLogin={props.lt('lbl_save_login')}
				txtBtnLogin={props.lt('btn_login')}
			/>
		</Col>
	</Row>
), 'K000-010')