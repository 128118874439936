import axios from 'axios'
import config from '../config'

/**
 * Set base axios
 * @type {AxiosInstance}
 */
let instance = axios.create({
  baseURL: config.API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
})

export const baseAxios = (method, url, data = {}, params = {}, options = {}) => {
  return instance({
    method,
    url,
    data,
    params,
    ...options,
  })
}

/**
 * Handles the payload for axios REST used in file upload of order transport
 */
export const uploadOrderTransportFiles = payload => {
  return baseAxios(
    'POST',
    '/upload-order-transport-images',
    payload,
    {},
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  )
}
