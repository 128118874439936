import React, { Component } from 'react'
import { Table } from 'antd';
import styles from '../../assets/scss/table.module.scss';

class TableList extends Component {

    state = {
        selectedRowKeys: [],
        selectedRows: [],
        currentPage: 1,
        currentSelected: ''
    };

    internalTableChange = (handleTableChange) => {
        return (pagination) => {
            if (this.state.currentPage !== pagination.current) {
                this.setState({ selectedRowKeys: [] });
            } else {
                this.setState({ selectedRowKeys: this.state.currentSelected });
            }
            if (this.props.onSelection) {
                this.props.onSelection([], []);
            }
            handleTableChange(pagination);
        }
    };


    componentWillReceiveProps(nextProps) {
        if (nextProps.clearSelected) {
            this.setState({ selectedRowKeys: [] });
        }
    }

    onRowClick(record, type) {
        if (type === 'radio') {
            let selectedRows = [...this.state.selectedRows];
            const recordIndex = selectedRows.findIndex(data => data.key === record.key);

            // Set selected
            if (recordIndex >= 0) {
                selectedRows.splice(recordIndex, 1)
            } else {
                selectedRows = [record]
            }
            /** Check if there is a listener **/
            const recordKeys = selectedRows.map(data => data.key);
            if (this.props.onSelection) {
                this.props.onSelection(recordKeys, selectedRows);
            }

            this.props.selectedRows(selectedRows);
            this.setState({ ...this.state, selectedRowKeys: recordKeys, currentSelected: recordKeys, selectedRows });
        } else {
            const selectedRows = [...this.state.selectedRows];
            const recordIndex = selectedRows.findIndex(data => data.key === record.key);

            // Set selected
            recordIndex >= 0 ? selectedRows.splice(recordIndex, 1) : selectedRows.push(record);

            /** Check if there is a listener **/
            const recordKeys = selectedRows.map(data => data.key);
            if (this.props.onSelection) {
                this.props.onSelection(recordKeys, selectedRows);
            }

            this.props.selectedRows(selectedRows);
            this.setState({ ...this.state, selectedRowKeys: recordKeys, currentSelected: recordKeys, selectedRows });
        }
    }

    render() {
        let {
            clearSelected,
            handleTableChange,
            removeType = false,
            pagination,
            bordered = false,
            loading,
            columns,
            rowClassName,
            data,
            minWidth = null,
            multiple = true,
            th,
            total,
        } = this.props;

        const rowMaker = {
            rowSelection: {
                selectedRowKeys: this.state.selectedRowKeys,
                type: this.props.type ? this.props.type : 'checkbox',
                onChange: (selectedRowKeys, selectedRows) => {
                    if (clearSelected) {
                        this.props.clearRows(false)
                    }
                    /** Check if there is a listener **/
                    if (this.props.onSelection) {
                        this.props.onSelection(selectedRowKeys, selectedRows);
                    }
                    this.setState({ selectedRowKeys, currentPage: pagination.current, currentSelected: selectedRowKeys, selectedRows });
                    this.props.selectedRows(selectedRows);
                }
            }
        };
        let rowTable = !removeType ? { ...rowMaker } : {};
        let scroller = th ? { y: th, x: minWidth } : { x: minWidth }
        return (
            <Table
                onRow={(record) => ({ onClick: () => this.onRowClick(record, this.props.type) })}
                bordered={bordered}
                className={"custom-table-wrapper " + styles.table + (total && " hasPagination")}
                loading={loading}
                columns={columns}
                rowClassName={rowClassName}
                // className={className}
                dataSource={data}
                onChange={this.internalTableChange(handleTableChange)}
                pagination={pagination}
                {...rowTable}
                scroll={scroller}
            />
        )
    }
}

export default TableList
