import React, { useContext, useState } from 'react';
import { Form, Row, Col, Button, Table, Modal, notification } from 'antd';
import Year from '../../../commons/Picklist/Year';
import Month from '../../../commons/Picklist/Month';
import { CustomRadioGroup, CustomHiddenInput } from "../../../commons/CustomDataEntry";
import CustomerModalConfig from '../../../components/MasterMaintenance/Customer/ListModal'
import ModalBase from "../../../commons/ModalBase";
import { Context } from '../../../commons/Context';
import _ from 'lodash';
import moment from 'moment';
import uuid from 'uuid/v4';
import { getAll as getAllCustomers } from "../../../queries/MasterMaintenance/customer";
import { getMonth } from "../../../queries/Reporting";
import WithEventButton from '../../../commons/WithEventButton';

notification.config({
  duration: 8
})

const Filter = ({ lt, form }) => {
  let graphs = [];
  const [selectedRadioCustomer, handleSelectedRadioCustomer] = useState(1)
  const [selectedRowKeys, handleSelectedRowKeys] = useState([])
  const { state, dispatch, client } = useContext(Context);

  const openNotificationWithIcon = (type, message) => {
    notification[type]({ message });
  };

  const search = (e) => {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        let filterByCustomerId = true
        if (values.customerId && selectedRadioCustomer !== 1) {
          confirmationModal(values, filterByCustomerId)
        } else if (selectedRadioCustomer === 1) {
          filterByCustomerId = false
          confirmationModal(values, filterByCustomerId)
        } else {
          openNotificationWithIcon('error', '顧客名を選択してください。');
        }
      }
    })
  }

  const confirmationModal = (values, filterByCustomerId) => {
    let date1 = moment(`${values.transportDate_From_Year}-${values.transportDate_From_Month}-01`).format("YYYY-MM-01");
    let date2 = moment(`${values.transportDate_To_Year}-${values.transportDate_To_Month}-01`).add(1, "months").format("YYYY-MM-01");
    Modal.confirm({
      title: '確認',
      icon: 'false',
      content: '顧客別売上の分析を開始しますか？',
      okText: 'はい',
      cancelText: 'いいえ',
      onOk: () => {
        dispatch({
          type: 'filters',
          filters: { values }
        });
        dispatch({
          type: 'spinner',
          spinner: true
        });
        customerSale(values, date1, date2, filterByCustomerId);
        openNotificationWithIcon('success', '顧客別売上分析を開始しました。');
      }
    });
  }

  let customerSale = async (values, date1, date2, filterByCustomerId) => {
    let startDate = moment(date1);
    let endDate = moment(date2);
    let yearOrMonth = 'month';
    let formatYearOrMonth = 'MM月';
    if (filterByCustomerId) {
      const { data: { orderDispatchMonthlyReportQuery: { result } } } = await client.query({
        query: getMonth,
        fetchPolicy: 'network-only',
        variables: {
          input: {
            customerId: [values.customerId],
            startTransportDate: date1,
            endTransportDate: date2
          }
        }
      });
      let data = _.chain(result).map(({ month, year, fare }) => {
        month = moment(month, 'MM').format('MM')
        return {
          date: `${year}-${month}`, total: fare ? parseFloat(_.replace(fare, /[,￥]/g, '')) : 0
        }
      }).value()
        // eslint-disable-next-line no-unused-vars
      let i = 0;
      while (startDate.isBefore(endDate)) {
        i++;
        // eslint-disable-next-line no-loop-func
        let filteredData = _.filter(data, dataInfo => {
          return dataInfo.date === startDate.startOf(yearOrMonth).format("YYYY-MM");
        });

        if (yearOrMonth === 'month' && !_.find(graphs, { name: startDate.format('YYYY') })) {
          graphs.push({ name: startDate.format("YYYY年") });
        } else if (yearOrMonth === 'year' && !_.find(graphs, { name: "" })) {
          graphs.push({ name: "" });
        }

        if (filteredData.length > 0) {
          if (yearOrMonth === 'month') {
            let findYear = _.find(graphs, { name: startDate.format('YYYY年') });
            if (findYear) {
              findYear[startDate.format(formatYearOrMonth)] = _.head(filteredData).total;
            }
          } else {
            _.head(graphs)[startDate.format(formatYearOrMonth)] = _.head(filteredData).total;
          }
        } else {
          if (yearOrMonth === 'month') {
            let findYear = _.find(graphs, { name: startDate.format('YYYY年') });
            if (findYear) {
              findYear[startDate.format(formatYearOrMonth)] = 0;
            }
          } else {
            _.head(graphs)[startDate.format(formatYearOrMonth)] = 0;
          }
        }
        startDate = startDate.add(1, yearOrMonth);
      }

      dispatch({
        type: 'graph',
        graphs
      });
    } else {
      const { data: { allCustomers: { totalCount } } } = await client.query({
        query: getAllCustomers,
        fetchPolicy: 'network-only'
      })
      let allData = []
      for (let x = 0; x <= totalCount; x++) {
        allData = allData.concat(x)
      }
      const { data: { orderDispatchMonthlyReportQuery: { result } } } = await client.query({
        query: getMonth,
        fetchPolicy: 'network-only',
        variables: {
          input: {
            customerId: allData,
            startTransportDate: date1,
            endTransportDate: date2
          }
        }
      })
      let data = _.chain(result).map(({ month, year, fare }) => {
        month = moment(month, 'MM').format('MM')
        return {
          date: `${year}-${month}`, total: fare ? parseFloat(_.replace(fare, /[,￥]/g, '')) : 0
        }
      }).value()
        // eslint-disable-next-line no-unused-vars
      let i = 0;
      while (startDate.isBefore(endDate)) {
        i++;
        // eslint-disable-next-line no-loop-func
        let filteredData = _.filter(data, dataInfo => {
          return dataInfo.date === startDate.startOf(yearOrMonth).format("YYYY-MM");
        });
        if (yearOrMonth === 'month' && !_.find(graphs, { name: startDate.format('YYYY') })) {
          graphs.push({ name: startDate.format("YYYY年") });
        } else if (yearOrMonth === 'year' && !_.find(graphs, { name: "" })) {
          graphs.push({ name: "" });
        }
        if (filteredData.length > 0) {
          if (yearOrMonth === 'month') {
            let findYear = _.find(graphs, { name: startDate.format('YYYY年') });
            if (findYear) {
              findYear[startDate.format(formatYearOrMonth)] = _.head(filteredData).total;
            }
          } else {
            _.head(graphs)[startDate.format(formatYearOrMonth)] = _.head(filteredData).total;
          }
        } else {
          if (yearOrMonth === 'month') {
            let findYear = _.find(graphs, { name: startDate.format('YYYY年') });
            if (findYear) {
              findYear[startDate.format(formatYearOrMonth)] = 0;
            }
          } else {
            _.head(graphs)[startDate.format(formatYearOrMonth)] = 0;
          }
        }
        startDate = startDate.add(1, yearOrMonth);
      }

      dispatch({
        type: 'graph',
        graphs
      });
    }
  }

  const onRowClick = (record) => {
    if (selectedRowKeys === record.key) {
      handleSelectedRowKeys([]);
      form.setFieldsValue({
        customerId: null,
        customerName: null
      })
    } else {
      const { id, nameJa } = record;
      form.setFieldsValue({
        customerId: id,
        customerName: nameJa
      });
      handleSelectedRowKeys(record.key)
    }
  };

  const onSelect = ({ id, nameJa }) => {
    form.setFieldsValue({
      customerId: id,
      customerName: nameJa
    })
  };

  const onSelectedRowKey = e => handleSelectedRowKeys(e);

  const onSelectedRadioCustomer = (option) => {
    handleSelectedRadioCustomer(option.target.value)
  }

  const handleReset = () => {
    dispatch({
      type: 'reset'
    });
    form.resetFields();
    handleSelectedRowKeys([])
    openNotificationWithIcon('warning', '分析条件をクリアしました。');
    handleSelectedRadioCustomer(1)
  }

  const selectedCustomer = (rows) => {
    let selectedRows = rows.map(({ id, nameJa }) => {
      let { data } = state;
      let filterData = data.filter(item => item.nameJa === nameJa);
      if (filterData.length <= 0) {
        return {
          id,
          nameJa,
          key: uuid()
        };
      }
      return {}
    });

    let rowFilter = selectedRows.filter(item => item !== undefined);

    if (rowFilter.length) {
      dispatch({
        type: 'add',
        selectedData: rowFilter
      });
    }
    handleSelectedRowKeys([])
  };

  const radioFilter = [
    { id: 1, value: lt('rdo_all_customers'), autoFocus: true },
    { id: 2, value: lt('rdo_specify_customer'), autoFocus: false }
  ];

  let columns = [{
    title: lt('lbl_customer_name'),
    dataIndex: 'nameJa',
    key: 'nameJa',
  }];
  return (
    <Form onSubmit={search}>
      <Row>
        <Col span={24}>
          {lt('lbl_analysis_year/month')}
        </Col>
      </Row>
      <Row style={{ marginTop: '4px' }}>
        <Col span={24}>
          <div className='ant-row ant-form-item four_column' style={{ marginTop: '10px' }}>
            <Year
              form={form}
              fieldName='transportDate_From_Year'
              postLabel={lt('lbl_conditions_from_year')}
              rowField={true}
              style={{ width: 100, display: 'inline-block', marginRight: 10 }}
              decorator={{
                rules: [{
                  required: true,
                  message: ' '
                }]
              }}
              labelWidth={16}
              width={18}
            />
            <Month
              form={form}
              fieldName='transportDate_From_Month'
              postLabel={lt('lbl_conditions_from_month')}
              style={{ width: 80, display: 'inline-block', marginRight: 10 }}
              rowField={true}
              decorator={{
                rules: [{
                  required: true,
                  message: ' '
                }]
              }}
              labelWidth={16}
              width={14}
            />
            <Year
              form={form}
              fieldName='transportDate_To_Year'
              postLabel={lt('lbl_conditions_to_year')}
              rowField={true}
              style={{ width: 100, display: 'inline-block', marginRight: 10 }}
              decorator={{
                rules: [{
                  required: true,
                  message: ' '
                }]
              }}
              labelWidth={16}
              width={18}
            />
            <CustomHiddenInput
              form={form}
              fieldName='customerId'
              type='hidden'
              decorator={{}}
            />
            <CustomHiddenInput
              form={form}
              fieldName='customerName'
              type='hidden'
              decorator={{}}
            />
            <Month
              form={form}
              fieldName='transportDate_To_Month'
              postLabel={lt('lbl_conditions_to_month')}
              rowField={true}
              style={{ width: 80, display: 'inline-block', marginRight: 10 }}
              decorator={{
                rules: [{
                  required: true,
                  message: ' '
                }]
              }}
              labelWidth={16}
              width={14}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          {lt('lbl_analysis_target')}
        </Col>
      </Row>
      <Row style={{ marginTop: '10px' }}>
        <Col span={18}>
          <CustomRadioGroup
            options={radioFilter}
            form={form}
            fieldName='radio_customer'
            decorator={{
              initialValue: 1
            }}
            onChange={onSelectedRadioCustomer}
          />
        </Col>
        <Col span={6} style={{ textAlign: 'right' }}>
          <ModalBase
            title='顧客情報検索'
            hideInputText={true}
            form={form}
            btnLabel={lt('btn_click_on_select_customer')}
            fieldName='customers'
            afterSelect={selectedCustomer}
            config={CustomerModalConfig('checkbox')}
            decorator={{}}
            labelWidth={6}
            width={6}
            buttonMarginRight={0}
            buttonMarginLeft={0}
            mode={selectedRadioCustomer === 1 ? "delete" : ""}
            filterStatus
          />
        </Col>
      </Row>
      <Row style={{ marginTop: '10px' }}>
        <Col span={24}>
          <Table
            className='searchsales'
            position="top"
            bordered={true}
            columns={columns}
            dataSource={state.data}
            rowSelection={{
              onSelect: onSelect,
              type: 'radio',
              onChange: onSelectedRowKey,
              selectedRowKeys,
            }}
            onRow={(record, rowIndex) => ({ record, onClick: () => onRowClick(record) })}
            pagination={{ position: 'top' }}
          />
        </Col>
      </Row>
      <Row style={{ marginTop: '10px' }}>
        <Col span={12}>
          <Button type='default' style={{ width: '106px' }} onClick={handleReset} htmlType='button'>{lt('lbl_clear_conditions')}</Button>
        </Col>
        <Col span={12} style={{ textAlign: 'right' }}>
          <WithEventButton
            event={search}
            loading={false}
            label={lt('btn_analyze')}
            style={{ width: '106px' }}
          />
        </Col>
      </Row>
    </Form>
  );
}

export default Form.create()(Filter);