import React, {Fragment} from 'react';
import CustomSelect from '../CustomDataEntry/CustomSelect';
import {getAll} from '../../queries/MasterMaintenance/vehicle';
import {Query} from 'react-apollo';
import uuid from 'uuid/v4';

export default (props)=> {
    return (<Query
                query={getAll}
                fetchPolicy='network-only'
                variables={{filter: {status: {equalTo: 'ACTIVE'}}}
                }
            >
        {
            ({data, loading}) => {
                if( loading ) return <Fragment></Fragment>;
                let allVehicles = data.allVehicles.nodes.slice();
                allVehicles.unshift({
                    id: null,
                    v: ''
                });

                return (
                    <Fragment>
                        <CustomSelect {...props}
                                    keyUuid={uuid()}
                                    allowClear={true}
                                    classOption={true}
                                    options={props.hasDefault ? allVehicles : data.allVehicles.nodes}
                                    optionId={'id'}
                                    optionValue={'vehicleNo'}
                                    onSelect={props.onSelect ? props.onSelect : null}/>
                    </Fragment>
                );
            }
        }
    </Query>)
};