
import { gql } from 'apollo-boost';
import { graphql, compose, withApollo } from 'react-apollo';
import { Query } from 'react-apollo';
import React, { Component } from 'react';
import { Row, Col, Form } from 'antd';
import { CustomTable } from '../commons/CustomDataEntry';
import _ from 'lodash';
import { getCustomerById } from '../queries/MasterMaintenance/customer';
import moment from 'moment';
import { userRoles } from '../utils/types';

class ListBase extends Component {
    constructor(props) {
        super(props)
        let temp
        this.state = {
            filteredData: []
        };
        if (props.config.fetchQuery) {
            const { definitions: [fetchArray] } = props.config.fetchQuery;
            let currentDate = moment().format('YYYY/MM/DD');
            let search_date;
            if (props.order || props.contact) {
                search_date = localStorage.getItem('search_date');
                if (search_date === null) {
                    localStorage.setItem('search_date', currentDate);
                    search_date = currentDate;
                }
            }
            if (fetchArray.name.value === 'allTransportDestinations') {
                if (props.config.transportD) {
                    temp = {
                        first: 20,
                        filter: props.variable.filter
                    }
                }
            }
            // equalTo: props.form.getFieldValue('driverId1')
            if (fetchArray.name.value === 'AllOrders') {
                if (props.config.listpor) {
                    if (props.form.getFieldValue('driverId1') == null) {
                        temp = {
                            first: 1,
                            filter: {
                                dispatchStatus: {
                                    equalTo: 'COMPLETED'
                                },
                                driverId1: { equalTo: 0 }
                            }
                        }
                    }
                } else {
                    if (search_date === null || search_date === '') {
                        if (props.contact) {
                            const token = localStorage.getItem('token');
                            let user_id;
                            if (token) {
                                const decodeToken = JSON.parse(atob(token.split('.')[1]));
                                user_id = decodeToken.user_id;
                            }
                            temp = {
                                filter: {
                                    and: [
                                        {
                                            or: [
                                                { driverId1: { equalTo: user_id } },
                                                { driverId2: { equalTo: user_id } },
                                            ]
                                        },
                                        {
                                            not: {
                                                transportDate: {
                                                    isNull: false,
                                                    lessThan: currentDate
                                                }
                                            }
                                        }
                                    ]
                                }
                            }
                        } else {
                            temp = {
                                filter: {
                                    not: {
                                        transportDate: {
                                            isNull: false,
                                            lessThan: currentDate
                                        }
                                    }
                                }
                            }
                        }
                    } else if (props.contact) {
                        const token = localStorage.getItem('token');
                        let user_id;
                        if (token) {
                            const decodeToken = JSON.parse(atob(token.split('.')[1]));
                            user_id = decodeToken.user_id;
                        }
                        temp = {
                            filter: {
                                and: [
                                    {
                                        and: [
                                            { transportDate: { greaterThanOrEqualTo: moment(search_date).subtract(1, 'd').format('YYYY-MM-DD') } },
                                            { transportDate: { lessThanOrEqualTo: moment(search_date).add(1, 'd').format('YYYY-MM-DD') } }
                                        ]
                                    },
                                    {
                                        or: [
                                            {
                                                and: [
                                                    // { ordersTransportsByOrderIdExist: true },
                                                    {
                                                        or: [
                                                            {
                                                                and: [
                                                                    {
                                                                        ordersTransportsByOrderId: {
                                                                            some: { pickupDate: { equalTo: moment(search_date).format('YYYY-MM-DD') } }
                                                                        }
                                                                    },
                                                                    {
                                                                        ordersTransportsByOrderId: {
                                                                            some: { pickupDate: { isNull: false } }
                                                                        }
                                                                    }
                                                                ]
                                                            },
                                                            {
                                                                and: [
                                                                    {
                                                                        ordersTransportsByOrderId: {
                                                                            some: { dropoffDate: { equalTo: moment(search_date).format('YYYY-MM-DD') } }
                                                                        }
                                                                    },
                                                                    {
                                                                        ordersTransportsByOrderId: {
                                                                            some: { dropoffDate: { isNull: false } }
                                                                        }
                                                                    }
                                                                ]
                                                            }
                                                        ]
                                                    },
                                                    { transportDate: { notEqualTo: moment(search_date).format('YYYY-MM-DD') } }
                                                ]
                                            },
                                            { transportDate: { equalTo: moment(search_date).format('YYYY-MM-DD') } }
                                        ]
                                    },
                                    {
                                        or: [
                                            { driverId1: { equalTo: user_id } },
                                            { driverId2: { equalTo: user_id } },
                                        ]
                                    }
                                ]
                            }
                        };
                    }
                    else {
                        temp = {
                            filter: {
                                and: [
                                    {
                                        and: [
                                            { transportDate: { greaterThanOrEqualTo: moment(search_date).subtract(1, 'd').format('YYYY-MM-DD') } },
                                            { transportDate: { lessThanOrEqualTo: moment(search_date).add(1, 'd').format('YYYY-MM-DD') } }
                                        ]
                                    },
                                    {
                                        or: [
                                            {
                                                and: [
                                                    { ordersTransportsByOrderIdExist: true },
                                                    {
                                                        or: [
                                                            {
                                                                and: [
                                                                    {
                                                                        ordersTransportsByOrderId: {
                                                                            some: { pickupDate: { equalTo: moment(search_date).format('YYYY-MM-DD') } }
                                                                        }
                                                                    },
                                                                    {
                                                                        ordersTransportsByOrderId: {
                                                                            some: { pickupDate: { isNull: false } }
                                                                        }
                                                                    }
                                                                ]
                                                            },
                                                            {
                                                                and: [
                                                                    {
                                                                        ordersTransportsByOrderId: {
                                                                            some: { dropoffDate: { equalTo: moment(search_date).format('YYYY-MM-DD') } }
                                                                        }
                                                                    },
                                                                    {
                                                                        ordersTransportsByOrderId: {
                                                                            some: { dropoffDate: { isNull: false } }
                                                                        }
                                                                    }
                                                                ]
                                                            }
                                                        ]
                                                    },
                                                    { transportDate: { notEqualTo: moment(search_date).format('YYYY-MM-DD') } }
                                                ]
                                            },
                                            { transportDate: { equalTo: moment(search_date).format('YYYY-MM-DD') } }
                                        ]
                                    }
                                ]
                            }
                        }
                    }
                }
            }
            // if(fetchArray.name.value==='allLeaves'){
            //     if(props.config.driverLeave){

            //     }
            // }

            if (fetchArray.name.value === 'AllUsers') {
                if (props.config.authority) {
                    temp = {
                        filter: {
                            rolesByUserId: {
                                some: {
                                    or: [
                                        { role: { equalTo: userRoles.COMPANY_DRIVER } },
                                        { role: { equalTo: userRoles.OUTSOURCING_DRIVER } },
                                        { role: { equalTo: userRoles.REP_OR_ACCOUNTS_EXECUTIVE } },
                                        { role: { equalTo: userRoles.ESCORT_COMPANY_DRIVER } },
                                        { role: { equalTo: userRoles.ESCORT_OUTSOURCING_DRIVER } }
                                    ]
                                }
                            },
                            // or: [{ authority: { equalTo: 4 } }, { authority: { equalTo: 2 } }]
                        }
                    }
                }
            }

            if (fetchArray.name.value === 'allUnitPrices') {
                if (props.config.unitCategory) {
                    temp = {
                        filter: {
                            unitPriceCategory: {
                                equalTo: props.config.unitCategory
                            }
                        }
                    }
                }
            }

            if (fetchArray.name.value === 'allCustomers') {
                if (props.filterStatus) {
                    temp = {
                        filter: {
                            status: { equalTo: 'ACTIVE' }
                        }
                    }
                }
            }

            if (fetchArray.name.value === 'allPackagings') {
                if (props.filterStatusPackage) {
                    temp = {
                        filter: {
                            status: { equalTo: 'ACTIVE' }
                        }
                    }
                }
            }
            if (fetchArray.name.value === 'allTransportDestinations') {
                if (props.transportStatus) {
                    temp = {
                        filter: {
                            status: { equalTo: "ACTIVE" }
                        }
                    }
                }
                if (props.customerSelected) {
                    temp = {
                        filter: {
                            status: { equalTo: "ACTIVE" },
                            customerId: { equalTo: props.customerSelected }
                        }
                    }
                }
            }

        }

        temp = !!temp ? temp : {}
        this.state = {
            currentPage: 1,
            filters: {
                offset: 0,
                first: 20,
                ...temp
            },
            orderRender: {
                ...temp
            },
            selectedRows: [],
            clear: false,
            transportDate_From: moment(),
            didUpdate: false,
            newRow: []
        }
    }
    data = []
    isAdmin = () => {
        const token = localStorage.getItem('token');
        if (token) {
            const decodeToken = JSON.parse(atob(token.split('.')[1]));
            if (decodeToken.role === 'app_driver' || decodeToken.role === 'app_customer') {
                return false;
            }
        }
        return true;
    }

    /** Handle on selecting the row **/
    handleOnSelection = (selectedRowKeys, selectedRows) => {
        if (this.props.handleOnSelection) {
            this.props.handleOnSelection(selectedRowKeys, selectedRows);
        }
    };

    handleClear = (clear) => {
        this.setState({ clear })
    }

    /** Handle table pagination **/
    handleTableChange = (fetchMore) => {
        return (pagination) => {
            let {
                perPage,
                authority
            } = this.props.config;
            this.setState({ currentPage: pagination.current });
            let page = pagination.current - 1;
            let baseVariables = {
                first: perPage,
                offset: page * perPage
            };
            if (this.props.config.orderRender) {
                baseVariables.filter = {
                    ...this.state.orderRender.filter
                };
            }
            if (this.state.filter) {
                baseVariables.filter = this.state.filter;
            }
            if (this.props.paginateOrder) {
                baseVariables = {
                    ...baseVariables,
                    ...this.props.paginateOrder
                };
            }
            if (this.props.customFilterByDate) {
                if (authority) {
                    baseVariables.filter = {
                        // affiliationEndDate: { greaterThanOrEqualTo: this.props.customFilterByDate || moment().format('YYYY/MM/DD') },
                        rolesByUserId: {
                            some: {
                                or: [
                                    { role: { equalTo: userRoles.COMPANY_DRIVER } },
                                    { role: { equalTo: userRoles.OUTSOURCING_DRIVER } },
                                    { role: { equalTo: userRoles.REP_OR_ACCOUNTS_EXECUTIVE } },
                                    { role: { equalTo: userRoles.ESCORT_COMPANY_DRIVER } },
                                    { role: { equalTo: userRoles.ESCORT_OUTSOURCING_DRIVER } }
                                ]
                            }
                        }
                    };
                } else {
                    baseVariables.filter = {
                        // affiliationEndDate: { greaterThanOrEqualTo: this.props.customFilterByDate || moment().format('YYYY/MM/DD') },
                    };
                }
            }
            if (this.props.filterStatus) {
                baseVariables.filter = {
                    ...this.state.filter,
                    status: { equalTo: 'ACTIVE' }
                }
            }
            if (this.props.filterStatusPackage) {
                baseVariables.filter = {
                    ...this.state.filter,
                    status: { equalTo: 'ACTIVE' }
                }
            }

            if (this.props.transportStatus) {
                baseVariables.filter = {
                    ...this.state.filter,
                    status: { equalTo: 'ACTIVE' }
                }
            }

            if (this.props.customerSelected) {
                baseVariables.filter = {
                    ...this.state.filter,
                    customerId: { equalTo: this.props.customerSelected }
                }
            }

            this.setState({ filters: baseVariables });
            fetchMore({
                variables: baseVariables,
                updateQuery: (prev, { fetchMoreResult }) => {
                    if (!fetchMoreResult) return prev;
                    return Object.assign({}, fetchMoreResult);
                }
            })
        }
    };

    handleSearch = (fetchMore) => {
        return (e, filter = []) => {
            if (e) e.preventDefault();
            let { form } = this.props;
            let custom = filter.length ? filter : null
            form.validateFields(custom, (err, filters) => {
                let {
                    perPage,
                    fetchQuery,
                    authority
                } = this.props.config;
                const { definitions: [fetchArray] } = fetchQuery;
                let operators = {
                    'From': 'greaterThanOrEqualTo',
                    'To': 'lessThanOrEqualTo',
                    'LikeStart': 'startsWithInsensitive',
                    'Like': 'includesInsensitive',
                    'isEqual': 'equalTo'
                };
                let flatten = [];
                let ref = false;
                let filter = {};
                if (this.props.order && filters.transportDate_isNull_isNull_isNull_isNull === false) {
                    let search_date = localStorage.getItem('search_date');
                    if (!filters.optionOrder) {
                        filter = {
                            and: [
                                {
                                    and: [
                                        { transportDate: { greaterThanOrEqualTo: moment(search_date).subtract(1, 'd').format('YYYY-MM-DD') } },
                                        { transportDate: { lessThanOrEqualTo: moment(search_date).add(1, 'd').format('YYYY-MM-DD') } }
                                    ]
                                },
                                {
                                    or: [
                                        {
                                            and: [
                                                { ordersTransportsByOrderIdExist: true },
                                                {
                                                    or: [
                                                        {
                                                            and: [
                                                                {
                                                                    ordersTransportsByOrderId: {
                                                                        some: { pickupDate: { equalTo: moment(search_date).format('YYYY-MM-DD') } }
                                                                    }
                                                                },
                                                                {
                                                                    ordersTransportsByOrderId: {
                                                                        some: { pickupDate: { isNull: false } }
                                                                    }
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            and: [
                                                                {
                                                                    ordersTransportsByOrderId: {
                                                                        some: { dropoffDate: { equalTo: moment(search_date).format('YYYY-MM-DD') } }
                                                                    }
                                                                },
                                                                {
                                                                    ordersTransportsByOrderId: {
                                                                        some: { dropoffDate: { isNull: false } }
                                                                    }
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                },
                                                { transportDate: { notEqualTo: moment(search_date).format('YYYY-MM-DD') } }
                                            ]
                                        },
                                        { transportDate: { equalTo: moment(search_date).format('YYYY-MM-DD') } }
                                    ]
                                }
                            ]
                        };
                    } else {

                        filter = {
                            and: [
                                {
                                    and: [
                                        { transportDate: { greaterThanOrEqualTo: moment(search_date).subtract(1, 'd').format('YYYY-MM-DD') } },
                                        { transportDate: { lessThanOrEqualTo: moment(search_date).add(2, 'd').format('YYYY-MM-DD') } }
                                    ]
                                },
                                {
                                    or: [
                                        {
                                            and: [
                                                { ordersTransportsByOrderIdExist: true },
                                                {
                                                    or: [
                                                        {
                                                            and: [
                                                                {
                                                                    ordersTransportsByOrderId: {
                                                                        some: { pickupDate: { equalTo: moment(search_date).format('YYYY-MM-DD') } }
                                                                    }
                                                                },
                                                                {
                                                                    ordersTransportsByOrderId: {
                                                                        some: { pickupDate: { isNull: false } }
                                                                    }
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            and: [
                                                                {
                                                                    ordersTransportsByOrderId: {
                                                                        some: { dropoffDate: { equalTo: moment(search_date).format('YYYY-MM-DD') } }
                                                                    }
                                                                },
                                                                {
                                                                    ordersTransportsByOrderId: {
                                                                        some: { dropoffDate: { isNull: false } }
                                                                    }
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                },
                                                {
                                                    and: [
                                                        {
                                                            transportDate: { notEqualTo: moment(search_date).format('YYYY-MM-DD') }
                                                        },
                                                        {
                                                            transportDate: { notEqualTo: moment(search_date).add(1, 'd').format('YYYY-MM-DD') }
                                                        }
                                                    ]
                                                }
                                            ]
                                        },
                                        {
                                            or: [
                                                {
                                                    transportDate: { equalTo: moment(search_date).format('YYYY-MM-DD') }
                                                },
                                                {
                                                    transportDate: { equalTo: moment(search_date).add(1, 'd').format('YYYY-MM-DD') }
                                                }
                                            ]
                                        }
                                    ]
                                }
                            ]
                        };
                    }
                } else if (this.props.contact && filters.transportDate_isNull_isNull_isNull_isNull === false) {
                    let search_date = localStorage.getItem('search_date');
                    const token = localStorage.getItem('token');
                    let user_id;
                    if (token) {
                        const decodeToken = JSON.parse(atob(token.split('.')[1]));
                        user_id = decodeToken.user_id;
                    }
                    filter = {
                        and: [
                            {
                                and: [
                                    { transportDate: { greaterThanOrEqualTo: moment(search_date).subtract(1, 'd').format('YYYY-MM-DD') } },
                                    { transportDate: { lessThanOrEqualTo: moment(search_date).add(1, 'd').format('YYYY-MM-DD') } }
                                ]
                            },
                            {
                                or: [
                                    {
                                        and: [
                                            { ordersTransportsByOrderIdExist: true },
                                            {
                                                or: [
                                                    {
                                                        and: [
                                                            {
                                                                ordersTransportsByOrderId: {
                                                                    some: { pickupDate: { equalTo: moment(search_date).format('YYYY-MM-DD') } }
                                                                }
                                                            },
                                                            {
                                                                ordersTransportsByOrderId: {
                                                                    some: { pickupDate: { isNull: false } }
                                                                }
                                                            }
                                                        ]
                                                    },
                                                    {
                                                        and: [
                                                            {
                                                                ordersTransportsByOrderId: {
                                                                    some: { dropoffDate: { equalTo: moment(search_date).format('YYYY-MM-DD') } }
                                                                }
                                                            },
                                                            {
                                                                ordersTransportsByOrderId: {
                                                                    some: { dropoffDate: { isNull: false } }
                                                                }
                                                            }
                                                        ]
                                                    }
                                                ]
                                            },
                                            { transportDate: { notEqualTo: moment(search_date).format('YYYY-MM-DD') } }
                                        ]
                                    },
                                    { transportDate: { equalTo: moment(search_date).format('YYYY-MM-DD') } }
                                ]
                            },
                            {
                                or: [
                                    { driverId1: { equalTo: user_id } },
                                    { driverId2: { equalTo: user_id } },
                                ]
                            }
                        ]
                    };
                } else {
                    Object.keys(filters).forEach((filter) => {
                        let checkFilter = filter.split('-');
                        if (checkFilter.length > 1) {
                            return;
                        }
                        let filterOptions = filter.split('_');
                        let key = filterOptions.shift();
                        if (filterOptions[0] === 'Relation' && filters[filter]) {
                            let convertFilter = {};
                            convertFilter[key] = {
                                nameJa: {
                                    includesInsensitive: filters[filter]
                                }
                            };
                            flatten.push(convertFilter);
                        }

                        /** For 1 is to 1 **/
                        if (!filterOptions.length && (filters[filter] || filters[filter] === 0)) {
                            let equalTo;
                            if (filter === 'areaName' && filters['areaName-display']) {
                                equalTo = filters['areaName-display'];
                            } else if (filter === 'customerNameJa' && filters['customerNameJa-display']) {
                                equalTo = filters['customerNameJa-display'];
                            } else {
                                equalTo = filters[filter];
                            }
                            let convertFilter = {};
                            convertFilter[key] = {
                                equalTo
                            };
                            flatten.push(convertFilter);
                        } else if (filterOptions.length === 3 && operators[filterOptions[2]]) {
                            if (filters[filter]) {
                                let op = operators[filterOptions.shift()];
                                let convertFilter = {};
                                convertFilter[key] = { or: [{ [key]: { [op]: filters[filter] } }] };
                                let key1 = filterOptions.shift();
                                op = operators[filterOptions.shift()];
                                convertFilter[key].or.push({ [key1]: { [op]: filters[filter] } });
                                flatten.push(convertFilter);
                                filterOptions.shift();
                            }
                        }
                        /** For range filter **/
                        if (filterOptions.length === 1 && filters[filter]) {
                            if (moment.isMoment(filters[filter])) {
                                filters[filter] = moment(filters[filter]).format("YYYY/MM/DD")
                            }
                            let op = operators[filterOptions.shift()];
                            let index = _.findIndex(flatten, key);
                            let field = {};
                            field[key] = {};
                            let katakanaName = [];
                            field[key][op] = {};
                            if (Array.isArray(filters[filter]) && (key === 'nameJaKatakana' || key === 'fullNameJaKatakana')) {
                                filters[filter].forEach(item => {
                                    katakanaName.push({ [key]: { [op]: item } });
                                })
                            } else {
                                switch (op) {
                                    case 'greaterThanOrEqualTo':
                                        field[key][op] = moment(filters[filter]).startOf('day').format("YYYY/MM/DD HH:mm:ss");
                                        if (this.props.config.packageSearch) {
                                            field[key][op] = form.getFieldValue('weight_From')
                                        }
                                        if (this.props.config.maintenanceVehicleList) {
                                            if (filter === 'oilChangeDistance_From') {
                                                field[key][op] = form.getFieldValue('oilChangeDistance_From')
                                            }
                                            if (filter === 'greaseDistance_From') {
                                                field[key][op] = form.getFieldValue('greaseDistance_From')
                                            }
                                        }
                                        break;
                                    case 'lessThanOrEqualTo':
                                        field[key][op] = moment(filters[filter]).endOf('day').format("YYYY/MM/DD HH:mm:ss");
                                        if (this.props.config.packageSearch) {
                                            field[key][op] = form.getFieldValue('weight_To')
                                        }
                                        if (this.props.config.maintenanceVehicleList) {
                                            if (filter === 'oilChangeDistance_To') {
                                                field[key][op] = form.getFieldValue('oilChangeDistance_To')
                                            }
                                            if (filter === 'greaseDistance_To') {
                                                field[key][op] = form.getFieldValue('greaseDistance_To')
                                            }
                                        }
                                        break;
                                    default:
                                        field[key][op] = filters[filter];
                                        break;
                                }
                            }

                            try {
                                // If key is not existing
                                if (index < 0) {
                                    let convertFilter = {};
                                    if (katakanaName.length > 0) {
                                        convertFilter[key] = { or: [] };
                                        convertFilter[key].or = katakanaName;
                                    } else {
                                        convertFilter[key] = { and: [] };
                                        convertFilter[key].and.push(field);
                                    }
                                    flatten.push(convertFilter);
                                } else {
                                    flatten[index][key].and.push(field)
                                }
                            } catch (err) { }
                        }

                        /** Combined fields operators **/
                        if (filterOptions.length === 2 && filters[filter]) {
                            let op = operators[filterOptions.shift()];
                            let type = filterOptions.shift();
                            let index = _.findIndex(flatten, key);
                            let field = {};
                            field[key] = {};
                            field[key][op] = filters[filter];
                            switch (op) {
                                case 'greaterThanOrEqualTo':
                                    field[key][op] = moment(filters[filter]).startOf('day').format("YYYY/MM/DD HH:mm:ss");
                                    break;
                                case 'lessThanOrEqualTo':
                                    field[key][op] = moment(filters[filter]).endOf('month').format("YYYY/MM/DD HH:mm:ss");
                                    break;
                                default:
                                    field[key][op] = filters[filter];
                                    break;
                            }
                            // If key is not existing
                            if (index < 0) {

                                let convertFilter = {};
                                convertFilter[key] = { and: [] };
                                convertFilter[key].and.push(field);
                                flatten.push(convertFilter);

                            } else {
                                let found = false;
                                flatten[index][key].and.forEach((f, i) => {
                                    if (f[key][op]) {
                                        let day = '01';
                                        found = true;
                                        if (type === 'Month' && op === 'lessThanOrEqualTo') {
                                            // day = '31';
                                            //old code
                                            // flatten[index][key].and[i][key][op] = moment(`${flatten[index][key].and[i][key][op]}-${filters[filter]}-${day}`).add('month', 1).date(1).subtract('days', 1).format("YYYY-MM-DD");
                                            flatten[index][key].and[i][key][op] = moment(`${moment(flatten[index][key].and[i][key][op]).year()}-${filters[filter]}-${day}`).add('month', 1).date(1).subtract('days', 1).format("YYYY-MM-DD");
                                        }
                                        else {
                                            //old code
                                            //flatten[index][key].and[i][key][op] = moment(`${flatten[index][key].and[i][key][op]}-${filters[filter]}-${day}`).format("YYYY-MM-DD");   
                                            flatten[index][key].and[i][key][op] = moment(`${moment(flatten[index][key].and[i][key][op]).year()}-${filters[filter]}-${day}`).format("YYYY-MM-DD");
                                        }
                                    }
                                });
                                if (!found) {
                                    flatten[index][key].and.push(field);
                                }
                            }
                        }

                        /** For relation fields */
                        if (filterOptions.length === 3 && filters[filter]) {
                            // let relationType = filterOptions.shift();
                            let op = operators[filterOptions.shift()];
                            let referenceField = filterOptions.shift();
                            let convertFilter = {};
                            convertFilter[key] = {};
                            convertFilter[key][referenceField] = {};
                            convertFilter[key][referenceField][op] = filters[filter];
                            flatten.push(convertFilter);
                        }

                        /** For isNull fields */
                        if (filterOptions.length === 4 && filters[filter]) {
                            let referenceField = filterOptions.shift();
                            ref = referenceField;
                            let convertFilter = {};
                            convertFilter[key] = {};
                            convertFilter[key][referenceField] = {};
                            convertFilter[key][referenceField] = false;
                            convertFilter[key].lessThan = filters[filter];
                            flatten.push(convertFilter);
                        }
                    });
                    let normalize = [];
                    let normalizeOr = [];

                    flatten.forEach((filter) => {
                        let key = Object.keys(filter)[0];
                        let op = Object.keys(filter[key])[0];
                        if (key === 'faxNo1' || key === 'telNo1') {
                            normalizeOr.push(filter);
                        }
                        if (key === 'postCode') {
                            normalize.push({
                                postCode: {
                                    includesInsensitive: Object.values(filter)[0]['and'][0]['postCode'].includesInsensitive.replace(/-/g, "")
                                }
                            })
                        }
                        if (op !== 'and' && op !== 'or') {
                            if (filter.postCode) {
                                return
                            }
                            normalize.push(filter);
                        } else {
                            if (filter.postCode) {
                                return
                            }
                            normalize.push(filter[key]);
                        }
                    });
                    let [temp] = normalize;
                    if (!ref) {
                        if (fetchArray.name.value === 'AllUsers') {
                            if (authority) {
                                let auth = {
                                    rolesByUserId: {
                                        some: {
                                            or: [
                                                { role: { equalTo: userRoles.COMPANY_DRIVER } },
                                                { role: { equalTo: userRoles.OUTSOURCING_DRIVER } },
                                                { role: { equalTo: userRoles.REP_OR_ACCOUNTS_EXECUTIVE } },
                                                { role: { equalTo: userRoles.ESCORT_COMPANY_DRIVER } },
                                                { role: { equalTo: userRoles.ESCORT_OUTSOURCING_DRIVER } }
                                            ]
                                        }
                                    }
                                };
                                normalize.push(auth)
                            }
                        }
                        filter = {
                            and: normalize
                        };
                    } else {
                        if (temp.transportDate && temp.transportDate.isNull) {
                            filter = temp
                        } else if (this.props.contact && normalize.length > 1) {
                            filter = {
                                and: [
                                    {
                                        ...temp
                                    },
                                    {
                                        not: normalize[1]
                                    }
                                ]
                            }
                        } else {
                            filter = {
                                not: temp
                            };
                        }
                    }
                    if (!!filters.faxNo1 && !!filters.telNo1) {
                        const filteredArray = normalize.filter(x => normalizeOr.indexOf(x) < 0)
                        filter = filteredArray;
                        filter.or = normalizeOr
                    }
                }

                this.setState({ currentPage: 1 })
                let page = this.state.currentPage - 1;
                if (('or' in filter && filter.or.length > 0) || ('and' in filter && filter.and.length > 0)) {
                    page = 0;
                }
                if (fetchArray.name.value === 'allCustomers') {
                    if (this.props.filterStatus) {
                        filter = {
                            ...filter,
                            status: { equalTo: 'ACTIVE' }
                        }
                    }
                }
                if (fetchArray.name.value === 'allPackagings') {
                    if (this.props.filterStatusPackage) {
                        filter = {
                            ...filter,
                            status: { equalTo: 'ACTIVE' }
                        }
                    }
                }
                if (fetchArray.name.value === 'allTransportDestinations') {
                    if (this.props.transportStatus) {
                        filter = {
                            ...filter,
                            status: { equalTo: 'ACTIVE' }
                        }
                    }
                    if (this.props.customerSelected) {
                        filter = {
                            ...filter,
                            customerId: { equalTo: this.props.customerSelected }
                        }
                    }
                }

                if (fetchArray.name.value === 'AllUsers') {
                    if (this.props.customFilterByDate) {
                        filter = {
                            ...filter,
                            // affiliationEndDate: { greaterThanOrEqualTo: this.props.customFilterByDate || moment().format('YYYY/MM/DD') },
                        };
                    }
                }

                if (fetchArray.name.value === 'allLeaves') {
                    const orIndex = filter.and.findIndex(data => !!data.or);
                    if (orIndex >= 0) {
                        filter.and[orIndex] = {
                            userByUserId: filter.and[orIndex]
                        }
                    }
                }

                fetchMore({
                    variables: {
                        first: perPage,
                        offset: page * perPage,
                        filter
                    },
                    updateQuery: (prev, { fetchMoreResult }) => {
                        return Object.assign({}, fetchMoreResult);
                    }
                });
                this.setState({
                    filter
                });
            });
        }
    };

    handleRows = (selectedRows) => {
        this.setState({ selectedRows })
        if (selectedRows[0] && selectedRows[0].fullNameJa) {
            this.selectedDriver = selectedRows[0].id
        }
    }

    clearRows = (clear) => {
        this.setState({ selectedRows: [], clear, currentPage: 1 })
    }

    componentWillMount() {
        // this.setState({filters: {
        //     first: 20,
        //     offset: 0
        // }})
    }
    async componentWillReceiveProps(nextProps) {
        if (nextProps.config.listpor) {
            if (!!nextProps.val && !!nextProps.form.getFieldValue('driverId1')) {
                let filter = {
                    transportDestinationByOriginId: {
                        id: {
                            equalTo: nextProps.val
                        }
                    },
                    orderByOrderId: {
                        driverId1: {
                            equalTo: nextProps.form.getFieldValue('driverId1')
                        },
                        dispatchStatus: {
                            equalTo: 'COMPLETED'
                        },
                    }
                };
                const getAllDistination = gql`
                query getAllDistination ($first:Int, $filter:OrdersTransportFilter)
                    {
                        allOrdersTransports(first:$first,filter:$filter,  orderBy: ORDER_BY_ORDER_ID__TRANSPORT_DATE_DESC)
                        {
                          nodes{
                            orderByOrderId {
                                    userByDriverId1 {
                                        fullNameJa
                                    },
                                    transportDate
                                }
                           }
                        }
                    }
                `;
                let { data } = await this.props.client.query({
                    query: getAllDistination,
                    fetchPolicy: 'network-only',
                    variables: { filter, first: 10 },

                })
                let allOrder = data.allOrdersTransports.nodes.map(item => {
                    return item
                })
                let newData = allOrder.map(item => {
                    let tempDate = item.orderByOrderId.transportDate
                    let tempDriverId = item.orderByOrderId.userByDriverId1.fullNameJa
                    return ({
                        transportDate: tempDate,
                        driverId1: tempDriverId
                    })
                })

                this.setState({
                    newRow: newData
                })
            }
        }
    }

    render() {
        let {
            columns,
            fetchQuery,
            allKey,
            dataMapping,
            searchHeader,
            actionHeader,
            perPage,
            type,
            methods,
            removeType,
            th = 528,
            minWidth,
            rowClassName,
        } = this.props.config;
        let { pagination = true, variable, multiple = true } = this.props
        let hasVariable = variable ? variable : {}
        let page = this.state.currentPage - 1;
        let filter = {
            first: perPage,
            offset: page * perPage,
            ...hasVariable
        };

        const customerById = this.props.getCustomerById && !this.isAdmin() ? this.props.getCustomerById.customerById : null;
        return (
            <Query
                query={fetchQuery}
                fetchPolicy='network-only'
                variables={this.state.filters}
                skip={!filter}
            >
                {({ data, loading, fetchMore, error }) => {
                    if (this.props.listPoRLoading) {
                        loading = false
                    }
                    this.data = data
                    let rows = [];
                    let total = 0;
                    /** Check for loading **/
                    if (error) {
                        return `Error! ${error.message}`;
                    }
                    if (loading) {
                        if (document.getElementsByClassName("ant-calendar-picker-input")[0]) {
                            let date = document.getElementsByClassName("ant-calendar-picker-input")[0].value
                            localStorage.setItem('date_filter', date);
                        }
                    }

                    if (!loading) {
                        let mappedData;
                        mappedData = data;
                        // if (this.state.filteredData){
                        //     mappedData = this.state.filteredData;
                        // }

                        if (this.props.contact) {
                            let filteredData = mappedData[allKey].nodes.filter((item) => {
                                if (item.ordersTransportsByOrderId.nodes.length) {
                                    return true;
                                }
                                return false;
                            });
                            mappedData[allKey].nodes = filteredData;
                            mappedData[allKey].totalCount = filteredData.length;
                        }

                        if (this.props.config.orderDispatchList) {
                            if (this.data[allKey].nodes.length) {
                                mappedData[allKey].nodes = _.orderBy(
                                    this.data[allKey].nodes,
                                    [
                                        'dispatchStatus',
                                        'vehicleByVehicleId.vehicleType',
                                        'vehicleByVehicleId.id',
                                        order => {
                                            const freightTransport = order.ordersTransportsByOrderId.nodes;
                                            let hour = freightTransport.length &&
                                                Object.values(freightTransport)[0].pickupHour
                                            let minute = freightTransport.length &&
                                                Object.values(freightTransport)[0].pickupMinute
                                            switch (freightTransport.length && freightTransport[0].pickupOption) {
                                                case 'A':
                                                    hour = 0;
                                                    minute = 0;
                                                    break;
                                                case 'C':
                                                    hour = 11;
                                                    minute = 59;
                                                    break;
                                                case 'D':
                                                    hour = 12;
                                                    minute = 1;
                                                    break;
                                                case 'E':
                                                    hour = 0;
                                                    minute = 1;
                                                    break;
                                                case 'F':
                                                    hour = 20;
                                                    minute = 0;
                                                    break;
                                                default:
                                                    break;
                                            }
                                            return new moment(`${hour}:${minute}`, "hh:mm").format("HH:mm");
                                        },
                                        order => {
                                            const freightTransport = order.ordersTransportsByOrderId.nodes;
                                            let hour = freightTransport.length &&
                                                Object.values(freightTransport)[0].dropoffHourFrom
                                            let minute = freightTransport.length &&
                                                Object.values(freightTransport)[0].dropoffMinuteFrom
                                            switch (freightTransport.length && freightTransport[0].dropoffOption) {
                                                case 'A':
                                                    hour = 0;
                                                    minute = 0;
                                                    break;
                                                case 'C':
                                                    hour = 11;
                                                    minute = 59;
                                                    break;
                                                case 'D':
                                                    hour = 12;
                                                    minute = 1;
                                                    break;
                                                case 'E':
                                                    hour = 0;
                                                    minute = 1;
                                                    break;
                                                case 'F':
                                                    hour = 20;
                                                    minute = 0;
                                                    break;
                                                default:
                                                    break;
                                            }
                                            return new moment(`${hour}:${minute}`, "hh:mm").format("HH:mm");
                                        }
                                    ],
                                    ['asc', 'asc', 'asc', 'asc', 'asc']
                                );
                            }

                            let search_date = localStorage.date_filter
                            let dateCompare = moment(search_date, "YYYY/MM/DD").add(1, 'days').format("YYYY-MM-DD");
                            for (let x = 0; mappedData[allKey].nodes.length > x; x++) {
                                let date = mappedData[allKey].nodes[x].transportDate
                                if (dateCompare === date) {
                                    mappedData[allKey].nodes[x].truck = true;
                                } else {
                                    mappedData[allKey].nodes[x].truck = false;
                                }
                            }
                            for (let x = 1; mappedData[allKey].nodes.length > x; x++) {
                                mappedData[allKey].nodes[x - 1].bordered = false;
                                if (mappedData[allKey].nodes[x].vehicleId && mappedData[allKey].nodes[x - 1].vehicleId) {
                                    let dataCurrent = mappedData[allKey].nodes[x];
                                    let dataBefore = mappedData[allKey].nodes[x - 1];
                                    let vechicleTypeIdBefore = dataBefore.vehicleByVehicleId.vehicleType
                                    let vechicleTypeIdCurrent = dataCurrent.vehicleByVehicleId.vehicleType
                                    let dateCurrent = dataCurrent.transportDate
                                    let dateBefore = dataBefore.transportDate
                                    if (dateCurrent !== dateBefore) {
                                        mappedData[allKey].nodes[x - 1].bordered = true;
                                    }
                                    if (vechicleTypeIdBefore !== vechicleTypeIdCurrent) {
                                        mappedData[allKey].nodes[x - 1].bordered = true;
                                    }
                                    if (mappedData[allKey].nodes[x].dispatchStatus !== mappedData[allKey].nodes[x - 1].dispatchStatus) {
                                        mappedData[allKey].nodes[x - 1].bordered = true;
                                    }
                                } else {
                                    if (mappedData[allKey].nodes[x].transportDate !== mappedData[allKey].nodes[x - 1].transportDate) {
                                        mappedData[allKey].nodes[x - 1].bordered = true;
                                    }
                                    else {
                                        mappedData[allKey].nodes[x - 1].bordered = false;
                                    }
                                    if (mappedData[allKey].nodes[x].dispatchStatus !== mappedData[allKey].nodes[x - 1].dispatchStatus) {
                                        mappedData[allKey].nodes[x - 1].bordered = true;
                                    }
                                }
                            }
                        }

                        rows = mappedData[allKey] && mappedData[allKey].nodes.map((item, key) => {
                            let data = dataMapping(item);
                            data.key = key;
                            return data;
                        })
                        total = data[allKey] && data[allKey].totalCount;
                    }
                    /** check for a search header **/
                    let search = searchHeader ? searchHeader({
                        loading,
                        handleSearch: this.handleSearch(fetchMore),
                        setState: this.setState.bind(this),
                        state: this.state,
                        form: this.props.form,
                        customerById,
                        clearRows: this.clearRows.bind(this),
                        selectedRows: this.state.selectedRows,
                        data: rows,
                        customerSelected: this.props.customerSelected
                    }) : null;
                    /** check for action **/
                    let action = actionHeader ? actionHeader.bind(this)({
                        methods: methods,
                        form: this.props.form,
                        setState: this.setState.bind(this),
                        state: this.state,
                        clearRows: this.clearRows.bind(this),
                        selectedRows: this.state.selectedRows
                    }) : null;
                    let paginate = pagination ? {
                        pagination: {
                            total,
                            pageSize: perPage,
                            current: this.state.currentPage,
                            position: 'top',
                            showTotal: (total, range) => `検索結果　${total} 件中：${range[0]} - ${range[1]} 件を表示しています。`

                        }
                    } : { pagination: false }


                    return (
                        <Row type='flex' className='h100' justify='space-around'>
                            <Col span={24}>
                                {search}
                                {action}
                                <CustomTable data={this.props.config.listpor ? this.state.newRow : rows}
                                    tableLayout='auto'
                                    multiple={multiple}
                                    bordered={this.props.bordered}
                                    type={type}
                                    removeType={removeType}
                                    th={th}
                                    didUpdate={this.state.didUpdate}
                                    loading={loading}
                                    columns={columns}
                                    clearSelected={this.state.clear}
                                    selectedRowKeys={this.state.selectedRows}
                                    clearRows={this.clearRows}
                                    rowClassName={rowClassName}
                                    minWidth={minWidth}
                                    total={total}
                                    onSelection={this.handleOnSelection}
                                    handleTableChange={this.handleTableChange(fetchMore)}
                                    selectedRows={this.handleRows}
                                    {...paginate} />
                            </Col>
                        </Row>
                    );
                }}
            </Query>
        )
    }
}

const options = () => {
    const token = localStorage.getItem('token');
    const decodeToken = JSON.parse(atob(token.split('.')[1]));
    return {
        variables: {
            id: decodeToken.user_id
        }
    }
}

const withCustomerInfo = compose(
    graphql(getCustomerById, {
        name: 'getCustomerById',
        options
    })
);

export default withCustomerInfo(withApollo(Form.create()(ListBase)));