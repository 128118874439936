import { Button, Col, Form, Row } from 'antd';
import React, { useContext, useEffect, useRef } from 'react';
import { GlobalContext, OrderContext } from '../../../../utils/context';
import { tabTypes } from '../../constants';

const TabView = () => {
  const { savedTab, globalSetter } = useContext(GlobalContext);
  const { view, orderSetter, isDriver } = useContext(OrderContext);
  const tabRef = useRef();
  const isView = (value) => view === value ? styles.active : styles.default;

  const onTabClick = (value) => () => {
    if (value !== view) {
      orderSetter({ pageLoading: true })
    }
    tabRef.current = value;
    orderSetter({ view: value, editMode: false });
  };

  useEffect(() => {
    (async () => {
      if (savedTab) {
        tabRef.current = savedTab;
        onTabClick(savedTab);
      }
    })();

    return () => {
      globalSetter({ savedTab: tabRef.current });
    }
  }, []);

  return (
    <Col style={{ flex: 1 }}>
      <Row type='flex' style={{ alignItems: 'center' }}>
        {!isDriver && <Button
          tabIndex='-1'
          style={isView(tabTypes.ORDERS)}
          onClick={onTabClick(tabTypes.ORDERS)}
        >
          受注
        </Button>}
        <Button
          tabIndex='-1'
          style={isView(tabTypes.DISPATCH)}
          onClick={onTabClick(tabTypes.DISPATCH)}
        >
          配車
        </Button>
        <Button
          tabIndex='-1'
          style={isView(tabTypes.PLANNED)}
          onClick={onTabClick(tabTypes.PLANNED)}
        >
          受注予定
        </Button>
        {!isDriver && <>
          <Button
            tabIndex='-1'
            style={isView(tabTypes.CONNECTED)}
            onClick={onTabClick(tabTypes.CONNECTED)}
          >
            連結
          </Button>
          <Button
            tabIndex='-1'
            style={isView(tabTypes.DELETED)}
            onClick={onTabClick(tabTypes.DELETED)}
          >
            削除データ
          </Button>
        </>
        }
      </Row>
    </Col>
  )
};

const styles = {
  default: {
    width: 100,
    marginRight: 2,
    borderWidth: 0,
    borderBottomWidth: 3,
    borderRadius: 0,
    fontSize: 12,
    borderBottomColor: '#E0E0E0'
  },
  active: {
    width: 100,
    marginRight: 2,
    borderWidth: 0,
    fontSize: 12,
    fontWeight: 'bold',
    backgroundColor: '#FAFAFA',
    borderBottomWidth: 3,
    borderRadius: 0,
    borderBottomColor: '#1890FF'
  },
  filter: {
    marginLeft: 10,
    borderWidth: 0,
    fontSize: 12,
    backgroundColor: '#F7F7F7'
  }
}
export default Form.create()(TabView);