import { createContext } from 'react';

export const initialState = {
    filters: {},
    mode: 'add',
    weight: ''
};

export const EstimateContext = createContext();

export const reducer = (state, action) => {
    switch(action.type) {
        case 'filters' :
            return {
            	...state,
            	filters: {...state.filters, ...action.payload}
            };
        case 'mode':
            return {
                ...state,
                mode: action.mode
            }
        case 'weight':
            return {
                ...state,
                weight: action.weight
            }
        default:
            return state;
    }
};
