import data from './translate';

export default (Page) => {
    return (field) => {
        if(!data[Page]) {
            return field;
        }

        // Default this to JP for now
        // return data[Page][field] && process.env.REACT_APP_LN === 'jp'
        //     ? data[Page][field] : field;
        return data[Page][field] ? data[Page][field] : field;
    };
}