import CustomButton from "./CustomButton";
import React, {Component, Fragment} from 'react';
import {Form,notification} from 'antd';
import CustomInput from "./CustomInput";
import CustomHiddenInput from "./CustomHiddenInput";

// This is for saving the name with the blob
const delimeter = '#X#X#X#X#X#X#';

class CustomFile extends Component {

    openNotificationWithIcon = (type, title) => {
        notification[type]({
          message: title,
          description: '',
        });
      };

    onFileChange = (form, fieldName) => {
        return (event) => {
            let file = event.target.files[0];
            if (file){
                if ((file.size/1000) >= 3000){
                    return this.openNotificationWithIcon('error','3MBを超えるファイルは選択できません。3MB以下のファイルを使用してください。');
                }else {
                    const fileReader = new FileReader();
                    fileReader.onloadend = function (e) {
                        let blob = e.target.result;
                        let data = {};
                        data[fieldName] = `${file.name}${delimeter}` + blob;
                        data[`${fieldName}_display`] = file.name;
                        form.setFieldsValue(data);
                    };
                    fileReader.readAsDataURL(file);
                }
            }else {
                //assume cancel was pressed
                let data = {};
                data[fieldName] = '';
                data[`${fieldName}_display`] = '';
                document.getElementById('fileInput').value = '';
                form.setFieldsValue(data); 
            }
        };
    };

    triggerUpload = () => {
        this.fileInput.click();
    };

    downloadFile = (pdfHidden, form, fieldName) => {
        return () => {
            if (form.getFieldValue(fieldName || pdfHidden)) {
                let data = form.getFieldValue(fieldName).split(delimeter);
                let uri = data[1].replace(/^data:image\/[^;]+/, 'data:application/octet-stream');
                let filename = data[0];
                var link = document.createElement('a');


                if (typeof link.download === 'string') {
                    link.href = uri;
                    link.download= filename;

                    //Firefox requires the link to be in the body
                    document.body.appendChild(link);
                    
                    //simulate click
                    link.click();

                    //remove the link when done
                    document.body.removeChild(link);
                } else {
                    window.open(uri)
                }
                // return window.open(data[1].replace(/^data:image\/[^;]+/, 'data:application/octet-stream'));
            }
        }
    };

    deleteFile = (form, fieldName) => {
        return () => {
            let data = {};
            data[fieldName] = '';
            data[`${fieldName}_display`] = '';
            document.getElementById('fileInput').value = '';
            form.setFieldsValue(data);
        };
    };

    render() {
        let {
            form,
            fieldName,
            mode,
            label,
            label1,
            label2,
            label3,
            placeholder,
            width,
            decorator
        } = this.props;
        let pdf;
        let pdfHidden;
        if (decorator.initialValue) {
            let data = decorator.initialValue.split(delimeter);
            pdf = {
                initialValue: data[0]
            };
            pdfHidden = {
                initialValue: decorator.initialValue
            };
        }
        return (
            <Fragment>
                <CustomHiddenInput
                    fieldName={fieldName}
                    form={form}
                    decorator={pdfHidden}
                />
                <Form.Item>
                    <CustomInput
                        fieldName={`${fieldName}_display`}
                        label={label}
                        type='text'
                        form={form}
                        mode={mode}
                        placeholder={placeholder}
                        decorator={pdf}
                        width={7}
                        rowField={false}
                        labelWidth={'16.66666667%'}
                        style={{width: '19.16666667%', marginRight: 10}}
                    />
                    <CustomButton label={label1} mode={mode} onClick={this.triggerUpload} style={{margin:"0px 10px"}}/>
                    <CustomButton label={label2} mode={mode} onClick={this.deleteFile(form, fieldName)} style={{margin:"0 10px"}}/>
                    <CustomButton label={label3} mode={mode === 'add'? 'addUser' : mode} onClick={this.downloadFile(pdfHidden, form, fieldName)} style={{margin:"0 10px"}}/>
                </Form.Item>
                <input type="file"
                       id = "fileInput"
                       ref={input => this.fileInput = input}
                       style={{
                           position: 'absolute',
                           top: '-100px'
                       }}
                    //    accept="application/pdf, image/*"
                       accept=".jpg, .jpeg, .bmp, .png"
                       onChange={this.onFileChange(form, fieldName)}
                       width={width}/>
            </Fragment>
        );
    }
}

export default CustomFile;
