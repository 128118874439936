import React, {Fragment, useRef} from 'react';
import {Select, Form} from 'antd';
import uuid from 'uuid/v4';

const Option = Select.Option;

const CustomSelect = ({
                        label,
                        width = 5,
                        half = false,
                        oneFourth = false,
                        oneThird = false,
                        small = false,
                        className,
                        allowClear = false,
                        options,
                        rowField = true,
                        optionId = 'id',
                        optionValue = 'value',
                        postLabel,
                        style,
                        classOption = false,
                        offset = 0,
                        mode,
                        labelWidth = '16.67%',
                        form: {getFieldDecorator},
                        fieldName,
                        decorator= {},
                        keyUuid = uuid(),
                        onChange,
                        inlineForm = false,
                        onSelect,
                        placeholder,
                        defaultValue
                    }) => {
    const formItemLayout = {    
       
        labelCol: {  //for label width
            xs: {span: 24},
            sm: {span: 8},
            md: {span: 4},
            lg: {span: labelWidth},
        },
        wrapperCol: { //for input width
            xs: {span: 24},
            sm: {span: 24},
            md: {span: width, offset},
        },
    };
    let mainStyle = {display: 'inline-block'};
    if (half) {
        mainStyle.width = '50%';
    } else if (oneFourth) {
        mainStyle.width = '25%';
    } else if (oneThird) {
        mainStyle.width = '33.3%';
    } else if (small) {
        mainStyle.width = '15%';
    } else mainStyle = null;

    const renderOptions = options.map((item, i) =>  (<Option {...i === 0 && classOption ? {className: "optionfirst"} : {}} key={keyUuid} value={item[optionId]}>{item[optionValue]}</Option>));
    let newRef = useRef(null);
    
    return (

        <Fragment>
            {rowField ? (
                <Form.Item
                    {...formItemLayout}
                    label={label}
                    style={mainStyle}
                    hasFeedback
                    className={className}
                >
                {getFieldDecorator(fieldName, decorator)(
                    <Select
                    
                    placeholder={placeholder}
                    disabled={mode === 'delete' ? true : false}
                    onChange={onChange}
                    allowClear={allowClear}
                    onSelect={onSelect}
                    
                >{renderOptions}</Select>
                )}
                {postLabel && <label htmlFor={fieldName}>{postLabel}</label>}
            </Form.Item>
            ): ( inlineForm ?
                <>
                <Form.Item
                    style={style}
                    label={label}
                    hasFeedback
                >
                    {getFieldDecorator(fieldName, decorator)(
                        <Select
                        ref={newRef}
                        onChange={onChange}
                        allowClear={allowClear}
                        onSelect={onSelect}
                        placeholder={placeholder}
                        disabled={mode === 'delete' ? true : false}
                    >{renderOptions}</Select>
                    )}
                    
                </Form.Item>
                {postLabel && <label htmlFor={fieldName} style={{display: 'inline-block', marginRight: 20}}>{postLabel}</label>}</> : <span className={`${className} ant-form-item`}>
                    {label && <label htmlFor={fieldName} style={{width: labelWidth, display: 'inline-block'}}>{label}</label>}
                    {getFieldDecorator(fieldName, decorator) (
                        <Select
                            ref={newRef}
                            style={style}
                            onChange={onChange}
                            allowClear={allowClear}
                            onSelect={onSelect}
                            placeholder={placeholder}
                            disabled={mode === 'delete' ? true : false}
                        >{renderOptions}</Select>
                    )}
                    {postLabel && <label htmlFor={fieldName} style={{marginRight:10}}> {postLabel}</label>}
                </span>
            )}
        </Fragment>
    );
}

export default CustomSelect
