import React, {Fragment} from 'react';
import moment from 'moment';
import CustomSelect from '../CustomDataEntry/CustomSelect';

export default (props)=> {
    let startDate = moment('2014');
    let endDate = moment();
    let selectYear = [];
    
    while(startDate.isBefore(endDate)) { 
        selectYear.push({
            id:startDate.format("YYYY"),
            value:startDate.format("YYYY")
        });
        startDate = startDate.add(1,'year');
    }

    return (<Fragment>
        <CustomSelect
            {...props}
            options={selectYear}
        />
    </Fragment>)
};