
import React, { Fragment } from 'react';
import { Checkbox } from 'antd';

export default (props) => {
	const { label, mode, fieldName, form: {getFieldDecorator} } = props
	return (
		<Fragment>
			{getFieldDecorator(fieldName)(
				<Checkbox disabled={mode === 'delete' ? true : false} > {label} </Checkbox>
			)}
		</Fragment>
	)
}


