import { AutoComplete, Button, Form, Icon, Input, Row, Typography } from 'antd';
import _ from 'lodash';
import moment from 'moment';
import React, { useCallback, useContext, useState } from 'react';
import { FormContext, OrderContext } from '../../../../utils/context';
import helper from '../../../../utils/helper';
import { timeTypes } from '../../../../utils/types';
import { maxTypes, tabTypes } from '../../constants';
import TimeModal from './modal/time-modal';
import LocationInput from './location.js';
import { toFullWidth } from './functions';
import AssigneeModal from './modal/assignee-modal';

let packagingDataSource = []
let customerDataSource = []

const initialState = { visible: false, field: false, id: null, index: null };
const initialTransport = {
  origin: 'empty',
  destination: 'empty',
  destinationTime: 'empty',
  originTime: 'empty',
};

const actualTypes = {
  'originTime': 'pickupActualDatetime',
  'destinationTime': 'dropoffActualDatetime'
};

const colorType = {
  1: '#000', // 菅原隆太
  13: 'green' // 馬場基博
}
const isOptionValue = (value) => ['AM', 'PM', 'DAY_TIME', 'EVENING'].indexOf(value) >= 0 ? timeTypes[value] : null;

const CellWrap = (props) => {
  let { children, editable, minimized, field, record, ...restProps } = props;
  const { date: selectedDate, packagingList, customerList, editMode, view } = useContext(OrderContext);
  const { form, form: { getFieldDecorator, resetFields } } = useContext(FormContext);
  const [moreInfoState, setMoreInfoState] = useState({
    visible: false,
    data: null,
    type: null
  })
  const moreInfoSetter = (value) => setMoreInfoState(state => ({ ...state, ...value }));

  const [state, setState] = useState({
    visible: false,
    field: false,
    data: null,
    id: null,
    index: null,
    toggle: false,
  });

  const stateSetter = (value) => setState(state => ({ ...state, ...value }));

  const isDispatch = (value) => (value && view === tabTypes.DISPATCH);

  const recordSetter = (key, index, field) => (event) => {
    let newReq = _.cloneDeep(record);
    const { value } = event.target;
    switch (key) {
      case 'customer':
        if (customerList[value]) {
          record.customerId = customerList[value];
          record.customer = value;
          if (newReq.customer !== value) {
            record.editList = _.union(record.editList, ['customer']);
          }
        };
        break;
      case 'packaging':
        if (index) {
          record.packaging2 = value;
          record.packagingId2 = value;
        } else if (packagingList[value]) {
          record.packaging1 = value;
          record.packagingId1 = packagingList[value];
        }
        if (newReq.packaging2 !== value || newReq.packaging1 !== value) {
          record.editList = _.union(record.editList, ['packaging']);
        }
        break;
      case 'transports':
        if (record.vehicleUnused) {
          newReq.transports[index][field] = value;
          record.transports = [...newReq.transports]
        } else {
          record.transports[index][field] = value;
        }
        record.editList = _.union(record.editList, ['transports']);
        break;
      case 'remarks':
        record.remarks = value;
        if (newReq.remarks !== value) {
          record.editList = _.union(record.editList, ['remarks']);
        }
        break;
      default:
    }
    record.edited = true;
  };

  const onPlusClick = (key, index, field) => (e) => {
    e.preventDefault();
    e.stopPropagation();
    let newReq = _.cloneDeep(record);
    switch (key) {
      case 'packaging':
        record.packaging2 = '';
        record.editList = _.union(record.editList, ['packaging']);
        break;
      case 'transports':
        const nextTransport = newReq.transports[index + 1];
        if (!nextTransport || (nextTransport && nextTransport[field] === 'empty')) {
          if (!nextTransport) newReq.transports[index + 1] = { ...initialTransport };
          newReq.transports[index + 1][field] = '';
          record.transports = [...newReq.transports]
        } else {
          if (!newReq.transports[index + 2]) newReq.transports[index + 2] = { ...initialTransport };
          newReq.transports[index + 2][field] = '';
          record.transports = [...newReq.transports]
        }
        record.editList = _.union(record.editList, ['transports']);
        break;
      default:
    }

    record.edited = true;
    stateSetter({ toggle: !state.toggle });
  };

  const onCloseClick = (key, index, field, fieldName, id) => (e) => {
    e.preventDefault();
    e.stopPropagation();
    switch (key) {
      case 'packaging':
        if (index) {
          record.packaging2 = null;
        } else {
          record.packaging1 = record.packaging2;
          record.packagingId1 = packagingList[record.packaging2] || null;
          record.packaging2 = null;
        }
        record.editList = _.union(record.editList, ['packaging']);
        break;
      case 'transports':
        _.each(record.transports, (transport, dataIndex) => {
          if (dataIndex >= index) {
            if (record.transports[dataIndex + 1]) {
              transport[field] = record.transports[dataIndex + 1][field];
            } else {
              record.transports[dataIndex][field] = 'empty';
            }
          };
        });
        record.editList = _.union(record.editList, ['transports']);
        break;
      default:
    }
    record.edited = true;
    stateSetter({ toggle: !state.toggle });
    if (key === 'transports' && index === 0) {
      const fieldName = `${id}|${key}|${field}|1`;
      resetFields(fieldName);
    }
    resetFields(fieldName);
  };

  const validatePackaging = (rule, value, callback) => {
    if (!packagingList[value]) callback('荷姿は存在しません');
    callback();
  };

  const validateCustomer = (rule, value, callback) => {
    if (!customerList[value]) callback('顧客名は存在しません');
    callback();
  };
  const onTimeClick = (index, data, field) => () => stateSetter({ index, data, field, visible: true, id: record.id });

  const onModalOk = (data) => {
    // edit setter here
    let { transports, vehicleUnused } = record;
    const { field, index, toggle } = state;

    if (vehicleUnused) {
      let newTransports = _.cloneDeep(transports);
      if (field === 'originTime') {
        newTransports[index]['pickupOption'] = data.option;
        newTransports[index]['pickupDate'] = data.date;
        // newTransports[index][field] = data.time || isOptionValue(data.option);
        newTransports[index][field] = !data.time_from && !data.time_to ? isOptionValue(data.option) : `${data.time_from || ''}〜${data.time_to || ''}`;
        record.transports = [...newTransports]
      } else {
        newTransports[index]['dropoffOption'] = data.option;
        newTransports[index]['dropoffDate'] = data.date;
        newTransports[index][field] = !data.time_from && !data.time_to ? isOptionValue(data.option) : `${data.time_from || ''}〜${data.time_to || ''}`;
        record.transports = [...newTransports]
      }
    } else {
      if (field === 'originTime') {
        transports[index]['pickupOption'] = data.option;
        transports[index]['pickupDate'] = data.date;
        transports[index][field] = !data.time_from && !data.time_to ? isOptionValue(data.option) : `${data.time_from || ''}〜${data.time_to || ''}`;
        // transports[index][field] = data.time || isOptionValue(data.option);
      } else {
        transports[index]['dropoffOption'] = data.option;
        transports[index]['dropoffDate'] = data.date;
        transports[index][field] = !data.time_from && !data.time_to ? isOptionValue(data.option) : `${data.time_from || ''}〜${data.time_to || ''}`;
      }
    }
    record.editList = _.union(record.editList, ['transports']);


    record.edited = true;
    setState({ ...initialState, toggle: !toggle });
  };

  const onModalCancel = () => setState(initialState);

  const onAutoCompleteSearch = key => text => {
    let list = {}

    switch (key) {
      case 'packaging':
        list = packagingList
        break;
      case 'customer':
        list = customerList
        break
      default:
        list = {}
        break;
    }

    let filtered = Object.keys(list).filter(data => helper.sanitizeTextValue(data).indexOf(helper.sanitizeTextValue(text)) >= 0)
    if (!text) filtered = []

    switch (key) {
      case 'packaging':
        packagingDataSource = filtered
        break;
      case 'customer':
        customerDataSource = filtered
        break
      default:
        break;
    }
  }

  const renderAutocompleteOption = dataKey => {
    const dataSource = dataKey === 'customer' ? customerDataSource : packagingDataSource

    return dataSource.map((data, index) => {
      return (
        <AutoComplete.Option key={index} value={data}>
          {data}
        </AutoComplete.Option>
      )
    })
  }

  const EditableForm = ({ mappedData, dataKey, length, field, isTime = false }) => {
    const dataMapped = mappedData.length ? mappedData : [null];
    const { id } = record;
    const dataLen = length || dataMapped.length;
    return (
      <>
        {dataMapped.map((data, index) => {
          if ((data || {})[field] === 'empty') return null;
          const fieldName = `${id}|${dataKey}|${field}|${index}`;
          return (
            <Row className={isTime ? 'is-time-row' : 'is-normal-row'} key={index}>
              {dataKey === 'transports' && !isTime ? (
                <LocationInput
                  form={form}
                  fieldName={fieldName}
                  initialValue={field ? ((data || {})[field] || null) : (data || null)}
                  type={field}
                  onBlur={(value) => {
                    const fullWidthValue = toFullWidth(value);
                    recordSetter(dataKey, index, field)({ target: { value: toFullWidth(fullWidthValue) } });
                  }}
                />
              ) :
                <Form.Item style={{ display: 'inline-block' }}>
                  {getFieldDecorator(fieldName, {
                    initialValue: field ? ((data || {})[field] || null) : (data || null),
                    rules: dataKey === 'packaging' && !index ? [{ validator: validatePackaging }] : []
                  })(
                    dataKey === 'packaging' ? !index ?
                      <AutoComplete
                        onChange={onAutoCompleteSearch('packaging')}
                        dataSource={renderAutocompleteOption('transports')}
                        onBlur={(value) => {
                          recordSetter(dataKey, index, field)({ target: { value } })
                        }}
                      /> : <Input onBlur={recordSetter(dataKey, index, field)} />
                      : <Input disabled={isTime} className={isTime ? 'date-input' : ''} onBlur={recordSetter(dataKey, index, field)} />
                  )}
                </Form.Item>
              }
              {isTime && <Row style={{ display: 'inline-block', maxWidth: 40 }}><Button tabIndex='-1' onClick={onTimeClick(index, data, field)} className='edit-btn-trigger'>設定</Button></Row>}
              <Row style={{ display: 'inline-block', maxWidth: 30 }}>
                {!!index || maxTypes[dataKey] === (dataLen) ?
                  <Icon onClick={onCloseClick(dataKey, index, field, fieldName, id)} className='scale' type='close-circle' theme='twoTone' style={styles.icon} twoToneColor='#E84855' /> :
                  <Icon onClick={onPlusClick(dataKey, index, field)} className='scale' type='plus-circle' theme='twoTone' style={styles.icon} />
                }
              </Row>
            </Row>
          )
        })
        }
      </>
    )
  };

  const convertDate = (value) => value ? moment(value).format('MM/DD HH:mm') : null;

  const fieldDateTypes = { originTime: 'pickupDate', destinationTime: 'dropoffDate' };
  const getDate = (record, transport, field) => {
    const dateTime = transport[fieldDateTypes[field]];
    const formattedDate = moment(selectedDate).format("YYYY-MM-DD")
    const formattedDateTime = moment(dateTime).format("YYYY-MM-DD")

    if (record.transportDate && moment(record.transportDate).isSame(formattedDateTime)) {
      return ''
    }

    if (!moment(formattedDate).isSame(formattedDateTime)) {
      return dateTime ? moment(dateTime).format('MM/DD') : '';
    }
    return ''
  };

  const renderInput = useCallback(() => {
    let element;
    const fieldName = `${record.id}|${field}`;

    const { transports } = record;

    const packages = _.filter([record.packaging1, record.packaging2], data => !_.isNull(data));

    switch (field) {
      case 'customer':
        element = editMode ? (
          <Form.Item>
            {getFieldDecorator(fieldName, {
              initialValue: record.customer && record.customer,
              rules: [{ validator: validateCustomer }]
            })(
              <AutoComplete
                onChange={onAutoCompleteSearch('customer')}
                dataSource={renderAutocompleteOption('customer')}
                onBlur={value => recordSetter('customer')({ target: { value } })}
              />
            )}
          </Form.Item>
        ) : <Typography style={{ color: colorType[record.userId] || 'red' }}>{record.customer}</Typography>
        break;
      case 'packaging':
        element = editMode ?
          <EditableForm mappedData={packages} dataKey='packaging' /> :
          <Typography>{(!packages[0] || !packages[1]) ? packages : packages.join("、")}</Typography>
        break;
      case 'origin':
      case 'destination':
        const locLen = _.filter(_.map(transports, transport => transport[field]), data => data !== 'empty');
        element = editMode ?
          <EditableForm mappedData={transports} length={locLen.length} dataKey='transports' field={field} /> :
          transports.map((transport, index) => (transport[field] === 'empty' ? null :
            <div key={index}>
              <div style={{ display: 'block' }}>
                <Typography style={{ display: 'inline-block' }} className={transport[`${field}Styles`]}>{transport[field]}
                  {field === 'origin' && ((transport || {}).ordersEscorts || []).length ?
                    <Typography style={{ fontSize: 14, fontWeight: 'normal', display: 'inline-block' }}>（{transport.ordersEscorts.join('、')}）</Typography> :
                    null}
                </Typography>
              </div>
              {isDispatch(transport[actualTypes[field]]) && (
                <Typography className='pink-label'>({convertDate(transport[actualTypes[field]])})</Typography>
              )}
            </div>
          ))
        break;
      case 'originTime':
      case 'destinationTime':
        const timeLen = _.filter(_.map(transports, transport => transport[field]), data => data !== 'empty');

        element = editMode ?
          <EditableForm mappedData={transports} length={timeLen.length} dataKey='transports' field={field} isTime={true} /> :
          transports.map((transport, index) => (transport[field] === 'empty' ? null :
            <div key={index}>
              <Typography className="test text-red">{`${getDate(record, transport, field)} ${transport[field] || ''}`}</Typography>
              {isDispatch(transport[actualTypes[field]]) && <Typography className='pink-label'>({convertDate(transport[actualTypes[field]])})</Typography>}
            </div>
          ))
        break;
      case 'remarks':
        element = editMode ? (
          <Form.Item>
            {getFieldDecorator(fieldName, {
              initialValue: record.remarks && record.remarks
            })(
              <Input
                className='table-input'
                onBlur={recordSetter('remarks')}
              />
            )}
          </Form.Item>
        ) : <Typography>{record.remarks}</Typography>
        break;
      case 'billingAmount':
        // element = <>{isDispatch(transport[actualTypes[field]]) && <Typography className='pink-label'>({convertDate(transport[actualTypes[field]])})</Typography>}</>
        element = (
          <>
            {record.hasConnection ? <>
              {record.isCompleted ? <>
                <>{record.billingAmount ? <Typography>{record.billingAmount}</Typography> : null}</>
                <>{record.escortFare ? <Typography className='orange-label-text'>{record.escortFare.toLocaleString()}円</Typography> : null}</>
                {!record.isFirst ? <>
                  <>{record.escortTotal ? <Typography className='pink-label'>{record.escortTotal.toLocaleString()}円</Typography> : null}</>
                  <>{record.totalBilling ? <Typography className='green-label'>{record.totalBilling.toLocaleString()}円</Typography> : null}</>
                </> : null}
              </> : null}
            </> : <>
              <Typography>{record.billingAmount}</Typography>
              <>{record.escortFare ? <Typography className='pink-label'>{record.escortFare.toLocaleString()}円</Typography> : null}</>
            </>}
          </>
        )
        break;
      case 'totalBilling':
        element = (
          <>
            {record.totalBilling ? <Typography>{record.totalBilling.toLocaleString()}円</Typography> : null}
          </>
        )
        break;
      case 'totalDistance':
        element = (
          <>
            {record.totalDistance ? <Typography>{record.totalDistance.toLocaleString()}km</Typography> : null}
          </>
        )
        break;
      case 'borrower':
        element = (<>
          {transports.map((transport, index) => transport.pickupBorrower && <Typography key={index}>発{index + 1}：{transport.pickupBorrower}</Typography>)}
          {transports.map((transport, index) => transport.dropoffBorrower && <Typography key={index}>着{index + 1}：{transport.dropoffBorrower}</Typography>)}
        </>)
        break;
      case 'assignee':
        const hasAssignee = _.some(transports, (data) => data.pickupAssignee || data.dropoffAssignee);
        element = (hasAssignee ?
          <Typography.Text onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            moreInfoSetter({ visible: true, transports, type: 'assignee' })
          }} style={styles.moreDetails}>詳細を表示</Typography.Text> :
          null);
        // element = (<Typography.Text>
        //   {transports.map((transport, index) => transport.pickupAssignee && <Typography key={index}>発{index + 1}：{transport.pickupAssignee.replace(/\|/g, '、')}</Typography>)}
        //   {transports.map((transport, index) => transport.dropoffAssignee && <Typography key={index}>着{index + 1}：{transport.dropoffAssignee.replace(/\|/g, '、')}</Typography>)}
        // </Typography.Text>)
        break;
      case 'contact':
        const hasContact = _.some(transports, (data) => data.pickupContact || data.dropoffContact);
        element = (hasContact ?
          <Typography.Text onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            moreInfoSetter({ visible: true, transports, type: 'contact' })
          }} style={styles.moreDetails}>詳細を表示</Typography.Text> :
          null);
        // element = (<>
        //   {transports.map((transport, index) => transport.pickupContact && <Typography key={index}>発{index + 1}：{transport.pickupContact.replace(/\|/g, '、')}</Typography>)}
        //   {transports.map((transport, index) => transport.dropoffContact && <Typography key={index}>着{index + 1}：{transport.dropoffContact.replace(/\|/g, '、')}</Typography>)}
        // </>)
        break;
      default:
        break;
    }

    return element;
  }, [record, editMode]);

  const closeInfoModal = () => moreInfoSetter({ visible: false, data: null, type: null });
  return (
    <td {...restProps}>
      {!minimized ? (
        editable ?
          renderInput() :
          children
      ) : <></>}
      {state.visible && (
        <TimeModal
          {...state}
          onOk={onModalOk}
          onCancel={onModalCancel}
          selectedDate={selectedDate}
          defaultDate={record.transportDate}
        />
      )}
      {moreInfoState.visible && (
        <AssigneeModal
          {...moreInfoState}
          onOk={closeInfoModal}
          onCancel={closeInfoModal}
        />
      )}
    </td>
  )
};

const styles = {
  icon: {
    fontSize: 18, marginLeft: 10, maxWidth: 30
  },
  moreDetails: { color: 'blue', textDecorationLine: 'underline', cursor: 'pointer', textAlign: 'center' }
}

export default CellWrap;