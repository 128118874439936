import React, {useContext, useEffect, Fragment} from "react";
import {
  Chart,
  Geom,
  Axis,
  Tooltip,
  Legend,
} from "bizcharts";
import moment from 'moment';
import { Spin } from 'antd';
import {Context} from '../../../commons/Context';
import DataSet from "@antv/data-set";
import _ from 'lodash';

const Graph = ({lt}) => {
  const {state, dispatch} = useContext(Context);
    let fields = _.chain(state.flatten).map(obj=>{
        return _.omit(obj,["week"]);
    }).map(value=>{
        return Object.keys(value);
    }).value();

    fields = fields.length ? Object.assign(...fields) : [];
    const {
      customerName, 
      transportDate_From_Year, 
      transportDate_From_Month, 
      transportDate_To_Year, 
      transportDate_To_Month
    } = state.filters;

    const sorted = state.flatten.sort((a,b) => (a.week > b.week) ? 1 : ((b.week > a.week) ? -1 : 0));
    
    let formatDate = sorted.map(user => {
        return {
            date: user.week + '週',
            ...user
        }
    });
    
    const ds = new DataSet();
    const dv = ds.createView().source(formatDate);
    dv.transform({
        type: "fold",
        fields,
        key: "magic",
        value: "total"
    });
    const cols = {
        date: {
            range: [0, 1]
        },
    };

    useEffect(() => {
      if(dv.rows.length && state.spinner){
        dispatch({
          type: 'spinner',
          spinner: false
        })
      }
    },[state.flatten]);

    return (
      <Fragment>
        <div className='head' style={{textAlign:'center', fontWeight: 'bold'}}>{lt('lbl_sales_by_driver_chart')}</div>
		    <div className='chartDate'>{customerName !== undefined && `${lt('lbl_analysis_year/month')}: ${moment().format('YYYY年MM月') }`}</div>
        <div className='chartDate'>{customerName !== undefined && `${lt('対象運転手')}: ${customerName}`}</div>
        <div className='chartDate' style={{marginBottom: 15}}>
          {customerName !== undefined && `${lt('出力年')}:  
          ${moment(`${transportDate_From_Year}-${transportDate_From_Month}`).format('YYYY年MM月') } ～ 
          ${moment(`${transportDate_To_Year}-${transportDate_To_Month}`).format('YYYY年MM月') }`}
        </div>
        <Spin spinning={state.spinner} size='large'>
          <Chart height={600} data={dv} scale={cols} forceFit>
            <Legend />
            <Axis 
              name="date"
              // label={{
              //   formatter: val => `${val}週`
              // }}
            />
            <Axis
              name="total"
              label={{
                formatter: val => `${val.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
              }}
            />
            <Tooltip
              crosshairs={{
                  type: "y"
              }}
              />
            <Geom
              type="line"
              position="date*total"
              size={2}
              color={"magic"}
              shape={"smooth"}

              />
            <Geom
              type="point"
              position="date*total"
              size={4}
              shape={"circle"}
              color={"magic"}
              style={{
                  stroke: "#fff",
                  lineWidth: 1
              }}
            />
          </Chart>
        </Spin>
      </Fragment>
    );
}

export default Graph

