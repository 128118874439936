import React, { Component, Fragment } from 'react';
import { Col, Alert, Form } from 'antd';
import styles from '../../assets/scss/dashboard.module.scss';

class ActionHeader extends Component {

  state = {
    isEnabled : false
  }

  handleEnabledChange = () => {
    this.setState({
      isEnabled : !this.state.isEnabled
    })
  }
  
  render(){
    const { alertMessage, leftActions, rightActions, customActions, handleSubmit } = this.props
    return (
      <Fragment>
        { alertMessage && <Alert message={alertMessage} type="error" className={styles.alert} /> }
        <Form className="clearfix actions-container" onSubmit={handleSubmit}>
          {leftActions && <Col span={10}> {leftActions} </Col> }
          {
            !customActions &&
            <Col span={24} 
              className={styles.buttonContainer} 
              style={!leftActions ? { float: 'right', overflow: 'hidden' , lineHeight:7} : null}
              >
            {rightActions}
            </Col>
          }
          {customActions}
        </Form>
      </Fragment>
    )
  }
}

export default ActionHeader


