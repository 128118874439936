import React, {useContext} from 'react';
import GraphBar from './GraphBar';
import GraphLine from './GraphLine';

import {Context} from '../../../commons/Context';

export default ({lt}) => {
    const {state} = useContext(Context);

    if (state.mode === 'week') {
        return <GraphLine lt={lt} />;
      }
    return <GraphBar lt={lt} />;
}