import React from 'react';
import { Form, Row, Col, Modal, notification } from 'antd';

/** Get All Common  **/
import {
	CustomButton,
	CustomMonthPicker,
	CustomRadioGroup
} from '../../commons/CustomDataEntry';

/** Get All Customer Queries **/
import {
	getById,
	getAll,
	create,
	updateById,
	deleteById
} from '../../queries/MasterMaintenance/customer';

import { GET_ORDERS_EXCEL, GET_ORDERS_CUSTOMERS_EXCEL, GET_ORDERS_EXCEL_OVERNIGHT } from '../Orders/queries';
import {
	getAllTaxes
} from '../../queries/Tax';


import styles from '../../assets/scss/dashboard.module.scss';
/** Get ADhoc translate **/
import adhocTranslator from '../../utils/locales/translate-adhoc';

import CustomerModalConfig from '../MasterMaintenance/Customer/ListModal';
import ModalBase from '../../commons/ModalBase';
import moment from 'moment';
import _ from 'lodash';

// const TabPane = Tabs.TabPane;

export default ({ mode }) => {
	let lt = adhocTranslator('K060-010');

	return {
		mode,
		/** query mapping **/
		data: {
			idRef: 'customerById'
		},
		queries: {
			GET_BY_ID: getById,
			GET_ALL: getAll,
			UPDATE_BY_ID: updateById,
			CREATE: create,
			DELETE_BY_ID: deleteById
		},
		component: function ({ form, data, handleSubmit, loading }) {

			let submitForm = () => {
				form.validateFields((err, values) => {
					if (!err) {
						const date1 = form.getFieldValue('transportDate_From');
						Modal.confirm({
							title: '確認',
							okText: 'はい',
							cancelText: 'いいえ',
							icon: 'false',
							content: `${date1.format("YYYY年MM月")} の請求書を作成しますか？`,
							onOk: async () => { printExcel() }
						})
					}
				});
			}

			const openNotificationWithIcon = (type, message) => {
				notification[type]({
					message: message
				});
			};

			let generateBillingDate = (dateValue, dateRangeMonth, dateRangeDay) => {
				var billingDate = moment(dateValue)
				if (dateRangeMonth < 0) {
					billingDate = billingDate.subtract(Math.abs(dateRangeMonth), "months")
				}

				if (dateRangeDay > billingDate.daysInMonth()) {
					dateRangeDay = billingDate.daysInMonth()
				}

				var billingDay = (dateRangeDay > 9) ? dateRangeDay : "0" + dateRangeDay;

				billingDate = billingDate.format("YYYY-MM-" + billingDay)
				return billingDate
			}

			const getOvernightOrders = async (orders, date1, date2, now, id) => {
				const response = await this.props.client.query({
					query: GET_ORDERS_EXCEL_OVERNIGHT,
					fetchPolicy: 'network-only',
					variables: {
						filter: {
							isDeleted: { equalTo: false },
							customerId: {
								equalTo: id,
							},
							dispatchStatus: {
								equalTo: "COMPLETED"
							},
							overnight: {
								equalTo: "YES"
							},
							ordersTransportsByOrderId: {
								some: {
									and: [
										{ dropoffDate: { greaterThanOrEqualTo: date1 } },
										{ dropoffDate: { lessThanOrEqualTo: date2 > now ? now : date2 } }
									]
								}
							},
							transportDate: {
								lessThan: date1
							}
						}
					}
				});

				return _.sortBy([...orders, ...response.data.allOrders.nodes], ['transportDate']);
			}

			let printExcel = async () => {
				const id = form.getFieldValue('customerId');
				const output = form.getFieldValue('output');

				if (output === 'customer_list') {
					// Use billingDateRange value as basis for transportDate input
					const date = moment(form.getFieldValue('transportDate_From'));
					const startDate = date.startOf('month').format('YYYY-MM-DD');
					const endDate = date.endOf('month').format('YYYY-MM-DD');
					const response = await this.props.client.query({
						query: GET_ORDERS_CUSTOMERS_EXCEL,
						fetchPolicy: 'network-only',
						variables: {
							filter: {
								isDeleted: { equalTo: false },
								dispatchStatus: {
									equalTo: "COMPLETED"
								},
								and: [
									{
										transportDate: {
											greaterThanOrEqualTo: startDate
										}
									},
									{
										transportDate: {
											lessThanOrEqualTo: endDate
										}
									}
								]
							}
						}
					});

					const ordersList = response.data.allOrders.nodes;
					if (ordersList.length) {
						const customerList = _.chain(ordersList)
							.filter(order => order.customerByCustomerId)
							.map((order, index) => ({ __typename: 'excelData', id: index + 1, name: (order.customerByCustomerId || {}).nameJa }))
							.compact()
							.uniqBy('name')
							.value()
						this.props.client.writeData({
							data: {
								excelOutput: {
									__typename: 'excelOutput',
									type: 'InvoiceCustomer',
									excelEstimateOutputData: [],
									excelOrdersOutputData: [],
									excelInvoiceOutputData: [],
									excelCrewPerformanceOutputData: [],
									excelDispatchOutputData: [],
									excelCustomersOutputData: [{ __typename: 'excelData', date: date.format('YYYYMM'), rows: customerList }]
								}
							}
						});
						openNotificationWithIcon('success', moment(form.getFieldValue('transportDate_From')).format("YYYY年MM月") + lt('success_message'));
					} else {
						openNotificationWithIcon('error', moment(form.getFieldValue('transportDate_From')).format("YYYY年MM月") + lt('error_message'));
					}
					return;
				}


				// Fetch Customer Data for billingDateRange info
				const { data: customer } = await this.props.client.query({
					query: getById,
					fetchPolicy: 'network-only',
					variables: {
						id: id
					}
				});
				const date1 = generateBillingDate(form.getFieldValue('transportDate_From'), customer.customerById.billingDateRangeMonthFrom, customer.customerById.billingDateRangeDayFrom)
				const date2 = generateBillingDate(form.getFieldValue('transportDate_From'), customer.customerById.billingDateRangeMonthTo, customer.customerById.billingDateRangeDayTo)
				const now = moment().format("YYYY-MM-DD");
				// Use billingDateRange value as basis for transportDate input
				const response = await this.props.client.query({
					query: GET_ORDERS_EXCEL,
					fetchPolicy: 'network-only',
					variables: {
						filter: {
							isDeleted: { equalTo: false },
							customerId: {
								equalTo: id,
							},
							dispatchStatus: {
								equalTo: "COMPLETED"
							},
							and: [
								{
									transportDate: {
										greaterThanOrEqualTo: date1
									}
								},
								{
									transportDate: {
										lessThanOrEqualTo: date2 > now ? now : date2
									}
								}
							]
						}
					}
				});
				let customerName = form.getFieldValue('customerId-display');

				let orders = response.data.allOrders.nodes;
				orders = await getOvernightOrders(orders, date1, date2, now, id);
				if (orders.length > 0) {
					let orderDestinations = [];
					// get current tax
					const { data: taxrate } = await this.props.client.query({
						fetchPolicy: 'network-only',
						query: getAllTaxes
					});
					const currentData = moment().valueOf();
					let taxes = _.filter(taxrate.allTaxes.nodes, (tax) => {
						let effectiveDate = moment(tax.effectiveDate).valueOf();
						return effectiveDate <= currentData
					});
					let taxRate = taxes.pop().taxRate / 100;

					// Sort Nodes by transportDate ascending
					const connectedIds = [];

					let allowedOrders = orders.filter(data => {
						if (data.overnight === 'YES') {
							const { ordersTransportsByOrderId } = data;
							const dropoffDate = _.max(ordersTransportsByOrderId.nodes.map(transport => transport.dropoffDate))
							if (moment(dropoffDate).isAfter(moment(date2))) {
								return false;
							}
						}
						const { ordersConnectedsByOrderId2, ordersConnectedsByOrderId1 } = data;
						if (ordersConnectedsByOrderId1.nodes.length || ordersConnectedsByOrderId2.nodes.length) {
							const [ordersConnected] = ordersConnectedsByOrderId1.nodes.length ? ordersConnectedsByOrderId1.nodes : ordersConnectedsByOrderId2.nodes;
							const { orderByOrderId1, orderByOrderId2 } = ordersConnected;
							const [order1, order2] = _.orderBy([orderByOrderId1, orderByOrderId2], 'transportDate', 'asc');
							if (order1.transportDate && order2.transportDate && moment(order2.transportDate).isAfter(moment(date2))) {
								return false;
							}
						}
						return true;
					});
					allowedOrders.map((order, index) => {
						const { ordersConnectedsByOrderId2, ordersConnectedsByOrderId1, ordersTransportsByOrderId } = order;
						const escortList = [];
						order.ordersTransportsByOrderId.nodes.forEach(transports => escortList.push(...transports.ordersEscortVehiclesByTransportId.nodes));
						const escortFare = _.sumBy(escortList, 'transportBillingAmount') || 0;

						// let subtotal = order.transportBillingAmount + order.escortVehicleFare;
						let subtotal = order.transportBillingAmount + escortFare;
						const nodes = order.ordersTransportsByOrderId.nodes;
						let notes = '';
						const org1 = [];
						const dest1 = [];
						const originDestination = nodes.map((detail, index) => {

							notes += detail.pickupBorrower ? detail.pickupBorrower + '、' : '';
							notes += detail.dropoffBorrower ? detail.dropoffBorrower + '、' : '';
							if (index >= nodes.length - 1) {
								notes = notes.slice(0, -1)
							}

							let originAreaName = detail.origin ? detail.origin : '';
							let destinationAreaName = detail.destination ? detail.destination : '';
							org1.push(originAreaName)
							dest1.push(destinationAreaName)
							return originAreaName + ' 〜 ' + destinationAreaName;
						});
						//notes = notes.length>0? (index + 1) + '行目の備考：' + notes:'';
						let dropOff = _.chain(order.ordersTransportsByOrderId.nodes).maxBy((obj) => {
							return obj.dropoffMileage;
						}).get('dropoffMileage', 0).value();

						let pickUp = _.chain(order.ordersTransportsByOrderId.nodes).minBy((obj) => {
							return obj.pickupMileage;
						}).get('pickupMileage', 0).value();

						const distance = dropOff - pickUp;
						let transportDate = moment(order.transportDate).format("MM/DD");
						if (order.overnight === 'YES') {
							const dropoff = _.max(ordersTransportsByOrderId.nodes.map(transport => transport.dropoffDate))
							transportDate = `${moment(order.transportDate).format("MM/DD")}〜${moment(dropoff).format("MM/DD")}`
						}
						const destination = {
							transportDate: transportDate,
							por: `${org1.join("<br/>")}|${dest1.join("<br/>")}`,
							packaging: _.compact([(order.packagingByPacking1 || {}).packagingName, order.packing2]).join(', '),
							distance: order.transportDistance,
							escortCount: escortList.length,
							escortFare: Math.round(escortFare),
							transportFare: Math.round(order.transportBillingAmount),
							amount: Math.round(subtotal),
							notes: _.compact([notes, order.notes]),
							__typename: 'excelData'
						};

						if (ordersConnectedsByOrderId1.nodes.length || ordersConnectedsByOrderId2.nodes.length) {
							const [ordersConnected] = ordersConnectedsByOrderId1.nodes.length ? ordersConnectedsByOrderId1.nodes : ordersConnectedsByOrderId2.nodes;
							const { orderByOrderId1, orderByOrderId2 } = ordersConnected;
							const [order1, order2] = _.orderBy([orderByOrderId1, orderByOrderId2], 'transportDate', 'asc');
							if (connectedIds.indexOf(order1.id) >= 0) {
								return;
							} else {
								connectedIds.push(order1.id, order2.id);
							}
							const org2 = [];
							const dest2 = [];

							const borrowers = [];
							order2.ordersTransportsByOrderId.nodes.forEach((transport) => {
								borrowers.push(transport.pickupBorrower);
								borrowers.push(transport.dropoffBorrower);
							})
							order1.ordersTransportsByOrderId.nodes.map((transport, index) => {

								let originAreaName = transport.origin ? transport.origin : '';
								let destinationAreaName = '';
								if (order2.ordersTransportsByOrderId.nodes[index]) {
									destinationAreaName = order2.ordersTransportsByOrderId.nodes[index].destination || ''
								}
								org2.push(originAreaName)
								dest2.push(destinationAreaName)
								return originAreaName + ' 〜 ' + destinationAreaName;
							});

							destination.notes = _.compact([...borrowers, order1.notes, order2.notes])
							destination.transportDate = `${moment(order1.transportDate).format("MM/DD")}〜${moment(order2.transportDate).format("MM/DD")}`
							destination.por = `${org2.join("<br/>")}|${dest2.join("<br/>")}`
							destination.distance = ordersConnected.totalDistance;
							destination.transportFare = Math.round(ordersConnected.totalBilling);
							// const escortList = [];
							const escortTotal = _.sumBy(order1.ordersEscortVehiclesByOrderId.nodes, 'transportBillingAmount') + _.sumBy(order2.ordersEscortVehiclesByOrderId.nodes, 'transportBillingAmount')
							destination.escortFare = Math.round(escortTotal);
							destination.amount = Math.round(ordersConnected.totalBilling + escortTotal);
						}
						return orderDestinations.push(destination);
					});

					const subtotal = Math.round(_.sumBy(orderDestinations, (destination) => {
						return destination.amount;
					}));
					const tax = Math.round(subtotal * taxRate);
					const totalamount = Math.round(subtotal * (1 + taxRate));

					const hasPackaging = _.every(orderDestinations, (data) => !!data.packaging);
					if (!hasPackaging) {
						return openNotificationWithIcon('error', '一部の注文にはパッケージがありません');
					}
					// orderDestinations
					const customer = orders[0];
					let orderData = {
						id: customer.id || null,
						customerName: customer.customerByCustomerId.nameJa,
						companyName: customer.customerByCustomerId.formOutputName,
						quotationAmount: '',
						validity: '',
						transportDate: moment(customer.transportDate).format("MM/DD"),
						rows: [...orderDestinations],
						subtotal,
						taxRate,
						tax,
						totalamount,
						__typename: 'excelData'
					}
					this.props.client.writeData({
						data: {
							excelOutput: {
								__typename: 'excelOutput',
								type: 'Invoice',
								excelEstimateOutputData: [],
								excelOrdersOutputData: [],
								excelInvoiceOutputData: [orderData],
								excelCrewPerformanceOutputData: [],
								excelDispatchOutputData: [],
								excelCustomersOutputData: []
							}
						}
					});

					openNotificationWithIcon('success', customerName + 'の' + moment(form.getFieldValue('transportDate_From')).format("YYYY年MM月") + lt('success_message'));

				} else {
					openNotificationWithIcon('error', customerName + 'の' + moment(form.getFieldValue('transportDate_From')).format("YYYY年MM月") + lt('error_message'));
				}

			};
			const output = [
				{ id: 'invoice_list', value: '請求書' },
				{ id: 'customer_list', value: '請求一覽' }
			];
			return (
				<Row type='flex' className='h100' justify='space-around' align='top'>
					<Col span={24}>
						<Form layout='vertical'>
							<CustomRadioGroup
								options={output}
								form={form}
								mode={mode}
								fieldName='output'
								decorator={{
									initialValue: 'invoice_list'
								}}
								offset={0}
								width={14}
								half={true}
								labelWidth={5}
								onChange={({ target: { value } }) => {
									if (value === 'customer_list') {
										form.setFieldsValue({
											customerId: null,
											'customerId-display': null
										})
									}
								}}
							/>
							{form.getFieldValue('output') !== 'customer_list' &&
								<div className='ant-row ant-form-item' style={{ marginBottom: 10 }}>
									<ModalBase
										title='顧客情報検索'
										form={form}
										label={lt('lbl_customer_name')}
										btnLabel={lt('btn_customer_select')}
										fieldName='customerId'
										offset={0}
										labelWidth={3}
										config={CustomerModalConfig()}
										decorator={{
											rules: [{
												required: true,
												message: lt('顧客名を選択してください。')
											}]
										}}
										filterStatus
									/>
								</div>
							}
							<CustomMonthPicker
								form={form}
								label={lt('lbl_billing_month')}
								placeholder='YYYY/MM'
								fieldName='transportDate_From'
								className={styles.datepicker}
								style={{ width: 100, display: 'inline-block', marginRight: 15 }}
								decorator={{
									rules: [{
										required: true,
										message: lt('請求年月を入力してください。')
									}]
								}}
								width={12}
								labelWidth={3} />
							<div style={{ textAlign: 'center' }}>
								<CustomButton label={lt('btn_create')}
									autoFocus={true}
									event={submitForm}
									loading={loading}
									style={{ marginTop: '15px', height: '40px', width: '104px' }}
									onClick={() => { submitForm() }}
									offset={12} />
							</div>
						</Form>
					</Col>
				</Row>
			);
		}
	}
}