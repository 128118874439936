import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { Form } from 'antd';
import { CustomMap } from '../../commons/CustomDataEntry';
import { connection } from '../../commons/SocketClient';

let selected = [];
const DispatchMap = ({ selectedRows, triggerUpdate, resetTrigger }) => {
  const [socket,] = useState(connection().socket);
  const [state, setState] = useState({
    trucks: {},
    selectedTrucks: [],
  });
  const stateSetter = (value) => setState(state => ({ ...state, ...value }));

  useEffect(() => {
    socket.emit('get-all-trucks');
    socket.on('all-truck-status', (trucks) => {
      // stateSetter({ trucks })
      updateSelectedTrucks(trucks);
    });

    socket.on('truck-status', (truckPositions) => {
      // console.log('Update Status', truckPositions)
      // If selected, show trucks to map
      let trucks = state.trucks;
      trucks[truckPositions.truckId] = truckPositions;
      updateSelectedTrucks(trucks);
    });

    return () => {
      socket.off('truck-status');
      socket.off('all-truck-status');
    }
  }, []);

  useEffect(() => {
    // remove unchecked
    selected = selectedRows;
  }, [selectedRows]);

  useEffect(() => {
    if (triggerUpdate) {
      // console.log('Emit', selectedRows);

      // Filter unchecked
      const vehicleNos = selected.map(data => data.vehicleNo);
      const truckList = state.selectedTrucks.filter(data => vehicleNos.indexOf(data.vehicleNo) >= 0);
      stateSetter({ selectedTrucks: truckList });

      socket.emit('get-trucks-status', selected);
      resetTrigger();
    };
  }, [triggerUpdate]);

  const updateSelectedTrucks = useCallback((trucks) => {
    const selectedTrucks = [];
    for (let key in trucks) {
      const truck = trucks[key];
      const truckDetails = selected.find((row) => row.id === truck.truckId);
      if (truckDetails) {
        selectedTrucks.push({
          label: truckDetails.vehicleNo,
          position: truck.data.position
        });
      }
    };

    stateSetter({ selectedTrucks, trucks });
  }, [selectedRows]);

  const rectangleIcon = {
    path: 'M-35 -10 H 35 V 10 H -35 Z',
    fillColor: '#fafafa',
    fillOpacity: 0.8,
    scale: 1,
    strokeColor: '#1890ff',
    strokeWeight: 2
  };
  // console.log('current states', state, selected);
  return (
    <Fragment>
      <CustomMap
        icon={rectangleIcon}
        position={{ lat: 38.2607821, lng: 140.8697266 }}
        placemarkers={state.selectedTrucks}
      />
    </Fragment>
  )
}

export default Form.create()(DispatchMap);