
export const formatTypes = {
  DATE: 'YYYY/MM/DD',
  DAY: 'DD',
  DATE_QUERY: 'YYYY-MM-DD',
  TIME: 'HH:mm'
}

export const schedTypes = [
  { key: 0, value: 'BLANK', label: '指定なし' },
  { key: 1, value: 'AM', label: 'AM' },
  { key: 2, value: 'PM', label: 'PM' },
  { key: 3, value: 'DAY_TIME', label: '日中' },
  { key: 4, value: 'EVENING', label: '夕方' },
  { key: 5, value: 'SPECIFIED_TIME', label: '時間指定' }
]

export const escortTypes = [
  '誘導１',
  '誘導２',
  '誘導３',
  '誘導４',
];

export const colorTypes = [
  { key: 0, value: 'RED', label: '赤色' },
  { key: 1, value: 'GREEN', label: '緑色' },
  { key: 2, value: 'BLACK', label: '黒色' },
]

export const fetchTypes = {
  CURRENT: 'current',
  FUTURE: 'future'
}

export const dispatchTypes = {
  COMPLETED: '配車',
  PENDING: '未配車',
  '配車': 'COMPLETED',
  '未配車': 'PENDING',
}

export const modeTypes = {
  ALL: 'all',
  ALL_REVERSE: 'reverse',
  ORIGIN: 'origin',
  DESTINATION: 'destination',
  COPY: 'copy',
  COPY_INVERSE: 'copy inverse',
  EDIT: 'edit',
}

export const vehicleTypes = {
  BLANK: 'BLANK',
  TRAILER: 'TRAILER',
  TRUCK_UNIC: 'TRUCK_UNIC_',
  TRUCK_SELF_LOADER: 'TRUCK_SELF_LOADER_',
  ESCORT_VEHICLE_COMPANY: 'ESCORT_VEHICLE_COMPANY_',
  ESCORT_VEHICLE_OUTSOURCE: 'ESCORT_VEHICLE_OUTSOURCE_',
  OUTSOURCE: 'OUTSOURCE'
}