import { Avatar, Breadcrumb, Icon, Layout, Menu, Modal } from 'antd';
import _ from 'lodash';
import React, { Component, Fragment } from 'react';
import { compose, graphql, withApollo } from 'react-apollo';
import { matchPath, withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import defaultUserImg from '../assets/img/default-user.png';
import styles from '../assets/scss/header.module.scss';
import { getCustomerQuery, getUserQuery } from '../queries/Users';
import { connection } from './SocketClient';
const { Header } = Layout;

class HeaderComponent extends Component {
    state = {
        collapsed: false,
        userName: '...'
    };

    componentDidMount() {
        this.displayUser()
    }

    displayUser = async () => {
        const token = localStorage.getItem('token');
        const decodeToken = JSON.parse(atob(token.split('.')[1]));
        let queryLogin = decodeToken.role === 'app_customer' ? getCustomerQuery : getUserQuery;
        const { data } = await this.props.client.query({
            query: queryLogin,
            fetchPolicy: 'network-only',
            variables: {
                id: decodeToken.user_id
            }
        });

        let userName = data.customerById ? data.customerById.nameJa : data.userById.fullNameJa

        this.setState({ userName });
    }

    toggleCollapsed = () => {
        this.setState({
            collapsed: !this.state.collapsed
        });
        this.props.toggle(!this.state.collapsed)
    };

    isCustomer = () => {
        const token = localStorage.getItem('token');
        if (token) {
            const decodeToken = JSON.parse(atob(token.split('.')[1]));
            if (decodeToken.role !== 'app_customer') {
                return false;
            }
        }
        return true;
    }

    handleLogout = (e) => {
        let redirect = '/login';
        e.preventDefault();
        Modal.confirm({
            centered: true,
            title: '確認',
            icon: 'false',
            content: 'ログアウトしますか？',
            okText: 'はい',
            cancelText: 'いいえ',
            className: 'modelStyle',
            onOk: () => {
                let { socket } = connection();
                if (socket) {
                    socket.disconnect();
                }

                if (this.isCustomer()) {
                    redirect = '/client'
                }

                // localStorage.removeItem('token');
                localStorage.removeItem('token')
                localStorage.removeItem('date_filter')
                localStorage.removeItem('notification')
                localStorage.removeItem('search_date')
                this.props.history.push(redirect);
            }
        });
    };

    findRoute = () => {
        let breadcrumbs = [];
        this.props.routes.forEach((route) => {
            let foundPath = _.find(route.components, { path: this.props.location.pathname });
            if (foundPath) {
                breadcrumbs = [
                    <Breadcrumb.Item key='stat1'><Icon type={route.icon} /> {route.name} </Breadcrumb.Item>,
                    <Breadcrumb.Item key='stat2'><Icon type={foundPath.icon} /> {foundPath.name} </Breadcrumb.Item>
                ];
            }

            if (route.components) {
                route.components.forEach((subRoute) => {
                    let foundPath = _.find(subRoute.components, { path: this.props.location.pathname });
                    if (foundPath) {
                        breadcrumbs = [
                            <Breadcrumb.Item key='stat1'><Icon type={route.icon} /> {route.name} </Breadcrumb.Item>,
                            <Breadcrumb.Item key='stat2'><Icon type={subRoute.icon} /> {subRoute.name} </Breadcrumb.Item>,
                            <Breadcrumb.Item key='stat3'><Icon type={foundPath.icon} /> {foundPath.name} </Breadcrumb.Item>
                        ];
                    } else {
                        if (subRoute.components) {
                            // Search for granular path
                            subRoute.components.forEach((subSubRoute) => {
                                const match = matchPath(this.props.location.pathname, {
                                    path: subSubRoute.path,
                                    exact: true,
                                    strict: false
                                });
                                if (match) {
                                    breadcrumbs = [
                                        <Breadcrumb.Item key='stat1'><Icon type={route.icon} /> {route.name} </Breadcrumb.Item>,
                                        <Breadcrumb.Item key='stat2'><Icon type={subRoute.icon} /> {subRoute.name} </Breadcrumb.Item>,
                                        // <Breadcrumb.Item key='stat3'><Icon type={subSubRoute.icon}/> {subSubRoute.name} </Breadcrumb.Item>,
                                        <Breadcrumb.Item key='stat4'> {match.params.id} </Breadcrumb.Item>
                                    ];
                                }
                            })
                        }
                    }
                });
            }
        });
        return breadcrumbs;
    };

    render() {
        const token = localStorage.getItem('token');
        const decodeToken = JSON.parse(atob(token.split('.')[1]));
        return (
            <Fragment>
                <Header className={styles.mainheader}>
                    <Icon
                        className={styles.icon}
                        type={this.state.collapsed ? 'menu-unfold' : 'menu-fold'}
                        onClick={() => this.toggleCollapsed()}
                    />
                    <div className={styles.rightContainer}>
                        <Menu mode="horizontal">
                            <Menu.SubMenu
                                title={
                                    <Fragment>
                                        <span>{this.state.userName}</span>
                                        <Avatar style={{ marginLeft: 8 }} src={defaultUserImg} />
                                    </Fragment>
                                }>
                                <Menu.Item>
                                    <Link to={{
                                        pathname: '/changepassword',
                                        state: { id: decodeToken.user_id }
                                    }}>パスワード変更</Link>
                                </Menu.Item>
                                <Menu.Item>
                                    <Link to="/login" onClick={e => this.handleLogout(e)}>ログアウト</Link>
                                </Menu.Item>
                            </Menu.SubMenu>
                        </Menu>
                    </div>
                </Header>
                <Breadcrumb className={styles.breadcrumb}>
                    {this.findRoute()}
                </Breadcrumb>
            </Fragment>
        )
    }
}

const options = () => {
    const token = localStorage.getItem('token');
    const decodeToken = JSON.parse(atob(token.split('.')[1]));
    return {
        variables: {
            id: decodeToken.user_id
        }
    }
}

export default compose(
    graphql(getUserQuery, {
        name: 'getUserQuery',
        options
    })
)(withApollo(withRouter(HeaderComponent)));