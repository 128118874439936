import eaw from 'eastasianwidth';
import _ from 'lodash';
import moment from 'moment';
import { modeTypes } from './constants';
import fullwidth from 'fullwidth';

export const getUserId = () => {
  const token = localStorage.getItem('token');
  const { user_id } = JSON.parse(atob(token.split('.')[1]));
  return user_id;
};

const isInverted = (mode) => [modeTypes.ALL_REVERSE, modeTypes.COPY_INVERSE].indexOf(mode) >= 0;

const isImported = (mode) => [modeTypes.ALL_REVERSE, modeTypes.ALL].indexOf(mode) >= 0;

const isCopyOrInverted = (mode) => [modeTypes.ALL_REVERSE, modeTypes.COPY_INVERSE, modeTypes.COPY].indexOf(mode) >= 0;

const isCopy = (mode) => [modeTypes.COPY_INVERSE, modeTypes.COPY].indexOf(mode) >= 0;

const acquireImages = ({ imageNo, imageFile }) => ({ imageNo, imageFile });

const acquireEscort = (escorts, mode) => (
  _.map(escorts, escort => {
    const { userByDriverId } = escort;

    const includedData = {};
    if (mode === modeTypes.EDIT) {
      includedData.pickupDistance = escort.pickupDistance ? `${escort.pickupDistance}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : null;
      includedData.dropoffDistance = escort.dropoffDistance;
      includedData.transportBillingAmount = escort.transportBillingAmount ? `${escort.transportBillingAmount}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : null;
      includedData.transportPaymentAmount = escort.transportPaymentAmount ? `${escort.transportPaymentAmount}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : null;
    }

    const { id: escortId, ordersEscortConnectedsByEscortId1, ordersEscortConnectedsByEscortId2 } = escort;
    let connectionData = {};
    if ((ordersEscortConnectedsByEscortId1 || {}).nodes && ordersEscortConnectedsByEscortId1.nodes.length) {
      const { id, escortId1, escortId2, ordersConnectedId, totalBilling, totalDistance, ...others } = ordersEscortConnectedsByEscortId1.nodes[0];
      connectionData = {
        ordersConnectedId,
        escortConnectionId: id,
        escortId1: escortId1 === escortId ? null : escortId1,
        escortId2: escortId2 === escortId ? null : escortId2,
        totalBilling: totalBilling.toLocaleString(),
        totalDistance: totalDistance.toLocaleString(),
        isSameDrivers: others.isSameDrivers,
        connectionDriverId1: others.driverId1,
        connectionDriverId2: others.driverId2,
        driver1Billing: others.driver1Billing,
        driver2Billing: others.driver2Billing,
      };
    }
    if ((ordersEscortConnectedsByEscortId2 || {}).nodes && ordersEscortConnectedsByEscortId2.nodes.length) {
      const { id, escortId1, escortId2, ordersConnectedId, totalBilling, totalDistance, ...others } = ordersEscortConnectedsByEscortId2.nodes[0];
      connectionData = {
        ordersConnectedId,
        escortConnectionId: id,
        escortId1: escortId1 === escortId ? null : escortId1,
        escortId2: escortId2 === escortId ? null : escortId2,
        totalBilling: totalBilling.toLocaleString(),
        totalDistance: totalDistance.toLocaleString(),
        isSameDrivers: others.isSameDrivers,
        connectionDriverId1: others.driverId1,
        connectionDriverId2: others.driverId2,
        driver1Billing: others.driver1Billing,
        driver2Billing: others.driver2Billing,
      };
    };

    return {
      orderId: escort.orderId,
      transportId: escort.transportId,
      escortNo: escort.escortNo,
      vehicleId: escort.vehicleId,
      driverId: escort.driverId,
      driverName: (userByDriverId || {}).fullNameJa,
      origin: escort.origin,
      destination: escort.destination,
      ...includedData,
      ...connectionData,
    }
  })
)

const acquireInverted = (transport, inverted, data1, data2) => {
  const key = inverted ? data2 : data1;
  return transport[key];
};

const colorValues = {
  'text-red': 'RED',
  'text-green': 'GREEN',
  'text-black': 'BLACK'
}
const acquireTransport = (orderTransport, transports, mode, withdrawDate, record) => (
  _.map(transports, (data, index) => {
    const inverted = isInverted(mode);
    const transport = orderTransport.nodes[index];
    const recordTransport = !!record.transports && record.transports[index] || {}
    let colorOrigin = !!recordTransport.originColor && !!recordTransport.origin ? colorValues[recordTransport.originColor] : null;
    let colorDestination = !!recordTransport.destinationColor && !!recordTransport.destination ? colorValues[recordTransport.destinationColor] : null;

    if (!transport) return {};
    const { ordersDropoffImagesByTransportId: dropoffImages, ordersPickupImagesByTransportId: pickupImages,
      ordersEscortVehiclesByTransportId: escorts } = transport;
    const includedData = {};
    if (modeTypes.EDIT === mode) {
      if (modeTypes.ALL !== mode) {
        includedData.pickupActualDatetime = transport.pickupActualDatetime;
        includedData.pickupMileage = transport.pickupMileage;
        includedData.dropoffActualDatetime = transport.dropoffActualDatetime;
        includedData.dropoffMileage = transport.dropoffMileage;
      } else {
        colorOrigin = 'RED';
        colorDestination = 'RED';
      }
      includedData.originColor = colorOrigin || transport.originColor;
      includedData.destinationColor = colorDestination || transport.destinationColor;

      includedData.dropoffTimeFrom = (!_.isNil(transport.dropoffHourFrom) && !_.isNil(transport.dropoffMinuteFrom)) ? `${transport.dropoffHourFrom}:${transport.dropoffMinuteFrom}` : null;
      includedData.dropoffTimeTo = (!_.isNil(transport.dropoffHourTo) && !_.isNil(transport.dropoffMinuteTo)) ? `${transport.dropoffHourTo}:${transport.dropoffMinuteTo}` : null;
      includedData.pickupOption = transport.pickupOption;
      includedData.pickupHour = transport.pickupHour;
      includedData.pickupMinute = transport.pickupMinute;
      includedData.pickupTime = (!_.isNil(transport.pickupHour) && !_.isNil(transport.pickupMinute)) ? `${transport.pickupHour}:${transport.pickupMinute}` : null;
      includedData.pickupHourTo = transport.pickupHourTo;
      includedData.pickupMinuteTo = transport.pickupMinuteTo;
      includedData.pickupTimeTo = (!_.isNil(transport.pickupHourTo) && !_.isNil(transport.pickupMinuteTo)) ? `${transport.pickupHourTo}:${transport.pickupMinuteTo}` : null;
      includedData.dropoffOption = transport.dropoffOption;
      includedData.dropoffHourFrom = transport.dropoffHourFrom;
      includedData.dropoffMinuteFrom = transport.dropoffMinuteFrom;
      includedData.dropoffHourTo = transport.dropoffHourTo;
      includedData.dropoffMinuteTo = transport.dropoffMinuteTo;
    }
    return {
      id: transport.id,
      origin: acquireInverted(transport, inverted, 'origin', 'destination'),
      destination: acquireInverted(transport, inverted, 'destination', 'origin'),
      pickupDate: transport.pickupDate && !isCopyOrInverted(mode) && !isImported(mode) ? moment(transport.pickupDate) : null,
      dropoffDate: transport.dropoffDate && !isCopyOrInverted(mode) && !isImported(mode) ? moment(transport.dropoffDate) : null,
      transportNo: transport.transportNo,
      pickupMemo: acquireInverted(transport, inverted, 'pickupMemo', 'dropoffMemo'),
      originColor: acquireInverted(transport, inverted, 'originColor', 'destinationColor'),
      destinationColor: acquireInverted(transport, inverted, 'destinationColor', 'originColor'),
      pickupBorrower: acquireInverted(transport, inverted, 'pickupBorrower', 'dropoffBorrower'),
      pickupAssignee: acquireInverted(transport, inverted, 'pickupAssignee', 'dropoffAssignee'),
      pickupContact: acquireInverted(transport, inverted, 'pickupContact', 'dropoffContact'),
      pickupSiteName: acquireInverted(transport, inverted, 'pickupSiteName', 'dropoffSiteName'),
      dropoffMemo: acquireInverted(transport, inverted, 'dropoffMemo', 'pickupMemo'),
      dropoffBorrower: acquireInverted(transport, inverted, 'dropoffBorrower', 'pickupBorrower'),
      dropoffAssignee: acquireInverted(transport, inverted, 'dropoffAssignee', 'pickupAssignee'),
      dropoffContact: acquireInverted(transport, inverted, 'dropoffContact', 'pickupContact'),
      dropoffSiteName: acquireInverted(transport, inverted, 'dropoffSiteName', 'pickupSiteName'),
      numberEscortVehicle: transport.numberEscortVehicle,
      ...includedData,
      pickupImages: _.map(inverted ? dropoffImages.nodes : pickupImages.nodes, acquireImages),
      dropoffImages: _.map(inverted ? pickupImages.nodes : dropoffImages.nodes, acquireImages),
      escort: acquireEscort(escorts.nodes, mode)
    };
  })
)

export const acquireFormData = (order, data, mode, withdrawDate, record = {}, action, transportDate) => {
  const { ordersTransportsByOrderId, packagingByPacking1, userByDriverId1,
    userByDriverId2, customerByCustomerId } = order;

  const includedData = {};
  if (modeTypes.EDIT === mode) {
    includedData.transportDistance = order.transportDistance ? `${order.transportDistance}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : null;
    includedData.transportBillingAmount = order.transportBillingAmount ? `${order.transportBillingAmount}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : null;
    includedData.transportPaymentAmount = order.transportPaymentAmount ? `${order.transportPaymentAmount}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : null;
    includedData.displayOrder = order.displayOrder;
  }

  if ((action === 'add' || action === 'edit')) {
    if (mode !== 'edit') {
      order.transportDate = transportDate ? transportDate.format('YYYY-MM-DD') : null;
      order.dispatchStatus = 'PENDING';
    }
  }

  const { ordersConnectedsByOrderId1, ordersConnectedsByOrderId2, id: orderId } = order;
  let connectionData = {};
  if (!data.isEditImport && (ordersConnectedsByOrderId1 || {}).nodes && ordersConnectedsByOrderId1.nodes.length) {
    const { id, escortConnected, connectionId, orderId1, orderId2, totalBilling, totalDistance, ...others } = ordersConnectedsByOrderId1.nodes[0];
    connectionData = {
      isFirst: true,
      orderConnectionId: id,
      connectionId, escortConnected,
      connectedOrderId: orderId1 === orderId ? orderId2 : orderId1,
      totalBilling: totalBilling.toLocaleString(),
      totalDistance: totalDistance.toLocaleString(),
      isSameDrivers: others.isSameDrivers,
      connectionDriverId1: others.driverId1,
      connectionDriverId2: others.driverId2,
      driver1Billing: others.driver1Billing,
      driver2Billing: others.driver2Billing,
    };
  }
  if (!data.isEditImport && (ordersConnectedsByOrderId2 || {}).nodes && ordersConnectedsByOrderId2.nodes.length) {
    const { id, escortConnected, connectionId, orderId2, orderId1, totalBilling, totalDistance, ...others } = ordersConnectedsByOrderId2.nodes[0];
    connectionData = {
      isFirst: false,
      orderConnectionId: id,
      connectionId, escortConnected,
      connectedOrderId: orderId1 === orderId ? orderId2 : orderId1,
      totalBilling: totalBilling.toLocaleString(),
      totalDistance: totalDistance.toLocaleString(),
      isSameDrivers: others.isSameDrivers,
      connectionDriverId1: others.driverId1,
      connectionDriverId2: others.driverId2,
      driver1Billing: others.driver1Billing,
      driver2Billing: others.driver2Billing,
    };
  };

  const payload = {
    id: order.id,
    ...connectionData,
    updatedAt: order.updatedAt,
    transportDate: modeTypes.COPY_INVERSE === mode ? withdrawDate : (order.transportDate ? moment(order.transportDate) : null),
    removeTransports: data.removeTransports || null,
    isEditImport: data.isEditImport || false,
    orderDate: (isCopy(mode) || mode === modeTypes.ALL || mode === modeTypes.ALL_REVERSE) ? moment() : order.orderDate ? moment(order.orderDate) : null,
    transportDateOption: order.transportDateOption,
    dispatchStatus: order.dispatchStatus,
    vehicleId: order.vehicleId,
    driverId1: order.driverId1,
    driver1Name: (userByDriverId1 || {}).fullNameJa,
    driverId2: order.driverId2,
    driver2Name: (userByDriverId2 || {}).fullNameJa,
    customerId: order.customerId,
    customerName: (customerByCustomerId || {}).nameJa,
    weight: (packagingByPacking1 || {}).weight,
    escortVehicleOutsourcingUnitPriceId: (customerByCustomerId || {}).escortVehicleOutsourcingUnitPriceId,
    escortVehicleUnitPriceId: (customerByCustomerId || {}).escortVehicleUnitPriceId,
    transportationUnitPriceId: (customerByCustomerId || {}).transportationUnitPriceId,
    packing1: order.packing1,
    packagingName: (packagingByPacking1 || {}).packagingName,
    packing2: order.packing2,
    overnight: order.overnight,
    notes: order.notes,
    // notes2: order.notes2,
    ...includedData,
    transport: acquireTransport(ordersTransportsByOrderId, data.transport, mode, withdrawDate, record)
  }
  return payload;
};

export const updateMomentValues = (values) => {
  values.driverId1 = values.driverId1 && values['driverId1-display'] !== '' ? values.driverId1 : null;
  values.driverId2 = values.driverId2 && values['driverId2-display'] !== '' ? values.driverId2 : null;
  values.orderDate = values.orderDate ? moment(values.orderDate).format('YYYY-MM-DD') : null;
  values.transportDate = values.transportDate ? moment(values.transportDate).format('YYYY-MM-DD') : null;
  values.pickupDate_1 = values.pickupDate_1 ? moment(values.pickupDate_1).format('YYYY-MM-DD') : null;
  values.dropoffDate_1 = values.dropoffDate_1 ? moment(values.dropoffDate_1).format('YYYY-MM-DD') : null;
  values.pickupDate_2 = values.pickupDate_2 ? moment(values.pickupDate_2).format('YYYY-MM-DD') : null;
  values.dropoffDate_2 = values.dropoffDate_2 ? moment(values.dropoffDate_2).format('YYYY-MM-DD') : null;
  values.pickupDate_3 = values.pickupDate_3 ? moment(values.pickupDate_3).format('YYYY-MM-DD') : null;
  values.dropoffDate_3 = values.dropoffDate_3 ? moment(values.dropoffDate_3).format('YYYY-MM-DD') : null;
  values.dropoffTimeFrom_1 = values.dropoffTimeFrom_1 ? moment(values.dropoffTimeFrom_1).format('HH:mm') : null;
  values.dropoffTimeFrom_2 = values.dropoffTimeFrom_2 ? moment(values.dropoffTimeFrom_2).format('HH:mm') : null;
  values.dropoffTimeFrom_3 = values.dropoffTimeFrom_3 ? moment(values.dropoffTimeFrom_3).format('HH:mm') : null;
  values.dropoffTimeTo_1 = values.dropoffTimeTo_1 ? moment(values.dropoffTimeTo_1).format('HH:mm') : null;
  values.dropoffTimeTo_2 = values.dropoffTimeTo_2 ? moment(values.dropoffTimeTo_2).format('HH:mm') : null;
  values.dropoffTimeTo_3 = values.dropoffTimeTo_3 ? moment(values.dropoffTimeTo_3).format('HH:mm') : null;
  values.pickupTime_1 = values.pickupTime_1 ? moment(values.pickupTime_1).format('HH:mm') : null;
  values.pickupTime_2 = values.pickupTime_2 ? moment(values.pickupTime_2).format('HH:mm') : null;
  values.pickupTime_3 = values.pickupTime_3 ? moment(values.pickupTime_3).format('HH:mm') : null;
  values.pickupTimeTo_1 = values.pickupTimeTo_1 ? moment(values.pickupTimeTo_1).format('HH:mm') : null;
  values.pickupTimeTo_2 = values.pickupTimeTo_2 ? moment(values.pickupTimeTo_2).format('HH:mm') : null;
  values.pickupTimeTo_3 = values.pickupTimeTo_3 ? moment(values.pickupTimeTo_3).format('HH:mm') : null;
  values.transportDistance = values.transportDistance ? `${values.transportDistance}`.replace(/,/g, '') : null;
  values.transportBillingAmount = values.transportBillingAmount ? `${values.transportBillingAmount}`.replace(/,/g, '') : null;
  values.transportPaymentAmount = values.transportPaymentAmount ? `${values.transportPaymentAmount}`.replace(/,/g, '') : null;
}

export const hasHalfWidthCharacter = (value) => {
  for (var i = 0; i < value.length; i++) {
    if ((eaw.length(value.charAt(i)) === 1) && value.charAt(i) !== ' ') {
      return true;
    }
  }
}

export const convertHalfWidthToFullWidth = (rawValue) => {
  if (rawValue) {
    return fullwidth(rawValue);
  }
}

const tranportLocKeys = ['origin_1', 'destination_1', 'origin_2', 'destination_2', 'origin_3', 'destination_3'];
const escortLocKeys = ['numberEscortVehicle_1', 'numberEscortVehicle_2', 'numberEscortVehicle_3'];
export const checkLocations = (values) => {
  let isValid = true;
  if (values.dispatchStatus === 'COMPLETED') {
    isValid = false;
    // Check transport locations
    _.each(tranportLocKeys, (key) => {
      if (!!values[key]) {
        isValid = true;
        return false;
      }
    });
    if (isValid) {
      _.each(escortLocKeys, (key) => {
        const [, transportIndex] = key.split('_');
        const escortCount = values[key];
        if (escortCount) {
          _.each([...new Array(escortCount)], (key, index) => {
            const originKey = `${transportIndex}_origin_${index + 1}`;
            const destinationKey = `${transportIndex}_destination_${index + 1}`;
            if (!(values[originKey] || values[destinationKey])) {
              isValid = false;
              return false;
            }
          });
          if (!isValid) return false;
        }
      });
    }
  };
  return isValid;
};