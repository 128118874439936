import { notification, Row, Typography } from 'antd';
import moment from 'moment';
import React, { Fragment, PureComponent } from 'react';
import { Query } from 'react-apollo';
import { getAll } from '../../queries/Orders/ordersTransportDestination';

class AlertsNotification extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      message: null
    };
  };

  alertNotif = (time) => {
    setTimeout(() => {
      notification['info']({
        message: '配車確定',
        description: `${time}`,
        duration: 0
      });

    }, 100);
  }
  openNotificationWithIcon = (type, message, description, duration) => {
    setTimeout(() => {
      notification[type]({
        message: message,
        description: `${description}`,
        duration: duration
      });
    }, 100);
  };

  generateFilters = () => {
    // let currentTime = moment().toDate().getTime();

    let currentHour = moment().toDate().getHours()
    let plusOne = currentHour + 1;
    let filterDate = moment().format('YYYY-MM-DD')
    let filterMinutes = moment().toDate().getMinutes();
    let filterHours = moment().toDate().getHours();
    let filters
    if (currentHour >= 17 && currentHour < 19) {
      filters = {
        orderByOrderId: {
          isDeleted: { equalTo: false },
          dispatchStatus: { equalTo: 'PENDING' }
        },
        or: [
          {
            and: [
              { pickupDate: { equalTo: filterDate } }
            ]
          }, {
            and: [
              {
                orderByOrderId: {
                  transportDate: { equalTo: filterDate }
                }
              }
            ]
          }
        ], and: [
          {
            or: [
              { pickupOption: { equalTo: 'EVENING' } },
              { dropoffOption: { equalTo: 'EVENING' } },
              {
                and: [
                  { pickupHour: { equalTo: currentHour } },
                  { pickupMinute: { lessThanOrEqualTo: 59 } },
                  { pickupMinute: { greaterThanOrEqualTo: filterMinutes } }
                ]
              },
              {
                and: [
                  { dropoffHourFrom: { equalTo: currentHour } },
                  { dropoffMinuteFrom: { lessThanOrEqualTo: 59 } },
                  { dropoffMinuteFrom: { greaterThanOrEqualTo: filterMinutes } }
                ]
              },
              {
                and: [
                  { dropoffHourTo: { equalTo: currentHour } },
                  { dropoffMinuteTo: { lessThanOrEqualTo: 59 } },
                  { dropoffMinuteTo: { greaterThanOrEqualTo: filterMinutes } }
                ]
              },
              {
                and: [
                  { pickupHour: { equalTo: plusOne } },
                  { pickupMinute: { lessThanOrEqualTo: filterMinutes } },
                ]
              },
              {
                and: [
                  { dropoffHourFrom: { equalTo: plusOne } },
                  { dropoffMinuteFrom: { lessThanOrEqualTo: filterMinutes } }
                ]
              },
              {
                and: [
                  { dropoffHourTo: { equalTo: plusOne } },
                  { dropoffMinuteTo: { lessThanOrEqualTo: filterMinutes } }
                ]
              }
            ]
          }
        ]
      }
    } else {
      filters = {
        orderByOrderId: {
          isDeleted: { equalTo: false },
          dispatchStatus: { equalTo: 'PENDING' }
        },
        or: [{
          and: [
            { pickupDate: { equalTo: filterDate } }
          ]
        }, {
          and: [
            {
              orderByOrderId: {
                transportDate: { equalTo: filterDate }
              }
            }
          ]
        }
        ], and: [
          {
            or: [
              {
                and: [
                  { pickupHour: { equalTo: currentHour } },
                  { pickupMinute: { lessThanOrEqualTo: 59 } },
                  { pickupMinute: { greaterThanOrEqualTo: filterMinutes } }
                ]
              },
              {
                and: [
                  { dropoffHourFrom: { equalTo: currentHour } },
                  { dropoffMinuteFrom: { lessThanOrEqualTo: 59 } },
                  { dropoffMinuteFrom: { greaterThanOrEqualTo: filterMinutes } }
                ]
              },
              {
                and: [
                  { dropoffHourTo: { equalTo: currentHour } },
                  { dropoffMinuteTo: { lessThanOrEqualTo: 59 } },
                  { dropoffMinuteTo: { greaterThanOrEqualTo: filterMinutes } }
                ]
              },
              {
                and: [
                  { pickupHour: { equalTo: plusOne } },
                  { pickupMinute: { lessThanOrEqualTo: filterMinutes } },
                ]
              },
              {
                and: [
                  { dropoffHourFrom: { equalTo: plusOne } },
                  { dropoffMinuteFrom: { lessThanOrEqualTo: filterMinutes } }
                ]
              },
              {
                and: [
                  { dropoffHourTo: { equalTo: plusOne } },
                  { dropoffMinuteTo: { lessThanOrEqualTo: filterMinutes } }
                ]
              }
            ]
          }
        ]
      }
    }
    return filters;
  }

  calculateTime = (offset) => {
    let date = new Date();
    let utc = date.getTime() + (date.getTimezoneOffset() * 60000);
    let nd = new Date(utc + (3600000 * offset));
    return nd.toLocaleString();
  }

  notificationTrigger = (data) => {
    let message = null;
    if (data) {
      if (data.allOrdersTransports && data.allOrdersTransports.nodes) {
        let length = data.allOrdersTransports.nodes ? data.allOrdersTransports.nodes.length - 1 : null;
        if (length + 1 > 0) {
          let dateTime = this.calculateTime(+9)
          let currentHour = +moment(dateTime).format('HH')
          let hourConfimation = data.allOrdersTransports.nodes ? true : false
          let pickupOptions = data.allOrdersTransports.nodes ? data.allOrdersTransports.nodes : 0
          for (let x = 0; x < pickupOptions.length; x++) {
            if (hourConfimation && (data.allOrdersTransports.nodes[x].pickupOption === 'SPECIFIED_TIME' || data.allOrdersTransports.nodes[x].dropoffOption === 'SPECIFIED_TIME')) {
              message = '【警告】未配車の発地時間が近づいてます！！';
              break;
            } else if (currentHour === 17 && hourConfimation && (data.allOrdersTransports.nodes[x].pickupOption === 'EVENING' || data.allOrdersTransports.nodes[x].dropoffOption === 'EVENING')) {
              // this.openNotificationWithIcon('info', '警告', '未配車の発地時間が近づいてます！！', 30);
              message = '【警告】未配車の発地時間が近づいてます！！';
              break;
            } else if (hourConfimation && (data.allOrdersTransports.nodes[x].orderByOrderId.dispatchStatus !== 'PENDING')) {
              message = null;//this will remove alert bar, remove alert when dispatch status is Confirmed/NOT pending
              break;
            }
          }
        }
      }
    }
    return message;
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  render() {
    // const format = 'HH:mm';
    // #YYYY/MM/DD（Day name）Time：hour:Minute#で確定しました。
    return (
      <Query
        query={getAll}
        fetchPolicy='network-only'
        // pollInterval={300000} // 5 minutes check of data
        variables={{
          filter: this.generateFilters(),
        }}>
        {({ data, loading, error, refetch, stopPolling, startPolling }) => {
          if (this.interval) clearInterval(this.interval);
          if (loading) return null;
          if (error) return null;
          if (data.allOrdersTransports && data.allOrdersTransports.nodes) {
            let length = data.allOrdersTransports.nodes ? data.allOrdersTransports.nodes.length - 1 : null;
            if (length + 1 > 0) {
              let dateTime = this.calculateTime(+9)
              let currentHour = +moment(dateTime).format('HH')
              let hourConfimation = data.allOrdersTransports.nodes ? true : false
              let pickupOptions = data.allOrdersTransports.nodes ? data.allOrdersTransports.nodes : 0
              for (let x = 0; x < pickupOptions.length; x++) {
                if (hourConfimation && (data.allOrdersTransports.nodes[x].pickupOption === 'B' || data.allOrdersTransports.nodes[x].dropoffOption === 'B')) {
                  this.setState({ message: '【警告】未配車の発地時間が近づいてます！！' });
                  break;
                } else if (currentHour === 17 && hourConfimation && (data.allOrdersTransports.nodes[x].pickupOption === 'F' || data.allOrdersTransports.nodes[x].dropoffOption === 'F')) {
                  this.setState({ message: '【警告】未配車の発地時間が近づいてます！！' });
                  break;
                }
              }
            }
          };

          this.interval = setInterval(() => {
            const dateTime = this.calculateTime(+9)
            const currentMinute = moment(dateTime).format('mm')
            // if (!(currentMinute % 10)) {//start showing alert every 10minute mark
            refetch({
              filter: this.generateFilters()
            }).then((data) => {
              let { data: fromRefetch } = data
              const message = this.notificationTrigger(fromRefetch);
              // if (!message) {
              //   message = null;
              // } else {//retain current alert/message
              //   message = this.state.message;
              // }
              localStorage.setItem('notification', message ? 'true' : 'false');
              this.setState({ message });
            });
            // }
          }, 2000); //2 secs

          return (
            <Fragment>
              {this.state.message ? (
                <Row style={{ backgroundColor: '#FADA5E', padding: 5 }}>
                  <Typography style={{ color: '#000000' }}>{this.state.message}</Typography>
                </Row>
              ) : null}
            </Fragment>
          )
        }}
      </Query>
    )
  }
}
export default AlertsNotification;
