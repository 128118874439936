import React, { Component, Fragment } from 'react';
import TableToExcel from '../../utils/table-to-excel/tableToExcel';
import { withApollo } from 'react-apollo';
import moment from 'moment';

const dispatchType = `
    excelDispatchOutputData {
        id
        dispatchDate
        date
        rows {
            date
            vehicleNo
            driver
            orderPlace
            packagingName
            por
            schedulePickup
            actualPickup
            destination
            scheduleDropoff
            payment
            amount
            actualDropoff
            actualVehicleDistance
            escortCount
            escortFare
            fare
            remarks
            borrower
        }
    }
`;

class DispatchExcel extends Component {

    constructor(props) {
        super(props);
        this.excel = {}
    };

    render() {
        const createExcel = (row) => {
            const i = row.id;
            let idvalue = "Output" + i;

            let details = row.rows.map((detail, i) => {

                return (
                    <>
                        <tr data-height="30" key={i}>
                            <td data-b-l-s="thin" data-b-b-s="thin" data-a-h="center" data-a-v="middle">{detail.date}</td>                    {/* A*/}
                            <td data-b-l-s="thin" data-b-b-s="thin" data-a-h="center" data-a-v="middle" >{detail.orderPlace}</td>               {/* D*/}
                            <td data-b-l-s="thin" data-b-b-s="thin" data-a-h="center" data-a-v="middle" data-a-wrap="true" dangerouslySetInnerHTML={{ __html: detail.schedulePickup }} />  {/*G*/}
                            <td data-b-l-s="thin" data-b-b-s="thin" data-a-h="center" data-a-v="middle" data-a-wrap="true" dangerouslySetInnerHTML={{ __html: detail.actualPickup.includes('Invalid date') ? '' : detail.actualPickup }} /> {/* H*/}
                            <td data-b-l-s="thin" data-b-b-s="thin" data-a-h="center" data-a-v="middle" data-a-wrap="true" dangerouslySetInnerHTML={{ __html: detail.por }} /> {/* F*/}
                            <td data-b-l-s="thin" data-b-b-s="thin" data-a-h="center" data-a-v="middle" data-a-wrap="true" dangerouslySetInnerHTML={{ __html: detail.scheduleDropoff }} /> {/* J*/}
                            <td data-b-l-s="thin" data-b-b-s="thin" data-a-h="center" data-a-v="middle" data-a-wrap="true" dangerouslySetInnerHTML={{ __html: detail.actualDropoff.includes('Invalid date') ? '' : detail.actualDropoff }} /> {/* K*/}
                            <td data-b-l-s="thin" data-b-b-s="thin" data-a-h="center" data-a-v="middle" data-a-wrap="true" dangerouslySetInnerHTML={{ __html: detail.destination }} />    {/* I */}
                            <td data-b-l-s="thin" data-b-b-s="thin" data-a-h="center" data-a-v="middle" >{detail.packagingName}</td>            {/* E*/}
                            <td data-b-l-s="thin" data-b-b-s="thin" data-a-h="center" data-a-v="middle" data-f-sz="16">{detail.vehicleNo}</td>               {/* B*/}
                            <td data-b-l-s="thin" data-b-b-s="thin" data-a-h="center" data-a-v="middle" >{detail.driver}</td>                   {/* C*/}
                            <td data-b-l-s="thin" data-b-b-s="thin" data-a-h="right" data-a-v="middle" dangerouslySetInnerHTML={{ __html: !detail.actualVehicleDistance ? '' : detail.actualVehicleDistance.toLocaleString() + "km" }} />     {/* L*/}
                            <td data-b-l-s="thin" data-b-b-s="thin" data-a-h="right" data-a-v="middle" data-a-wrap="true" dangerouslySetInnerHTML={{ __html: !detail.fare ? '' : detail.fare }} />  {/* M*/}
                            <td data-b-l-s="thin" data-b-b-s="thin" data-a-h="right" data-a-v="middle" data-a-wrap="true" dangerouslySetInnerHTML={{ __html: !detail.amount ? '' : detail.amount }} /> 
                            <td data-b-l-s="thin" data-b-b-s="thin" data-a-h="right" data-a-v="middle" dangerouslySetInnerHTML={{ __html: !detail.payment ? '' : detail.payment }} />     {/* L*/}
                            <td data-b-l-s="thin" data-b-b-s="thin" data-a-h="center" data-a-v="middle" data-a-wrap="true" dangerouslySetInnerHTML={{ __html: detail.borrower }} />
                            <td data-b-l-s="thin" data-b-b-s="thin" data-a-h="center" data-a-v="middle" data-b-r-s="thin">{detail.remarks}</td>
                        </tr>
                        {detail.escortCount ? (
                            <tr data-height="30" key={i}>
                                <td data-b-l-s="thin" data-b-b-s="thin" data-a-h="center" data-a-v="middle">{detail.date}</td>                    {/* A*/}
                                <td data-b-l-s="thin" data-b-b-s="thin" data-a-h="center" data-a-v="middle" >{detail.orderPlace}</td>               {/* D*/}
                                <td data-b-l-s="thin" data-b-b-s="thin" data-a-h="center" data-a-v="middle" data-a-wrap="true" dangerouslySetInnerHTML={{ __html: detail.schedulePickup }} />  {/*G*/}
                                <td data-b-l-s="thin" data-b-b-s="thin" data-a-h="center" data-a-v="middle" data-a-wrap="true" dangerouslySetInnerHTML={{ __html: detail.actualPickup.includes('Invalid date') ? '' : detail.actualPickup }} /> {/* H*/}
                                <td data-b-l-s="thin" data-b-b-s="thin" data-a-h="center" data-a-v="middle" data-a-wrap="true" dangerouslySetInnerHTML={{ __html: detail.por }} /> {/* F*/}
                                <td data-b-l-s="thin" data-b-b-s="thin" data-a-h="center" data-a-v="middle" data-a-wrap="true" dangerouslySetInnerHTML={{ __html: detail.scheduleDropoff }} /> {/* J*/}
                                <td data-b-l-s="thin" data-b-b-s="thin" data-a-h="center" data-a-v="middle" data-a-wrap="true" dangerouslySetInnerHTML={{ __html: detail.actualDropoff.includes('Invalid date') ? '' : detail.actualDropoff }} /> {/* K*/}
                                <td data-b-l-s="thin" data-b-b-s="thin" data-a-h="center" data-a-v="middle" data-a-wrap="true" dangerouslySetInnerHTML={{ __html: detail.destination }} />    {/* I */}
                                <td data-b-l-s="thin" data-b-b-s="thin" data-a-h="center" data-a-v="middle" >誘導{detail.escortCount}台 </td>        {/* E*/}
                                <td data-b-l-s="thin" data-b-b-s="thin" data-a-h="center" data-a-v="middle" data-f-sz="16">{detail.vehicleNo}</td>               {/* B*/}
                                <td data-b-l-s="thin" data-b-b-s="thin" data-a-h="center" data-a-v="middle" >{detail.driver}</td>                   {/* C*/}
                                <td data-b-l-s="thin" data-b-b-s="thin" data-a-h="right" data-a-v="middle" dangerouslySetInnerHTML={{ __html: !detail.actualVehicleDistance ? '' : detail.actualVehicleDistance.toLocaleString() + "km" }} />     {/* L*/}
                                <td data-b-l-s="thin" data-b-b-s="thin" data-a-h="right" data-a-v="middle" data-a-wrap="true" dangerouslySetInnerHTML={{ __html: !detail.escortFare ? '' : detail.escortFare }} />  {/* M*/}
                                <td data-b-l-s="thin" data-b-b-s="thin" data-a-h="right" data-a-v="middle" data-a-wrap="true" />  
                                <td data-b-l-s="thin" data-b-b-s="thin" data-a-h="right" data-a-v="middle" dangerouslySetInnerHTML={{ __html: !detail.payment ? '' : detail.payment }} />     {/* L*/}
                                <td data-b-l-s="thin" data-b-b-s="thin" data-a-h="center" data-a-v="middle" data-a-wrap="true" dangerouslySetInnerHTML={{ __html: detail.borrower }} />
                                <td data-b-l-s="thin" data-b-b-s="thin" data-a-h="center" data-a-v="middle" data-b-r-s="thin">{detail.remarks}</td>
                            </tr>
                        ) : null}
                    </>
                );
            })

            return <Fragment key={i}>
                <table id={idvalue} data-cols-grouped="3,6" data-cols-width="7,25,0.4,20,18,0.4,20,18,15,12,12,12,12,12,12,16,30">
                    <thead>
                        <tr data-height="25">
                            <td colSpan="17"></td>
                        </tr>
                        <tr data-height="33">
                            <td colSpan="5" data-a-h="left" data-f-sz="14" data-a-v="middle">配車日：{moment(row.dispatchDate).format("YYYY年MM月DD日")}</td>
                            <th colSpan="7" data-a-h="center" data-f-sz="26" data-a-v="middle">配車表</th>
                            <td colSpan="5" data-a-h="right" data-f-sz="14" data-a-v="middle">出力日：{row.date}</td>
                        </tr>
                        <tr data-height="25">
                            <th colSpan="17">&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr data-height="30">
                            <td data-fill-color="D8D8D8" data-a-v="middle" data-a-h="center" data-b-l-s="thin" data-b-b-s="thin" data-b-t-s="thin">日付</td>
                            <td data-fill-color="D8D8D8" data-a-v="middle" data-a-h="center" data-b-l-s="thin" data-b-b-s="thin" data-b-t-s="thin">顧客名</td>
                            <td data-fill-color="D8D8D8" data-a-v="middle" data-a-h="center" data-b-l-s="thin" data-b-b-s="thin" data-b-t-s="thin">発地予定時間</td>
                            <td data-fill-color="D8D8D8" data-a-v="middle" data-a-h="center" data-b-l-s="thin" data-b-b-s="thin" data-b-t-s="thin">発地実績時間</td>
                            <td data-fill-color="D8D8D8" data-a-v="middle" data-a-h="center" data-b-l-s="thin" data-b-b-s="thin" data-b-t-s="thin">発地場所</td>
                            <td data-fill-color="D8D8D8" data-a-v="middle" data-a-h="center" data-b-l-s="thin" data-b-b-s="thin" data-b-t-s="thin">着地予定時間</td>
                            <td data-fill-color="D8D8D8" data-a-v="middle" data-a-h="center" data-b-l-s="thin" data-b-b-s="thin" data-b-t-s="thin">着地実績時間</td>
                            <td data-fill-color="D8D8D8" data-a-v="middle" data-a-h="center" data-b-l-s="thin" data-b-b-s="thin" data-b-t-s="thin">着地場所</td>
                            <td data-fill-color="D8D8D8" data-a-v="middle" data-a-h="center" data-b-l-s="thin" data-b-b-s="thin" data-b-t-s="thin">荷姿</td>
                            <td data-fill-color="D8D8D8" data-a-v="middle" data-a-h="center" data-b-l-s="thin" data-b-b-s="thin" data-b-t-s="thin">号車</td>
                            <td data-fill-color="D8D8D8" data-a-v="middle" data-a-h="center" data-b-l-s="thin" data-b-b-s="thin" data-b-t-s="thin">運転手</td>
                            <td data-fill-color="D8D8D8" data-a-v="middle" data-a-h="center" data-b-l-s="thin" data-b-b-s="thin" data-b-t-s="thin">実車距離</td>
                            <td data-fill-color="D8D8D8" data-a-v="middle" data-a-h="center" data-b-l-s="thin" data-b-b-s="thin" data-b-t-s="thin">運賃</td>
                            <td data-fill-color="D8D8D8" data-a-v="middle" data-a-h="center" data-b-l-s="thin" data-b-b-s="thin" data-b-t-s="thin">請求額</td>
                            <td data-fill-color="D8D8D8" data-a-v="middle" data-a-h="center" data-b-l-s="thin" data-b-b-s="thin" data-b-t-s="thin">支払い額</td>
                            <td data-fill-color="D8D8D8" data-a-v="middle" data-a-h="center" data-b-l-s="thin" data-b-b-s="thin" data-b-t-s="thin">借主</td>
                            <td data-fill-color="D8D8D8" data-a-v="middle" data-a-h="center" data-b-l-s="thin" data-b-b-s="thin" data-b-t-s="thin" data-b-r-s="thin">備考</td>
                        </tr>
                        {details}
                    </tbody>
                </table>
            </Fragment>
        };

        let { data } = this.props;

        const excels = data.map((row) => {
            return createExcel(row);
        });

        setTimeout(() => {
            for (var i = data.length - 1; i >= 0; i--) {
                let row = data[i];
                let idname = "Output" + row.id;
                TableToExcel.convert(document.getElementById(idname), {
                    name: "配車表_" + moment(row.dispatchDate).format("YYYYMMDD") + ".xlsx",
                    sheet: {
                        name: "Sheet 1"
                    },
                    pageSetup: {
                        orientation: 'landscape',
                        paperSize: 9,
                        fitToWidth: 1,
                        fitToHeight: 0,
                        margins: {
							left: 0.25, right: 0.25,
							top: 0.75, bottom: 0.75,
                            header: 0, footer: 0
						},
                    }
                });
            }
            this.props.client.writeData({
                data: {
                    excelOutput: {
                        __typename: 'excelOutput',
                        excelDispatchOutputData: []
                    }
                }
            });
        }, 0);

        return (<Fragment>{excels}</Fragment>);
    }
}

const DispatchExcelWrap = withApollo(DispatchExcel);

export { DispatchExcelWrap, dispatchType };