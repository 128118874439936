import { gql } from 'apollo-boost';

export const CREATE_ROLES = gql`
  mutation createRoles($input: CreateRoleInput!) {
    createRole(input: $input) {
      role {
        id
      }
    }
  }
`;

export const DELETE_ROLES = gql`
  mutation deleteRoles($id: Int!) {
    deleteRoleById(input: {id: $id}) {
      deletedRoleId
    }
  }
`;
