import React, { useContext } from 'react';
import { Form, Row, Col } from 'antd';

/** Get All Customer Queries **/
import {
	getById,
	getAll,
	create,
	updateById,
	deleteById
} from '../../queries/Estimate/details';

import { getUnitPrice } from '../../queries/Estimate/unitPrice';

/** Get user_id stored in LocalStorage **/
import decodeToken from '../../utils/user_id';

/** Get ADhoc translate **/
import adhocTranslator from '../../utils/locales/translate-adhoc';
import PackageModalConfig from '../../components/MasterMaintenance/Package/ListModal';
import { EstimateContext } from './Context';

/** Get All Common  **/
import {
	CustomInput,
	CustomInputNumber,
	CustomHiddenInput,
	CustomButton,
	CustomSelect
} from '../../commons/CustomDataEntry';

import ModalBase from '../../commons/ModalBase';
import DebounceInput from './DebouncedInput';
import { isNull } from 'util';

const TransportationDetailsConfig = ({ mode, modal }) => {

	let lt = adhocTranslator('K050-030');
	let flag = 1;

	const { estimatesState, client } = useContext(EstimateContext);

	return {
		mode,
		modal,
		/** messages */
		messages: {
			add: "運搬先を設定しますか？",
			copy: "運搬先を設定しますか？",
			edit: "運搬先を設定しますか？",
			delete: "運搬先詳細を削除しますか？",
			reg_success_message: lt("reg_success_message"),
			reg_error_message: lt("reg_error_message"),
			edit_success_message: lt("edit_success_message"),
			edit_failure_message: lt("edit_failure_message"),
			delete_success_message: lt("delete_success_message"),
			delete_failure_message: lt("delete_failure_message")
		},
		/** Redirect config **/
		redirect: '/create-estimate/add',

		/** query mapping **/
		data: {
			idRef: 'estimatesDetailById'
		},
		queries: {
			GET_BY_ID: getById,
			GET_ALL: getAll,
			UPDATE_BY_ID: updateById,
			CREATE: create,
			DELETE_BY_ID: deleteById
		},
		component: function ({ form, data, handleSubmit, loading, setState, state }) {
			let saveButton = <CustomButton label={lt('btn_add')}
				loading={loading}
				style={{ marginTop: '15px' }}
				htmlType="submit"
				offset={12} />
			if (modal) {
				/** Decorate submit **/
				handleSubmit = modal.handleOk(handleSubmit);
				saveButton = '';
				if (estimatesState.mode === 'copy' || estimatesState.mode === 'edit' || estimatesState.mode === 'delete') {
					data = modal.form;
				} else {
					if (flag === 1) {
						form.resetFields();
					}
					flag = 0;
				}
			}

			let onChange = (value) => {
				setState({
					packagingName: value
				})
				form.setFieldsValue({
					transportDistance: null,
					transportFee: null,
					packagingName: value
				})
			};

			const afterSelect = (rows) => {
				form.setFieldsValue({
					transportDistance: null,
					transportFee: null,
					escortVehicleDistance: null,
					escortVehicleFee: null,
					weight: rows.weight
				})

			};

			const setDistanceFilter = async (value) => {
				value = !!value ? value : 0
				if (!Number.isInteger(value) && value.includes(",")) {
					value = parseFloat(value.replace(/,/g, ''))
				} else {
					value = parseFloat(value)
				}
				let weightModal = !!modal.form ? modal.form.weight : 0;
				let { data } = await this.props.client.mutate({
					mutation: getUnitPrice,
					variables: {
						id: estimatesState.filters.transportId,
						weight: form.getFieldValue('weight') || weightModal,
						distance: value
					}
				})

				await form.setFieldsValue({
					transportFee: data.getUnitPrice.float,
				});

				if (form.getFieldValue('escortVehicleOption') === 'YES') {
					let escortCount = form.getFieldValue('escortVehicleCount') ? form.getFieldValue('escortVehicleCount') : 1;
					if (estimatesState.filters && estimatesState.filters.estimateId) {
						let { data: escortData } = await client.mutate({
							mutation: getUnitPrice,
							variables: {
								id: estimatesState.filters.estimateId,
								weight: form.getFieldValue('weight') || weightModal,
								distance: !!form.getFieldValue('escortVehicleDistance') ? parseFloat(form.getFieldValue('escortVehicleDistance')) : 0
							}
						});
						form.setFieldsValue({
							initialEscortVehicleFee: escortData.getUnitPrice.float * escortCount,
							// escortVehicleDistance: value 
						})
					}
				} else {
					form.setFieldsValue({
						initialEscortVehicleFee: 0,
						escortVehicleDistance: 0
					})
				}

				if (form.getFieldValue('outsourceEscortVehicleOption') === 'YES') {
					let escortCount = form.getFieldValue('outsourceEscortVehicleCount') ? form.getFieldValue('outsourceEscortVehicleCount') : 1;
					if (estimatesState.filters && estimatesState.filters.subcontractEstimateId) {
						let { data: escortData } = await client.mutate({
							mutation: getUnitPrice,
							variables: {
								id: estimatesState.filters.subcontractEstimateId,
								weight: form.getFieldValue('weight') || weightModal,
								distance: !!form.getFieldValue('outsourceEscortVehicleDistance') ? parseFloat(form.getFieldValue('outsourceEscortVehicleDistance')) : 0
							}
						});
						form.setFieldsValue({
							outsourceEscortVehicleFee: escortData.getUnitPrice.float * escortCount,
							// subcontractedEscortVehicleDistance: value 
						})
					}
				} else {
					form.setFieldsValue({
						outsourceEscortVehicleFee: 0,
						outsourceEscortVehicleDistance: 0
					})
				}

				let initialEscortVehicleFee = form.getFieldValue('initialEscortVehicleFee')
				let outsourceEscortVehicleFee = form.getFieldValue('outsourceEscortVehicleFee')
				form.setFieldsValue({
					escortVehicleFee: (!!initialEscortVehicleFee ? initialEscortVehicleFee : 0) + (!!outsourceEscortVehicleFee ? outsourceEscortVehicleFee : 0)
				})

			};

			// const setEscortFilter = async (value) => {
			// 	value = parseFloat(value.replace(/,/g, ''))
			// 	let weightModal = !!modal.form ? modal.form.weight : 0;
			// 	let { data } = await client.mutate({
			// 		mutation: getUnitPrice,
			// 		variables: {
			// 			id: estimatesState.filters.estimateId,
			// 			weight: form.getFieldValue('weight') || weightModal,
			// 			distance: value
			// 		}
			// 	});

			// 	let escortCount = form.getFieldValue('escortVehicleCount') ? form.getFieldValue('escortVehicleCount') : 1;

			// 	form.setFieldsValue({
			// 		escortVehicleFee: data.getUnitPrice.float * escortCount,
			// 	})

			// }

			const setEscortCount = async (value) => {
				value = parseFloat(value.target.value.replace(/,/g, ''))
				// let weightModal = !!modal.form ? modal.form.weight : 0;
				// let { data } = await client.mutate({
				// 	mutation: getUnitPrice,
				// 	variables: {
				// 		id: estimatesState.filters.estimateId,
				// 		weight: form.getFieldValue('weight') || weightModal,
				// 		distance: value
				// 	}
				// });
				let transportDist = form.getFieldValue("transportDistance")
				if (transportDist) {
					setDistanceFilter(transportDist)
				}
			}

			const setSubcontractEscortCount = async (value) => {
				value = parseFloat(value.target.value.replace(/,/g, ''))
				// let weightModal = !!modal.form ? modal.form.weight : 0;

				// let { data } = await client.mutate({
				// 	mutation: getUnitPrice,
				// 	variables: {
				// 		id: estimatesState.filters.subcontractEstimateId,
				// 		weight: form.getFieldValue('weight') || weightModal,
				// 		distance: value
				// 	}
				// });
				let transportDist = form.getFieldValue("transportDistance")
				if (transportDist) {
					setDistanceFilter(transportDist)
				}
			}


			const escortVehicleOption = [
				{ id: 'NONE', value: lt('誘導なし') },
				{ id: 'YES', value: lt('誘導あり（自社）') },
			];
			const outsourceEscortVehicleOption = [
				{ id: 'NONE', value: lt('誘導なし') },
				{ id: 'YES', value: lt('誘導あり（外注）') },
			];

			const transportDistanceHandler = (e) => {
				var x = document.getElementById('transportDistance')
				x.value = x.value.replace(/\D+/g, '')
				x.value = `${x.value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
				form.setFieldsValue({
					transportDistance: x.value
				})
				setDistanceFilter(e.target.value)
			}

			const onBlur = (fieldName) => {
				var x = document.getElementById(fieldName)
				x.value = x.value.replace(/\D+/g, '')
				x.value = `${x.value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
				form.setFieldsValue({
					[fieldName]: x.value
				})
			}

			return (
				<Row type='flex' className='h100' justify='space-around' align='top'>
					<Col span={24}>
						<Form onSubmit={handleSubmit} onKeyDown={handleSubmit} onlayout='vertical'>
							<CustomHiddenInput
								form={form}
								type='hidden'
								fieldName='initialEscortVehicleFee'
								mode={estimatesState.mode ? estimatesState.mode : mode}
								decorator={{}}
							/>
							<CustomHiddenInput
								form={form}
								type='hidden'
								fieldName='outsourceEscortVehicleFee'
								mode={estimatesState.mode ? estimatesState.mode : mode}
								decorator={{}}
							/>
							<CustomInput
								form={form}
								fieldName='originDestinationLocation'
								label={lt('lbl_point_of_origin_destination_location')}
								decorator={{
									rules: [{
										required: true,
										message: lt('発地・着地場所を入力してください。'),
									}],
									initialValue: estimatesState.mode !== 'add' && data && data.originDestinationLocation ? data.originDestinationLocation : null
								}}
								labelWidth={7}
								width={12}
								mode={estimatesState.mode ? estimatesState.mode : mode}
							/>
							<CustomHiddenInput
								fieldName={mode === 'add' ? 'createdUserId' : 'updatedUserId'}
								form={form}
								decorator={{
									initialValue: decodeToken().user_id
								}}
							/>
							<div className='ant-row ant-form-item'>
								<ModalBase
									title='荷姿情報検索'
									form={form}
									mode={estimatesState.mode ? estimatesState.mode : mode}
									label={lt('lbl_packaging')}
									btnLabel={lt('btn_packaging')}
									fieldName='packing1'
									offset={3}
									config={PackageModalConfig()}
									onChange={onChange}
									afterSelect={afterSelect}
									decorator={{
										initialValue: estimatesState.mode !== 'add' && data && data.packing1 && data.packagingName ? data.packagingName : null,
										rules: [{
											required: true,
											message: lt('荷姿を入力してください。'),
										}]
									}}
									hiddenDecorator={{
										initialValue: estimatesState.mode !== 'add' && data && data.packing1 ? data.packing1 : null
									}}
								/>
							</div>
							<DebounceInput
								fieldName='transportDistance'
								form={form}
								maxLength={7}
								label={lt('lbl_distance_to_destination')}
								width={6}
								postLabel="km "
								className="floatToRight"
								inputClassname="input-text-right"
								offset={3}
								onChange={transportDistanceHandler}
								textInput={false}
								decorator={{
									rules: [{
										required: true,
										message: lt('運搬距離を入力してください。'),
									}],
									initialValue: estimatesState.mode !== 'add' && data && data.transportDistance ?
										`${data.transportDistance}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : null
								}}
								mode={estimatesState.mode ? estimatesState.mode : mode}
							/>
							<CustomInputNumber
								form={form}
								fieldName='transportFee'
								label={lt('lbl_transportation_fare_before_tax')}
								width={6}
								maxLength={7}
								offset={2}
								labelRowWidth={5}
								postLabel="円"
								className="floatToRight"
								inputClassname="input-text-right"
								style={{ marginRight: 5 }}
								onBlur={() => onBlur('transportFee')}
								decorator={{
									rules: [{
										required: true,
										message: lt('運搬運賃（税抜き）を入力してください。'),
									}],
									initialValue: estimatesState.mode !== 'add' && data && data.transportFee ?
										`${data.transportFee}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : null
								}}
								mode={estimatesState.mode ? estimatesState.mode : mode}
							/>
							<CustomInput
								form={form}
								fieldName='notes'
								label={lt('lbl_transportation_notes')}
								labelWidth={7}
								width={12}
								decorator={{
									initialValue: estimatesState.mode !== 'add' && data && data.notes ? data.notes : null
								}}
								mode={estimatesState.mode ? estimatesState.mode : mode}
							/>
							<div className="ant-row ant-form-item" style={{ position: 'relative' }}>
								<CustomSelect
									form={form}
									mode={estimatesState.mode ? estimatesState.mode : mode}
									options={escortVehicleOption}
									label={lt('lbl_escort_vehicle')}
									fieldName='escortVehicleOption'
									placeholder={lt('lbl_escort_vehicle')}
									decorator={{
										initialValue: data && data.escortVehicleOption ? data.escortVehicleOption : 'NONE'
									}}
									width={8}
									rowField={false}
									style={{ width: 184, marginRight: 15 }}
									labelWidth={225}
									onChange={(e) => {
										let transportDist = form.getFieldValue("transportDistance")
										transportDist = isNull(transportDist) ? form.setFieldsValue({ transportDistance: '0' }) : transportDist
										if (e) {
											setState({ mode: 'add' });
											let escortCount = 1
											form.setFieldsValue({
												escortVehicleCount: escortCount,
												escortVehicleDistance: form.getFieldValue("transportDistance")
											})
											// if(transportDist) {
											setDistanceFilter(transportDist)
											// }
										} else {
											form.setFieldsValue({
												escortVehicleDistance: undefined,
												escortVehicleFee: undefined,
												escortVehicleCount: undefined
											})
											setState({ mode: 'delete' })
											setDistanceFilter(transportDist)
										}
									}}
								/>

								<CustomInputNumber
									onBlur={() => onBlur('escortVehicleCount')}
									formatter={value => `$ ${value}`.replace(/\D+/g, '')}
									form={form}
									fieldName='escortVehicleCount'
									style={{ width: '100px', marginRight: '15px' }}
									postLabel="台"
									width={6}
									maxLength={7}
									onChange={setEscortCount}
									decorator={{
										initialValue: estimatesState.mode !== 'add' && data && data.escortVehicleCount ?
											`${data.escortVehicleCount}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : null
									}}
									// eslint-disable-next-line no-mixed-operators
									mode={(mode !== 'delete' || state.mode) && (data && data.escortVehicleOption === 'YES') || state.mode ? state.mode : 'delete'}
									inputClassname="transportconfigclass"
								/>
							</div>
							<DebounceInput
								form={form}
								fieldName='escortVehicleDistance'
								label={lt('lbl_escort_vehicle_distance')}
								offset={3}
								postLabel="km "
								className="floatToRight"
								onBlur={() => onBlur('escortVehicleDistance')}
								inputClassname="input-text-right"
								width={6}
								maxLength={7}
								decorator={{
									initialValue: estimatesState.mode !== 'add' && data && data.escortVehicleDistance ?
										`${data.escortVehicleDistance}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : null
								}}
								onChange={() => {
									// eslint-disable-next-line no-use-before-define
									let transportDist = isNull(transportDist) ? form.setFieldsValue({ transportDistance: '0' }) : transportDist
									setDistanceFilter(transportDist)
								}}
								// eslint-disable-next-line no-mixed-operators
								mode={(mode !== 'delete' || state.mode) && (data && data.escortVehicleOption === 'YES') || state.mode ? state.mode : 'delete'}
							/>

							{/* notes additional field for subcontracted */}

							<div className="ant-row ant-form-item" style={{ position: 'relative' }}>
								<CustomSelect
									form={form}
									mode={estimatesState.mode ? estimatesState.mode : mode}
									options={outsourceEscortVehicleOption}
									label={lt('lbl_subcontract_escort_vehicle')}
									fieldName='outsourceEscortVehicleOption'
									placeholder={lt('lbl_escort_vehicle')}
									decorator={{
										initialValue: data && data.outsourceEscortVehicleOption ? data.outsourceEscortVehicleOption : 'NONE'
									}}
									width={8}
									rowField={false}
									style={{ width: 184, marginRight: 15 }}
									labelWidth={225}
									onChange={(e) => {
										let transportDist = form.getFieldValue("transportDistance")
										transportDist = isNull(transportDist) ? form.setFieldsValue({ transportDistance: '0' }) : transportDist
										if (e) {
											setState({ modes: 'add' });
											let escortCount = 1
											form.setFieldsValue({
												outsourceEscortVehicleCount: escortCount,
												outsourceEscortVehicleDistance: form.getFieldValue("transportDistance")
											})
											// transportDist = isNull(transportDist) ? ',0' : transportDist
											// if(transportDist) {
											setDistanceFilter(transportDist)
											// }
										} else {
											form.setFieldsValue({
												outsourceEscortVehicleDistance: undefined,
												escortVehicleFee: undefined,
												outsourceEscortVehicleCount: undefined
											})
											setState({ modes: 'delete' })
											setDistanceFilter(transportDist)
										}
									}}
								/>

								<CustomInputNumber
									onBlur={() => onBlur('outsourceEscortVehicleCount')}
									form={form}
									fieldName='outsourceEscortVehicleCount'
									style={{ width: '100px', marginRight: '15px' }}
									postLabel="台"
									width={6}
									maxLength={7}
									onChange={setSubcontractEscortCount}
									decorator={{
										initialValue: estimatesState.mode !== 'add' && data && data.outsourceEscortVehicleCount ?
											`${data.outsourceEscortVehicleCount}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : null
									}}
									// eslint-disable-next-line no-mixed-operators
									mode={(mode !== 'delete' || state.modes) && (data && data.outsourceEscortVehicleOption === 'YES' || state.modes) ? state.modes : 'delete'}
									inputClassname="transportconfigclass"
								/>
							</div>

							<DebounceInput
								form={form}
								fieldName='outsourceEscortVehicleDistance'
								label={lt('lbl_subcontract_escort_vehicle_distance')}
								offset={3}
								postLabel="km "
								className="floatToRight"
								onBlur={() => onBlur('outsourceEscortVehicleDistance')}
								inputClassname="input-text-right"
								width={6}
								maxLength={7}
								decorator={{
									initialValue: estimatesState.mode !== 'add' && data && data.outsourceEscortVehicleDistance ?
										`${data.outsourceEscortVehicleDistance}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : null
								}}
								onChange={() => {
									// eslint-disable-next-line no-use-before-define
									let transportDist = isNull(transportDist) ? form.setFieldsValue({ transportDistance: '0' }) : transportDist
									setDistanceFilter(transportDist)
								}}
								// eslint-disable-next-line no-mixed-operators
								mode={((mode !== 'delete' || state.modes) && (data && data.outsourceEscortVehicleOption === 'YES') || state.modes ? state.modes : 'delete')}
							/>
							{/* notes additional field for subcontracted  end here*/}
							<CustomInputNumber
								form={form}
								fieldName='escortVehicleFee'
								label={lt('lbl_escort_vehicle_fare_before_tax')}
								offset={3}
								postLabel="円"
								className="floatToRight"
								inputClassname="input-text-right"
								onBlur={() => onBlur('escortVehicleFee')}
								width={6}
								maxLength={7}
								decorator={{
									initialValue: estimatesState.mode !== 'add' && data && data.escortVehicleFee ?
										`${data.escortVehicleFee}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : null
								}}
								mode={mode !== 'delete' ? 'add' : 'delete'}
							/>
							{/* <CustomInput
								form={form}
								fieldName='escortVehicleNotes'
								label={lt('lbl_escort_vehicle_notes')}
								labelWidth={7}
								width={12}
								decorator={{
									initialValue: estimatesState.mode !== 'add' && data && data.escortVehicleNotes ? data.escortVehicleNotes : null
								}}
								mode={estimatesState.mode ? estimatesState.mode : mode}
							/> */}
							<CustomHiddenInput
								form={form}
								type='hidden'
								fieldName='packagingName'
								mode={estimatesState.mode ? estimatesState.mode : mode}
								decorator={{
									initialValue: data && data.packagingName
								}}
							/>
							<CustomHiddenInput
								form={form}
								type='hidden'
								fieldName='subtotal'
								mode={estimatesState.mode ? estimatesState.mode : mode}
								decorator={{}}
							/>
							<CustomHiddenInput
								form={form}
								type='hidden'
								fieldName='id'
								mode={estimatesState.mode ? estimatesState.mode : mode}
								decorator={{
									initialValue: data && data.id
								}}
							/>
							<CustomHiddenInput
								form={form}
								type='hidden'
								fieldName='weight'
								mode={estimatesState.mode ? estimatesState.mode : mode}
								decorator={{
									initialValue: estimatesState.filters.weight ? estimatesState.filters.weight : null
								}}
							/>
							{saveButton}
						</Form>
					</Col>
				</Row>
			);
		}
	}
}

export default TransportationDetailsConfig
