import { Button, DatePicker, Form, Modal, Row, Select, TimePicker, Typography } from 'antd';
import jaJP from 'antd/lib/date-picker/locale/ja_JP';
import 'moment/locale/ja';
import React, { useEffect, useState } from 'react';
import adhocTranslator from '../../../../../utils/locales/translate-adhoc';
import { formatTypes, schedTypes } from '../../../constants';
import moment from 'moment';
import _ from 'lodash';

const lt = adhocTranslator('K020-010');

const { Option } = Select;

const timeFields = {
  originTime: 'pickupOption',
  destinationTime: 'dropoffOption'
}
const TimeModal = (props) => {

  const { defaultDate, selectedDate, field, data, onCancel, onOk, visible, form: { getFieldDecorator, setFieldsValue, validateFields, getFieldValue } } = props;
  const [show, setShow] = useState(data[timeFields[field]] === 'SPECIFIED_TIME');
  const isTimeSelect = (data) => data === 'SPECIFIED_TIME';
  const formatTime = (time) => time ? time.format(formatTypes.TIME) : null;

  const onSubmit = async () => {
    const value = await validateFields();
    const { date, timeType, ...data } = value;
    const payload = {
      date: date.format(formatTypes.DATE),
      option: timeType,
      time: null
    };

    if (isTimeSelect(timeType)) {
      payload.time_from = formatTime(data.time_from);
      payload.time_to = formatTime(data.time_to);
    };
    onOk(payload);
  };

  const onTimeChange = (value) => {
    value === 'SPECIFIED_TIME' ?
      setShow(true) :
      setShow(false);
  };

  useEffect(() => {
    if (field === 'originTime') {
      const timeFrom = (!_.isNil(data.pickupHour) && !_.isNil(data.pickupMinute)) ? `${data.pickupHour}:${data.pickupMinute}` : null;
      const timeTo = (!_.isNil(data.pickupHourTo) && !_.isNil(data.pickupMinuteTo)) ? `${data.pickupHourTo}:${data.pickupMinuteTo}` : null
      const intialValues = {
        date: data.pickupDate ? moment(data.pickupDate) : moment(defaultDate),
        timeType: data.pickupOption,
        time_from: timeFrom ? moment(timeFrom, 'HH:mm') : null,
        time_to: timeTo ? moment(timeTo, 'HH:mm') : null
      }
      setFieldsValue(intialValues)
    } else {
      const timeFrom = (!_.isNil(data.dropoffHourFrom) && !_.isNil(data.dropoffMinuteFrom)) ? `${data.dropoffHourFrom}:${data.dropoffMinuteFrom}` : null;
      const timeTo = (!_.isNil(data.dropoffHourTo) && !_.isNil(data.dropoffMinuteTo)) ? `${data.dropoffHourTo}:${data.dropoffMinuteTo}` : null
      const intialValues = {
        date: data.dropoffDate ? moment(data.dropoffDate) : moment(defaultDate),
        timeType: data.dropoffOption,
        time_from: timeFrom ? moment(timeFrom, 'HH:mm') : null,
        time_to: timeTo ? moment(timeTo, 'HH:mm') : null
      }
      setFieldsValue(intialValues)
    }
  }, [data]);

  return (
    <Modal
      visible={visible}
      onOK={onOk}
      onCancel={onCancel}
      className='modal-vehicle'
      style={{ zIndex: 1000 }}
      width={416}
      footer={[
        <Button key='submit' type='primary' onClick={onSubmit}>設定</Button>,
      ]}
    >
      <Row>
        <Row type='flex' justify='center' style={{ marginBottom: 30 }}>
          <Typography style={{ fontWeight: 'bold' }}>{field === 'originTime' ? '発地時間' : '着地時間'}</Typography>
        </Row>
        <Form>
          <Row type='flex' style={{ flexDirection: 'row' }} className='vertical-align'>
            <Typography.Text style={styles.label}>{field === 'originTime' ? '発地日' : '着地日'}</Typography.Text>
            <Form.Item style={{ flex: 1 }}>
              {getFieldDecorator('date', {
                initialValue: selectedDate,
                rules: [{
                  required: true,
                  message: '着地日を入力してください。'
                }]
              })(
                <DatePicker
                  format={formatTypes.DATE}
                  locale={jaJP}
                  placeholder={lt('txt_ｆrom_date')}
                  showToday={false}
                  size='default'
                  style={{ width: 170, marginRight: 30 }}
                />
              )}
            </Form.Item>
          </Row>

          <Row type='flex' className='vertical-align'>
            <Typography.Text style={styles.label}>区分</Typography.Text>
            <Form.Item style={{ marginBottom: 15, flex: 1 }}>
              {getFieldDecorator('timeType', {
                initialValue: 'BLANK'
              })(
                <Select
                  style={{ width: 170, marginRight: 30 }}
                  onChange={onTimeChange}
                >
                  {schedTypes.map(sched => (
                    <Option key={sched.key} value={sched.value}>{sched.label}</Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Row>
          {show && (<>
            {field === 'originTime' ? (
              <>
                <Row type='flex' className='vertical-align'>
                  <Typography.Text style={styles.label}>発地（自)</Typography.Text>
                  <Form.Item style={{ marginBottom: 15, flex: 1 }}>
                    {getFieldDecorator('time_from', {
                      rules: [{
                        required: !!getFieldValue('time_to') ? false : true,
                        message: '発地（自)を入力してください。'
                      }]
                    })(
                      <TimePicker
                        className='time_from'
                        allowClear
                        style={{ width: 170, marginRight: 30 }}
                        format={formatTypes.TIME}
                        placeholder='時間を選択'
                        minuteStep={10}
                        defaultOpenValue={moment('00:00:')}
                      />
                    )}
                  </Form.Item>
                </Row>
                <Row type='flex' className='vertical-align'>
                  <Typography.Text style={styles.label}>発地（至)</Typography.Text>
                  <Form.Item style={{ marginBottom: 15, flex: 1 }}>
                    {getFieldDecorator('time_to', {
                      rules: [{
                        required: !!getFieldValue('time_from') ? false : true,
                        message: '発地（至)を入力してください。'
                      }]
                    })(
                      <TimePicker
                        className='time_to'
                        allowClear
                        style={{ width: 170, marginRight: 30 }}
                        format={formatTypes.TIME}
                        placeholder='時間を選択'
                        minuteStep={10}
                        defaultOpenValue={moment('00:00:')}
                      />
                    )}
                  </Form.Item>
                </Row>
              </>
            ) : (
              <>
                <Row type='flex' className='vertical-align'>
                  <Typography.Text style={styles.label}>着地時間（自)</Typography.Text>
                  <Form.Item style={{ marginBottom: 15, flex: 1 }}>
                    {getFieldDecorator('time_from', {
                      rules: [{
                        required: !!getFieldValue('time_to') ? false : true,
                        message: '着地時間（自)を入力してください。'
                      }]
                    })(
                      <TimePicker
                        className='time_from'
                        allowClear
                        style={{ width: 170, marginRight: 30 }}
                        format={formatTypes.TIME}
                        placeholder='時間を選択'
                        minuteStep={10}
                        defaultOpenValue={moment('00:00:')}
                      />
                    )}
                  </Form.Item>
                </Row>
                <Row type='flex' className='vertical-align'>
                  <Typography.Text style={styles.label}>着地時間（至)</Typography.Text>
                  <Form.Item style={{ marginBottom: 15, flex: 1 }}>
                    {getFieldDecorator('time_to', {
                      rules: [{
                        required: !!getFieldValue('time_from') ? false : true,
                        message: '着地時間（至)を入力してください。'
                      }]
                    })(
                      <TimePicker
                        className='time_to'
                        allowClear
                        style={{ width: 170, marginRight: 30 }}
                        format={formatTypes.TIME}
                        placeholder='時間を選択'
                        minuteStep={10}
                        defaultOpenValue={moment('00:00:')}
                      />
                    )}
                  </Form.Item>
                </Row>
              </>)}
          </>)}
        </Form>
      </Row>
    </Modal>
  )
};

const styles = {
  label: { textAlign: 'end', paddingTop: 5, flex: 1, marginRight: 30 },
}

export default Form.create()(TimeModal);