import React, {Fragment} from 'react';
import locale_jp from 'antd/lib/date-picker/locale/ja_JP';
import {DatePicker, Form} from 'antd';

const {MonthPicker} = DatePicker;
const monthFormat = 'YYYY/MM';

const CustomMonthPicker = ({
            label,
            placeholder,
            className,
            fieldName,
            mode,
            rowField = true,
            style,
            labelWidth = 8,
            width = 24,
            offset = 0,
            postLabel,
            half = false,
            mid = false,
            small = false,
            form: { getFieldDecorator },
            decorator,
            rangeSymbol,
        }) => {
        const formItemLayout = {
            labelCol: {  //for label width
                xs: { span: 24 },
                sm: { span: 8 },
                md: { span: 8},
                lg: { span: labelWidth },
            },
            wrapperCol: { //for input width
                xs: { span: 24 },
                sm: { span: 24 },
                md: { span: width, offset },
            },
        };
        let mainStyle = { display:'inline-block' };
        if(half){
            mainStyle.width = '50%';
        }else if(mid){
            mainStyle.width = '35%';
        }else if(small){
            mainStyle.width = '15%';
        }else mainStyle = null;

        return (
            <Fragment>
                {rowField ? (
                    <Fragment>{rangeSymbol && <div className='range-symbol'>{rangeSymbol}</div>}
                        <Form.Item
                            {...formItemLayout}
                            label={label}
                            style={mainStyle}>
                            {getFieldDecorator(fieldName, decorator)(
                                <MonthPicker
                                    className={className}
                                    format={monthFormat}
                                    disabled={mode === 'delete' ? true : false}
                                    locale={locale_jp}
                                    placeholder={placeholder}
                                    showToday={false}
                                    size="default"/>
                            )}

                        </Form.Item>
                    </Fragment>) :(getFieldDecorator(fieldName, decorator)(
                        <span className="ant-form-item">
                            {label && <label htmlFor={fieldName} style={{width:labelWidth, display: 'inline-block'}}>{label}</label>}
                                {getFieldDecorator(fieldName, decorator)(
                                    <MonthPicker
                                        disabled={mode === 'delete' ? true : false}
                                        className={className}
                                        format={monthFormat}
                                        locale={locale_jp}
                                        placeholder={placeholder}
                                        style={style}
                                        showToday={false}
                                        size="default"/>
                                )}
                            {postLabel && <label htmlFor={fieldName} style={{marginRight:10}}>{postLabel}</label>}
                        </span>
                ))
            }
        </Fragment>
    );
}

export default CustomMonthPicker;