import {gql} from 'apollo-boost';

const getAll = gql`
query allUnitPrices($first: Int, $offset: Int, $filter: UnitPriceFilter) {
    allUnitPrices(first:$first,offset:$offset,filter:$filter,orderBy:[CONVERTED_UNIT_PRICE_NAME_ASC,UNIT_PRICE_CATEGORY_ASC]){
        nodes {
            id,
            unitPriceName,
            unitPriceCategory,
            fractionCategory
        },
        totalCount
    }
}`;
    
const getTransportationPrice = gql`
    query estimatesByTransportationUnitPriceId($first: Int, $offset: Int, $filter: EstimateFilter) {
        estimatesByTransportationUnitPriceId(first:$first,offset:$offset,filter:$filter){
            nodes {
                id,
                estimateDate,
                estimateDateEstimateNo,
                customerNameJa,
                transportationUnitPriceId,
                escortVehicleUnitPriceId,
                subtotal,
                consumptionTax,
                totalAmount,
                status,
            },
            totalCount
        }
    }`;

const getVehiclePrice = gql`
query estimatesByEscortVehicleUnitPriceId($first: Int, $offset: Int, $filter: EstimateFilter) {
    estimatesByEscortVehicleUnitPriceId(first:$first,offset:$offset,filter:$filter){
        nodes {
            id,
            estimateDate,
            estimateDateEstimateNo,
            customerNameJa,
            transportationUnitPriceId,
            escortVehicleUnitPriceId,
            subtotal,
            consumptionTax,
            totalAmount,
            status,
        },
        totalCount
    }
}`;

const create = gql`
  mutation createUnitPrice (
    $unitPriceName: String,
    $unitPriceCategory: UnitPriceOption,
    $fractionCategory: FractionOption,
    $unitPriceFileName: String,
    $updatedUserId : Int!,
    $createdUserId : Int!,
  ){
    createUnitPrice (input:{
		unitPrice : {
            unitPriceName: $unitPriceName,
            unitPriceCategory: $unitPriceCategory,
            fractionCategory: $fractionCategory,
            unitPriceFileName: $unitPriceFileName,
            createdUserId : $createdUserId,
            updatedUserId : $updatedUserId
		}
		}){ unitPrice { id, unitPriceName} }
  	}
`;

const getById = gql`
query getUnitPrice ($id:Int!){
    unitPriceById (id : $id) {
        id,
        unitPriceName,
        unitPriceCategory,
        fractionCategory,
        unitPriceFileName,
        unitPricesDetailsByUnitPriceId {
          nodes {
            id
            unitPriceId
            weight
            transportDistance
            price
          }
        }
    }
}`;


const updateById = gql`
mutation updateUnitPriceById (
    $id: Int!,
    $unitPriceName: String,
    $unitPriceCategory: UnitPriceOption,
    $fractionCategory: FractionOption,
    $unitPriceFileName: String,
    $updatedUserId : Int,
    $createdUserId : Int,
  ){
	updateUnitPriceById (input:{
		id : $id,
            unitPricePatch : {
                unitPriceName: $unitPriceName,
                unitPriceCategory: $unitPriceCategory,
                fractionCategory: $fractionCategory,
                unitPriceFileName: $unitPriceFileName,
                createdUserId : $createdUserId,
                updatedUserId : $updatedUserId
            }
        }
	){ unitPrice { unitPriceName }}
}
`;

const deleteById = gql`
mutation deleteUnitPriceById($id:Int!){
    unitPricesDelMutate(input: {unitPriceId: $id}) {
      result
    }
  }
`;

export {getAll, getTransportationPrice, getVehiclePrice, create, getById, updateById, deleteById};