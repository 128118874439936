import React, {useContext, Fragment, useEffect} from 'react';
import {
    Chart,
    Geom,
    Axis,
    Tooltip,
    Legend
} from 'bizcharts';
import { Spin } from 'antd';
import moment from 'moment';
import {Context} from '../../../commons/Context';
import DataSet from "@antv/data-set";
import _ from 'lodash';
import 'moment/locale/ja';

moment.locale('ja');

const Graph = ({lt}) => {
    const {state, dispatch} = useContext(Context);
    
    
    // const cols = {
    //   total: {
    //     tickInterval: 10000
    //   }
    // };
    const {
      customerName, 
      transportDate_From_Year, 
      transportDate_From_Month, 
      transportDate_To_Year, 
      transportDate_To_Month
    } = state.filters;

    let fields = _.chain(state.graph).map(obj=>{
                    return _.omit(obj,["name"]);
                  }).map(value=>{
                    return Object.keys(value);
                  }).value();

    fields = fields.length ? Object.assign(...fields) : [];

    const ds = new DataSet();
    const dv = ds.createView().source(state.graph);

    dv.transform({
      type: "fold",
      fields: fields,
      key: "date",
      value: "total"
    });

    useEffect(() => {
      if(dv.rows.length && state.spinner){
        dispatch({
          type: 'spinner',
          spinner: false
        })
      }
    },[state.graph]);
    
    return (
      <Fragment>
        <div className='head' style={{textAlign:'center', fontWeight: 'bold'}}>{lt('lbl_sales_by_customer_chart')}</div>
        <div className='chartDate'>{customerName !== undefined && `${lt('lbl_analysis_year/month')}: ${moment().format('YYYY年MM月') }`}</div>
        <div className='chartDate'>{customerName !== undefined && `${lt('対象顧客')}: ${customerName}`}</div>
        <div className='chartDate' style={{marginBottom: 15}}>
          {customerName !== undefined && `${lt('出力年')}:  
          ${moment(`${transportDate_From_Year}-${transportDate_From_Month}`).format('YYYY年MM月') } ～ 
          ${moment(`${transportDate_To_Year}-${transportDate_To_Month}`).format('YYYY年MM月') }`}
        </div>
          <Spin spinning={state.spinner} size='large'>
          <Chart height={600} data={dv} forceFit>
            <Axis name="date" />
            <Axis name="total" 
                label={{
                  formatter: val => `${val.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
                }}/>
            <Legend />
            <Tooltip
              crosshairs={{
                type: "y"
              }}
            />
            <Geom
              type="interval"
              position="date*total"
              color={"name"}
              adjust={[
                {
                  type: "dodge",
                  marginRatio: 1 / 32
                }
              ]}
            />
          </Chart>
          </Spin>
      </Fragment>
    );
}
export default Graph;