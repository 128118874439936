import React from 'react';
import AEDBase from '../commons/AEDBase';
import ListBase from '../commons/ListBase';
import ReportBase from '../commons/ReportBase';
import DispatchConfig from '../components/Dispatch/DispatchConfig';
import DispatchStatusBase from "../components/DispatchStatus/Base";
import EstimateConfig from '../components/Estimate/EstimateConfig';
import EstimateList from '../components/Estimate/List';
import TransportationDetailsConfig from '../components/Estimate/TransportationDetailsConfig';
import InvoiceConfig from '../components/Invoice/InvoiceConfig';
import CustomerConfig from '../components/MasterMaintenance/Customer/CustomerConfig';
import CustomerList from '../components/MasterMaintenance/Customer/List';
import DriverLeaveConfig from '../components/MasterMaintenance/DriverLeave/DriverLeaveConfig';
import DriverLeaveList from '../components/MasterMaintenance/DriverLeave/List';
import HolidaysConfig from '../components/MasterMaintenance/Holidays/HolidaysConfig';
import PackageList from '../components/MasterMaintenance/Package/List';
import PackageConfig from '../components/MasterMaintenance/Package/PackageConfig';
import UnitPriceList from '../components/MasterMaintenance/UnitPrice/List';
import UnitPriceConfig from '../components/MasterMaintenance/UnitPrice/UnitPriceConfig';
import UserList from '../components/MasterMaintenance/User/List';
// import TransportationDetailsList from '../components/Estimate/List';
/** module configs **/
import UserConfig from '../components/MasterMaintenance/User/UserConfig';
import VehicleList from '../components/MasterMaintenance/Vehicle/List';
import VehicleConfig from '../components/MasterMaintenance/Vehicle/VehicleConfig';
import OrderScreen from '../components/Orders';
import SalesByCustomerConfig from '../components/Reporting/SalesByCustomer/Config';
import SalesByCustomerMultipleConfig from '../components/Reporting/SalesByCustomerMultiple/Config';
import SalesByDriverConfig from '../components/Reporting/SalesByDriver/Config';
import VehiclePerformance from '../components/Reporting/VehicleAnalysis/Config';
// import OutputEstimateConfig from '../components/Accounting/OutputEstimateConfig';
import adhocTranslator from "../utils/locales/translate-adhoc";

// import AccountingBase from '../commons/AccountingBase';

let lt = adhocTranslator('K010-010');

export default () => {
    return {
        admin: [
            /** Orders Dispatch **/
            {
                name: lt('btn_orders-dispatch_menu'),
                icon: 'fund',
                components: [
                    {
                        path: '/order/search-list',
                        name: lt('btn_orders-dispatch_list'),
                        icon: 'ordered-list',
                        // component : () => <ListBase order={true} bordered={true} config={OrderList()}/>
                        component: () => <OrderScreen />
                    },
                    // {
                    //     path: '/order/register',
                    //     name: '登録',
                    //     con: 'usergroup-add',
                    //     hidden: true,
                    //     component: () => <AEDBase config={OrderConfig({ mode: 'add' })} />
                    // },
                    // {
                    //     hidden : true,
                    //     path: '/order/update/:id',
                    //     name: ' Dispatch List update info',
                    //     icon: 'usergroup-add',
                    //     component: () => <AEDBase config={OrderConfig({mode:'edit'})}/>
                    // },
                    // {
                    //     hidden : true,
                    //     path: '/order/delete/:id',
                    //     name: ' Dispatch List delete info',
                    //     icon: 'usergroup-add',
                    //     component: () => <AEDBase config={OrderConfig({mode:'delete'})}/>
                    // }

                ]
            },

            /** Dispatch Status **/
            {
                name: lt('btn_dispatch_status_menu'),
                icon: 'setting',
                components: [
                    {
                        path: '/dispatch-status',
                        name: lt('btn_confirm_distribution_status'),
                        icon: 'audit',
                        component: () => <DispatchStatusBase />
                    }
                ]
            },

            /** Accounting operation **/
            {
                name: lt('btn_accounting_operations_menu'),
                icon: 'api',
                components: [
                    {
                        path: '/output-estimate',
                        name: lt('btn_create_estimate'),
                        icon: 'book',
                        component: () => <ListBase config={EstimateList()} />
                    },
                    {
                        hidden: true,
                        name: lt('btn_create_estimate'),
                        icon: 'setting',
                        components: [{
                            path: '/create-estimate/register',
                            component: () => <AEDBase config={EstimateConfig({ mode: 'add' })} />
                        }, {
                            path: '/create-estimate/update/:id',
                            component: () => <AEDBase config={EstimateConfig({ mode: 'edit' })} />
                        }, {
                            path: '/create-estimate/delete/:id',
                            component: () => <AEDBase config={EstimateConfig({ mode: 'delete' })} />
                        }, {
                            path: '/transportation-details/register',
                            name: 'transportation details',
                            icon: 'setting',
                            component: () => <AEDBase config={TransportationDetailsConfig({ mode: 'add' })} />
                        }, {
                            path: '/transportation-details/update/:id',
                            name: 'transportation details',
                            icon: 'setting',
                            component: () => <AEDBase config={TransportationDetailsConfig({ mode: 'edit' })} />
                        }, {
                            path: '/transportation-details/delete/:id',
                            name: 'transportation details',
                            icon: 'setting',
                            component: () => <AEDBase config={TransportationDetailsConfig({ mode: 'delete' })} />
                        }]
                    },
                    {
                        path: '/create-invoice',
                        name: lt('btn_create_invoice'),
                        icon: 'file-search',
                        component: () => <AEDBase config={InvoiceConfig({ mode: 'add' })} />
                    },
                    // {
                    //     path : '/manage-operation-hours',
                    //     name : lt('btn_manage_operation_hours'),
                    //     icon : 'project',
                    //     component : ()=><AEDBase config={CrewPerformanceConfig({mode:'add'})}/>
                    // },
                    {
                        path: '/output-dispatch-chart',
                        name: lt('btn_output_dispatch_chart'),
                        icon: 'table',
                        component: () => <AEDBase config={DispatchConfig({ mode: 'add' })} />
                    }
                ]
            },

            /** Analysis **/
            {
                name: lt('btn_analysis_menu'),
                icon: 'line-chart',
                components: [
                    {
                        path: '/sales-by-customer',
                        name: lt('btn_sales_by_customer'),
                        icon: 'line-chart',
                        component: () => <ReportBase config={SalesByCustomerConfig()} />
                    },
                    {
                        path: '/compare-sales-of-multiple-customers',
                        name: lt('btn_compare_sales_of_multiple_customers'),
                        icon: 'line-chart',
                        component: () => <ReportBase config={SalesByCustomerMultipleConfig()} />
                    },
                    {
                        path: '/sales-by-driver',
                        name: lt('btn_sales_by_driver'),
                        icon: 'line-chart',
                        component: () => <ReportBase config={SalesByDriverConfig()} />
                    },
                    {
                        path: '/vehicle-performance',
                        name: lt('btn_vehicle_performance'),
                        icon: 'line-chart',
                        component: () => <ReportBase config={VehiclePerformance()} />
                    }
                ]
            },

            /** Master Maintenance **/
            {
                name: lt('btn_master_maintenance_menu'),
                icon: 'folder-open',
                components: [
                    /** User info **/
                    {
                        name: lt('btn_user_info_menu'),
                        icon: 'team',
                        components: [
                            {
                                path: '/user/search-list',
                                name: lt('btn_search_and_list'),
                                icon: 'bars',
                                component: () => <ListBase config={UserList()} />
                            },
                            {
                                path: '/user/register',
                                name: lt('btn_register_new_info'),
                                icon: 'plus-circle',
                                component: () => <AEDBase config={UserConfig({ mode: 'add' })} />
                            },
                            {
                                hidden: true,
                                path: '/user/update/:id',
                                name: 'User update info',
                                icon: 'idcard',
                                component: () => <AEDBase config={UserConfig({ mode: 'edit' })} />
                            },
                            {
                                hidden: true,
                                path: '/user/delete/:id',
                                name: 'User delete info',
                                icon: 'usergroup-add',
                                component: () => <AEDBase config={UserConfig({ mode: 'delete' })} />
                            }
                        ]
                    },
                    /** Customer info **/
                    {
                        name: lt('btn_customer_info_menu'),
                        icon: 'hdd',
                        components: [
                            {
                                path: '/customer/search-list',
                                name: lt('btn_search_and_list'),
                                icon: 'bars',
                                component: () => <ListBase config={CustomerList()} />
                            },
                            {
                                path: '/customer/register',
                                name: lt('btn_register_new_info'),
                                icon: 'plus-circle',
                                component: () => <AEDBase config={CustomerConfig({ mode: 'add' })} />
                            },
                            {
                                hidden: true,
                                path: '/customer/update/:id',
                                name: 'Customer update info',
                                icon: 'usergroup-add',
                                component: () => <AEDBase config={CustomerConfig({ mode: 'edit' })} />
                            },
                            {
                                hidden: true,
                                path: '/customer/delete/:id',
                                name: 'Customer delete info',
                                icon: 'usergroup-add',
                                component: () => <AEDBase config={CustomerConfig({ mode: 'delete' })} />
                            }
                        ]
                    },
                    /** Vehicle info **/
                    {
                        name: lt('btn_vehicle_info_menu'),
                        icon: 'car',
                        components: [
                            {
                                path: '/vehicle/search-list',
                                name: lt('btn_search_and_list'),
                                icon: 'bars',
                                component: () => <ListBase config={VehicleList()} />
                            },
                            {
                                path: '/vehicle/register',
                                name: lt('btn_register_new_info'),
                                icon: 'plus-circle',
                                component: () => <AEDBase config={VehicleConfig({ mode: 'add' })} />
                            },
                            {
                                hidden: true,
                                path: '/vehicle/update/:id',
                                name: 'Vehicle update info',
                                icon: 'usergroup-add',
                                component: () => <AEDBase config={VehicleConfig({ mode: 'edit' })} />
                            },
                            {
                                hidden: true,
                                path: '/vehicle/delete/:id',
                                name: 'Vehicle delete info',
                                icon: 'usergroup-add',
                                component: () => <AEDBase config={VehicleConfig({ mode: 'delete' })} />
                            }
                        ]
                    },
                    /** Packaging info **/
                    {
                        name: lt('btn_packaging_info_menu'),
                        icon: 'tags',
                        components: [
                            {
                                path: '/packaging/search-list',
                                name: lt('btn_search_and_list'),
                                icon: 'bars',
                                component: () => <ListBase config={PackageList()} />
                            },
                            {
                                path: '/packaging/register',
                                name: lt('btn_register_new_info'),
                                icon: 'plus-circle',
                                component: () => <AEDBase config={PackageConfig({ mode: 'add' })} />
                            },
                            {
                                hidden: true,
                                path: '/packaging/update/:id',
                                name: 'Packaging update info',
                                icon: 'usergroup-add',
                                component: () => <AEDBase config={PackageConfig({ mode: 'edit' })} />
                            },
                            {
                                hidden: true,
                                path: '/packaging/delete/:id',
                                name: 'Packaging delete info',
                                icon: 'usergroup-add',
                                component: () => <AEDBase config={PackageConfig({ mode: 'delete' })} />
                            }
                        ]
                    },
                    /** Transportation Destination info **/
                    // {
                    //     name : lt('btn_transportation_destination_info_menu'),
                    //     icon : 'compass',
                    //     components : [
                    //         {
                    //             path: '/transportation-destination/search-list',
                    //             name: lt('btn_search_and_list'),
                    //             icon: 'bars',
                    //             component: () => <ListBase config={TransportationList()}/>
                    //         },
                    //         {
                    //             path: '/transportation-destination/register',
                    //             name: lt('btn_register_new_info'),
                    //             icon: 'plus-circle',
                    //             component: () =><AEDBase config={TransportationConfig({mode:'add'})}/>
                    //         },
                    //         {
                    //             hidden : true,
                    //             path: '/transportation-destination/update/:id',
                    //             name: 'Transportation Destination update info',
                    //             icon: 'usergroup-add',
                    //             component: () =><AEDBase config={TransportationConfig({mode:'edit'})}/>
                    //         },
                    //         {
                    //             hidden : true,
                    //             path: '/transportation-destination/delete/:id',
                    //             name: 'Transportation Destination delete info',
                    //             icon: 'usergroup-add',
                    //             component: () =><AEDBase config={TransportationConfig({mode:'delete'})}/>
                    //         }
                    //     ]
                    // },
                    /** Driver Leave info **/
                    {
                        name: lt('btn_driver_leave_info_menu'),
                        icon: 'dot-chart',
                        components: [
                            {
                                path: '/driver-leave/search-list',
                                name: lt('btn_search_and_list'),
                                icon: 'bars',
                                component: () => <ListBase config={DriverLeaveList()} />
                            },
                            {
                                path: '/driver-leave/register',
                                name: lt('btn_register_new_info'),
                                icon: 'plus-circle',
                                component: () => <AEDBase config={DriverLeaveConfig({ mode: 'add' })} />
                            },
                            {
                                hidden: true,
                                path: '/driver-leave/update/:id',
                                name: 'Driver Leave update info',
                                icon: 'usergroup-add',
                                component: () => <AEDBase config={DriverLeaveConfig({ mode: 'edit' })} />
                            },
                            {
                                hidden: true,
                                path: '/driver-leave/delete/:id',
                                name: 'Driver Leave delete info',
                                icon: 'usergroup-add',
                                component: () => <AEDBase config={DriverLeaveConfig({ mode: 'delete' })} />
                            }
                        ]
                    },
                    /** Holidays **/
                    {
                        name: lt('休祝日情報'),
                        icon: 'calendar',
                        components: [
                            {
                                path: '/holidays/register',
                                name: lt('インポート/エクスポート'),
                                icon: 'plus-circle',
                                component: () => <AEDBase config={HolidaysConfig({ mode: 'add' })} />
                            },
                            {
                                hidden: true,
                                path: '/holidays/update/:id',
                                name: 'Holidays update info',
                                icon: 'usergroup-add',
                                component: () => <AEDBase config={HolidaysConfig({ mode: 'edit' })} />
                            },
                            {
                                hidden: true,
                                path: '/holidays/delete/:id',
                                name: 'Holidays delete info',
                                icon: 'usergroup-add',
                                component: () => <AEDBase config={HolidaysConfig({ mode: 'delete' })} />
                            }
                        ]
                    },
                    /** Unit Price info **/
                    {
                        name: lt('btn_unit_price_info_menu'),
                        icon: 'reconciliation',
                        components: [
                            {
                                path: '/unit-price/search-list',
                                name: lt('btn_search_and_list'),
                                icon: 'bars',
                                component: () => <ListBase config={UnitPriceList()} />
                            },
                            {
                                path: '/unit-price/register',
                                name: lt('btn_register_new_info'),
                                icon: 'plus-circle',
                                component: () => <AEDBase config={UnitPriceConfig({ mode: 'add' })} />
                            },
                            {
                                hidden: true,
                                path: '/unit-price/update/:id',
                                name: 'Unit Price update info',
                                icon: 'usergroup-add',
                                component: () => <AEDBase config={UnitPriceConfig({ mode: 'edit' })} />
                            },
                            {
                                hidden: true,
                                path: '/unit-price/delete/:id',
                                name: 'Unit Price delete info',
                                icon: 'usergroup-add',
                                component: () => <AEDBase config={UnitPriceConfig({ mode: 'delete' })} />
                            }
                        ]
                    }
                ]
            }
        ],
        customer: [
            {
                name: lt('btn_master_maintenance_menu'),
                icon: 'folder-open',
                components: [
                    /** Transportation Destination info **/
                    // {
                    //     name : lt('btn_transportation_destination_info_menu'),
                    //     icon : 'compass',
                    //     components : [
                    //         {
                    //             path: '/transportation-destination/search-list',
                    //             name: lt('btn_search_and_list'),
                    //             icon: 'bars',
                    //             component: () => <ListBase config={TransportationList()}/>
                    //         },
                    //         {
                    //             path: '/transportation-destination/register',
                    //             name: lt('btn_register_new_info'),
                    //             icon: 'plus-circle',
                    //             component: () =><AEDBase config={TransportationConfig({mode:'add'})}/>
                    //         },
                    //         {
                    //             hidden : true,
                    //             path: '/transportation-destination/update/:id',
                    //             name: 'Transportation Destination update info',
                    //             icon: 'usergroup-add',
                    //             component: () =><AEDBase config={TransportationConfig({mode:'edit'})}/>
                    //         },
                    //         {
                    //             hidden : true,
                    //             path: '/transportation-destination/delete/:id',
                    //             name: 'Transportation Destination delete info',
                    //             icon: 'usergroup-add',
                    //             component: () =><AEDBase config={TransportationConfig({mode:'delete'})}/>
                    //         }
                    //     ]
                    // }
                ]
            }
        ],
        driver: [
            /** Orders Dispatch **/
            {
                name: lt('btn_orders-dispatch_menu'),
                icon: 'fund',
                components: [
                    {
                        path: '/order/search-list',
                        name: lt('btn_orders-dispatch_list'),
                        icon: 'ordered-list',
                        component: () => <OrderScreen isDriver={true} />
                    },
                    // {
                    //     path : '/order/search-list',
                    //     name : lt('btn_orders-dispatch_list'),
                    //     icon : 'ordered-list',
                    //     component : () => <ListBase order={true} config={OrderList()}/>
                    // },
                    // {
                    //     path : '/contacts/search-list',
                    //     name : lt('btn_contacts_search_list'),
                    //     icon : 'ordered-list',
                    //     component: () => <ListBase  contact={true} config={TransportationList()}/>
                    // },
                    // {
                    //     hidden : true,
                    //     path: '/order/delete/:id',
                    //     name: ' Dispatch List delete info',
                    //     icon: 'usergroup-add',
                    //     component: () => <AEDBase config={OrderConfig({mode:'delete'})}/>
                    // }
                ]
            }
        ],
        user: [
            /** Orders Dispatch **/
            {
                name: lt('btn_orders-dispatch_menu'),
                icon: 'fund',
                components: [
                    {
                        path: '/order/search-list',
                        name: lt('btn_orders-dispatch_list'),
                        icon: 'ordered-list',
                        component: () => <OrderScreen />
                    },
                    // {
                    //     path : '/order/search-list',
                    //     name : lt('btn_orders-dispatch_list'),
                    //     icon : 'ordered-list',
                    //     component : () => <ListBase order={true} config={OrderList()}/>
                    // },
                    // {
                    //     path : '/order/register',
                    //     name : lt('btn_register_orders-dispatch'),
                    //     icon : 'team',
                    //     component : ()=><AEDBase config={OrderConfig({mode:'add'})}/>
                    // },
                    // {
                    //     hidden : true,
                    //     path: '/order/update/:id',
                    //     name: ' Dispatch List update info',
                    //     icon: 'usergroup-add',
                    //     component: () => <AEDBase config={OrderConfig({mode:'edit'})}/>
                    // },
                    // {
                    //     hidden : true,
                    //     path: '/order/delete/:id',
                    //     name: ' Dispatch List delete info',
                    //     icon: 'usergroup-add',
                    //     component: () => <AEDBase config={OrderConfig({mode:'delete'})}/>
                    // }

                ]
            },

            /** Dispatch Status **/
            {
                name: lt('btn_dispatch_status_menu'),
                icon: 'setting',
                components: [
                    {
                        path: '/dispatch-status',
                        name: lt('btn_confirm_distribution_status'),
                        icon: 'audit',
                        component: () => <DispatchStatusBase />
                    }
                ]
            },

            /** Accounting operation **/
            {
                name: lt('btn_accounting_operations_menu'),
                icon: 'api',
                components: [
                    {
                        path: '/output-estimate',
                        name: lt('btn_create_estimate'),
                        icon: 'book',
                        component: () => <ListBase config={EstimateList()} />
                    },
                    {
                        hidden: true,
                        name: lt('btn_create_estimate'),
                        icon: 'setting',
                        components: [{
                            path: '/create-estimate/register',
                            component: () => <AEDBase config={EstimateConfig({ mode: 'add' })} />
                        }, {
                            path: '/create-estimate/update/:id',
                            component: () => <AEDBase config={EstimateConfig({ mode: 'edit' })} />
                        }, {
                            path: '/create-estimate/delete/:id',
                            component: () => <AEDBase config={EstimateConfig({ mode: 'delete' })} />
                        }, {
                            path: '/transportation-details/register',
                            name: 'transportation details',
                            icon: 'setting',
                            component: () => <AEDBase config={TransportationDetailsConfig({ mode: 'add' })} />
                        }, {
                            path: '/transportation-details/update/:id',
                            name: 'transportation details',
                            icon: 'setting',
                            component: () => <AEDBase config={TransportationDetailsConfig({ mode: 'edit' })} />
                        }, {
                            path: '/transportation-details/delete/:id',
                            name: 'transportation details',
                            icon: 'setting',
                            component: () => <AEDBase config={TransportationDetailsConfig({ mode: 'delete' })} />
                        }]
                    },
                    {
                        path: '/create-invoice',
                        name: lt('btn_create_invoice'),
                        icon: 'file-search',
                        component: () => <AEDBase config={InvoiceConfig({ mode: 'add' })} />
                    },
                    // {
                    //     path : '/manage-operation-hours',
                    //     name : lt('btn_manage_operation_hours'),
                    //     icon : 'project',
                    //     component : ()=><AEDBase config={CrewPerformanceConfig({mode:'add'})}/>
                    // },
                    {
                        path: '/output-dispatch-chart',
                        name: lt('btn_output_dispatch_chart'),
                        icon: 'table',
                        component: () => <AEDBase config={DispatchConfig({ mode: 'add' })} />
                    }
                ]
            },
            /** Master Maintenance **/
            {
                name: lt('btn_master_maintenance_menu'),
                icon: 'folder-open',
                components: [
                    /** User info **/
                    {
                        name: lt('btn_user_info_menu'),
                        icon: 'team',
                        components: [
                            {
                                path: '/user/search-list',
                                name: lt('btn_search_and_list'),
                                icon: 'bars',
                                component: () => <ListBase bordered={true} config={UserList()} />
                            },
                            {
                                path: '/user/register',
                                name: lt('btn_register_new_info'),
                                icon: 'plus-circle',
                                component: () => <AEDBase config={UserConfig({ mode: 'add' })} />
                            },
                            {
                                hidden: true,
                                path: '/user/update/:id',
                                name: 'User update info',
                                icon: 'idcard',
                                component: () => <AEDBase config={UserConfig({ mode: 'edit' })} />
                            },
                            {
                                hidden: true,
                                path: '/user/delete/:id',
                                name: 'User delete info',
                                icon: 'usergroup-add',
                                component: () => <AEDBase config={UserConfig({ mode: 'delete' })} />
                            }
                        ]
                    },
                    /** Customer info **/
                    {
                        name: lt('btn_customer_info_menu'),
                        icon: 'hdd',
                        components: [
                            {
                                path: '/customer/search-list',
                                name: lt('btn_search_and_list'),
                                icon: 'bars',
                                component: () => <ListBase config={CustomerList()} />
                            },
                            {
                                path: '/customer/register',
                                name: lt('btn_register_new_info'),
                                icon: 'plus-circle',
                                component: () => <AEDBase config={CustomerConfig({ mode: 'add' })} />
                            },
                            {
                                hidden: true,
                                path: '/customer/update/:id',
                                name: 'Customer update info',
                                icon: 'usergroup-add',
                                component: () => <AEDBase config={CustomerConfig({ mode: 'edit' })} />
                            },
                            {
                                hidden: true,
                                path: '/customer/delete/:id',
                                name: 'Customer delete info',
                                icon: 'usergroup-add',
                                component: () => <AEDBase config={CustomerConfig({ mode: 'delete' })} />
                            }
                        ]
                    },
                    /** Vehicle info **/
                    {
                        name: lt('btn_vehicle_info_menu'),
                        icon: 'car',
                        components: [
                            {
                                path: '/vehicle/search-list',
                                name: lt('btn_search_and_list'),
                                icon: 'bars',
                                component: () => <ListBase config={VehicleList()} />
                            },
                            {
                                path: '/vehicle/register',
                                name: lt('btn_register_new_info'),
                                icon: 'plus-circle',
                                component: () => <AEDBase config={VehicleConfig({ mode: 'add' })} />
                            },
                            {
                                hidden: true,
                                path: '/vehicle/update/:id',
                                name: 'Vehicle update info',
                                icon: 'usergroup-add',
                                component: () => <AEDBase config={VehicleConfig({ mode: 'edit' })} />
                            },
                            {
                                hidden: true,
                                path: '/vehicle/delete/:id',
                                name: 'Vehicle delete info',
                                icon: 'usergroup-add',
                                component: () => <AEDBase config={VehicleConfig({ mode: 'delete' })} />
                            }
                        ]
                    },
                    /** Packaging info **/
                    {
                        name: lt('btn_packaging_info_menu'),
                        icon: 'tags',
                        components: [
                            {
                                path: '/packaging/search-list',
                                name: lt('btn_search_and_list'),
                                icon: 'bars',
                                component: () => <ListBase config={PackageList()} />
                            },
                            {
                                path: '/packaging/register',
                                name: lt('btn_register_new_info'),
                                icon: 'plus-circle',
                                component: () => <AEDBase config={PackageConfig({ mode: 'add' })} />
                            },
                            {
                                hidden: true,
                                path: '/packaging/update/:id',
                                name: 'Packaging update info',
                                icon: 'usergroup-add',
                                component: () => <AEDBase config={PackageConfig({ mode: 'edit' })} />
                            },
                            {
                                hidden: true,
                                path: '/packaging/delete/:id',
                                name: 'Packaging delete info',
                                icon: 'usergroup-add',
                                component: () => <AEDBase config={PackageConfig({ mode: 'delete' })} />
                            }
                        ]
                    },
                    /** Transportation Destination info **/
                    // {
                    //     name : lt('btn_transportation_destination_info_menu'),
                    //     icon : 'compass',
                    //     components : [
                    //         {
                    //             path: '/transportation-destination/search-list',
                    //             name: lt('btn_search_and_list'),
                    //             icon: 'bars',
                    //             component: () => <ListBase config={TransportationList()}/>
                    //         },
                    //         {
                    //             path: '/transportation-destination/register',
                    //             name: lt('btn_register_new_info'),
                    //             icon: 'plus-circle',
                    //             component: () =><AEDBase config={TransportationConfig({mode:'add'})}/>
                    //         },
                    //         {
                    //             hidden : true,
                    //             path: '/transportation-destination/update/:id',
                    //             name: 'Transportation Destination update info',
                    //             icon: 'usergroup-add',
                    //             component: () =><AEDBase config={TransportationConfig({mode:'edit'})}/>
                    //         },
                    //         {
                    //             hidden : true,
                    //             path: '/transportation-destination/delete/:id',
                    //             name: 'Transportation Destination delete info',
                    //             icon: 'usergroup-add',
                    //             component: () =><AEDBase config={TransportationConfig({mode:'delete'})}/>
                    //         }
                    //     ]
                    // },
                    /** Driver Leave info **/
                    {
                        name: lt('btn_driver_leave_info_menu'),
                        icon: 'dot-chart',
                        components: [
                            {
                                path: '/driver-leave/search-list',
                                name: lt('btn_search_and_list'),
                                icon: 'bars',
                                component: () => <ListBase config={DriverLeaveList()} />
                            },
                            {
                                path: '/driver-leave/register',
                                name: lt('btn_register_new_info'),
                                icon: 'plus-circle',
                                component: () => <AEDBase config={DriverLeaveConfig({ mode: 'add' })} />
                            },
                            {
                                hidden: true,
                                path: '/driver-leave/update/:id',
                                name: 'Driver Leave update info',
                                icon: 'usergroup-add',
                                component: () => <AEDBase config={DriverLeaveConfig({ mode: 'edit' })} />
                            },
                            {
                                hidden: true,
                                path: '/driver-leave/delete/:id',
                                name: 'Driver Leave delete info',
                                icon: 'usergroup-add',
                                component: () => <AEDBase config={DriverLeaveConfig({ mode: 'delete' })} />
                            }
                        ]
                    },
                    /** Holidays **/
                    {
                        name: lt('休祝日情報'),
                        icon: 'calendar',
                        components: [
                            {
                                path: '/holidays/register',
                                name: lt('インポート/エクスポート'),
                                icon: 'plus-circle',
                                component: () => <AEDBase config={HolidaysConfig({ mode: 'add' })} />
                            },
                            {
                                hidden: true,
                                path: '/holidays/update/:id',
                                name: 'Holidays update info',
                                icon: 'usergroup-add',
                                component: () => <AEDBase config={HolidaysConfig({ mode: 'edit' })} />
                            },
                            {
                                hidden: true,
                                path: '/holidays/delete/:id',
                                name: 'Holidays delete info',
                                icon: 'usergroup-add',
                                component: () => <AEDBase config={HolidaysConfig({ mode: 'delete' })} />
                            }
                        ]
                    },
                    /** Unit Price info **/
                    {
                        name: lt('btn_unit_price_info_menu'),
                        icon: 'reconciliation',
                        components: [
                            {
                                path: '/unit-price/search-list',
                                name: lt('btn_search_and_list'),
                                icon: 'bars',
                                component: () => <ListBase config={UnitPriceList()} />
                            },
                            {
                                path: '/unit-price/register',
                                name: lt('btn_register_new_info'),
                                icon: 'plus-circle',
                                component: () => <AEDBase config={UnitPriceConfig({ mode: 'add' })} />
                            },
                            {
                                hidden: true,
                                path: '/unit-price/update/:id',
                                name: 'Unit Price update info',
                                icon: 'usergroup-add',
                                component: () => <AEDBase config={UnitPriceConfig({ mode: 'edit' })} />
                            },
                            {
                                hidden: true,
                                path: '/unit-price/delete/:id',
                                name: 'Unit Price delete info',
                                icon: 'usergroup-add',
                                component: () => <AEDBase config={UnitPriceConfig({ mode: 'delete' })} />
                            }
                        ]
                    }
                ]
            }
        ]
    }
};
