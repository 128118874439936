import { Button, Form, Modal, notification, Row, Select, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { withApollo } from 'react-apollo';
import { vehicleTypes } from '../../../../OrdersAction/constants';
import { GET_VEHICLES } from '../../../queries';

const ChangeModal = (props) => {
  const { onCancel, onOk, visible, client, form: { getFieldDecorator, getFieldsValue } } = props;

  const [options, setOptions] = useState([])

  const notify = (type, message) => {
    notification[type]({
      message: message,
    });
  };

  useEffect(() => {
    (async () => {
      const { data } = await client.query({
        query: GET_VEHICLES,
        fetchPolicy: 'network-only',
        variables: {
          filter: { status: { equalTo: 'ACTIVE' } }
        }
      });

      setOptions(data.allVehicles.nodes);
    })()
  }, []);

  const onSubmit = () => {
    const { vehicle } = getFieldsValue();

    if (!vehicle) {
      // open notification
      notify('error', '号車を選択して変更ボタンを押してください。');
      return;
    }

    const vehicleData = options.find(opt => opt.id === +vehicle);
    onOk(vehicleData);
  };

  return (
    <Modal
      visible={visible}
      maskClosable={false}
      onCancel={onCancel}
      className='modal-vehicle'
      footer={[
        <Button key="submit" type="primary" onClick={onSubmit}>変更</Button>,
        <Button key="back" onClick={onCancel}>戻る</Button>,
      ]}
    >
      <Row>
        <Row type='flex' justify='center' style={{ marginBottom: 30 }}>
          <Typography style={{ fontWeight: 'bold' }}>配車変更</Typography>
        </Row>

        <Row type='flex' style={{ marginBottom: 15 }}>
          <Typography>一覧画面で選択した受注・配車割当情報を以下の号車に変更します。 </Typography>
          <Typography>号車を選択して変更ボタンを押してください。</Typography>
        </Row>
        <Form>
          <Row type='flex' className='vertical-align'>
            <Typography.Text style={{ marginRight: 120, paddingTop: 5 }}>号車</Typography.Text>
            <Form.Item style={{ marginBottom: 15 }}>
              {getFieldDecorator('vehicle')(
                <Select
                  style={{ width: 200 }}
                >
                  {options
                    .filter(({ vehicleType }) =>
                      vehicleType !== vehicleTypes.ESCORT_VEHICLE_COMPANY &&
                      vehicleType !== vehicleTypes.ESCORT_VEHICLE_OUTSOURCE
                    )
                    .map(({ id, vehicleNo }) => (
                      <Select.Option
                        key={id}
                        value={id}
                      >
                        {vehicleNo}
                      </Select.Option>
                    ))}
                  {/* 
                  {options.map(option => (
                    <Select.Option
                      key={option.id}
                      value={option.id}
                    >
                      {option.vehicleNo}
                    </Select.Option>
                  ))} */}
                </Select>
              )}
            </Form.Item>
          </Row>
        </Form>
      </Row>
    </Modal>
  )
};

export default withApollo(Form.create()(ChangeModal));