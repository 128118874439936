import React, { Fragment, Component } from 'react';
import { Button, Modal, Col, Row, Form, AutoComplete } from "antd";
import fullwidth from 'fullwidth';
import ListBase from './ListBase';
import CustomHiddenInput from './CustomDataEntry/CustomHiddenInput';
import CustomMap from './CustomDataEntry/CustomMap';
import { withApollo } from "react-apollo";
import helper from '../utils/helper';

const filterTypeField = {
    customer: 'nameJaKatakana_Like_nameJa_Like',
    packing: 'packagingName_Like',
    vehicle: 'vehicleNo_Like',
    driver: 'fullNameJaKatakana_Like_fullNameJa_Like',
}
class ModalBase extends Component {

    state = {
        show: false,
        currentPage: 1,
        selectedRow: null,
        selectedRows: null,
        position: {
            lat: null,
            lng: null
        },
        isCenter: false,
        queryData: [],
        autoCompleteDataSource: []
    };

    invalidDataMessages = {
        allCustomers: "存在しない顧客です。",
        allPackagings: "荷姿が存在しません。",
        allUsers: "ユーザが存在しません。",
        allUnitPrices: "単価が存在しません。",
        allVehicles: "号車が存在しません。"
    }

    popModal = () => {
        this.setState({
            show: true
        });
    };

    handleCancel = () => {
        this.setState({
            show: false,
            position: {
                lat: null,
                lang: null
            },
            selectedRow: null
        });

    };

    handleCheckTransport = () => {
        this.props.form.validateFields([
            'destinationChecker',
            'ordersConfig',
            'originId_1',
            'originId_1-display',
            'originId_2',
            'originId_2-display',
            'originId_3',
            'originId_3-display',
            'destinationId_1',
            'destinationId_1-display',
            'destinationId_2',
            'destinationId_2-display',
            'destinationId_3',
            'destinationId_3-display',
        ], { first: true }, (err, values) => {
            if (!err) {
                if (values.ordersConfig) {
                    let orderTransportDestinations = {};

                    Object.keys(values).forEach((key) => {
                        if ((key.split('-').length > 1) || !values[key]) {
                            return;
                        }
                        let keyArray = key.split('_');
                        if (keyArray.length > 1) {
                            let orderTransportDestinationKey = keyArray.shift();
                            let orderTransportDestinationId = keyArray.shift();
                            if (!orderTransportDestinations[orderTransportDestinationId]) {
                                orderTransportDestinations[orderTransportDestinationId] = {};
                                orderTransportDestinations[orderTransportDestinationId].sequenceNo = +orderTransportDestinationId;
                            }
                            orderTransportDestinations[orderTransportDestinationId][orderTransportDestinationKey] = values[key];
                        }
                    });

                    let ordersDispatchDestination = Object.keys(orderTransportDestinations).map((key) => {
                        if (!orderTransportDestinations[key].originId && !orderTransportDestinations[key].destinationId) {
                            return null
                        }
                        return {
                            ordersTransportDestination: orderTransportDestinations[key]
                        };

                    }).filter((o) => !!o);
                    values.ordersDispatchDestination = ordersDispatchDestination;

                    this.props.form.setFieldsValue({
                        destinationChecker: values.ordersDispatchDestination
                    });
                }
            }
        })
    }

    handleDataProcess = (selectedRow, selectedRows) => {
        let {
            form,
            fieldName,
            config: { displayField, type },
            onChange
        } = this.props;

        if (type === 'radio') {
            let fieldsToChage = {};
            /** Needs to be dynamic if we make this common **/
            fieldsToChage[fieldName] = selectedRow.id;
            if (onChange) {
                onChange(selectedRow.packagingName);
            }
            fieldsToChage[`${fieldName}-display`] = selectedRow[displayField];

            form.setFieldsValue(fieldsToChage);
        }

        if (this.props.afterSelect) {
            if (type === 'radio') {
                this.props.afterSelect(selectedRow);

                //set new customerId onOk
                form.setFieldsValue({
                    customerId: this.props.form.getFieldValue('customerId')
                });
            } else if (type === 'checkbox') {
                this.props.afterSelect(selectedRows)
            }
        }

        this.handleCheckTransport()
    }

    handleOk = () => {
        if (!this.state.selectedRow && !this.state.selectedRows) {
            return;
        }
        this.setState({
            show: false
        });

        this.handleDataProcess(this.state.selectedRow, this.state.selectedRows)

        // PRESERVED COMMENTED CODES

        // let {
        //     form,
        //     fieldName,
        //     config: {displayField, type},
        //     onChange
        // } = this.props;

        // if(type === 'radio') {
        //     let fieldsToChage = {};
        //     /** Needs to be dynamic if we make this common **/
        //     fieldsToChage[fieldName] =  this.state.selectedRow.id;
        //     if(onChange) {
        //       onChange(this.state.selectedRow.packagingName);
        //     }
        //     fieldsToChage[`${fieldName}-display`] = this.state.selectedRow[displayField];

        //     form.setFieldsValue(fieldsToChage);
        // }

        // if (this.props.afterSelect) {
        //     if(type === 'radio') {
        //         this.props.afterSelect(this.state.selectedRow);

        //         //set new customerId onOkautoCompleteDataSource
        //         form.setFieldsValue({
        //             customerId: this.props.form.getFieldValue('customerId')
        //         });
        //     } else if (type === 'checkbox') {
        //         this.props.afterSelect(this.state.selectedRows)
        //     }
        // }

        // this.handleCheckTransport()

    };

    handleOnSelection = (selectedRowKeys, selectedRows, fromAutoComplete = false) => {
        let newObject = {};
        if (this.props.config.type === 'radio') {
            newObject = {
                selectedRow: selectedRows[0]
            };
            if (selectedRows.length > 0 && selectedRows[0].latitude) {
                newObject = {
                    selectedRow: selectedRows[0],
                    position: {
                        lat: selectedRows[0].latitude,
                        lng: selectedRows[0].longitude
                    },
                    isCenter: true
                };
            }
        } else {
            newObject = {
                selectedRows
            };
        }

        if (fromAutoComplete) {
            newObject = {
                selectedRow: selectedRows[0]
            }
        }

        this.setState(newObject);

        if (fromAutoComplete) {
            this.handleDataProcess(selectedRows[0], this.state.selectedRows)
        }

        this.resetFormData();
    };

    handleOnClear = (form, name, fieldName) => {
        // form.setFieldsValue({
        //     [name]: undefined,
        // });

        // form.setFieldsValue({
        //     [fieldName]: undefined,
        // });
        if (this.props.mode === 'edit') {
            if (form.getFieldValue([name])) {
                form.setFieldsValue({
                    [name]: null,
                });
            } else {
                form.resetFields([name]);
            }
            if (form.getFieldValue([fieldName])) {
                form.setFieldsValue({
                    [fieldName]: null,
                });
            } else {
                form.resetFields([fieldName]);
            }
        } else {
            if (form.getFieldValue([name])) {
                form.setFieldsValue({
                    [name]: undefined,
                });
            } else {
                form.resetFields([name]);
            }
            if (form.getFieldValue([fieldName])) {
                form.setFieldsValue({
                    [fieldName]: undefined,
                });
            } else {
                form.resetFields([fieldName]);
            }
        }

        this.setState({
            show: false,
            position: {
                lat: null,
                lang: null
            },
            selectedRow: null
        });

        this.handleCheckTransport()

        const destinationChecker = form.getFieldValue('destinationChecker');
        if (destinationChecker) {
            if (destinationChecker.length < 1) {
                form.setFieldsValue({ destinationChecker: null });
            }
        }
    }

    setDriver = async (id) => {
        let {
            form,
            fieldName,
            config
        } = this.props;
        let { data } = await this.props.client.query({
            query: config.fetchQuery,
            fetchPolicy: 'network-only',
            variables: {
                filter: {
                    vehicleNumber: {
                        equalTo: id
                    }
                }
            }
        });
        let fieldsToChage = {};
        fieldsToChage[fieldName] = null;
        fieldsToChage[fieldName + "-display"] = null;
        if (data.allUsers.nodes.length > 0) {
            fieldsToChage[fieldName] = data.allUsers.nodes[0].id;
            fieldsToChage[fieldName + "-display"] = data.allUsers.nodes[0].fullNameJa;
        }
        form.setFieldsValue(fieldsToChage);

    }

    queryAll = async () => {
        let { config, client } = this.props
        let { data } = await client.query({
            fetchPolicy: "network-only",
            query: config.fetchQuery,
            variables: {
                filters: {}
            }
        })
        this.setState({ queryData: data[config.allKey].nodes })
    }

    UNSAFE_componentWillMount() {
        this.queryAll()
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.queryAll()
        let { selectedDriver } = nextProps;
        let oldvalue = this.props.selectedDriver;
        if (selectedDriver && selectedDriver !== oldvalue) {
            this.setDriver(selectedDriver)
        }
    }

    UNSAFE_componentWillUnmount() {
        this.setState({ queryData: [] })
    }

    getFilterBasis = () => {
        let filterBasis

        switch (this.props.config.allKey) {
            case "allCustomers":
                filterBasis = 'nameJa'
                break;
            case "allPackagings":
                filterBasis = 'packagingName'
                break;
            case "allUsers":
                filterBasis = 'fullNameJa'
                break;
            case "allUnitPrices":
                filterBasis = 'unitPriceName'
                break;
            case "allVehicles":
                filterBasis = 'vehicleNo'
                break;
            default:
                filterBasis = ''
                break;
        }

        return filterBasis
    }

    checkQueryData = () => {
        let filteredActiveStatus = []

        if (this.props.filterStatus || this.props.filterStatusPackage) {
            filteredActiveStatus = this.state.queryData.filter(data => {
                return data.status === "ACTIVE"
            })
        } else {
            filteredActiveStatus = this.state.queryData
        }

        return filteredActiveStatus
    }

    setFormData = (value) => {
        const key = filterTypeField[this.props.filterType]
        if (this.props.filterType === 'vehicle') value = fullwidth(value);
        this.props.form.setFieldsValue({
            [key]: value
        });
    }

    resetFormData = () => {
        const key = filterTypeField[this.props.filterType]
        this.props.form.setFieldsValue({
            [key]: null
        });
    }

    onAutoCompleteFocus = field => () => {
        const autoCompleteValue = this.props.form.getFieldValue(field)

        if (!autoCompleteValue) {
            return this.setState({ autoCompleteDataSource: [] })
        }

        const filterBasis = this.getFilterBasis()
        const filteredActiveStatus = this.checkQueryData()

        let filtered = filteredActiveStatus.filter(data => {
            return helper.sanitizeTextValue(data[filterBasis]).indexOf(helper.sanitizeTextValue(autoCompleteValue)) >= 0
        })

        this.setState({ autoCompleteDataSource: filtered })
    }

    onAutoCompleteSearch = text => {
        const filterBasis = this.getFilterBasis()
        const filteredActiveStatus = this.checkQueryData()

        let filtered = filteredActiveStatus.filter(data => {
            return helper.sanitizeTextValue(data[filterBasis]).indexOf(helper.sanitizeTextValue(text)) >= 0
        })

        if (!text) filtered = []
        if (this.props.isFilter) {
            this.setFormData(text);
        }

        this.setState({ autoCompleteDataSource: filtered })
    }

    onAutoCompleteChange = value => {
        const filterBasis = this.getFilterBasis()

        let filtered = this.state.autoCompleteDataSource.filter(data => {
            return data[filterBasis].toLowerCase() === value.toLowerCase()
        })

        if (!value) filtered = []

        if (filtered.length) {
            this.handleOnSelection(null, filtered, true)
        }
    }

    renderAutocompleteOption = () => {
        const filterBasis = this.getFilterBasis()
        if (!this.state.autoCompleteDataSource.length) return

        return this.state.autoCompleteDataSource.map((data, index) => {
            return (
                <AutoComplete.Option key={index} value={data[filterBasis]}>
                    {data[filterBasis]}
                </AutoComplete.Option>
            )
        })
    }

    getValidator = (rule, value, callback) => {
        if (!value) callback()

        try {
            const filterBasis = this.getFilterBasis()
            const filteredActiveStatus = this.checkQueryData()

            let filtered = filteredActiveStatus.filter(data => {
                return helper.sanitizeTextValue(data[filterBasis]) === helper.sanitizeTextValue(value)
            })

            if (value && !filtered.length) {
                throw new Error(this.invalidDataMessages[this.props.config.allKey])
            } else {
                callback()
            }
        } catch (err) {
            callback(err)
        }
    }

    constructDecorator = decorator => {
        if (!decorator || !decorator.rules) return { ...decorator }

        let newRules = decorator.rules

        if (!decorator.rules.find(rule => !!rule.validator)) {
            newRules.push({ validator: this.getValidator })
        }

        return { ...decorator, rules: newRules }
    }

    render() {
        let {
            form,
            width = 20,
            labelWidth = 4,
            label,
            title,
            mapWidth = 650,
            mode,
            hasMap = false,
            decorator,
            modalClass = 'mc',
            hiddenDecorator,
            filterType = null,
            btnLabel,
            fieldName,
            placeholder,
            hasVariable,
            packingWeight = 0,
            escortVehicleUnitPriceId,
            transportationUnitPriceId,
            dropdownClassName = '',
            offset,
            hideInputText,
            buttonMarginRight = 25,
            buttonMarginLeft = 25,
            destroyOnClose = true,
            inputWidth = 200,
            multiple = true,
        } = this.props;

        const formItemLayout = {
            labelCol: {  //for label width
                xs: { span: 24 },
                sm: { span: 8 },
                md: { span: 4 },
                lg: { span: labelWidth },
            },
            wrapperCol: { //for input width
                xs: { span: 24 },
                sm: { span: 24 },
                md: { span: width, offset },
            },
        };
        let variable = hasVariable ? {
            filter: {
                customerId: {
                    equalTo: hasVariable
                }
            }
        } : {}
        let renderList = <ListBase
            multiple={multiple}
            paginateOrder={variable}
            variable={variable}
            bordered={true}
            handleOnSelection={this.handleOnSelection}
            config={this.props.config}
            customerSelected={hasVariable}
            customFilterByDate={this.props.customFilterByDate}
            filterStatus={this.props.filterStatus}
            filterStatusPackage={this.props.filterStatusPackage}
            transportStatus={this.props.transportStatus}
        />

        return (<Fragment>
            <Modal
                style={{ top: 20 }}
                width={mapWidth}
                title={title}
                destroyOnClose={destroyOnClose}
                visible={this.state.show}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                footer={[
                    <Button key="submit" type="primary" onClick={this.handleOk} autoFocus>設定</Button>,
                    <Button key="clear" onClick={() => this.handleOnClear(form, `${fieldName}-display`, fieldName)}>解除</Button>,
                    <Button key="back" onClick={this.handleCancel}>戻る</Button>,
                ]}>
                {hasMap ? (
                    <Row>
                        <Col span={11}>
                            {renderList}
                        </Col>
                        <Col span={12} offset={1}>
                            <CustomMap isMarkerShown position={this.state.position} isCenter={true} />
                        </Col>
                    </Row>
                ) : (renderList)}

            </Modal>
            <Form.Item
                {...formItemLayout}
                label={label}
                className={`customModal ${modalClass}`}
            >
                <>
                    {fieldName === 'customerId' ?
                        <>
                            <CustomHiddenInput
                                form={form}
                                fieldName={`${fieldName}-escortVehicleUnitPriceId`}
                                type='hidden'
                                decorator={{ initialValue: escortVehicleUnitPriceId }}
                            />
                            <CustomHiddenInput
                                form={form}
                                fieldName={`${fieldName}-transportationUnitPriceId`}
                                type='hidden'
                                decorator={{ initialValue: transportationUnitPriceId }}
                            />
                        </>
                        : null}
                    {fieldName === 'packing1' ?
                        <CustomHiddenInput
                            form={form}
                            fieldName={`${fieldName}-weight`}
                            type='hidden'
                            decorator={{ initialValue: packingWeight }}
                        /> : null}
                    {!hideInputText && form.getFieldDecorator(`${fieldName}-display`, this.constructDecorator(decorator))(
                        // <Input disabled={true} type={type} placeholder={placeholder} maxLength={maxLength} onChange={onChange} style={{width: inputWidth}} />
                        <AutoComplete
                            className="modal-autocomplete"
                            dropdownClassName={dropdownClassName}
                            placeholder={placeholder}
                            style={{ width: inputWidth }}
                            disabled={this.props.isDriver ? this.props.isDriver : mode === 'delete' ? true : false}
                            dataSource={this.renderAutocompleteOption()}
                            onChange={this.onAutoCompleteSearch}
                            onSelect={this.onAutoCompleteChange}
                            onFocus={this.onAutoCompleteFocus(`${fieldName}-display`)}
                        />
                    )}
                    <CustomHiddenInput
                        form={form}
                        fieldName={fieldName}
                        type='hidden'
                        decorator={hiddenDecorator}
                    />
                    {filterType && (
                        <CustomHiddenInput
                            form={form}
                            fieldName={filterTypeField[filterType]}
                            type='hidden'
                            decorator={hiddenDecorator}
                        />
                    )}
                    <Button
                        type='primary'
                        disabled={this.props.isDriver ? this.props.isDriver : mode === 'delete' ? true : false}
                        onClick={this.popModal}
                        style={{ marginRight: buttonMarginRight, marginLeft: buttonMarginLeft }}
                        htmlType='button'
                    >
                        {btnLabel}
                    </Button>
                </>
            </Form.Item>
        </Fragment>
        );
    }
}

export default withApollo(ModalBase);