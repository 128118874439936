import { Col, Modal, Row, Typography, Upload } from 'antd';
import 'moment/locale/ja';
import React, { useCallback, useEffect, useState } from 'react';
import adhocTranslator from '../../../../../utils/locales/translate-adhoc';

const lt = adhocTranslator('K020-010');

const indexTypes = {
  0: '１',
  1: '２',
  2: '３',
  3: '４',
  4: '５'
}

const keyTypes = {
  assignee: {
    title: '担当者',
    pickup: 'pickupAssignee',
    dropoff: 'dropoffAssignee'
  },
  contact: {
    title: '連絡先',
    pickup: 'pickupContact',
    dropoff: 'dropoffContact'
  },
  borrower: {
    title: '借主',
    pickup: 'pickupBorrower',
    dropoff: 'dropoffBorrower'
  },
  memo: {
    title: 'メモ',
    pickup: 'pickupMaps',
    dropoff: 'dropoffMaps'
  },
  image: {
    title: '画像',
    pickup: 'pickupImages',
    dropoff: 'dropoffImages'
  }
};

const MapModal = (props) => {
  const { onCancel, onOk, visible, order = {}, type } = props;
  const [state, setState] = useState({
    pickups: [],
    dropoffs: [],
  })
  const [imageState, setImageState] = useState({
    previewVisible: false,
    previewImage: '',
    previewTitle: '',
  });

  const imageSetter = (value) => setImageState(state => ({ ...state, ...value }));
  
  useEffect(() => {
    if (visible) {
      const { pickup, dropoff } = keyTypes[type]
      setState({ pickups: order[pickup], dropoffs: order[dropoff] });
    }
  }, [visible]);

  const uploaderProps = {
    beforeUpload: () => false,
    accept: 'image/*',
    listType: 'picture-card',
    className: 'avatar-uploader',
    showUploadList: {
      showDownloadIcon: false,
      showPreviewIcon: true,
    },
  }

  const constructFileList = (data) => {
    return data.map(image => ({
      uid: image.imageNo,
      url: image.imageFile
    }))
  }

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  const onPreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    imageSetter({
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
    })
  };

  const renderData = useCallback((key) => {
    const data = state[key];
    let element;
    switch (type) {
      case 'memo':
        element = data.map(map => <Typography>
          <a
            onClick={() => window.open(map, "_blank")}
            target='_blank'
            style={{ textDecorationLine: 'underline', cursor: 'pointer' }}
            href={map}>{map}</a>
        </Typography>)
        break;
      case 'image':
        element = <Upload
          {...uploaderProps}
          fileList={constructFileList(data)}
          disabled={true}
          onPreview={onPreview}
        >
          <Typography></Typography>
        </Upload>
        break;
      case 'assignee':
        const assignees = data ? data.split('|') : [];
        element = assignees.map((assignee, index) => <Row>
          <Typography>担当者{indexTypes[index]}: {assignee}</Typography>
        </Row>)
        break;
      case 'contact':
        const contacts = data ? data.split('|') : [];
        element = contacts.map((contact, index) => <Row>
          <Typography>連絡先{indexTypes[index]}: {contact}</Typography>
        </Row>)
        break;
      case 'borrower':
        element = <Typography>{data}</Typography>
    };

    return element;
  }, [state]);

  return (
    <Modal
      visible={visible}
      onOK={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onOk();
      }}
      onCancel={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onCancel();
      }}
      className='modal-vehicle modal-details'
      width={800}
      footer={[
        <></>,
      ]}
    >
      <Row onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}>
        <Row type='flex' justify='center' style={{ marginBottom: 30 }}>
          <Typography style={{ fontWeight: 'bold' }}>{(keyTypes[type] || {}).title}</Typography>
        </Row>
        <Row style={{ paddingLeft: 20, paddingRight: 20 }}>
          <Row style={{ marginBottom: 20 }}>
            <Col span={12}>
              <Typography>発</Typography>
              {renderData('pickups')}
            </Col>
            <Col span={12}>
              <Typography>着</Typography>
              {renderData('dropoffs')}
            </Col>
          </Row>
        </Row>
      </Row>
      <Modal
        visible={imageState.previewVisible}
        title={imageState.previewTitle}
        footer={null}
        width={'80%'}
        onCancel={() => {
          imageSetter({ previewVisible: false })
        }}
      >
        <img alt="example" style={{ width: '100%' }} src={imageState.previewImage} />
      </Modal>
    </Modal>
  )
};

export default MapModal;