import React, {Component, Fragment} from 'react';

class Home extends Component {

	render() {
		return (
			<Fragment>
				
			</Fragment>
		);
	}

}


export default Home;