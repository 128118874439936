import React from 'react';
import { isMobile } from 'react-device-detect';
import { Redirect } from 'react-router-dom';
import NotFound from '../commons/NotFound';
import { init } from '../commons/SocketClient';
import Client from '../components/Login/LoginClient';
import Login from '../components/Login/LoginInternal';
import OrdersMobile from '../components/OrdersMobile';
import Main from '../layouts/Main';

const isAuthenticated = () => {
    const token = localStorage.getItem('token');
    if (token) {
        const decodeToken = JSON.parse(atob(token.split('.')[1]));
        return Math.floor(Date.now() / 1000) < decodeToken.exp; //check if expire
    }
    return token;
};

const authenticatedMiddleWare = (component) => {
    return () => {
        let token = isAuthenticated();
        if (token) {
            // Connect to socket io here
            init();
            return component;
        } else {
            localStorage.removeItem('token');
            return <Redirect to='/login' />;
        }
    }
};

const redirectedMiddleWare = (component) => {
    return () => {
        if (isAuthenticated()) {
            return <Redirect to='/' />;
        } else return component;
    }
};

export default [
    {
        path: '/login',
        name: 'Login',
        render: redirectedMiddleWare(<Login />)
    },
    {
        path: '/client',
        name: 'Client Login',
        render: redirectedMiddleWare(<Client />)
    },
    {
        path: '/404',
        name: 'Not Found',
        render: () => {
            return <NotFound />;
        }
    },
    { path: '/', name: 'Home', render: authenticatedMiddleWare(isMobile ? <OrdersMobile /> : <Main lastname={"Sacay"} />) },
];

