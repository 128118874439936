import { Button, Col, Modal, notification, Row } from 'antd';
import _ from 'lodash';
import 'moment/locale/ja';
import React, { useContext, useEffect, useState } from 'react';
import { withApollo } from 'react-apollo';
import { ordersDispatchBatchListUpdate, sendNotifications } from '../../../../queries/Orders';
import {
  DELETE_DEPARTURE,
  UPDATE_ORDER_COLORED,
  GET_CONNECTION_DETAILS,
  CONNECT_ESCORT,
  DISCONNECT_ESCORT,
  ESCORT_CONNECTED_BY_CONNECTION,
  DELETE_ESCORT_CONNECTED,
  CREATE_ORDER_CONNECTED,
  GET_DEPARTURES,
  DELETE_ORDER_CONNECTED,
  GET_LAST_CONNECTION,
  CREATE_ESCORT_CONNECTED,
  GET_ORDERS
} from '../../queries/index';
import { OrderContext } from '../../../../utils/context';
import adhocTranslator from '../../../../utils/locales/translate-adhoc';
import decodedToken from '../../../../utils/user_id';
import { tabTypes } from '../../constants';
import CancelModal from './modals/cancel-modal';
import ChangeModal from './modals/change-modal';
import ConfirmModal from './modals/confirm-modal';
import ReportModal from './modals/report-modal';
import SearchModal from './modals/seach-modal';
import moment from 'moment';
import { getUnitPrice } from '../../../../queries/Estimate/unitPrice';

const lt = adhocTranslator('K020-010');
const initialTransport = { origin: null, destination: null, destinationTime: null, originTime: null, };
const buttonProp = { tabIndex: '-1', type: 'primary', htmlType: 'button', style: { marginLeft: 10, width: 88 } };
const ActionsView = ({ client }) => {

  const { selected, view, editMode, orderList, orderSetter, modalSetter } = useContext(OrderContext);

  const [state, setState] = useState({
    change: false,
    confirm: false,
    cancel: false,
    report: false,
    connect: false,
    disconnect: false
  });

  const [connectState, setConnectState] = useState({
    connect: false,
    disconnect: false,
    escortConnect: false,
    escortDisconnect: false
  });

  const [isColored, setIsColored] = useState(false);

  const stateSetter = (value) => setState(state => ({ ...state, ...value }))

  const notify = (type, message) => {
    notification[type]({
      message: message,
    });
  };

  const onActionClick = (key) => () => stateSetter({ [key]: true });

  const onChangeOk = async ({ id, vehicleNo, usersByVehicleNumber = {} }) => {
    // Mutate
    console.log('change vehicle');
    await client.mutate({
      mutation: ordersDispatchBatchListUpdate,
      variables: {
        ordersDispatchIds: _.map(selected, 'id'),
        ordersDispatchVehicleId: id,
        updatedUserId: decodedToken().user_id
      },
    });

    const clearData = selected.map(async order => {
      console.log('order', order);
      if (order.vehicleId === id) return;

      // Set driver id notif
      if (order.dispatch === '配車') {
        const oldDriverId = order.driverId1;
        const newDriverId = usersByVehicleNumber.nodes[0].id;
        await client.mutate({
          mutation: sendNotifications,
          variables: {
            orders: JSON.stringify([{ ...order, driverId1: newDriverId }]),
            is_type_1: true
          },
        });

        await client.mutate({
          mutation: sendNotifications,
          variables: {
            orders: JSON.stringify([{ ...order, driverId1: oldDriverId }]),
            is_type_1: false
          },
        });
      }

      const { data } = await client.query({
        query: GET_DEPARTURES,
        fetchPolicy: 'network-only',
        variables: {
          orderId: order.id
        },
      });

      const departure = data.allDepartures.nodes;
      if (departure.length) {
        const departureMap = departure.map(async data => {
          await client.mutate({
            mutation: DELETE_DEPARTURE,
            variables: {
              id: data.id
            },
          });
        });
        await Promise.all(departureMap)
      }
    })

    // Delete orderId where vehicle id 
    await Promise.all(clearData);
    const order = (orderList || []).find(data => data.id === selected[0].id);
    const vehicle = ((order || {}).vehicleByVehicleId || {}).vehicleNo
    notify('success', `号車を${vehicle ? '' + vehicle + '' : ''} から ${vehicleNo} ${lt('vehicle_success_message')}`)
    stateSetter({ change: false });

    // refetch
    orderSetter({ refetch: true });
  };

  const onConfirmOk = async () => {
    const required = ['vehicleId', 'transportDate', 'dispatch', 'customerId', 'packaging1'];
    let isValid = true;
    let requiredStop = ''
    _.each(selected, (data) => {
      if (!isValid) return false;
      _.each(required, req => {
        isValid = !!data[req];
        requiredStop = req;
        if (!isValid) return false;
      });
      if (!isValid) return false; // stops the loop if above fails
      // check origin and destinaton
      _.each(data.transports, transport => {
        if (!transport.origin && !transport.destination) {
          isValid = false
          requiredStop = 'transports';
        }
      })
    });

    if (!isValid) {
      // vehicle
      if (requiredStop === 'vehicleId') {
        notify('warning', '号車を選択してください。');
        return
      }
      // transport origin/destination
      if (requiredStop === 'transports') {
        notify('warning', '発地・着地情報を入力してください。');
        return
      }
      //transportDate
      if (requiredStop === 'transportDate') {
        notify('warning', '運搬日を入力してください。');
        return
      }
    }

    // Mutate
    await client.mutate({
      mutation: ordersDispatchBatchListUpdate,
      variables: {
        ordersDispatchIds: _.map(selected, 'id'),
        ordersDispatchStatus: 'COMPLETED',
        updatedUserId: decodedToken().user_id
      },
    });

    // Get all orders
    const orders = selected.filter((data) => !!data.driverId1 && data.transportDate === moment().format('YYYY-MM-DD') && data.dispatch === "未配車");
    // Call api to process message sending
    if (orders.length) {
      await client.mutate({
        mutation: sendNotifications,
        variables: {
          orders: JSON.stringify(orders),
          is_type_1: true
        },
      });
    }

    notify('success', lt('vehicle_complete_message'));
    stateSetter({ confirm: false });

    // refetch
    orderSetter({ refetch: true });
  };

  const onCancelOk = async () => {
    let isValid = true;
    _.each(selected, (data) => {
      const { transports } = data;
      const hasActualTime = _.find(transports, (transport) => !!transport.dropoffActualDatetime || !!transport.pickupActualDatetime);
      if (!!hasActualTime) {
        isValid = false;
        return false;
      }
    });

    if (!isValid) {
      notify('warning', 'すでに運搬しています。発地または着地の実績時間を設定した配車は解除できません。');
      return;
    }

    // Mutate
    await client.mutate({
      mutation: ordersDispatchBatchListUpdate,
      variables: {
        ordersDispatchIds: _.map(selected, 'id'),
        ordersDispatchStatus: 'PENDING',
        updatedUserId: decodedToken().user_id
      },
    });

    notify('success', lt('vehicle_pending_message'));
    stateSetter({ cancel: false });

    // refetch
    orderSetter({ refetch: true });
  };

  const onReportOk = () => { };

  const onRegisterClick = () => {
    // Go to register page
    modalSetter({ visible: true });
  };

  const onEditClick = () => {
    // if (orderList && orderList.length) {
    orderSetter({ editMode: true });
    // }
  };

  const onEditSaveClick = () => {
    orderSetter({ editMode: false, editSave: true });
  };

  const checkViewAction = () => editMode ? onEditSaveClick : onEditClick

  const checkViewLabel = () => editMode ? '編集完了' : '編集'

  const setDisabled = () => {
    if (selected.length <= 0)
      return true;
    for (let index = 0; index < selected.length; index++) {
      //disable button(s) if selected free vehicles
      if (selected[index].vehicleUnused)
        return true;
    }
    return false;
  };

  const onDisconnectOrders = () => {
    Modal.confirm({
      title: '確認',
      content: '選択している運搬の連結解除しますか？',
      okText: 'はい',
      cancelText: 'いいえ',
      onOk: async () => {

        const connectionIds = _.uniq(_.compact(selected.map(data => data.orderConnectionId || null)));
        const queryMap = connectionIds.map(async id => {
          // Delete record
          await client.mutate({
            mutation: DELETE_ORDER_CONNECTED,
            variables: {
              id
            },
          });
        });
        await Promise.all(queryMap);
        notify('success', '連結を解除しました。');

        // do refetch
        orderSetter({ refetch: true, selected: [] });
      }
    });
  }

  const onDisconnectEscorts = () => {
    Modal.confirm({
      title: '確認',
      content: '誘導車連携を解除しますか？',
      okText: 'はい',
      cancelText: 'いいえ',
      onOk: async () => {
        // Query 
        const connectionIds = _.uniq(_.compact(selected.map(data => data.orderConnectionId || null)));
        const queryMap = connectionIds.map(async id => {
          // Delete record
          await client.mutate({
            mutation: DISCONNECT_ESCORT,
            variables: {
              connectId: id
            },
          });

          const response = await client.query({
            query: ESCORT_CONNECTED_BY_CONNECTION,
            fetchPolicy: 'network-only',
            variables: {
              connectId: id
            },
          });

          const { allOrdersEscortConnecteds } = response.data;
          allOrdersEscortConnecteds.nodes.map(async escortConnected => {
            await client.mutate({
              mutation: DELETE_ESCORT_CONNECTED,
              variables: {
                id: escortConnected.id
              },
            });
          });
        });

        await Promise.all(queryMap);
        notify('success', '連結を解除しました。');

        // do refetch
        orderSetter({ refetch: true, selected: [] });
      }
    });
  }

  const onConnect = (isDisconnect = false) => () => {
    if (isDisconnect) {
      onDisconnectOrders();
    } else {
      if (selected.length === 1) {
        return stateSetter({ connect: true })
      }
      onConnectOrders(selected)
    }
  };

  const onConnectEscortData = (isDisconnect = false) => () => {
    if (isDisconnect) {
      onDisconnectEscorts();
    } else {
      onConnectEscort(selected)
    }
  }

  const onConnectOrders = (orders) => {
    stateSetter({ connect: false });
    Modal.confirm({
      title: '確認',
      content: '選択している２つの運搬を連結しますか？',
      okText: 'はい',
      cancelText: 'いいえ',
      onOk: () => {
        Modal.confirm({
          title: '確認',
          content: '誘導車を連携しますか？',
          okText: 'はい',
          cancelText: 'いいえ',
          onOk: () => {
            connectOrders(orders, true);
          },
          onCancel: () => {
            connectOrders(orders, false);
          }
        })
      }
    });
  }

  const onConnectEscort = (orders) => {
    Modal.confirm({
      title: '確認',
      content: '誘導車を連携しますか？',
      okText: 'はい',
      cancelText: 'いいえ',
      onOk: () => {
        connectEscorts(orders, true);
      },
      onCancel: () => { }
    })
  }

  const connectEscorts = (orderList) => {
    const orders = _.orderBy(_.uniqBy(orderList, 'connectionId'), ['transportDate', 'displayOrder'], 'asc', 'asc');
    orders.map(async order => {
      // Delete record
      await client.mutate({
        mutation: CONNECT_ESCORT,
        variables: {
          connectId: order.orderConnectionId
        },
      });
      // GET THE TWO ORDERS
      const { data: { allOrdersConnecteds: { nodes } } } = await client.query({
        query: GET_CONNECTION_DETAILS,
        fetchPolicy: 'network-only',
        variables: {
          connectionId: order.connectionId
        }
      });
      if (nodes.length) {
        const { orderByOrderId1, orderByOrderId2 } = nodes[0];
        let escortList = [];
        orderByOrderId1.ordersTransportsByOrderId.nodes.map(getEscortList(escortList))
        orderByOrderId2.ordersTransportsByOrderId.nodes.map(getEscortList(escortList))
        escortList = escortList.filter(escort => escort.escorts.length === 2);
        const escortPromise = escortList.map(async ({ escorts }) => {
          const [escort1, escort2] = escorts;
          const totalDistance = escort1.distance + escort2.distance;
          const order1 = {
            packagingWeight: (orderByOrderId1.packagingByPacking1 || {}).weight,
            escortUnitPriceId: (orderByOrderId1.customerByCustomerId || {}).escortVehicleUnitPriceId
          };
          const order2 = {
            packagingWeight: (orderByOrderId2.packagingByPacking1 || {}).weight,
            escortUnitPriceId: (orderByOrderId2.customerByCustomerId || {}).escortVehicleUnitPriceId
          };
          const [distance, billing] = await getCombinedData(order1, order2, totalDistance);
          const driver = getDriverDetails(escort1, escort2, distance, billing)
          await client.mutate({
            mutation: CREATE_ESCORT_CONNECTED,
            variables: {
              id1: escort1.escortId,
              id2: escort2.escortId,
              billing1: driver.driver1Billing || 0,
              billing2: driver.driver2Billing || 0,
              input: {
                ordersEscortConnected: {
                  escortId1: escort1.escortId,
                  escortId2: escort2.escortId,
                  totalDistance: distance,
                  totalBilling: billing,
                  ordersConnectedId: order.orderConnectionId,
                  ...driver
                }
              }
            },
          });
        })
        await Promise.all(escortPromise);
        notify('success', '連結しました。')
        orderSetter({ selected: [], refetch: true });
      }
    });
  }

  const getOrderData = async ({ orderId }) => {
    const { data: { allOrders: { nodes } } } = await client.query({
      query: GET_ORDERS,
      fetchPolicy: 'network-only',
      variables: { 
        filter: { 
          isDeleted: { equalTo: false },
          id: { equalTo: orderId }
        } 
      }
    })

    if (nodes.length) {
      const [order] = nodes;
      const {
        customerByCustomerId = {},
        packagingByPacking1 = {},
        ordersTransportsByOrderId,
      } = order;
      const transports = ordersTransportsByOrderId.nodes.length ? _.map(_.orderBy(ordersTransportsByOrderId.nodes, 'transportNo'), (transport) => transport) : [{ ...initialTransport }];
      return {
        id: order.id,
        transportDate: order.transportDate,
        displayOrder: order.displayOrder,
        customerId: order.customerId,
        customerUnitPriceId: (customerByCustomerId || {}).transportationUnitPriceId,
        escortUnitPriceId: (customerByCustomerId || {}).escortVehicleUnitPriceId,
        customer: (customerByCustomerId || {}).nameJa,
        packagingId1: order.packing1,
        packagingWeight: (packagingByPacking1 || {}).weight,
        driverId1: order.driverId1,
        driverId2: order.driverId2,
        distance: order.transportDistance ? `${order.transportDistance.toLocaleString()}km` : null,
        billingAmount: order.transportBillingAmount ? `${order.transportBillingAmount.toLocaleString()}円` : null,
        paymentAmount: order.transportPaymentAmount ? `${order.transportPaymentAmount.toLocaleString()}円` : null,
        transports,
      }
    }

    return {}
  }

  const connectOrders = async (orders, isEscortConnected) => {
    const [order1, order2] = _.orderBy(orders, ['transportDate', 'displayOrder'], 'asc', 'asc');
    try {
      if (!order1.customerId || !order2.customerId || order1.customerId !== order2.customerId || order1.id === order2.id) {
        return notify('error', '連結したい運搬の顧客が同じではありません。同じ顧客を選択してください。')
      }
      if (!order1.packagingId1 || !order2.packagingId1 || order1.packagingId1 !== order2.packagingId1) {
        return notify('error', '接続する輸送用パッケージが同じではありません。同じパッケージを選択してください。')
      }
      const connectionId = await getLastConnectionNo();
      const [distance, billing] = await getCombinedData(order1, order2);
      const driver = getDriverDetails(order1, order2, distance, billing)
      const response = await client.mutate({
        mutation: CREATE_ORDER_CONNECTED,
        variables: {
          id1: order1.id,
          id2: order2.id,
          billing1: driver.driver1Billing || 0,
          billing2: driver.driver2Billing || 0,
          input: {
            ordersConnected: {
              escortConnected: isEscortConnected,
              orderId1: order1.id,
              orderId2: order2.id,
              connectionId: connectionId,
              totalDistance: distance,
              totalBilling: billing,
              ...driver
            }
          },
        },
      });

      const { ordersConnected } = response.data.a;
      if (isEscortConnected) {
        let escortList = [];
        order1.transports.map(getEscortList(escortList))
        order2.transports.map(getEscortList(escortList))
        escortList = escortList.filter(escort => escort.escorts.length === 2);
        const escortPromise = escortList.map(async ({ escorts }) => {
          const [escort1, escort2] = escorts;
          const totalDistance = escort1.distance + escort2.distance;
          const [distance, billing] = await getCombinedData(order1, order2, totalDistance);
          const driver = getDriverDetails(escort1, escort2, distance, billing)
          await client.mutate({
            mutation: CREATE_ESCORT_CONNECTED,
            variables: {
              id1: escort1.escortId,
              id2: escort2.escortId,
              billing1: driver.driver1Billing || 0,
              billing2: driver.driver2Billing || 0,
              input: {
                ordersEscortConnected: {
                  escortId1: escort1.escortId,
                  escortId2: escort2.escortId,
                  totalDistance: distance,
                  totalBilling: billing,
                  ordersConnectedId: ordersConnected.id,
                  ...driver
                }
              }
            },
          });
        })
        await Promise.all(escortPromise);
        orderSetter({ selected: [], refetch: true });
      } else {
        orderSetter({ selected: [], refetch: true });
      }
      notify('success', '連結しました。')
    } catch (e) {
      notify('error', 'エラーが発生しました')
    }
  }

  const getDriverDetails = (data1, data2, totalDistance, totalBilling) => {
    const driverId1 = data1.driverId1 ? data1.driverId1 : null;
    const driverId2 = data2.driverId1 ? data2.driverId1 : null;
    const isSameDrivers = driverId1 === driverId2;

    const driver1Distance = data1.distance ? data1.distance.toString().replace('km', '') : null;
    const driver2Distance = data2.distance ? data2.distance.toString().replace('km', '') : null;
    return {
      isSameDrivers,
      driverId1,
      driverId2,
      driver1Billing: (driver1Distance / totalDistance) * totalBilling,
      driver2Billing: (driver2Distance / totalDistance) * totalBilling
    }
  }

  const getEscortList = (escortList) => (transport, transportIndex) => {
    const { ordersEscortVehiclesByTransportId } = transport;
    if (!ordersEscortVehiclesByTransportId) return;
    ordersEscortVehiclesByTransportId.nodes.map((escort, escortIndex) => {
      const escortData = escortList.find(data => data.transportIndex === transportIndex && data.escortIndex === escortIndex);
      if (escortData) {
        escortData.escorts.push({
          escortId: escort.id,
          distance: escort.pickupDistance,
          billing: escort.transportBillingAmount,
          driverId1: escort.driverId,
        })
      } else {
        escortList.push({
          transportIndex,
          escortIndex,
          escorts: [{
            escortId: escort.id,
            distance: escort.pickupDistance,
            billing: escort.transportBillingAmount,
            driverId1: escort.driverId,
          }]
        })
      }
    });
  }

  const getCombinedData = async (order1, order2, distance = null) => {
    // Check if same customer price id and weight
    const distance1 = order1.distance ? order1.distance.replace('km', '') : null;
    const distance2 = order2.distance ? order2.distance.replace('km', '') : null;
    const totalDistance = distance ? distance : Number(distance1) + Number(distance2);
    let totalBilling = 0;
    const priceId1 = distance ? order1.escortUnitPriceId : order1.customerUnitPriceId;
    const priceId2 = distance ? order2.escortUnitPriceId : order2.customerUnitPriceId;
    if (priceId1 && priceId2 && priceId1 === priceId2) {
      const totalWeight = order1.packagingWeight;
      // const totalWeight = order1.packagingWeight;
      let { data } = await client.mutate({
        mutation: getUnitPrice,
        variables: {
          id: parseInt(priceId1),
          weight: parseFloat(totalWeight),
          distance: parseFloat(totalDistance)
        }
      });
      if (data && data.getUnitPrice.float) {
        totalBilling = data.getUnitPrice.float;
      }
    }
    return [totalDistance, totalBilling]
  }

  const getLastConnectionNo = async () => {
    const date = moment().format('YYMM');
    const { data } = await client.query({
      query: GET_LAST_CONNECTION,
      fetchPolicy: 'network-only',
      variables: {
        connectionId: `%${date}%`
      }
    });
    const lastOrder = data.allOrdersConnecteds.nodes[0] || null;
    if (lastOrder) {
      const [, connectionId] = lastOrder.connectionId.split('-');
      return `${date}-${String(+connectionId + 1).padStart(3, '0')}`
    }
    return `${date}-001`
  }

  const onColor = async () => {
    Modal.confirm({
      title: '確認',
      content: '続行してもよろしいですか？',
      okText: 'はい',
      cancelText: 'いいえ',
      onOk: async () => {
        const mutation = selected.map(async (order) => {
          await client.mutate({
            mutation: UPDATE_ORDER_COLORED,
            variables: {
              id: order.id,
              colored: !isColored
            }
          })
        })
        await Promise.all(mutation);
        orderSetter({ refetch: true, selected: [] });
      },
      onCancel: () => { }
    })

  }

  useEffect(() => {
    let connect = false, disconnect = false, escortConnect = false, escortDisconnect = false, colored = false;
    if (selected.length) {
      const allConnected = _.every(selected, (data) => !!data.orderConnectionId);
      const allColored = _.every(selected, (data) => !!data.isColored);
      const hasConnected = _.some(selected, (data) => !!data.orderConnectionId);
      const allEscort = _.every(selected, (data) => !!data.escortConnected);
      const hasEscort = _.some(selected, (data) => !!data.escortConnected);

      if (selected.length <= 2 && !hasConnected) connect = true;
      if (allConnected) disconnect = true;
      if (!hasEscort && allConnected) escortConnect = true;
      if (allEscort) escortDisconnect = true;
      if (allColored) colored = true;
    }
    setIsColored(colored);
    setConnectState({ connect, disconnect, escortConnect, escortDisconnect });
  }, [selected]);

  return (
    <Col style={{ flex: 1 }}>
      <Row type='flex' style={{ alignItems: 'center', justifyContent: 'flex-end' }}>
        <Button
          {...buttonProp}
          style={{ marginLeft: 10, width: 98 }}
          onClick={onColor}
          disabled={!selected.length}
        >
          {isColored ? '背景色解除' : '背景色設定'}
        </Button>
        <Button
          {...buttonProp}
          style={{ marginLeft: 10, width: 118 }}
          onClick={onConnect(connectState.disconnect)}
          disabled={!connectState.connect && !connectState.disconnect}
        >
          {connectState.disconnect ? '運搬連結解除' : '運搬連携'}
        </Button>
        <Button
          {...buttonProp}
          style={{ marginLeft: 10, width: 118 }}
          onClick={onConnectEscortData(connectState.escortDisconnect)}
          disabled={!connectState.escortConnect && !connectState.escortDisconnect}
        >
          {connectState.escortDisconnect ? '誘導連結解除' : '誘導連結'}
        </Button>


        <Button
          {...buttonProp}
          onClick={onActionClick('change')}
          disabled={setDisabled()}
        >
          {/* {lt('btn_change_dispatched_vehicle')} */}
          配車変更
        </Button>

        <Button
          {...buttonProp}
          disabled={setDisabled()}
          onClick={onActionClick('confirm')}
        >
          {lt('btn_confirm_dispatch')}
        </Button>

        <Button
          {...buttonProp}
          disabled={setDisabled()}
          onClick={onActionClick('cancel')}
        >
          {lt('btn_cancel_dispatch_confirmation')}
        </Button>

        <Button {...buttonProp} onClick={onActionClick('report')}>
          {lt('btn_output_daily_report')}
        </Button>

        {/* overall edit all rows button */}
        {view === tabTypes.ORDERS ? (
          <Button {...buttonProp}
            onClick={checkViewAction()}>
            {checkViewLabel()}
          </Button>
        ) : null}
        <Button {...buttonProp}
          onClick={onRegisterClick}>
          {lt('btn_register_new_data')}
        </Button>
      </Row>
      {state.connect && (
        <SearchModal
          selected={selected}
          onOk={(value) => {
            (async () => {
              const order = await getOrderData(value)
              onConnectOrders([...selected, order])
            })()
          }}
          onCancel={() => stateSetter({ connect: false })}
        />
      )}
      <ChangeModal
        visible={state.change}
        onOk={onChangeOk}
        onCancel={() => stateSetter({ change: false })}
      />
      <ConfirmModal
        visible={state.confirm}
        onOk={onConfirmOk}
        onCancel={() => stateSetter({ confirm: false })}
      />
      <CancelModal
        visible={state.cancel}
        onOk={onCancelOk}
        onCancel={() => stateSetter({ cancel: false })}
      />
      <ReportModal
        visible={state.report}
        onOk={onReportOk}
        onCancel={() => stateSetter({ report: false })}
      />
    </Col>
  )
};

export default withApollo(ActionsView);