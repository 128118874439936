import { Button, Col, Form, Row } from 'antd';
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import {
    CustomInput,
    CustomSelect
} from '../../../commons/CustomDataEntry';
import ModalBase from '../../../commons/ModalBase';
import ActionHeader from '../../../commons/TableActions/ActionHeader';
import WithEventButton from '../../../commons/WithEventButton';
import { getAll } from '../../../queries/MasterMaintenance/customer';
import adhocTranslator from "../../../utils/locales/translate-adhoc";
import { statusTypes } from '../../../utils/types';
import CustomerModalConfig from './ListModal';

export default () => {
    let lt = adhocTranslator('K901-010');
    let ltV = adhocTranslator('K901-021');

    const statusList = [
        { id: '', value: ltV('すべて') },
        { id: 'ACTIVE', value: ltV('rdo_status_active') },
        { id: 'INACTIVE', value: ltV('rdo_status_inactive') }
    ];

    let formatPostCode = postCode => {
        if (postCode) {
            postCode = postCode.replace(/-/g, "");
            if (postCode.length >= 4) {
                postCode = postCode.substr(0, 3) + "-" + postCode.substr(3, 4);
            }
        }
        return postCode;
    }

    return {
        fetchQuery: getAll,
        allKey: 'allCustomers',
        perPage: 20,
        removeType: true,
        th: '360px',
        columns: [
            {
                title: lt('lbl_customer_name_header'),
                dataIndex: 'nameJa',
                key: 'nameJa',
                width: 220
            }, {
                title: lt('lbl_postal_code_header'),
                dataIndex: 'postCode',
                key: 'postCode',
                render: (text, record) => formatPostCode(record.postCode),
                align: 'center',
                width: 80
            }, {
                title: lt('lbl_address_header'),
                dataIndex: 'address',
                key: 'address',
                width: 440,
                render: (text =>
                    <div style={{ textAlign: 'left' }}>
                        <span >
                            {text}
                        </span>
                    </div>
                ),
                align: 'center',
            }, {
                title: lt('lbl_tel_header'),
                dataIndex: 'telNo1',
                key: 'telNo1',
                width: 120,
                align: 'center'
            }, {
                title: lt('lbl_fax_header'),
                dataIndex: 'faxNo1',
                key: 'faxNo1',
                width: 120,
                align: 'center',
            }, {
                title: lt('lbl_conditions_email_address'),
                dataIndex: 'emailAddress',
                key: 'emailAddress',
                width: 250,
                align: 'center',
            }, {
                title: lt('lbl_status_header'),
                dataIndex: 'status',
                key: 'status',
                align: 'center',
                width: 80
            }, {
                title: lt('lbl_process_header'),
                key: 'lbl_process_header',
                render: (text, record) => (
                    <span>
                        <Link to={{
                            pathname: `/customer/update/${record.id}`,
                            state: { id: text.id }
                        }}>[ {lt('lbl_edit')} ]</Link>
                        <Link to={{
                            pathname: `/customer/delete/${record.id}`,
                            state: { id: text.id }
                        }}>[ {lt('btn_delete')} ]</Link>
                    </span>
                ),
                align: 'center',
                width: 110
            }
        ],

        dataMapping: (item) => {
            return {
                id: item.id,
                loginId: item.loginId,
                nameJa: item.nameJa,
                nameJaKatakana: item.nameJaKatakana,
                formOutputName: item.formOutputName,
                postCode: formatPostCode(item.postCode),
                // postCode: item.postCode,
                address: item.address,
                telNo1: item.telNo1,
                telNo2: item.telNo2,
                faxNo1: item.faxNo1,
                faxNo2: item.faxNo2,
                emailAddress: item.emailAddress,
                url: item.url,
                transportationUnitPriceId: item.transportationUnitPriceId,
                escortVehicleUnitPriceId: item.escortVehicleUnitPriceId,
                billingDateRangeMonthFrom: item.billingDateRangeMonthFrom,
                billingDateRangeDayFrom: item.billingDateRangeDayFrom,
                billingDateRangeMonthTo: item.billingDateRangeMonthTo,
                billingDateRangeDayTo: item.billingDateRangeDayTo,
                dueDateMonth: item.dueDateMonth,
                dueDateDay: item.dueDateDay,
                escortVehicleOutsourcingUnitPriceId: item.escortVehicleOutsourcingUnitPriceId,
                status: statusTypes[item.status],
            }
        },

        searchHeader: ({ form, handleSearch, loading, clearRows }) => {
            const resetFields = () => {
                form.resetFields();
                clearRows(true)
                handleSearch();
            }

            return (
                <Fragment>
                    <span className="search-filter">{lt('hdr_search_conditions')}</span>
                    <Form onSubmit={handleSearch}>
                        <Row type='flex'>
                            <Col span={12}>
                                <div className='ant-row ant-form-item'>
                                    <ModalBase
                                        title='顧客情報検索'
                                        form={form}
                                        label={lt('lbl_conditions_customer_name')}
                                        btnLabel={lt('選択')}
                                        fieldName='id'
                                        labelWidth={120}
                                        config={CustomerModalConfig()}
                                        isFilter={true}
                                        filterType='customer'
                                        filterStatus={false}
                                    />
                                </div>
                                <CustomInput
                                    form={form}
                                    fieldName='postCode_Like'
                                    label={lt('lbl_conditions_postal_code')}
                                    placeholder={lt('txt_conditions_postal_code')}
                                    width={7}
                                    style={{ width: 200, display: 'inline-block', marginRight: 10 }}
                                    decorator={{
                                        rules: [{
                                            // min: 8,
                                            message: lt('郵便番号を入力してください。'),
                                        }],
                                    }}
                                    onBlur={() => {
                                        const postCode = form.getFieldValue('postCode_Like');
                                        const normalized = postCode.replace(
                                            /[\uff01-\uff5e]/g,
                                            function (ch) { return String.fromCharCode(ch.charCodeAt(0) - 0xfee0); }
                                        );
                                        form.setFieldsValue({ postCode_Like: normalized })
                                    }}
                                />

                                <CustomInput
                                    form={form}
                                    fieldName='address_Like'
                                    type='text'
                                    label={lt('lbl_conditions_address')}
                                    placeholder={lt('txt_conditions_address')}
                                    decorator={{}}
                                    width={15}
                                />
                            </Col>
                            <Col span={12}>
                                <CustomInput
                                    form={form}
                                    fieldName='faxNo1_Like_telNo1_Like'
                                    type='text'
                                    label={lt('電話・FAX番号')}
                                    placeholder={lt('電話番号またはFAX番号を入力してください。')}
                                    decorator={{}}
                                    width={10}
                                    onChange={(e) => {
                                        form.setFieldsValue({
                                            telNo1: e.target.value
                                        })
                                    }}
                                    onBlur={() => {
                                        const postCode = form.getFieldValue('faxNo1_Like_telNo1_Like');
                                        const normalized = postCode.replace(
                                            /[\uff01-\uff5e]/g,
                                            function (ch) { return String.fromCharCode(ch.charCodeAt(0) - 0xfee0); }
                                        );
                                        form.setFieldsValue({
                                            faxNo1_Like_telNo1_Like: normalized,
                                            telNo1: normalized
                                        })
                                    }}
                                />

                                <CustomInput
                                    form={form}
                                    fieldName='emailAddress_Like'
                                    type='text'
                                    label={lt('lbl_conditions_email_address')}
                                    placeholder={lt('txt_conditions_email_address')}
                                    decorator={{}}
                                    width={10}
                                />

                                <CustomSelect
                                    form={form}
                                    options={statusList}
                                    label={lt('lbl_conditions_status')}
                                    fieldName='status'
                                    decorator={{
                                        initialValue: '',
                                    }}
                                    width={10}
                                />


                            </Col>
                        </Row>
                        <Row type='flex'  >
                            <Col span={24} align='center'>
                                {/*Submit*/}
                                <WithEventButton
                                    event={handleSearch}
                                    loading={loading}
                                    label={lt('btn_conditions_search')}
                                    style={{ height: '38px', width: '104px', margin: '14px 14px 14px 0px' }}
                                />
                                <Button
                                    onClick={resetFields}
                                    type="primary"
                                    loading={loading}
                                    style={{ height: '38px', width: '104px', margin: '14px 14px 14px 0px' }}
                                >
                                    {lt('btn_conditions_clear')}
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Fragment>
            );
        },
        actionHeader: () => {
            return (
                <Fragment >
                    <ActionHeader
                        rightActions={
                            <Fragment>
                                <Button
                                    type="primary"
                                    htmlType="button">
                                    <Link to="/customer/register">{lt('btn_register_new')}</Link>
                                </Button>
                            </Fragment>
                        }
                    />
                </Fragment>
            )
        },
        minWidth: 'max-content'
    }
}