import React from 'react';
import { Checkbox, Form } from 'antd';





const CustomCheckboxGroup = ({
    label,
    className,
    width = 20,
    mode,
    offset = 0,
    half = false,
    oneFourth = false,
    oneThird = false,
    twoThird = false,
    small = false,
    labelWidth,
    contain = false,
    decorator,
    form: { getFieldDecorator },
    fieldName,
    options,
    //optionId = 'id',
    //optionValue = 'value',
    onChange
}) => {
    const formItemLayout = {
        labelCol: {  //for label width
            xs: { span: 24 },
            sm: { span: 8 },
            md: { span: 4 },
            lg: { span: labelWidth },
        },
        wrapperCol: { //for input width
            xs: { span: 24 },
            sm: { span: 24 },
            md: { span: width, offset },
        },
    };

    let mainStyle = { display: 'inline-block' };
    if (half) {
        mainStyle.width = '50%';
    } else if (oneFourth) {
        mainStyle.width = '25%';
    } else if (oneThird) {
        mainStyle.width = '33.3%';
    } else if (twoThird) {
        mainStyle.width = '66.6%';
    } else if (small) {
        mainStyle.width = '15%';
    } else mainStyle = null;

    return (
        <Form.Item
            {...formItemLayout}
            label={label}
            style={mainStyle}
            className={className}
        >
            {getFieldDecorator(fieldName, decorator)(
                <Checkbox.Group disabled={mode === 'delete' ? true : false} options={options.map((chkOptions) => ({ label: chkOptions.label, value: chkOptions.value }))} />
            )}
        </Form.Item>
    );
};

export default CustomCheckboxGroup
