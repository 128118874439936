import { gql } from 'apollo-boost';

// ORDERS_TRANSPORT_DESTINATIONS_BY_ORDER_FREIGHT_CAR_ID__COUNT_ASC,
const getAll = gql`
  query AllOrders(
    $first: Int, 
    $offset: Int, 
    $filter: OrderFilter, 
    $arrivalsByVehicleFilter: ArrivalFilter,
    $departureByVehicleFilter: DepartureFilter
    ) {
      allOrders(first:$first,offset:$offset,filter:$filter,
       orderBy: [DISPATCH_STATUS_DESC,
                VEHICLE_BY_VEHICLE_ID__VEHICLE_TYPE_ASC,
                VEHICLE_BY_VEHICLE_ID__ID_ASC,
              ]) {
        nodes {
          id,
          vehicleId,
          driverId1,
          driverId2,
          transportDate,
          dispatchStatus,
          customerId,
          packing1,
          packing2,
          transportDistance,
          transportFare,
          escortVehicleOption,
          escortVehicleCount,
          escortVehicleDistance,
          escortVehicleFare,
          subcontractedEscortVehicleOption,
          subcontractedEscortVehicleCount,
          subcontractedEscortVehicleDistance,
          subcontractedEscortVehicleFare,
          notes,
          remarks,
          overnight,
          vehicleByVehicleId {
            id
            vehicleNo
            nextInspectionDate
            nextPeriodicInspectionDate
            oilChangeDistance
            greaseDistance
            status
            createdUserId
            createdAt
            updatedUserId
            updatedAt
            vehicleType
            arrivalsByVehicleId(filter:$arrivalsByVehicleFilter){
              nodes {
                id
                vehicleId
                transportDate
                arrivalMileage
                arrivalDatetime
                weather
                fuelOilSupply
              }
            }
            departuresByVehicleId(filter:$departureByVehicleFilter){
              nodes {
                id
                vehicleId
                transportDate
                departuresMileage
                departuresDatetime
              }
            }
          }
          userByDriverId1 {
            id,
            fullNameJa,
            fullNameJaKatakana
          }
          userByDriverId2 {
            id,
            fullNameJa,
            fullNameJaKatakana
          }
          customerByCustomerId {
            id,
            nameJa,
            nameJaKatakana,
            formOutputName,
            transportationUnitPriceId
          }
          packagingByPacking1 {
            id,
            packagingName,
            packingCategoryId,
            packagingCategoryByPackingCategoryId {
              nameJa
            },
            weight
          },
          ordersTransportsByOrderId(orderBy:[ORDER_BY_ORDER_ID___DISPATCH_STATUS_DESC, 
              ORDER_BY_ORDER_ID___VEHICLE_ID_ASC, 
              ORDER_BY_ORDER_ID___TRANSPORT_DATE_ASC,
                PICKUP_DATE_ASC,
                PICKUP_OPTION_ASC,
                PICKUP_HOUR_ASC,
                PICKUP_MINUTE_ASC,
                DROPOFF_HOUR_FROM_ASC,
                DROPOFF_MINUTE_FROM_ASC]) {
            nodes {
              id,
              orderId,
              sequenceNo,
              originId,
              origin,
              pickupDate,
              pickupOption,
              pickupHour,
              pickupMinute,
              pickupActualDatetime,
              pickupMileage,
              destinationId,
              destination,
              dropoffDate,
              dropoffOption,
              dropoffHourFrom,
              dropoffMinuteFrom,
              dropoffHourTo,
              dropoffMinuteTo,
              dropoffActualDatetime,
              dropoffMileage,

              transportDestinationByOriginId {
                areaName,
                siteName,
                borrower,
                assignee,
                contact,
                latitude,
                longitude,
                notes,
                customerByCustomerId {
                  nameJa
                }
              }

              orderByOrderId {
                customerByCustomerId {
                  nameJa
                }
              }

              transportDestinationByDestinationId {
                areaName,
                siteName,
                borrower,
                assignee,
                contact,
                latitude,
                longitude,
                notes,
                customerByCustomerId {
                  nameJa
                }
              }
            }

          }
        },
        totalCount
      }
  }
`;

const getAllTranspo = gql`
  query AllOrders(
    $first: Int, 
    $offset: Int, 
    $filter: OrderFilter, 
  ) {
    allOrders(first:$first,offset:$offset,filter:$filter) {
      nodes {
        id
        customerByCustomerId {
          id
          nameJa
        }
        ordersTransportsByOrderId {
          nodes {
            transportDestinationByDestinationId {
              id
              contact
              borrower
            }
            transportDestinationByOriginId {
              id
              borrower
              contact
            }
          }
        }
      },
      totalCount
    }
  }  
`;

const getAllOrdersTrimmed = gql`
  query AllOrders(
    $first: Int, 
    $offset: Int, 
    $filter: OrderFilter, 
    $arrivalsByVehicleFilter: ArrivalFilter,
    $departureByVehicleFilter: DepartureFilter
    ) {
      allOrders(first:$first,offset:$offset,filter:$filter, orderBy: [DISPATCH_STATUS_DESC,VEHICLE_BY_VEHICLE_ID__ID_ASC, TRANSPORT_DATE_ASC]) {
        nodes {
          id,
          vehicleId,
          transportDate,
          dispatchStatus,
          transportDistance,
          transportFare,
          escortVehicleDistance,
          escortVehicleFare,
          subcontractedEscortVehicleDistance,
          subcontractedEscortVehicleFare,
          vehicleByVehicleId {
            id
            vehicleNo
            nextInspectionDate
            nextPeriodicInspectionDate
            oilChangeDistance
            greaseDistance
            vehicleType
            # arrivalsByVehicleId(filter:$arrivalsByVehicleFilter){
            #   nodes {
            #     id
            #     vehicleId
            #     transportDate
            #     arrivalMileage
            #     arrivalDatetime
            #     weather
            #     fuelOilSupply
            #   }
            # }
            # departuresByVehicleId(filter:$departureByVehicleFilter){
            #   nodes {
            #     id
            #     vehicleId
            #     transportDate
            #     departuresMileage
            #     departuresDatetime
            #   }
            # }
          }
          customerByCustomerId {
            id,
            nameJa,
            transportationUnitPriceId
          }
          ordersTransportsByOrderId(orderBy:[
                ORDER_BY_ORDER_ID__DISPATCH_STATUS_DESC, 
                ORDER_BY_ORDER_ID__VEHICLE_ID_ASC, 
                ORDER_BY_ORDER_ID__TRANSPORT_DATE_ASC,
                PICKUP_DATE_ASC,
                PICKUP_OPTION_ASC,
                PICKUP_HOUR_ASC,
                PICKUP_MINUTE_ASC,
                DROPOFF_HOUR_FROM_ASC,
                DROPOFF_MINUTE_FROM_ASC]) {
            nodes {
              id,
              orderId,
              originId,
              pickupDate,
              pickupMileage,
              dropoffDate,
              dropoffOption,
              dropoffMileage,
              pickupDate,
              dropoffDate,
            }
          },
        },
        totalCount
      }
      allArrivals(filter:$arrivalsByVehicleFilter){
        nodes {
          id
          vehicleId
          transportDate
          arrivalMileage
          arrivalDatetime
          weather
          fuelOilSupply
        },
        totalCount
      }
      allDepartures(filter:$departureByVehicleFilter){
        nodes {
          id
          vehicleId
          transportDate
          departuresMileage
          departuresDatetime
        },
        totalCount
      }
    }
`;


const ordersDispatchBatch = gql`
mutation createOrderDispatchBatch(
      $vehicleId : Int,
      $driverId1 : Int,
      $driverId2 :Int,
      $transportDate : Date,
      $dispatchStatus :DispatchStatusOption,
      $customerId : Int,
      $packing1 : Int,
      $packing2 : String,
      $transportDistance : Float,
      $transportFare : Float,
      $escortVehicleOption : Int,
      $escortVehicleCount : Int,
      $escortVehicleDistance :Float,
      $escortVehicleFare :Float,
      $subcontractedEscortVehicleOption : Int,
      $subcontractedEscortVehicleCount : Int,
      $subcontractedEscortVehicleDistance :Float,
      $subcontractedEscortVehicleFare :Float,
      $notes : String,
      $remarks :String,
      $overnight : Overnight,
      $updatedUserId : Int,
      $createdUserId : Int,
      $ordersDispatchDestination : [CreateOrdersTransportDestinationInput!]
){
  ordersDispatchBatch (input: {
    ordersDispatch : {
          vehicleId : $vehicleId,
          driverId1 : $driverId1,
          driverId2 :$driverId2,
          transportDate : $transportDate,
          dispatchStatus :$dispatchStatus,
          customerId : $customerId,
          packing1 : $packing1,
          packing2 : $packing2,
          transportDistance : $transportDistance,
          transportFare : $transportFare,
          escortVehicleOption : $escortVehicleOption,
          escortVehicleCount : $escortVehicleCount,
          escortVehicleDistance :$escortVehicleDistance,
          escortVehicleFare :$escortVehicleFare,
          subcontractedEscortVehicleOption : $subcontractedEscortVehicleOption,
          subcontractedEscortVehicleCount : $subcontractedEscortVehicleCount,
          subcontractedEscortVehicleDistance :$subcontractedEscortVehicleDistance,
          subcontractedEscortVehicleFare :$subcontractedEscortVehicleFare,
          notes: $notes,
          remarks :$remarks,
          overnight : $overnight,
          createdUserId : $createdUserId,
          updatedUserId : $updatedUserId
    },
    ordersDispatchDestination : $ordersDispatchDestination
  }) {
    status
  }
}
`;

const create = gql`
  mutation createOrderDispatch(
      $vehicleId : Int,
      $driverId1 : Int,
      $driverId2 :Int,
      $transportDate : Date,
      $dispatchStatus :DispatchStatusOption,
      $customerId : Int,
      $packing1 : Int,
      $packing2 : String,
      $transportDistance : Float,
      $transportFare : Float,
      $escortVehicleOption : Int,
      $escortVehicleCount : Int,
      $escortVehicleDistance :Float,
      $escortVehicleFare :Float,
      $notes : String,
      $remarks :String,
      $overnight : Overnight,
      $updatedUserId : Int,
      $createdUserId : Int,
    ) {
      createOrdersDispatch (input:{
        ordersDispatch : {
          vehicleId : $vehicleId,
          driverId1 : $driverId1,
          driverId2 :$driverId2,
          transportDate : $transportDate,
          dispatchStatus :$dispatchStatus,
          customerId : $customerId,
          packing1 : $packing1,
          packing2 : $packing2,
          transportDistance : $transportDistance,
          transportFare : $transportFare,
          escortVehicleOption : $escortVehicleOption,
          escortVehicleCount : $escortVehicleCount,
          escortVehicleDistance :$escortVehicleDistance,
          escortVehicleFare :$escortVehicleFare,
          subcontractedEscortVehicleOption : $subcontractedEscortVehicleOption,
          subcontractedEscortVehicleCount : $subcontractedEscortVehicleCount,
          subcontractedEscortVehicleDistance :$subcontractedEscortVehicleDistance,
          subcontractedEscortVehicleFare :$subcontractedEscortVehicleFare,
          notes: $notes,
          remarks :$remarks,
          overnight : $overnight,
          createdUserId : $createdUserId,
          updatedUserId : $updatedUserId
        }
      }) {
        ordersDispatch {
          id
        }
      }
    }
`;

const ordersDispatchBatchUpdate = gql`
mutation createOrderDispatchBatchUpdate(
      $id : Int!,
      $vehicleId : Int,
      $driverId1 : Int,
      $driverId2 :Int,
      $transportDate : Date,
      $dispatchStatus :DispatchStatusOption,
      $customerId : Int,
      $packing1 : Int,
      $packing2 : String,
      $transportDistance : Float,
      $transportFare : Float,
      $escortVehicleOption : Int,
      $escortVehicleCount : Int,
      $escortVehicleDistance :Float,
      $escortVehicleFare :Float,
      $subcontractedEscortVehicleOption : Int,
      $subcontractedEscortVehicleCount : Int,
      $subcontractedEscortVehicleDistance :Float,
      $subcontractedEscortVehicleFare :Float,
      $notes : String,
      $remarks :String,
      $overnight : Overnight,
      $updatedUserId : Int,
      $createdUserId : Int,
      $ordersDispatchDestination : [CreateOrdersTransportDestinationInput!]
){
  ordersDispatchBatchUpdate (input: {
    ordersDispatch : {
          id : $id,
          vehicleId : $vehicleId,
          driverId1 : $driverId1,
          driverId2 :$driverId2,
          transportDate : $transportDate,
          dispatchStatus :$dispatchStatus,
          customerId : $customerId,
          packing1 : $packing1,
          packing2 : $packing2,
          transportDistance : $transportDistance,
          transportFare : $transportFare,
          escortVehicleOption : $escortVehicleOption,
          escortVehicleCount : $escortVehicleCount,
          escortVehicleDistance :$escortVehicleDistance,
          escortVehicleFare :$escortVehicleFare,
          subcontractedEscortVehicleOption : $subcontractedEscortVehicleOption,
          subcontractedEscortVehicleCount : $subcontractedEscortVehicleCount,
          subcontractedEscortVehicleDistance :$subcontractedEscortVehicleDistance,
          subcontractedEscortVehicleFare :$subcontractedEscortVehicleFare,
          notes: $notes,
          remarks :$remarks,
          overnight : $overnight,
          createdUserId : $createdUserId,
          updatedUserId : $updatedUserId,
    },
    ordersDispatchDestination : $ordersDispatchDestination
  }) {
    status
  }
}
`;

const ordersDispatchBatchListUpdate = gql`
  mutation updateOrderDispatchBatchList(
        $ordersDispatchIds : [Int]!,
        $ordersDispatchVehicleId : Int,
        $ordersDispatchStatus : DispatchStatusOption,
        $updatedUserId : Int
  ){
    ordersDispatchBatchList (input: {
        ordersDispatchIds : $ordersDispatchIds,
        ordersDispatchVehicleId : $ordersDispatchVehicleId,
        ordersDispatchStatus :$ordersDispatchStatus,
        updatedUserId : $updatedUserId
    }) {
      status
    }
  }
`;

export const sendNotifications = gql`
  mutation sendNotifications($orders: String, $is_type_1: Boolean) {
    sendNotifications(input: {orders: $orders, is_type_1: $is_type_1}) {
      result
    }
  }
`;

const getById = gql`
    query GetOrderDispach($id:Int!) {
     ordersDispatchById (id:$id) {
        id,
        vehicleId,
        driverId1,
        driverId2,
        transportDate,
        dispatchStatus,
        customerId,
        packing1,
        packing2,
        transportDistance,
        transportFare,
        escortVehicleOption,
        escortVehicleCount,
        escortVehicleDistance,
        escortVehicleFare,
        subcontractedEscortVehicleOption,
        subcontractedEscortVehicleCount,
        subcontractedEscortVehicleDistance,
        subcontractedEscortVehicleFare,
        notes,
        remarks,
        overnight,
        userByDriverId1 {
          id,
          fullNameJa,
          fullNameJaKatakana
        }
        userByDriverId2 {
          id,
          fullNameJa,
          fullNameJaKatakana
        }
        customerByCustomerId {
          id,
          nameJa,
          nameJaKatakana,
          formOutputName,
          transportationUnitPriceId
          escortVehicleUnitPriceId
          escortVehicleOutsourcingUnitPriceId
        }
        packagingByPacking1 {
          id,
          packagingName,
          packingCategoryId,
          packagingCategoryByPackingCategoryId {
            nameJa
          },
          weight
        }

        ordersTransportsByOrderId(orderBy: [ORDER_BY_ORDER_ID__DISPATCH_STATUS_DESC, 
            ORDER_BY_ORDER_ID___VEHICLE_ID_ASC, 
            ORDER_BY_ORDER_ID___TRANSPORT_DATE_ASC,
                PICKUP_DATE_ASC,
                PICKUP_OPTION_ASC,
                PICKUP_HOUR_ASC,
                PICKUP_MINUTE_ASC,
                DROPOFF_HOUR_FROM_ASC,
                DROPOFF_MINUTE_FROM_ASC]) {
          nodes {
            id,
            orderId,
            sequenceNo,
            originId,
            origin,
            pickupDate,
            pickupOption,
            pickupHour,
            pickupMinute,
            pickupActualDatetime,
            pickupMileage,
            destinationId,
            destination,
            dropoffDate,
            dropoffOption,
            dropoffHourFrom,
            dropoffMinuteFrom,
            dropoffHourTo,
            dropoffMinuteTo,
            dropoffActualDatetime,
            dropoffMileage,

            transportDestinationByOriginId {
              areaName,
              siteName,
              borrower,
              assignee,
              contact,
              latitude,
              longitude,
              notes,
              customerByCustomerId {
                nameJa
              }
            }

            orderByOrderId {
              customerByCustomerId {
                nameJa
              }
            }

            transportDestinationByDestinationId {
              areaName,
              siteName,
              borrower,
              assignee,
              contact,
              latitude,
              longitude,
              notes,
              customerByCustomerId {
                nameJa
              }
            }
          }

        }
      }   
    }
`;


const getByIds = gql`
    query TransportDestinationById($id1: Int!, $id2: Int!, $id3: Int!) {
      destination1: transportDestinationById(id: $id1) {
        id
        areaName
        siteName
        borrower
        assignee
        contact
        latitude
        longitude
        notes
        customerByCustomerId {
          id
          nameJa
        }
      }
      destination2: transportDestinationById(id: $id2) {
        id
        areaName
        siteName
        borrower
        assignee
        contact
        latitude
        longitude
        notes
        customerByCustomerId {
          id
          nameJa
        }
      }
      destination3: transportDestinationById(id: $id3) {
        id
        areaName
        siteName
        borrower
        assignee
        contact
        latitude
        longitude
        notes
        customerByCustomerId {
          id
          nameJa
        }
      }
    }

`;

const updateById = gql`
    mutation updateOrderDispatchById(
      $id: Int!,
      $vehicleId : Int,
      $driverId1 : Int,
      $driverId2 :Int,
      $transportDate : Date,
      $dispatchStatus :DispatchStatusOption,
      $customerId : Int,
      $packing1 : Int,
      $packing2 : String,
      $transportDistance : Float,
      $transportFare : Float,
      $escortVehicleOption : Int,
      $escortVehicleCount : Int,
      $escortVehicleDistance :Float,
      $escortVehicleFare :Float,
      $notes: String,
      $remarks :String,
      $overnight : Overnight,
      $updatedUserId : Int,
      $createdUserId : Int,
    ) {
      updateOrdersDispatchById(
        input : {
          id: $id,
          ordersDispatchPatch : {
            vehicleId : $vehicleId,
            driverId1 : $driverId1,
            driverId2 :$driverId2,
            transportDate : $transportDate,
            dispatchStatus :$dispatchStatus,
            customerId : $customerId,
            packing1 : $packing1,
            packing2 : $packing2,
            transportDistance : $transportDistance,
            transportFare : $transportFare,
            escortVehicleOption : $escortVehicleOption,
            escortVehicleCount : $escortVehicleCount,
            escortVehicleDistance :$escortVehicleDistance,
            escortVehicleFare :$escortVehicleFare,
            notes: $notes,
            remarks :$remarks,
            overnight : $overnight,
            createdUserId : $createdUserId,
            updatedUserId : $updatedUserId
          }
        }
      ) {
        ordersDispatch {
          vehicleId
        }
      }
    }
`;

const deleteById = gql`
	mutation DeleteOrdersDispatchById($id:Int!){
      deleteOrdersDispatchById(input:{
        id: $id
      }){
        deletedOrdersDispatchId
      }
	}
`;



const ordersDispatchBatchDelete = gql`
	mutation DeleteOrdersBatchDispatchById($id:Int!, $ordersDispatchDestination : [CreateOrdersTransportDestinationInput!]){
      ordersDispatchBatchDelete(input:{
        ordersDispatch : {
          id : $id,
        },
        ordersDispatchDestination : $ordersDispatchDestination
      }){
        status
      }
	  }
`;

const getArrivalByVehicleId = gql`
  query VehicleId($id:Int! $arrivalsByVehicleFilter: ArrivalFilter, $departureByVehicleFilter: DepartureFilter) {
      vehicleById(id:$id) {
      id
      vehicleNo
      nextInspectionDate
      nextPeriodicInspectionDate
      oilChangeDistance
      greaseDistance
      status
      createdUserId
      createdAt
      updatedUserId
      updatedAt
      vehicleType
      arrivalsByVehicleId(filter:$arrivalsByVehicleFilter){
        nodes {
          id
          vehicleId
          transportDate
          arrivalMileage
          arrivalDatetime
          weather
          fuelOilSupply
        }
      }
      departuresByVehicleId(filter:$departureByVehicleFilter){
        nodes {
          id
          vehicleId
          transportDate
          departuresMileage
          departuresDatetime
        }
      }
    }
  }
`;



export {
  getAll, getAllTranspo, create, getById, getByIds, updateById, deleteById, ordersDispatchBatchDelete, getArrivalByVehicleId,
  ordersDispatchBatch, ordersDispatchBatchUpdate, ordersDispatchBatchListUpdate, getAllOrdersTrimmed
};
