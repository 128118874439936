import { Button, Col, Form, Row } from "antd";
import moment from 'moment';
import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { CustomSelect, InputNumbers } from '../../../commons/CustomDataEntry';
import ModalBase from '../../../commons/ModalBase';
import Month from "../../../commons/Picklist/Month";
import VehicleType from "../../../commons/Picklist/VehicleType";
import Year from "../../../commons/Picklist/Year";
import ActionHeader from '../../../commons/TableActions/ActionHeader';
import WithEventButton from "../../../commons/WithEventButton";
import { getAll } from '../../../queries/MasterMaintenance/vehicle';
import adhocTranslator from "../../../utils/locales/translate-adhoc";
import { statusTypes, vehicleTypes } from "../../../utils/types";
import VehicleModalConfig from './ListModal';

export default () => {
    let lt = adhocTranslator('K902-010');
    let ltV = adhocTranslator('K902-020');
    const dateFormat = 'YYYY/MM/DD';

    const statusList = [
        { id: '', value: ltV('すべて') },
        { id: 'ACTIVE', value: ltV('rdo_active') },
        { id: 'INACTIVE', value: ltV('rdo_inactive') }
    ];

    return {
        maintenanceVehicleList: true,
        fetchQuery: getAll,
        allKey: 'allVehicles',
        perPage: 20,
        removeType: true,
        minWidth: 'max-content',
        th: '360px',
        columns: [
            {
                title: lt('lbl_vehicle_no_header'),
                dataIndex: 'vehicleNo',
                key: 'vehicleNo',
                width: 150
            }, {
                title: lt('lbl_vehicle_class_header'),
                dataIndex: 'vehicleType',
                key: 'vehicleType',
                width: 150
            }, {
                title: lt('lbl_next_vehicle_inspection_date_header'),
                dataIndex: 'nextInspectionDate',
                key: 'nextInspectionDate',
                width: 150
            }, {
                title: lt('lbl_next_periodic_inspection_date_header'),
                dataIndex: 'nextPeriodicInspectionDate',
                key: 'nextPeriodicInspectionDate',
                width: 150
            }, {
                title: lt('lbl_oil_replacement_distance_header'),
                dataIndex: 'oilChangeDistance',
                align: 'center',
                key: 'oilChangeDistance',
                width: 150,
                render: (text) => { return <p style={{ textAlign: 'right', marginBottom: 1 }}>{text}</p> }
            }, {
                title: lt('lbl_grease_up_distance_header'),
                dataIndex: 'greaseDistance',
                key: 'greaseDistance',
                align: 'center',
                width: 150,
                render: (text) => { return <p style={{ textAlign: 'right', marginBottom: 1 }}>{text}</p> }
            }, {
                title: lt('lbl_status_header'),
                dataIndex: 'status',
                key: 'status',
                align: 'center',
                width: 80
            }, {
                title: lt('lbl_process_header'),
                key: 'process',
                render: (text, record) => {
                    return (
                        <span>
                            <Link to={{
                                pathname: `/vehicle/update/${record.id}`,
                                state: { id: text.id }
                            }}>[ {lt('btn_edit')} ]</Link>
                            <Link to={{
                                pathname: `/vehicle/delete/${record.id}`,
                                state: { id: text.id }
                            }}>[ {lt('btn_delete')} ]</Link>
                        </span>
                    );
                },
                align: 'center',
                width: 110
            }
        ],

        dataMapping: (item) => {

            return {
                id: item.id,
                vehicleNo: item.vehicleNo,
                vehicleType: vehicleTypes[item.vehicleType],
                nextInspectionDate: item.nextInspectionDate && moment(item.nextInspectionDate).format(dateFormat),
                nextPeriodicInspectionDate: item.nextPeriodicInspectionDate && moment(item.nextPeriodicInspectionDate).format(dateFormat),
                oilChangeDistance: item.oilChangeDistance ? (item.oilChangeDistance && `${item.oilChangeDistance.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} `) + ' km' : null,
                greaseDistance: item.greaseDistance ? (item.greaseDistance && `${item.greaseDistance.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} `) + ' km' : null,
                status: statusTypes[item.status]
            }
        },

        searchHeader: ({ form, handleSearch, loading, clearRows }) => {
            const resetFields = () => {
                form.resetFields();
                handleSearch();
                clearRows(true)
            }

            return (
                <Fragment>
                    <span className="search-filter">{lt('hdr_search_conditions')}</span>
                    <Form onSubmit={handleSearch} id="myform">
                        <Row type='flex'>
                            <Col span={12}>
                                <div className='ant-row ant-form-item vehicleModal'>
                                    <ModalBase
                                        title='車両情報検索'
                                        form={form}
                                        label={lt('lbl_conditions_vehicle_no')}
                                        btnLabel={lt('選択')}
                                        fieldName='id'
                                        width={24}
                                        labelWidth={7}
                                        config={VehicleModalConfig()}
                                        isFilter={true}
                                        filterType='vehicle'
                                    />

                                    {/*Vehicle Class*/}
                                    <VehicleType
                                        form={form}
                                        label={lt('lbl_conditions_vehicle_class')}
                                        fieldName='vehicleType'
                                        decorator={{
                                        }}
                                        rowField={false}
                                        style={{ width: 170, display: 'inline-block', marginRight: 10 }}
                                        labelWidth={80}
                                    />
                                </div>
                                <div className='ant-row ant-form-item'>
                                    {/*Oil distance*/}
                                    <Year
                                        form={form}
                                        fieldName='nextInspectionDate_From_Year'
                                        label={lt('lbl_conditions_next_vehicle_inspection_month')}
                                        postLabel={lt('lbl_conditions_next_vehicle_inspection_from_year')}
                                        rowField={false}
                                        style={{ width: 100, display: 'inline-block', marginRight: 10 }}
                                        decorator={{
                                        }}
                                        labelWidth={120}
                                    />
                                    <Month
                                        form={form}
                                        fieldName='nextInspectionDate_From_Month'
                                        postLabel={lt('lbl_conditions_next_vehicle_inspection_from_month')}
                                        style={{ width: 80, display: 'inline-block', marginRight: 10 }}
                                        rowField={false}
                                        decorator={{
                                        }}
                                        labelWidth={80}
                                    />

                                    <Year
                                        form={form}
                                        fieldName='nextInspectionDate_To_Year'
                                        postLabel={lt('lbl_conditions_next_vehicle_inspection_to_year')}
                                        rowField={false}
                                        style={{ width: 100, display: 'inline-block', marginRight: 10 }}
                                        decorator={{
                                        }}
                                        labelWidth={120}
                                    />

                                    <Month
                                        form={form}
                                        fieldName='nextInspectionDate_To_Month' オ
                                        postLabel={lt('lbl_conditions_next_vehicle_inspection_to_month')}
                                        rowField={false}
                                        style={{ width: 80, display: 'inline-block', marginRight: 10 }}
                                        decorator={{
                                        }}
                                        labelWidth={120}
                                    />
                                </div>

                                <div className='ant-row ant-form-item'>
                                    {/*Next Inspection Date Filter*/}
                                    <Year
                                        form={form}
                                        fieldName='nextPeriodicInspectionDate_From_Year'
                                        label={lt('lbl_conditions_next_periodic_inspection_month')}
                                        postLabel={lt('lbl_conditions_next_periodic_inspection_from_year')}
                                        rowField={false}
                                        style={{ width: 100, display: 'inline-block', marginRight: 10 }}
                                        decorator={{
                                        }}
                                        labelWidth={120}
                                    />
                                    <Month
                                        form={form}
                                        fieldName='nextPeriodicInspectionDate_From_Month'
                                        postLabel={lt('lbl_conditions_next_periodic_inspection_from_month')}
                                        rowField={false}
                                        style={{ width: 80, display: 'inline-block', marginRight: 10 }}
                                        decorator={{
                                        }}
                                        labelWidth={120}
                                    />
                                    <Year
                                        form={form}
                                        fieldName='nextPeriodicInspectionDate_To_Year'
                                        postLabel={lt('lbl_conditions_next_periodic_inspection_to_year')}
                                        rowField={false}
                                        style={{ width: 100, display: 'inline-block', marginRight: 10 }}
                                        decorator={{
                                        }}
                                        labelWidth={120}
                                    />
                                    <Month
                                        form={form}
                                        fieldName='nextPeriodicInspectionDate_To_Month'
                                        postLabel={lt('lbl_conditions_next_periodic_inspection_to_month')}
                                        rowField={false}
                                        style={{ width: 80, display: 'inline-block', marginRight: 10 }}
                                        decorator={{
                                        }}
                                        labelWidth={120}
                                    />
                                </div>
                            </Col>
                            <Col span={12}>
                                <div className='ant-row ant-form-item'>
                                    {/*Oil distance*/}
                                    <InputNumbers
                                        form={form}
                                        formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                        fieldName='oilChangeDistance_From'
                                        label={lt('lbl_conditions_oil_replacement_distance')}
                                        postLabel={lt('lbl_conditions_oil_replacement_distance_fromlabel')}
                                        maxLength={8}
                                        rowField={false}
                                        style={{ width: 100, display: 'inline-block', marginRight: 10 }}
                                        decorator={{
                                        }}
                                        labelWidth={120} />
                                    <InputNumbers
                                        form={form}
                                        formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                        fieldName='oilChangeDistance_To'
                                        postLabel={lt('lbl_conditions_oil_replacement_distance_tolabel')}
                                        maxLength={8}
                                        rowField={false}
                                        style={{ width: 100, display: 'inline-block', marginRight: 10 }}
                                        decorator={{
                                        }}
                                        labelWidth={120} />
                                </div>
                                <div className='ant-row ant-form-item'>
                                    {/*Grease up distance*/}
                                    <InputNumbers
                                        form={form}
                                        formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                        fieldName='greaseDistance_From'
                                        label={lt('lbl_conditions_grease_up_distance')}
                                        postLabel={lt('lbl_conditions_grease_up_distance_from')}
                                        maxLength={8}
                                        rowField={false}
                                        style={{ width: 100, display: 'inline-block', marginRight: 10 }}
                                        decorator={{
                                        }}
                                        labelWidth={120} />
                                    <InputNumbers
                                        form={form}
                                        formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                        fieldName='greaseDistance_To'
                                        postLabel={lt('lbl_conditions_grease_up_distance_to')}
                                        maxLength={8}
                                        rowField={false}
                                        style={{ width: 100, display: 'inline-block', marginRight: 10 }}
                                        decorator={{
                                        }}
                                        labelWidth={120} />
                                </div>
                                <div className='ant-row ant-form-item'>
                                    {/*Vehicle Status*/}
                                    <CustomSelect
                                        form={form}
                                        options={statusList}
                                        label={lt('lbl_conditions_status')}
                                        fieldName='status'
                                        className="vehicleStatus"
                                        decorator={{
                                            initialValue: '',
                                        }}
                                        width={8}
                                    />
                                </div>

                            </Col>
                        </Row>
                        <Row type='flex' justify='space-around'>
                            <Col span={24} align='center'>
                                {/*Submit*/}
                                <WithEventButton
                                    loading={loading}
                                    event={handleSearch}
                                    label={lt('btn_conditions_search')}
                                    style={{ height: '38px', width: '104px', margin: '14px 14px 14px 0px' }}
                                />
                                <Button
                                    onClick={resetFields}
                                    type="primary"
                                    htmlType='button'
                                    loading={loading}
                                    style={{ height: '38px', width: '104px', margin: '14px 14px 14px 0px' }}
                                >
                                    {lt('btn_conditions_clear')}
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Fragment>
            );
        },
        actionHeader: () => {
            return (
                <Fragment>
                    <ActionHeader
                        rightActions={
                            <Fragment>
                                <Button
                                    type="primary"
                                    htmlType="button">
                                    <Link to="/vehicle/register">{lt('btn_new_registration')}</Link>
                                </Button>
                            </Fragment>
                        } />
                </Fragment>
            )
        },
    };
}