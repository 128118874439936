import React, { Component, Fragment } from 'react';
import { Query, withApollo } from 'react-apollo';
import { EstimateExcelWrap, estimateType } from '../Estimate/Excel';
import { OrderExcelWrap, ordersType } from '../OrdersDispatch/Excel';
import { InvoiceExcelWrap, invoiceType } from '../Invoice/Excel';
import { InvoiceCustomerExcelWrap, invoiceCustomerType } from '../Invoice/CustomerExcel';
import { CrewPerformanceExcelWrap, crewPerformanceType } from '../CrewPerformance/Excel';
import { DispatchExcelWrap, dispatchType } from '../Dispatch/Excel';
import { gql } from 'apollo-boost';

const query = gql`
    query {
        excelOutput {
            type
            ${ordersType}
            ${estimateType}
            ${invoiceType}
            ${crewPerformanceType}
            ${dispatchType}
            ${invoiceCustomerType}
        }
    }  
`;

class Excel extends Component {
    render() {
        return (
            <Query query={query} fetchPolicy="cache-only">
                {({ data, loading, client }) => {
                    if (!data.excelOutput) {
                        return <Fragment></Fragment>
                    }

                    switch (data.excelOutput.type) {
                        case 'Orders':
                            return <OrderExcelWrap data={data.excelOutput.excelOrdersOutputData} />
                        case 'Estimates':
                            return <EstimateExcelWrap data={data.excelOutput.excelEstimateOutputData} />
                        case 'Invoice':
                            return <InvoiceExcelWrap data={data.excelOutput.excelInvoiceOutputData} />
                        case 'InvoiceCustomer':
                            return <InvoiceCustomerExcelWrap data={data.excelOutput.excelCustomersOutputData} />
                        case 'Crew':
                            return <CrewPerformanceExcelWrap data={data.excelOutput.excelCrewPerformanceOutputData} />
                        case 'Dispatch':
                            return <DispatchExcelWrap data={data.excelOutput.excelDispatchOutputData} />
                        default:
                            return <Fragment></Fragment>
                    }
                }}
            </Query>

        );
    }
}

export default withApollo(Excel);