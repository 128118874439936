import { gql } from 'apollo-boost';

export const GET_OPTIONS = gql`
query getAllVehicles($filter: VehicleFilter) {
  allVehicles(filter: $filter, orderBy: DISPLAY_ORDER_ASC) {
    nodes {
      id
      vehicleNo
      vehicleType
      usersByVehicleNumber {
        nodes {
          id
          fullNameJa
        }
      }
    }
  }
  allUsers(filter: {rolesByUserId: {every: {role: {in: [DRIVER_COMPANY_, DRIVER_OUTSOURCING_, ESCORT_VEHICLE_COMPANY_DRIVER, ESCORT_VEHICLE_OUTSOURCING_DRIVER]}}}}) {
    nodes {
      id
      fullNameJa
      fullNameJaKatakana
    }
  }
}
`;

export const GET_ORDER_THROUGH_TRANSPORT = gql`
query getOrders ($filter: OrdersTransportFilter) {
    allOrdersTransports (filter: $filter, orderBy: ORDER_BY_ORDER_ID__TRANSPORT_DATE_DESC) {
        nodes {
            orderByOrderId {
                orderDate
                transportDate
                dispatchStatus
                vehicleId
                driverId1
                driverId2
                userByDriverId1 {
                  fullNameJa
                  fullNameJaKatakana
                  shortNameJa
                }
                userByDriverId2 {
                  fullNameJa
                  fullNameJaKatakana
                  shortNameJa
                }
                customerByCustomerId {
                  nameJa
                }
                customerId
                packagingByPacking1 {
                  packagingName
                }
                packing1
                packing2
                notes
                # notes2
                transportDistance
                transportBillingAmount
                transportPaymentAmount
                vehicleByVehicleId {
                    vehicleNo
                }
            }
            ordersEscortVehiclesByTransportId {
                nodes {
                    orderId
                    transportBillingAmount
                    transportPaymentAmount
                }
            }
            orderId
            origin
            pickupDate
            pickupActualDatetime
            pickupBorrower
            pickupAssignee
            pickupContact
            pickupSiteName
            pickupMemo
            originColor
            destinationColor
            destination
            dropoffDate
            dropoffActualDatetime
            dropoffBorrower
            dropoffAssignee
            dropoffContact
            dropoffSiteName
            dropoffMemo
            ordersPickupImagesByTransportId(orderBy: IMAGE_NO_ASC) {
                nodes {
                  imageNo
                  imageFile
                  id
                  transportId
                }
              }
              ordersDropoffImagesByTransportId(orderBy: IMAGE_NO_ASC) {
                nodes {
                  id
                  imageFile
                  imageNo
                  transportId
                }
              }
        }
    }
}
`;

export const GET_ORDERS_BY_ID = gql`
query getOrdersDispatch($filter: OrderFilter) {
      allOrders(filter: $filter) {
        nodes {
          createdAt
          createdUserId
          customerId
          dispatchStatus
          displayOrder
          driverId1
          driverId2
          vehicleId
          id
          notes
          # notes2
          orderDate
          overnight
          packing1
          packing2
          transportBillingAmount
          transportDate
          transportDateOption
          transportDistance
          transportPaymentAmount
          updatedAt
          updatedUserId
          ordersConnectedsByOrderId1 {
            nodes {
              id
              connectionId
              orderId1
              orderId2
              escortConnected
              totalBilling
              totalDistance
              isSameDrivers
              driverId1
              driverId2
              driver1Billing
              driver2Billing
            }
          }
          ordersConnectedsByOrderId2 {
            nodes {
              id
              connectionId
              orderId1
              escortConnected
              orderId2
              totalBilling
              totalDistance
              isSameDrivers
              driverId1
              driverId2
              driver1Billing
              driver2Billing
            }
          }
          userByDriverId1 {
            id
            fullNameJa
            fullNameJaKatakana
          }
          userByDriverId2 {
            id
            fullNameJa
            fullNameJaKatakana
          }
          packagingByPacking1 {
            id
            packagingName
            weight
          }
          customerByCustomerId {
            id
            nameJa
            transportationUnitPriceId
            escortVehicleUnitPriceId
            escortVehicleOutsourcingUnitPriceId
          }
          vehicleByVehicleId {
            id
            vehicleNo
          }
          ordersTransportsByOrderId(orderBy: TRANSPORT_NO_ASC) {
            nodes {
              destination
              origin
              dropoffActualDatetime
              dropoffAssignee
              dropoffBorrower
              dropoffContact
              dropoffDate
              dropoffHourFrom
              dropoffHourTo
              dropoffMileage
              dropoffMinuteFrom
              dropoffMinuteTo
              dropoffOption
              dropoffMemo
              dropoffSiteName
              id
              nodeId
              numberEscortVehicle
              orderId
              pickupActualDatetime
              pickupAssignee
              pickupBorrower
              pickupContact
              pickupDate
              pickupHour
              pickupHourTo
              pickupMemo
              originColor
              destinationColor
              pickupMileage
              pickupMinute
              pickupMinuteTo
              pickupOption
              pickupSiteName
              transportNo
              ordersPickupImagesByTransportId(orderBy: IMAGE_NO_ASC) {
                nodes {
                  imageNo
                  imageFile
                  id
                  transportId
                }
              }
              ordersEscortVehiclesByTransportId(orderBy: ESCORT_NO_ASC) {
                nodes {
                  id
                  escortNo
                  dropoffDistance
                  pickupDistance
                  origin
                  driverId
                  destination
                  transportBillingAmount
                  transportId
                  transportPaymentAmount
                  vehicleId
                  ordersEscortConnectedsByEscortId1 {
                    nodes {
                      id
                      ordersConnectedId
                      totalBilling
                      totalDistance
                      escortId2
                      escortId1
                      isSameDrivers
                      driverId1
                      driverId2
                      driver1Billing
                      driver2Billing
                    }
                  }
                  ordersEscortConnectedsByEscortId2 {
                    nodes {
                      id
                      ordersConnectedId
                      totalBilling
                      totalDistance
                      escortId2
                      escortId1
                      isSameDrivers
                      driverId1
                      driverId2
                      driver1Billing
                      driver2Billing
                    }
                  }
                  userByDriverId {
                    fullNameJa
                    id
                  }
                }
              }
              ordersDropoffImagesByTransportId(orderBy: IMAGE_NO_ASC) {
                nodes {
                  id
                  imageFile
                  imageNo
                  transportId
                }
              }
            }
          }
        }
    }
  }
`;

export const INSERT_NESTED_ORDERS = gql`
  mutation insertOrders($payload: String!) {
    ordersNestedInsert(input: {payload: $payload}) {
      orderId,
      transportIds
    }
  }
`;

export const UPDATE_NESTED_ORDERS = gql`
  mutation updateOrders($payload: String!) {
    ordersNestedUpdate(input: {payload: $payload}) {
      orderId,
      transportIds
    }
  }
`;

export const UPDATE_ORDER = gql`
  mutation updateOrderById($id: Int!, $patch: OrderPatch!) {
    updateOrderById(input: { orderPatch: $patch, id: $id }) {
      order {
        id
      }
    }
  }
`

export const UPDATE_ESCORT = gql`
  mutation updateOrdersEscortVehicleById($id: Int!, $patch: OrdersEscortVehiclePatch!) {
    updateOrdersEscortVehicleById(input: { ordersEscortVehiclePatch: $patch, id: $id }) {
      ordersEscortVehicle {
        id
        transportBillingAmount
      }
    }
  }
`

export const GET_ORDER_UPDATED_AT = gql`
  query getOrdersDispatch($id: Int!) {
    orderById(id: $id) {
      updatedAt
    }
  }
`;

export const GET_CONNECTED_ORDER = gql`
  query allOrders($orderId: Int!) {
    allOrders(filter: {id: {equalTo: $orderId}}) {
      nodes {
        transportDistance
        transportBillingAmount
        driverId1
        customerByCustomerId {
          transportationUnitPriceId
          escortVehicleUnitPriceId
        }
        packagingByPacking1 {
          id
          weight
        }
      }
    }
  }
`;

export const GET_CONNECTED_DATA = gql`
  query allOrders($escortFilter: OrdersEscortVehicleFilter!, $orderFilter: OrderFilter!) {
    allOrdersEscortVehicles(filter: $escortFilter) {
      nodes {
        id
        escortNo
        pickupDistance
        transportDistance
        transportPaymentAmount
        driverId
      }
    }
    allOrders(filter: $orderFilter) {
      nodes {
        transportDistance
        transportBillingAmount
        customerByCustomerId {
          transportationUnitPriceId
          escortVehicleUnitPriceId
        }
        packagingByPacking1 {
          id
          weight
        }
      }
    }
  }
`;
