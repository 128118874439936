import {gql} from 'apollo-boost';

const getAll = gql`
query allHolidays($first: Int, $offset: Int, $holidayFilter: HolidayFilter) {
  allHolidays(first:$first,offset:$offset,filter:$holidayFilter){
        nodes {
          holidayName
          holidayDate
        },
        totalCount
    }
}`;

const create = gql`
  mutation createHolidays($holidaysList : [CreateHolidayInput!]){
    holidaysMutate(input: { holidaysList: $holidaysList }){
      result {
        holiday_date
        holiday_name
      }
    }
}
`;


export {create, getAll};