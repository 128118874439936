import React, { Fragment, useReducer, useRef, useState } from 'react';
import { Form, Row, Col, Table, Modal } from 'antd';

import AEModalBase from '../../commons/AEModalBase';
import TransportationDetailsList from './TransportationDetailsList'
import styles from '../../assets/scss/table.module.scss';
import moment from 'moment';

/** Get All Customer Queries **/
import {
    getById,
    getAll,
    estimatesDetailsBatch,
    estimatesDetailsBatchUpdate,
    estimatesDetailsBatchDelete
} from '../../queries/Estimate';
import { EstimateContext, initialState, reducer } from './Context';

/** Get All Taxes **/
import { getAllTaxes } from '../../queries/Tax';

/** Get user_id stored in LocalStorage **/
import decodeToken from '../../utils/user_id';

/** Get ADhoc translate **/
import adhocTranslator from '../../utils/locales/translate-adhoc';

/** Get All Common  **/
import {
    CustomInputNumber,
    CustomInput,
    CustomHiddenInput,
    CustomButton,
    CustomCheckbox
} from '../../commons/CustomDataEntry';


import UnitPrices from "../../commons/Picklist/UnitPrices";

import _ from 'lodash';

const EstimateConfig = ({ mode }) => {
    let lt = adhocTranslator('K050-020');
    let lV = adhocTranslator('K130-010');
    let defaultList = [];
    let render = 1;

    let ref = useRef();
    let didRun = useRef();
    const [estimatesState, dispatch] = useReducer(reducer, initialState);

    const [btnTblAction, setBtnTblAction] = useState('')
    const loadDataAction = (values, transportationUnitPriceId, escortVehicleUnitPriceId, subcontractedEscortVehicleUnitPriceId) => {
        if (!didRun.current) {
            didRun.current = true
            dispatch({
                type: 'filters',
                payload: {
                    transportId: transportationUnitPriceId,
                    estimateId: escortVehicleUnitPriceId,
                    subcontractEstimateId: subcontractedEscortVehicleUnitPriceId,
                    weight: values.weight
                }
            })
        }
    }

    const loadData = (values) => {
        if (!didRun.current) {
            didRun.current = true
            dispatch({
                type: 'filters',
                payload: {
                    transportId: values.transportationUnitPriceId,
                    estimateId: values.escortVehicleUnitPriceId,
                    subcontractEstimateId: values.subcontractedEscortVehicleUnitPriceId,
                }
            })
        }
    }

    const resetBtnTbl = (val) => {
        if (val) {
            setBtnTblAction('')
        }
    }

    return {
        mode,
        /** Redirect config **/
        redirect: '/output-estimate',

        /** query mapping **/
        data: {
            idRef: 'estimateById'
        },
        queries: {
            GET_BY_ID: getById,
            GET_ALL: getAll,
            UPDATE_BY_ID: estimatesDetailsBatchUpdate,
            CREATE: estimatesDetailsBatch,
            DELETE_BY_ID: estimatesDetailsBatchDelete
        },
        /** messages */
        messages: {
            add: "運搬先を設定しますか？",
            edit: "運搬先を更新しますか？",
            delete: "見積書を削除しますか？",
            reg_success_message: lV("reg_success_message"),
            reg_error_message: lV("reg_error_message"),
            edit_success_message: lV("edit_success_message"),
            edit_failure_message: lV("edit_failure_message"),
            delete_success_message: lV("delete_success_message"),
            delete_failure_message: lV("delete_failure_message")
        },
        // messages: {
        //     add: "運搬先を設定しますか？",
        //     edit: "運搬先を更新しますか？",
        //     delete: "運搬先を削除しますか？"
        // },
        decorate: (variables, state, data) => {
            if (!!variables.subtotal) {
                variables.subtotal = parseFloat(variables.subtotal.toString().replace(/,/g, ''))
            }
            if (!!variables.consumptionTax) {
                variables.consumptionTax = parseFloat(variables.consumptionTax.toString().replace(/,/g, ''))
            }
            if (!!variables.totalAmount) {
                variables.totalAmount = parseFloat(variables.totalAmount.toString().replace(/,/g, ''))
            }

            if (state.mode === 'add') {
                let list = [];
                if (!state.list) {
                    list = data.list ? data.list : [];
                } else {
                    list = state.list;
                }
                let listing = _.map(list, (list) => {
                    list.createdUserId = decodeToken().user_id;
                    list.updatedUserId = decodeToken().user_id;
                    return _.omit(list, ['id', 'key', 'estimateId', 'weight']);
                });
                variables.createdUserId = decodeToken().user_id;
                variables.estimatesDetails = listing;
                return variables;

            } else {
                let list = [];

                if (!state.list) {
                    if (data) {
                        list = data.list ? data.list : [];
                    }
                } else {
                    list = state.list;
                }

                let listing = _.map(list, (list) => {
                    list.updatedUserId = decodeToken().user_id;
                    list.createdUserId = decodeToken().user_id;
                    return _.omit(list, ['id', 'key', 'estimateId', 'weight']);
                });
                variables.createdUserId = decodeToken().user_id;
                variables.estimatesDetails = listing;
                return variables;
            }

        },
        decorateResult: (data, state, setState) => {
            if (!_.isEmpty(data) && (mode === 'copy' || mode === 'edit' || mode === 'delete')) {
                defaultList = [];
                _.each(data.estimatesDetailsByEstimateId.nodes, (list) => {
                    defaultList.push({
                        id: list.id,
                        key: list.id,
                        estimateId: list.estimateId,
                        sequenceNo: list.sequenceNo,
                        originDestinationLocation: list.originDestinationLocation,
                        packing1: list.packing1,
                        packagingName: list.packagingByPacking1.packagingName,
                        weight: list.packagingByPacking1.weight,
                        transportDistance: !!list.transportDistance ? list.transportDistance : 0,
                        transportFee: list.transportFee,
                        totalTransport: list.transportFee + list.escortVehicleFee,
                        notes: list.notes,
                        escortVehicleOption: list.escortVehicleOption,
                        escortVehicleCount: list.escortVehicleCount,
                        escortVehicleDistance: list.escortVehicleDistance,
                        escortVehicleFee: list.escortVehicleFee,
                        // escortVehicleNotes: list.escortVehicleNotes,
                        outsourceEscortVehicleOption: list.outsourceEscortVehicleOption,
                        outsourceEscortVehicleCount: list.outsourceEscortVehicleCount,
                        outsourceEscortVehicleDistance: list.outsourceEscortVehicleDistance,
                        outsourceEscortVehicleFee: list.outsourceEscortVehicleFee,

                    });
                });
                data.list = defaultList;
            }

            if ((mode === 'edit' || mode === 'delete' || mode === 'copy') && render === 1) {
                let totalAmount = Number(data.totalAmount);
                let subtotal = Number(data.subtotal);
                let consumptionTax = Number(data.consumptionTax)
                setState({
                    totalAmount,
                    subtotal,
                    consumptionTax
                });
            }
            render = 0;
            return data;
        },
        component: function ({ form, data, handleSubmit, loading, id, setState, state }) {
            if (data.list) {
                data.list.forEach((el, key) => {
                    if (el.totalTransport) {
                        delete data.list[key].totalTransport
                    }
                })
            }
            const reg = new RegExp(/^\d+(\.\d+)?$/);
            let tdl = TransportationDetailsList({
                modal: {
                    onEdit: (id) => {
                        let lists = [];
                        if (!state.list) {
                            lists = data.list ? data.list : [];
                        } else {
                            lists = state.list;
                        }
                        const index = _.findIndex(lists, (list) => {
                            return list.id === id
                        });
                        onAction('edit', lists[index], data.transportationUnitPriceId, data.escortVehicleUnitPriceId, data.subcontractedEscortVehicleUnitPriceId);
                        setBtnTblAction('edit')
                    },
                    onCopy: (id) => {
                        let lists = [];
                        if (!state.list) {
                            lists = data.list ? data.list : [];
                        } else {
                            lists = state.list;
                        }
                        const index = _.findIndex(lists, (list) => {
                            return list.id === id
                        });
                        onAction('copy', lists[index], data.transportationUnitPriceId, data.escortVehicleUnitPriceId, data.subcontractedEscortVehicleUnitPriceId);
                        setBtnTblAction('copy')
                    },
                    onDelete: (id) => {
                        let lists = [];
                        if (!state.list) {
                            lists = data.list ? data.list : [];
                        } else {
                            lists = state.list;
                        }
                        const index = _.findIndex(lists, (list) => {
                            return list.id === id
                        });
                        onAction('delete', lists[index], data.transportationUnitPriceId, data.escortVehicleUnitPriceId, data.subcontractedEscortVehicleUnitPriceId);
                        setBtnTblAction('delete')
                    }
                },
                mode: mode,
            });
            loadData(data)
            let onAction = (mode, data, transportationUnitPriceId, escortVehicleUnitPriceId, subcontractedEscortVehicleUnitPriceId) => {
                loadDataAction(data, transportationUnitPriceId, escortVehicleUnitPriceId, subcontractedEscortVehicleUnitPriceId);
                dispatch({
                    type: 'mode',
                    mode
                });
                setState({
                    mode: mode,
                    form: data
                });
                form.setFieldsValue({
                    weight: data.weight
                });
                dispatch({
                    type: 'filters',
                    payload: {
                        weight: data.weight
                    }
                });
                dispatch({
                    type: 'weight',
                    weight: data.weight
                });
                ref.current.popupModal();
            }
            let subtotalChange = async (value) => {
                let total = value
                if (reg.test(value)) {
                    const { data } = await this.props.client.query({
                        fetchPolicy: 'network-only',
                        query: getAllTaxes
                    });

                    const currentData = moment().valueOf();
                    let taxes = _.filter(data.allTaxes.nodes, (tax) => {
                        let effectiveDate = moment(tax.effectiveDate).valueOf();
                        return effectiveDate <= currentData
                    });
                    let taxRate = taxes.pop().taxRate / 100;
                    let consumptionTax = Math.floor(Number(value * taxRate));
                    let totalAmount = Number((parseFloat(consumptionTax) + parseFloat(value)).toFixed(2));

                    setState({
                        totalAmount: totalAmount.toLocaleString(),
                        consumptionTax: consumptionTax.toLocaleString()
                    });
                    form.setFieldsValue({
                        totalAmount: totalAmount.toLocaleString(),
                        consumptionTax: consumptionTax.toLocaleString(),
                        subtotal: total.toLocaleString()
                    });
                }
            }

            let taxChange = async (value) => {
                if (reg.test(value)) {
                    let totalAmount = Number((form.getFieldValue('subtotal') + value).toFixed(2));
                    setState({
                        totalAmount
                    });
                    form.setFieldsValue({
                        totalAmount
                    });
                }
            }

            let subtotalHandler = async () => {
                let x = document.getElementById('subtotal')
                x.value = x.value.replace(/\D+/g, '')
                x.value = `${x.value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                form.setFieldsValue({
                    subtotal: x.value
                })
            }
            let consumptionTaxHandler = async (e) => {
                let x = document.getElementById('consumptionTax')
                x.value = x.value.replace(/\D+/g, '')
                x.value = `${x.value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                form.setFieldsValue({
                    consumptionTax: x.value
                })
            }
            let totalAmountHandler = async (e) => {
                let x = document.getElementById('totalAmount')
                x.value = x.value.replace(/\D+/g, '')
                x.value = `${x.value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')

                form.setFieldsValue({
                    totalAmount: x.value
                })
            }

            let attachModeHandler = (mode) => {
                setState({
                    mode
                });
            };

            const onData = (newRecord) => {

                if (newRecord.hasOwnProperty('transportDistance') && !!newRecord.transportDistance) {
                    newRecord.transportDistance = parseFloat(newRecord.transportDistance.toString().replace(/,/g, ''))
                }
                if (newRecord.hasOwnProperty('escortVehicleFee') && !!newRecord.escortVehicleFee) {
                    newRecord.escortVehicleFee = parseFloat(newRecord.escortVehicleFee.toString().replace(/,/g, ''))
                }
                if (newRecord.hasOwnProperty('outsourceEscortVehicleFee') && !!newRecord.outsourceEscortVehicleFee) {
                    newRecord.outsourceEscortVehicleFee = parseFloat(newRecord.outsourceEscortVehicleFee.toString().replace(/,/g, ''))
                }
                if (newRecord.hasOwnProperty('escortVehicleCount') && !!newRecord.escortVehicleCount) {
                    newRecord.escortVehicleCount = parseFloat(newRecord.escortVehicleCount.toString().replace(/,/g, ''))
                }
                if (newRecord.hasOwnProperty('outsourceEscortVehicleCount') && !!newRecord.outsourceEscortVehicleCount) {
                    newRecord.outsourceEscortVehicleCount = parseFloat(newRecord.outsourceEscortVehicleCount.toString().replace(/,/g, ''))
                }
                if (newRecord.hasOwnProperty('transportFee') && !!newRecord.transportFee) {
                    newRecord.transportFee = parseFloat(newRecord.transportFee.toString().replace(/,/g, ''))
                }
                if (newRecord.hasOwnProperty('totalAmount') && !!newRecord.totalAmount) {
                    newRecord.totalAmount = parseFloat(newRecord.totalAmount.toString().replace(/,/g, ''))
                }
                if (!!newRecord.transportFare) {
                    newRecord.transportFare = parseFloat(newRecord.transportFare.toString().replace(/,/g, ''))
                }
                if (!!newRecord.escortVehicleDistance) {
                    newRecord.escortVehicleDistance = parseFloat(newRecord.escortVehicleDistance.toString().replace(/,/g, ''))
                }
                if (!!newRecord.outsourceEscortVehicleDistance) {
                    newRecord.outsourceEscortVehicleDistance = parseFloat(newRecord.outsourceEscortVehicleDistance.toString().replace(/,/g, ''))
                }
                if (!!newRecord.escortVehicleFare) {
                    newRecord.escortVehicleFare = parseFloat(newRecord.escortVehicleFare.toString().replace(/,/g, ''))
                }
                if (!!newRecord.transportDistance) {
                    newRecord.transportDistance = parseFloat(newRecord.transportDistance.toString().replace(/,/g, ''))
                }
                if (!!newRecord.subtotal) {
                    newRecord.subtotal = parseFloat(newRecord.subtotal.toString().replace(/,/g, ''))
                }
                let list = [];
                if (!state.list) {
                    list = data.list ? data.list : [];
                } else {
                    list = state.list;
                }
                if (estimatesState.mode === 'edit') {
                    const index = _.findIndex(list, (list) => {
                        return list.id === state.form.id
                    });
                    list[index] = {
                        id: state.form.id,
                        key: state.form.key,
                        estimateId: newRecord.estimateId,
                        sequenceNo: newRecord.sequenceNo,
                        originDestinationLocation: newRecord.originDestinationLocation,
                        packing1: newRecord.packing1,
                        packagingName: newRecord.packagingName,
                        transportDistance: !!newRecord.transportDistance ? newRecord.transportDistance : 0,
                        transportFee: newRecord.transportFee,
                        weight: newRecord.weight,
                        notes: newRecord.notes,
                        escortVehicleOption: newRecord.escortVehicleOption,
                        escortVehicleCount: newRecord.escortVehicleCount,
                        escortVehicleDistance: newRecord.escortVehicleDistance,
                        escortVehicleFee: newRecord.escortVehicleFee,
                        // escortVehicleNotes: newRecord.escortVehicleNotes,
                        outsourceEscortVehicleOption: newRecord.outsourceEscortVehicleOption,
                        outsourceEscortVehicleCount: newRecord.outsourceEscortVehicleCount,
                        outsourceEescortVehicleDistance: newRecord.outsourceEescortVehicleDistance,
                        outsourceEscortVehicleFee: newRecord.outsourceEscortVehicleFee,

                    }
                } else if (estimatesState.mode === 'delete') {
                    _.remove(list, (list) => {
                        return list.id === state.form.id
                    });
                    _.map(list, (list) => {
                        list.key -= 1;
                    });
                } else {
                    let lastId = _.get(_.last(list), 'id', 0);
                    list.push({
                        id: lastId + 1,
                        key: lastId + 1,
                        estimateId: newRecord.estimateId,
                        sequenceNo: newRecord.sequenceNo,
                        originDestinationLocation: newRecord.originDestinationLocation,
                        packing1: newRecord.packing1,
                        packagingName: newRecord.packagingName,
                        transportDistance: newRecord.transportDistance,
                        transportFee: newRecord.transportFee,
                        weight: newRecord.weight,
                        notes: newRecord.notes,
                        escortVehicleOption: newRecord.escortVehicleOption,
                        escortVehicleCount: newRecord.escortVehicleCount,
                        escortVehicleDistance: newRecord.escortVehicleDistance,
                        escortVehicleFee: newRecord.escortVehicleFee,
                        // escortVehicleNotes: newRecord.escortVehicleNotes,
                        outsourceEscortVehicleOption: newRecord.outsourceEscortVehicleOption,
                        outsourceEscortVehicleCount: newRecord.outsourceEscortVehicleCount,
                        outsourceEescortVehicleDistance: newRecord.outsourceEescortVehicleDistance,
                        outsourceEscortVehicleFee: newRecord.outsourceEscortVehicleFee,

                    });
                }
                let subtotal = 0;

                _.each(list, (list) => {
                    if (list.escortVehicleFee && list.transportFee) {
                        subtotal += list.escortVehicleFee + list.transportFee
                    }
                    if (list.transportFee && !list.escortVehicleFee) {
                        subtotal += list.transportFee
                    }
                });

                if (_.isEmpty(list)) {
                    subtotal = 0;
                }

                subtotalChange(subtotal)

                setState({
                    list,
                    subtotal
                });

                form.setFieldsValue({
                    subtotal
                })

            }

            let showModal = () => {
                if (mode === 'add') {
                    form.validateFields((err, values) => {
                        if (!err) {

                            setState({
                                show: true
                            });
                        }
                    });
                }
            }

            let minValue = (rule, value, callback) => {
                if (!!value && value > 0) {
                    callback();
                }
                callback();
            }

            let btnLabel;
            if (mode === 'edit') {
                btnLabel = lt('btn_update')
            } else if (mode === 'delete') {
                btnLabel = lt('btn_delete')
            } else {
                btnLabel = lt('btn_register')
            }

            const handleTransportPrice = e => {
                dispatch({
                    type: 'filters',
                    payload: {
                        transportId: e
                    }
                })
            }

            const handleEscortPrice = e => {
                dispatch({
                    type: 'filters',
                    payload: {
                        estimateId: e
                    }
                })
            }
            const handleSubcontractedEscortPrice = e => {
                dispatch({
                    type: 'filters',
                    payload: {
                        subcontractEstimateId: e
                    }
                })
            }

            let client = mode !== 'delete' ? { client: this.props.client } : {};

            const checkSubmit = e => {
                if(mode === 'add') {
                    return showModal()
                } else {
                    return handleSubmit(e)
                }
            }

            return (
                <EstimateContext.Provider value={{ estimatesState, dispatch, ...client }}>
                    <Row type='flex' className='h100' justify='space-around' align='top' >
                        <Col span={24}>
                            <Form onSubmit={checkSubmit} layout='vertical'>
                                <CustomHiddenInput
                                    fieldName={mode === 'add' ? 'createdUserId' : 'updatedUserId'}
                                    form={form}
                                    decorator={{
                                        initialValue: decodeToken().user_id
                                    }}
                                />
                                <CustomInput
                                    form={form}
                                    fieldName='customerNameJa'
                                    label={lt('lbl_customer_name')}
                                    mode={mode}
                                    decorator={{
                                        rules: [{
                                            required: true,
                                            message: lt('顧客名を入力してください。'),
                                        }],
                                        initialValue: data.customerNameJa,
                                    }}
                                    width={7}
                                />
                                <UnitPrices
                                    form={form}
                                    fieldName='transportationUnitPriceId'
                                    label={lt('lbl_transportation_unit_price_chart')}
                                    labelWidth={130}
                                    filter="FARE"
                                    style={{ width: 320, display: 'inline-block', marginRight: 10 }}
                                    mode={mode}
                                    decorator={{
                                        rules: [{
                                            required: true,
                                            message: lt('運搬単価表を入力してください。'),
                                        }],
                                        initialValue: data.transportationUnitPriceId
                                    }}
                                    onChange={handleTransportPrice}
                                />
                                <UnitPrices
                                    form={form}
                                    fieldName='escortVehicleUnitPriceId'
                                    label={lt('lbl_escort_vehicle_unit_price_chart')}
                                    labelWidth={130}
                                    mode={mode}
                                    filter="ESCORT_VEHICLE_FARE"
                                    style={{ width: 320, display: 'inline-block', marginRight: 10 }}
                                    decorator={{
                                        // rules: [{
                                        //     required: true,
                                        //     message: lt('誘導単価表を入力してください。'),
                                        // }],
                                        initialValue: data.escortVehicleUnitPriceId
                                    }}
                                    onChange={handleEscortPrice}
                                />

                                <UnitPrices
                                    form={form}
                                    fieldName='subcontractedEscortVehicleUnitPriceId'
                                    label={lt('lbl_subcontracted_escort_vehicle_unit_price_chart')}
                                    labelWidth={130}
                                    mode={mode}
                                    filter="ESCORT_VEHICLE_FARE"
                                    style={{ width: 320, display: 'inline-block', marginRight: 10 }}
                                    decorator={{
                                        // rules: [{
                                        //     required: true,
                                        //     message: lt('誘導単価表を入力してください。'),
                                        // }],
                                        initialValue: data.subcontractedEscortVehicleUnitPriceId
                                    }}
                                    onChange={handleSubcontractedEscortPrice}
                                />

                                <AEModalBase
                                    title='運搬先詳細'
                                    ref={ref}
                                    onData={onData}
                                    mode={mode}
                                    form={state.form}
                                    btnTblAction={btnTblAction}
                                    resetBtnTbl={resetBtnTbl}
                                    modeChange={attachModeHandler} />

                                <Table
                                    bordered={true}
                                    className={styles.table}
                                    columns={tdl.columns}
                                    pagination={false}
                                    dataSource={state.list ? state.list : data.list ? data.list : []}
                                    style={{ marginBottom: '50px' }}
                                />
                                <CustomInputNumber
                                    onBlur={subtotalHandler}
                                    form={form}
                                    fieldName='subtotal'
                                    label={lt('lbl_subtotal_before_tax')}
                                    className={'lbltax allignRight'}
                                    postLabel={lt('円')}
                                    style={{ width: 190, marginRight: 10 }}
                                    mode={mode}
                                    decorator={{
                                        initialValue: state.subtotal,
                                        rules: [{
                                            required: true,
                                            message: lt('小計を入力してください。')
                                        }, { validator: minValue }]
                                    }}
                                    width={4}
                                    onChange={subtotalChange}
                                />
                                <CustomInputNumber
                                    onBlur={consumptionTaxHandler}
                                    form={form}
                                    fieldName='consumptionTax'
                                    label={lt('lbl_consumption_tax')}
                                    className={'lbltax allignRight'}
                                    postLabel={lt('円')}
                                    style={{ width: 190, marginRight: 10 }}
                                    mode={mode}
                                    decorator={{
                                        initialValue: state.consumptionTax,
                                        rules: [{
                                            required: true,
                                            message: lt('消費税を入力してください。')
                                        }, { validator: minValue }]
                                    }}
                                    width={4}
                                    onChange={taxChange}
                                />
                                <CustomInputNumber
                                    onBlur={totalAmountHandler}
                                    form={form}
                                    fieldName='totalAmount'
                                    label={lt('lbl_total_amount_before_tax')}
                                    className={'lbltax allignRight'}
                                    postLabel={lt('円')}
                                    style={{ width: 190, marginRight: 1 }}
                                    mode={mode}
                                    decorator={{
                                        initialValue: state.totalAmount,
                                        rules: [{
                                            required: true,
                                            message: lt('合計金額を入力してください。')
                                        }, { validator: minValue }]
                                    }}
                                    width={4}
                                />
                                <CustomHiddenInput
                                    form={form}
                                    type='hidden'
                                    fieldName='estimateNo'
                                    mode={mode}
                                    decorator={{
                                        initialValue: data.estimateNo ? data.estimateNo : '1'
                                    }}
                                />
                                <Modal
                                    title={'確認'}
                                    visible={state.show}
                                    onOk={handleSubmit}
                                    onCancel={() => { setState({ show: false }) }}
                                    okText="はい"
                                    destroyOnClose={false}
                                    cancelText="いいえ"
                                    closable={false}
                                    centered={true}
                                    okType="primary"
                                    className="modal-vehicle estimateModal">
                                    <Fragment>
                                        <div className="ant-row ant-form-item" style={{ textAlign: 'center' }}>
                                            {'見積書を登録します。よろしいですか？'}<br />
                                            {'見積書の登録時に印刷を同時に行うときは、以下のチェックボックスをチェックし、「はい」ボタンを押してください。'}
                                        </div>
                                        <div className="ant-row ant-form-item" style={{ textAlign: 'center' }}>
                                            <CustomCheckbox
                                                label={'見積書の印刷'}
                                                form={form}
                                                mode={mode}
                                                fieldName='print'
                                                decorator={{
                                                    initialValue: false
                                                }}
                                            />
                                        </div>
                                    </Fragment>
                                </Modal>
                                <CustomHiddenInput
                                    form={form}
                                    type='hidden'
                                    fieldName='triggerFlag'
                                    mode={mode}
                                    decorator={{
                                        initialValue: mode
                                    }}
                                />
                                <div style={{ textAlign: mode === 'add' ? 'center' : '' }}>
                                    <CustomButton label={btnLabel}
                                        event={checkSubmit}
                                        loading={loading}
                                        style={{ marginTop: '15px' }}
                                        onClick={() => { showModal() }}
                                        htmlType={mode === 'add' ? "button" : "submit"}
                                        offset={12} />
                                </div>
                            </Form>
                        </Col>
                    </Row>
                </EstimateContext.Provider>
            );
        }
    }
}

export default EstimateConfig
