
import React, { Component, Fragment } from 'react';
import TableToExcel from '@linways/table-to-excel';
import { withApollo } from 'react-apollo';
import moment from 'moment';
import _ from 'lodash';
import 'moment/locale/ja';
import uuid from 'uuid/v4';
import adhocTranslator from '../../utils/locales/translate-adhoc';

const lt = adhocTranslator('K140-011');

moment.locale('ja');

const weatherTypes = {
    "CLEAR": "晴れ",
    "CLOUDY": "曇り",
    "HEAVY_RAIN": "大雨",
    "RAIN": "雨",
    "HEAVY_SNOW": "大雪",
    "SNOW": "雪"
};

const ordersType = `
    excelOrdersOutputData {
        id
        vehicleId
        driverId1
        driverId
        driverId2
        nameJa
        packagingName
        transportDate
        dispatchStatus
        customerId
        packing1
        packing2
        transportDistance
        transportFare
        escortVehicleDistance
        escortVehicleFare
        notes
        remarks
        overnight
        vehicleNo
        ordersTransportsByOrderId {
            destination
            origin
            dropoffActualDatetime
            dropoffAssignee
            dropoffBorrower
            dropoffContact
            dropoffDate
            dropoffHourFrom
            dropoffHourTo
            dropoffMileage
            dropoffMinuteFrom
            dropoffMinuteTo
            dropoffOption
            dropoffMemo
            dropoffSiteName
            id
            nodeId
            numberEscortVehicle
            orderId
            pickupActualDatetime
            pickupAssignee
            pickupBorrower
            pickupContact
            pickupDate
            pickupHour
            pickupMemo
            originColor
            destinationColor
            pickupMileage
            pickupMinute
            pickupOption
            pickupSiteName
            transportNo
        }
        arrivalsByVehicleId
        departuresByVehicleId
    }
`;

class OrderExcel extends Component {

    constructor(props) {
        super(props);
        this.excel = {}
    };

    render() {
        let firstDataId,
            dataVehicleNo,
            dataTransportDate,
            dataDriverName1,
            dataDriverName2,
            dataWeather = [],
            dataArrivalDatetime = [],
            dataArrivalMileage = [],
            dataFuelOilSupply = [],
            dataDeparturesDatetime = [],
            dataDeparturesMileage = [];

        const createExcel = (data) => {
            let dataTotalArrivalMileage = 0,
                dataTotalDeparturesMileage = 0,
                dataDropoffMileage = 0,
                dataPickupMileage = 0,
                dataDetails = [];
            // data = _.orderBy(
            //     data,
            //     [
            //         'dispatchStatus',
            //         'vehicleByVehicleId.vehicleType',
            //         'vehicleByVehicleId.id',
            //         order => {
            //             const hour = order.ordersTransportsByOrderId.length &&
            //                 Object.values(order.ordersTransportsByOrderId)[0].pickupHour
            //             const minute = order.ordersTransportsByOrderId.length &&
            //                 Object.values(order.ordersTransportsByOrderId)[0].pickupMinute
            //             return new moment(`${hour}:${minute}`, "hh:mm").format("HH:mm");
            //         },
            //         order => {
            //             const hour = order.ordersTransportsByOrderId.length &&
            //                 Object.values(order.ordersTransportsByOrderId)[0].dropoffHourFrom
            //             const minute = order.ordersTransportsByOrderId.length &&
            //                 Object.values(order.ordersTransportsByOrderId)[0].dropoffMinuteFrom
            //             return new moment(`${hour}:${minute}`, "hh:mm").format("HH:mm");
            //         }
            //     ],
            //     ['asc', 'asc', 'asc', 'asc', 'asc']
            // );

            const dropoffList = [], transportDateList = [], pickupList = [];
            let hasOvernight = false;
            let dropoffDate = null;
            _.forEach(data, function (row, key) {
                if (row.overnight === 'YES') {
                    hasOvernight = true;
                };
                transportDateList.push(row.transportDate);
                dropoffList.push(...row.ordersTransportsByOrderId.map(transports => transports.dropoffDate));
                pickupList.push(...row.ordersTransportsByOrderId.map(transports => transports.pickupDate));
                if (key === 0) {
                    // Get first row detail values, since every row details are similar
                    firstDataId = row.id;
                    dataVehicleNo = row.vehicleNo;
                    dataTransportDate = moment(row.transportDate).isValid() && moment(row.transportDate).format('YYYYMMDD');
                    dataDriverName1 = row.driverId1
                    dataDriverName2 = row.driverId2
                    dataWeather = row.arrivalsByVehicleId.map(({ weather }) => {
                        return weatherTypes[weather]
                    });
                    dataArrivalDatetime = row.arrivalsByVehicleId.map(({ arrivalDatetime }) => {
                        return moment(arrivalDatetime).format("MM/DD HH:mm");
                    });
                    dataArrivalMileage = row.arrivalsByVehicleId.map(({ arrivalMileage }) => {
                        return arrivalMileage.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
                    });
                    dataFuelOilSupply = row.arrivalsByVehicleId.map(({ fuelOilSupply }) => {
                        return fuelOilSupply;
                    });
                    dataDeparturesDatetime = row.departuresByVehicleId.map(({ departuresDatetime }) => {
                        return moment(departuresDatetime).format("MM/DD HH:mm");
                    });
                    dataDeparturesMileage = row.departuresByVehicleId.map(({ departuresMileage }) => {
                        return departuresMileage.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
                    });

                    dataTotalArrivalMileage += _.get(row, 'arrivalsByVehicleId[0].arrivalMileage', 0);
                    dataTotalDeparturesMileage += _.get(row, 'departuresByVehicleId[0].departuresMileage', 0);
                }

                dataDropoffMileage += _.get(row, 'ordersTransportsByOrderId[0].dropoffMileage', 0);
                dataPickupMileage += _.get(row, 'ordersTransportsByOrderId[0].pickupMileage', 0);
                let rowDataDetails = row.ordersTransportsByOrderId.map((detail, index) => {
                    const packages = _.compact([row.packagingName, row.packing2]);
                    return (
                        <tr data-height="26" key={uuid()}>
                            <td></td>
                            <td data-b-l-s="thin" data-b-b-s="thin" data-a-h="center" data-a-v="middle" colSpan="2">{row.nameJa}</td>
                            <td data-f-sz='9' data-a-wrap='true' data-b-l-s="thin" data-b-b-s="thin" data-a-h="center" data-a-v="middle" colSpan="2">{packages.join(',')}</td>
                            <td data-b-l-s="thin" data-b-b-s="thin" data-a-h="center" data-a-v="middle" colSpan="2">{detail.origin}</td>
                            <td data-b-l-s="thin" data-b-b-s="thin" data-a-h="center" data-a-v="middle">{moment(detail.pickupActualDatetime).isValid() && moment(detail.pickupActualDatetime).format('HH:mm')}</td>
                            <td data-b-l-s="thin" data-b-b-s="thin" data-a-h="right" data-a-v="middle">{!detail.pickupMileage ? 0 : detail.pickupMileage.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}km{/* //11 */}</td>
                            <td data-b-l-s="thin" data-b-b-s="thin" data-a-h="center" data-a-v="middle" colSpan="2">{detail.destination}</td>
                            <td data-b-l-s="thin" data-b-b-s="thin" data-a-h="right" data-a-v="middle">{!detail.dropoffMileage ? 0 : detail.dropoffMileage.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}km{/* //13 */}</td>
                            <td data-b-l-s="thin" data-b-b-s="thin" data-a-h="center" data-a-v="middle">{moment(detail.dropoffActualDatetime).isValid() && moment(detail.dropoffActualDatetime).format('HH:mm')}</td>
                            <td data-b-l-s="thin" data-b-b-s="thin" data-a-h="right" data-a-v="middle">{index ? '' : (!detail.dropoffMileage && !detail.pickupMileage ? '0km' : `${(detail.dropoffMileage - detail.pickupMileage).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}km`)}</td>
                            <td data-b-l-s="thin" data-b-b-s="thin" data-b-r-s="thin" data-a-h="center" data-a-v="middle" colSpan="2">{row.notes} {/* //16 */}</td>
                            <td></td>
                        </tr>
                    );
                });
                dataDetails.push(rowDataDetails);

            });

            if (hasOvernight) {
                const tranport = _.max(transportDateList);
                const pickup = _.max(pickupList);
                dropoffDate = _.max([...dropoffList, tranport, pickup]);
            };

            let idvalue = "Output" + firstDataId;

            let totalArrivalMileage = dataTotalArrivalMileage;
            let totalDeparturesMileage = dataTotalDeparturesMileage;
            let totalMilleage = totalArrivalMileage - totalDeparturesMileage;
            let totalActualDistance = dataDropoffMileage - dataPickupMileage;

            let actualOperation = 0;
            if (totalMilleage) {
                // to prevent infinity value
                actualOperation = (totalActualDistance / totalMilleage) * 100
            }

            let details = dataDetails;
            for (let j = 6 - details.length; j >= 0; j--) {
                let row = (<tr data-height="26" key={uuid()}>
                    <td></td>
                    <td data-b-l-s="thin" data-b-b-s="thin" data-a-h="left" data-a-v="middle" colSpan="2"></td>
                    <td data-b-l-s="thin" data-b-b-s="thin" data-a-h="left" data-a-v="middle" colSpan="2"></td>
                    <td data-b-l-s="thin" data-b-b-s="thin" data-a-h="left" data-a-v="middle" colSpan="2"></td>
                    <td data-b-l-s="thin" data-b-b-s="thin" data-a-h="center" data-a-v="middle"></td>
                    <td data-b-l-s="thin" data-b-b-s="thin" data-a-h="right" data-a-v="middle"></td>
                    <td data-b-l-s="thin" data-b-b-s="thin" data-a-h="left" data-a-v="middle" colSpan="2"></td>
                    <td data-b-l-s="thin" data-b-b-s="thin" data-a-h="right" data-a-v="middle"></td>
                    <td data-b-l-s="thin" data-b-b-s="thin" data-a-h="center" data-a-v="middle"></td>
                    <td data-b-l-s="thin" data-b-b-s="thin" data-a-h="right" data-a-v="middle"></td>
                    <td data-b-l-s="thin" data-b-b-s="thin" data-b-r-s="thin" data-a-h="center" data-a-v="middle" colSpan="2"></td>
                    <td></td>
                </tr>);
                details.push(row)
            }
            let weather = _.union(dataWeather).join('、');
            let arrivalDatetime = _.union(dataArrivalDatetime);
            let arrivalMileage = _.union(dataArrivalMileage);
            let fuelOilSupply = _.union(dataFuelOilSupply);
            let departuresDatetime = _.union(dataDeparturesDatetime);
            let departuresMileage = _.union(dataDeparturesMileage);

            return (
                <Fragment key={uuid()}>
                    <table id={idvalue} data-cols-width="2,12,12,2,9,7,10,8,10,10,7,8,8,10,10,10,1">
                        <tbody>
                            {/* <tr data-height="26">
                                <td width="20">
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td rowSpan="2" style={{height:10, paddingRight:10}}>&nbsp;&nbsp;</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr> */}
                            <tr data-data-height="26">{/* ROW 1 */}
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr data-height="26"></tr>{/* ROW 2 */}
                            <tr data-height="26">{/* ROW 3 */}
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td
                                    colSpan="7"
                                    id="DailyReport"
                                    data-a-v="middle"
                                    data-f-sz="20"
                                    data-a-h="center">運 転 日 報</td>
                                <td
                                    colSpan="4"
                                    data-a-h="center"
                                    data-f-sz="14"
                                    data-b-b-s="thin">有限会社黒潮重機興業 </td>
                                <td></td>
                            </tr>
                            <tr data-height="26">{/* ROW 4 */}
                                <td></td>
                                <td colSpan="10"></td>
                            </tr>
                            <tr data-height="26">{/* ROW 5 */}
                                <td></td>
                                <td data-fill-color="D8D8D8" data-b-a-s="thin" data-a-h="center" data-a-v="middle"></td>
                                <td
                                    colSpan="3"
                                    data-fill-color="D8D8D8"
                                    data-b-a-s="thin" data-a-h="center" data-a-v="middle">運転手</td>
                                <td
                                    data-fill-color="D8D8D8"
                                    data-b-a-s="thin" data-a-h="center" data-a-v="middle">車番</td>
                                <td
                                    colSpan="3"
                                    data-a-v="middle"
                                    data-a-h="center"
                                    data-f-bold='true'
                                    data-b-a-s="thin">{dataVehicleNo}</td>
                                <td
                                    data-fill-color="D8D8D8"
                                    data-b-a-s="thin" data-a-h="center" data-a-v="middle">天候</td>
                                <td
                                    colSpan="2"
                                    data-b-a-s="thin" data-a-h="center" data-a-v="middle">{weather}</td>
                                <td></td>
                                <td data-b-a-s="thin" data-a-h="center" data-a-v="middle">課長</td>
                                <td data-b-a-s="thin" data-a-h="center" data-a-v="middle">管理者</td>
                                <td data-b-a-s="thin" data-a-h="center" data-a-v="middle">代務者</td>
                                <td></td>
                            </tr>
                            <tr data-height="26">{/* ROW 6 */}
                                <td></td>
                                <td
                                    data-fill-color="D8D8D8"
                                    data-b-a-s="thin" data-a-h="center" data-a-v="middle">氏名A</td>
                                <td
                                    colSpan="3"
                                    data-a-v="middle"
                                    data-a-h="center"
                                    data-b-a-s="thin">{dataDriverName1}</td>
                                <td
                                    data-fill-color="D8D8D8"
                                    data-b-a-s="thin" data-a-h="center" data-a-v="middle">日時</td>
                                <td
                                    colSpan="3"
                                    data-a-v="middle"
                                    data-a-h="center"
                                    data-b-a-s="thin">{moment(dataTransportDate).isValid() && moment(dataTransportDate).format('YYYY/MM/DD (ddd)')}{dropoffDate && ` 〜 ${moment(dropoffDate).format('MM/DD (ddd)')}`}</td>
                                <td colSpan="4"></td>
                                <td
                                    rowSpan="2"
                                    data-b-a-s="thin" data-a-h="center" data-a-v="middle"></td>
                                <td
                                    rowSpan="2"
                                    data-b-a-s="thin" data-a-h="center" data-a-v="middle"></td>
                                <td
                                    rowSpan="2"
                                    data-b-a-s="thin" data-a-h="center" data-a-v="middle"></td>
                            </tr>
                            <tr data-height="26">{/* ROW 7 */}
                                <td></td>
                                <td
                                    data-fill-color="D8D8D8"
                                    data-b-a-s="thin" data-a-h="center" data-a-v="middle">氏名B</td>
                                <td
                                    colSpan="3"
                                    data-a-v="middle"
                                    data-a-h="center"
                                    data-b-a-s="thin">{dataDriverName2}</td>
                                <td colSpan="8"></td>
                            </tr>

                            {/* <tr data-height="26">
                                <td width="20">
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td rowSpan="1" style={{height:10}}>&nbsp;</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr> */}
                            <tr data-height="26"></tr>{/* ROW 8 */}

                            <tr data-height="26">{/* ROW 7 */}
                                <td></td>
                                <td data-fill-color="D8D8D8" data-b-a-s="thin" data-a-h="center" data-a-v="middle" colSpan="2">受注先</td>
                                <td data-fill-color="D8D8D8" data-b-a-s="thin" data-a-h="center" data-a-v="middle" colSpan="2">荷姿</td>
                                <td data-fill-color="D8D8D8" data-b-a-s="thin" data-a-h="center" data-a-v="middle" colSpan="2">発地</td>
                                <td data-fill-color="D8D8D8" data-b-a-s="thin" data-a-h="center" data-a-v="middle">時間</td>
                                <td data-fill-color="D8D8D8" data-b-a-s="thin" data-a-h="center" data-a-v="middle">発距離</td>
                                <td data-fill-color="D8D8D8" data-b-a-s="thin" data-a-h="center" data-a-v="middle" colSpan="2">着地</td>
                                <td data-fill-color="D8D8D8" data-b-a-s="thin" data-a-h="center" data-a-v="middle">着距離</td>
                                <td data-fill-color="D8D8D8" data-b-a-s="thin" data-a-h="center" data-a-v="middle">時間</td>
                                <td data-fill-color="D8D8D8" data-b-a-s="thin" data-a-h="center" data-a-v="middle">実車距離</td>
                                <td data-fill-color="D8D8D8" data-b-a-s="thin" data-a-h="center" data-a-v="middle" colSpan="2">備考</td>
                                <td></td>
                            </tr>

                            {details}

                            <tr data-height="26">{/* ROW 17 */}
                                <td></td>
                                <td data-b-a-s="thin" data-a-h="center" data-a-v="middle">入庫時刻</td>
                                <td
                                    colSpan="2"
                                    data-a-h="center" data-a-v="middle"
                                    dangerouslySetInnerHTML={{ __html: arrivalDatetime.length && arrivalDatetime[0] }}></td>
                                {/* <td>{row.arrivalsByVehicleId.length ? row.arrivalsByVehicleId[0].arrivalDatetime : 0} //17</td> */}
                                <td data-b-a-s="thin" data-a-h="center" data-a-v="middle">入庫距離</td>
                                <td
                                    colSpan="2"
                                    data-b-a-s="thin" data-a-h="right" data-a-v="middle" dangerouslySetInnerHTML={{ __html: !arrivalMileage.length ? "0km" : arrivalMileage.join('<br />') + "km" }}></td>
                                {/* <td>{row.arrivalsByVehicleId.length ? row.arrivalsByVehicleId[0].arrivalMileage : 0}//18</td> */}
                                <td data-b-a-s="thin" data-a-h="center" data-a-v="middle">FO補給量</td>
                                <td
                                    colSpan="2"
                                    data-b-a-s="thin" data-a-h="right" data-a-v="middle" dangerouslySetInnerHTML={{ __html: !fuelOilSupply ? "0L" : fuelOilSupply.join('<br/>') + "L" }}></td>
                                {/* <td>{row.arrivalsByVehicleId.length ? row.arrivalsByVehicleId[0].fuelOilSupply : 0} //19</td> */}
                                <td data-b-a-s="thin" data-a-v="top" colSpan="6" rowSpan="3">備考</td>
                                <td></td>
                            </tr>
                            <tr data-height="26">{/* ROW 18 */}
                                <td></td>
                                <td data-b-a-s="thin" data-a-h="center" data-a-v="middle">出庫時刻</td>
                                <td
                                    colSpan="2"
                                    data-b-a-s="thin" data-a-h="center" data-a-v="middle" dangerouslySetInnerHTML={{ __html: departuresDatetime.length && departuresDatetime[0] }}></td>
                                {/* <td>{row.departuresByVehicleId.length ? row.departuresByVehicleId[0].departuresDatetime : 0} //20</td> */}
                                <td data-b-a-s="thin" data-a-h="center" data-a-v="middle">出庫距離</td>
                                <td
                                    colSpan="2"
                                    data-b-a-s="thin" data-a-h="right" data-a-v="middle" dangerouslySetInnerHTML={{ __html: !departuresMileage.length ? "0km" : departuresMileage.join('<br/>') + "km" }}></td>
                                {/* <td>{row.departuresByVehicleId.length ? row.departuresByVehicleId[0].departuresMileage : 0}//21</td> */}
                                <td data-b-a-s="thin" data-a-h="center" data-a-v="middle">実車率</td>
                                <td
                                    colSpan="2"
                                    data-b-a-s="thin" data-a-h="right" data-a-v="middle">{!isNaN(actualOperation) ? actualOperation.toFixed(0) : 0}%{/* //22 */}</td>
                            </tr>
                            <tr data-height="26">{/* ROW 19 */}
                                <td></td>
                                <td data-b-a-s="thin" data-a-h="center" data-a-v="middle">実車距離計</td>
                                <td
                                    colSpan="2"
                                    data-b-a-s="thin" data-a-h="right" data-a-v="middle">{!totalActualDistance ? 0 : totalActualDistance.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}km {/* 23 */}</td>
                                <td data-b-a-s="thin" data-a-h="center" data-a-v="middle">走行距離</td>
                                <td
                                    colSpan="2"
                                    data-b-a-s="thin" data-a-h="right" data-a-v="middle">{!totalMilleage ? 0 : totalMilleage.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}km {/*24*/}</td>
                                <td
                                    colSpan="3"
                                    data-b-a-s="thin" data-a-h="center" data-a-v="middle"></td>
                            </tr>
                            <tr data-height="26"></tr>
                        </tbody>
                    </table>
                </Fragment>
            )
        };

        let { data } = this.props;

        let excels
        if (data.length) {
            data.forEach(form => {
                try {
                    form.arrivalsByVehicleId = form.arrivalsByVehicleId.length ? JSON.parse(form.arrivalsByVehicleId) : [];
                    form.departuresByVehicleId = form.departuresByVehicleId.length ? JSON.parse(form.departuresByVehicleId) : [];
                } catch (e) {
                    form.arrivalsByVehicleId = form.arrivalsByVehicleId.length ? form.arrivalsByVehicleId : [];
                    form.departuresByVehicleId = form.departuresByVehicleId.length ? form.departuresByVehicleId : [];
                }
            })
            excels = createExcel(data);
            setTimeout(() => {
                let idname = "Output" + firstDataId;
                // eslint-disable-next-line no-self-assign
                dataVehicleNo = dataVehicleNo;
                // eslint-disable-next-line no-self-assign
                dataTransportDate = dataTransportDate;
                // eslint-disable-next-line no-self-assign
                dataDriverName1 = dataDriverName1

                let fileName = dataTransportDate + '_' + dataVehicleNo + '_' + dataDriverName1;
                TableToExcel.convert(document.getElementById(idname), {
                    name: fileName + ".xlsx",
                    sheet: {
                        name: "Sheet 1"
                    },
                    pageSetup: {
                        orientation: 'landscape',
                        paperSize: 13,
                        fitToWidth: 1,
                        fitToHeight: 1,
                    }
                });

                this.props.client.writeData({
                    data: {
                        excelOutput: {
                            __typename: 'excelOutput',
                            excelOrdersOutputData: []
                        }
                    }
                });
            }, 0);

            return (<Fragment>{excels}</Fragment>);
        } else {
            return (<Fragment>{excels}</Fragment>);
        }
    }
}
const OrderExcelWrap = withApollo(OrderExcel);

export { OrderExcelWrap, ordersType };