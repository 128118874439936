import { Button, DatePicker, Form, Modal, notification, Row, Select, Typography } from 'antd';
import jaJP from 'antd/lib/date-picker/locale/ja_JP';
import _ from 'lodash';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { withApollo } from 'react-apollo';
import { OrderContext } from '../../../../../utils/context';
import adhocTranslator from '../../../../../utils/locales/translate-adhoc';
import { formatTypes } from '../../../constants';
import { GET_ORDERS_REPORT, GET_VEHICLES } from '../../../queries';

const lt = adhocTranslator('K020-010');

const ReportModal = (props) => {
  const { onCancel, visible, client, form: { getFieldDecorator, getFieldsValue } } = props;

  const { selected } = useContext(OrderContext);
  const [options, setOptions] = useState([]);

  const notify = (type, message) => {
    notification[type]({
      message,
    });
  };

  useEffect(() => {
    (async () => {
      const { data } = await client.query({
        query: GET_VEHICLES,
        fetchPolicy: 'network-only'
      });

      setOptions(data.allVehicles.nodes);
    })()
  }, []);

  const onSubmit = async () => {
    const { date, vehicle } = getFieldsValue();
    if (!date || !vehicle) {
      const message = (!date && !vehicle) ?
        `号車 と ${lt('lbl_order_date')}` :
        !date ? lt('lbl_order_date') : '号車';
      notify('error', `${message} を入力してください`);

      return;
    };

    const formatDate = moment(date).format(formatTypes.DATE_QUERY);
    const nextDate = moment(date.format(formatTypes.DATE_QUERY)).add(1, 'days').format(formatTypes.DATE_QUERY);
    const overnightDate = moment(date.format(formatTypes.DATE_QUERY)).subtract(1, 'days').format(formatTypes.DATE_QUERY);

    const { data: { allOrders: { nodes: orders } } } = await client.query({
      query: GET_ORDERS_REPORT,
      fetchPolicy: 'network-only',
      variables: {
        orderBy: ['TRANSPORT_DATE_ASC', 'DISPLAY_ORDER_ASC', 'CREATED_AT_ASC'],
        filter: {
          isDeleted: { equalTo: false },
          or: [
            { transportDate: { equalTo: formatDate }, vehicleId: { equalTo: vehicle } },
            { vehicleId: { equalTo: vehicle }, overnight: { equalTo: 'YES' }, transportDate: { equalTo: overnightDate }, transportDateOption: { equalTo: 'SPECIFIED_DAY' } },
            {
              overnight: { equalTo: 'YES' },
              vehicleId: { equalTo: vehicle },
              ordersTransportsByOrderId: {
                some: {
                  and: [
                    { pickupDate: { lessThanOrEqualTo: formatDate } },
                    { dropoffDate: { greaterThanOrEqualTo: formatDate } },
                  ]
                }
              }
            }
          ],
        },
        // arrivalsByVehicleFilter: {
        //   and: [
        //     { arrivalDatetime: { greaterThanOrEqualTo: formatDate } },
        //     { arrivalDatetime: { lessThan: nextDate } }
        //   ]
        // },
        // departureByVehicleFilter: {
        //   and: [
        //     { departuresDatetime: { greaterThanOrEqualTo: formatDate } },
        //     { departuresDatetime: { lessThan: nextDate } }
        //   ]
        // }
      }
    });

    if (!orders.length) {
      notify('error', `号車：${vehicle}、運搬日：${moment(date).format(formatTypes.DATE)}${lt('vehicle_generate_report_error')}`);
      onCancel();
      return;
    };

    await processDownload(orders, formatDate, vehicle);
  };

  const acquireOtherData = async (date, vehicle) => {
    const nextDate = moment(date).add(1, 'days').format(formatTypes.DATE_QUERY);
    const { data: { allOrders: { nodes: orders } } } = await client.query({
      query: GET_ORDERS_REPORT,
      fetchPolicy: 'network-only',
      variables: {
        orderBy: ['TRANSPORT_DATE_ASC', 'DISPLAY_ORDER_ASC', 'CREATED_AT_ASC'],
        filter: {
          isDeleted: { equalTo: false },
          transportDate: { equalTo: date },
          vehicleId: { equalTo: vehicle },
          overnight: { equalTo: 'NO' },
          transportDateOption: { equalTo: 'SPECIFIED_DAY' },
        },
        // arrivalsByVehicleFilter: {
        //   and: [
        //     { arrivalDatetime: { greaterThanOrEqualTo: date } },
        //     { arrivalDatetime: { lessThan: nextDate } }
        //   ]
        // },
        // departureByVehicleFilter: {
        //   and: [
        //     { departuresDatetime: { greaterThanOrEqualTo: date } },
        //     { departuresDatetime: { lessThan: nextDate } }
        //   ]
        // },
      }
    });

    const rows = [];
    _.each(orders, (order) => {
      if (!order.ordersTransportsByOrderId.nodes.length) return;
      const escortList = [];
      order.ordersTransportsByOrderId.nodes.forEach(transports => escortList.push(...transports.ordersEscortVehiclesByTransportId.nodes));
      const escortFare = _.sumBy(escortList, 'transportBillingAmount') || 0;
      const escortDistance = _.sumBy(escortList, 'pickupDistance') || 0;

      const arrivalData = _.first((order.arrivalsByOrderId || {}).nodes);
      const departureData = _.first((order.departuresByOrderId || {}).nodes);
      rows.push({
        id: order.id,
        vehicleId: order.vehicleId,
        driverId1: _.get(order, 'userByDriverId1.fullNameJa', ''),
        driverId: _.get(order, 'userByDriverId1.id'),
        driverId2: _.get(order, 'userByDriverId2.fullNameJa', ''),
        nameJa: _.get(order, 'customerByCustomerId.nameJa') || null,
        packagingName: _.get(order, 'packagingByPacking1.packagingName') || null,
        transportDate: order.transportDate,
        dispatchStatus: order.dispatchStatus,
        customerId: order.customerId,
        packing1: order.packing1,
        packing2: order.packing2,
        transportDistance: order.transportDistance,
        transportFare: order.transportBillingAmount,
        escortVehicleDistance: escortDistance,
        escortVehicleFare: escortFare,
        notes: order.notes,
        remarks: order.notes,
        overnight: order.overnight,
        ordersTransportsByOrderId: _.get(order, 'ordersTransportsByOrderId.nodes', []),
        arrivalsByVehicleId: arrivalData ? JSON.stringify([arrivalData]) : [],
        departuresByVehicleId: departureData ? JSON.stringify([departureData]) : [],
        vehicleNo: order.vehicleByVehicleId.vehicleNo,
        __typename: 'excelData'
      });
    });
    return rows;
  }

  const processDownload = async (orders, date, vehicle) => {
    const selectedRows = [];
    _.each(orders, (order) => {
      if (!order.ordersTransportsByOrderId.nodes.length) return;

      const escortList = [];
      order.ordersTransportsByOrderId.nodes.forEach(transports => escortList.push(...transports.ordersEscortVehiclesByTransportId.nodes));
      const escortFare = _.sumBy(escortList, 'transportBillingAmount') || 0;
      const escortDistance = _.sumBy(escortList, 'pickupDistance') || 0;

      const arrivalData = _.first((order.arrivalsByOrderId || {}).nodes);
      const departureData = _.first((order.departuresByOrderId || {}).nodes);
      selectedRows.push({
        id: order.id,
        vehicleId: order.vehicleId,
        driverId1: _.get(order, 'userByDriverId1.fullNameJa', ''),
        driverId: _.get(order, 'userByDriverId1.id'),
        driverId2: _.get(order, 'userByDriverId2.fullNameJa', ''),
        nameJa: _.get(order, 'customerByCustomerId.nameJa') || null,
        packagingName: _.get(order, 'packagingByPacking1.packagingName') || null,
        transportDate: order.transportDate,
        dispatchStatus: order.dispatchStatus,
        customerId: order.customerId,
        packing1: order.packing1,
        packing2: order.packing2,
        transportDistance: order.transportDistance,
        transportFare: order.transportBillingAmount,
        escortVehicleDistance: escortDistance,
        escortVehicleFare: escortFare,
        notes: order.notes,
        remarks: order.notes,
        overnight: order.overnight,
        ordersTransportsByOrderId: _.get(order, 'ordersTransportsByOrderId.nodes', []),
        arrivalsByVehicleId: arrivalData ? JSON.stringify([arrivalData]) : [],
        departuresByVehicleId: departureData ? JSON.stringify([departureData]) : [],
        vehicleNo: order.vehicleByVehicleId.vehicleNo,
        __typename: 'excelData'
      });
    });

    const excelId = selectedRows.length && selectedRows[0].vehicleNo;
    if (selectedRows.length) {
      const driverGroup = _.groupBy(selectedRows, 'driverId');
      const response = _.map(driverGroup, async (rowData, key) => {
        const minData = _.minBy(rowData, (data) => data.transportDate);
        const dropoffDateList = [];
        _.each(rowData, (data) => {
          _.each(data.ordersTransportsByOrderId, transport => dropoffDateList.push(transport.dropoffDate));
        });
        const maxData = _.max(dropoffDateList);

        const hasOvernight = _.some(rowData, (data) => data.overnight === 'YES');
        if (hasOvernight && minData.transportDate !== date) {
          const morningData = await acquireOtherData(minData.transportDate, vehicle);
          driverGroup[key] = [...morningData, ...driverGroup[key]];
        }

        if (hasOvernight && maxData && maxData !== date) {
          const afternoonData = await acquireOtherData(maxData, vehicle);
          driverGroup[key] = [...driverGroup[key], ...afternoonData];
        }
      });

      await Promise.all(response);

      let currentIndex = 0;
      _.forOwn(driverGroup, (rowData) => {
        setTimeout(async () => {
          await client.writeData({
            data: {
              excelOutput: {
                __typename: 'excelOutput',
                type: 'Orders',
                excelOrdersOutputData: rowData,
                excelEstimateOutputData: [],
                excelInvoiceOutputData: [],
                excelCrewPerformanceOutputData: [],
                excelDispatchOutputData: [],
                excelCustomersOutputData: []
              }
            }
          });
        }, (currentIndex * 400));
        currentIndex++;
      });
    }
    notify('success', `号車：${excelId}、運搬日：${moment(date).format(formatTypes.DATE)}${lt('vehicle_generate_report_success')}`);
    onCancel();
  }

  return (
    <Modal
      visible={visible}
      maskClosable={false}
      onCancel={onCancel}
      className='modal-vehicle'
      footer={[
        <Button key="submit" type="primary" onClick={onSubmit}>出力</Button>,
        <Button key="back" onClick={onCancel}>戻る</Button>,
      ]}
    >
      <Row>
        <Row type='flex' justify='center' style={{ marginBottom: 30 }}>
          <Typography style={{ fontWeight: 'bold' }}>日報出力</Typography>
        </Row>

        <Row type='flex' justify='center' style={{ marginBottom: 15 }}>
          <Typography>以下で指定した号車、運搬日の運転日報を出力します。</Typography>
        </Row>
        <Form>
          <Row type='flex' className='vertical-align'>
            <Typography.Text style={{ marginRight: 70, paddingTop: 5 }}>号車</Typography.Text>
            <Form.Item style={{ marginBottom: 15 }}>
              {getFieldDecorator('vehicle', {
                initialValue: selected[0] && selected[0].vehicleId
              })(
                <Select
                  style={{ width: 250 }}
                >
                  {options.map(option => (
                    <Select.Option
                      key={option.id}
                      value={option.id}
                    >
                      {option.vehicleNo}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Row>
          <Row type='flex' className='vertical-align'>
            <Form.Item
              label='運搬日'
              style={{ marginBottom: 15, display: 'flex', alignItems: 'center' }}
            >
              {getFieldDecorator('date', {
                initialValue: selected[0] && selected[0].transportDate ? moment(selected[0].transportDate) : moment(),
                rules: [{
                  required: true,
                  message: ' '
                }],
              })(
                <DatePicker
                  format={formatTypes.DATE}
                  locale={jaJP}
                  placeholder={lt('txt_ｆrom_date')}
                  showToday={false}
                  size='default'
                  style={{ width: 170, marginLeft: 50, display: 'inline-block' }}
                />
              )}
            </Form.Item>
          </Row>
        </Form>
      </Row>
    </Modal>
  )
};

export default withApollo(Form.create()(ReportModal));