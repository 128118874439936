import { Typography } from 'antd';
import React from 'react';
import adhocTranslator from '../../utils/locales/translate-adhoc';

export const tabTypes = {
  ORDERS: 'orders',
  DISPATCH: 'dispatch',
  PLANNED: 'planned',
  CONNECTED: 'connected',
  DELETED: 'deleted'
}

export const fetchTypes = {
  CURRENT: 'current',
  FUTURE: 'future'
}

export const formatTypes = {
  DATE: 'YYYY/MM/DD',
  DAY: 'DD',
  DATE_QUERY: 'YYYY-MM-DD',
  TIME: 'HH:mm'
}

export const dispatchTypes = {
  COMPLETED: '配車',
  PENDING: '未配車',
  '配車': 'COMPLETED',
  '未配車': 'PENDING',
}

let lt = adhocTranslator('K020-010');
const vehicleRender = (data, record, index) => {

  return (
    <div>
      <Typography className='orange-label'>{data}</Typography>
      {(record.escortList || []).length ?
        record.escortList.map(data => (<Typography style={{ fontSize: 14, fontWeight: 'normal' }}>{data}</Typography>)) :
        null}
    </div>
  )
};

export const columnTypes = {
  orders: (editMode, onCell, onHeaderCell, checkMinimized) => [
    {
      title: lt('lbl_date_header'), dataIndex: 'transportDisplay', key: 'transportDate', align: 'center', width: 60,
      className: 'vertical-align', onHeaderCell, ...checkMinimized('transportDate')
    },
    {
      title: lt('lbl_dispatch_status_header'), dataIndex: 'dispatch', key: 'dispatchStatus',
      align: 'center', width: 60, className: 'vertical-align', onHeaderCell, ...checkMinimized('dispatchStatus')
    },
    {
      title: lt('lbl_customer_header'), dataIndex: 'customer', key: 'customer', width: 160, align: 'center',
      className: !editMode && 'vertical-align', onCell: onCell('customer'), onHeaderCell, ...checkMinimized('customer')
    },
    {
      title: lt('lbl_point_of_origin_time_header'), dataIndex: 'originTime', key: 'originTime', align: 'center', width: 170,
      className: !editMode && 'vertical-align', onCell: onCell('originTime'), onHeaderCell, ...checkMinimized('originTime')
    },
    {
      title: lt('lbl_point_of_origin_header'), dataIndex: 'origin', key: 'origin', width: 150, align: 'center',
      className: !editMode && 'vertical-align', onCell: onCell('origin'), onHeaderCell, ...checkMinimized('origin')
    },
    {
      title: lt('lbl_destination_time_header'), dataIndex: 'destinationTime', key: 'destinationTime', align: 'center', width: 170,
      className: !editMode && 'vertical-align', onCell: onCell('destinationTime'), onHeaderCell, ...checkMinimized('destinationTime')
    },
    {
      title: lt('lbl_destination_header'), dataIndex: 'destination', key: 'destination', width: 150, align: 'center',
      className: !editMode && 'vertical-align', onCell: onCell('destination'), onHeaderCell, ...checkMinimized('destination')
    },
    {
      title: lt('lbl_packaging_header'), dataIndex: 'packaging1', key: 'packaging', width: 205, align: 'center',
      className: !editMode && 'vertical-align', onCell: onCell('packaging'), onHeaderCell, ...checkMinimized('packaging')
    },
    {
      title: lt('lbl_vehicle_no_header'), dataIndex: 'vehicle', key: 'vehicle', width: 70,
      align: 'center', className: 'vertical-align orange-label vehicle-cell', onHeaderCell, ...checkMinimized('vehicle'),
      render: vehicleRender
    },
    {
      title: lt('lbl_driver_header'), dataIndex: 'driver1', key: 'driver', width: 90,
      align: 'center', className: 'vertical-align', onHeaderCell, ...checkMinimized('driver')
    },
    {
      title: '借主', dataIndex: 'borrower', key: 'borrower', width: 160,
      align: 'center', className: 'vertical-align', onHeaderCell, ...checkMinimized('borrower'), onCell: onCell('borrower')
    },
    {
      title: lt('lbl_remarks_header'), dataIndex: 'remarks', key: 'remarks', width: 160, align: 'center',
      className: !editMode && 'vertical-align', onCell: onCell('remarks'), onHeaderCell, ...checkMinimized('remarks')
    }
  ],
  dispatch: (editMode, onCell, onHeaderCell, checkMinimized) => [
    {
      title: lt('lbl_date_header'), dataIndex: 'transportDisplay', key: 'transportDate', align: 'center', width: 60,
      className: 'vertical-align', onHeaderCell, ...checkMinimized('transportDate')
    },
    {
      title: lt('lbl_dispatch_status_header'), dataIndex: 'dispatch', key: 'dispatchStatus',
      align: 'center', width: 60, className: 'vertical-align', onHeaderCell, ...checkMinimized('dispatchStatus')
    },
    {
      title: lt('lbl_customer_header'), dataIndex: 'customer', key: 'customer', width: 160,
      align: 'center', className: 'vertical-align', onCell: onCell('customer'), onHeaderCell, ...checkMinimized('customer')
    },
    {
      title: <><Typography style={{ color: '#FFFFFF' }}>発地予定</Typography><Typography style={{ color: '#FFFFFF' }}>（発地実績）</Typography></>,
      dataIndex: 'originTime', key: 'originTime', align: 'center', width: 110,
      className: 'vertical-align', onCell: onCell('originTime'), onHeaderCell, ...checkMinimized('originTime')
    },
    {
      title: lt('lbl_point_of_origin_header'), dataIndex: 'origin', key: 'origin', width: 160,
      align: 'center', className: 'vertical-align', onCell: onCell('origin'), onHeaderCell, ...checkMinimized('origin')
    },
    {
      title: <><Typography style={{ color: '#FFFFFF' }}>着地予定</Typography><Typography style={{ color: '#FFFFFF' }}>（着地実績）</Typography></>,
      dataIndex: 'destinationTime', key: 'destinationTime', align: 'center', width: 170,
      className: 'vertical-align', onCell: onCell('destinationTime'), onHeaderCell, ...checkMinimized('destinationTime')
    },
    {
      title: lt('lbl_destination_header'), dataIndex: 'destination', key: 'destination', width: 160,
      align: 'center', className: 'vertical-align', onCell: onCell('destination'), onHeaderCell, ...checkMinimized('destination')
    },
    {
      title: lt('lbl_packaging_header'), dataIndex: 'packaging1', key: 'packaging', width: 160,
      align: 'center', className: 'vertical-align', onCell: onCell('packaging'), onHeaderCell, ...checkMinimized('packaging')
    },
    {
      title: lt('lbl_vehicle_no_header'), dataIndex: 'vehicle', key: 'vehicle', width: 70,
      align: 'center', className: 'vertical-align orange-label', onHeaderCell, ...checkMinimized('vehicle'),
      render: vehicleRender
    },
    {
      title: '距離', dataIndex: 'distance', key: 'distance', width: 70,
      align: 'right', className: 'vertical-align amount', onHeaderCell, ...checkMinimized('distance'),
    },
    {
      title: '運賃', dataIndex: 'billingAmount', key: 'billingAmount', width: 80,
      align: 'right', className: 'vertical-align amount', onHeaderCell, ...checkMinimized('billingAmount'),
      onCell: onCell('billingAmount'),
    },
    {
      title: '支払額', dataIndex: 'paymentAmount', key: 'paymentAmount', width: 80,
      align: 'right', className: 'vertical-align amount', onHeaderCell, ...checkMinimized('paymentAmount'),
    },
    {
      title: '借主', dataIndex: 'borrower', key: 'borrower', width: 160,
      align: 'center', className: 'vertical-align', onHeaderCell, ...checkMinimized('borrower'), onCell: onCell('borrower')
    },
    {
      title: '担当者/連絡先', dataIndex: 'assignee', key: 'assignee', width: 120,
      align: 'center', className: 'vertical-align', onHeaderCell, ...checkMinimized('assignee'), onCell: onCell('assignee')
    },
    // {
    //   title: '連絡先', dataIndex: 'contact', key: 'contact', width: 120,
    //   align: 'center', className: 'vertical-align', onHeaderCell, ...checkMinimized('contact'), onCell: onCell('contact')
    // },
    {
      title: lt('lbl_remarks_header'), dataIndex: 'remarks', key: 'remarks', width: 160, align: 'center',
      className: 'vertical-align', onHeaderCell, ...checkMinimized('remarks')
    }
  ],
  connected: (editMode, onCell, onHeaderCell, checkMinimized) => [
    {
      title: lt('lbl_date_header'), dataIndex: 'transportDisplay', key: 'transportDate', align: 'center', width: 60,
      className: 'vertical-align', onHeaderCell, ...checkMinimized('transportDate')
    },
    {
      title: lt('lbl_dispatch_status_header'), dataIndex: 'dispatch', key: 'dispatchStatus',
      align: 'center', width: 60, className: 'vertical-align', onHeaderCell, ...checkMinimized('dispatchStatus')
    },
    {
      title: '連携番号', dataIndex: 'connectionId', key: 'connectionId',
      align: 'center', width: 100, className: 'vertical-align', onHeaderCell, ...checkMinimized('connectionId')
    },
    {
      title: lt('lbl_customer_header'), dataIndex: 'customer', key: 'customer', width: 160, align: 'center',
      className: 'vertical-align', onCell: onCell('customer'), onHeaderCell, ...checkMinimized('customer')
    },
    {
      title: <><Typography style={{ color: '#FFFFFF' }}>発地予定</Typography><Typography style={{ color: '#FFFFFF' }}>（発地実績）</Typography></>,
      dataIndex: 'originTime', key: 'originTime', align: 'center', width: 110,
      className: 'vertical-align', onCell: onCell('originTime'), onHeaderCell, ...checkMinimized('originTime')
    },
    {
      title: lt('lbl_point_of_origin_header'), dataIndex: 'origin', key: 'origin', width: 160, align: 'center',
      className: 'vertical-align', onCell: onCell('origin'), onHeaderCell, ...checkMinimized('origin')
    },
    {
      title: <><Typography style={{ color: '#FFFFFF' }}>着地予定</Typography><Typography style={{ color: '#FFFFFF' }}>（着地実績）</Typography></>,
      dataIndex: 'destinationTime', key: 'destinationTime', align: 'center', width: 170,
      className: 'vertical-align', onCell: onCell('destinationTime'), onHeaderCell, ...checkMinimized('destinationTime')
    },
    {
      title: lt('lbl_destination_header'), dataIndex: 'destination', key: 'destination', width: 160, align: 'center',
      className: 'vertical-align', onCell: onCell('destination'), onHeaderCell, ...checkMinimized('destination')
    },
    {
      title: lt('lbl_packaging_header'), dataIndex: 'packaging1', key: 'packaging', width: 160, align: 'center',
      className: 'vertical-align', onCell: onCell('packaging'), onHeaderCell, ...checkMinimized('packaging')
    },
    {
      title: lt('lbl_vehicle_no_header'), dataIndex: 'vehicle', key: 'vehicle', width: 70,
      align: 'center', className: 'vertical-align orange-label', onHeaderCell, ...checkMinimized('vehicle'),
      render: vehicleRender
    },
    {
      title: '距離', dataIndex: 'distance', key: 'distance', width: 70,
      align: 'right', className: 'vertical-align amount', onHeaderCell, ...checkMinimized('distance'),
    },
    {
      title: '請求額', dataIndex: 'billingAmount', key: 'billingAmount', width: 80,
      align: 'right', className: 'vertical-align amount', onHeaderCell, ...checkMinimized('billingAmount'),
      onCell: onCell('billingAmount'),
    },
    {
      title: '支払額', dataIndex: 'paymentAmount', key: 'paymentAmount', width: 80,
      align: 'right', className: 'vertical-align amount', onHeaderCell, ...checkMinimized('paymentAmount'),
    },
    {
      title: '借主', dataIndex: 'borrower', key: 'borrower', width: 160,
      align: 'center', className: 'vertical-align', onHeaderCell, ...checkMinimized('borrower'), onCell: onCell('borrower')
    },
    {
      title: '担当者/連絡先', dataIndex: 'assignee', key: 'assignee', width: 120,
      align: 'center', className: 'vertical-align', onHeaderCell, ...checkMinimized('assignee'), onCell: onCell('assignee')
    },
    // {
    //   title: '連絡先', dataIndex: 'contact', key: 'contact', width: 120,
    //   align: 'center', className: 'vertical-align', onHeaderCell, ...checkMinimized('contact'), onCell: onCell('contact')
    // },
    {
      title: lt('lbl_remarks_header'), dataIndex: 'remarks', key: 'remarks', width: 160, align: 'center',
      className: 'vertical-align', onHeaderCell, ...checkMinimized('remarks')
    }
  ],
  planned: (editMode, onCell, onHeaderCell, checkMinimized) => [
    {
      title: lt('lbl_date_header'), dataIndex: 'transportDisplay', key: 'transportDate', align: 'center', width: 60,
      className: 'vertical-align', onHeaderCell, ...checkMinimized('transportDate')
    },
    {
      title: lt('lbl_dispatch_status_header'), dataIndex: 'dispatch', key: 'dispatchStatus',
      align: 'center', width: 60, className: 'vertical-align', onHeaderCell, ...checkMinimized('dispatchStatus')
    },
    {
      title: lt('lbl_customer_header'), dataIndex: 'customer', key: 'customer', width: 160, align: 'center',
      className: 'vertical-align', onCell: onCell('customer'), onHeaderCell, ...checkMinimized('customer')
    },
    {
      title: lt('lbl_point_of_origin_time_header'), dataIndex: 'originTime', key: 'originTime', align: 'center', width: 110,
      className: 'vertical-align', onCell: onCell('originTime'), onHeaderCell, ...checkMinimized('originTime')
    },
    {
      title: lt('lbl_point_of_origin_header'), dataIndex: 'origin', key: 'origin', width: 160, align: 'center',
      className: 'vertical-align', onCell: onCell('origin'), onHeaderCell, ...checkMinimized('origin')
    },
    {
      title: lt('lbl_destination_time_header'), dataIndex: 'destinationTime', key: 'destinationTime', align: 'center', width: 170,
      className: 'vertical-align', onCell: onCell('destinationTime'), onHeaderCell, ...checkMinimized('destinationTime')
    },
    {
      title: lt('lbl_destination_header'), dataIndex: 'destination', key: 'destination', width: 160, align: 'center',
      className: 'vertical-align', onCell: onCell('destination'), onHeaderCell, ...checkMinimized('destination')
    },
    {
      title: lt('lbl_packaging_header'), dataIndex: 'packaging1', key: 'packaging', width: 160, align: 'center',
      className: 'vertical-align', onCell: onCell('packaging'), onHeaderCell, ...checkMinimized('packaging')
    },
    {
      title: lt('lbl_vehicle_no_header'), dataIndex: 'vehicle', key: 'vehicle', width: 70,
      align: 'center', className: 'vertical-align orange-label', onHeaderCell, ...checkMinimized('vehicle'),
      render: vehicleRender
    },
    {
      title: lt('lbl_remarks_header'), dataIndex: 'remarks', key: 'remarks', width: 160, align: 'center',
      className: !editMode && 'vertical-align', onCell: onCell('remarks'), onHeaderCell, ...checkMinimized('remarks')
    }
  ],
  driver_dispatch: (editMode, onCell, onHeaderCell, checkMinimized) => [
    {
      title: lt('lbl_date_header'), dataIndex: 'transportDisplay', key: 'transportDate', align: 'center', width: 60,
      className: 'vertical-align', onHeaderCell, ...checkMinimized('transportDate')
    },
    {
      title: lt('lbl_customer_header'), dataIndex: 'customer', key: 'customer', width: 160, align: 'center',
      className: 'vertical-align', onCell: onCell('customer'), onHeaderCell, ...checkMinimized('customer')
    },
    {
      title: lt('lbl_point_of_origin_header'), dataIndex: 'origin', key: 'origin', width: 160, align: 'center',
      className: 'vertical-align', onCell: onCell('origin'), onHeaderCell, ...checkMinimized('origin')
    },
    {
      title: lt('lbl_destination_header'), dataIndex: 'destination', key: 'destination', width: 160, align: 'center',
      className: 'vertical-align', onCell: onCell('destination'), onHeaderCell, ...checkMinimized('destination')
    }
  ],
  driver_planned: (editMode, onCell, onHeaderCell, checkMinimized) => [
    {
      title: lt('lbl_date_header'), dataIndex: 'transportDisplay', key: 'transportDate', align: 'center', width: 60,
      className: 'vertical-align', onHeaderCell, ...checkMinimized('transportDate')
    },
    {
      title: lt('lbl_customer_header'), dataIndex: 'customer', key: 'customer', width: 160, align: 'center',
      className: 'vertical-align', onCell: onCell('customer'), onHeaderCell, ...checkMinimized('customer')
    },
    {
      title: lt('lbl_point_of_origin_header'), dataIndex: 'origin', key: 'origin', width: 160, align: 'center',
      className: 'vertical-align', onCell: onCell('origin'), onHeaderCell, ...checkMinimized('origin')
    },
    {
      title: lt('lbl_destination_header'), dataIndex: 'destination', key: 'destination', width: 160, align: 'center',
      className: 'vertical-align', onCell: onCell('destination'), onHeaderCell, ...checkMinimized('destination')
    }
  ],
  deleted: (editMode, onCell, onHeaderCell, checkMinimized) => [
    {
      title: '削除日時', dataIndex: 'deletedAt', key: 'deletedAt', align: 'center', width: 120,
      className: 'vertical-align', onHeaderCell, ...checkMinimized('deletedAt')
    },
    {
      title: '削除ユーザー', dataIndex: 'deletedBy', key: 'deletedBy', align: 'center', width: 120,
      className: 'vertical-align', onHeaderCell, ...checkMinimized('deletedBy')
    },
    {
      title: lt('lbl_date_header'), dataIndex: 'transportDisplay', key: 'transportDate', align: 'center', width: 60,
      className: 'vertical-align', onHeaderCell, ...checkMinimized('transportDate')
    },
    {
      title: lt('lbl_dispatch_status_header'), dataIndex: 'dispatch', key: 'dispatchStatus',
      align: 'center', width: 60, className: 'vertical-align', onHeaderCell, ...checkMinimized('dispatchStatus')
    },
    {
      title: lt('lbl_customer_header'), dataIndex: 'customer', key: 'customer', width: 160, align: 'center',
      className: !editMode && 'vertical-align', onCell: onCell('customer'), onHeaderCell, ...checkMinimized('customer')
    },
    {
      title: lt('lbl_point_of_origin_time_header'), dataIndex: 'originTime', key: 'originTime', align: 'center', width: 170,
      className: !editMode && 'vertical-align', onCell: onCell('originTime'), onHeaderCell, ...checkMinimized('originTime')
    },
    {
      title: lt('lbl_point_of_origin_header'), dataIndex: 'origin', key: 'origin', width: 150, align: 'center',
      className: !editMode && 'vertical-align', onCell: onCell('origin'), onHeaderCell, ...checkMinimized('origin')
    },
    {
      title: lt('lbl_destination_time_header'), dataIndex: 'destinationTime', key: 'destinationTime', align: 'center', width: 170,
      className: !editMode && 'vertical-align', onCell: onCell('destinationTime'), onHeaderCell, ...checkMinimized('destinationTime')
    },
    {
      title: lt('lbl_destination_header'), dataIndex: 'destination', key: 'destination', width: 150, align: 'center',
      className: !editMode && 'vertical-align', onCell: onCell('destination'), onHeaderCell, ...checkMinimized('destination')
    },
    {
      title: lt('lbl_packaging_header'), dataIndex: 'packaging1', key: 'packaging', width: 205, align: 'center',
      className: !editMode && 'vertical-align', onCell: onCell('packaging'), onHeaderCell, ...checkMinimized('packaging')
    },
    {
      title: lt('lbl_vehicle_no_header'), dataIndex: 'vehicle', key: 'vehicle', width: 70,
      align: 'center', className: 'vertical-align orange-label vehicle-cell', onHeaderCell, ...checkMinimized('vehicle'),
      render: vehicleRender
    },
    {
      title: lt('lbl_driver_header'), dataIndex: 'driver1', key: 'driver', width: 90,
      align: 'center', className: 'vertical-align', onHeaderCell, ...checkMinimized('driver')
    },
    {
      title: '借主', dataIndex: 'borrower', key: 'borrower', width: 160,
      align: 'center', className: 'vertical-align', onHeaderCell, ...checkMinimized('borrower'), onCell: onCell('borrower')
    },
    {
      title: lt('lbl_remarks_header'), dataIndex: 'remarks', key: 'remarks', width: 160, align: 'center',
      className: !editMode && 'vertical-align', onCell: onCell('remarks'), onHeaderCell, ...checkMinimized('remarks')
    }
  ],
};

export const schedTypes = [
  { key: 0, value: 'BLANK', label: '指定なし' },
  { key: 1, value: 'AM', label: 'AM' },
  { key: 2, value: 'PM', label: 'PM' },
  { key: 3, value: 'DAY_TIME', label: '日中' },
  { key: 4, value: 'EVENING', label: '夕方' },
  { key: 5, value: 'SPECIFIED_TIME', label: '時間指定' }
]

export const maxTypes = {
  packaging: 2,
  transports: 3,
  remarks: 3
}