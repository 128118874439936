import React, {Component} from 'react';
import {Menu, Icon, Layout} from 'antd';
import {withRouter} from "react-router";
import {NavLink} from "react-router-dom";
import styles from '../assets/scss/sidebar.module.scss';

const SubMenu = Menu.SubMenu;

class Sidebar extends Component {

    state = {
        collapsed: false
    };

    // Get current menu
    getCurrentMenu = (key) => {
        let selectedMenu = key;
        let selectedKeys = [selectedMenu];
        let getLevels = selectedMenu.split('-');
        let openKeys = [];
        getLevels.pop();
        for (let i = 0; i <= getLevels.length; ++i) {
            openKeys.unshift(getLevels.join('-'));
            getLevels.pop();
        }
        this.selectedKeys = selectedKeys;
        this.openKeys = openKeys;
    };

    //Remove search data
    removeSearchDate = () => {
        localStorage.removeItem('search_date');
    };


    // Build menu item
    buildMenuItem = (menus, mainKey) => {
        return menus.map((menu, key) => {
            if (menu.hidden) {
                return null;
            }
            /** If there is path in the menu **/
            if (menu.path) {
                if (menu.path === this.props.location.pathname) {
                    this.getCurrentMenu(`${mainKey}-${key}`);
                }
                return <Menu.Item key={`${mainKey}-${key}`}onClick={this.removeSearchDate}>
                    <NavLink to={menu.path}>
                        {menu.icon && <Icon type={menu.icon}/>}
                        <span>{menu.name}</span>
                    </NavLink>
                </Menu.Item>
            }
            return this.buildSubmenu(menu, `${mainKey}-${key}`);
        }).filter((menu) => !!menu);
    };

    // Build Submenu
    buildSubmenu = (menu, key) => {
        return <SubMenu
            key={key}
            title={
                <span>
                    <Icon type={menu.icon}/><span>{menu.name}</span>
                </span>
            }>
            {this.buildMenuItem(menu.components, key)}
        </SubMenu>
    };

    // Build menu
    buildMenu = () => {
        let menus = [];
        this.props.routes.forEach((menu, key) => {
            menus.push(this.buildSubmenu(menu, key));
        });
        return menus;
    };


    render() {
        let menus = this.buildMenu();
        return (
            <Layout.Sider
                width={285}
                trigger={null}
                breakpoint="lg"
                collapsible
                collapsed={this.props.collapsed}>
                <div className={styles.logo}>
                　　<h3 className="bp3-heading white hid-sm">有限会社黒潮重機興業</h3>
                    <h3 className="bp3-heading white">KRSION</h3>
                </div>
                <div className={styles.menuContainer}>
                    <Menu
                        defaultSelectedKeys={this.selectedKeys} //accept string
                        defaultOpenKeys={this.openKeys}
                        mode="inline"
                        theme="dark"
                    >
                        {menus}
                    </Menu>
                </div>
            </Layout.Sider>
        )
    }
}

export default withRouter(Sidebar)
