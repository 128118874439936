import React, {Component, Fragment} from 'react';
import {Button} from "antd";
import adhocTranslator from "../../utils/locales/translate-adhoc";

class CustomFilter extends Component {

    constructor(props){
			super(props);
			this.props.buttonType.type11 = true
    }

	render() {
		let lt = adhocTranslator('K030-021');
		let {
			setFilter,
			buttonType
		} = this.props;
		return (
			<Fragment>
				<div className='ant-row-flex-space-around ant-row-flex-middle ant-row ant-form-item smallButton'>
					<Button id={1} type={buttonType.type1 ? 'primary' : 'default' } onClick={(e)=>{setFilter(e,'ア')}}>{lt('btn_a_column')} </Button>
					<Button id={2} type={buttonType.type2 ? 'primary' : 'default'} onClick={(e)=>{setFilter(e,'カ')}}>{lt('btn_k_column')} </Button>
					<Button id={3} type={buttonType.type3 ? 'primary' : 'default'} onClick={(e)=>{setFilter(e,'サ')}}>{lt('btn_s_column')} </Button>
					<Button id={4} type={buttonType.type4 ? 'primary' : 'default'} onClick={(e)=>{setFilter(e,'タ')}}>{lt('btn_t_column')} </Button>
					<Button id={5} type={buttonType.type5 ? 'primary' : 'default'} onClick={(e)=>{setFilter(e,'ナ')}}>{lt('btn_n_column')} </Button>
					<Button id={6} type={buttonType.type6 ? 'primary' : 'default'} onClick={(e)=>{setFilter(e,'ハ')}}>{lt('btn_h_column')} </Button>
					<Button id={7} type={buttonType.type7 ? 'primary' : 'default'} onClick={(e)=>{setFilter(e,'マ')}}>{lt('btn_m_column')} </Button>
					<Button id={8} type={buttonType.type8 ? 'primary' : 'default'} onClick={(e)=>{setFilter(e,'ヤ')}}>{lt('btn_y_column')} </Button>
					<Button id={9} type={buttonType.type9 ? 'primary' : 'default'} onClick={(e)=>{setFilter(e,'ラ')}}>{lt('btn_l_column')} </Button>
					<Button id={10} type={buttonType.type10 ? 'primary' : 'default'} onClick={(e)=>{setFilter(e,'ワ')}}>{lt('btn_w_column')} </Button>
					<Button id={11} type={buttonType.type11 ? 'primary' : 'default'} onClick={(e)=>{setFilter(e,'')}}>{lt('btn_all_button')} </Button>
				</div>
			</Fragment>
		);
	}
}

export default CustomFilter;