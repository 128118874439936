import {gql} from 'apollo-boost';

const getAll = gql`
query allLeaves($first: Int, $offset: Int, $filter: LeafFilter) {
  allLeaves(first:$first,offset:$offset,filter:$filter,orderBy:[LEAVE_DATE_ASC,USER_BY_USER_ID__FULL_NAME_JA_ASC,LEAVE_CATEGORY_ASC]){
    nodes {
        id,
        userId,
        leaveDate,
        leaveCategory,
        reason,
        userByUserId {
          vehicleNumber,
          id,
          fullNameJa,
          fullNameJaKatakana,
        },
    },
    totalCount
  }
}`;

const create = gql`
  mutation createLeaf (
    $userId: Int!,
    $leaveDate: Date!,
    $leaveCategory: LeaveOption!,
    $reason: String,
    $updatedUserId : Int!,
    $createdUserId : Int!
  ){
    createLeaf(input:{
        leaf : {
            userId: $userId,
            leaveDate: $leaveDate,
            leaveCategory: $leaveCategory,
            reason: $reason,
            createdUserId : $createdUserId,
            updatedUserId : $updatedUserId
        }
	}){
        leaf {
            userId
        }
    }
  }
`;

const getById = gql`
query getLeaves ($id:Int!){
    leafById (id : $id) {
        id,
        userId,
        leaveDate,
        leaveCategory,
        reason,
        userByUserId {
            id,
            fullNameJa,
            fullNameJaKatakana
            vehicleNumber
        },
    }
}`;


const updateById = gql`
mutation updateLeaf(
    $id: Int!,
    $userId: Int!,
    $leaveDate: Date!,
    $leaveCategory: LeaveOption!,
    $reason: String,
    $updatedUserId : Int,
    $createdUserId : Int
  ){
    updateLeafById(input:{
        id : $id,
        leafPatch : {
            id: $id,
            userId: $userId,
            leaveDate: $leaveDate,
            leaveCategory: $leaveCategory,
            reason: $reason,
            createdUserId : $createdUserId,
            updatedUserId : $updatedUserId
        }
	}){
        leaf {
            userId,
            leaveDate,
            leaveCategory
        }
    }
  }
`;

const deleteById = gql`
mutation deleteLeafById($id:Int!){
    deleteLeafById (input:{id:$id}){
        deletedLeafId
  }
}
`;

export {getAll, create, getById, updateById, deleteById};