import React from 'react'
import { Button } from 'antd'
import KeyboardEventHandler from 'react-keyboard-event-handler';

/**
 * Search button with enter button event
 * @param {*} props 
 */
const WithEventButton = props => {
	const { label, loading, style, event } = props

	return (
		<>
			{event && <KeyboardEventHandler
					handleKeys={['enter']}
					onKeyEvent={(key, e) => {
						event(e)
					}}
			/>}
			<Button
				type="primary"
				htmlType="submit"
				loading={loading}
				style={style}
			>
				{label}
			</Button>
		</>
	)
}

export default WithEventButton