import React, { Component } from 'react';

import { CustomInputNumber } from '../../commons/CustomDataEntry';

class DebouncedInput extends Component {
    constructor(props) {
        super(props);
        this.onChange = props.onChange
    }

    render() {
        return (
            <CustomInputNumber 
                {...this.props} 
                onChange={this.onChange}
            />
        );
    }
}

export default DebouncedInput



