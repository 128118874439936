import React from 'react';
import { Col, Row } from 'antd';
import withTranslation from '../utils/locales/translator';
import exclamation from '../assets/img/exclamation.png'

export default withTranslation((props) => (
	<Row type='flex' align='middle'>
		<Col span={24}  style={{textAlign:'center', color:'black'}}>
			<p style={{backgroundColor:'#1a76d2', color:'white', padding: '20px 20px 20px 20px', margin:'20px 20px 20px 20px', textAlign:'center',fontSize:30}}>
				404 Page Not Found
			</p>
			<p style={{margin:'20px 20px 20px 20px', padding: '20px 20px 20px 20px'}}>
				<img src={exclamation} style={{height: '16vh'}} alt="not found"/>
			</p>
			<p style={{margin:'20px 20px 20px 20px', fontSize:20}}>
				お探しのページは表示できませんでした。
			</p>
			<p style={{margin:'20px 20px 20px 20px', fontSize:16}}>
				指定されたページは、削除されたか、他の場所に移動した可能性があります。
			</p>
		</Col>
	</Row>
), 'K000-020')