import { Row, Typography, Spin } from 'antd';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { withApollo } from 'react-apollo';
import TabGuard from 'react-tabguard';
import '../../assets/css/orders.css';
import { OrderContext } from '../../utils/context';
import AlertsNotification from '../Alerts/Alerts';
import OrdersModal from '../OrdersAction';
import { modeTypes } from '../OrdersAction/constants';
import ActionView from './components/actions';
import DateView from './components/date';
import SearchView from './components/search';
import TableView from './components/table';
import TabView from './components/tabs';
import { formatTypes, tabTypes, fetchTypes } from './constants';
import { GET_OPTIONS, GET_LEAVES, GET_ORDER_TRANSPORTS_COLORS } from './queries';

const OrderScreen = (props) => {
  const { client, isDriver = false } = props;
  const [dataState, setDataState] = useState({
    orderList: null,
    customerList: [],
    packagingList: [],
    vacationList: [],
    incomingList: [],
    driverList: [],
    vehicleList: null,
  });

  const [state, setState] = useState({
    sort: null,
    search: null,
    date: null,

    editMode: false,
    editSave: false,
    editId: null,

    selected: [],
    view: isDriver ? tabTypes.DISPATCH : tabTypes.ORDERS,
    fetch: fetchTypes.CURRENT,
    refetch: false,
    overlay: false,

    modal: {
      visible: false,
      id: null,
      mode: null
    },

    // check if logged in user is driver
    isDriver,
    pageLoading: true,
    transportColorList: {
      origin: [],
      destination: []
    }
  });

  const stateSetter = (value) => setState(state => ({ ...state, ...value }));
  const modalSetter = (value) => setState(state => ({ ...state, modal: { ...state.modal, ...value } }));
  const dataSetter = (value) => setDataState(state => ({ ...state, ...value }));
  const colorSetter = (value) => setState(state => ({ ...state, transportColorList: { ...state.transportColorList, ...value } }))

  const onSubmit = (success) => {
    // refetch here
    if (success) {
      stateSetter({ refetch: true });
    }
    modalSetter({ visible: false, id: null, mode: null, withdrawDate: null })
  }

  const getTransportColorList = async () => {
    const {
      data: {
        allOriginOrdersTransportsColors,
        allDestinationOrdersTransportsColors
      }
    } = await client.query({
      fetchPolicy: 'network-only',
      query: GET_ORDER_TRANSPORTS_COLORS
    })

    colorSetter({
      origin: (allOriginOrdersTransportsColors || {}).nodes,
      destination: (allDestinationOrdersTransportsColors || {}).nodes
    })
  }

  useEffect(() => {
    (async () => {
      await getTransportColorList();

      // packaging, customer, transports
      const { data } = await client.query({
        query: GET_OPTIONS,
        fetchPolicy: 'network-only',
        variables: {
          filter: { leaveDate: { equalTo: moment().format(formatTypes.DATE_QUERY) } }
        }
      });
      const { allCustomers, allPackagings, allVehicles, allUsers } = data;

      const customerList = {}, packagingList = {}, transportList = {}, vehicleList = allVehicles.nodes ? allVehicles.nodes : [];
      _.each(allCustomers.nodes, data => customerList[data.nameJa] = data.id);
      _.each(allPackagings.nodes, data => packagingList[data.packagingName] = data.id);
      dataSetter({ vehicleList, customerList, packagingList, transportList, driverList: allUsers.nodes });
      await acquireVacation(state.date);
    })();
  }, []);
  const acquireVacation = async (date) => {
    if (!date) return;
    const { data } = await client.query({
      query: GET_LEAVES,
      fetchPolicy: 'network-only',
      variables: {
        filter: { leaveDate: { equalTo: date.format(formatTypes.DATE_QUERY) } }
      }
    });
    const { allLeaves } = data;
    dataSetter({ vacationList: allLeaves.nodes.map(vacation => vacation.userByUserId.fullNameJa) })
  }

  useEffect(() => {
    if (state.date) {
      (async () => {
        await acquireVacation(state.date);
      })();
    }
  }, [state.date]);

  return (
    <TabGuard>
      <OrderContext.Provider value={{ ...state, ...dataState, orderSetter: stateSetter, dataSetter, modalSetter }}>
        {dataState.vacationList.length ? (
          <Row style={{ backgroundColor: '#E84855', padding: 5, marginBottom: 5 }}>
            <Typography style={{ color: '#FFFFFF' }}>【休暇】 {dataState.vacationList.join('、')}</Typography>
          </Row>
        ) : null}
        <Spin spinning={state.pageLoading} size="large">
          <AlertsNotification />
          <Row style={{ padding: 10 }}>
            <Row type='flex' style={styles.search}>
              <SearchView getTransportColorList={getTransportColorList} />
              {!isDriver && <ActionView />}
            </Row>
            <Row type='flex' style={styles.tabs}>
              <TabView />
              <DateView />
            </Row>
            <Row>
              <TableView />
            </Row>
          </Row>
          {state.modal.visible &&
            <OrdersModal
              onOk={onSubmit}
              {...state.modal}
              date={!state.modal.mode ? state.date : null}
              isDriver={isDriver}
              onCancel={() => {
                const selected = state.selected.filter(data => data.id !== state.modal.id);
                stateSetter({ selected });
                modalSetter({ visible: false, id: null, mode: null })
              }}
              handleRefetch={() => stateSetter({ refetch: true })}
            />
          }
        </Spin>
      </OrderContext.Provider>
    </TabGuard>
  )
};

const styles = {
  search: { flex: 1, flexDirection: 'row' },
  tabs: { flex: 1, flexDirection: 'row', marginTop: 20 }
};

export default withApollo(OrderScreen);