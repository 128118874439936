import React, {Component} from 'react';
import {Input, Form} from 'antd';
const {TextArea} = Input;

class CustomTextarea extends Component {
    render() {
        let {
            placeholder,
            type,
            label,
            width = 24,
            offset = 0,
            half = false,
            oneFourth = false,
            small = false,
            form: { getFieldDecorator },
            fieldName,
            decorator,
            mode
        } = this.props;
        const formItemLayout = {
            labelCol: {  //for label width
                xs: {span: 24},
                sm: {span: 8},
                md: {span: 5},
                lg: {span: 4},
            },
            wrapperCol: { //for input width
                xs: {span: 24},
                sm: {span: 24},
                md: {span: width, offset},
            },
        };
        let mainStyle = {display: 'inline-block'};
        if (half) {
            mainStyle.width = '50%';
        } else if (oneFourth) {
            mainStyle.width = '25%';
        } else if (small) {
            mainStyle.width = '15%';
        } else mainStyle = null;

        return (
            <Form.Item
                {...formItemLayout}
                label={label}
                style={mainStyle}
            >
                {getFieldDecorator(fieldName, decorator)(
                    <TextArea rows={3} disabled={mode === 'delete' ? true : false} type={type}
                              placeholder={placeholder}/>
                )}
            </Form.Item>
        )
    }
}

export default CustomTextarea;
