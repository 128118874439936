import React, { useContext, useState, useEffect } from 'react';
import { Form, Row, Col, Button, Table, Modal, notification } from 'antd';
import _ from 'lodash';
import moment from 'moment';
import 'moment/locale/ja';
import uuid from 'uuid/v4';

import Year from '../../../commons/Picklist/Year';
import Month from '../../../commons/Picklist/Month';
import { CustomRadioGroup, CustomHiddenInput } from "../../../commons/CustomDataEntry";
import { Context } from '../../../commons/Context';
import { getAll as getVehicles } from "../../../queries/MasterMaintenance/vehicle";
import { GET_VEHICLE_ANALYSIS_REPORTING } from "../../Orders/queries";
import WithEventButton from '../../../commons/WithEventButton';

notification.config({
    duration: 8
});
moment.locale('ja');

const Filter = ({ lt, form }) => {
    const [table, handleTable] = useState([])
    const [selectedRowKeys, handleSelectedRowKeys] = useState([])
    const { dispatch, client } = useContext(Context);

    const openNotificationWithIcon = (type, message) => {
        notification[type]({ message });
    };

    const search = (e) => {

        e.preventDefault();
        form.validateFields(async (err, values) => {
            if (!err) {
                if (values.vehicleId) {
                    Modal.confirm({
                        title: '確認',
                        icon: 'false',
                        content: '号車別状況の分析を開始しますか？',
                        okText: 'はい',
                        cancelText: 'いいえ',
                        onOk: () => {
                            dispatch({
                                type: 'filters',
                                filters: { values }
                            });
                            dispatch({
                                type: 'spinner',
                                spinner: true
                            });
                            let date1 = moment(`${values.transportDate_From_Year}-${values.transportDate_From_Month}-01`).format("YYYY-MM-01");
                            let date2 = moment(`${values.transportDate_To_Year}-${values.transportDate_To_Month}-01`).add(1, "months").format("YYYY-MM-01");
                            vehicleSale(values, date1, date2);
                        }
                    });
                } else {
                    openNotificationWithIcon('error', lt('lbl_error_no_car'));
                }
            }
        });
    };

    useEffect(() => {

        (async () => {
            const { data: vehicles } = await client.query({
                query: getVehicles,
                fetchPolicy: 'network-only'
            });
            let allVehicles = [];
            await vehicles.allVehicles.nodes.forEach(vehicles => {
                allVehicles.push({
                    ...vehicles,
                    key: uuid()
                })
                return allVehicles
            });
            //get all vehicles on load
            handleTable(allVehicles);

        })();

    }, []);

    let vehicleSale = async (values, date1, date2) => {
        // let mapIds = selectedRowKeys.map(vehicle => { 
        //     return {vehicleId: { equalTo: vehicle.id } }}
        // );
        if (values && date1 && date2) {
            openNotificationWithIcon('success', lt('lbl_success_search'));
        }
        const { data: orders } = await client.query({
            query: GET_VEHICLE_ANALYSIS_REPORTING,
            fetchPolicy: 'network-only',
            variables: {
                filter: {
                    vehicleId: { equalTo: values.vehicleId },
                    dispatchStatus: {
                        equalTo: "COMPLETED"
                    },
                    and: [
                        {
                            transportDate: {
                                greaterThanOrEqualTo: date1
                            }
                        },
                        {
                            transportDate: {
                                lessThan: date2
                            }
                        },
                    ]
                },
                arrivalsByVehicleFilter: {
                    vehicleId: { equalTo: values.vehicleId },
                    and: [
                        {
                            workingDay: {
                                greaterThanOrEqualTo: date1
                            }
                        },
                        {
                            workingDay: {
                                lessThan: date2
                            }
                        },
                    ]
                },
                departureByVehicleFilter: {
                    vehicleId: { equalTo: values.vehicleId },
                    and: [
                        {
                            workingDay: {
                                greaterThanOrEqualTo: date1
                            }
                        },
                        {
                            workingDay: {
                                lessThan: date2
                            }
                        },
                    ]
                }
            }
        });
        const yearOrMonth = values.radio_customer === 1 ? 'year' : 'month';
        const format = yearOrMonth === 'year' ? 'YYYY' : 'YYYY-MM';

        const dataByDateOrdersDispatch = _.chain(orders.allOrders.nodes).map(order => {
            return {
                date: moment(order.transportDate).startOf(yearOrMonth).format(format),
                ...order
            }
        }).groupBy('date').value();

        const ordersByMonthKeys = Object.keys(dataByDateOrdersDispatch);
        const arrivals = {};
        const departures = {};
        const mileageData = {};
        ordersByMonthKeys.forEach(month => {
            const ordersMonthly = dataByDateOrdersDispatch[month];
            if (ordersMonthly.length) {
                arrivals[month] = [];
                departures[month] = [];
                mileageData[month] = 0;
                ordersMonthly.forEach((order) => {
                    const departure = orders.allDepartures.nodes.find(departure => departure.orderId === order.id);
                    const arrival = orders.allArrivals.nodes.find(arrival => arrival.orderId === order.id);
                    if (departure && arrival) {
                        arrivals[month].push(arrival);
                        departures[month].push(departure);
                    }
                })
                const maxArrival = _.maxBy(arrivals[month], data => data.arrivalMileage)
                const minDeparture = _.minBy(departures[month], data => data.departuresMileage)
                mileageData[month] = maxArrival.arrivalMileage - minDeparture.departuresMileage;
                arrivals[month] = _.uniqBy(arrivals[month], data => `${data.arrivalMileage}${data.vehicleId}`)
                departures[month] = _.uniqBy(departures[month], data => `${data.departuresMileage}${data.vehicleId}`)
            }
        });
        const distance = Object.keys(dataByDateOrdersDispatch).map((group, index) => {
            let dropoffMileage = [];
            let pickupMileage = []
            if (dataByDateOrdersDispatch[group].length > 1) {
                dropoffMileage.push(_.sumBy(dataByDateOrdersDispatch[group], 'ordersTransportsByOrderId.nodes[0].dropoffMileage'));
                pickupMileage.push(_.sumBy(dataByDateOrdersDispatch[group], 'ordersTransportsByOrderId.nodes[0].pickupMileage'));
            } else {
                let orders = _.head(dataByDateOrdersDispatch[group]).ordersTransportsByOrderId.nodes[0];
                dropoffMileage.push(orders.dropoffMileage);
                pickupMileage.push(orders.pickupMileage);
            }
            return {
                [group]: dropoffMileage.concat(pickupMileage)
            }
        }).reduce((acc, cur) => {
            let [yearOrMonth] = Object.keys(cur);
            let [total] = Object.values(cur);
            acc.push({
                [yearOrMonth]: total[0] - total[1]
            })
            return acc;
        }, []);
        const flattenFn = (arr, payload) => {
            return Object.keys(arr).map((group) => {
                let temp = [];
                if (arr[group].length > 1) {
                    temp.push(_.sumBy(arr[group], payload));
                } else {
                    temp.push(_.head(arr[group])[payload]);
                }
                let [table] = temp;
                return {
                    [group]: table
                }
            });
        }
        let fuelOilSupply = flattenFn(arrivals, 'fuelOilSupply');
        let totalActualDistance = Object.assign({}, ...distance);
        let totalFuelOilSupply = Object.assign({}, ...fuelOilSupply);
        let totalMilleage = mileageData;
        let actualOperation = distance.reduce((acc, cur) => {
            let key = Object.keys(cur)[0];
            let actualDistance = cur[key];
            let milleage = totalMilleage[key] ? totalMilleage[key] : 0;
            let operation = (actualDistance / milleage) * 100;
            acc.push({
                [key]: isFinite(operation) ? +operation.toFixed(2) : 0
            })
            return acc
        }, []);
        let totalActualOperation = Object.assign({}, ...actualOperation);
        // eslint-disable-next-line no-unused-vars
        let graphs = Object.keys(dataByDateOrdersDispatch).reduce((acc, key) => {
            return acc.concat({
                date: key,
                totalMilleage: totalMilleage[key],
                totalFuelOilSupply: totalFuelOilSupply[key],
                totalFuelConsumed: totalMilleage[key] / totalFuelOilSupply[key],//totalFuelConsumed[key],
                totalActualDistance: totalActualDistance[key],
                totalActualOperation: totalActualOperation[key],
            })
        }, []);
        // totalMilleage[key]/totalFuelOilSupply[key]
        dispatch({
            type: 'graph',
            graphs
        });
    }

    const handleReset = () => {
        dispatch({
            type: 'reset'
        });
        form.resetFields();
        handleSelectedRowKeys([])
        openNotificationWithIcon('warning', '分析条件をクリアしました。');
        form.setFieldsValue({
            transportDate_From_Year: '',
            transportDate_From_Month: '',
            transportDate_To_Year: '',
            transportDate_To_Month: '',
        })
    }

    const radioFilter = [
        { id: 2, value: lt('rdo_by_month'), autoFocus: true },
        { id: 1, value: lt('rdo_by_year'), autoFocus: false }
    ];

    let columns = [{
        title: lt('lbl_vehicle_no.'),
        dataIndex: 'vehicleNo',
        key: 'vehicleNo',
    }];


    const onRowClick = (record) => {
        if (selectedRowKeys === record.key) {
            handleSelectedRowKeys([]);
            form.setFieldsValue({
                vehicleId: null,
                vehicleNo: null,
            })
        } else {
            const { id, vehicleNo } = record;
            form.setFieldsValue({
                vehicleId: id,
                vehicleNo
            });
            handleSelectedRowKeys(record.key)
        }
    };

    const onSelect = ({ id, vehicleNo }) => {
        form.setFieldsValue({
            vehicleId: id,
            vehicleNo
        })
    };

    const onSelectedRowKey = e => handleSelectedRowKeys(e);

    return (
        <Form onSubmit={search}>
            <Row>
                <Col span={24}>{lt('lbl_analysis_year/month')}</Col>
            </Row>
            <Row style={{ marginTop: '10px' }}>
                <Col span={24}>
                    <CustomRadioGroup
                        options={radioFilter}
                        form={form}
                        fieldName='radio_customer'
                        decorator={{
                            initialValue: 0
                        }}
                    />
                </Col>
            </Row>
            <Row style={{ marginTop: '4px' }}>
                <Col>分析年月</Col>
            </Row>
            <Row style={{ marginTop: '10px' }}>
                <Col>
                    <div className='ant-row ant-form-item four_column'>
                        <Year
                            form={form}
                            fieldName='transportDate_From_Year'
                            postLabel={lt('lbl_conditions_from_year')}
                            rowField={true}
                            style={{ width: 150, display: 'inline-block', marginRight: 8 }}
                            decorator={{
                                rules: [{
                                    required: true,
                                    message: ' '
                                }]
                            }}
                            defaultValue={2018}
                            labelWidth={16}
                            width={18}
                        />
                        <Month
                            form={form}
                            fieldName='transportDate_From_Month'
                            postLabel={lt('lbl_conditions_from_month')}
                            style={{ width: 100, display: 'inline-block', marginRight: 10 }}
                            rowField={true}
                            decorator={{
                                rules: [{
                                    required: true,
                                    message: ' '
                                }]
                            }}
                            labelWidth={12}
                            width={14}
                        />

                        <Year
                            form={form}
                            fieldName='transportDate_To_Year'
                            postLabel={lt('lbl_conditions_to_year')}
                            rowField={true}
                            style={{ width: 150, display: 'inline-block', marginRight: 10 }}
                            decorator={{
                                rules: [{
                                    required: true,
                                    message: ' '
                                }]
                            }}
                            labelWidth={16}
                            width={18}
                        />
                        <CustomHiddenInput
                            form={form}
                            fieldName='vehicleId'
                            type='hidden'
                            decorator={{}}
                        />
                        <CustomHiddenInput
                            form={form}
                            fieldName='vehicleNo'
                            type='hidden'
                            decorator={{}}
                        />
                        <Month
                            form={form}
                            fieldName='transportDate_To_Month'
                            postLabel={lt('lbl_conditions_to_month')}
                            rowField={true}
                            style={{ width: 100, display: 'inline-block', marginRight: 10 }}
                            decorator={{
                                rules: [{
                                    required: true,
                                    message: ' '
                                }]
                            }}
                            labelWidth={12}
                            width={14}
                        />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    {lt('lbl_target_vehicle')}
                </Col>
            </Row>
            <Row>
                <Col>
                    <Table
                        className='searchsales'
                        position="top"
                        bordered={true}
                        columns={columns}
                        dataSource={table}
                        rowSelection={{
                            onSelect: onSelect,
                            type: 'radio',
                            onChange: onSelectedRowKey,
                            selectedRowKeys,
                        }}
                        pagination={{ pageSize: 10, position: 'top' }}
                        onRow={(record, rowIndex) => ({ record, onClick: () => onRowClick(record) })}
                    />
                </Col>
            </Row>
            <Row style={{ marginTop: '10px' }}>
                <Col span={12}>
                    <Button type='default' style={{ width: '106px' }} onClick={handleReset} htmlType='button'>{lt('btn_click_on_clear_conditions')}</Button>
                </Col>
                <Col span={12} style={{ textAlign: 'right' }}>
                    <WithEventButton
                        event={search}
                        loading={false}
                        style={{ width: '106px' }}
                        label={lt('btn_click_on_analyze')}
                    />
                </Col>
            </Row>
        </Form>
    );
}

export default Form.create()(Filter);