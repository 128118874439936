import { DatePicker, Icon, Modal, Popover, Row, Table, Typography } from 'antd';
import jaJP from 'antd/lib/date-picker/locale/ja_JP';
import decode from 'jwt-decode';
import _ from 'lodash';
import moment from 'moment';
import 'moment/locale/ja';
import React, { useEffect, useState } from 'react';
import { withApollo } from 'react-apollo';
import { Link, withRouter } from 'react-router-dom';
import '../../assets/css/orders-mobile.css';
import { connection } from '../../commons/SocketClient';
import adhocTranslator from '../../utils/locales/translate-adhoc';
import { GET_ORDERS } from '../Orders/queries';

const lt = adhocTranslator('K020-010');
const dateFormat = 'YYYY/MM/DD';

const columns = [
  {
    title: (
      <>
        <Typography style={{ color: '#FFFFFF' }}>顧客名</Typography>
        <Typography style={{ color: '#FFFFFF', marginLeft: 20 }}>借主</Typography>
        <Typography style={{ color: '#FFFFFF', marginLeft: 20 }}>担当者</Typography>
      </>
    ),
    dataIndex: 'info', key: 'info', width: '50%',
    align: 'left', className: 'vertical-align',
    render: (data) => (
      <>
        <Typography>{data.customer}</Typography>
        <Row style={{ marginLeft: 10 }}>
          <Typography>借主：{data.borrower}</Typography>
          <Typography>担当：{data.assignee ? `${data.assignee}さん` : ''}</Typography>
        </Row>
      </>
    )
  },
  {
    title: '連絡先', dataIndex: 'number',
    width: '50%', key: 'number',
    align: 'center', className: 'vertical-align',
    render: (data, record) => data && record.dispatchStatus !== 'PENDING' ? <a style={{ textDecoration: 'underline' }} href={`tel:${data}`}>{data}</a> : <span>不明</span>
  },
];

const OrdersMobile = (props) => {
  const { client, history } = props;
  const [date, setDate] = useState(null);
  const [tableState, setTableState] = useState({
    loading: false,
    dataSource: [],
    columns,
  });

  const tableSetter = (value) => setTableState(state => ({ ...state, ...value }));

  const confirmLogout = () => {
    let redirect = '/login';
    const { socket } = connection();
    if (socket) socket.disconnect();

    const token = localStorage.getItem('token');
    if (token) {
      const decodeToken = JSON.parse(atob(token.split('.')[1]));
      if (decodeToken.role === 'app_customer') redirect = '/client';
    }

    localStorage.removeItem('token');
    localStorage.removeItem('search_date');
    history.push(redirect);
  };

  const onLogout = (e) => {
    e.preventDefault();
    Modal.confirm({
      centered: true, itle: '確認',
      icon: 'false', content: 'ログアウトしますか？', okText: 'はい',
      cancelText: 'いいえ', lassName: 'modelStyle',
      onOk: confirmLogout
    });
  };

  useEffect(() => setDate(moment()), []);

  useEffect(() => {
    // Acqure order data
    if (!date) return;

    (async () => {
      const token = localStorage.getItem('token');
      const { user_id } = decode(token);
      if (user_id) {
        tableSetter({ loading: true });
        const transportDate = date && moment(date).isValid() ? date.format('YYYY-MM-DD') : null;
        const overnightDate = date && moment(date).isValid() ? moment(date.format('YYYY-MM-DD')).subtract(1, 'days').format('YYYY-MM-DD') : null;
        const { data } = await client.query({
          query: GET_ORDERS,
          fetchPolicy: 'network-only',
          variables: {
            filter: {
							isDeleted: { equalTo: false },
              or: [
                {
                  dispatchStatus: { equalTo: 'COMPLETED' },
                  transportDate: { equalTo: transportDate },
                  transportDateOption: { equalTo: 'SPECIFIED_DAY' },
                  driverId1: { equalTo: user_id }
                },
                {
                  dispatchStatus: { equalTo: 'COMPLETED' },
                  driverId1: { equalTo: user_id },
                  overnight: { equalTo: 'YES' },
                  transportDate: { equalTo: overnightDate },
                  transportDateOption: { equalTo: 'SPECIFIED_DAY' }
                },
                {
                  dispatchStatus: { equalTo: 'COMPLETED' },
                  overnight: { equalTo: 'YES' },
                  driverId1: { equalTo: user_id },
                  ordersTransportsByOrderId: {
                    some: {
                      and: [
                        { pickupDate: { lessThanOrEqualTo: transportDate } },
                        { dropoffDate: { greaterThanOrEqualTo: transportDate } },
                      ]
                    }
                  }
                }
              ],
            },
            orderBy: ['TRANSPORT_DATE_ASC', 'ORDER_DATE_ASC', 'DISPLAY_ORDER_ASC', 'CREATED_AT_ASC']
          }
        });

        // Set to table source
        const dataSource = _.map(data.allOrders.nodes, (order) => {
          const transports = order.ordersTransportsByOrderId.nodes;
          const { nameJa = null } = order.customerByCustomerId || {};
          const transportList = _.map(transports, transport => {
            const { pickupAssignee, pickupBorrower, pickupContact, dropoffAssignee, dropoffBorrower, dropoffContact } = transport;
            const dataList = [];
            if (pickupContact) {
              const contactList = pickupContact.split('|');
              const assigneeList = (pickupAssignee || '').split('|');
              contactList.forEach((contact, index) => {
                if (contact && contact !== 'empty') {
                  dataList.push({
                    dispatchStatus: order.dispatchStatus, key: 'origin' + transport.id,
                    info: {
                      customer: nameJa,
                      assignee: (assigneeList[index] && assigneeList[index]) !== 'empty' ? assigneeList[index] : '',
                      borrower: pickupBorrower
                    },
                    number: contact
                  });
                }
              });
            }

            if (dropoffContact) {
              const contactList = dropoffContact.split('|');
              const assigneeList = (dropoffAssignee || '').split('|');
              contactList.forEach((contact, index) => {
                if (contact && contact !== 'empty') {
                  dataList.push({
                    dispatchStatus: order.dispatchStatus, key: 'origin' + transport.id,
                    info: {
                      customer: nameJa,
                      assignee: (assigneeList[index] && assigneeList[index] !== 'empty') ? assigneeList[index] : '',
                      borrower: dropoffBorrower
                    },
                    number: contact
                  });
                }
              });
            }
            // if (dropoffContact) {
            //   dataList.push({ dispatchStatus: order.dispatchStatus, key: 'destination' + transport.id, info: { customer: nameJa, borrower: dropoffBorrower, assignee: dropoffAssignee }, number: dropoffContact });
            // }
            return dataList;
          });
          return _.flatten(transportList);
        });

        tableSetter({ dataSource: _.flatten(dataSource), loading: false })
      }
    })();
  }, [date]);

  return (
    <Row className='orders-mb-container'>
      <Row className='orders-mb-header'>
        <Typography.Text>連絡先</Typography.Text>
        <Row className='orders-mb-logout'>
          <Popover
            trigger='click'
            placement='bottomRight'
            content={(<Link to='/login' onClick={e => onLogout(e)}>ログアウト</Link>)}
          >
            <Row>
              <Icon type='ellipsis' style={{ fontSize: 18 }} />
            </Row>
          </Popover>
        </Row>
      </Row>
      <Row className='orders-mb-search'>
        <Typography.Text>運搬日</Typography.Text>
        <DatePicker
          id='order-search'
          value={date}
          format={dateFormat}
          locale={jaJP}
          autoFocus={false}
          placeholder={lt('txt_ｆrom_date')}
          showToday={false}
          size='default'
          onChange={(value) => setDate(value)}
          style={{ marginLeft: 15 }}
        />
      </Row>
      <Row className='orders-mb-table'>
        <Table
          {...tableState}
          rowKey='id'
          className='order-table'
        />
      </Row>
    </Row>
  )
};

export default withRouter(withApollo(OrdersMobile));