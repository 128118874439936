import { Form, Input, Row, Select, Typography } from "antd";
import _ from 'lodash';
import moment from 'moment';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import '../../../../assets/css/orders.css';
import ModalBase from '../../../../commons/ModalBase';
import DriverModalConfig from '../../../../components/MasterMaintenance/User/ListModal';
import { getUnitPrice } from '../../../../queries/Estimate/unitPrice';
import { FormContext, OrderContext } from '../../../../utils/context';
import adhocTranslator from '../../../../utils/locales/translate-adhoc';
import { vehicleTypes } from "../../constants";
import { convertHalfWidthToFullWidth } from "../../functions";
import { GET_CONNECTED_DATA } from '../../queries';
import LocationInput from '../location';

const { Option } = Select;

let lt = adhocTranslator('K030-020');

const EscortForm = (props) => {
  const { escortIndex, transportData, transportIndex } = props;
  const { mode, formData, transportDistance, dataList: { vehicleList }, isDriver } = useContext(OrderContext);
  const { form } = useContext(FormContext);
  const { getFieldDecorator, getFieldValue, setFieldsValue } = form;

  const [state, setState] = useState({
    loaded: false,
    escortData: {},
    connectedEscort: null,
  });
  const { escortData, loaded } = state;

  const isConnected = useMemo(() => {
    return !!formData.connectionId && formData.escortConnected
  }, [formData]);

  const stateSetter = (value) => setState(state => ({ ...state, ...value }));

  useEffect(() => {
    if (_.isEmpty(escortData) && loaded) {
      const origin = `${transportIndex}_origin_${escortIndex}`;
      const destination = `${transportIndex}_destination_${escortIndex}`;
      switch (escortIndex) {
        case 1:
          setFieldsValue({
            [origin]: getFieldValue(`origin_${transportIndex}`),
            [destination]: getFieldValue(`destination_${transportIndex}`),
          });
          break;
        case 2:
        case 4:
          setFieldsValue({
            [origin]: getFieldValue(`${transportIndex}_origin_${escortIndex - 1}`),
            [destination]: getFieldValue(`${transportIndex}_destination_${escortIndex - 1}`),
          });
          break;
        default:
      }
    }
  }, [escortData, loaded]);

  useEffect(() => {
    if (formData) {
      const escortValues = (transportData.escort || [])[escortIndex - 1] || {};
      stateSetter({ escortData: { ...escortValues }, loaded: true });
    }
  }, [formData, transportData]);

  const getEscortConnected = async () => {
    const response = await props.client.query({
      query: GET_CONNECTED_DATA,
      fetchPolicy: 'network-only',
      variables: {
        escortFilter: { orderId: { equalTo: formData.connectedOrderId }, ordersTransportByTransportId: { transportNo: { equalTo: Number(transportIndex) } }, escortNo: { equalTo: Number(escortIndex) } },
        orderFilter: { id: { equalTo: formData.id } }
      }
    });

    const otherOrder = response.data.allOrders.nodes[0];
    const otherEscort = response.data.allOrdersEscortVehicles.nodes;
    return [otherOrder, otherEscort];
  }

  const autoFillBillingAmount = async (caller) => {
    const { callType, value } = caller;

    const escortVehicleUnitPriceId = getFieldValue(`customerId-escortVehicleUnitPriceId`);
    const weight = getFieldValue(`packing1-weight`); //check pack Weight
    let transportDistance;

    if (!escortVehicleUnitPriceId || !weight) return;

    let vehicleName = '';
    let distance = '';
    if (callType === 'vehicle') {
      //check if transport distance has value
      const fieldNamePickupDistance = getFieldValue(`${transportIndex}_pickupDistance_${escortIndex}`);
      vehicleName = value;
      distance = '' + fieldNamePickupDistance;
    } else {
      vehicleName = getFieldValue(`${transportIndex}_vehicleId_${escortIndex}`);
      distance = value;
    }

    if (!Number.isInteger(distance) && distance.includes(",")) {
      transportDistance = parseFloat(distance.replace(/,/g, ''))
    } else {
      transportDistance = parseFloat(distance)
    }

    let billAmount = '';
    if (vehicleName && distance) {
      let { data } = await props.client.mutate({
        mutation: getUnitPrice,
        variables: {
          id: parseInt(escortVehicleUnitPriceId),
          weight: parseFloat(weight),
          distance: parseFloat(transportDistance)
        }
      });

      if (data) {
        if (data.getUnitPrice.float) {
          billAmount = data.getUnitPrice.float;
        }
      }
    }
    if (isConnected) {
      let totalDistance = null, totalBilling = null;
      const [otherOrder, otherEscort] = await getEscortConnected();
      if (otherEscort.length) {
        const [escort] = otherEscort;
        stateSetter({ connectedEscort: escort });
        const { customerByCustomerId, packagingByPacking1 } = otherOrder;
        if ((customerByCustomerId || {}).escortVehicleUnitPriceId === parseInt(escortVehicleUnitPriceId)) {
          totalDistance = transportDistance + escort.pickupDistance;
          const totalWeight = weight;
          const { data } = await props.client.mutate({
            mutation: getUnitPrice,
            variables: {
              id: parseFloat(escortVehicleUnitPriceId),
              weight: parseFloat(totalWeight),
              distance: parseFloat(totalDistance)
            }
          });
          if (data && data.getUnitPrice.float) {
            // Set total billing and distance
            totalBilling = data.getUnitPrice.float;
          }
        }
        appendEscortConnection(transportDistance, escort, totalDistance, totalBilling);

        if (totalDistance) setFieldsValue({ [`${transportIndex}_totalDistance_${escortIndex}`]: totalDistance ? totalDistance.toLocaleString() : null });
        if (totalBilling) setFieldsValue({ [`${transportIndex}_totalBilling_${escortIndex}`]: totalBilling ? totalBilling.toLocaleString() : null });
      }
    } else {
      const fieldNameBillAmount = `${transportIndex}_transportBillingAmount_${escortIndex}`;
      setFieldsValue({ [`${fieldNameBillAmount}`]: `${billAmount}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') })
    }
  }

  const onVehicleChange = async (value) => {
    const { usersByVehicleNumber: { nodes } } = vehicleList.find(vehicle => vehicle.id === value);
    const { id, fullNameJa } = nodes[0];

    const fieldName = `${transportIndex}_driverId_${escortIndex}`;
    setFieldsValue({ [`${fieldName}-display`]: fullNameJa, [fieldName]: id })
    await autoFillBillingAmount({ callType: 'vehicle', value: fullNameJa });
    updateFormDriverId(value);
  };

  const onBlur = async (value) => {
    await autoFillBillingAmount({ callType: 'distance', value });
  }

  const onLocationChange = (location) => () => {
    const value = getFieldValue(`${transportIndex}_${location}_${escortIndex}`);
    switch (escortIndex) {
      case 1:
        setFieldsValue({ [`${transportIndex}_${location}_${2}`]: convertHalfWidthToFullWidth(value) });
        break;
      case 3:
        setFieldsValue({ [`${transportIndex}_${location}_${4}`]: convertHalfWidthToFullWidth(value) });
        break;
      default:
        break;
    }
  };

  const checkRequired = () => {
    const isCompleted = (getFieldValue('dispatchStatus')) === 'COMPLETED';
    return isCompleted;
  };

  const convertFullWidthOnBlur = (field, value) => {
    if (value) {
      setFieldsValue({ [field]: convertHalfWidthToFullWidth(value) })
    }
  }

  const onNumberBlur = (name) => (event, callback) => {
    let { value } = event.target;
    let dataValue = null;
    if (!value || value !== '') {
      value = value.replace(/,/g, '');
      const reg = /^[0-9\b]+$/;
      dataValue = reg.test(value) ? `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : null;
    };
    setFieldsValue({ [name]: dataValue });

    if (dataValue && callback) {
      callback(dataValue);
    }
  }

  const onNumberFocus = (name) => (event) => {
    let { value } = event.target;
    setFieldsValue({ [name]: value.replace(/,/g, '') });
  }

  const onTransportBillingChange = async (value) => {
    const [, otherEscort] = await getEscortConnected();
    if (otherEscort.length) {
      const [escort] = otherEscort;
      const amount = value ? parseFloat(value.replace(/,/g, '')) : null;
      const totalBilling = getFieldValue(`${transportIndex}_totalBilling_${escortIndex}`);
      const baseBilling = parseFloat(totalBilling.replace(/,/g, ''))
      const secondBilling = _.round(baseBilling - amount);

      if (!formData.updateEscorts) formData.updateEscorts = [];

      const hasEscort = formData.updateEscorts.find(data => data.id === escort.id);
      if (hasEscort) {
        hasEscort.billing = secondBilling;
      } else {
        formData.updateEscorts.push({
          id: escort.id,
          billing: secondBilling
        });
      }
    }
  }

  const calculateTotalDistance = async (value, key) => {
    const transportPriceId = getFieldValue(`customerId-escortVehicleUnitPriceId`);
    const weight = getFieldValue(`packing1-weight`); //check pack Weight
    if (!transportPriceId || !weight || !value) return;

    const totalDistance = parseFloat(value.replace(/,/g, ''))
    let { data } = await props.client.mutate({
      mutation: getUnitPrice,
      variables: {
        id: parseInt(transportPriceId),
        weight: parseFloat(weight),
        distance: totalDistance
      }
    });

    if (data && data.getUnitPrice.float) {
      const totalBilling = data.getUnitPrice.float || '';
      setFieldsValue({ [key]: totalBilling ? `${totalBilling}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : totalBilling })
    }
  };

  const appendEscortConnection = (transportDistance, escort, totalDistance, totalBilling) => {
    // SET FORM DATA
    // Calculate billing division
    const driverId = form.getFieldValue(`${transportIndex}_driverId_${escortIndex}`);
    const otherBilling = {};
    if (totalBilling) {
      const billing1 = _.round((Number(transportDistance) / totalDistance) * totalBilling);
      const billing2 = _.round((escort.pickupDistance / totalDistance) * totalBilling);
      otherBilling.driver1Billing = formData.isFirst ? billing1 : billing2;
      otherBilling.driver2Billing = formData.isFirst ? billing2 : billing1;
      otherBilling.connectionDriverId1 = form.isFirst ? driverId : escort.driverId;
      otherBilling.connectionDriverId2 = form.isFirst ? escort.driverId : driverId;
      otherBilling.isSameDrivers = otherBilling.connectionDriverId1 === otherBilling.connectionDriverId2;
    } else {
      otherBilling.driver1Billing = null;
      otherBilling.driver2Billing = null;
      otherBilling.connectionDriverId1 = form.isFirst ? driverId : escort.driverId;
      otherBilling.connectionDriverId2 = form.isFirst ? escort.driverId : driverId;
      otherBilling.isSameDrivers = otherBilling.connectionDriverId1 === otherBilling.connectionDriverId2;
    }
    const transport = formData.transport[Number(transportIndex) - 1];
    const escortId1 = formData.isFirst ? null : escort.id;
    const escortId2 = formData.isFirst ? escort.id : null

    if (transport.escort[escortIndex - 1]) {
      transport.escort[escortIndex - 1] = {
        ...transport.escort[escortIndex - 1],
        escortId2: escortId1,
        escortId1: escortId2,
        totalDistance: totalDistance,
        totalBilling: totalBilling,
        escortConnectionId: escort.id,
        ...otherBilling
      }
    } else {
      transport.escort[escortIndex - 1] = {};
      transport.escort[escortIndex - 1] = {
        escortId2: escortId1,
        escortId1: escortId2,
        totalDistance: totalDistance,
        totalBilling: totalBilling,
        escortConnectionId: escort.id,
        ...otherBilling
      }
    }

    const fieldNameBillAmount = `${transportIndex}_transportBillingAmount_${escortIndex}`;
    const billAmount = formData.isFirst ? otherBilling.driver1Billing : otherBilling.driver2Billing
    setFieldsValue({ [`${fieldNameBillAmount}`]: billAmount ? `${billAmount}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : null });

    if (!formData.updateEscorts) formData.updateEscorts = [];
    const billing = formData.isFirst ? otherBilling.driver2Billing : otherBilling.driver1Billing
    const hasEscort = formData.updateEscorts.find(data => data.id === escort.id);
    if (hasEscort) {
      hasEscort.billing = billing;
    } else {
      formData.updateEscorts.push({
        id: escort.id,
        billing: billing
      });
    }
  }

  const updateFormDriverId = (value) => {
    if (!isConnected) return;
    const key = formData.isFirst ? 'connectionDriverId1' : 'connectionDriverId2';
    const transport = formData.transport[Number(transportIndex) - 1];
    if (transport && transport.escort) {
      const escort = transport.escort[escortIndex - 1]
      if (escort) {
        escort[key] = value
        escort.isSameDrivers = escort.connectionDriverId1 === escort.connectionDriverId2;
      }
    }
  };

  useEffect(() => {
    if (transportDistance) {
      onNumberBlur(`${transportIndex}_pickupDistance_${escortIndex}`)
        ({ target: { value: transportDistance } }, (distance) => onBlur(distance));
    };
  }, [transportDistance]);

  useEffect(() => {
    if (loaded) {
      const distance = getFieldValue('transportDistance');
      if (_.isEmpty(escortData) && distance) {
        onNumberBlur(`${transportIndex}_pickupDistance_${escortIndex}`)
          ({ target: { value: distance } }, (distance) => onBlur(distance));
      }
    }
  }, [loaded]);

  return (
    <Row className='escort-form'>
      <Row type='flex'>
        <Form.Item style={styles.formItem} label='号車'>
          {getFieldDecorator(`${transportIndex}_vehicleId_${escortIndex}`, {
            initialValue: escortData.vehicleId,
            rules: [{
              required: checkRequired(),
              message: '号車を選択してください。'
            }]
          })(
            <Select
              style={{ width: 200, marginRight: 10 }}
              onChange={onVehicleChange}
              disabled={isDriver}
            >
              {vehicleList
                .filter(({ vehicleType }) =>
                  vehicleType === vehicleTypes.ESCORT_VEHICLE_COMPANY ||
                  vehicleType === vehicleTypes.ESCORT_VEHICLE_OUTSOURCE ||
                  vehicleType === vehicleTypes.OUTSOURCE
                )
                .map(({ id, vehicleNo }) => (
                  <Option key={id} value={id}>{vehicleNo}</Option>
                ))
              }
            </Select>
          )}
        </Form.Item>

        <ModalBase
          title='運転手情報検索'
          form={form}
          label='運転手'
          multiple={false}
          mode={mode}
          btnLabel='選択'
          fieldName={`${transportIndex}_driverId_${escortIndex}`}
          config={DriverModalConfig({ authority: 4 })}
          labelWidth={5}
          className='orderConfigModal'
          modalClass='basic-form-modal'
          afterSelect={(value) => updateFormDriverId(value)}
          width={20}
          buttonMarginRight={20}
          decorator={{
            rules: [{
              required: (getFieldValue('dispatchStatus') || formData.dispatchStatus) === 'COMPLETED' ? true : false,
              message: lt('運転手を選択してください。'),
            }],
            initialValue: escortData.driverId && escortData.driverName
          }}
          hiddenDecorator={{
            initialValue: escortData.driverId
          }}
          customFilterByDate={
            getFieldValue('transportDate') === null ?
              moment().format('YYYY/MM/DD') :
              moment(getFieldValue('transportDate')).format('YYYY/MM/DD')
          }
          isDriver={isDriver}
        />

        <LocationInput
          form={form}
          type='origin'
          fieldName={`${transportIndex}_origin_${escortIndex}`}
          initialValue={escortData.origin}
          style={{ width: 200, marginRight: 10 }}
          disabled={isDriver}
          onBlur={(value) => {
            onLocationChange('origin', value)();
            convertFullWidthOnBlur(`${transportIndex}_origin_${escortIndex}`, value);
          }}
        />

        {/* <Form.Item style={styles.formItem} label='発地場所'>
          {getFieldDecorator(`${transportIndex}_origin_${escortIndex}`, {
            initialValue: escortData.origin,
            // rules: [{
            //   required: checkRequired(),
            //   message: '発地場所を入力してください。',
            // }],
          })(
            <Input
              onBlur={onLocationChange(true)}
              style={{ width: 200, marginRight: 10 }}
              disabled={isDriver}
            />
          )}
        </Form.Item> */}

        <LocationInput
          form={form}
          type='destination'
          fieldName={`${transportIndex}_destination_${escortIndex}`}
          initialValue={escortData.destination}
          style={{ width: 200, marginRight: 10 }}
          disabled={isDriver}
          onBlur={(value) => {
            onLocationChange('destination', value)();
            convertFullWidthOnBlur(`${transportIndex}_destination${escortIndex}`, value);
          }}
        />
      </Row>

      {isConnected ? (
        <>
          <Row type='flex'>
            <Form.Item style={styles.formItem} label='運搬距離'>
              {getFieldDecorator(`${transportIndex}_pickupDistance_${escortIndex}`, {
                initialValue: escortData.pickupDistance || null,
              })(
                <Input
                  style={{
                    width: 158,
                    verticalAlign: 'middle', borderBottomRightRadius: 0, borderTopRightRadius: 0
                  }}
                  onBlur={(e) => {
                    onNumberBlur(`${transportIndex}_pickupDistance_${escortIndex}`)(e, (distance) => {
                      onBlur(distance);
                    });
                  }}
                  onFocus={onNumberFocus(`${transportIndex}_pickupDistance_${escortIndex}`)}
                  disabled={isDriver}
                  maxLength={7}
                />
              )}
              <div className="ant-input-group-addon"
                style={{
                  paddingTop: '2px', verticalAlign: 'middle', display: 'inline-table', lineHeight: '24px', height: '32px',
                  marginRight: 10
                }}>
                <Typography>km</Typography>
              </div>
            </Form.Item>
            <Form.Item style={styles.formItem} label='運搬費'>
              {getFieldDecorator(`${transportIndex}_transportBillingAmount_${escortIndex}`, {
                initialValue: escortData.transportBillingAmount || null,
              })(
                <Input
                  style={{
                    width: 167,
                    verticalAlign: 'middle', borderBottomRightRadius: 0, borderTopRightRadius: 0
                  }}
                  onFocus={onNumberFocus(`${transportIndex}_transportBillingAmount_${escortIndex}`)}
                  onBlur={(e) => {
                    onNumberBlur(`${transportIndex}_transportBillingAmount_${escortIndex}`)(e, (distance) => {
                      onTransportBillingChange(distance);
                    })
                  }}
                  disabled={isDriver}
                  maxLength={7}
                />
              )}
              <div className="ant-input-group-addon"
                style={{ paddingTop: '2px', verticalAlign: 'middle', display: 'inline-table', lineHeight: '24px', height: '32px', marginRight: 18 }}>
                <Typography>円</Typography>
              </div>
            </Form.Item>
          </Row>
          <Row type='flex'>
            <Form.Item style={styles.formItem} label='請求距離'>
              {getFieldDecorator(`${transportIndex}_totalDistance_${escortIndex}`, {
                initialValue: escortData.totalDistance || null,
              })(
                <Input
                  style={{
                    width: 158,
                    verticalAlign: 'middle', borderBottomRightRadius: 0, borderTopRightRadius: 0
                  }}
                  onBlur={(e) => {
                    onNumberBlur(`${transportIndex}_totalDistance_${escortIndex}`)(e, (distance) => {
                      // onBlur(distance);
                      calculateTotalDistance(distance, `${transportIndex}_totalBilling_${escortIndex}`)
                    });
                  }}
                  onFocus={onNumberFocus(`${transportIndex}_totalDistance_${escortIndex}`)}
                  disabled={true}
                  maxLength={7}
                />
              )}
              <div className="ant-input-group-addon"
                style={{
                  paddingTop: '2px', verticalAlign: 'middle', display: 'inline-table', lineHeight: '24px', height: '32px',
                  marginRight: 10
                }}>
                <Typography>km</Typography>
              </div>
            </Form.Item>
            <Form.Item style={styles.formItem} label='請求額'>
              {getFieldDecorator(`${transportIndex}_totalBilling_${escortIndex}`, {
                initialValue: escortData.totalBilling || null,
              })(
                <Input
                  style={{
                    width: 167,
                    verticalAlign: 'middle', borderBottomRightRadius: 0, borderTopRightRadius: 0
                  }}
                  onFocus={onNumberFocus(`${transportIndex}_totalBilling_${escortIndex}`)}
                  onBlur={onNumberBlur(`${transportIndex}_totalBilling_${escortIndex}`)}
                  disabled={isDriver}
                  maxLength={7}
                />
              )}
              <div className="ant-input-group-addon"
                style={{ paddingTop: '2px', verticalAlign: 'middle', display: 'inline-table', lineHeight: '24px', height: '32px', marginRight: 18 }}>
                <Typography>円</Typography>
              </div>
            </Form.Item>
            <Form.Item style={styles.formItem} label='支払額'>
              {getFieldDecorator(`${transportIndex}_transportPaymentAmount_${escortIndex}`, {
                initialValue: escortData.transportPaymentAmount || null,
              })(
                <Input
                  style={{
                    width: 167,
                    verticalAlign: 'middle', borderBottomRightRadius: 0, borderTopRightRadius: 0
                  }}
                  onFocus={onNumberFocus(`${transportIndex}_transportPaymentAmount_${escortIndex}`)}
                  onBlur={onNumberBlur(`${transportIndex}_transportPaymentAmount_${escortIndex}`)}
                  disabled={isDriver}
                  maxLength={7}
                />
              )}
              <div className="ant-input-group-addon"
                style={{ paddingTop: '2px', verticalAlign: 'middle', display: 'inline-table', lineHeight: '24px', height: '32px' }}>
                <Typography>円</Typography>
              </div>
            </Form.Item>
          </Row>
        </>
      ) : (
        <Row type='flex'>
          <Form.Item style={styles.formItem} label='距離'>
            {getFieldDecorator(`${transportIndex}_pickupDistance_${escortIndex}`, {
              initialValue: escortData.pickupDistance || null,
            })(
              <Input
                style={{
                  width: 158,
                  verticalAlign: 'middle', borderBottomRightRadius: 0, borderTopRightRadius: 0
                }}
                onBlur={(e) => {
                  onNumberBlur(`${transportIndex}_pickupDistance_${escortIndex}`)(e, (distance) => {
                    onBlur(distance);
                  });
                }}
                onFocus={onNumberFocus(`${transportIndex}_pickupDistance_${escortIndex}`)}
                disabled={isDriver}
                maxLength={7}
              />
            )}
            <div className="ant-input-group-addon"
              style={{
                paddingTop: '2px', verticalAlign: 'middle', display: 'inline-table', lineHeight: '24px', height: '32px',
                marginRight: 10
              }}>
              <Typography>km</Typography>
            </div>
          </Form.Item>
          <Form.Item style={styles.formItem} label='請求額'>
            {getFieldDecorator(`${transportIndex}_transportBillingAmount_${escortIndex}`, {
              initialValue: escortData.transportBillingAmount || null,
            })(
              <Input
                style={{
                  width: 167,
                  verticalAlign: 'middle', borderBottomRightRadius: 0, borderTopRightRadius: 0
                }}
                onFocus={onNumberFocus(`${transportIndex}_transportBillingAmount_${escortIndex}`)}
                onBlur={onNumberBlur(`${transportIndex}_transportBillingAmount_${escortIndex}`)}
                disabled={isDriver}
                maxLength={7}
              />
            )}
            <div className="ant-input-group-addon"
              style={{ paddingTop: '2px', verticalAlign: 'middle', display: 'inline-table', lineHeight: '24px', height: '32px', marginRight: 18 }}>
              <Typography>円</Typography>
            </div>
          </Form.Item>
          <Form.Item style={styles.formItem} label='支払額'>
            {getFieldDecorator(`${transportIndex}_transportPaymentAmount_${escortIndex}`, {
              initialValue: escortData.transportPaymentAmount || null,
            })(
              <Input
                style={{
                  width: 167,
                  verticalAlign: 'middle', borderBottomRightRadius: 0, borderTopRightRadius: 0
                }}
                onFocus={onNumberFocus(`${transportIndex}_transportPaymentAmount_${escortIndex}`)}
                onBlur={onNumberBlur(`${transportIndex}_transportPaymentAmount_${escortIndex}`)}
                disabled={isDriver}
                maxLength={7}
              />
            )}
            <div className="ant-input-group-addon"
              style={{ paddingTop: '2px', verticalAlign: 'middle', display: 'inline-table', lineHeight: '24px', height: '32px' }}>
              <Typography>円</Typography>
            </div>
          </Form.Item>
        </Row>
      )}

    </Row>
  )
};

const styles = {
  formItem: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 8
  },
  panelStyle: {
    background: '#f7f7f7',
    borderRadius: 4,
    marginBottom: 24,
    border: 0,
    overflow: 'hidden',
  }
}
export default EscortForm;