import { Button, Typography, Col, DatePicker, Form, Input, Modal, Row, Select, Table, notification } from 'antd';
import jaJP from 'antd/lib/date-picker/locale/ja_JP';
import _ from 'lodash';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { withApollo } from 'react-apollo';
import { OrderContext } from '../../../../utils/context';
import adhocTranslator from '../../../../utils/locales/translate-adhoc';
import { dispatchTypes, formatTypes, modeTypes } from '../../constants';
import { GET_ORDER_THROUGH_TRANSPORT } from '../../queries';
import MapModal from './modal/map-modal';
import OrdersModal from '../../index';

const { Option } = Select;
const lt = adhocTranslator('K140-010');
const dateFormat = 'YYYY/MM/DD';

const formItemLayout = {
  labelCol: { xs: { span: 24 }, sm: { span: 8 } },
  wrapperCol: { xs: { span: 24 }, sm: { span: 16 } },
};

const payloadTypes = [
  {
    keys: ['origin', 'destination'],
    payloadKey: 'origin_destination_name'
  },
  {
    keys: ['pickupBorrower', 'dropoffBorrower'],
    payloadKey: 'borrower'
  },
  {
    keys: ['pickupAssignee', 'dropoffAssignee'],
    payloadKey: 'person_in_charge'
  },
  {
    keys: ['pickupContact', 'dropoffContact'],
    payloadKey: 'contact'
  },
  {
    type: 'exist',
    keys: ['ordersDropoffImagesByTransportIdExist', 'ordersPickupImagesByTransportIdExist'],
    payloadKey: 'has_image'
  },
  {
    type: 'isNull',
    keys: ['pickupMemo', 'dropoffMemo'],
    payloadKey: 'has_memo'
  },
];

const valueType = {
  0: null,
  1: true,
  2: false,
}

const nullType = {
  0: null,
  1: false,
  2: true,
}
const SearchModal = (props) => {

  /** Initialize translators **/
  const { onOk, onCancel, client, form: { getFieldDecorator, getFieldsValue, resetFields, setFieldsValue } } = props;
  const { modal: { mode, defaults }, isDriver, dataList: { vehicleList, driverList }, modalSetter } = useContext(OrderContext);

  // state var definitions
  const [modal, setModal] = useState({
    visible: false,
    id: null,
    mode: null
  });
  const [selected, setSelected] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tableState, setTableState] = useState({
    columns: [],
    pagination: { pageSize: 100 },
    scroll: { y: 450, x: 1000, scrollToFirstRowOnChange: true },
    dataSource: null,
    className: 'orders-search'
  });
  const [viewState, setViewState] = useState({
    visible: false,
    order: null,
    type: null
  });

  // setters
  const tableSetter = (value) => setTableState(state => ({ ...state, ...value }));
  const viewSetter = (value) => setViewState(state => ({ ...state, ...value }));
  const modalEditSetter = (value) => setModal(state => ({ ...state, ...value }));

  const onViewDetails = (order, type) => () => {
    viewSetter({ visible: true, order, type })
  };

  const closeView = () => {
    viewSetter({ visible: false, order: null, type: null })
  }

  const showEditModal = (id) => () => {
    modalEditSetter({ visible: true, id, mode: 'edit' })
  };

  const columns = [
    {
      title: lt('orders_action_table_col_transport_date'),
      dataIndex: 'transportDate',
      key: 'transportDate',
      width: 70,
      align: 'center'
    },
    {
      title: <div style={{ display: 'flex', flexDirection: 'column' }}>
        <span>{lt('orders_action_table_col_dispatch_status')}</span>
        <span>{lt('orders_action_table_col_condition_status')}</span>
      </div>,
      dataIndex: 'dispatchStatus',
      key: 'dispatchStatus',
      width: 70,
      align: 'center'
    },
    {
      title: lt('orders_action_table_col_customer'),
      dataIndex: 'customer',
      key: 'customer',
      width: 180,
      align: 'center'
    },

    {
      title: lt('orders_action_table_col_origin'),
      dataIndex: 'origin',
      key: 'origin',
      width: 140,
      align: 'center'
    },
    {
      title: lt('orders_action_table_col_departure_time'),
      dataIndex: 'departure_time',
      key: 'departure_time',
      width: 100,
      align: 'center'
    },
    {
      title: lt('orders_action_table_col_landing_time'),
      dataIndex: 'landing_time',
      key: 'landing_time',
      width: 100,
      align: 'center'
    },
    {
      title: lt('orders_action_table_col_destination'),
      dataIndex: 'destination',
      key: 'destination',
      width: 140,
      align: 'center'
    },
    {
      title: lt('orders_action_table_col_packaging'),
      dataIndex: 'packageData',
      key: 'packagingName',
      width: 110,
      align: 'center'
    },
    {
      title: lt('orders_action_table_col_vehicle'),
      dataIndex: 'vehicleNo',
      key: 'vehicleNo',
      width: 60,
      align: 'center'
    },
    {
      title: lt('orders_action_table_col_driver'),
      dataIndex: 'driver1Name',
      key: 'driver1Name',
      width: 90,
      align: 'center'
    },
    {
      title: '距離',
      dataIndex: 'distance',
      key: 'transport_distance',
      width: 100,
      align: 'center'
    },
    {
      title: '請求額',
      dataIndex: 'billing',
      key: 'billing',
      width: 100,
      align: 'center',
      render: (_, order) => (
        <>
          {order.billing ? <Typography>{order.billing}</Typography> : null}
          {order.escortFare ? <Typography className='pink-label'>{order.escortFare}</Typography> : null}
        </>
      )
    },
    {
      title: '支払額',
      dataIndex: 'payment',
      key: 'payment',
      width: 100,
      align: 'center'
    },
    {
      title: 'メモ',
      dataIndex: 'memo',
      key: 'memo',
      width: 100,
      align: 'center',
      render: (_, order) => (
        order.pickupMemo || order.dropoffMemo ? <Typography
          onClick={onViewDetails(order, 'memo')}
          style={{ color: 'blue', textDecorationLine: 'underline', cursor: 'pointer', textAlign: 'center' }}
        >
          詳細を表示
        </Typography> : null
      )
    },
    {
      title: '借主',
      dataIndex: 'borrower',
      key: 'borrower',
      width: 100,
      align: 'center',
      render: (_, order) => (
        order.pickupBorrower || order.dropoffBorrower ? <Typography
          onClick={onViewDetails(order, 'borrower')}
          style={{ color: 'blue', textDecorationLine: 'underline', cursor: 'pointer', textAlign: 'center' }}
        >
          詳細を表示
        </Typography> : null
      )
    },
    {
      title: '担当者',
      dataIndex: 'assignee',
      key: 'assignee',
      width: 100,
      align: 'center',
      render: (_, order) => (
        order.pickupAssignee || order.dropoffAssignee ? <Typography
          onClick={onViewDetails(order, 'assignee')}
          style={{ color: 'blue', textDecorationLine: 'underline', cursor: 'pointer', textAlign: 'center' }}
        >
          詳細を表示
        </Typography> : null
      )
    },
    {
      title: '連絡先',
      dataIndex: 'contact',
      key: 'contact',
      width: 100,
      align: 'center',
      render: (_, order) => (
        order.pickupContact || order.dropoffContact ? <Typography
          onClick={onViewDetails(order, 'contact')}
          style={{ color: 'blue', textDecorationLine: 'underline', cursor: 'pointer', textAlign: 'center' }}
        >
          詳細を表示
        </Typography> : null
      )
    },
    {
      title: '画像',
      dataIndex: 'image',
      key: 'image',
      width: 100,
      align: 'center',
      render: (_, order) => (
        order.pickupImages.length || order.dropoffImages.length ? <Typography
          onClick={onViewDetails(order, 'image')}
          style={{ color: 'blue', textDecorationLine: 'underline', cursor: 'pointer', textAlign: 'center' }}
        >
          詳細を表示
        </Typography> : null
      )
    },
    {
      title: lt('orders_action_table_col_remarks'),
      dataIndex: 'notes',
      key: 'notes',
      align: 'left',
      width: 220,
    },
    {
      title: '',
      dataIndex: 'orderId',
      width: 80,
      align: 'center',
      render: (data) => {
        return <Typography onClick={showEditModal(data)} style={{ cursor: 'pointer', color: 'blue' }}>[編集]</Typography>
      }
    }
  ];

  useEffect(() => {
    tableSetter({ columns });
  }, []);

  const orConstruct = ({ childKey, keys, type }, dataValue) => {
    if (type === 'exist') {
      if (!dataValue) return;
      const value = valueType[dataValue];
      return { or: [{ [keys[0]]: value }, { [keys[1]]: value }] }
    };

    if (type === 'isNull') {
      if (!dataValue) return;
      const value = nullType[dataValue];
      return { or: [{ [keys[0]]: { isNull: value } }, { [keys[1]]: { isNull: value } }] }
    }

    return (
      {
        or: [
          { [keys[0]]: childKey ? { [childKey]: { includes: dataValue } } : { includes: dataValue } },
          { [keys[1]]: childKey ? { [childKey]: { includes: dataValue } } : { includes: dataValue } },
        ]
      }
    )
  };

  const onSearchClick = async () => {
    const payload = getFieldsValue();
    const { transportDateFrom, transportDateTo } = payload;
    if (transportDateFrom && transportDateTo) {
      const diff = transportDateTo.diff(transportDateFrom, 'months');
      if (diff >= 12) {
        return notification.error({ message: '日付の範囲は、１２ケ月以内に設定してください。' })
      }
    }

    setLoading(true)
    const transportFilter = [], orderFilter = { isDeleted: { equalTo: false } }, orderEscortFilter = {};
    // Order FIlters
    if (payload.transportDateFrom && payload.transportDateTo) {
      orderFilter.transportDate = {
        greaterThanOrEqualTo: payload.transportDateFrom.format(formatTypes.DATE_QUERY),
        lessThanOrEqualTo: payload.transportDateTo.format(formatTypes.DATE_QUERY),
      };
    } else if (payload.transportDateFrom) {
      const endDate = moment(payload.transportDateFrom).add(1, 'year').format(formatTypes.DATE_QUERY)
      orderFilter.transportDate = {
        greaterThanOrEqualTo: payload.transportDateFrom.format(formatTypes.DATE_QUERY),
        lessThanOrEqualTo: endDate
      };
    } else if (payload.transportDateTo) {
      const startDate = moment(payload.transportDateTo).subtract(1, 'year').format(formatTypes.DATE_QUERY)
      orderFilter.transportDate = {
        greaterThanOrEqualTo: startDate,
        lessThanOrEqualTo: payload.transportDateTo.format(formatTypes.DATE_QUERY),
      };
    } else {
      const startYear = moment().subtract(1, 'year').format(formatTypes.DATE_QUERY)
      const currentYear = moment().format(formatTypes.DATE_QUERY)
      orderFilter.transportDate = {
        greaterThanOrEqualTo: startYear,
        lessThanOrEqualTo: currentYear
      }
    }

    let orFilter = null;
    if ((payload.vehicleId || []).length || (payload.driver || []).length) {
      orFilter = { or: [] };
      if ((payload.vehicleId || []).length) {
        orFilter.or.push(...[
          { orderByOrderId: { vehicleId: { in: payload.vehicleId } } },
          { ordersEscortVehiclesByTransportId: { some: { vehicleId: { in: payload.vehicleId } } } }
        ])
      }
      if ((payload.driver || []).length) {
        orFilter.or.push(...[
          { orderByOrderId: { driverId1: { in: payload.driver } } },
          { ordersEscortVehiclesByTransportId: { some: { driverId: { in: payload.driver } } } }
        ]);
      }
    };

    if (payload.customerName) orderFilter.customerByCustomerId = { nameJa: { includes: payload.customerName } };
    if (payload.packagingName) orderFilter.packagingByPacking1 = { packagingName: { includes: payload.packagingName } };
    if (payload.notes) orderFilter.notes = { includes: payload.notes };

    switch (payload.billing_payment_target) {
      case lt('orders_action_billing_payment_target_transportation'):
        switch (payload.claim) {
          case lt('orders_action_claim_pending_invoice'):
            orderFilter.transportBillingAmount = { equalTo: 0 };
            break;
          case lt('orders_action_claim_paid_invoice'):
            orderFilter.transportBillingAmount = { greaterThan: 0 }
            break;
          default:
        }

        switch (payload.payment) {
          case lt('orders_action_payment_unpaid'):
            orderFilter.transportPaymentAmount = { equalTo: 0 }
            break;
          case lt('orders_action_payment_paid'):
            orderFilter.transportPaymentAmount = { greaterThan: 0 }
            break;
          default:
        }
        break;
      case lt('orders_action_billing_payment_target_induction'):
        switch (payload.claim) {
          case lt('orders_action_claim_pending_invoice'):
            orderEscortFilter.transportBillingAmount = { equalTo: 0 };
            break;
          case lt('orders_action_claim_paid_invoice'):
            orderEscortFilter.transportBillingAmount = { greaterThan: 0 };
            break;
          default:
        }

        switch (payload.payment) {
          case lt('orders_action_payment_unpaid'):
            orderEscortFilter.transportPaymentAmount = { equalTo: 0 };
            break;
          case lt('orders_action_payment_paid'):
            orderEscortFilter.transportPaymentAmount = { greaterThan: 0 };
            break;
          default:
        }
        break;
      default:
    }

    // Transport Filters
    _.each(payloadTypes, type => {
      const { payloadKey } = type;
      if (payload[payloadKey]) {
        const value = orConstruct(type, payload[payloadKey]);
        if (value) transportFilter.push(value);
      }
    });

    let filter = _.assign({},
      !_.isEmpty(transportFilter) && { and: transportFilter },
      !_.isEmpty(orderFilter) && { orderByOrderId: orderFilter },
      !_.isEmpty(orderEscortFilter) && { ordersEscortVehiclesByTransportId: { some: orderEscortFilter } }
    );

    if (orFilter) {
      filter = { ...filter, ...orFilter }
    }

    if (_.isEmpty(filter)) {
      setLoading(false);
      return;
    }

    const { data: orderDataFiltered } = await client.query({
      query: GET_ORDER_THROUGH_TRANSPORT,
      fetchPolicy: 'network-only',
      variables: _.assign({}, _.isEmpty(filter) ? null : { filter })
    });

    let orderDataUnique = _.uniqBy(orderDataFiltered.allOrdersTransports.nodes, 'orderId');

    const formatDate = (date, type) => {
      const dateToday = moment().format('YYYY-MM-DD')

      if (!date) return ''

      if (type === 'dateTime') {
        if (moment(date).isSame(dateToday)) {
          return moment(date).format('HH:mm')
        }
        return moment(date).format('MM/DD HH:mm')
      }

      if (type === 'date') {
        if (moment(date).isSame(dateToday)) {
          return ''
        }
        return moment(date).format('MM/DD')
      }
    }

    const dataSource = orderDataUnique.map((orders, index) => {
      const {
        orderByOrderId = {},
        ordersEscortVehiclesByTransportId = {}
      } = orders;

      const className = (orderByOrderId || {}).dispatchStatus === 'PENDING' ? 'pink-row' : 'lblue-row';

      const customerByCustomerId = (orderByOrderId || {}).customerByCustomerId;
      const packagingByPacking1 = (orderByOrderId || {}).packagingByPacking1;
      const vehicleByVehicleId = (orderByOrderId || {}).vehicleByVehicleId;
      const userByDriverId1 = (orderByOrderId || {}).userByDriverId1;
      const userByDriverId2 = (orderByOrderId || {}).userByDriverId2;

      let escortFare = 0;
      if (ordersEscortVehiclesByTransportId.nodes.length) {
        escortFare = _.sumBy(ordersEscortVehiclesByTransportId.nodes, 'transportBillingAmount');
      };

      return {
        escortFare: escortFare ? `${escortFare.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}円` : null,
        key: index,
        class: className,
        // order details
        orderId: orders.orderId,
        orderDate: (orderByOrderId || {}).orderDate,
        transportDate: moment((orderByOrderId || {}).transportDate).format('MM/DD'),
        dispatchStatus: dispatchTypes[(orderByOrderId || {}).dispatchStatus],
        vehicle: (orderByOrderId || {}).vehicleId,
        vehicleNo: (vehicleByVehicleId || {}).vehicleNo,
        driver1Name: (userByDriverId1 || {}).shortNameJa,
        driver2Name: (userByDriverId2 || {}).shortNameJa,
        driverId1: (orderByOrderId || {}).driverId1,
        driverId2: (orderByOrderId || {}).driverId2,
        customer: (customerByCustomerId || {}).nameJa,
        customerId: (orderByOrderId || {}).customerId,
        packaging1: (orderByOrderId || {}).packing1,
        packagingName: (packagingByPacking1 || {}).packagingName,
        packaging2: (orderByOrderId || {}).packing2,
        packageData: _.compact([(packagingByPacking1 || {}).packagingName, (orderByOrderId || {}).packing2]).join(', '),
        notes: (orderByOrderId || {}).notes,
        // notes2: (orderByOrderId || {}).notes2,
        distance: (orderByOrderId || {}).transportDistance ? `${`${(orderByOrderId || {}).transportDistance}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}km` : null,
        billing: (orderByOrderId || {}).transportBillingAmount ? `${`${(orderByOrderId || {}).transportBillingAmount}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}円` : null,
        payment: (orderByOrderId || {}).transportPaymentAmount ? `${`${(orderByOrderId || {}).transportPaymentAmount}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}円` : null,

        // PICKUP
        pickupAssignee: orders.pickupAssignee,
        pickupBorrower: orders.pickupBorrower,
        pickupContact: orders.pickupContact,
        pickupMemo: orders.pickupMemo,
        pickupSiteName: orders.pickupSiteName,
        pickupMaps: orders.pickupMemo ? orders.pickupMemo.split('\n') : [],

        // DROP OFF
        dropoffAssignee: orders.dropoffAssignee,
        dropoffBorrower: orders.dropoffBorrower,
        dropoffContact: orders.dropoffContact,
        dropoffMemo: orders.dropoffMemo,
        dropoffSiteName: orders.dropoffSiteName,
        dropoffMaps: orders.dropoffMemo ? orders.dropoffMemo.split('\n') : [],

        // origin details object
        origin: orders.origin,
        destination: orders.destination,
        pickupImages: orders.ordersPickupImagesByTransportId.nodes.map(({ imageNo, imageFile }) => ({ imageNo, imageFile })),
        dropoffImages: orders.ordersDropoffImagesByTransportId.nodes.map(({ imageNo, imageFile }) => ({ imageNo, imageFile })),
        departure_time: (orders.pickupActualDatetime !== null) ? formatDate(orders.pickupActualDatetime, 'dateTime') : formatDate(orders.pickupDate, 'date'),
        landing_time: (orders.dropoffActualDatetime !== null) ? formatDate(orders.dropoffActualDatetime, 'dateTime') : formatDate(orders.dropoffDate, 'date'),
      }
    });
    setLoading(false)
    tableSetter({ dataSource });
  };

  const rowSelection = {
    selectedRowKeys: selected.map(data => data.key),
    onSelect: (record, selected, selectedRows) => setSelected(selectedRows),
    type: 'radio'
  };

  const isModeAll = () => [modeTypes.ALL, modeTypes.ALL_REVERSE].indexOf(mode) >= 0;

  const modeProps = (value) => mode === value ? {} :
    { backgroundColor: '#F5F5F5', color: 'rgba(0, 0, 0, 0.40)', borderColor: '#D9D9D9' };

  const onButtonClick = (selectedMode) => () => {
    if (selected.length && (selectedMode === modeTypes.ORIGIN || selectedMode === modeTypes.DESTINATION)) {
      onOk(selected[0], mode, selectedMode);
      modalSetter({ visible: false, mode: null, transportIndex: null, defaults: {} });
      return
    }

    modalSetter({ mode: selectedMode })
  };

  const onSubmit = () => {
    if (!selected.length) return;
    isModeAll ? onOk(selected[0].orderId, mode) : onOk(selected[0], mode);
    modalSetter({ visible: false, mode: null, transportIndex: null, defaults: {} });
  };

  const formStyle = {
    float: 'left',
    width: 325,
    display: 'flex',
    alignItems: 'center',
    marginBottom: '0'
  }

  useEffect(() => {
    if (defaults) {
      // Set to form
      setFieldsValue({
        ...defaults
      })
    }
  }, [mode]);

  return (
    <Modal
      title={lt('orders_action_modal_title')}
      visible={true}
      onOK={onOk}
      onCancel={onCancel}
      width={'80%'}
      className='modal-vehicle'
      footer={[]}
      style={{ top: 20, paddingBottom: 0 }}
    >
      <div id="order_action_search_form">
        <Form {...formItemLayout}>
          <Row
            gutter={[8, 8]}
            style={{ paddingLeft: '3%', paddingRight: '3%' }}
          >
            <Col span={6} className='modal-datepicker' style={{ position: 'relative' }}>
              <Form.Item
                label={lt('orders_action_shipping_date')}
                style={{ ...formStyle }}
              >
                {getFieldDecorator('transportDateFrom')(
                  <DatePicker
                    format={dateFormat}
                    locale={jaJP}
                    style={{ width: 120 }}
                    showToday={false}
                    placeholder={null}
                  />
                )}
              </Form.Item>
              {/* <span>〜</span> */}
              <Form.Item
                label='〜'
                style={{ ...formStyle, width: 150, position: 'absolute', right: '-10px' }}
              >
                {getFieldDecorator('transportDateTo')(
                  <DatePicker
                    format={dateFormat}
                    locale={jaJP}
                    style={{ width: 120 }}
                    showToday={false}
                    placeholder={null}
                  />
                )}
              </Form.Item>
            </Col>

            <Col span={6}>
              <Form.Item
                label={lt('orders_action_car')}
                style={formStyle}
              >
                {getFieldDecorator('vehicleId')(
                  <Select
                    showSearch
                    allowClear
                    mode="multiple"
                    style={{ width: 215 }}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {(vehicleList || []).map(({ id, vehicleNo }) => (<Option key={id} value={id}>{vehicleNo}</Option>))}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label={lt('orders_action_customer_name')}
                style={formStyle}
              >
                {getFieldDecorator('customerName')(
                  <Input />
                )}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label={lt('orders_action_packing')}
                style={formStyle}
              >
                {getFieldDecorator('packagingName')(
                  <Input />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row
            gutter={[8, 8]}
            style={{ paddingLeft: '3%', paddingRight: '3%' }}
          >
            <Col span={6}>
              <Form.Item
                label={lt('orders_action_origin_destination_name')}
                style={formStyle}
              >
                {getFieldDecorator('origin_destination_name')(
                  <Input />
                )}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label={lt('orders_action_origin_borrower')}
                style={formStyle}
              >
                {getFieldDecorator('borrower')(
                  <Input />
                )}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label={lt('orders_action_origin_person_in_charge')}
                style={formStyle}
              >
                {getFieldDecorator('person_in_charge')(
                  <Input />
                )}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label={'連絡先'}
                style={formStyle}
              >
                {getFieldDecorator('contact')(
                  <Input />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row
            gutter={[8, 8]}
            style={{ paddingLeft: '3%', paddingRight: '3%' }}
          >
            <Col span={6}>
              <Form.Item
                label={'運転手'}
                style={formStyle}
              >
                {getFieldDecorator('driver')(
                  <Select
                    mode="multiple"
                    showSearch
                    allowClear
                    style={{ width: 215 }}
                  >
                    {(driverList || []).map(driver => <Option value={driver.id}>{driver.fullNameJa}</Option>)}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label={'メモ'}
                style={formStyle}
              >
                {getFieldDecorator('has_memo')(
                  <Select
                    showSearch
                    allowClear
                    style={{ width: 215 }}
                  >
                    <Option value={0}>  </Option>
                    <Option value={1}>あり</Option>
                    <Option value={2}>なし</Option>
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label={'画像'}
                style={formStyle}
              >
                {getFieldDecorator('has_image')(
                  <Select
                    showSearch
                    allowClear
                    style={{ width: 215 }}
                  >
                    <Option value={0}>  </Option>
                    <Option value={1}>あり</Option>
                    <Option value={2}>なし</Option>
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label={lt('orders_action_origin_remarks')}
                style={formStyle}
              >
                {getFieldDecorator('notes')(
                  <Input />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row
            gutter={[8, 8]}
            style={{ paddingLeft: '3%', paddingRight: '3%' }}
          >
            <Col span={6}>
              <Form.Item
                label={lt('orders_action_billing_payment_target')}
                style={formStyle}
              >
                {getFieldDecorator('billing_payment_target')(
                  <Select
                    showSearch
                    allowClear
                    style={{ width: 215 }}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    <Option value={lt('orders_action_billing_payment_target_transportation')}>{lt('orders_action_billing_payment_target_transportation')}</Option>
                    <Option value={lt('orders_action_billing_payment_target_induction')}>{lt('orders_action_billing_payment_target_induction')}</Option>
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label={lt('orders_action_claim')}
                style={formStyle}
              >
                {getFieldDecorator('claim')(
                  <Select
                    showSearch
                    allowClear
                    style={{ width: 215 }}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    <Option value={lt('orders_action_claim_pending_invoice')}>{lt('orders_action_claim_pending_invoice')}</Option>
                    <Option value={lt('orders_action_claim_paid_invoice')}>{lt('orders_action_claim_paid_invoice')}</Option>
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label={lt('orders_action_payment')}
                style={formStyle}
              >
                {getFieldDecorator('payment')(
                  <Select
                    showSearch
                    allowClear
                    style={{ width: 215 }}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    <Option value={lt('orders_action_payment_unpaid')}>{lt('orders_action_payment_unpaid')}</Option>
                    <Option value={lt('orders_action_payment_paid')}>{lt('orders_action_payment_paid')}</Option>
                  </Select>
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row
            gutter={[8, 8]}
            style={{ paddingLeft: '3%', paddingRight: '3%', marginBottom: '10px' }}
          >
            <Col
              span={24}
              style={{ textAlign: 'center' }}
            >
              <Button
                key=''
                type='primary'
                onClick={onSearchClick}
                loading={loading}
                style={{ marginRight: 10 }}
              >{lt('orders_action_button_search')}
              </Button>
              <Button
                key=''
                type='warning'
                onClick={() => resetFields()}
              >クリア
              </Button>
            </Col>
          </Row>
          <Row
            gutter={[8, 8]}
            style={{ paddingLeft: '3%', paddingRight: '3%' }}
          >
            <div id="order_action_search_form_table">
              <Table
                loading={loading}
                {...tableState}
                rowSelection={rowSelection}
                onRow={(record) => {
                  return {
                    onClick: () => setSelected([record])
                  }
                }}
              />
            </div>
          </Row>
          <hr />
          <Row
            gutter={[8, 8]}
            style={{ paddingLeft: '3%', paddingRight: '3%', marginTop: '10px' }}
          >
            <Col span={2}>
              <Button
                type='primary'
                style={{ width: '100%' }}
                disabled={isModeAll()}
                onClick={onButtonClick(modeTypes.ORIGIN)}
              >
                {lt('orders_action_button_origin')}
              </Button>
            </Col>
            <Col span={2}>
              <Button
                type='primary'
                style={{ width: '100%' }}
                disabled={isModeAll()}
                onClick={onButtonClick(modeTypes.DESTINATION)}
              >
                {lt('orders_action_button_landing')}
              </Button>
            </Col>
            <Col span={1} ></Col>
            <Col span={2}>
              <Button
                type='primary'
                style={{ width: '100%', ...modeProps(modeTypes.ALL_REVERSE) }}
                disabled={!isModeAll()}
                onClick={onButtonClick(modeTypes.ALL_REVERSE)}
              >
                {lt('orders_action_button_arrival_departure_reversal')}
              </Button>
            </Col>
            <Col span={2}>
              <Button
                type='primary'
                style={{ width: '100%', ...modeProps(modeTypes.ALL) }}
                disabled={!isModeAll()}
                onClick={onButtonClick(modeTypes.ALL)}
              >
                {lt('orders_action_button_no_inversion')}
              </Button>
            </Col>
            <Col span={1} ></Col>
            <Col span={1} ></Col>
            <Col span={2}>
              <Button
                key='submit'
                type='primary'
                disabled={mode === modeTypes.ORIGIN || mode === modeTypes.DESTINATION}
                onClick={onSubmit}
                style={{ width: '100%' }}
              >
                {lt('orders_action_button_configuration')}
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
      <MapModal
        {...viewState}
        onOk={(closeView)}
        onCancel={closeView}
      />
      {modal.visible &&
        <OrdersModal
          {...modal}
          onOk={() => modalEditSetter({ visible: false, id: null, mode: null, })}
          isDriver={isDriver}
          onCancel={() => modalEditSetter({ visible: false, id: null, mode: null })}
          handleRefetch={() => { }}
        />
      }
    </Modal>
  )
};

export default withApollo(Form.create()(SearchModal));