import { Button, DatePicker, Form, Input, InputNumber, Radio, Row, Select, Typography } from "antd";
import jaJP from 'antd/lib/date-picker/locale/ja_JP';
import { isImageUrl } from 'antd/lib/upload/utils';
import moment from 'moment';
import 'moment/locale/ja';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import ModalBase from '../../../../commons/ModalBase';
import CustomerModalConfig from '../../../../components/MasterMaintenance/Customer/ListModal';
import PackageModalConfig from '../../../../components/MasterMaintenance/Package/ListModal';
import DriverModalConfig from '../../../../components/MasterMaintenance/User/ListModal';
import { getUnitPrice } from '../../../../queries/Estimate/unitPrice';
import { FormContext, OrderContext } from '../../../../utils/context';
import adhocTranslator from '../../../../utils/locales/translate-adhoc';
import { formatTypes, vehicleTypes } from '../../constants';
import { convertHalfWidthToFullWidth, hasHalfWidthCharacter } from '../../functions';
import { GET_CONNECTED_ORDER, UPDATE_ORDER } from "../../queries";
import _ from 'lodash';

const lt = adhocTranslator('K020-010');

const { Option } = Select;

const dispatchOpts = [
  { label: '未配車', value: 'PENDING' },
  { label: '配車済', value: 'COMPLETED' },
];

const stayOpts = [
  { label: '宿泊無し', value: 'NO' },
  { label: '宿泊有り', value: 'YES' },
  { label: '未定', value: 'UNDECIDED' },
];

export const dayTypes = [
  { key: 0, value: 'SPECIFIED_DAY', label: '指定運搬日' },
  { key: 1, value: 'THIS_WEEK', label: '今週中' },
  { key: 2, value: 'NEXT_WEEK', label: '来週中' },
  { key: 3, value: 'THIS_MONTH', label: '今月中' },
  { key: 4, value: 'NEXT_MONTH', label: '来月中' },
];

const BasicInfoForm = (props) => {
  const { mode, submit, formData, dataList: { vehicleList }, isDriver, orderSetter } = useContext(OrderContext);
  const { form } = useContext(FormContext);
  const { getFieldDecorator, getFieldValue, setFieldsValue, setFields } = form;

  const [packingWeight, setPackingWeight] = useState(0)
  const [escortVehicleUnitPriceId, setEscortVehicleUnitPriceId] = useState(null);
  const [transportationUnitPriceId, setTransportationUnitPriceId] = useState(null);

  const onVehicleChange = (value) => {
    const { usersByVehicleNumber: { nodes } } = vehicleList.find(vehicle => vehicle.id === value);
    if (nodes.length) {
      const { id, fullNameJa } = nodes[0];
      setFieldsValue({ 'driverId1-display': fullNameJa, driverId1: id });
      updateFormDriverId(id);
    } else {
      setFieldsValue({ 'driverId1-display': undefined, driverId1: undefined });
      updateFormDriverId(null);
    }
  };

  const updateFormDriverId = (value) => {
    if (!isConnected) return;
    const key = formData.isFirst ? 'connectionDriverId1' : 'connectionDriverId2';
    formData[key] = value;
    formData.isSameDrivers = formData.connectionDriverId1 === formData.connectionDriverId2;
  };

  const isConnected = useMemo(() => {
    return !!formData.connectionId
  }, [formData]);

  useEffect(() => {
    if (formData.weight && formData.escortVehicleUnitPriceId && formData.transportationUnitPriceId) {
      setPackingWeight(formData.weight);
      setEscortVehicleUnitPriceId(formData.escortVehicleUnitPriceId);
      setTransportationUnitPriceId(formData.transportationUnitPriceId);
    }
  }, [formData.weight, formData.escortVehicleUnitPriceId, formData.transportationUnitPriceId]);

  const onBlur = async (distance, updateOrder = false) => {
    const transportPriceId = getFieldValue(`customerId-transportationUnitPriceId`);
    const weight = getFieldValue(`packing1-weight`); //check pack Weight
    let transportDistance
    let billAmount = '';
    if (!transportPriceId || !weight || !distance) return;

    if (!Number.isInteger(distance) && distance.includes(",")) {
      transportDistance = parseFloat(distance.replace(/,/g, ''))
    } else {
      transportDistance = parseFloat(distance)
    }

    let { data } = await props.client.mutate({
      mutation: getUnitPrice,
      variables: {
        id: parseInt(transportPriceId),
        weight: parseFloat(weight),
        distance: parseFloat(transportDistance)
      }
    });

    if (data && data.getUnitPrice.float) {
      billAmount = data.getUnitPrice.float;
    }
    if (updateOrder) {
      await props.client.mutate({
        mutation: UPDATE_ORDER,
        variables: {
          id: formData.id,
          patch: {
            transportBillingAmount: parseFloat(billAmount)
          }
        }
      })
      props.handleRefetch() // refetch table
    }

    // TODO: calculate total distance
    if (isConnected) {
      let totalDistance = parseFloat(transportDistance), totalBilling = null;
      const response = await props.client.query({
        query: GET_CONNECTED_ORDER,
        fetchPolicy: 'network-only',
        variables: {
          orderId: formData.connectedOrderId
        }
      });
      const [connectedOrder] = response.data.allOrders.nodes;
      const { customerByCustomerId, packagingByPacking1 } = connectedOrder;
      if ((customerByCustomerId || {}).transportationUnitPriceId === parseInt(transportPriceId)) {
        totalDistance = totalDistance + connectedOrder.transportDistance;
        const totalWeight = weight;
        const { data } = await props.client.mutate({
          mutation: getUnitPrice,
          variables: {
            id: parseFloat(transportPriceId),
            weight: parseFloat(totalWeight),
            distance: parseFloat(totalDistance)
          }
        });
        if (data && data.getUnitPrice.float) {
          // Set total billing and distance
          totalBilling = data.getUnitPrice.float;
        }
      }
      if (totalDistance) setFieldsValue({ totalDistance: totalDistance.toLocaleString() });
      if (totalBilling) setFieldsValue({ totalBilling: totalBilling.toLocaleString() });

      // SET FORM DATA
      // Calculate billing division
      if (totalBilling) {
        const billing1 = _.round((transportDistance / totalDistance) * totalBilling);
        const billing2 = _.round((connectedOrder.transportDistance / totalDistance) * totalBilling);
        formData.driver1Billing = formData.isFirst ? billing1 : billing2;
        formData.driver2Billing = formData.isFirst ? billing2 : billing1;
        formData.updateOther = formData.isFirst ? formData.driver2Billing : formData.driver1Billing;
        setFieldsValue({
          transportBillingAmount: formData.isFirst ?
            `${formData.driver1Billing}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') :
            `${formData.driver2Billing}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        });
      } else {
        formData.updateOther = null;
        formData.driver1Billing = null;
        formData.driver2Billing = null
      }
    } else {
      setFieldsValue({ transportBillingAmount: billAmount ? `${billAmount}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : billAmount })
    }
  }

  /**
   * check if transport distance is inputted from iPad
   * iPad does not support auto calculate upon input of transport distance
   * So we need it to update here update open of modal
   */
  useEffect(() => {
    (async () => {
      if (!packingWeight || !transportationUnitPriceId) return
      if (formData.transportDistance !== 0 && formData.transportBillingAmount === 0) {
        await onBlur(formData.transportDistance, true)
      }
    })()
  }, [
    formData.transportBillingAmount,
    formData.transportDistance,
    packingWeight,
    transportationUnitPriceId
  ])

  const convertFullWidthOnBlur = (field, value) => {
    if (value) {
      setFieldsValue({ [field]: convertHalfWidthToFullWidth(value) })
    }
  }

  const onChangeTransportDateOption = (value) => {
    if (value !== 'SPECIFIED_DAY') {
      // Clear transport date data 
      orderSetter({ transportDate: null });
      setFieldsValue({
        transportDate: null,
      });
    }
  };

  const onChangeTransportDateData = (value) => {
    let dispatchStatus = value.target ? value.target.value : getFieldValue('dispatchStatus');

    let errors = null;
    let initialValue = getFieldValue('transportDate');
    if (typeof (initialValue) === "undefined" || initialValue === null) {
      try {
        initialValue = value.target ? value.target.transportDate ? value.target.transportDate : null : null;
      } catch (error) {
        //console.log("Warning", error);
      }
    }
    if (initialValue === null &&
      dispatchStatus === 'COMPLETED') {
      errors = [new Error('運搬日を入力してください。')];
    }

    setFields({
      transportDate: {
        value: initialValue,
        errors,
      }
    })
  };

  const onChangeTransportDate = async (value) => {
    // const dateValue = value ? value : null;
    orderSetter({ transportDate: value });
    setFieldsValue({ transportDateOption: 'SPECIFIED_DAY' })
  };

  const onNumberBlur = (name) => (event, callback) => {
    let { value } = event.target;
    let dataValue = null;
    if (!value || value !== '') {
      value = value.replace(/,/g, '');
      const reg = /^[0-9\b]+$/;
      dataValue = reg.test(value) ? `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : null;
    };
    setFieldsValue({ [name]: dataValue });

    if (dataValue && callback) {
      callback(dataValue);
    }
  }

  const onNumberFocus = (name) => (event) => {
    let { value } = event.target;
    setFieldsValue({ [name]: value.replace(/,/g, '') });
  }

  const onBillingChange = async (value) => {
    const amount = value ? parseFloat(value.replace(/,/g, '')) : null;
    let transportDistance = getFieldValue('transportDistance');
    let totalDistance = getFieldValue('totalDistance');

    totalDistance = !_.isEmpty(totalDistance) ? parseFloat(totalDistance.replace(/,/g, '')) : null;
    transportDistance = !_.isEmpty(transportDistance) ? parseFloat(transportDistance.replace(/,/g, '')) : null;
    if (!totalDistance || !transportDistance || !amount) return;
    const response = await props.client.query({
      query: GET_CONNECTED_ORDER,
      fetchPolicy: 'network-only',
      variables: {
        orderId: formData.connectedOrderId
      }
    });
    const [connectedOrder] = response.data.allOrders.nodes;

    // Calculate
    const firstBilling = _.round(transportDistance / totalDistance * amount)
    const secondBilling = _.round(connectedOrder.transportDistance / totalDistance * amount);
    setFieldsValue({
      transportBillingAmount: firstBilling.toLocaleString()
    })
    // Calculate billing division
    if (amount) {
      formData.updateOther = secondBilling;
      formData.driver1Billing = formData.isFirst ? firstBilling : secondBilling;
      formData.driver2Billing = formData.isFirst ? secondBilling : firstBilling;
    } else {
      formData.updateOther = null
      formData.driver1Billing = null;
      formData.driver2Billing = null
    }
  };

  const onTransportBillingChange = async (value) => {
    const amount = value ? parseFloat(value.replace(/,/g, '')) : null;
    let totalBilling = getFieldValue('totalBilling') || formData.totalBilling;

    const baseBilling = parseFloat(totalBilling.replace(/,/g, ''))
    const secondBilling = _.round(baseBilling - amount);

    // Calculate billing division
    if (secondBilling !== null) {
      formData.updateOther = secondBilling;
      formData.driver1Billing = formData.isFirst ? amount : secondBilling;
      formData.driver2Billing = formData.isFirst ? secondBilling : amount;
    } else {
      formData.updateOther = null
      formData.driver1Billing = null;
      formData.driver2Billing = null
    }
  }

  const onDistanceChange = (event) => {
    const { value } = event.target;
    if (!formData.transportDistance) {
      orderSetter({ transportDistance: value })
    }
  }

  return (
    <Row className='basic-form'>
      <Row style={styles.headerStyle}>
        <Typography style={{ fontWeight: 'bold' }}>基本情報</Typography>
      </Row>
      <Row style={styles.formStyle}>
        <Row type='flex' style={styles.rowStyle}>
          <Form.Item style={styles.formItem} label='受注日'>
            {getFieldDecorator('orderDate', {
              preserve: false,
              initialValue: formData.orderDate || moment(),
              rules: [
                { required: true, message: '受注日を選択してください。' }
              ]
            })(
              <DatePicker
                format={formatTypes.DATE}
                locale={jaJP}
                placeholder={lt('txt_ｆrom_date')}
                size='default'
                mode='date'
                style={{ width: 200, display: 'inline-block', marginRight: 15 }}
                disabled={isDriver}
              />
            )}
          </Form.Item>
          <Button
            style={{
              marginTop: 4, background: '#2296F3', color: '#fff'
            }}
            disabled={isDriver}
            onClick={() => {
              form.setFieldsValue({
                orderDate: moment()
              })
            }}
          >
            今日
          </Button>
          {isConnected && (
            <Form.Item style={styles.formItem} label='連結番号'>
              {getFieldDecorator('connectionId', {
                preserve: false,
                initialValue: formData.connectionId,
              })(
                <Input
                  style={{ width: 200 }}
                  disabled={true}
                />
              )}
            </Form.Item>
          )}
        </Row>
        <Row type='flex' style={styles.rowStyle}>
          <Form.Item style={styles.formItem} label='運搬日'>
            {getFieldDecorator('transportDate', {
              initialValue: formData.transportDate ? formData.transportDate : null,
              rules: [{
                required: getFieldValue('dispatchStatus') === 'COMPLETED' ? true : getFieldValue('transportDateOption') === 'SPECIFIED_DAY' ? true : false,
                message: '運搬日を入力してください。'
              }]
            })(
              <DatePicker
                format={formatTypes.DATE}
                locale={jaJP}
                placeholder={lt('txt_ｆrom_date')}
                size='default'
                mode='date'
                style={{ width: 200, display: 'inline-block', marginRight: 15 }}
                disabled={isDriver}
                onChange={onChangeTransportDate}
              />
            )}
          </Form.Item>
          <Form.Item style={styles.formItem}>
            {getFieldDecorator('transportDateOption', {
              initialValue: formData.transportDateOption || 'SPECIFIED_DAY'
            })(
              <Select
                style={{ width: 150, marginRight: 15 }}
                disabled={isDriver}
                onChange={(value) => {
                  onChangeTransportDateData(value);
                  onChangeTransportDateOption(value);
                }}
              >
                {dayTypes.map(day => (
                  <Option key={day.key} value={day.value}>{day.label}</Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Row>

        <Form.Item style={styles.formItem} label='配車状態'>
          {getFieldDecorator('dispatchStatus', {
            initialValue: !formData.transportDate ? 'PENDING' : formData.dispatchStatus,
            rules: [
              { required: true, message: '配車状態を選択してください。' }
            ]
          })(
            <Radio.Group style={{}} options={dispatchOpts} disabled={isDriver} onChange={onChangeTransportDateData} />
          )}
        </Form.Item>

        <Row type='flex' style={styles.rowStyle}>
          <Form.Item style={styles.formItem} label='号車'>
            {getFieldDecorator('vehicleId', {
              initialValue: formData.vehicleId,
              rules: [{
                required: (getFieldValue('dispatchStatus') || formData.dispatchStatus) === 'COMPLETED' ? true : false,
                message: lt('号車を選択してください。'),
              }],
            })(
              <Select
                style={{ width: 200, marginRight: 20 }}
                onChange={onVehicleChange}
                disabled={isDriver}
              >
                {vehicleList
                  .filter(({ vehicleType }) =>
                    vehicleType !== vehicleTypes.ESCORT_VEHICLE_COMPANY &&
                    vehicleType !== vehicleTypes.ESCORT_VEHICLE_OUTSOURCE
                  )
                  .map(({ id, vehicleNo }) => (
                    <Option key={id} value={id}>{vehicleNo}</Option>
                  ))}
              </Select>
            )}
          </Form.Item>

          {useMemo(() => (
            <ModalBase
              multiple={false}
              title='運転手情報検索'
              form={form}
              label='運転手'
              mode={mode}
              btnLabel='選択'
              fieldName='driverId1'
              config={DriverModalConfig({ authority: 4 })}
              labelWidth={5}
              className='orderConfigModal'
              modalClass='basic-form-modal'
              width={20}
              buttonMarginRight={20}
              afterSelect={(value) => updateFormDriverId(value.id)}
              decorator={{
                rules: [{
                  required: (getFieldValue('dispatchStatus') || formData.dispatchStatus) === 'COMPLETED' ? true : false,
                  message: lt('運転手を選択してください。'),
                }],
                initialValue: formData.driverId1 && formData.driver1Name
              }}
              hiddenDecorator={{
                initialValue: formData.driverId1
              }}
              // selectedDriver={state.selectedDriver}
              customFilterByDate={
                getFieldValue('transportDate') === null ?
                  moment().format('YYYY/MM/DD') :
                  moment(getFieldValue('transportDate')).format('YYYY/MM/DD')
              }
              isDriver={isDriver}
            />
          ), [submit, isDriver, formData.dispatchStatus, formData.driverId1])}
          {useMemo(() => (
            <ModalBase
              multiple={false}
              title='運転手情報検索'
              form={form}
              mode={mode}
              btnLabel='選択'
              fieldName='driverId2'
              labelWidth={5}
              width={20}
              config={DriverModalConfig({ authority: 4 })}
              className='orderConfigModal'
              modalClass='basic-form-modal w-300'
              buttonMarginRight={0}
              decorator={{
                initialValue: formData.driverId2 && formData.driver2Name
              }}
              hiddenDecorator={{
                initialValue: formData.driverId2
              }}
              customFilterByDate={
                getFieldValue('transport_date') === null ?
                  moment().format('YYYY/MM/DD') :
                  moment(getFieldValue('transport_date')).format('YYYY/MM/DD')
              }
              isDriver={isDriver}
            />
          ), [submit, isDriver, formData.driverId2])}
        </Row>
        <Row type='flex' style={styles.rowStyle}>
          {useMemo(() => (
            <ModalBase
              multiple={false}
              title='顧客情報検索'
              form={form}
              label='顧客名'
              mode={mode}
              btnLabel='選択'
              fieldName='customerId'
              modalClass='basic-form-modal'
              dropdownClassName='customer-modal'
              width={20}
              afterSelect={(e) => {
                setEscortVehicleUnitPriceId(e.escortVehicleUnitPriceId)
                setTransportationUnitPriceId(e.transportationUnitPriceId)
              }}
              transportationUnitPriceId={transportationUnitPriceId}
              escortVehicleUnitPriceId={escortVehicleUnitPriceId}
              config={CustomerModalConfig()}
              decorator={{
                rules: [{
                  required: true,
                  message: lt('顧客名を選択してください。'),
                }],
                initialValue: formData.customerName
              }}
              hiddenDecorator={{
                initialValue: formData.customerId
              }}
              labelWidth={3}
              filterStatus
              isDriver={isDriver || isConnected}
            />
          ), [submit, isDriver, formData.customerId, transportationUnitPriceId, escortVehicleUnitPriceId])}
        </Row>

        <Row type='flex' style={styles.rowStyle}>
          {useMemo(() => (
            <ModalBase
              multiple={false}
              title='荷姿情報検索'
              form={form}
              mode={mode}
              label='荷姿 (請求用)'
              btnLabel='選択'
              fieldName='packing1'
              modalClass='basic-form-modal'
              dropdownClassName='package-modal'
              rowField={false}
              labelWidth={3}
              width={20}
              config={PackageModalConfig()}
              afterSelect={(e) => {
                setPackingWeight(e.weight)
              }}
              packingWeight={packingWeight}
              decorator={{
                rules: [{
                  required: true,
                  message: lt('荷姿(請求用)を選択してください。'),
                }],
                initialValue: formData.packing1 && formData.packagingName
              }}
              hiddenDecorator={{
                initialValue: formData.packing1
              }}
              filterStatusPackage
              isDriver={isDriver || isConnected}
            />
           ), [submit, isDriver, formData.packing1, packingWeight])}

          <Form.Item style={styles.formItem} label='荷姿 (全体)'>
            {getFieldDecorator('packing2', {
              initialValue: formData.packing2 ? convertHalfWidthToFullWidth(formData.packing2) : null,
              rules: [{
                validator: async (rule, value) => {
                  if (value && hasHalfWidthCharacter(value)) {
                    throw new Error('半角文字が検出されました。');
                  }
                }
              }],
            })(
              <Input
                style={{ width: 415 }}
                disabled={isDriver}
                onBlur={(e) => convertFullWidthOnBlur('packing2', e.target.value)}
              />
            )}
          </Form.Item>
        </Row>

        <Form.Item style={styles.formItem} label='宿泊有無'>
          {getFieldDecorator('overnight', {
            initialValue: formData.overnight || 'NO'
          })(
            <Radio.Group style={{}} options={stayOpts} disabled={isDriver} />
          )}
        </Form.Item>

        <Row type='flex' style={styles.rowStyle}>
          <Form.Item style={styles.formItem} label='備考'>
            {getFieldDecorator('notes', {
              initialValue: formData.notes,
            })(
              <Input
                style={{ width: 515 }}
                disabled={isDriver}
              />
            )}
          </Form.Item>
          {/* <Form.Item style={styles.formItem} label='備考２'>
            {getFieldDecorator('notes2', {
              initialValue: formData.notes2,
            })(
              <Input
                style={{ width: 535 }}
                disabled={isDriver}
              />
            )}
          </Form.Item> */}
        </Row>

        {isConnected ? (
          <>
            <Row type='flex' style={styles.rowStyle}>
              <Form.Item style={styles.formItem} label='運搬距離'>
                {getFieldDecorator('transportDistance', {
                  initialValue: formData.transportDistance || null,
                })(
                  <Input
                    style={{
                      width: 160,
                      verticalAlign: 'middle', borderBottomRightRadius: 0, borderTopRightRadius: 0
                    }}
                    onBlur={(e) => {
                      onDistanceChange(e);
                      onNumberBlur('transportDistance')(e, (distance) => {
                        onBlur(distance);
                      });
                    }}
                    onFocus={onNumberFocus('transportDistance')}
                    disabled={isDriver}
                    maxLength={7}
                  />
                )}
                <div className="ant-input-group-addon"
                  style={{ paddingTop: '2px', verticalAlign: 'middle', display: 'inline-table', lineHeight: '24px', height: '32px' }}>
                  <Typography>km</Typography>
                </div>
              </Form.Item>

              <Form.Item style={styles.formItem} label='運搬費'>
                {getFieldDecorator('transportBillingAmount', {
                  initialValue: formData.transportBillingAmount || null,
                })(
                  <Input
                    style={{
                      width: 160,
                      verticalAlign: 'middle', borderBottomRightRadius: 0, borderTopRightRadius: 0
                    }}
                    onBlur={(e) => {
                      onNumberBlur('transportBillingAmount')(e, (distance) => {
                        onTransportBillingChange(distance);
                      })
                    }}
                    onFocus={onNumberFocus('transportBillingAmount')}
                    disabled={isDriver}
                    maxLength={7}
                  />
                )}
                <div className="ant-input-group-addon"
                  style={{ paddingTop: '2px', verticalAlign: 'middle', display: 'inline-table', lineHeight: '24px', height: '32px' }}>
                  <Typography>円</Typography>
                </div>
              </Form.Item>
            </Row>
            <Row type='flex' style={styles.rowStyle}>
              <Form.Item style={styles.formItem} label='請求距離'>
                {getFieldDecorator('totalDistance', {
                  initialValue: formData.totalDistance || null,
                })(
                  <Input
                    style={{
                      width: 160,
                      verticalAlign: 'middle', borderBottomRightRadius: 0, borderTopRightRadius: 0
                    }}
                    onBlur={onNumberBlur('totalDistance')}
                    onFocus={onNumberFocus('totalDistance')}
                    disabled={true}
                    maxLength={7}
                  />
                )}
                <div className="ant-input-group-addon"
                  style={{ paddingTop: '2px', verticalAlign: 'middle', display: 'inline-table', lineHeight: '24px', height: '32px' }}>
                  <Typography>km</Typography>
                </div>
              </Form.Item>

              <Form.Item style={styles.formItem} label='請求額'>
                {getFieldDecorator('totalBilling', {
                  initialValue: formData.totalBilling || null,
                })(
                  <Input
                    style={{
                      width: 160,
                      verticalAlign: 'middle', borderBottomRightRadius: 0, borderTopRightRadius: 0
                    }}
                    onBlur={(e) => {
                      onNumberBlur('totalBilling')(e, (distance) => {
                        onBillingChange(distance);
                      })
                    }}
                    onFocus={onNumberFocus('totalBilling')}
                    disabled={isDriver}
                    maxLength={7}
                  />
                )}
                <div className="ant-input-group-addon"
                  style={{ paddingTop: '2px', verticalAlign: 'middle', display: 'inline-table', lineHeight: '24px', height: '32px' }}>
                  <Typography>円</Typography>
                </div>
              </Form.Item>

              <Form.Item style={styles.formItem} label='支払額'>
                {getFieldDecorator('transportPaymentAmount', {
                  initialValue: formData.transportPaymentAmount || null,
                })(
                  <Input
                    style={{
                      width: 160,
                      verticalAlign: 'middle', borderBottomRightRadius: 0, borderTopRightRadius: 0
                    }}
                    onFocus={onNumberFocus('transportPaymentAmount')}
                    onBlur={onNumberBlur('transportPaymentAmount')}
                    disabled={isDriver}
                    maxLength={7}
                  />
                )}
                <div className="ant-input-group-addon"
                  style={{ paddingTop: '2px', verticalAlign: 'middle', display: 'inline-table', lineHeight: '24px', height: '32px' }}>
                  <Typography>円</Typography>
                </div>
              </Form.Item>
            </Row>
          </>
        ) : (
          <Row type='flex' style={styles.rowStyle}>
            <Form.Item style={styles.formItem} label='距離'>
              {getFieldDecorator('transportDistance', {
                initialValue: formData.transportDistance || null,
              })(
                <Input
                  style={{
                    width: 160,
                    verticalAlign: 'middle', borderBottomRightRadius: 0, borderTopRightRadius: 0
                  }}
                  onBlur={(e) => {
                    onDistanceChange(e);
                    onNumberBlur('transportDistance')(e, (distance) => {
                      onBlur(distance);
                    });
                  }}
                  onFocus={onNumberFocus('transportDistance')}
                  disabled={isDriver}
                  maxLength={7}
                />
              )}
              <div className="ant-input-group-addon"
                style={{ paddingTop: '2px', verticalAlign: 'middle', display: 'inline-table', lineHeight: '24px', height: '32px' }}>
                <Typography>km</Typography>
              </div>
            </Form.Item>

            <Form.Item style={styles.formItem} label='請求額'>
              {getFieldDecorator('transportBillingAmount', {
                initialValue: formData.transportBillingAmount || null,
              })(
                <Input
                  style={{
                    width: 160,
                    verticalAlign: 'middle', borderBottomRightRadius: 0, borderTopRightRadius: 0
                  }}
                  onBlur={onNumberBlur('transportBillingAmount')}
                  onFocus={onNumberFocus('transportBillingAmount')}
                  disabled={isDriver}
                  maxLength={7}
                />
              )}
              <div className="ant-input-group-addon"
                style={{ paddingTop: '2px', verticalAlign: 'middle', display: 'inline-table', lineHeight: '24px', height: '32px' }}>
                <Typography>円</Typography>
              </div>
            </Form.Item>

            <Form.Item style={styles.formItem} label='支払額'>
              {getFieldDecorator('transportPaymentAmount', {
                initialValue: formData.transportPaymentAmount || null,
              })(
                <Input
                  style={{
                    width: 160,
                    verticalAlign: 'middle', borderBottomRightRadius: 0, borderTopRightRadius: 0
                  }}
                  onFocus={onNumberFocus('transportPaymentAmount')}
                  onBlur={onNumberBlur('transportPaymentAmount')}
                  disabled={isDriver}
                  maxLength={7}
                />
              )}
              <div className="ant-input-group-addon"
                style={{ paddingTop: '2px', verticalAlign: 'middle', display: 'inline-table', lineHeight: '24px', height: '32px' }}>
                <Typography>円</Typography>
              </div>
            </Form.Item>
          </Row>
        )}
      </Row>
    </Row>
  )
};

const styles = {
  headerStyle: {
    border: '1px solid rgba(0, 0, 0, 0.2)',
    padding: 5,
    height: 45,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 15
  },
  formStyle: {
    padding: 20,
    border: '1px solid rgba(0, 0, 0, 0.2)',
    borderTopWidth: 0
  },
  formItem: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 8,
    height: 39
  },
  rowStyle: {
    height: 39,
    marginBottom: 8,
  }
}
export default BasicInfoForm;