import React, {Fragment, useState, forwardRef, useImperativeHandle, useContext } from 'react';
import {Button, Modal} from "antd";
import TransportationDetailsConfig from '../components/Estimate/TransportationDetailsConfig';

import AEDBase from './AEDBase';
import { EstimateContext } from '../components/Estimate/Context';

/** Get ADhoc translate **/
import adhocTranslator from '../utils/locales/translate-adhoc';

const AEModalBase = forwardRef((props, ref) =>  {
    let submitHandler = {};
    const [show, handleShow] = useState(false);

    const {estimatesState, dispatch} = useContext(EstimateContext);

    const popupModal = () => {
        dispatch({
            type: 'mode',
            mode: 'add'
        })
        handleShow(true);
        props.modeChange('add');

    };

    useImperativeHandle(ref, () => ({
        popupModal() {
            handleShow(true);
            props.modeChange('add');
        }

    }));

    const handleCancel = () => {
        handleShow(false)
    };
    
    const onData = (variables) => {
        props.onData(variables);
        props.resetBtnTbl(true)
        handleShow(false);
    };

    const attachHandleSubmit = (handleSubmit) => {
        submitHandler.handleSubmit = handleSubmit
    };

    const handleOk = e => {
        submitHandler.handleSubmit({
            preventDefault : () => {}
        });
    }

    let {
        form,
        title,
        mode = 'add',
        btnTblAction,
        mapWidth = 820,
    } = props;
    let lt = adhocTranslator('K050-020');
    return (
        <Fragment>
            <Modal
                width={mapWidth}
                title={title}
                visible={show}
                destroyOnClose={true}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={[
                    <Button disabled={mode === 'delete' ? true : false} key="submit" type="primary" onClick={handleOk}>
                       {btnTblAction === 'delete' ? '削除' : '設定' }
                    </Button>,
                    <Button disabled={mode === 'delete' ? true : false} key="back" onClick={handleCancel}>戻る</Button>,
                ]}
            >
            
            <AEDBase config={TransportationDetailsConfig({
                mode: btnTblAction ? btnTblAction : 'add',
                modal:{
                    handleOk: attachHandleSubmit,
                    onData,
                    form
                },
                form
            })}/>
            </Modal>
                <div style={{textAlign:'right',marginBottom:25}}>
                    <Button disabled={(estimatesState.filters.transportId && mode === 'edit') || mode === 'add' ? false : true} type="primary" onClick={popupModal}>{lt('btn_add_transportation_destination')}</Button>
                </div>
        </Fragment>
    );
});

export default AEModalBase;
