import { Col, Form, Icon, notification, Row, Upload } from 'antd';
import _ from 'lodash';
import Papa from 'papaparse';
import React from 'react';
/** Get All Common  **/
import {
    CustomButton,
    CustomHiddenInput, CustomInput,
    CustomSelect
} from '../../../commons/CustomDataEntry';
/** Get All Package Queries **/
import {
    create,
    deleteById, getAll, getById,
    updateById
} from '../../../queries/MasterMaintenance/unitprice';
/** Get ADhoc translate **/
import adhocTranslator from '../../../utils/locales/translate-adhoc';
/** Get user_id stored in LocalStorage **/
import decodeToken from '../../../utils/user_id';

const Dragger = Upload.Dragger;

export default ({ mode }) => {
    /** Initialize translators **/
    let lt = adhocTranslator('K908-020');
    let ltV = adhocTranslator('K908-010');

    notification.config({
        duration: 3
    })

    return {
        /** Assign mode **/
        mode,

        /** Redirect config **/
        redirect: '/unit-price/search-list',

        /** query mapping **/
        data: {
            idRef: 'unitPriceById'
        },
        /** Map queries **/
        queries: {
            GET_BY_ID: getById,
            GET_ALL: getAll,
            CREATE: create,
            UPDATE_BY_ID: updateById,
            DELETE_BY_ID: deleteById
        },

        /** messages */
        messages: {
            add: "単価情報を登録しますか？",
            edit: "単価情報を更新しますか？",
            delete: "単価情報を削除しますか？",
            reg_success_message: lt("reg_success_message"),
            reg_error_message: lt("reg_error_message"),
            edit_success_message: lt("edit_success_message"),
            edit_failure_message: lt("edit_failure_message"),
            delete_success_message: lt("delete_success_message"),
            delete_failure_message: lt("delete_failure_message")
        },

        methods: {
            downloadCSV: (data, fileName) => {
                if (data && data.nodes) {
                    if (!fileName) {
                        fileName = "download.csv";
                    }
                    let datas = _.map(data.nodes, row => {
                        return {
                            "Unit Price ID": row.unitPriceId,
                            "Weight": row.weight,
                            "Transport Distance": row.transportDistance,
                            "Price": row.price
                        };
                    });
                    datas = _.sortBy(datas, ['Transport Distance', 'Weight']);
                    const csv = Papa.unparse(datas);
                    const csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
                    if (window.navigator.msSaveOrOpenBlob) {
                        window.navigator.msSaveBlob(csvData, fileName);
                    } else {
                        let a = window.document.createElement("a");
                        a.href = window.URL.createObjectURL(csvData, { type: "text/plain" });
                        a.download = fileName;
                        a.click()
                    }
                }
            }
        },


        /** Create common component **/
        component: ({ form, data, handleSubmit, loading, methods }) => {

            const radioPC = [
                { id: 'FARE', value: ltV('lbl_transportation_fare') },
                { id: 'ESCORT_VEHICLE_FARE', value: ltV('lbl_escort_vehicle_fare') }
            ];

            const radioFC = [
                { id: 'AS_IT_IS', value: '変更なし' },
                { id: 'ROUND_UP', value: '切り上げ' },
                { id: 'ROUND_DOWN', value: '切り拾て' },
                { id: 'ROUND_OFF', value: '四拾五入' }
            ];
            const openNotificationWithIcon = (type, title) => {
                notification[type]({
                    message: title,
                    description: '',
                });
            };
            const propsCSV = {
                name: "file",
                openFileDialogOnClick: true,
                showUploadList: true,
                multiple: false,
                accept: '.csv',
                beforeUpload: (file) => {
                    if (!file.size) {
                        openNotificationWithIcon('error', '空のCSVファイルは取込むことはできません。');
                        return false;
                    }
                    return true;
                },
                onRemove: (file) => {
                    if (file.status === 'removed') {
                        file = null;
                        form.setFieldsValue({
                            csvFile: null,
                            unitPriceFileName: null
                        });
                    }
                },
                onChange: (e) => {
                    // to delete undefined list doesnt work without setTimeOut
                    setTimeout(function () {
                        let element = document.getElementsByClassName('ant-upload-list-item ant-upload-list-item-undefined');
                        if (element.length > 0) {
                            element[0].remove()
                        }
                    }, 1);
                    if (e.file.status === 'done' || e.file.status === 'error') {
                        Papa.parse(e.file.originFileObj, {
                            complete: function (results) {
                                let estimateDetails = [];
                                // To remove header row
                                results.data.shift();

                                _.map(results.data, (data) => {
                                    // To remove unit price id column values
                                    data.shift();
                                    return data;
                                }).forEach(csvRow => {
                                    let field = mode === 'add' ? 'createdUserId' : 'updatedUserId';
                                    estimateDetails.push({
                                        [field]: decodeToken().user_id,
                                        weight: +csvRow[0],
                                        transportDistance: +csvRow[1],
                                        price: +csvRow[2]
                                    });
                                });
                                form.setFieldsValue({
                                    csvFile: estimateDetails,
                                    unitPriceFileName: e.file.name
                                });
                            }
                        });
                    }
                },
            };


            let btnLabel;
            if (mode === 'edit') {
                btnLabel = lt('btn_edit')
            } else if (mode === 'delete') {
                btnLabel = lt('btn_delete')
            } else {
                btnLabel = lt('btn_register')
            }

            return (
                <Row type='flex' className='h100' justify='space-around'>
                    <Col span={12}>
                        <Form onSubmit={handleSubmit} layout='vertical' >
                            <CustomHiddenInput
                                fieldName={mode === 'add' ? 'createdUserId' : 'updatedUserId'}
                                form={form}
                                decorator={{
                                    initialValue: decodeToken().user_id
                                }}
                            />
                            <CustomInput
                                autoFocus={true}
                                form={form}
                                fieldName='unitPriceName'
                                label={lt('lbl_unit_price_chart_name')}
                                placeholder={lt('txt_unit_price_chart_name')}
                                type='text'
                                mode={mode}
                                offset={1}
                                width={18}
                                maxLength={255}
                                decorator={{
                                    rules: [{
                                        required: true,
                                        message: lt('txt_unit_price_chart_name'),
                                    }],
                                    initialValue: data.unitPriceName
                                }} />

                            <CustomSelect
                                form={form}
                                mode={mode}
                                options={radioPC}
                                fieldName='unitPriceCategory'
                                label={lt('lbl_unit_price_category')}
                                placeholder={lt('lst_unit_price_category')}
                                decorator={{
                                    rules: [{
                                        required: true,
                                        message: lt('lst_unit_price_category'),
                                    }],
                                    initialValue: data.unitPriceCategory
                                }}
                                offset={1}
                                width={12}
                            />
                            <CustomSelect
                                form={form}
                                mode={mode}
                                options={radioFC}
                                fieldName='fractionCategory'
                                label={lt('lbl_fraction_category')}
                                placeholder={lt('請求距離の端数処理を選択してください。')}
                                decorator={{
                                    rules: [{
                                        required: true,
                                        message: lt('lst_fraction_category'),
                                    }],
                                    initialValue: data.fractionCategory
                                }}
                                offset={1}
                                width={12}
                            />
                            <CustomHiddenInput fieldName='csvFile' form={form} />
                            <CustomHiddenInput fieldName='unitPriceConfig' form={form} decorator={{ initialValue: true }} />
                            <CustomHiddenInput fieldName='unitPriceFileName' form={form} decorator={{ initialValue: data.unitPriceFileName ? data.unitPriceFileName : "" }} />
                            <CustomHiddenInput fieldName='details' form={form} decorator={{ initialValue: data.unitPricesDetailsByUnitPriceId ? data.unitPricesDetailsByUnitPriceId.nodes : "" }} />
                            <CustomHiddenInput form={form} fieldName='unitPriceConfig' decorator={{ initialValue: true }} />

                            <div className="ant-row ant-form-item-label custom-upload">
                                <label style={{ display: 'block', float: 'left' }}>
                                    <span style={{ color: '#ea4d4d', 'fontMozTabSize': '100px' }}>* </span>ファイル名
                                </label>
                                <Dragger
                                    disabled={mode === 'delete' ? true : false}
                                    action="//jsonplaceholder.typicode.com/posts/"
                                    fieldName='fileCSV' {...propsCSV}>
                                    <p className="ant-upload-drag-icon">
                                        <Icon type="inbox" />
                                    </p>
                                    <p>{lt('btn_import_csv')}</p>
                                </Dragger>
                                <CustomButton label={lt('btn_download_csv')}
                                    mode={mode}
                                    loading={loading}
                                    onClick={() => {
                                        methods.downloadCSV(data.unitPricesDetailsByUnitPriceId, data.unitPriceFileName);
                                    }}
                                    style={{ float: 'right' }}
                                    offset={20} />
                            </div>

                            <CustomButton label={btnLabel}
                                event={handleSubmit}
                                loading={loading}
                                style={{ marginTop: '15px', height: '40px', width: '104px' }}
                                htmlType="submit"
                                offset={5} />
                        </Form>

                    </Col>
                    <Col span={12} />
                </Row>
            );
        }

    }
}