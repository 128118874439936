import { gql } from 'apollo-boost';

const loginMutation = gql`
  mutation authenticate($loginId: String!, $password: String!){
    authenticate(input: {loginId: $loginId, password: $password}) {
      jwtToken
    }
  }
`;

const getDriverRole = gql`
  query getDriverRole($user_id: Int) {
    allRoles(filter: {userByUserId: {id: {equalTo: $user_id}}, role: {equalTo: DRIVER_COMPANY_}}) {
      nodes {
        id
        role
      }
    }
  }
`;

export { loginMutation, getDriverRole };