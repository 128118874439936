import React, { Component, Fragment } from 'react';
import TableToExcel from '../../utils/table-to-excel/tableToExcel';
import { withApollo } from 'react-apollo';
import moment from 'moment';
import _ from 'lodash';

const invoiceType = `
    excelInvoiceOutputData {
        id
        customerName
        companyName
        quotationAmount
        validity
        transportDate
        rows {
            transportDate
            por
            packaging
            distance
            escortCount
            escortFare
            transportFare
            amount
            notes
        }
        subtotal
        taxRate
        tax
        totalamount
    }
`;

class InvoiceExcel extends Component {

	constructor(props) {
		super(props);
		this.excel = {}
	};
	zeroPad = (num, places) => {
		let zero = places - num.toString().length + 1;
		return Array(+(zero > 0 && zero)).join("0") + num;
	}

	createTableRows = (record) => {
		const result = record.map((detail, i) => {
			let rows = []
			const [origin, destination] = detail.por.split('|');
			let transportRow = (
				<tr data-height="23.5" key={i}>
					<td></td>
					<td data-b-l-s="thin" data-b-b-s="thin" data-a-v="middle" data-a-h="center">{detail.transportDate}</td>
					<td data-b-l-s="thin" data-b-b-s="thin" data-a-v="middle" data-a-wrap={true} data-a-h='center' dangerouslySetInnerHTML={{ __html: origin }} />
					<td data-b-l-s="thin" data-b-b-s="thin" data-a-v="middle" data-a-h="center">～</td>
					<td data-b-l-s="thin" data-b-b-s="thin" data-a-v="middle" data-a-wrap={true} data-a-h='center' dangerouslySetInnerHTML={{ __html: destination }} />
					<td data-b-l-s="thin" data-b-b-s="thin" data-a-v="middle" data-a-wrap={true} data-a-h='center'>{detail.packaging}</td>
					<td data-num-fmt='0"km";-0;;@' data-t="n" data-a-h="right" data-b-l-s="thin" data-a-v="middle" data-b-b-s="thin">{detail.distance}</td>
					<td data-num-fmt='_(* #,##0_);_(* (#,##0);_(* "-"_);_(@_)' data-a-h="right" data-b-l-s="thin" data-a-v="middle" data-b-b-s="thin" data-t="n">{detail.transportFare ? detail.transportFare : ''}</td>
					<td data-b-l-s="thin" data-b-b-s="thin" data-a-v="middle" data-b-r-s="thin" data-a-wrap="true" data-f-sz={detail.notes.length >= 2 ? "9" : "11"} dangerouslySetInnerHTML={{ __html: detail.notes.join('<br/>') }} />
				</tr>
			);

			let escortRow = (
				<tr data-height="23.5" key={i}>
					<td></td>
					<td data-b-l-s="thin" data-b-b-s="thin" data-a-v="middle" data-a-h="center">{detail.transportDate}</td>
					<td data-b-l-s="thin" data-b-b-s="thin" data-a-wrap={true} data-a-v="middle" data-a-h='center' dangerouslySetInnerHTML={{ __html: origin }} />
					<td data-b-l-s="thin" data-b-b-s="thin" data-a-v="middle" data-a-h="center">～</td>
					<td data-b-l-s="thin" data-b-b-s="thin" data-a-wrap={true} data-a-v="middle" data-a-h='center' dangerouslySetInnerHTML={{ __html: destination }} />
					<td data-b-l-s="thin" data-b-b-s="thin" data-a-wrap={true} data-a-v="middle" data-a-h='center'>誘導{detail.escortCount}台 </td>
					<td data-num-fmt='0"km";-0;;@' data-t="n" data-a-h="right" data-b-l-s="thin" data-a-v="middle" data-b-b-s="thin">{detail.distance}</td>
					<td data-num-fmt='_(* #,##0_);_(* (#,##0);_(* "-"_);_(@_)' data-a-h="right" data-b-l-s="thin" data-a-v="middle" data-b-b-s="thin" data-t="n">{detail.escortFare ? detail.escortFare : ''}</td>
					<td data-b-l-s="thin" data-b-b-s="thin" data-a-v="middle" data-b-r-s="thin" data-a-wrap="true" data-f-sz={detail.notes.length >= 2 ? "9" : "11"} dangerouslySetInnerHTML={{ __html: detail.notes.join('<br/>') }} />
				</tr>
			);

			rows.push(transportRow);
			if (detail.escortCount > 0) {
				rows.push(escortRow)
			}
			return rows;
		});

		return result.length ? _.flatten(result) : [];
	};

	createPage(pageList, record, page, totalPage, invoice) {
		let pageRecord = [...record]
		if (page > 1) {
			// const pageHeader = [];
			// const marginTop = page > 2 ? 30 : 20;
			const pageHeader = (
				<>
					{/* <tr data-height={margin ? `${10 * margin}` : '20'}></tr> */}
					<tr data-height='20'></tr>
					<tr data-height="15"></tr>
					<tr data-height="15">
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td data-a-h="right" colSpan="2">
							ページ: {page}/{totalPage}
						</td>
					</tr>
					<tr data-height="15" >
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td data-a-h="right" data-a-v="middle" colSpan="2">
							請求日: {moment().format("YYYY年MM月DD日")}
						</td>
					</tr>
					<tr data-height="15">
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
					</tr>
					<tr data-height="27">
						<td></td>
						<td colSpan='5' data-f-sz="14" data-b-b-s='thin'>{invoice.companyName} 御中</td>
						<td></td>
						<td></td>
						<td></td>
					</tr>
					<tr data-height="15">
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
					</tr>
					{pageRecord.length && (
						<tr data-height="23.5">
							<td></td>
							<td data-a-v="middle" data-a-h="center" data-fill-color="D8D8D8" data-f-bold="true" data-b-a-s="thin" style={{ padding: '10px' }}>日付</td>
							<td data-a-v="middle" data-b-b-s='thin' data-b-t-s='thin' data-b-l-s='thin' data-a-h="center" data-fill-color="D8D8D8" data-f-bold="true" style={{ padding: '10px' }}>発地</td>
							<td data-a-v="middle" data-b-b-s='thin' data-b-t-s='thin' data-a-h="center" data-fill-color="D8D8D8" data-f-bold="true" style={{ padding: '10px' }}>～</td>
							<td data-a-v="middle" data-b-b-s='thin' data-b-t-s='thin' data-b-r-s='thin' data-a-h="center" data-fill-color="D8D8D8" data-f-bold="true" style={{ padding: '10px' }}>着地</td>
							<td data-a-v="middle" data-a-h="center" data-fill-color="D8D8D8" data-f-bold="true" data-b-a-s="thin" style={{ padding: '10px' }}>荷姿</td>
							<td data-a-v="middle" data-a-h="center" data-fill-color="D8D8D8" data-f-bold="true" data-b-a-s="thin" style={{ padding: '10px' }}>距離</td>
							<td data-a-v="middle" data-a-h="center" data-fill-color="D8D8D8" data-f-bold="true" data-b-a-s="thin" style={{ padding: '10px' }}>金額</td>
							<td data-a-v="middle" data-a-h="center" data-fill-color="D8D8D8" data-f-bold="true" data-b-a-s="thin" style={{ padding: '10px' }}>備考</td>
						</tr>
					)}
				</>
			)
			pageRecord = [
				pageHeader,
				...pageRecord,
			]
		}
		pageList.push([...pageRecord]);
	}

	render() {
		const createExcel = (record, invoice) => {
			let i = invoice.id;
			let totalPageNo = 1;
			let idvalue = "Output1";

			const rowList = this.createTableRows(record, 1);
			const totalRows = rowList.length;

			let pageList = [], totalPages = 1, pageEndList = [], overflowFlag = false;
			// only one page
			if (totalRows <= 25) {
				let indexesToAppend = 25 - rowList.length;
				if (totalRows <= 19) {
					indexesToAppend = 19 - rowList.length
				} else {
					totalPages = totalPages + 1;
					overflowFlag = true;
				}

				rowList.push(...[...new Array(indexesToAppend)].map(() => (
					<tr data-height="23.5" key={i}>
						<td></td>
						<td data-b-l-s="thin" data-b-b-s="thin" data-a-v="middle" data-a-h="center"></td>
						<td data-b-l-s="thin" data-b-b-s="thin" data-a-v="middle" data-a-h='center' />
						<td data-b-l-s="thin" data-b-b-s="thin" data-a-v="middle" data-a-h="center">～</td>
						<td data-b-l-s="thin" data-b-b-s="thin" data-a-v="middle" data-a-h='center' />
						<td data-b-l-s="thin" data-b-b-s="thin" data-a-v="middle" data-a-h='center'></td>
						<td data-num-fmt='0"km";-0;;@' data-t="n" data-a-h="right" data-b-l-s="thin" data-a-v="middle" data-b-b-s="thin"></td>
						<td data-num-fmt='_(* #,##0_);_(* (#,##0);_(* "-"_);_(@_)' data-a-h="right" data-b-l-s="thin" data-a-v="middle" data-b-b-s="thin" data-t="n"></td>
						<td data-b-l-s="thin" data-b-b-s="thin" data-a-v="middle" data-b-r-s="thin" data-a-wrap="true"></td>
					</tr>)))
				pageList.push([...rowList]);
				pageEndList.push(rowList.length);
			} else {
				totalPages = 2;
				const initTotal = totalRows - 49;
				if (initTotal > 0) {
					// greater than 2 pages
					totalPages = 2 + Math.ceil(initTotal / 30);
				}

				[...new Array(totalPages)].map((_, index) => {
					const isLastIndex = (index + 1) === totalPages;
					let pageEnd = index === 0 ? 25 : 30;
					if (isLastIndex) {
						let indexesToAppend = pageEnd - rowList.length;
						// if page 2
						if (index >= 1 && rowList.length <= 24) {
							indexesToAppend = 24 - rowList.length
							pageEnd = 24;
						} else {
							// totalPages = totalPages + 1;
							overflowFlag = true;
						}

						if (indexesToAppend > 0 && rowList.length) {
							rowList.push(...[...new Array(indexesToAppend)].map(() => (
								<tr data-height="23.5" key={i}>
									<td></td>
									<td data-b-l-s="thin" data-b-b-s="thin" data-a-v="middle" data-a-h="center"></td>
									<td data-b-l-s="thin" data-b-b-s="thin" data-a-v="middle" data-a-h='center' />
									<td data-b-l-s="thin" data-b-b-s="thin" data-a-v="middle" data-a-h="center">～</td>
									<td data-b-l-s="thin" data-b-b-s="thin" data-a-v="middle" data-a-h='center' />
									<td data-b-l-s="thin" data-b-b-s="thin" data-a-v="middle" data-a-h='center'></td>
									<td data-num-fmt='0"km";-0;;@' data-t="n" data-a-h="right" data-b-l-s="thin" data-a-v="middle" data-b-b-s="thin"></td>
									<td data-num-fmt='_(* #,##0_);_(* (#,##0);_(* "-"_);_(@_)' data-a-h="right" data-b-l-s="thin" data-a-v="middle" data-b-b-s="thin" data-t="n"></td>
									<td data-b-l-s="thin" data-b-b-s="thin" data-a-v="middle" data-b-r-s="thin" data-a-wrap="true"></td>
								</tr>)))
						}

						if (rowList.length) {
							pageEndList.push(index === 0 ? 25 : pageEnd + 8);
						} else {
							pageEndList.push(7);
						}
						return this.createPage(pageList, rowList.splice(0, pageEnd), index + 1, totalPages, invoice);
					}
					const dataList = rowList.splice(0, pageEnd);
					const diff = pageEnd - dataList.length;
					if (diff > 0) {
						dataList.push(...[...new Array(diff)].map(() => (
							<tr data-height="23.5" key={i}>
								<td></td>
								<td data-b-l-s="thin" data-b-b-s="thin" data-a-v="middle" data-a-h="center"></td>
								<td data-b-l-s="thin" data-b-b-s="thin" data-a-v="middle" data-a-h='center' />
								<td data-b-l-s="thin" data-b-b-s="thin" data-a-v="middle" data-a-h="center">～</td>
								<td data-b-l-s="thin" data-b-b-s="thin" data-a-v="middle" data-a-h='center' />
								<td data-b-l-s="thin" data-b-b-s="thin" data-a-v="middle" data-a-h='center'></td>
								<td data-num-fmt='0"km";-0;;@' data-t="n" data-a-h="right" data-b-l-s="thin" data-a-v="middle" data-b-b-s="thin"></td>
								<td data-num-fmt='_(* #,##0_);_(* (#,##0);_(* "-"_);_(@_)' data-a-h="right" data-b-l-s="thin" data-a-v="middle" data-b-b-s="thin" data-t="n"></td>
								<td data-b-l-s="thin" data-b-b-s="thin" data-a-v="middle" data-b-r-s="thin" data-a-wrap="true"></td>
							</tr>)))
					}
					pageEndList.push(index === 0 ? 25 : pageEnd + 8);
					this.createPage(pageList, dataList, index + 1, totalPages, invoice);
				});
			};

			let dataEnd = 15;
			if (overflowFlag) {
				dataEnd = dataEnd + 7;
			}
			pageEndList.map(end => {
				dataEnd = dataEnd + end;
			});

			return (<Fragment key={i}>
				<table id={idvalue} data-cols-width="1,9.66,14.66,3.66,14.66,18.66,6.83,10,22">
					<thead>
						<tr data-height="15"></tr>
						<tr data-height="15">
							<td></td>
							<td></td>
							<td></td>
							<td></td>
							<td></td>
							<td></td>
							<td></td>
							<td data-a-h="right" colSpan="2">
								ページ: 1/{totalPages}
							</td>
						</tr>
						<tr data-height="15" >
							<td></td>
							<td></td>
							<td></td>
							<td></td>
							<td></td>
							<td></td>
							<td></td>
							<td data-a-h="right" data-a-v="middle" colSpan="2">
								請求日: {moment().format("YYYY年MM月DD日")}
							</td>
						</tr>
						<tr data-height="15">
							<td></td>
							<td></td>
							<td></td>
							<td></td>
							<td></td>
							<td></td>
							<td></td>
						</tr>
						<tr data-height="27">
							<td></td>
							<td colSpan="8" data-f-bold="true" data-a-v="middle" data-a-h="center" data-f-sz="26">請　求　書</td>
						</tr>
						<tr data-height="25">
							<td></td>
							<td colSpan="8"></td>
						</tr>
						<tr data-height="25">
							<td></td>
							<td colSpan="5" data-a-v="middle" data-a-h="center" data-f-sz="11" data-b-b-s="thin" data-f-bold="true" data-f-sz="16" style={{ padding: '10px' }}>
								{invoice.companyName} 御中
							</td>
							<td></td>
							<td colSpan="2" data-a-v="middle" data-a-h="left" >一般区域貨物運送事業</td>
						</tr>
						<tr data-height="15">
							<td></td>
							<td colSpan="4"></td>
							<td></td>
							<td></td>
							<td colSpan="2" data-a-v="middle" data-a-h="left" data-f-sz="14">有限会社黒潮重機興業</td>
						</tr>
						<tr data-height="15">
							<td></td>
							<td colSpan="6"></td>
						</tr>
						<tr data-height="15">
							<td></td>
							<td colSpan="4" data-a-v="middle" data-a-h='center' >以下の通り、ご請求申し上げます。</td>
							<td></td>
							<td></td>
							<td colSpan="2" data-a-v="middle" data-a-h="left" data-f-sz="10">〒985-0844</td>
						</tr>
						<tr data-height="15">
							<td></td>
							<td colSpan="4"></td>
							<td></td>
							<td></td>
							<td colSpan="2" data-a-v="middle" data-a-h="left" data-f-sz="10">宮城県多賀城市宮内一丁目１３番１３号</td>
						</tr>
						<tr data-height="15">
							<td></td>
							<td colSpan="2" rowSpan="2" data-a-v='middle' data-a-h="center" data-a-h="center" data-b-b-s="thick" data-b-l-s="thick" data-b-t-s="thick" data-f-sz="18">合計金額</td>
							<td data-formula={`H${dataEnd + 3}`} data-a-v='middle' data-cell='D12' data-num-fmt='_-[$¥-ja-JP] #,##0_-;-[$¥-ja-JP] #,##0_-;_-[$¥-ja-JP] "-"_-;_-@_-' colSpan="3" data-a-h="left" rowSpan="2" data-b-b-s="thick" data-b-r-s="thick" data-b-t-s="thick" data-f-sz="24"></td>
							<td></td>
							<td colSpan="2" data-a-v="middle" data-a-h="left">TEL:022(367)5288　FAX:022(367)5289</td>
						</tr>
						<tr data-height="15">
							<td></td>
							<td></td>
							<td colSpan="2" data-a-v="middle" data-a-h="left">登録番号:T3370602000572</td>
						</tr>
					</thead>
					<tbody>
						<tr data-height="15">
							<td></td>
							<td></td>
							<td></td>
							<td></td>
							<td></td>
							<td></td>
							<td></td>
						</tr>
						<tr data-height="23.5">
							<td></td>
							<td data-a-v="middle" data-a-h="center" data-fill-color="D8D8D8" data-f-bold="true" data-b-a-s="thin" style={{ padding: '10px' }}>日付</td>
							<td data-a-v="middle" data-b-b-s='thin' data-b-t-s='thin' data-b-l-s='thin' data-a-h="center" data-fill-color="D8D8D8" data-f-bold="true" style={{ padding: '10px' }}>発地</td>
							<td data-a-v="middle" data-b-b-s='thin' data-b-t-s='thin' data-a-h="center" data-fill-color="D8D8D8" data-f-bold="true" style={{ padding: '10px' }}>～</td>
							<td data-a-v="middle" data-b-b-s='thin' data-b-t-s='thin' data-b-r-s='thin' data-a-h="center" data-fill-color="D8D8D8" data-f-bold="true" style={{ padding: '10px' }}>着地</td>
							<td data-a-v="middle" data-a-h="center" data-fill-color="D8D8D8" data-f-bold="true" data-b-a-s="thin" style={{ padding: '10px' }}>荷姿</td>
							<td data-a-v="middle" data-a-h="center" data-fill-color="D8D8D8" data-f-bold="true" data-b-a-s="thin" style={{ padding: '10px' }}>距離</td>
							<td data-a-v="middle" data-a-h="center" data-fill-color="D8D8D8" data-f-bold="true" data-b-a-s="thin" style={{ padding: '10px' }}>金額</td>
							<td data-a-v="middle" data-a-h="center" data-fill-color="D8D8D8" data-f-bold="true" data-b-a-s="thin" style={{ padding: '10px' }}>備考</td>
						</tr>
						{pageList}
						{overflowFlag && (
							<>
								{/* <tr data-height={margin ? `${10 * margin}` : '20'}></tr> */}
								<tr data-height='20'></tr>
								<tr data-height="15"></tr>
								<tr data-height="15">
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									<td data-a-h="right" colSpan="2">
										ページ: {totalPages}/{totalPages}
									</td>
								</tr>
								<tr data-height="15" >
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									<td data-a-h="right" data-a-v="middle" colSpan="2">
										請求日: {moment().format("YYYY年MM月DD日")}
									</td>
								</tr>
								<tr data-height="15">
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
								</tr>
								<tr data-height="27">
									<td></td>
									<td colSpan='5' data-f-sz="14" data-b-b-s='thin'>{invoice.companyName} 御中</td>
									<td></td>
									<td></td>
									<td></td>
								</tr>
								<tr data-height="15">
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
								</tr>
							</>
						)}
						<tr data-height="23.5">
							<td></td>
							<td colSpan="5" data-b-l-s="thin" data-b-t-s="thin" data-b-b-s="thin" data-a-v="middle" data-a-h="center" data-f-sz="14" data-f-bold="true">小　　計</td>
							<td data-b-l-s="thin" data-b-b-s="thin" data-b-t-s="thin" data-a-v="middle" data-a-h="center"></td>
							<td data-formula={`SUM(H16:H${dataEnd})`} data-cell={`H${dataEnd + 1}`} data-b-t-s="thin" data-num-fmt='_(* #,##0_);_(* (#,##0);_(* "-"_);_(@_)' data-b-l-s="thin" data-b-b-s="thin" data-a-v="middle" data-a-h="center" data-t="n">{invoice.subtotal}</td>
							<td data-b-l-s="thin" data-b-b-s="thin" data-b-t-s="thin" data-a-v="middle" data-b-r-s="thin" data-a-h="center"></td>
						</tr>
						<tr data-height="23.5">
							<td></td>
							<td colSpan="5" data-b-l-s="thin" data-b-b-s="thin" data-a-v="middle" data-a-h="center" data-f-sz="14" data-f-bold="true">消 費 税 （10％）</td>
							<td data-b-l-s="thin" data-b-b-s="thin" data-a-v="middle" data-a-h="center"></td>
							<td data-formula={`H${dataEnd + 1}*${invoice.taxRate}`} data-cell={`H${dataEnd + 2}`} data-num-fmt='_(* #,##0_);_(* (#,##0);_(* "-"_);_(@_)' data-b-l-s="thin" data-b-b-s="thin" data-a-v="middle" data-a-h="center" data-t="n">{invoice.tax}</td>
							<td data-b-l-s="thin" data-b-b-s="thin" data-a-v="middle" data-b-r-s="thin" data-a-h="center"></td>
						</tr>
						<tr data-height="23.5">
							<td></td>
							<td colSpan="5" data-b-l-s="thin" data-b-b-s="thin" data-a-v="middle" data-a-h="center" data-f-sz="14" data-f-bold="true">合 計 （10％対象）</td>
							<td data-b-l-s="thin" data-b-b-s="thin" data-a-v="middle" data-a-h="center"></td>
							<td data-formula={`H${dataEnd + 1}+H${dataEnd + 2}`} data-cell={`H${dataEnd + 3}`} data-num-fmt='_(* #,##0_);_(* (#,##0);_(* "-"_);_(@_)' data-b-l-s="thin" data-b-b-s="thin" data-a-v="middle" data-a-h="center" data-t="n">{invoice.totalamount}</td>
							<td data-b-l-s="thin" data-b-b-s="thin" data-a-v="middle" data-b-r-s="thin" data-a-h="center"></td>
						</tr>

						<tr data-height="15">
							<td></td>
							<td colSpan="6"></td>
						</tr>
						<tr data-height="25">
							<td></td>
							<td data-a-v="middle" data-a-h="center" rowSpan="2" data-f-sz="10" data-b-a-s="thin">振込先</td>
							<td data-a-h="center" data-a-v="middle" colSpan="4" data-f-sz="11" data-b-a-s="thin" style={{ padding: '10px' }}>
								北日本銀行多賀城支店&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（普通）1245162
							</td>
							<td></td>
							<td></td>
							<td></td>
						</tr>
						<tr data-height="25">
							<td></td>
							<td data-a-h="center" data-a-v="middle" colSpan="4" data-f-sz="11" data-b-a-s="thin" style={{ padding: '10px' }}>
								杜の都信用金庫多賀城支店&nbsp;&nbsp;&nbsp;&nbsp;（普通）0957055
							</td>
							<td></td>
							<td></td>
							<td></td>
						</tr>
					</tbody>
				</table>
			</Fragment>);
		};

		let { data } = this.props;
		let exceels = [];
		let counter = 0;

		const excels = data.map((row, i) => {
			return createExcel(row.rows, row)
		});

		let invoiceDate;

		let transportDateFrom = document.getElementById("transportDate_From");
		if (transportDateFrom && transportDateFrom.getElementsByClassName('ant-input')) {
			transportDateFrom = transportDateFrom.getElementsByClassName('ant-input');
			if (transportDateFrom[0]) {
				invoiceDate = transportDateFrom[0].value;
			}
		}

		setTimeout(() => {
			for (var i = data.length - 1; i >= 0; i--) {
				let row = data[i];
				let idname = "Output" + 1;

				TableToExcel.convert(document.getElementById("Output1"), {
					name: "請求書_" + data[0].customerName + "_" + moment(invoiceDate).format("YYYYMM") + ".xlsx",
					sheet: {
						name: "Sheet 1"
					},
					pageSetup: {
						orientation: 'portrait',
						paperSize: 9,
						fitToWidth: 1,
						fitToHeight: 0,
						margins: {
							left: 0.28, right: 0.28,
							top: 0.47, bottom: 0.47,
							header: 0.31, footer: 0.31
						},
					},
					properties: {
						defaultRowHeight: 25
					},
					views: [{
						// showGridLines: false,
						// horizontalCentered: false
					}]
				});
				// }
			}

			this.props.client.writeData({
				data: {
					excelOutput: {
						__typename: 'excelOutput',
						excelInvoiceOutputData: []
					}
				}
			});
		}, 0);
		return (<Fragment>{excels}{exceels}</Fragment>);
	}
}

const InvoiceExcelWrap = withApollo(InvoiceExcel);

export { InvoiceExcelWrap, invoiceType };