import React, { useContext, useState } from 'react';
import { Form, Row, Col, Button, Table, Modal, notification } from 'antd';
import Year from '../../../commons/Picklist/Year';
import Month from '../../../commons/Picklist/Month';
import { CustomRadioGroup, CustomHiddenInput } from "../../../commons/CustomDataEntry";
import DriverModalConfig from '../../../components/MasterMaintenance/User/ListModal'
import ModalBase from "../../../commons/ModalBase";
import { Context } from '../../../commons/Context';
import _ from 'lodash';
import moment from 'moment';
import 'moment/locale/ja';
import uuid from 'uuid/v4';
import { getWeek, getMonth, getYear } from "../../../queries/Reporting";
import WithEventButton from '../../../commons/WithEventButton';

notification.config({
    duration: 8
})
moment.locale('ja');

const Filter = ({ lt, form }) => {
    const [selectedRowKeys, handleSelectedRowKeys] = useState([])
    const { state, dispatch, client } = useContext(Context);

    const openNotificationWithIcon = (type, message) => {
        notification[type]({ message });
    };

    const search = (e) => {
        e.preventDefault();
        form.validateFields((err, values) => {
            if (!err) {
                if (values.customerId) {
                    Modal.confirm({
                        title: '確認',
                        icon: 'false',
                        content: '運転手別売上の分析を開始しますか？',
                        okText: 'はい',
                        cancelText: 'いいえ',
                        onOk: () => {
                            dispatch({
                                type: 'filters',
                                filters: { values }
                            });
                            dispatch({
                                type: 'spinner',
                                spinner: true
                            });
                            let date1 = moment(`${values.transportDate_From_Year}-${values.transportDate_From_Month}-01`).startOf('month').format("YYYY-MM-DD");
                            let date2 = moment(`${values.transportDate_To_Year}-${values.transportDate_To_Month}-01`).endOf('month').format("YYYY-MM-DD")
                            customerSale(values, date1, date2);
                            openNotificationWithIcon('success', '運転手別売上の分析を開始しました。');
                        }
                    });
                } else {
                    openNotificationWithIcon('error', '運転手を選択してください。');
                }
            }
        });
    };
    let customerSale = async (values, date1, date2) => {

        let weekMonthYear;

        switch (values.radio_customer) {
            case 0:
                weekMonthYear = 'week';
                break;
            case 1:
                weekMonthYear = 'month';
                break;
            default:
                weekMonthYear = 'year';
                break;
        }

        if (weekMonthYear === 'week') {
            try {  //2014-01-01 2020-01-31
                const { data: { orderDispatchWeeklyReportQuery: { result } } } = await client.query({
                    query: getWeek,
                    fetchPolicy: 'network-only',
                    variables: {
                        input: {
                            driverId: values.customerId,
                            startTransportDate: date1,
                            endTransportDate: date2
                        }
                    }
                });
                let totalWeeksOfEndYear = moment(date2).weeks()
                let totalWeeksOfStartyear = moment(date1).weeks()
                let startYear = moment(date1).year()
                let endYear = moment(date2).year()
                let newStartYear = startYear
                let newEndYear = endYear
                let newRes = []
                for (startYear; startYear <= endYear; startYear++) {
                    if (newStartYear === startYear && startYear === newEndYear) {
                        if (newStartYear === startYear) {
                            for (totalWeeksOfStartyear; totalWeeksOfStartyear <= 52; totalWeeksOfStartyear++) {
                                newRes.push({ week: totalWeeksOfStartyear, year: startYear, fare: 0 })
                            }
                        }
                        if (startYear === newEndYear) {
                            for (let x = 1; x <= totalWeeksOfEndYear; x++) {
                                newRes.push({ week: x, year: startYear, fare: 0 })
                            }
                        }
                    } else {
                        for (let x = 1; x <= 52; x++) {
                            newRes.push({ week: x, year: startYear, fare: 0 })
                        }
                    }
                }
                _.forEach(newRes, (val, i) => {
                    let filter = _.filter(result, (v) => {
                        return v.week === val.week && v.year === val.year
                    })
                    if (filter.length) {
                        newRes[i] = filter[0]
                    }
                })

                //   let  graphs=newRes
                let graphs = _.chain(newRes).map(({ week, year, fare, }) => {
                    return {
                        week,
                        [`${year}年`]: !!fare ? parseFloat(_.replace(fare, /[,￥]/g, '')) : 0
                    }
                }).groupBy('week').map((weeklyData) => {
                    return Object.assign(...weeklyData, ...weeklyData)
                }).value();
                if (graphs.length === 0) {
                    graphs = [{
                        week: 0, year: 0
                    }, {
                        week: 0, year: 0
                    }]
                }
                dispatch({
                    type: 'graph',
                    graphs
                });

                dispatch({
                    type: 'flatten',
                    flatten: graphs
                });
            } catch (err) {
                let graphs = [{
                    week: 0, year: 0
                }, {
                    week: 0, year: 0
                }]
                dispatch({
                    type: 'graph',
                    graphs
                });

                dispatch({
                    type: 'flatten',
                    flatten: graphs
                });
            }

        } else if (weekMonthYear === 'month') {
            try {
                const { data: { orderDispatchMonthlyReportQuery: { result } } } = await client.query({
                    query: getMonth,
                    fetchPolicy: 'network-only',
                    variables: {
                        input: {
                            driverId: [values.customerId],
                            startTransportDate: date1,
                            endTransportDate: date2
                        }
                    }
                });

                //   fare: 123247
                //     month: 7
                //     year: 2019
                //     __typename: "MonthObjects"
                //     __proto__: Object
                //     length: 1
                //     __proto__: Array(0)
                var newArr = []
                let month1 = moment(date1).month() + 1
                let month2 = moment(date2).month() + 1
                let year1 = moment(date1).year()
                let year2 = moment(date2).year()
                let newYear1 = year1
                let newYear2 = year2
                for (year1; year1 <= year2; year1++) {

                    if (year1 === newYear1) {
                        for (month1; month1 <= 12; month1++) {
                            newArr.push({ month: month1, year: year1, fare: 0 })
                        }
                    }
                    if (year1 !== newYear2 && year1 !== newYear1) {
                        for (let x = 1; x <= 12; x++) {
                            newArr.push({ month: x, year: year1, fare: 0 })
                        }
                    }
                    if (year1 === newYear2) {
                        for (let x = 1; x <= month2; x++) {
                            newArr.push({ month: x, year: year1, fare: 0 })
                        }
                    }
                }


                _.forEach(newArr, (val, i) => {
                    let filter = _.filter(result, (v) => {
                        return v.month === val.month && v.year === val.year
                    })
                    if (filter.length) {
                        newArr[i] = filter[0]
                    }
                })


                let graphs = _.chain(newArr).map(({ month, year, fare, }) => {
                    return {
                        [`${year}年 ${month}月 `]: !!fare ? parseFloat(_.replace(fare, /[,￥]/g, '')) : 0,
                    }
                }).groupBy('month').map((monthlyData) => {
                    return Object.assign(...monthlyData, ...monthlyData)
                }).value();


                if (graphs.length === 0) {
                    graphs = [{
                        月年: 0
                    }, {
                        月年: 0
                    }]
                }
                dispatch({
                    type: 'graph',
                    graphs
                });
                dispatch({
                    type: 'flatten',
                    flatten: graphs
                });
            } catch (err) {
                let graphs = [{
                    月年: 0
                }, {
                    月年: 0
                }]
                dispatch({
                    type: 'graph',
                    graphs
                });
                dispatch({
                    type: 'flatten',
                    flatten: graphs
                });
            }
        } else {
            try {
                const { data: { orderDispatchYearlyReportQuery: { result } } } = await client.query({
                    query: getYear,
                    fetchPolicy: 'network-only',
                    variables: {
                        input: {
                            driverId: [values.customerId],
                            startTransportDate: date1,
                            endTransportDate: date2
                        }
                    }
                });
                let year1 = moment(date1).year()
                let year2 = moment(date2).year()
                let temp = []
                for (year1; year1 <= year2; year1++) {
                    temp.push({ year: year1, fare: 0 })
                }

                _.forEach(temp, (val, i) => {
                    let filter = _.filter(result, (v) => {
                        return v.year === val.year
                    })
                    if (filter.length) {
                        temp[i] = filter[0]
                    }
                })


                let graphs = _.chain(temp).map(({ year, fare, }) => {
                    return {
                        [`${year}年`]: fare == null ? 0 : !!fare ? parseFloat(_.replace(fare, /[,￥]/g, '')) : 0,
                    }
                }).groupBy('week').map((weeklyData) => {
                    return Object.assign(...weeklyData, ...weeklyData)
                }).value();

                if (graphs.length === 0) {
                    graphs = [{
                        年: 0
                    }, {
                        年: 0
                    }]
                }
                dispatch({
                    type: 'graph',
                    graphs
                });
                dispatch({
                    type: 'flatten',
                    flatten: graphs
                });
            } catch (err) {
                let graphs = [{
                    年: 0
                }, {
                    年: 0
                }]
                dispatch({
                    type: 'graph',
                    graphs
                });
                dispatch({
                    type: 'flatten',
                    flatten: graphs
                });
            }

        }
        dispatch({
            type: 'mode',
            mode: weekMonthYear
        });
    }

    const onRowClick = (record) => {
        if (selectedRowKeys === record.key) {
            handleSelectedRowKeys([]);
            form.setFieldsValue({
                customerId: null,
                customerName: null
            })
        } else {
            const { id, fullNameJa } = record;
            form.setFieldsValue({
                customerId: id,
                customerName: fullNameJa
            });
            handleSelectedRowKeys(record.key)
        }
    };

    const onSelect = ({ id, fullNameJa }) => {
        form.setFieldsValue({
            customerId: id,
            customerName: fullNameJa
        })
    };

    const onSelectedRowKey = e => handleSelectedRowKeys(e);

    const handleReset = () => {
        dispatch({
            type: 'reset'
        });
        form.resetFields();
        handleSelectedRowKeys([])
        openNotificationWithIcon('warning', '分析条件をクリアしました。');
    }

    const selectedCustomer = (rows) => {
        let selectedRows = rows.map(({ id, fullNameJa }) => {
            let { data } = state;
            let filterData = data.filter(item => {
                return item.fullNameJa === fullNameJa
            });
            if (filterData.length <= 0) {
                return {
                    id,
                    fullNameJa,
                    key: uuid()
                };
            }
            // eslint-disable-next-line array-callback-return
            return
        });

        let rowFilter = selectedRows.filter(item => item !== undefined);

        if (rowFilter.length) {
            dispatch({
                type: 'add',
                selectedData: rowFilter
            });
        }
        handleSelectedRowKeys([])
    };

    const radioFilter = [
        { id: 0, value: lt('rdo_by_week'), autoFocus: true },
        { id: 1, value: lt('rdo_by_month'), autoFocus: false },
        { id: 2, value: lt('rdo_by_year'), autoFocus: false }
    ];

    let columns = [{
        title: lt('lbl_name'),
        dataIndex: 'fullNameJa',
        key: 'fullNameJa',
    }];

    return (
        <Form onSubmit={search}>
            <Row>
                <Col>
                    {lt('lbl_output_category')}
                </Col>
            </Row>
            <Row style={{ marginTop: '10px' }}>
                <Col>
                    <CustomRadioGroup
                        options={radioFilter}
                        form={form}
                        fieldName='radio_customer'
                        decorator={{
                            initialValue: 0
                        }}
                    />
                </Col>
            </Row>
            <Row style={{ marginTop: '4px' }}>
                <Col span={24}>
                    <label>分析年月</label>
                    <div className='ant-row ant-form-item four_column' style={{ marginTop: '10px' }}>
                        <Year
                            form={form}
                            fieldName='transportDate_From_Year'
                            postLabel={lt('lbl_conditions_from_year')}
                            rowField={true}
                            style={{ width: 100, display: 'inline-block', marginRight: 10 }}
                            decorator={{
                                rules: [{
                                    required: true,
                                    message: ' '
                                }]
                            }}
                            labelWidth={16}
                            width={18}
                        />
                        <Month
                            form={form}
                            fieldName='transportDate_From_Month'
                            postLabel={lt('lbl_conditions_from_month')}
                            style={{ width: 80, display: 'inline-block', marginRight: 10 }}
                            rowField={true}
                            decorator={{
                                rules: [{
                                    required: true,
                                    message: ' '
                                }]
                            }}
                            labelWidth={13}
                            width={14}
                        />

                        <Year
                            form={form}
                            fieldName='transportDate_To_Year'
                            postLabel={lt('lbl_conditions_to_year')}
                            rowField={true}
                            style={{ width: 100, display: 'inline-block', marginRight: 10 }}
                            decorator={{
                                rules: [{
                                    required: true,
                                    message: ' '
                                }]
                            }}
                            labelWidth={16}
                            width={18}
                        />
                        <CustomHiddenInput
                            form={form}
                            fieldName='customerId'
                            type='hidden'
                            decorator={{}}
                        />
                        <CustomHiddenInput
                            form={form}
                            fieldName='customerName'
                            type='hidden'
                            decorator={{}}
                        />
                        <Month
                            form={form}
                            fieldName='transportDate_To_Month'
                            postLabel={lt('lbl_conditions_to_month')}
                            rowField={true}
                            style={{ width: 80, display: 'inline-block', marginRight: 10 }}
                            decorator={{
                                rules: [{
                                    required: true,
                                    message: ' '
                                }]
                            }}
                            labelWidth={12}
                            width={14}
                        />
                    </div>
                </Col>
            </Row>
            <Row style={{ marginBottom: '10px' }}>
                <Col span={12} style={{ padding: '5px' }}>
                    {lt('lbl_analysis_target')}
                </Col>
                <Col span={12} style={{ textAlign: 'right' }}>
                    <ModalBase
                        title={lt('運転手情報検索')}
                        hideInputText={true}
                        form={form}
                        btnLabel={lt('btn_select_driver')}
                        fieldName='customers'
                        afterSelect={selectedCustomer}
                        config={DriverModalConfig({ authority: 4 }, 'checkbox')}
                        decorator={{}}
                        labelWidth={32}
                        width={32}
                        buttonMarginRight={0}
                        buttonMarginLeft={0}
                    />
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Table
                        className='searchsales'
                        position="top"
                        bordered={true}
                        columns={columns}
                        dataSource={state.data}
                        rowSelection={{
                            onSelect: onSelect,
                            type: 'radio',
                            onChange: onSelectedRowKey,
                            selectedRowKeys,
                        }}
                        onRow={(record, rowIndex) => ({ record, onClick: () => onRowClick(record) })}
                        pagination={{ position: 'top' }}
                    />
                </Col>
            </Row>
            <Row style={{ marginTop: '10px' }}>
                <Col span={12}>
                    <Button type='default' style={{ width: '106px' }} onClick={handleReset} htmlType='button'>{lt('btn_click_on_clear_conditions')}</Button>
                </Col>
                <Col span={12} style={{ textAlign: 'right' }}>
                    <WithEventButton
                        event={search}
                        loading={false}
                        style={{ width: '106px' }}
                        label={lt('btn_click_on_analyze')}
                    />
                </Col>
            </Row>
        </Form>
    );
}

export default Form.create()(Filter);
