import { Button, Modal, Row, Typography } from 'antd';
import React from 'react';

const ConfirmModal = (props) => {
  const { onCancel, onOk, visible } = props;

  return (
    <Modal
      visible={visible}
      maskClosable={false}
      onOK={onOk}
      onCancel={onCancel}
      className='modal-vehicle'
      width={416}
      footer={[
        <Button key='submit' type='primary' onClick={onOk}>確 定</Button>,
        <Button key='back' onClick={onCancel}>戻る</Button>,
      ]}
    >
      <Row>
        <Row type='flex' justify='center' style={{ marginBottom: 30 }}>
          <Typography style={{ fontWeight: 'bold' }}>配車確定</Typography>
        </Row>
        <Row type='flex' justify='center' style={{ marginBottom: 15 }}>
          <Typography>一覧画面で選択した配車内容を確定します。</Typography>
        </Row>
      </Row>
    </Modal>
  )
};

export default ConfirmModal;