import React, { Component } from 'react';
import data from './translate.json'

const withTranslation = (WrappedComponent, Page) => {
    class HOC extends Component {
        handleTranslate = (field) => {
            if (!data[Page]) {
                return field;
            }

            // Default this to JP for now
            // return data[Page][field] && process.env.REACT_APP_LN === 'jp'
            //     ? data[Page][field] : field;
            return data[Page][field] ? data[Page][field] : field;
        };
        render() {
            return ( <
                WrappedComponent {...this.props }
                lt = { this.handleTranslate }
                />
            );
        }
    }
    return HOC;
};

export default withTranslation;