import { gql } from 'apollo-boost';

const getAll = gql`
query allOrders($filter:OrderFilter) {
  allOrders(filter:$filter) {
    nodes {
      id
      transportDate
      customerId
      transportFare
      escortVehicleFare
      customerByCustomerId {
        nameJa
      }
    }
  }
}`;

const getWeek = gql`
query orderDispatchWeeklyReportQuery($input:OrderDispatchReportInput) {
  orderDispatchWeeklyReportQuery(input:$input) {
    result {
      week
      year
      fare
    }
  }
}`;


const getMonth = gql`
query orderDispatchMonthlyReportQuery($input:OrderDispatchReportInput) {
  orderDispatchMonthlyReportQuery(input:$input) {
    result {
      month
      year
      fare
    }
  }
}`;

const getYear = gql`
query orderDispatchYearlyReportQuery($input:OrderDispatchReportInput) {
  orderDispatchYearlyReportQuery(input:$input) {
    result {
      year
      fare
    }
  }
}`;

export {getAll,getWeek, getMonth, getYear};