import { gql } from 'apollo-boost';

const customerLoginMutation = gql`
  mutation authenticateCustomer($loginId: String!, $password: String!){
    authenticateCustomer(input: {loginId: $loginId, password: $password}) {
      jwtToken
    }
  }
`;

const changeCustomerPassword = gql`
  mutation changeCustomerPassword(
    $id: Int!,
    $password: String!,
  ){
    changeCustomerPassword(input:{
      customerId: $id,
      newPassword: $password
    }) {
      clientMutationId
    }
  }
`;


const changeCustomerPasswordConfirmation = gql`
mutation changeCustomerPasswordConfirmation(
  $newPassword:String,
  $currentPassword:String, 
  $userId: Int) {
  changeCustomerPasswordConfirmation(input:{
    newPassword:$newPassword,
    currentPassword:$currentPassword,
    userId:$userId
  }) {
    customer{
      id
    }
  }
}
`;

const getLastCustomer = gql`
  query allCustomers {
    allCustomers(orderBy: LOGIN_ID_DESC, first: 1) {
      nodes {
        id
        loginId
      }
    }
  }
`

export { getLastCustomer, customerLoginMutation, changeCustomerPassword, changeCustomerPasswordConfirmation };