// import React, {Fragment} from "react";
// import {Button} from "antd";
import adhocTranslator from "../../utils/locales/translate-adhoc";
// import ActionHeader from '../../commons/TableActions/ActionHeader';
import {getAll} from '../../queries/MasterMaintenance/vehicle';
// import { Col } from 'antd';

// import {connection} from '../../commons/SocketClient';

export default () => {

    let lt = adhocTranslator('K040-010');
    // let socket = connection().socket;
    
    return {
        fetchQuery: getAll,
        allKey: 'allVehicles',
        pagination: true,
        perPage: 15,
        th: '722px',
        columns : [
            {
                title: lt('lbl_vehicle_no_header'),
                dataIndex: 'vehicleNo',
                key: 'vehicleNo',
            }
        ],
        dataMapping: (item) => {
            return {
                id: item.id,
                vehicleNo: item.vehicleNo  // + lt('lbl_vehicle_no_he'),
            }
        },

        // actionHeader: () => {
        //     return (
        //         <Fragment>
        //             {/* <ActionHeader
        //                 customActions={
        //                     <Fragment>
        //                         <Col span={24}>
        //                             <Button type="primary" 
        //                                 htmlType="button"
        //                                 style={{marginBottom: 10, width: "100%", overflow: "hidden", boxShadow: "none"}}
        //                                 onClick={() => {socket.emit('get-all-trucks')}}>
        //                                 {lt('btn_update_list')}
        //                             </Button>
        //                         </Col>
        //                     </Fragment>
        //                 }/> */}
        //         </Fragment>
        //     )
        // },
    };
}