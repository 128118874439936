import { gql } from 'apollo-boost';

const getAll = gql`
query allEstimatesDetails($first:Int, $offset:Int, $filter:EstimatesDetailFilter) {
  allEstimatesDetails(first:$first, offset:$offset, filter:$filter) {
    nodes {
    	id,
  		estimateId,
  		sequenceNo,
  		originDestinationLocation,
  		packing1,
  		transportDistance,
  		transportFee,
  		notes,
  		escortVehicleOption,
  		escortVehicleCount,
      escortVehicleDistance,
      outsourceEscortVehicleOption,
      outsourceEscortVehicleCount,
      outsourceEscortVehicleDistance,
      outsourceEscortVehicleFee,
  		escortVehicleFee,
  		createdAt,
  		packagingByPacking1 {
  			packagingName,
        weight
  		}
    }
  }
}`;

const getById = gql`
query getEstimatesDetailsById($id:Int!) {
  estimatesDetailById(id:$id) {
  	id,
    estimateId,
    sequenceNo,
    originDestinationLocation,
    packing1,
    transportDistance,
    transportFee,
    notes,
    escortVehicleOption,
    escortVehicleCount,
    escortVehicleDistance,
    outsourceEscortVehicleOption,
    outsourceEscortVehicleCount,
    outsourceEscortVehicleDistance,
    outsourceEscortVehicleFee,
    escortVehicleFee,
    packagingByPacking1 {
      packagingName,
      weight
    }
  }
}`;

const create = gql`
mutation createEstimatesDetails(
  $estimateId: Int,
	$sequenceNo: Int,
	$originDestinationLocation: String,
	$packing1: Int,
	$transportDistance: Float,
	$transportFee: Float,
	$notes: String,
	$escortVehicleOption: Int,
	$escortVehicleCount: Int,
  $escortVehicleDistance: Float,
  $outsourceEscortVehicleOption: Int,
  $outsourceEscortVehicleCount: Int,
  $outsourceEscortVehicleFee: Float,
	$outsourceEscortVehicleDistance: Float,
	$escortVehicleFee: Float,
  $updatedUserId : Int,
  $createdUserId : Int
) {
  createEstimatesDetail(input:{
    estimatesDetail:{
      estimateId: $estimateId, 
			sequenceNo: $sequenceNo, 
			originDestinationLocation: $originDestinationLocation, 
			packing1: $packing1, 
			transportDistance: $transportDistance, 
			transportFee: $transportFee, 
			notes: $notes, 
			escortVehicleOption: $escortVehicleOption, 
			escortVehicleCount: $escortVehicleCount, 
      escortVehicleDistance: $escortVehicleDistance, 
      escortVehicleOption: $escortVehicleOption,  
      outsourceEscortVehicleOption: $outsourceEscortVehicleOption, 
			outsourceEscortVehicleDistance: $outsourceEscortVehicleDistance, 
			outsourceEscortVehicleFee: $outsourceEscortVehicleFee, 
			outsourceEscortVehicleCount: $outsourceEscortVehicleCount,
      createdUserId : $createdUserId,
      updatedUserId : $updatedUserId
    }
  }) {
    estimatesDetail {
      id
    }

    packagingByPacking1 {
  			packagingName,
        weight
  		}
  }
}`;

const updateById = gql`
mutation updateEstimatesDetailsById(
  $id: Int!
  $estimateId: Int
  $sequenceNo: Int
  $originDestinationLocation: String
  $packing1: Int
  $transportDistance: Float
  $transportFee: Float
  $notes: String
  $escortVehicleOption: Int
  $escortVehicleCount: Int
  $escortVehicleDistance: Float
  $outsourceEscortVehicleOption: Int
	$outsourceEscortVehicleCount: Int
  $outsourceEscortVehicleDistance: Float
  $outsourceEscortVehicleFee: Float
  $escortVehicleFee: Float
  $updatedUserId : Int,
  $createdUserId : Int
) {
	updateEstimatesDetailById(input:{
    id:$id,
    estimatesDetailPatch:{
      estimateId: $estimateId, 
      sequenceNo: $sequenceNo, 
      originDestinationLocation: $originDestinationLocation, 
      packing1: $packing1, 
      transportDistance: $transportDistance, 
      transportFee: $transportFee, 
      notes: $notes, 
      escortVehicleOption: $escortVehicleOption, 
      escortVehicleCount: $escortVehicleCount, 
      escortVehicleDistance: $escortVehicleDistance, 
      outsourceEscortVehicleOption: $outsourceEscortVehicleOption, 
      outsourceEscortVehicleCount: $outsourceEscortVehicleCount, 
      outsourceEscortVehicleDistance: $outsourceEscortVehicleDistance, 
      outsourceEscortVehicleFee:$outsourceEscortVehicleFee,
      escortVehicleFee: $escortVehicleFee, 
      createdUserId : $createdUserId,
      updatedUserId : $updatedUserId
    }
  }) {
    estimatesDetail{
      id
    }
    packagingByPacking1 {
  			packagingName,
        weight
  		}
  }
}`;

const deleteById= gql`
mutation deleteEstimatesDetailsById($id:Int!) {
  deleteEstimatesDetailById(input:{
    id:$id
  }){
    deletedEstimatesDetailId,
    estimatesDetail{
      id
    }
  }
}`;

export { getAll, create, getById, updateById, deleteById };