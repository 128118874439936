import { Button, Col, Form, Row } from 'antd';
import _ from 'lodash';
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { CustomSelect, InputNumbers } from '../../../commons/CustomDataEntry';
import ModalBase from '../../../commons/ModalBase';
import PackagingCategories from '../../../commons/Picklist/PackagingCategories';
import { ActionHeader } from '../../../commons/TableActions';
import WithEventButton from '../../../commons/WithEventButton';
import { getAll } from '../../../queries/MasterMaintenance/package';
import adhocTranslator from "../../../utils/locales/translate-adhoc";
import { statusTypes } from '../../../utils/types';
import PackageConfig from './ListModal';

export default () => {
    let lt = adhocTranslator('K903-010');
    let ltV = adhocTranslator('K903-020');

    const statusList = [
        { id: '', value: ltV('すべて') },
        { id: 'ACTIVE', value: ltV('rdo_active') },
        { id: 'INACTIVE', value: ltV('rdo_inactive') }
    ];

    return {
        packageSearch: true,
        fetchQuery: getAll,
        allKey: 'allPackagings',
        perPage: 20,
        removeType: true,
        th: '390px',
        columns: [
            {
                title: lt('lbl_packaging_header'),
                dataIndex: 'packagingName',
                key: 'packagingName',
                width: 310
            }, {
                title: lt('lbl_class_header'),
                dataIndex: 'packingCategory',
                key: 'packingCategory',
                width: 310
            }, {
                title: lt('lbl_weight_ton_header'),
                dataIndex: 'weight',
                key: 'weight',
                align: 'center',
                width: 310,
                render: (text) => { return <p style={{ textAlign: 'center', marginBottom: 1 }}>{text} t</p> }
            }, {
                title: lt('lbl_status_header'),
                dataIndex: 'status',
                key: 'status',
                align: 'center',
                width: 80
            }, {
                title: lt('lbl_process'),
                key: 'lbl_process',
                render: (text, record) => (
                    <span>
                        <Link to={{
                            pathname: `/packaging/update/${record.id}`,
                            state: { id: text.id }
                        }}>[ {lt('btn_edit')} ]</Link>
                        <Link to={{
                            pathname: `/packaging/delete/${record.id}`,
                            state: { id: text.id }
                        }}>[ {lt('btn_delete')} ]</Link>
                    </span>
                ),
                align: 'center',
                width: 110
            }
        ],
        dataMapping: (item) => {
            return {
                id: item.id,
                packagingName: item.packagingName,
                packingCategory: _.get(item.packagingCategoryByPackingCategoryId, 'nameJa', ''),
                weight: item.weight,
                status: statusTypes[item.status],
            }
        },

        searchHeader: ({ form, handleSearch, loading, clearRows }) => {
            const resetFields = () => {
                form.resetFields()
                clearRows(true)
                handleSearch()
            }

            return (
                <Fragment>
                    <span className="search-filter">{lt('hdr_search_conditions')}</span>
                    <Form onSubmit={handleSearch}>
                        <Row type='flex'>
                            <Col span={9}>
                                <div className='ant-row ant-form-item'>
                                    <ModalBase
                                        title='荷物情報検索'
                                        form={form}
                                        label={lt('lbl_packaging')}
                                        btnLabel={lt('選択')}
                                        fieldName='id'
                                        rowField={false}
                                        mode={'add'}
                                        labelWidth={5}
                                        config={PackageConfig()}
                                        decorator={{}}
                                        isFilter={true}
                                        filterType='packing'
                                    />
                                </div>

                                <div className="ant-row ant-form-item" >
                                    <PackagingCategories
                                        form={form}
                                        fieldName='packingCategoryId'
                                        label={lt('lbl_class')}
                                        rowField={false}
                                        hasDefault={true}
                                        labelWidth={120}
                                        style={{ width: 320, display: 'inline-block', marginRight: 10 }}
                                    />
                                </div>
                                <InputNumbers
                                    form={form}
                                    fieldName='weight_From'
                                    label={lt('lbl_weight')}
                                    postLabel={lt('lbl_weight_from')}
                                    maxLength={2}
                                    rowField={false}
                                    style={{ width: 100, display: 'inline-block', marginRight: 10 }}
                                    decorator={{
                                    }}
                                    labelWidth={120} />
                                <InputNumbers
                                    form={form}
                                    fieldName='weight_To'
                                    maxLength={2}
                                    rowField={false}
                                    style={{ width: 100, display: 'inline-block', marginRight: 10 }}
                                    decorator={{
                                    }}
                                    labelWidth={120} />
                            </Col>
                            <Col span={12}>

                                <CustomSelect
                                    form={form}
                                    options={statusList}
                                    label={lt('lbl_status')}
                                    fieldName='status'
                                    decorator={{
                                        initialValue: '',
                                    }}
                                    width={8}
                                />

                            </Col>
                        </Row>
                        <Row type='flex' justify='space-around'>
                            <Col span={24} align='center'>
                                {/*Submit*/}
                                <WithEventButton
                                    event={handleSearch}
                                    loading={loading}
                                    label={lt('btn_search')}
                                    style={{ height: '38px', width: '104px', margin: '14px 14px 14px 0px' }}
                                />
                                <Button
                                    onClick={resetFields}
                                    type="primary"
                                    htmlType="button"
                                    loading={loading}
                                    style={{ height: '38px', width: '104px', margin: '14px 14px 14px 0px' }}
                                >
                                    {lt('btn_clear')}
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Fragment>
            );
        },
        actionHeader: () => {
            return (
                <Fragment>
                    <ActionHeader
                        rightActions={
                            <Fragment>
                                <Button
                                    type="primary"
                                    htmlType="button">
                                    <Link to="/packaging/register">{lt('btn_register_new_info')}</Link>
                                </Button>
                            </Fragment>
                        }
                    />
                </Fragment>
            )
        },
        minWidth: 'max-content'
    }
}