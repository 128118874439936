import io from 'socket.io-client';

let socket;

export const init = () => {
    // Return cached socket
    if (socket) {
        return socket;
    }
    // Check cache
    socket = io(process.env.REACT_APP_SOCKET_SERVER || 'http://localhost:4005',{
        transports: ['websocket'],
        reconnection : true,
        query : {
            token : localStorage.getItem('token'),
            type : 'web'
        }
    });

    socket.on('disconnect', ()=> {
        console.log('disconnected')
        // Remove socket
        socket = null;
    });

    return socket;
};

export let connection = () => {
    return {
        socket
    };
};